import React, {
  useState, useEffect, useLayoutEffect, createContext, useContext,
} from 'react';
import { useSelector } from 'react-redux';
import { useAsyncCallback } from 'react-async-hook';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { store } from 'state';
import { calculations as service } from 'services';
import { useQuery } from 'utils';

export const Calculation = createContext({ loading: true, result: null });

export const useCalculationContext = () => useContext(Calculation);

export const useCalculationType = (calculationType) => {
  const { t } = useTranslation();
  const queryType = useQuery().get('type');
  const [type, setType] = useState(calculationType || 'product');

  const options = [
    { value: 'product', label: t('product') },
    { value: 'semi_finished_product', label: t('semi_finished_product') },
  ];

  useEffect(() => {
    if (calculationType === 'product' || queryType === 'product') {
      setType('product');
    } else if (calculationType === 'semi_finished_product'
      || queryType === 'semi') {
      setType('semi_finished_product');
    }
  }, [calculationType, queryType]);

  return {
    type,
    get item() { return options[this.type === 'product' ? 0 : 1]; },
    options,
  };
};

export const useCanEdit = () => {
  const result = useContext(Calculation)?.result;
  const user = useSelector(state => state.user);
  const { id = {} } = store.getState()?.company || {};
  const [canEdit, setPermission] = useState(true);

  useLayoutEffect(() => {
    setPermission(!result?.locked);
  }, [result, id]);

  return canEdit && user?.selectedMembership?.view !== 'Viewer';
};

export const useCalculation = () => {
  const { id: calculationId } = useParams();
  const [cachedCalculation, cacheCalculation] = useState();
  const calculation = useAsyncCallback(() => service.getExtended(calculationId), []);
  const { execute } = calculation;

  useEffect(() => {
    if (calculationId) execute();
  }, [calculationId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (calculation.result) {
      if (Number(calculationId) === calculation.result.id) {
        cacheCalculation(calculation.result);
      } else {
        cacheCalculation(undefined);
      }
    }
  }, [calculation.result, calculationId]);

  useEffect(() => {
    if (cachedCalculation) {
      if (cachedCalculation.id !== Number(calculationId)) {
        cacheCalculation(undefined);
      }
    }
  }, [cachedCalculation, calculationId]);

  return { ...calculation, result: cachedCalculation || calculation.result };
};

export default ({ children }) => {
  const calculation = useCalculation();

  return (
    <Calculation.Provider value={calculation}>
      {children}
    </Calculation.Provider>
  );
};
