export const getDropDownPosition = (el, divEl) => {
  let placement = '';

  // get position of dropdown from bottom
  const elHeight = el.clientHeight + 25;

  const windowHeight = document.documentElement.clientHeight;

  // distance top top (excluding navbar 4rem * 4 = 256px);

  const divPositionToTop = divEl.getBoundingClientRect().top - 260;
  const divPositionToBottom = windowHeight - divPositionToTop - 260;

  // EPD-706
  if ((divPositionToBottom - 30) > elHeight) {
    placement = 'bottom';
  } else if (divPositionToTop > elHeight) {
    placement = 'top';
  } else {
    placement = 'left';
  }

  return {
    placement,
    toTop: divEl.getBoundingClientRect().top,
  };
};
