import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { orderBy } from 'state/calculations/calculations.actions';

import Arrow from 'assets/sortArrow.svg';
import * as S from './TopBar.styled';

const LastChangedStatus = ({
  hasStatus, onOrderBy, onOrderByStatus, label, inverted, lastChangedLabel, order, orderStatus,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isDesc, setIsDesc] = useState(false);
  const [status, setStatus] = useState(false);

  useEffect(() => {
    setIsDesc(order === 'desc');
    // eslint-disable-next-line
  }, []);

  const sortName = () => {
    if (isDesc) {
      if (onOrderBy) onOrderBy(inverted ? 'desc' : 'asc');
      else dispatch(orderBy(inverted ? 'desc' : 'asc'));
      if (order === 'desc') setIsDesc(false);
      else if (order === null) setIsDesc(true);
      else setIsDesc(!isDesc);
    } else {
      if (onOrderBy) onOrderBy(inverted ? 'asc' : 'desc');
      else dispatch(orderBy(inverted ? 'asc' : 'desc'));
      if (order === 'desc') setIsDesc(false);
      else if (order === null) setIsDesc(true);
      else setIsDesc(!isDesc);
    }
  };

  const handleStatusChange = () => {
    if (status) {
      onOrderByStatus('asc');
      if (orderStatus === 'desc') setStatus(false);
      else if (orderStatus === null) setStatus(true);
      else setStatus(!status);
    } else {
      onOrderByStatus('desc');
      if (orderStatus === 'desc') setStatus(false);
      else if (orderStatus === null) setStatus(true);
      else setStatus(!status);
    }
  };

  return (
    <S.Section hasChanged hasStatus={hasStatus}>
      <S.Changed>
        <S.Label onClick={sortName}>{label || t(lastChangedLabel || 'last changed')}</S.Label>
        {order && <S.Arrow isDesc={inverted ? !isDesc : isDesc} onClick={sortName} src={Arrow} />}
      </S.Changed>

      {hasStatus && (
        <S.Status>
          <S.Label onClick={handleStatusChange}>{t('Status')}</S.Label>
          {orderStatus && <S.Arrow isDesc={inverted ? !status : status} onClick={handleStatusChange} src={Arrow} />}
        </S.Status>
      )}
    </S.Section>
  );
};

export default LastChangedStatus;
