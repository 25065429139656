import _ from 'lodash';
import React from 'react';
import { useDispatch } from 'react-redux';
import { setBreadcrumb } from 'state/sidebar/sidebar.actions';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useWasteRegion } from 'hooks/wasteRegion.hooks';
import { useMethods } from 'hooks/methods.hooks';
import { useCustomUnitForLCA, useStandardUnitForLCA } from 'hooks/functionalUnit.hooks';
import Button from 'components/Button/Button';
import { ReadOnlyField } from 'components/Field';
import { Placeholder } from 'components/Splash/Loading';

import {
  ViewModeContainer, TitleBar, GroupFields, GroupFieldsHeader, GroupFieldsDivider,
} from 'routes/GlobalStyle.styled';
import { findPublisherOperator } from 'utils/calculationsPublisherOperator';
import { predefinedConstructionWasteOpt } from '../Advanced/Editor.helpers';

const ViewTemplateBasics = ({ template, loading }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const methods = useMethods(template);
  const customUnitForLCA = useCustomUnitForLCA(null, template);
  const standardUnitForLCA = useStandardUnitForLCA(null, template);
  const wasteRegions = useWasteRegion(template);

  React.useEffect(() => {
    if (template?.code) dispatch(setBreadcrumb(template?.code));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [template]);

  return (
    <Placeholder loaders={[loading, methods, wasteRegions]}>
      <TitleBar>
        {t('template basics')}

        <Button
          as={Link}
          styling="select"
          to={`/calculations/templates/${template?.id}/template-basics/edit`}>
          {t('edit')}
        </Button>
      </TitleBar>

      <ViewModeContainer style={{ padding: '1.87rem 0', display: 'unset' }}>
        <GroupFields>
          <GroupFieldsHeader>{t('Template characteristics')}</GroupFieldsHeader>
          <ReadOnlyField name="Code">
            {template?.code}
          </ReadOnlyField>
          <ReadOnlyField name="name">
            {template?.name}
          </ReadOnlyField>
          {template?.location && (
          <ReadOnlyField name="location">
            {`${template?.location?.name}
              - ${template?.location?.city}
              , ${template?.location?.country}`}
          </ReadOnlyField>
)}
          {template?.productLifeTime && (
          <ReadOnlyField name="productLifeTime">
            {template?.productLifeTime}
          </ReadOnlyField>
)}
        </GroupFields>
        <GroupFieldsDivider />
        <GroupFields>
          <GroupFieldsHeader>{t('lca method')}</GroupFieldsHeader>
          <ReadOnlyField name="method">
            {template?.method.name}
          </ReadOnlyField>

          <ReadOnlyField name="calculationPublisher">
            {findPublisherOperator('publisher', null, template)?.name || '-'}
          </ReadOnlyField>
          <ReadOnlyField name="calculationOperator">
            {findPublisherOperator('operator', null, template)?.name || '-'}
          </ReadOnlyField>

          <ReadOnlyField name="wasteRegion">
            {wasteRegions.selected
                && `${wasteRegions.selected.name}, ${wasteRegions.selected.code}`}
          </ReadOnlyField>
        </GroupFields>
        <GroupFieldsDivider />
        <GroupFields>
          <GroupFieldsHeader>{t('standardUnit.name')}</GroupFieldsHeader>
          {!template?.customFunctionalUnit && standardUnitForLCA.selected ? (
            <ReadOnlyField name="functionalUnit">
              <div>
                <p>
                  {`${standardUnitForLCA.selected.unit}
                    (${standardUnitForLCA.selected.elementName})`}
                </p>
                <p><sub>{standardUnitForLCA.selected.description}</sub></p>
              </div>
            </ReadOnlyField>
          ) : (
            <>
              <ReadOnlyField name="functionalUnitTitle">
                {customUnitForLCA.title}
              </ReadOnlyField>

              <ReadOnlyField name="functionalUnitDescription">
                {customUnitForLCA.description}
              </ReadOnlyField>

              <ReadOnlyField name="standardUnit">
                {`(${customUnitForLCA?.selected?.abbreviation})
                  ${customUnitForLCA?.selected?.unit}`}
              </ReadOnlyField>
            </>
          )}
        </GroupFields>
        <GroupFieldsDivider />
        <GroupFields>
          <GroupFieldsHeader>{`${t('productType.label')} (A4)`}</GroupFieldsHeader>
          <ReadOnlyField name="productType">
            {methods.productType?.name}
          </ReadOnlyField>
          {methods.productType?.id === 3 && (
            <>
              <ReadOnlyField name="transportProfileToConstructionSite">
                {_.find(
                  methods.profiles,
                  ({ '@id': id }) => id === template.transportProfileToConstructionSite,
                )?.name}
              </ReadOnlyField>

              <ReadOnlyField name="distanceToConstructionSite">
                {template?.distanceToConstructionSite}
              </ReadOnlyField>
            </>
            )}
        </GroupFields>

        <GroupFieldsDivider />
        <GroupFields>
          <GroupFieldsHeader>{t('construction waste')}</GroupFieldsHeader>
          <ReadOnlyField name="predefinedConstructionWaste">
            {_.find(
              predefinedConstructionWasteOpt,
              ({ value }) => value === (template.predefinedConstructionWaste || 0),
            )?.label}
          </ReadOnlyField>

          {!template.predefinedConstructionWaste && (
            <ReadOnlyField name="constructionWaste">
              <pre>{template.constructionWaste}</pre>
            </ReadOnlyField>
          )}
        </GroupFields>
      </ViewModeContainer>
    </Placeholder>
  );
};

export default ViewTemplateBasics;
