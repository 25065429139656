import React, { memo } from 'react';

import DropDown from 'components/DropDown/DropDown';
import dots from 'assets/dots.svg';

// icons
// import plane from 'assets/transportsIcons/plane.svg';
// import truck from 'assets/transportsIcons/truck.svg';
import * as S from './Card.styled';

// regex to get veihicle inside parentheses
// const regExp = /\(([^)]+)\)/;
// const matches = regExp.exec(name);
// const veihicle = matches[1].toLowerCase();

const Card = ({
  description, dropdownContent, name, onClick, hideDropdown,
}) => (
  <S.Wrapper onClick={onClick}>
    <S.Section>
      <S.Info>
        <S.NameDetail>{name}</S.NameDetail>
        <S.Description>{description}</S.Description>
      </S.Info>
    </S.Section>
    <DropDown content={dropdownContent}>
      <S.Options alt="icon" hideDropdown={hideDropdown} src={dots} />
    </DropDown>
  </S.Wrapper>
);

export default memo(Card);
