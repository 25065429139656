import React from 'react';
import { Link, useRouteMatch, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from 'components/Button/Button';
import { locations } from 'services';
import { useAsync } from 'react-async-hook';
import { FormContentInfoNoBorder } from 'components/ContentInfo/ContentInfo';
import { Loading } from 'components/Splash/Loading';
import * as S from 'routes/GlobalStyle.styled';
import { useSelector, useDispatch } from 'react-redux';
import { setBreadcrumb } from 'state/sidebar/sidebar.actions';

const Supplier = () => {
  const { t } = useTranslation();
  const { url } = useRouteMatch();
  const { id } = useParams();
  const dispatch = useDispatch();
  const { selectedCompany, id: companyId } = useSelector(({ company }) => company || {});
  const user = useSelector(state => state.user);

  const { loading, result: supplier = {} } = useAsync(locations.supplier, [id]);

  React.useEffect(() => {
    if (supplier?.name) dispatch(setBreadcrumb(supplier?.name));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supplier]);

  if (loading) return Loading;

  const isEditable = companyId === selectedCompany?.id;

  const ShowProfileContent = () => (
    <>
      <S.Bar>
        {t('supplier profile')}

        {user?.selectedMembership?.view !== 'Viewer'
          && (
            <Button
              as={Link}
              style={{ pointerEvents: !isEditable && 'none' }}
              styling="select"
              to={isEditable && `${url}/edit`}>
              {t('edit')}
            </Button>
          )}
      </S.Bar>

      <S.Container>
        <S.ItemsContainer>
          <div style={{ marginRight: '6.87rem' }}>
            <FormContentInfoNoBorder content={supplier?.name} name="name supplier" />
            <FormContentInfoNoBorder content={supplier?.code} name="unique id" />
            <FormContentInfoNoBorder
              content={supplier?.documentType}
              name="received document (when applicable)"
            />
            <FormContentInfoNoBorder
              content={supplier?.approachedBy ? t('by letter') : t('by email')}
              name="manner in which the supplier is approached"
            />
            <FormContentInfoNoBorder
              content={supplier?.approachedForCompanyEPD ? t('yes') : t('no')}
              name="supplier approached for company specific environmental data"
            />
          </div>
          <div>
            <FormContentInfoNoBorder content={supplier?.address} name="address supplier" />
            <FormContentInfoNoBorder content={supplier?.city} name="city" />
            <FormContentInfoNoBorder content={supplier?.postalCode} name="zipcode" />
            <FormContentInfoNoBorder content={supplier?.country} name="country" />
          </div>
        </S.ItemsContainer>
      </S.Container>
    </>
  );

  return <ShowProfileContent />;
};

export default Supplier;
