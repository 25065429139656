import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: no-wrap;
  justify-content: flex-start;
  background: #E7EDF0;
  padding: 0.62rem 1.93rem;
  padding-right: 2rem;
  border-bottom: 1px solid #e4e4e4;
  height: 4rem;
`;

export const Spacer = styled.span`
  flex: 1;
`;

export const Title = styled.h2`
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  text-transform: capitalize;
`;
