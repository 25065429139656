import _ from 'lodash';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import ProfileIcon from 'assets/profile.svg';

import * as S from '../Content.styled';

const User = ({ close }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const userDropDown = [
    {
      path: '/user-profile',
      label: t('User profile'),
      img: ProfileIcon,
    },
  ];

  const handleLogout = () => history.push('/logout');

  return (
    <>
      <S.Wrapper>
        {_.map(userDropDown, (link) => (
          <S.StyledLink key={link.path} onClick={close} to={link.path}>
            <div>
              <img alt="icon" src={link.img} />
            </div>

            <S.StyledLabel>{link.label}</S.StyledLabel>
          </S.StyledLink>
        ))}
      </S.Wrapper>

      <S.StyledLogout onClick={handleLogout}>
        {t('Logout')}
      </S.StyledLogout>
    </>
  );
};

export default User;
