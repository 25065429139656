import React from 'react';

const Valid = ({ color, ...props }) => (
  <svg fill="none" height="14" viewBox="0 0 14 14" width="14" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      // eslint-disable-next-line max-len
      d="M7 0C3.15 0 0 3.15 0 7C0 10.85 3.15 14 7 14C10.85 14 14 10.85 14 7C14 3.15 10.85 0 7 0ZM7 12.6C3.92 12.6 1.4 10.08 1.4 7C1.4 3.92 3.92 1.4 7 1.4C10.08 1.4 12.6 3.92 12.6 7C12.6 10.08 10.08 12.6 7 12.6Z"
      fill={color || '#70876E'}
    />
    <path
      // eslint-disable-next-line max-len
      d="M5.81 7.83995L4.48 6.50995L3.5 7.48995L5.81 9.86995L10.5 5.17995L9.52 4.19995L5.81 7.83995Z"
      fill={color || '#70876E'}
    />
  </svg>
);

export default Valid;
