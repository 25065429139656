import styled, { css } from 'styled-components';
import { GREY, DARK_BLUE, paddingRows } from 'config/style';

export const Wrapper = styled.div`
  background: ${GREY};
  padding: ${paddingRows};
  color: ${DARK_BLUE};
  font-size: .875rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e4e4e4;
  min-height: 4rem;
`;

export const Name = styled.div`display: flex;`;

export const Changed = styled.div`display: flex; cursor: pointer`;

export const Status = styled.div`display: flex; cursor: pointer`;

export const Section = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${(props) => props.hasStatus && css`width: 20rem;`};
  ${(props) => props.hasStatus && css`padding-right: 3rem`};
`;

export const Label = styled.span`
  ${({ noSorting, onClick }) => !noSorting && onClick && 'cursor: pointer;'};
`;

export const Arrow = styled.img`
  margin-left: 7px;
  ${({ noSorting }) => !noSorting && 'cursor: pointer;'};
  ${(props) => props.isDesc && 'transform: rotate(180deg)'};
`;
