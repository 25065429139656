import React, { useState } from 'react';
import * as S from './VerticalMenu.styled';

const MenuCollapse = ({ children, to, label }) => {
  const [active, setActive] = useState(false);
  const isActive = match => setActive(!!match);

  return (
    <>
      <S.AsideLink isActive={isActive} to={to}>
        {label}
      </S.AsideLink>

      <S.AsideLinkWrapper in={active}>
        {children}
      </S.AsideLinkWrapper>
    </>
  );
};

export default MenuCollapse;
