import React from 'react';
import { useTranslation } from 'react-i18next';

import filterIcon from 'assets/settings.svg';
import * as S from './AsideOverview.styled';

const AsideOverview = ({
  children,
  clearFilter,
  results,
  clearingFilterCondition,
  hasFilterIcon,
  padding,
}) => {
  const { t } = useTranslation();

  return (
    <div>
      {hasFilterIcon && (
      <S.FilterSection>
        <img alt="icon" src={filterIcon} />

        <S.FilterText>
          {t('filters')}
        </S.FilterText>
      </S.FilterSection>
      )}

      <S.InputWrapper>
        <div>
          <S.Padding padding={padding}>
            {children}
          </S.Padding>

          <S.FilterWrapper>
            {((results !== undefined) && (results !== null))
              && (
                <S.Results>
                  {t('results found', { count: results })}
                </S.Results>
              )}

            {clearingFilterCondition && ((results !== undefined) && (results !== null)) && (
            <S.ClearFilter onClick={clearFilter}>
              {t('clear all filters')}
            </S.ClearFilter>
            )}
          </S.FilterWrapper>
        </div>
      </S.InputWrapper>
    </div>
  );
};

export default AsideOverview;
