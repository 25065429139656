import {
  calculations as calculationsService,
  folders as foldersService,
} from 'services';

import * as types from './calculations.types';

export const getFolders = (params) => dispatch => foldersService
  .get({ params, headers: { Accept: 'application/json' } })
  .then(folders => dispatch({
    type: types.FOLDERS,
    payload: folders,
  }));

export const getList = ({ params }) => dispatch => {
  dispatch({ type: types.LOADING, payload: true });
  const folderConfig = { parent: params.folder };

  if (params?.productName) folderConfig.name = params.productName;
  if (params?.owner) folderConfig.owner = params.owner;
  if (params['order[productName]']) {
    folderConfig['order[name]'] = params['order[productName]'];
  }

  return Promise.all([
    getFolders(folderConfig)(dispatch),
    calculationsService
      .getByFolder({ params })
      .then(data => {
        dispatch({
          type: types.LIST,
          payload: { list: data.data, totalItems: data.totalItems, nextPage: data.nextPage },
        });
      }),
  ])
    .finally(() => dispatch({ type: types.LOADING, payload: false }));
};

export const getNextPageList = (url) => dispatch => calculationsService
  .getNextPageByFolder(url)
  .then(data => {
    dispatch({
      type: types.NEXT_PAGE_LIST,
      payload: { list: data.data, totalItems: data.totalItems, nextPage: data.nextPage },
    });
  });

export const deleteCalculation = (calculationIdsArray) => ({
  type: types.PRUNE_LIST,
  payload: calculationIdsArray,
});

export const byName = payload => ({
  type: types.FIND_NAME,
  payload,
});

export const refresh = ({ params }) => dispatch => calculationsService
  .getByFolder({ params })
  .then(({ data, totalItems, nextPage }) => {
    dispatch({
      type: types.LIST,
      payload: {
        list: data,
        totalItems,
        nextPage,
      },
    });
  });

const getSortConfig = value => {
  switch (value) {
    case 'created-asc': return { sortBy: 'created', sortDirection: 'asc' };
    case 'created-desc': return { sortBy: 'created', sortDirection: 'desc' };
    case 'updated-asc': return { sortBy: 'updated', sortDirection: 'asc' };
    case 'updated-desc': return { sortBy: 'updated', sortDirection: 'desc' };
    case 'desc': return { sortDirection: 'desc' };
    case 'asc': return { sortDirection: 'asc' };
    default: return { sortDirection: 'asc' };
  }
};

export const orderBy = (selectedOrder) => ({
  type: types.ORDER,
  payload: getSortConfig(selectedOrder),
});

export const sortLastModified = direction => ({
  type: types.SORT_LAST_MODIFIED,
  payload: direction,
});
export const sortStatus = direction => ({
  type: types.SORT_STATUS,
  payload: direction,
});
export const orderByName = direction => ({
  type: types.ORDER_BY_NAME,
  payload: direction,
});
export const orderByCode = direction => ({
  type: types.ORDER_BY_CODE,
  payload: direction,
});

export const selectCalculation = (id, value) => ({
  type: types.SELECTION,
  payload: { id, value },
});

export const selectNone = { type: types.CLEAR_SELECTION };
export const selectAll = (list) => ({ type: types.FULL_SELECTION, payload: { list } });
export const selectAllCalculations = { type: types.SELECT_ALL_CALCULATIONS };

export const setFilter = (payload) => ({
  type: types.UPDATE_FILTER,
  payload,
});

export const clearFilters = {
  type: types.CLEAR_FILTERS,
};

export const reset = {
  type: types.RELEASE_MEMORY,
};
