/* eslint-disable no-unused-vars */
import _ from 'lodash';
import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, Link } from 'react-router-dom';
import { useAsyncCallback } from 'react-async-hook';

import { locations, companies as companiesServices } from 'services';

import AsideLayout from 'components/AsideLayout/AsideLayout';
import AsideOverview from 'components/AsideOverview/AsideOverview';
import Button from 'components/Button/Button';
import ID from 'components/TopBar/ID';
import MainModal from 'components/Modal';
import Name from 'components/TopBar/Name';
import TopBar from 'components/TopBar/TopBar';
import { FormInput } from 'components/Input/Input';
import { FormSelect } from 'components/Select/Select';
import { useSelector, useDispatch } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import TopbarActions from 'components/TopbarActions/TopbarActions';
import { selectCompany } from 'state/company/company.actions';
import { Loading } from 'components/Splash/Loading';
import { confirmationModalSetup } from 'components/Modal/ModalContent/Delete';

import edit from 'assets/edit.svg';
import deleteIcon from 'assets/delete.svg';
import plusIcon from 'assets/plus_sign.svg';
import * as S from 'routes/GlobalStyle.styled';
import { setBreadcrumb } from 'state/sidebar/sidebar.actions';
import Card from './Card/Card';

const buttonStyle = {
  height: '2.81rem',
  width: '2.81rem',
  minWidth: 'auto',
  padding: '.9rem .4rem .8rem',
  marginBottom: '1.3rem',
};

const Location = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const user = useSelector(state => state.user);
  const dispatch = useDispatch();
  const {
    selectedCompany, id: companyId, name: companyName, ...other
  } = useSelector(({ company }) => company || {});
  const [input, setInput] = useState('');
  const [clearInput, setClearInput] = useState(null);
  const [confirmationModal, setConfirmationModal] = useState(null);
  const [modalContent, setModalContent] = useState(null);
  const [country, setCountry] = useState(null);
  const [city, setCity] = useState(null);
  const [suppliers, setSuppliers] = useState(null);
  const [suppliersOriginalList, setSuppliersOriginalList] = useState(null);
  const [page, setPage] = useState(null);
  const [loadingFlag, setLoadingFlag] = useState(false);
  const [sorting, setSorting] = useState('asc');
  const [sortingCode, setSortingCode] = useState(null);
  const [totalNumberOfRecords, setTotalNumberOfRecords] = useState(null);
  const time = useRef(0);

  const countries = locations.countries();

  const {
    result: location, loading, execute: locationExecute,
  } = useAsyncCallback(() => locations.get(id));

  useEffect(() => {
    if (location?.name) dispatch(setBreadcrumb(location?.name));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    let suppliersFromTransports = _.sortBy(
      location?.transports?.map(tr => (
        { ...tr?.supplier, distance: tr?.distance, transportProfile: tr?.transportProfile }
      )), (s) => s?.name,
    );
    suppliersFromTransports = _.sortBy(
      _.toArray(_.groupBy(location?.transports, 'supplier.id'))?.map(s => ({
        ...s[0]?.supplier,
        transports: s,
      })), s => s?.name,
    );
    setSuppliers(suppliersFromTransports);
    setSuppliersOriginalList(suppliersFromTransports);
    setTotalNumberOfRecords(suppliersFromTransports?.length);
  }, [loading, location]);

  useEffect(() => {
    if (suppliers) {
      const sortedSuppliers = suppliers?.sort((a, b) => {
        const sortingOption = sorting || sortingCode;
        let prop = '';

        if (sorting) prop = 'name';
        else if (sortingCode) prop = 'code';

        const aValue = !isNaN(a[prop]) ? Number(a[prop]) : a[prop]?.toLowerCase();
        const bValue = !isNaN(b[prop]) ? Number(b[prop]) : b[prop]?.toLowerCase();

        if (aValue > bValue) {
          return (sortingOption === 'asc') ? 1 : -1;
        }
        if (aValue < bValue) {
          return (sortingOption === 'asc') ? -1 : 1;
        }
        return 0;
      });
      setSuppliers([...sortedSuppliers]);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortingCode, sorting]);

  useEffect(() => {
    let filteredSuppliers = suppliersOriginalList;
    if (input) {
      filteredSuppliers = input === '' ? suppliersOriginalList
        : _.filter(filteredSuppliers, s => s?.name?.includes(input));
    } if (country) {
      filteredSuppliers = _.filter(filteredSuppliers, s => s?.country === country?.value);
    } if (city) {
      filteredSuppliers = city === '' ? suppliersOriginalList : _.filter(filteredSuppliers,
        s => s?.city?.toLowerCase() === city?.toLowerCase());
    }
    setSuppliers(filteredSuppliers);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [input, country, city]);

  const {
    result: companies, execute: executeCompanies,
  } = useAsyncCallback(companiesServices.getAll);

  const superAdmin = user?.selectedMembership?.view === 'Super Admin'
    || user?.selectedMembership?.view === 'Nibe Employee';

  useEffect(() => {
    locationExecute();
    if (superAdmin) executeCompanies();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const clearFilter = () => {
    const inputValue = { value: '' };
    setClearInput(inputValue);
    setInput('');
    setCountry(null);
    setCity(null);
    selectCompany({
      id: companyId, name: companyName, label: companyName, value: companyName, ...other,
    })(dispatch);
  };

  /* useEffect(() => {
    const params = {};

    if (input) params.name = input;
    if (city) params.city = city;
    if (country) params.country = country.value;
    if (company) params.owner = company.id;
    if (sorting) params['order[name]'] = sorting;
    if (sortingCode) params['order[code]'] = sortingCode;
    setTotalNumberOfRecords(null);
    setLoadingFlag(true);
    locations.suppliersWithTransports(params).then(suppliersResults => {
      setSuppliers(suppliersResults.suppliers);
      setPage(suppliersResults.nextPage);
      setTotalNumberOfRecords(suppliersResults.totalItems);
    }).finally(() => setLoadingFlag(false));
  }, [city, company, country, input, sorting, sortingCode]); */

  const handleInputChange = (e, type) => {
    if (time.current) clearTimeout(time.current);
    const inputText = e.target.value;
    const params = {};

    if (type === 'search' && inputText) params.name = inputText;
    else if (type === 'city' && inputText) params.city = inputText;

    time.current = setTimeout(() => {
      const clearSpaces = inputText && inputText.length > 0 && inputText.trim();
      if (clearSpaces) {
        if (type === 'search') setInput(inputText);
        else setCity(inputText);
      } else if (type === 'search') setInput('');
      else setCity('');
      clearTimeout(time.current);
    }, 2000);
  };

  const handleOnKeyDown = (event, type) => {
    if (event.key === 'Enter') {
      const inputText = event.target.value;
      const clearSpaces = inputText && inputText.length > 0 && inputText.trim();
      if (clearSpaces) {
        if (type === 'search') setInput(inputText);
        else setCity(inputText);
      } else if (type === 'search') setInput('');
      else setCity('');
    }
  };

  const onFocus = () => setClearInput(null);

  const updateSuppliers = (result, supplier) => {
    const newSuppliersList = _.map(suppliers, s => {
      const tempSupplier = s;

      if (tempSupplier.id === supplier.id) {
        let update = false;
        const tempTransports = tempSupplier?.transports?.map(tr => {
          if (tr.id === result.id) {
            update = true;
            return result;
          }
          return tr;
        });

        if (tempTransports) {
          if (!update) tempTransports.push(result);
          tempSupplier.transports = tempTransports;
        } else tempSupplier.transports = [result];
      }

      return tempSupplier;
    });

    if (!newSuppliersList?.some(s => s?.id === supplier?.id)) {
      newSuppliersList.unshift({
        ...supplier,
        transports: [result],
      });
    }

    setSuppliers(newSuppliersList);
    setConfirmationModal(null);
  };

  const onEditItem = (transport, supplier) => {
    setModalContent('supplierTransport');
    setConfirmationModal({
      supplier, transport, isEdit: true, location: id, updateSuppliers,
    });
  };

  const onDeleteItem = (data, supplier) => {
    setModalContent('delete');
    confirmationModalSetup(
      setConfirmationModal,
      (closeModal) => locations
        .deleteTransport(data.id)
        .then(() => {
          const tempSuppliers = _.map(suppliers, supplierValue => {
            const valueToReturn = supplierValue;

            if (supplierValue.id === supplier.id) {
              valueToReturn.transports = _.filter(
                supplierValue.transports,
                value => value.id !== data.id,
              );
            }

            return valueToReturn;
          });

          setSuppliers(_.filter(tempSuppliers, s => s?.transports?.length > 0));
          closeModal();
        }),
    );
  };

  const addTransport = (supplier) => {
    setModalContent('supplierTransport');
    setConfirmationModal({ supplier, location, updateSuppliers });
  };

  const ListItemContent = ({ data, supplier }) => (
    <>
      <S.Option
        key="edit"
        isClickable={user?.selectedMembership?.view !== 'Viewer'}
        onClick={() => user?.selectedMembership?.view !== 'Viewer'
          && onEditItem(data, supplier)}>
        <div>
          <img alt="icon" src={edit} />
        </div>

        <S.Label isRegular>
          {t('edit')}
        </S.Label>
      </S.Option>

      <S.Option
        key="delete"
        isClickable={user?.selectedMembership?.view !== 'Viewer'}
        onClick={() => user?.selectedMembership?.view !== 'Viewer' && onDeleteItem(data, supplier)}>
        <div>
          <img alt="icon" src={deleteIcon} />
        </div>

        <S.Label color="#BF2E00" isRegular>
          {t('delete')}
        </S.Label>
      </S.Option>
    </>
  );

  const fetchData = () => {
    if (page) {
      locations
        .getNextPageSuppliersWithTransports(page)
        .then((values) => {
          setPage(values.nextPage);
          setTotalNumberOfRecords(values.totalItems);
          setSuppliers(suppliers.concat(values.suppliers));
        });
    }
  };

  const suppliersList = () => (
    <S.OverviewRows id="overviewRowsId">
      <InfiniteScroll
        dataLength={suppliers?.length}
        hasMore={page}
        loader={Loading}
        next={fetchData}
        scrollableTarget="overviewRowsId"
        scrollThreshold={0.4}
        style={{ minHeight: '50vh' }}>
        {suppliers?.map((data) => (
          <S.PaddingRow key={data.id} style={{ paddingBottom: 0 }}>
            <S.ItemsWrapper style={{ paddingRight: '13.5rem', marginBottom: '.875rem' }}>
              <div>
                <S.NameTitle>
                  {data?.name}
                </S.NameTitle>
                <S.DetailsInfo>
                  {/* eslint-disable-next-line max-len */}
                  {[data?.address, data?.postalCode, data?.city, data?.country].filter(s => !!s).join(', ')}
                </S.DetailsInfo>
              </div>
              <div>
                <S.SubText>{data?.code}</S.SubText>
              </div>
            </S.ItemsWrapper>
            {data?.transports?.length
              ? (
                <S.AlignItems style={{ flexWrap: 'wrap' }}>
                  {data.transports.map(transport => (
                    <Card
                      key={transport.id}
                      description={`${transport?.distance} km`}
                      dropdownContent={<ListItemContent data={transport} supplier={data} />}
                      hideDropdown={user?.selectedMembership?.view === 'Viewer'}
                      name={transport?.transportProfile?.name}
                      onClick={() => user?.selectedMembership?.view !== 'Viewer'
                        && onEditItem(transport, data)}
                    />
                  ))}
                  {user?.selectedMembership?.view !== 'Viewer'
                    && (
                      <Button
                        onClick={() => addTransport(data)}
                        style={buttonStyle}
                        styling="select">
                        <img alt="icon" src={plusIcon} style={{ width: '.875rem' }} />
                      </Button>
                    )}
                </S.AlignItems>
              ) : user?.selectedMembership?.view !== 'Viewer' && (
                <div style={{ display: 'flex', margin: '1.3rem 0' }}>
                  <Button
                    onClick={() => addTransport(data)}
                    styling="select">
                    <p>Add transport</p>
                  </Button>
                </div>
              )}
          </S.PaddingRow>
        ))}
      </InfiniteScroll>
    </S.OverviewRows>
  );

  const handleDropdownChangeCountry = (countryValue) => {
    setCountry(countryValue);
  };

  const handleDropdownChangeCompany = (companyValue) => {
    selectCompany(companyValue)(dispatch);
  };

  const addSupplier = () => {
    setModalContent('supplierTransport');
    setConfirmationModal({
      isSupplier: true, location, updateSuppliers,
    });
  };

  return (
    <>
      <AsideLayout
        LeftPane={(
          !loading
            ? (
              <div>
                <S.LinksWrapper>
                  <p style={{ fontWeight: '500', fontSize: '22px', lineHeight: '29px' }}>
                    {location?.name}
                  </p>

                  <p style={{ marginBottom: '1.5rem' }}>
                    {`ID: ${location?.code}`}
                  </p>

                  <p>{location?.address}</p>

                  <div style={{ flexDirection: 'row', display: 'flex' }}>
                    <p style={{ marginRight: '0.2rem' }}>{location?.postalCode}</p>
                    <p>{location?.city}</p>
                  </div>

                  <p>{_.find(countries, value => value.value === location?.country)?.label}</p>

                  {user?.selectedMembership?.view !== 'Viewer'
                    && (
                      <div style={{ display: 'flex' }}>
                        <Button
                          as={Link}
                          style={{
                            padding: 0,
                            marginTop: '1rem',
                            pointerEvents: !(user?.selectedMembership?.view !== 'Viewer') && 'none',
                          }}
                          styling="underline"
                          to={user?.selectedMembership?.view !== 'Viewer'
                            ? `/locations-&-suppliers/locations/${id}/edit` : {}}>
                          {t('edit location')}
                        </Button>
                      </div>
                    )}
                </S.LinksWrapper>

                <AsideOverview
                  clearFilter={clearFilter}
                  clearingFilterCondition={
                    (input || city || country) && (totalNumberOfRecords !== null)
                  }
                  results={suppliers?.length}>
                  <FormInput
                    {...clearInput}
                    fullInputWidth
                    id="name"
                    name="name"
                    onChange={(e) => handleInputChange(e, 'search')}
                    onFocus={onFocus}
                    onKeyDown={(e) => handleOnKeyDown(e, 'search')}
                    placeholder={t('find supplier by name')}
                  />

                  <FormSelect
                    fullInputWidth
                    isSearchable
                    name="country"
                    onChange={handleDropdownChangeCountry}
                    options={countries}
                    placeholder={t('find supplier by country')}
                    value={country}
                  />

                  <FormInput
                    {...clearInput}
                    fullInputWidth
                    id="city"
                    name="city"
                    onChange={(e) => handleInputChange(e, 'city')}
                    onFocus={onFocus}
                    onKeyDown={(e) => handleOnKeyDown(e, 'city')}
                    placeholder={t('find supplier by city')}
                  />
                </AsideOverview>
              </div>
            ) : Loading
      )}>
        {user?.selectedMembership?.view !== 'Viewer'
          && (
            <TopbarActions>
              <Button onClick={addSupplier} styling="select">{t('add supplier')}</Button>
            </TopbarActions>
          )}
        <TopBar style={{ paddingRight: '14.5rem' }}>
          <Name
            onOrderBy={order => {
              setSorting(order);
              setSortingCode(null);
            }}
          />
          <ID
            onOrderBy={order => {
              setSorting(null);
              setSortingCode(order);
            }}
          />
        </TopBar>
        {!suppliers || loadingFlag || loading
          ? Loading
          : suppliersList()}
      </AsideLayout>

      <MainModal
        confirmationModalInfo={confirmationModal}
        content={modalContent}
        isModalOpen={confirmationModal}
        setIsModalOpen={setConfirmationModal}
      />
    </>
  );
};

export default Location;
