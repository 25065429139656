import React from 'react';
import * as S from './styled';
import { downloadFile } from '../../utils';

export const FileDownloadButton = ({ originalFilename, contentUrl }) => (
  <S.Download onClick={() => downloadFile(
    `${process.env.REACT_APP_API_URL}${contentUrl}`,
    originalFilename,
  )}>
    {originalFilename}
    <S.Icon />
  </S.Download>
);
