import { alertToast, errorToast } from 'config/toast';
import network from './network';

const paginatedMember = ({
  'hydra:member': imports,
  'hydra:view': view,
  'hydra:totalItems': totalItems,
}) => ({
  imports,
  nextPage: view && view['hydra:next'],
  totalItems,
});

export const getImportFile = (url) => network
  .get(`${process.env.REACT_APP_API_URL}${url}`, { params: { owner: 'none' } })
  .catch();

export const imports = (params) => network
  .get('/supplier_imports', { params })
  .then(paginatedMember)
  .catch();

export const getImport = (id) => network
  .get(`/supplier_imports/${id}`)
  .catch();

export const getImportValidate = (id) => network
  .get(`/supplier_imports/${id}/validate`)
  .catch();

export const getImportedFile = (id) => network
  .get(`/supplier_imports/${id}/import`)
  .catch();

export const getImportCanceled = (id) => network
  .get(`/supplier_imports/${id}/cancel`)
  .catch();

export const getNextPage = (url) => network
  .get(`${process.env.REACT_APP_API_URL}${url}`, { params: { owner: 'none' } })
  .then(paginatedMember)
  .catch((err) => errorToast(err));

export const importSuppliers = (payload) => network
  .post('/supplier_imports', payload)
  .catch();

export const envImports = (params) => network
  .get('/epd_imports', { params })
  .then(paginatedMember)
  .catch();

export const getEnvImport = (id) => network
  .get(`/epd_imports/${id}`)
  .catch();

export const getEnvImportValidate = (id) => network
  .get(`/epd_imports/${id}/validate`)
  .catch();

export const getEnvImportedFile = (id) => network
  .get(`/epd_imports/${id}/import`)
  .catch();

export const getEnvImportCanceled = (id) => network
  .get(`/epd_imports/${id}/cancel`)
  .catch();

export const importEnv = (payload) => network
  .post('/epd_imports', payload)
  .catch();

export const importEnvironmentalProfiles = (payload) => network
  .post('/versions', payload)
  .then(() => alertToast('Environmental profiles imported with success!', 'success'))
  .catch();

export const importFile = (payload) => network
  .post('/media_objects', payload, {
    params: { owner: 'none' }, fileHeader: { 'Content-Type': 'multipart/form-data' },
  })
  .catch();

export const getCalculationsImports = (params) => network
  .get('/product_imports', { params })
  .then(paginatedMember)
  .catch(errorToast);

export const getCalculationImport = (id) => network
  .get(`/product_imports/${id}`)
  .catch();

export const importCalculation = (payload) => network
  .post('/product_imports', payload)
  .catch();

export const getCalculationImportValidate = (id) => network
  .get(`/product_imports/${id}/validate`)
  .catch();

export const getCalculationImportedFile = (id) => network
  .get(`/product_imports/${id}/import`)
  .catch();

export const getCalculationImportCanceled = (id) => network
  .get(`/product_imports/${id}/cancel`)
  .catch();

export const getPdisImports = (params) => network
  .get('/pdis_imports', { params })
  .then(paginatedMember)
  .catch(errorToast);

export const getPdisImport = (id) => network
  .get(`/pdis_imports/${id}`)
  .catch();

export const importPdis = (payload) => network
  .post('/pdis_imports', payload)
  .catch();

export const getPdisImportValidate = (id) => network
  .get(`/pdis_imports/${id}/validate`)
  .catch();

export const getPdisImportedFile = (id) => network
  .get(`/pdis_imports/${id}/import`)
  .catch();

export const getPdisImportCanceled = (id) => network
  .get(`/pdis_imports/${id}/cancel`)
  .catch();
