import _ from 'lodash';
import React, { memo, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { inputName } from 'utils';

import Pricing from 'components/Pricing/Pricing';

import * as S from './Table.styled';
import { useRealTimeResultsContext } from '../../hooks/realTimeResults.hooks';
import { Calculation } from '../../hooks/calculation.hooks';

const Table = ({ inputsByKey }) => {
  const { t } = useTranslation();
  const { results } = useRealTimeResultsContext();
  const { top, other, total } = results || {};
  const { result: calculation } = useContext(Calculation);

  // not sure if needed, but checking first for every possible table header
  const headers = (_.sortBy(_.uniq(_.flatten(
    [..._.keys(other),
      ..._.values(top).map(v => _.keys(v)),
    ],
  ))));

  return (
    <S.StyledTable>
      <tbody>
        <S.TableRow>
          <S.TableHeader>Material</S.TableHeader>
          {headers.map(h => <S.TableHeader key={h}>{h.toUpperCase()}</S.TableHeader>)}
        </S.TableRow>

        {_.map(_.keys(top), key => inputsByKey[key] && (
          <S.TableRow key={key} total={top[key].total}>
            <S.TableData>{inputName(inputsByKey[key])}</S.TableData>

            {_.chain(top[key])
              .keys()
              .sort()
              .map((section) => (
                <S.TableData key={section}>
                  <Pricing cost={top[key][section]} nilDigit="-" />
                </S.TableData>
              ))
              .value()}
          </S.TableRow>
        ))?.sort((a, b) => b?.props?.total - a?.props?.total)}

        {!_.isEmpty(other) && (
          <S.TableRow>
            <S.TableData>{t('remaining materials')}</S.TableData>

            {_.chain(total)
              .keys()
              .sort()
              .map(key => (
                <S.TableData key={key}>
                  <Pricing cost={other[key]} nilDigit="-" />
                </S.TableData>
              ))
              .value()}
          </S.TableRow>
        )}

        {total && (
          <S.TableRow totalRow>
            <S.TableData totalRow>
              {t(calculation?.method?.onePointScoreName)}
              {' '}
              (
              {calculation?.method?.onePointScoreUnit}
              )
            </S.TableData>

            {_.chain(total)
              .keys()
              .sort()
              .map(key => (
                <S.TableData key={key} totalRow>
                  <Pricing cost={total[key]} nilDigit="-" />
                </S.TableData>
              ))
              .value()}
          </S.TableRow>
        )}
      </tbody>
    </S.StyledTable>
  );
};

export default memo(Table);
