import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { companies as companiesServices } from 'services';

import ListItem from 'components/ListItem/ListItem';
import TopBar from 'components/TopBar/TopBar';
import { Loading } from 'components/Splash/Loading';
import Button from 'components/Button/Button';

import edit from 'assets/edit.svg';
import { styledButton } from 'config/style';
import * as S from 'routes/Calculations/Overview.styled';
import InfiniteScroll from 'react-infinite-scroll-component';
import TopbarActions from 'components/TopbarActions/TopbarActions';
import Name from 'components/TopBar/Name';

const Companies = ({
  input: inputValue,
  setTotalNumberOfRecords,
  isCorp,
  type,
  corporation,
  parentCorporation,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const user = useSelector(state => state.user);
  const {
    selectedCompany,
    id,
  } = useSelector(({ company }) => company || {});
  const [companies, setCompanies] = useState(null);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(null);
  const [sorting, setSorting] = useState('asc');
  const [sortingType, setSortingType] = useState(null);
  const [sortingCorporation, setSortingCorporation] = useState(null);
  const isEditable = id === selectedCompany?.id;

  useEffect(() => {
    const params = { owner: 'none' };
    if (sortingType) params['order[type]'] = sortingType;
    if (sortingCorporation) params['order[corporation]'] = sortingCorporation;
    if (sorting) params['order[name]'] = sorting;
    const corp = corporation || parentCorporation;
    if (inputValue) params.name = inputValue;
    if (type) params.corporation = type?.value;
    if (corp || (user?.selectedMembership?.view === 'Corporate Admin')) {
      params.parentCompany = corp?.id || user?.selectedMembership?.company?.id;
    }
    if (isCorp) params.noItemsPerPage = true;
    setTotalNumberOfRecords(null);
    setLoading(true);
    companiesServices
      .getAll({ params })
      .then(values => {
        // eslint-disable-next-line no-unused-expressions
        values?.companies?.shift();
        setCompanies(values.companies);
        setPage(values.nextPage);
        setTotalNumberOfRecords(values.totalItems);
      })
      .finally(() => setLoading(false));
  }, [inputValue, setTotalNumberOfRecords, type, corporation,
    parentCorporation, isCorp, user, sorting, sortingType, sortingCorporation]);

  const onEditItem = (data) => history
    .push(`/manage/companies/${data.id}/edit`, { routeName: data.name });

  const ListItemContent = ({ data }) => (
    <S.Option key="edit" isClickable={isEditable} onClick={() => isEditable && onEditItem(data)}>
      <div>
        <img alt="icon" src={edit} />
      </div>

      <S.StyledLabel isRegular>
        {t('edit')}
      </S.StyledLabel>
    </S.Option>
  );

  const fetchData = () => {
    if (page) {
      companiesServices
        .getNextPage(page)
        .then(values => {
          // eslint-disable-next-line no-unused-expressions
          values?.companies?.shift();
          setCompanies(values.companies);
          setPage(values.nextPage);
          setTotalNumberOfRecords(values.totalItems);
        });
    }
  };

  const companyAdmin = user?.selectedMembership?.view === 'Company Admin'
    || user?.selectedMembership?.view === 'Corporate Admin';

  const superAdmin = user?.selectedMembership?.view === 'Super Admin'
    || user?.selectedMembership?.view === 'Nibe Employee';

  const companiesList = () => (
    <S.OverviewRows id="overviewRowsId">
      <InfiniteScroll
        dataLength={companies?.length}
        hasMore={page}
        loader={Loading}
        next={fetchData}
        scrollableTarget="overviewRowsId"
        scrollThreshold={0.4}>
        {_.map(companies, (data) => (
          <ListItem
            key={data.id}
            corporation={
              superAdmin
              && data?.relatedCorporation
            }
            dropdownContent={(<ListItemContent data={data} />)}
            isCompaniesOverview
            isCorp={isCorp}
            name={data.name}
            to={{
              pathname: `/manage/companies/${data.id}/basic-information`,
              state: {
                routeName: data.name,
              },
            }}
            type={data?.corporation ? t('corporation') : t('company.label')}
          />
        ))}
      </InfiniteScroll>
    </S.OverviewRows>
  );

  if (!superAdmin && !companyAdmin) return <Redirect to="/" />;

  return (
    <>
      {(superAdmin || user?.selectedMembership?.view === 'Corporate Admin') && !isCorp && (
        <TopbarActions>
          <Button
            as={Link}
            style={{
              ...styledButton,
              position: 'absolute',
              right: 36,
            }}
            styling="select"
            to="/manage/companies/new">
            {t('add new company')}
          </Button>
        </TopbarActions>
      )}
      <TopBar>
        <div style={{
          display: 'flex',
        }}>
          <Name
            onOrderBy={order => {
              setSorting(order);
            }}
            order={sorting}
          />
          {!isCorp
          && (
            <>
              <div style={{ marginLeft: '25rem' }}>
                <Name
                  label="Type"
                  noSorting
                />
              </div>
              {user?.selectedMembership?.view === 'Super Admin'
              && (
                <div style={{ marginLeft: '15rem' }}>
                  <Name
                    inverted
                    label="corporation"
                    onOrderBy={() => {
                      if (sortingType) setSortingType(null);
                      if (!sortingCorporation) setSortingCorporation('asc');
                      else if (sortingCorporation === 'asc') setSortingCorporation('desc');
                      else setSortingCorporation(null);
                    }}
                    order={sortingCorporation}
                  />
                </div>
              )}
            </>
          )}
        </div>
      </TopBar>

      {!companies || loading
        ? Loading
        : companiesList()}
    </>
  );
};

export default Companies;
