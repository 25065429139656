import React, { useState } from 'react';
import Button from 'components/Button/Button';
import { useTranslation } from 'react-i18next';
import { reduxForm, Form, Field } from 'redux-form';
import { FormInput } from 'components/Input/Input';
import { required } from 'routes/Calculations/Detail/Basics/validation';
import { FormSimpleCKEditor } from 'components/SimpleCKEditor';
import { useHistory, useParams } from 'react-router-dom';
import MainModal from 'components/Modal';

const AnnouncementForm = ({
  pristine,
  invalid,
  submitting,
  handleSubmit,
  handleSubmitValues,
  isEdit,
  announcementDescription,
}) => {
  const { t } = useTranslation();
  const [hasEditedForm, setHasEditedForm] = useState(false);

  const history = useHistory();
  const { id } = useParams();

  const onChange = (editor, input) => {
    const data = editor.getData();
    input.onChange(data || null);
  };

  return (
    <>
      <Form onSubmit={handleSubmit((props) => {
        setHasEditedForm(true);
        return handleSubmitValues(props);
      })}>
        <Field
          component={FormInput}
          name="title"
          props={{
            name: 'title',
            placeholder: t('Title'),
          }}
          validate={isEdit ? null : required}
        />
        <Field
          component={FormSimpleCKEditor}
          name="description"
          props={{
            name: 'description',
            content: announcementDescription,
            onChange,
          }}
          validate={isEdit ? null : required}
        />
        <Field
          component={FormInput}
          name="publishedAt"
          props={{
            type: 'date',
            name: 'publishedAt',
          }}
          validate={isEdit ? null : required}
        />
        <Field
          component={FormInput}
          name="stickyUntil"
          props={{
            type: 'date',
            name: 'stickyUntil',
          }}
          validate={isEdit ? null : required}
        />
        <div style={{ display: 'flex' }}>
          <Button
            disabled={pristine || invalid}
            loading={submitting}
            style={{ marginRight: '24px' }}
            styling="select"
            type="submit">
            {t('save changes')}
          </Button>
          <Button
            onClick={() => history
              .push(isEdit
                ? `/manage/announcements/${id}` : '/manage/announcements')}
            styling="underline">
            {t('cancel')}
          </Button>
        </div>
      </Form>
      <MainModal
        content="prompt"
        exitModalContent={{
          navigateSideEffects: () => null,
        }}
        prompt
        promptCondition={!pristine && !hasEditedForm}
      />
    </>
  );
};

export default reduxForm({
  form: 'new-announcement',
  enableReinitialize: true,
})(AnnouncementForm);
