import _ from 'lodash';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAsync } from 'react-async-hook';
import { calculations, methods as methodsService, countries as countriesService } from 'services';
import { Placeholder } from 'components/Splash/Loading';
import AsideLayout from 'components/AsideLayout/AsideLayout';
import * as S from 'routes/GlobalStyle.styled';
import { useLocations } from 'hooks/location.hooks';
import { useWasteRegion } from 'hooks/wasteRegion.hooks';
import { useCustomUnitForLCA, useStandardUnitForLCA } from 'hooks/functionalUnit.hooks';
import { useCompanies } from 'hooks/companies.hooks';
import { useSelector } from 'react-redux';
import TemplateForm from './TemplateForm';

const NewTemplate = () => {
  const h = useHistory();
  const { t } = useTranslation();
  const locationHook = useLocations();
  const wasteRegions = useWasteRegion();
  const customUnitForLCA = useCustomUnitForLCA();
  const standardUnitForLCA = useStandardUnitForLCA();
  const operators = useCompanies({ operator: true });
  const companies = useCompanies();
  const currentCompany = useSelector(s => s.user?.selectedMembership?.company);

  const { result, loading } = useAsync(() => methodsService.all(), []);
  const {
    result: countries,
    loading: countriesLoading,
  } = useAsync(() => countriesService.getWasteRegions(), []);

  const loaderList = [
    loading,
    countriesLoading,
    operators.loading,
    companies.loading,
  ];

  const save = (values, isCustom) => {
    const templateInfo = {};

    if (values.Code) templateInfo.code = values.Code;
    if (values.name) templateInfo.name = values.name;
    if (values.location) templateInfo.location = values.location['@id'];
    if (values.productLifeTime) {
      templateInfo.productLifeTime = parseInt(values.productLifeTime, 10);
    }
    if (values.method) templateInfo.method = values.method['@id'];
    if (values.wasteRegion) templateInfo.wasteRegion = values.wasteRegion['@id'];
    if (values.wasteRegion) templateInfo.wasteScenarioSet = values.wasteRegion['@id'];

    templateInfo.productType = values.productType && values.productType['@id'];
    if (values?.productType?.id === 3) {
      templateInfo.distanceToConstructionSite = _.parseInt(values.distanceToConstructionSite);
      templateInfo.transportProfileToConstructionSite = values
        .transportProfileToConstructionSite['@id'];
    }
    /* if is standard else custom. */
    if (!isCustom) {
      templateInfo.functionalUnit = values.functionalUnit['@id'];
    } else {
      templateInfo.functionalUnitTitle = values.functionalUnitTitle;
      templateInfo.functionalUnitDescription = values.functionalUnitDescription;
      templateInfo.standardUnit = values.standardUnit['@id'];
    }
    templateInfo.customFunctionalUnit = isCustom;

    templateInfo.predefinedConstructionWaste = values.predefinedConstructionWaste.value;
    if (templateInfo.predefinedConstructionWaste === 0) {
      templateInfo.predefinedConstructionWaste = null;
      templateInfo.constructionWaste = parseFloat(values.constructionWaste);
    } else {
      templateInfo.constructionWaste = templateInfo.predefinedConstructionWaste;
    }

    if (!values.method.publisher && values.publisher) {
      templateInfo.publisher = values.publisher['@id'] || values.publisher;
    }

    if (!values.method.operator && values.operator) {
      templateInfo.operator = values.operator['@id'] || values.operator;
    }

    return calculations.createTemplate(templateInfo).then(res => {
      h.push(`/calculations/templates/${res?.id}`);
    });
  };

  const [hidePanel, setHidePanel] = useState(true);

  return (
    <AsideLayout
      hidePanel={hidePanel}
      LeftPane={(
        <p style={{ padding: '2.5rem' }}>
          {t('hint new template')}
        </p>
      )}
      setHidePanel={setHidePanel}>
      <S.Background isEditable>
        <S.HeaderTitle>{t('new template')}</S.HeaderTitle>
        <Placeholder loaders={loaderList}>
          <div style={{ paddingLeft: hidePanel ? '0' : '0', transition: '1s' }}>
            <TemplateForm
              companies={companies.data || []}
              customUnitForLCA={customUnitForLCA}
              locationHook={locationHook}
              methods={result?.methods}
              operators={operators.data || []}
              ownerCompany={companies.data
                ?.find(c => c['@id'] === currentCompany?.['@id']) || currentCompany}
              saveForm={save}
              standardUnitForLCA={standardUnitForLCA}
              wasteRegions={wasteRegions}
              wasteScenarioSets={countries}
            />
          </div>
        </Placeholder>
      </S.Background>
    </AsideLayout>
  );
};

export default NewTemplate;
