import React from 'react';
import {
  useRouteMatch,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';

import { useCompanies } from 'hooks/companies.hooks';
import ViewBasics from './ViewBasics';
import Editor from './Editor';

const CalculationBasics = () => {
  const { path } = useRouteMatch();
  const companies = useCompanies();
  const operators = useCompanies({ operator: true });

  return (
    <Switch>
      <Route component={() => <Redirect to="basics/view" />} exact path={path} />

      <Route exact path={`${path}/view`}>
        <ViewBasics companies={companies} />
      </Route>

      <Route exact path={`${path}/edit`}>
        <Editor companies={companies} operators={operators} />
      </Route>
    </Switch>
  );
};

export default CalculationBasics;
