import React, { memo } from 'react';
import { useSelector, connect } from 'react-redux';
import { change, clearFields } from 'redux-form';
import Button from 'components/Button/Button';
import Input from 'components/Input/Input';
import { useTranslation } from 'react-i18next';
import { withLabels } from 'components/Field/field';
import { alertToast } from 'config/toast';
import { InputWidth } from 'config/style';

const DEFAULT_MAX_SIZE = 2_000_000;

const FileUpload = ({
  fileName,
  calculationKey,
  url,
  content,
  downloadFile = null,
  deleteFile,
  onChange,
  input,
  importName,
  maxSize,
  ...props
}) => {
  const { t } = useTranslation();
  const form = useSelector(state => state.form);
  const formName = Object.keys(form)?.[0];
  const [loading, setLoading] = React.useState(null);
  const inputRef = React.useRef(undefined);

  React.useEffect(() => {
    if (inputRef.current && (input?.value?.[0]?.size >= (maxSize || DEFAULT_MAX_SIZE))) {
      alertToast(
        t('the file you are trying to upload exceeds', {
          maxSize: Number(
            Math.max(1, (maxSize || DEFAULT_MAX_SIZE) / 1_000_000),
          ).toFixed(0),
        }),
        'error',
      );
      inputRef.current.value = null;
      props.clearFields(formName, false, true, calculationKey || importName || props.name);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [input]);

  if (onChange && input.value && input.value.length) onChange();

  const handleDownload = () => {
    if (downloadFile) {
      setLoading(true);
      downloadFile(url, content?.originalFilename, setLoading);
    }
  };

  return fileName || url ? (
    <div>
      {fileName && <p style={{ margin: '1rem 0 1.2rem', fontSize: '.875rem' }}>{fileName}</p>}
      <div style={{ display: 'flex' }}>
        <Button
          loading={loading}
          onClick={handleDownload}
          style={{ marginRight: '1.5rem' }}
          styling="select">
          {t('download')}
        </Button>
        {deleteFile && (
        <Button onClick={deleteFile} styling="select">
          {t('delete this file')}
        </Button>
        )}
      </div>
    </div>
  ) : (
    <Input
      innerRef={inputRef}
      input={input}
      style={{ width: InputWidth, border: '1px dashed rgba(0, 0, 0, 0.13)' }}
      type="file"
      {...props}
    />
  );
};

export const FormFileUpload = connect(null, { change, clearFields })(withLabels(FileUpload));
export default connect(null, { change, clearFields })(memo(FileUpload));
