import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { locations as locationsService } from 'services';

import Button from 'components/Button/Button';
import ListItem from 'components/ListItem/ListItem';
import { confirmationModalSetup } from 'components/Modal/ModalContent/Delete';
import MainModal from 'components/Modal';
import TopBar from 'components/TopBar/TopBar';
import Name from 'components/TopBar/Name';
import Spinner from 'components/Spinner';
import ID from 'components/TopBar/ID';
import TopbarActions from 'components/TopbarActions/TopbarActions';
import InfiniteScroll from 'react-infinite-scroll-component';
import deleteIcon from 'assets/delete.svg';
import edit from 'assets/edit.svg';
import * as S from 'routes/Calculations/Overview.styled';

const Locations = ({
  input: inputValue, city: cityValue,
  country: countryValue, company: companyValue, setTotalNumberOfRecords,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { selectedCompany, id: companyId } = useSelector(({ company }) => company || {});
  const user = useSelector(state => state.user);
  const [locations, setLocations] = useState(null);
  const [confirmationModal, setConfirmationModal] = useState(null);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(null);
  const [sorting, setSorting] = useState('asc');
  const [sortingCode, setSortingCode] = useState(null);
  const isEditable = companyId === selectedCompany?.id;

  useEffect(() => {
    const params = {};

    if (inputValue) params.name = inputValue;
    if (cityValue) params.city = cityValue;
    if (countryValue) params.country = countryValue.value;
    if (companyValue) params.owner = companyValue.id;
    if (sortingCode) params['order[code]'] = sortingCode;
    if (sorting) params['order[name]'] = sorting;

    setTotalNumberOfRecords(null);
    setLoading(true);
    locationsService
      .all(params)
      .then((values) => {
        setLocations(values.locations);
        setPage(values.nextPage);
        setTotalNumberOfRecords(values.totalItems);
        return values;
      })
      .finally(() => setLoading(false))
      .catch(_.noop);
  }, [inputValue, cityValue, countryValue, companyValue,
    sorting, setTotalNumberOfRecords, sortingCode]);

  const onDeleteItem = id => confirmationModalSetup(
    setConfirmationModal,
    (closeModal) => locationsService
      .deleteLocation(id)
      .then(() => {
        const tempLocations = _.filter(locations, location => location.id !== id);
        setLocations(tempLocations);
        return closeModal();
      }),
  );
  const onEditItem = (data) => history
    .push(`/locations-&-suppliers/locations/${data.id}/edit`, { routeName: data.name });

  const ListItemContent = ({ data }) => (
    <>
      <S.Option
        key="edit"
        isClickable={isEditable && user?.selectedMembership?.view !== 'Viewer'}
        onClick={
          () => isEditable && user?.selectedMembership?.view !== 'Viewer' && onEditItem(data)
        }>
        <div>
          <img alt="icon" src={edit} />
        </div>

        <S.StyledLabel isRegular>
          {t('edit')}
        </S.StyledLabel>
      </S.Option>

      <S.Option
        key="delete"
        isClickable={user?.selectedMembership?.view !== 'Viewer'}
        onClick={() => user?.selectedMembership?.view !== 'Viewer' && onDeleteItem(data.id)}>
        <div>
          <img alt="icon" src={deleteIcon} />
        </div>

        <S.StyledLabel color="#BF2E00" isRegular>
          {t('delete')}
        </S.StyledLabel>
      </S.Option>
    </>
  );

  const fetchData = () => {
    if (!page) return undefined;
    return locationsService
      .getNextPageLocations(page)
      .then((values) => {
        setPage(values.nextPage);
        setTotalNumberOfRecords(values.totalItems);
        setLocations(locations.concat(values.locations));
        return values;
      });
  };

  const locationsList = () => (
    <S.OverviewRows id="overviewRowsId">
      <InfiniteScroll
        dataLength={locations?.length}
        hasMore={page}
        loader={<Spinner />}
        next={fetchData}
        scrollableTarget="overviewRowsId"
        scrollThreshold={0.4}
        style={{ minHeight: '50vh' }}>
        {_.map(locations, (data) => (
          <ListItem
            key={data?.id}
            code={data?.code}
            description={[data?.address, data?.postalCode, data?.city, data?.country].join(', ')}
            dropdownContent={(<ListItemContent data={data} />)}
            hideDropdown={user?.selectedMembership?.view === 'Viewer'}
            name={data?.name}
            to={{
              pathname: `/locations-&-suppliers/locations/${data?.id}`,
              state: {
                routeName: data?.name,
              },
            }}
          />
        ))}
      </InfiniteScroll>
    </S.OverviewRows>
  );

  return (
    <>
      <TopbarActions>
        {user?.selectedMembership?.view !== 'Viewer'
          && (
            <Button
              as={Link}
              styling="select"
              to="/locations-&-suppliers/locations/new">
              {t('add new location')}
            </Button>
          )}
      </TopbarActions>

      <TopBar style={{ paddingRight: '11.5rem' }}>
        <Name
          onOrderBy={order => {
            setSorting(order);
          }}
          order={sorting}
        />
        <ID
          inverted
          onOrderBy={() => {
            if (!sortingCode) setSortingCode('asc');
            else if (sortingCode === 'asc') setSortingCode('desc');
            else setSortingCode(null);
          }}
          order={sortingCode}
        />
      </TopBar>

      {!locations || loading
        ? (
          <div
            style={{
              height: 'calc(100vh - 4rem)',
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            <Spinner />
          </div>
        )
        : locationsList()}

      <MainModal
        confirmationModalInfo={confirmationModal}
        content="delete"
        isModalOpen={confirmationModal}
        setIsModalOpen={setConfirmationModal}
      />
    </>
  );
};

export default Locations;
