import React from 'react';

const Rejected = ({ color, ...props }) => (
  <svg fill="none" height="17" viewBox="0 0 20 17" width="20" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      // eslint-disable-next-line max-len
      d="M12.2521 12.4299V13.2769C12.2521 13.7103 12.624 14.0649 13.0785 14.0649C13.5331 14.0649 13.905 13.7103 13.905 13.2769V12.4299C14.2975 12.1738 14.5248 11.7404 14.5248 11.3071C14.5248 10.5388 13.8843 9.92816 13.0785 9.92816C12.2727 9.92816 11.6322 10.5388 11.6322 11.3071C11.6322 11.7601 11.8802 12.1738 12.2521 12.4299ZM19.1736 6.95365H18.2645H16.6116H10.3306V4.92468C10.3306 2.20626 8.01653 0 5.16529 0C2.31405 0 0 2.20626 0 4.92468V6.95365H1.65289V4.92468C1.65289 3.073 3.22314 1.5759 5.16529 1.5759C7.10744 1.5759 8.67769 3.073 8.67769 4.92468V6.95365H7.91322H7.00413C6.54959 6.95365 6.17769 7.30823 6.17769 7.7416V16.2121C6.17769 16.6454 6.54959 17 7.00413 17H19.1529C19.6074 17 19.9793 16.6454 19.9793 16.2121V7.7416C20 7.30823 19.6281 6.95365 19.1736 6.95365ZM18.3471 15.4438H7.83058V8.54925H18.3264V15.4438H18.3471Z"
      fill={color || '#BD5230'}
    />
  </svg>
);

export default Rejected;
