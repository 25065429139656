import React, { useEffect } from 'react';
import { Link, Redirect, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useAsync, useAsyncCallback } from 'react-async-hook';

import { companies, users } from 'services';

import Button from 'components/Button/Button';
import { Loading, Placeholder } from 'components/Splash/Loading';
import { showFile } from 'utils';

import * as S from 'routes/GlobalStyle.styled';

const AccountDetails = () => {
  const { t } = useTranslation();
  const { id: userId } = useParams();
  const auth = useSelector(state => state.user);
  const id = userId || auth?.id;
  const { result: user, loading } = useAsync(() => id && users.getUser(id), [id]);

  const { result: company, loading: loadingCompany, execute } = useAsyncCallback(
    () => auth?.selectedMembership?.company?.['@id']
      && companies.getCompany(auth?.selectedMembership?.company?.['@id']),
  );

  const isEditable = true;

  const [autographImage, setAutographImage] = React.useState(null);

  useEffect(() => {
    if (user) execute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const loaders = [];

  if (user?.autographImage?.contentUrl) {
    loaders.push(autographImage === null);
  }

  React.useEffect(() => {
    if (user?.autographImage?.contentUrl) {
      loaders.push(autographImage === null);
      showFile(
        `${process.env.REACT_APP_API_URL}${user?.autographImage?.contentUrl}`,
      ).then(setAutographImage);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const [detailsColumnId, setDetailsColumnId] = React.useState(null);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(() => {
    const width = document.getElementById('detailsColumnId')?.offsetWidth;
    if (width && (detailsColumnId !== width)) setDetailsColumnId(width);
  });

  if (id !== auth.id) {
    return <Redirect to="/" />;
  }

  if ((loading && !user) || loadingCompany) return Loading;

  return (
    <Placeholder loaders={loaders}>
      <S.Bar>
        {t(userId ? 'user profile' : 'account')}
        <div style={{ display: 'flex' }}>
          <Button
            as={Link}
            style={{ margin: 0, padding: '.75rem auto', pointerEvents: !isEditable && 'none' }}
            styling="select"
            to={userId ? isEditable && `/manage/users/${userId}/edit` : '/user-profile/edit'}>
            {t(userId ? 'edit user' : 'edit')}
          </Button>
        </div>
      </S.Bar>

      <S.Container>
        <S.ItemsContainer>
          <div id="detailsColumnId" style={{ marginRight: '6.87rem' }}>
            <S.ItemWrapper>
              <S.LabelText>{t('Name')}</S.LabelText>
              <S.Details>{user?.name}</S.Details>
            </S.ItemWrapper>

            <S.ItemWrapper>
              <S.LabelText>{t('Email')}</S.LabelText>
              <S.Details>{user?.email}</S.Details>
            </S.ItemWrapper>

            {autographImage && <S.LabelText>{t('autograph.label')}</S.LabelText>}
          </div>

          <div>
            <S.ItemWrapper>
              <S.LabelText>{t('Language')}</S.LabelText>
              <S.Details>{t(user?.locale)}</S.Details>
            </S.ItemWrapper>

            <S.ItemWrapper>
              <S.LabelText>{t('Company')}</S.LabelText>
              <S.Details>{t(company?.name)}</S.Details>
            </S.ItemWrapper>

            <S.ItemWrapper>
              <S.LabelText>{t('Password')}</S.LabelText>
              <S.Details>***********</S.Details>
            </S.ItemWrapper>
          </div>

        </S.ItemsContainer>
        {autographImage && (
          <S.ItemWrapper style={{ marginTop: '-2rem' }}>
            <img
              alt="autographImage"
              src={autographImage}
              style={{
                width: `${detailsColumnId}px`,
              }}
            />
          </S.ItemWrapper>
        )}
      </S.Container>
    </Placeholder>
  );
};

export default AccountDetails;
