import styled from 'styled-components/macro';
import { DARK_BLUE, AsideWidth, NAV_HEIGHT } from 'config/style';

export const Aside = styled.div`
  height: calc(100vh - (2 * ${NAV_HEIGHT}));
  width: ${AsideWidth};
  background: white;
  box-shadow: 0 4px 14px rgba(0, 0, 0, 0.2);
  position: relative;
  transition: 1s;
  display: flex;
  flex-direction: column;
  z-index: 1;
  color: ${DARK_BLUE};
  transform: translate(${(props) => (props.isVisible ? 0 : `calc(2rem - ${AsideWidth})`)});
`;

export const HideAside = styled.div`
  width: 2.4rem;
  height: 2.4rem;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  cursor: pointer;
  position: absolute;
  bottom: 7%;
  right: -1.2rem;
  box-shadow: 4px 0 14px rgba(0, 0, 0, 0.2);
`;

export const AsideArrow = styled.img`
  transform: rotate(${(props) => (props.isVisible ? 0 : '180deg')});
  transition: 1s;
`;

export const InputWrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

export const RadioContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2.13rem;
`;

export const Results = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  margin-top: 3rem;
  margin-right: 5px;
`;

export const ClearFilter = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  margin-top: 1.13rem;
  cursor: pointer;
  text-decoration: underline;
`;

export const FilterSection = styled.div`
  border-bottom: 1px solid #e4e4e4;
  padding: 1.5rem 2.5rem;
  display: flex;
  align-items: center;
  height: 4rem;
`;

export const FilterText = styled.span`
  color: #969bae;
  font-size: 1rem;
  margin-left: 1rem;
`;

export const FilterWrapper = styled.div`
  padding: 1.13rem 2.5rem;
  border-top: 1px solid #e4e4e4;
`;

export const Padding = styled.div`padding: ${({ padding }) => (padding || '2rem 2.5rem')}`;
