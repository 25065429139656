import React from 'react';
import { useDispatch } from 'react-redux';
import { setBreadcrumb } from 'state/sidebar/sidebar.actions';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Button from 'components/Button/Button';
import { ReadOnlyField } from 'components/Field';
import { Placeholder } from 'components/Splash/Loading';

import { ViewModeContainer, TitleBar, GroupFields } from 'routes/GlobalStyle.styled';

const ViewPdisBasics = ({ pdis, loading }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (pdis?.code) dispatch(setBreadcrumb(pdis?.code));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pdis]);

  return (
    <Placeholder loaders={[loading]}>
      <TitleBar>
        {t('predefined input set basics')}

        <Button
          as={Link}
          styling="select"
          to={`/calculations/predefined-input-sets/${pdis?.id || 1}/edit`}>
          {t('edit')}
        </Button>
      </TitleBar>

      <ViewModeContainer style={{ padding: '1.87rem 0', display: 'unset' }}>
        <GroupFields>
          <ReadOnlyField name="Code">
            {pdis?.code}
          </ReadOnlyField>
          <ReadOnlyField name="name">
            {pdis?.name}
          </ReadOnlyField>
          <ReadOnlyField name="Unit">
            {pdis?.unit}
          </ReadOnlyField>
          <ReadOnlyField name="methodLabel">
            {pdis?.method?.name}
          </ReadOnlyField>
          <ReadOnlyField name="WasteScenarioSet">
            {pdis?.wasteScenarioSet?.name}
          </ReadOnlyField>
        </GroupFields>
      </ViewModeContainer>
    </Placeholder>
  );
};

export default ViewPdisBasics;
