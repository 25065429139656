import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import React, { memo, useContext } from 'react';
import { useSelector } from 'react-redux';
import { useAsync } from 'react-async-hook';
import * as moment from 'moment';

import { Calculation } from 'hooks/calculation.hooks';
import Spinner from 'components/Spinner';
import * as Styled from 'routes/Calculations/Detail/DetailDashboard/DetailDashboard.styled';
import { journals } from './Logs.services';
import * as S from './Logs.styled';
import { FileDownloadButton } from '../FileDownloadButton';

const Logs = ({ rowsToShow, noTitle }) => {
  const { t } = useTranslation();
  const user = useSelector(state => state.user);
  const calculation = useContext(Calculation);
  const { id: calculationId, createdAt } = calculation.result;
  const { result = {}, loading } = useAsync(journals, [user, calculationId]);
  const { data = [] } = result;
  const initialLog = {
    id: 'created',
    message: t('Calculation has been created'),
    createdAt,
  };

  if (loading) return <Spinner />;

  const values = _.chain(data)
    .reverse()
    .concat(initialLog)
    .map((log) => (
      <S.Row key={log.id}>
        {log.message && <p>{log.message}</p>}
        {/* eslint-disable-next-line react/no-danger */}
        {log.reviewStatement && <div dangerouslySetInnerHTML={{ __html: log.reviewStatement }} />}
        {log.createdAt
          && (
            <S.Data>
              {moment(log.createdAt).format('DD-MM-YYYY HH:mm')}
            </S.Data>
          )}
        {log?.validationTables
          && (
            <FileDownloadButton {...log.validationTables} />
          )}
      </S.Row>
    ))
    .value();

  return (
    <div>
      {!noTitle && (
        <Styled.TitleSection>
          Logs
        </Styled.TitleSection>
      )}
      <S.Wrapper>
        {rowsToShow
          ? values?.slice(0, rowsToShow)
          : values}
      </S.Wrapper>
    </div>
  );
};

export default memo(Logs);
