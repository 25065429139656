import React from 'react';

/*
  Some of the CSS of this component are in the root(raw) HTML of the project
*/

const Splash = () => (
  <aside className="suspense-loading-bg" style={{ opacity: 1 }}>
    <h1>
      Loading...
    </h1>
  </aside>
);

export default Splash;
