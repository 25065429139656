import React, { useState } from 'react';
import * as S from '../../../../../GlobalStyle.styled';
import arrow from '../../../../../../assets/arrow_show.svg';

export const SemiFinishedRow = ({
  blueBackground, forSubInputs,
  product: { amount, productName },
  funcUnit, hideArrow = false, forceExpanded = false, renderActions, children,
}) => {
  const [expanded, setExpanded] = useState(false);
  return (
    <S.RowContainer blueBackground={blueBackground}>
      <S.SimpleRow onClick={() => setExpanded(!expanded)}>
        <S.Expand>
          {!forSubInputs && `${amount}${funcUnit} ${productName || ''}`}

          {forSubInputs && `${productName} ${amount}${funcUnit}`}
        </S.Expand>
        <div style={{ display: 'flex', alignItems: 'center', marginLeft: '2rem' }}>

          {renderActions && renderActions()}
          {children && !hideArrow
        && (
          <S.Img
            alt="icon"
            src={arrow}
            style={{
              marginLeft: '29px',
              transform: (forceExpanded || expanded) && 'rotate(180deg)',
            }}
          />
        )}
        </div>
      </S.SimpleRow>
      {(forceExpanded || expanded) && children}
    </S.RowContainer>
  );
};
