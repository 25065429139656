const extractField = (which, calculation, template, method) => {
  if (method?.[which]) {
    return method[which];
  }
  if (template) {
    if (template.method?.[which]) {
      return template.method?.[which];
    }
    if (template[which]) {
      return template[which];
    }
  }

  if (calculation) {
    if (calculation.method?.[which]) {
      return calculation.method[which];
    }

    if (calculation[which]) {
      return calculation[which];
    }
  }
};

export const findPublisherOperator = (
  which,
  calculation,
  template,
  method,
  companies,
) => {
  const result = extractField(which, calculation, template, method);

  if (companies && typeof result === 'string') {
    return companies.find(c => c?.['@id'] === result) || result;
  }

  if (companies && typeof result === 'object' && !result.name) {
    return companies.find(c => c?.['@id'] === result['@id']) || result;
  }

  return result;
};
