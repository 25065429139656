import React, {
  createContext, useContext, useEffect, useState,
} from 'react';
import { useParams } from 'react-router-dom';
import { useAsyncCallback } from 'react-async-hook';
import { calculations } from '../services';

const TemplateContext = createContext({ result: null, loading: true, execute: () => null });

export const useTemplateContext = () => useContext(TemplateContext);

const useTemplate = () => {
  const { id: templateId } = useParams();
  const [cachedTemplate, cache] = useState();
  const templateResult = useAsyncCallback(() => calculations.getTemplateDetail(templateId), []);

  useEffect(() => {
    if (templateId) templateResult.execute();
  }, [templateId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (templateResult.result) {
      if (Number(templateId) === templateResult.result.id) {
        cache(templateResult.result);
      } else {
        cache(undefined);
      }
    }
  }, [templateResult.result, templateId]);

  useEffect(() => {
    if (cachedTemplate) {
      if (cachedTemplate.id !== Number(templateId)) {
        cache(undefined);
      }
    }
  }, [cachedTemplate, templateId]);

  return { ...templateResult, result: cachedTemplate || templateResult.result };
};

export const WithTemplateContext = ({ children }) => {
  const value = useTemplate();

  return <TemplateContext.Provider value={value}>{children}</TemplateContext.Provider>;
};
