import React from 'react';
import { PredefinedInputSetRow } from '../Rows/PredefinedInputSetRow';
import CalculationInputItem from '../CalculationInputItem';

export const PredefinedInputSetInputs = ({
  code, amount, name, unit, inputs, blueBackground, forceExpanded, updateContext,
}) => (
  <PredefinedInputSetRow
    blueBackground={blueBackground}
    forceExpanded={forceExpanded}
    pdis={{
      code, name, unit, amount,
    }}>
    <ul style={{ paddingBottom: 1.1 }}>
      {inputs.map(input => (
        <CalculationInputItem
          key={input?.id}
          blueBackground={!blueBackground}
          expandDetails={forceExpanded}
          input={{ ...input }}
          isSfp
          updateContext={updateContext}
        />
      ))}
    </ul>
  </PredefinedInputSetRow>
);
