import React, { useState, useEffect } from 'react';
import { Prompt, useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Button from 'components/Button/Button';
import { ButtonsWrapper } from '../styled';

export const ExitModalContent = ({
  title,
  navigateWithoutSaveFunc,
  closeModalFunc,
}) => {
  const { t } = useTranslation();

  return (
    <div>
      <p>{title}</p>

      <ButtonsWrapper>
        <Button
          onClick={navigateWithoutSaveFunc}
          style={{ marginRight: '24px' }}
          styling="white">
          {t('yes')}
        </Button>
        <Button onClick={closeModalFunc} styling="underline">
          {t('cancel')}
        </Button>
      </ButtonsWrapper>
    </div>
  );
};

const PromptModal = ({
  condition,
  content,
  navigateSideEffects,
}) => {
  const history = useHistory();
  const { pathname: currentPath } = useLocation();
  const [promptPath, setPromptPath] = useState(null);
  const [hideModal, setHideModal] = useState(false);

  useEffect(() => {
    setPromptPath(null);
    setHideModal(false);
  }, [hideModal]);

  return (
    <>
      <Prompt
        message={({ pathname: newPath }) => {
          const checkPath = currentPath === newPath;
          if (!checkPath) setPromptPath(newPath);
          return checkPath ? null : !!promptPath;
        }}
        when={condition}
      />

      {promptPath && content({
        saveAndNavigateFunc: () => {
          setHideModal(true);
          history.push(promptPath);
        },
        navigateWithoutSaveFunc: () => {
          setHideModal(true);
          navigateSideEffects();
          history.push(promptPath);
        },
        closeModalFunc: () => setPromptPath(null),
      })}
    </>
  );
};

export default PromptModal;
