import styled from 'styled-components';
import SearchIcon from 'assets/search.svg';

export const Wrapper = styled.form`
  width: 20rem;
  margin: 0 20px;
  background-color: white;
`;

export const Input = styled.input`
  width: 100%;
  border: 1px solid #e4e4e4;
  font-size: 12px;
  border-radius: 2px;
  height: 100%;
  padding: .6rem 1rem .6rem 2.43rem;
  background: url(${SearchIcon}) no-repeat;
  background-position: 1rem center;
  color: rgba(129, 129, 129, 0.7);

  &:focus {
    outline: none;
  }
`;
