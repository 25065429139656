import _ from 'lodash';
import { useEffect, useState } from 'react';

import { countries } from 'services';

import {
  wasteScenarioInputMethodsOpt as wsimOptions,
  predefinedConstructionWasteOpt as pcwOptions,
} from 'routes/Calculations/Detail/Advanced/Editor.helpers';

export const useWasteRegion = (calculation = {}) => {
  const {
    wasteScenarioSet: defaultWastelandId,
    wasteScenarioProductionWaste: defaultWasteScenarioProductionWaste,
    wasteScenario: defaultWasteScenario,
    wasteScenarioInputMethod: defaultWasteScenarioInputMethod,
    productionWasteInputMethod: defaultProductionWasteInputMethod,
    productionWastePercentage: defaultProductionWastePercentage,
    predefinedConstructionWaste: defaultPredefinedConstructionWaste,
    constructionWaste: defaultConstructionWaste,
  } = calculation;

  const [wasteRegionsList, setWasteRegionsList] = useState([]);
  const [wasteland, setWasteland] = useState(undefined);
  const [loading, setBusy] = useState(true);
  const [productionWastePercentage, setWastePercentage] = useState(
    defaultProductionWastePercentage,
  );
  const [predefinedConstructionWaste, setPredefinedConstructionWaste] = useState(null);
  const [constructionWaste, setConstructionWaste] = useState(defaultConstructionWaste);

  const [productionWaste, setProductionWaste] = useState(null);

  const [scenario, setScenario] = useState(defaultWasteScenarioInputMethod || 2); // (1 = raw metarial, 2 = product)
  const [scenarios, setScenarios] = useState([]);
  const [productionWasteInputMethod, setInputMethod] = useState(
    defaultProductionWasteInputMethod,
  );
  const [wasteScenario, setWasteScenario] = useState(0);

  const handleEvent = (method) => ({ target: { value } }) => method(value);

  useEffect(
    () => {
      setBusy(true);
      countries
        .getWasteRegions()
        .then((r) => {
          setWasteRegionsList(r);
          return r;
        })
        .then((r) => {
          let selected;
          if (defaultWastelandId) {
            selected = _.find(r, (l) => l.id === defaultWastelandId?.id);
          } else {
            selected = _.head(r);
          }
          setWasteland(selected);
        })
        .finally(() => setBusy(false));
    },
    [defaultWastelandId],
  );

  useEffect(() => {
    setBusy(true);
    countries.getWasteScenarios({ params: { owner: 'none', itemsPerPage: 999999 } })
      .then(setScenarios).finally(() => setBusy(false));
  }, []);

  useEffect(
    () => {
      if (defaultWasteScenarioProductionWaste) {
        setProductionWaste(
          _.find(scenarios, (scene) => (scene
            && scene['@id']) === defaultWasteScenarioProductionWaste),
        );
      }
      if (defaultWasteScenario) {
        setWasteScenario(_.find(scenarios, (scene) => (scene
          && scene['@id']) === defaultWasteScenario));
      }
    },
    [defaultWasteScenarioProductionWaste, defaultWasteScenario, scenarios],
  );

  useEffect(
    () => {
      if (defaultProductionWasteInputMethod) {
        setInputMethod(_.find(wsimOptions, (o) => o.value === defaultProductionWasteInputMethod));
      }
    },
    [defaultProductionWasteInputMethod],
  );

  useEffect(
    () => {
      if (defaultPredefinedConstructionWaste || defaultPredefinedConstructionWaste === 0) {
        setPredefinedConstructionWaste(
          _.find(pcwOptions, ({ value }) => value === defaultPredefinedConstructionWaste),
        );
      }
    },
    [defaultPredefinedConstructionWaste],
  );

  return {
    selected: wasteland, // api
    set: setWasteland,

    list: wasteRegionsList,
    loading,

    scenarios,

    scenario, // api
    setScenario: ({ target: { value } }) => setScenario(_.parseInt(value)),

    productionWaste,
    setProductionWaste,
    wasteScenario,
    setWasteScenario,

    productionWasteInputMethod, // api
    setInputMethod,

    productionWastePercentage,
    setWastePercentage: handleEvent(setWastePercentage),

    predefinedConstructionWaste,
    setPredefinedConstructionWaste,

    constructionWaste,
    setConstructionWaste: handleEvent(setConstructionWaste),
  };
};
