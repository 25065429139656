import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #F4F8FA;
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #FFFFFF;
  padding: 21px 40px 35px 43px;
  border: 1px solid #FFFFFF;
  box-sizing: border-box;
  box-shadow: 0 0 10px rgba(57, 57, 57, 0.2);
  border-radius: 2px;
`;

export const MajorContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 60px 80px 0 80px;
  margin-bottom: 38px;
`;

export const UserName = styled.span`
  font-weight: bold;
`;

export const SignOut = styled.span`
  font-size: 14px;
  line-height: 21px;
  text-decoration: underline;
  cursor: pointer;
`;

export const MembershipName = styled.span`
  font-size: 14px;
  line-height: 21px;
`;

export const Name = styled.span`
  font-size: 14px;
  line-height: 21px;
  margin-top: 1.5rem;
`;

export const Role = styled.div`
  font-size: 12px;
  line-height: 18px;
`;

export const CardContainer = styled.div`
  width: ${({ maxFour }) => (maxFour ? '397px' : 'calc((100% - 80px) / 3)')};
  height: 75px;
  background: #FDFEFF;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.15);
  display: flex;
  ${({ noFlex }) => (!noFlex ? null : css`flex: 0 0 22.36%`)};
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding: 17px 20px 18px 20px;
  margin-top: 33px;
  cursor: pointer;
  
  :not(:nth-child(3n)) {
    margin-right: ${({ maxFour }) => (maxFour ? '0px' : '40px')};
  }
`;

export const Options = styled.img`
  cursor: pointer;
`;

export const FooterContainer = styled.div`
  margin-bottom: 2rem;
  border-top: 1px solid #E4E4E4;
  padding-top: 1.625rem;
`;

export const Title = styled.div`
  font-weight: 500;
  font-size: 26px;
  line-height: 38px;
`;

export const List = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
`;
