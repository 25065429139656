import React, { useEffect, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
// import { useAsync } from 'react-async-hook';
import {
  reduxForm, Form, Field, formValues,
} from 'redux-form';
import { useSelector } from 'react-redux';

// import { companies as companiesServices } from 'services';
import { isValid, isEmailValid } from 'utils/validation';

import Button from 'components/Button/Button';
import { FormFileUpload } from 'components/FileUpload/FileUpload';
import { FormInput } from 'components/Input/Input';
import { FormSelect } from 'components/Select/Select';
import { FormSimpleContentInfo } from 'components/ContentInfo/ContentInfo';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { required } from 'routes/Calculations/Detail/Basics/validation';
import { downloadFile } from 'utils';
import MainModal from 'components/Modal';

const UserForm = ({
  autograph,
  deleteFile,
  differentId,
  handleSubmit,
  handleSubmitValues,
  invalid,
  // isDefaultUser,
  isEdit,
  languages,
  pathname,
  // permissions,
  pristine,
  reset,
  reviewer,
  reviewerOptions,
  status,
  submitting,
  user,
  completeProfile,
  onDeleteItem,
}) => {
  const { t } = useTranslation();
  const { url } = useRouteMatch();
  const { selectedMembership } = useSelector(state => state.user);
  const [hasEditedForm, setHasEditedForm] = useState(false);

  const isUserProfile = url?.includes('user-profile');

  const history = useHistory();
  const { id } = useParams();

  const companyAdmin = selectedMembership?.view === 'Company Admin'
    || selectedMembership?.view === 'Corporate Admin';

  /* const superAdmin = selectedMembership?.view === 'Super Admin'
    || selectedMembership?.view === 'Nibe Employee'; */

  /* const { result: companies = [] } = useAsync(
    () => superAdmin && companiesServices.getAll(),
    [superAdmin],
  ); */

  useEffect(() => {
    reset();
  }, [pathname]); // eslint-disable-line react-hooks/exhaustive-deps

  const passwordCriteria = useCallback(value => ((value && !isValid(value))
    ? t('password criteria')
    : undefined), []); // eslint-disable-line react-hooks/exhaustive-deps

  const passwordsMustMatch = useCallback((value, { plainPassword }) => (
    (value && (value !== plainPassword)) ? t('passwords dont match') : undefined
  ), []); // eslint-disable-line react-hooks/exhaustive-deps

  const emailCriteria = useCallback(value => ((value && !isEmailValid(value))
    ? t('this is not a valid email address')
    : undefined), []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Form onSubmit={handleSubmit((props) => {
        setHasEditedForm(true);
        return handleSubmitValues(props);
      })}>
        {((!companyAdmin || completeProfile) || isEdit)
          && (
            <Field
              component={FormInput}
              name="name"
              props={{
                name: 'name',
                placeholder: t('Name'),
              }}
              validate={isEdit ? null : required}
            />
          )}

        {!isEdit && !completeProfile
          ? (
            <Field
              component={FormInput}
              name="email"
              props={{
                name: 'email',
                placeholder: t('E-mail adress*'),
              }}
              validate={isEdit ? null : [required, emailCriteria]}
            />
          )
          : <FormSimpleContentInfo content={completeProfile?.email || user?.email} name="email" />}

        {((!companyAdmin || completeProfile) || isEdit)
          && (
            <>
              <Field
                component={FormSelect}
                name="locale"
                props={{
                  name: 'locale',
                  options: languages,
                  placeholder: isEdit ? user?.locale : t('Language'),
                  isSearchable: false,
                }}
                validate={isEdit ? null : required}
              />

              {/* {superAdmin && (
                <Field
                  component={FormSelect}
                  name="company"
                  props={{
                    name: 'company',
                    options: companies?.companies,
                    placeholder: t('Company'),
                    isSearchable: true,
                  }}
                  validate={isEdit ? null : required}
                />
              )} */}

              {/* {!isDefaultUser && (
                <Field
                  component={FormSelect}
                  name="permissions"
                  props={{
                    name: 'permissions',
                    options: permissions,
                    placeholder: t('default'),
                    isSearchable: false,
                  }}
                />
              )} */}

              <Field
                component={FormInput}
                name="plainPassword"
                props={{
                  type: 'password',
                  name: 'plainPassword',
                  placeholder: t('type password'),
                }}
                validate={isEdit ? null : [required, passwordCriteria]}
              />

              <Field
                component={FormInput}
                name="repeatPassword"
                props={{
                  type: 'password',
                  name: 'repeatPassword',
                  placeholder: t('repeat password'),
                }}
                validate={isEdit ? null : [required, passwordCriteria, passwordsMustMatch]}
              />

              {/* {superAdmin && (
                <Field
                  component={FormSelect}
                  name="reviewer"
                  props={{
                    name: 'reviewer',
                    options: reviewerOptions,
                    placeholder: t('no'),
                    isSearchable: false,
                  }}
                />
              )} */}

              {isEdit && (
                <Field
                  component={FormFileUpload}
                  name="autograph"
                  props={{
                    url: autograph?.contentUrl
                    && `${process.env.REACT_APP_API_URL}${autograph?.contentUrl}`,
                    content: autograph,
                    id: 'autograph',
                    deleteFile,
                    downloadFile,
                    name: 'autograph',
                  }}
                />
              )}

              {differentId && (
                <Field
                  component={FormSelect}
                  name="enabled"
                  props={{
                    name: 'status',
                    options: status,
                    placeholder: t('enabled'),
                    isSearchable: false,
                  }}
                />
              )}
            </>
          )}

        <div style={{ display: 'flex', margin: '1.8rem 0 0' }}>
          <Button
            disabled={pristine || invalid}
            loading={submitting}
            style={{ minWidth: '9.87rem', marginRight: '24px' }}
            styling="select"
            type="submit">
            {t('save')}
          </Button>

          {differentId && (
            <Button
              onClick={onDeleteItem}
              style={{ minWidth: '9.87rem', marginRight: '24px' }}
              styling="select">
              {t('remove user')}
            </Button>
          )}
          <Button
            onClick={() => history
              // eslint-disable-next-line no-nested-ternary
              .push(isUserProfile ? '/user-profile' : isEdit
                ? `/manage/users/${id}` : '/manage/users')}
            styling="underline">
            {t('cancel')}
          </Button>
        </div>
      </Form>
      <MainModal
        content="prompt"
        exitModalContent={{
          navigateSideEffects: () => null,
        }}
        prompt
        promptCondition={!pristine && !hasEditedForm}
      />
    </>
  );
};

export default reduxForm({
  form: 'new-user',
  enableReinitialize: true,
})(formValues({
  reviewer: 'reviewer',
})(UserForm));
