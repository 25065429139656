import _ from 'lodash';
import React, { useContext } from 'react';
import { Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { setBreadcrumb } from 'state/sidebar/sidebar.actions';

import { useQuery } from 'utils';
import { calculations, inputs as inputService } from 'services';
import { Calculation, useCanEdit } from 'hooks/calculation.hooks';
import { useInput, useInputsContext } from 'hooks/input.hooks';
import { useWasteRegion } from 'hooks/wasteRegion.hooks';

import { Placeholder } from 'components/Splash/Loading';

import * as G from 'routes/GlobalStyle.styled';
import * as S from './AddInput.styled';

import handleFailSubmit from './handleFailSubmit';
import AddInput from './AddInput.form';
import { dataQualityOptions } from './inputs.helpers';

// eslint-disable-next-line import/no-anonymous-default-export
export default ({
  withSelect, inputStage, isPdisInput, addAnother,
}) => {
  const { isHidden } = useSelector(state => state.sidebar);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const inputId = useQuery().get('inputId');
  const calculation = useContext(Calculation);
  const inputs = useInputsContext();
  const isPdisCalculation = inputs.isPdis;

  const canEdit = useCanEdit();
  const {
    id: calculationId, scalingType, productName,
    wasteScenarioInputMethod,
  } = calculation.result || {};
  const input = useInput(calculationId, inputId, isPdisInput);
  const { scenarios, loading: wasteLoader } = useWasteRegion();
  const stage = (() => {
    if (inputStage !== undefined) return inputStage;
    return input.result?.inputStage || undefined;
  })();

  let { wasteScenarioProductionWaste, wasteScenario } = input?.result || {};

  wasteScenario = inputId
    ? _.find(scenarios, s => (s && s['@id']) === (wasteScenario?.['@id'])) : null;
  wasteScenarioProductionWaste = inputId
    ? _.find(scenarios, s => (s && s['@id'])
    === (wasteScenarioProductionWaste?.['@id'])) : null;

  React.useEffect(() => {
    if (productName) dispatch(setBreadcrumb(productName));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calculation]);

  const productionWasteName = inputs.isPdis || calculation.result?.productionWasteInputMethod
  === 'per_raw_material_kg' ? 'productionWasteKg' : 'productionWastePercentage';

  const save = (formData) => {
    let payload = {
      amount: parseFloat(formData?.amount),
      calculation: calculation?.result?.['@id'],
      predefinedInputSet: formData?.PDIS?.['@id'],
    };
    if (stage?.id === 'pdis') {
      return inputService.saveUsagePdis(payload, inputId).then(() => {
        inputs.refresh();
        return calculation.execute();
      });
    }
    payload = {
      inputStage: stage?.['@id'],
      description: formData.descriptionInputs,
      netAmount: _.toNumber(formData.netAmount),
      linearScalable: formData?.linearScalable?.value || false,
    };
    if (!isPdisCalculation) {
      payload = {
        ...payload,
        calculation: calculation?.result?.['@id'],
      };
    }
    if (isPdisCalculation) payload.predefinedInputSet = inputs.calculationPath;
    if (formData[productionWasteName]) {
      payload.productionWaste = _.toNumber(formData[productionWasteName]);
    }
    if (formData.supplierInputs) payload.supplier = formData.supplierInputs['@id'];
    payload.comments = formData?.comments || '';
    payload.dataSource = formData?.dataSource || '';
    payload.dataQuality = formData?.dataQuality?.value || null;
    if (formData.lifeTime && !_.includes([4, 3, 5, 6, 7, 8, 11], stage?.id)) {
      payload.lifeTime = _.toInteger(formData.lifeTime);
    }
    if (formData.wasteScenario) payload.wasteScenario = formData.wasteScenario['@id'];
    if (formData.wasteScenarioProductionWaste) {
      payload.wasteScenarioProductionWaste = formData.wasteScenarioProductionWaste['@id'];
    }

    if (formData.environmentalProfile) {
      payload.environmentalProfile = formData.environmentalProfile['@id'];
    }

    payload.epSubstantiation = formData.epSubstantiation || '';

    if (!formData?.environmentalProfile?.transportNeeded) payload.supplier = null;
    if (!formData?.environmentalProfile?.wasteScenarioNeeded) {
      payload.productionWaste = null;
      payload.wasteScenario = null;
      payload.wasteScenarioProductionWaste = null;
    }

    if ([3, 4].includes(stage.id)) {
      payload.wasteScenarioProductionWaste = null;
      payload.productionWaste = null;
    }

    return calculations
      .saveInput(payload, inputId)
      .then(r => {
        if (!isPdisCalculation) {
          calculation.execute();
        }
        inputs.refresh();
        return r;
      })
      .catch(handleFailSubmit);
  };

  function mapToInitialValues(data) {
    if ((stage?.code === 'A1_RAW_MATERIAL') && wasteScenarioInputMethod === '2') {
      return {
        ...data,
        lifeTime: data?.lifeTime || calculation?.result?.lifeTime
          || calculation?.result?.productLifeTime,
        descriptionInputs: data?.description,
        [productionWasteName]: data?.productionWaste
          || calculation?.result?.productionWastePercentage,
        dataQuality: _.find(dataQualityOptions, item => item?.value === data?.dataQuality),
        supplierInputs: data?.supplier,
        wasteScenario,
        wasteScenarioProductionWaste,
        linearScalable: {
          label: data?.linearScalable ? t('yes') : t('no'),
          value: data?.linearScalable,
        },
        PDIS: data?.predefinedInputSet,
      };
    }
    if (data) {
      const params = {
        ...data,
        dataQuality: _.find(dataQualityOptions, item => item?.value === data?.dataQuality),
        supplierInputs: data?.supplier,
        descriptionInputs: data?.description,
        linearScalable: {
          label: data?.linearScalable ? t('yes') : t('no'),
          value: data?.linearScalable,
        },
        PDIS: data?.predefinedInputSet,
      };

      params[productionWasteName] = data?.productionWaste;

      return params;
    }

    const values = {
      lifeTime: calculation?.result?.lifeTime || calculation?.result?.productLifeTime,
    };

    if (calculation?.result?.productionWasteInputMethod === 'overall_percentage') {
      values.productionWastePercentage = calculation?.result?.productionWastePercentage;
    }

    return values;
  }

  if (!canEdit) return <Redirect to="list" />;

  return (
    <G.Background isEditable>
      <G.HeaderTitle>{t('Inputs')}</G.HeaderTitle>

      <G.FormContainer isHidden={isHidden} style={{ minHeight: inputStage ? 'auto' : '64.5vh' }}>
        <S.Header>
          <S.TitleHeader>{t('Choose input type')}</S.TitleHeader>

          <S.InputWrapper>
            {withSelect}
          </S.InputWrapper>
        </S.Header>
      </G.FormContainer>

      {inputStage && (
        <Placeholder loaders={[wasteLoader, inputId ? input?.loading : false]}>
          {() => (
            <AddInput
              addAnother={addAnother}
              dataQualityOptions={dataQualityOptions}
              initialValues={mapToInitialValues(input.result)}
              inputResult={input.result}
              isEdit={inputId}
              predefinedInputSet={input?.result?.predefinedInputSet}
              productionWasteInputMethod={calculation.result?.productionWasteInputMethod}
              productionWasteName={productionWasteName}
              saveForm={save}
              scalable={scalingType !== 'none'}
              stage={stage}
              wasteOptions={scenarios}
              wasteScenarioEndOfLife={wasteScenario}
              wasteScenarioProductionWasteSelected={wasteScenarioProductionWaste}
            />
          )}
        </Placeholder>
      )}
    </G.Background>
  );
};
