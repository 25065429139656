import _ from 'lodash';
import React, { useRef } from 'react';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { downloadFile } from 'utils';
import { calculations } from 'services';
import useRestrictions from 'utils/calculationRestrictions';
import * as actions from 'state/calculations/calculations.actions';

import { Prompt } from 'components/Modal';
import SubmitToReview from 'components/Modal/ModalContent/Submit.prompt';
import Destinations from 'components/Modal/ModalContent/Destinations.prompt';

import editIcon from 'assets/edit.svg';
import deleteIcon from 'assets/delete.svg';
import submitIcon from 'assets/submit.svg';
import downloadIcon from 'assets/download.svg';
import groupIcon from 'assets/group.svg';
import moveIcon from 'assets/move.svg';

import { alertToast } from 'config/toast';

const { bulk } = calculations;

export const actionsHoc = (ChildComponent, selection, calculation, callback, reviewActions) => {
  const ComposedComponent = props => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const user = useSelector(state => state.user);
    const { list } = useSelector(state => state.calculations);
    const restrictions = useRestrictions(calculation, selection, list);
    const h = useHistory();
    const retries = useRef(0);
    const [loadingResubmit, setLoadingResubmit] = React.useState(false);

    const retry = (format) => {
      bulk.download(selection, format)
        .catch(error => {
          if ((error?.response?.status === 404) && (retries.current < 15)) {
            retries.current += 1;
            setTimeout(() => retry(format), 2000);
          }
        });
    };

    const downloadAs = format => () => retry(format);

    const remove = {
      id: 'remove',
      label: t('delete'),
      action: () => restrictions.edit || Prompt.Default({
        title: i18next.t('confirmDeleteCalculation', { count: selection.length }),
        onConfirm: () => bulk.remove(selection)
          .then(() => {
            dispatch(actions.deleteCalculation(selection));
            dispatch(actions.selectNone);
          }),
      }),
      img: deleteIcon,
      disabled: restrictions.delete,
    };

    const submit = {
      id: 'submit',
      action: () => Prompt.Custom(
        <SubmitToReview
          onConfirm={reviewerID => bulk.submit(selection, reviewerID)}
          title={t('Submit for review')}
        />,
      ).then(callback),
      label: t('Submit for review'),
      img: submitIcon,
      disabled: restrictions.review,
    };

    const resubmit = {
      id: 'resubmit',
      action: () => {
        setLoadingResubmit(true);
        bulk.resubmit(selection).finally(() => {
          // eslint-disable-next-line callback-return
          if (callback) callback();
          setLoadingResubmit(false);
        });
      },
      label: t('Resubmit for review'),
      img: submitIcon,
      disabled: restrictions.review,
      loading: loadingResubmit,
    };

    const edit = {
      id: 'edit',
      action: () => restrictions.edit || h.push(`/calculation/${calculation.id}`),
      label: t('edit'),
      img: editIcon,
      disabled: restrictions.edit || restrictions.copy,
    };

    const copy = {
      id: 'copy',
      action: () => Prompt.Custom(
        <Destinations
          onConfirm={to => bulk.copy(selection, to)}
          title={t('copy')}
        />,
      ).then(callback),
      label: t('copy'),
      img: groupIcon,
      disabled: restrictions.copy,
    };

    const move = {
      id: 'move',
      action: () => Prompt.Custom(
        <Destinations
          onConfirm={to => bulk.move(selection, to)}
          title={t('Move')}
        />,
      ).then(() => {
        dispatch(actions.selectNone); callback();
      }),
      label: t('Move'),
      img: moveIcon,
      disabled: restrictions.copy,
    };

    const [loading, setLoading] = React.useState({});

    const doDownload = (type, fileObj) => {
      setLoading({ ...loading, [type]: true });
      downloadFile(
        `${process.env.REACT_APP_API_URL}${fileObj?.contentUrl}`,
        fileObj?.originalFilename,
        () => {
          setLoading({ ...loading, [type]: false });
          if (callback) return callback();
        },
      );
    };

    const retryDownloadReport = async (type, result) => {
      const res = await calculations.getReport(result?.id);
      if (res?.response?.status && (res?.response?.status !== 200)) {
        setLoading({ ...loading, [type]: false });
        return;
      }
      if (res?.mediaObject) {
        const report = await calculations.getReportRef(res?.mediaObject);
        doDownload(type, report);
      } else setTimeout(() => retryDownloadReport(type, result), 5000);
    };

    const downloadReport = (type, format) => async () => {
      alertToast(t('The download of the report has started!'), 'success');
      if (calculation?.[type]?.contentUrl) {
        doDownload(type, calculation?.[type]);
      } else if (calculation?.[type]) {
        calculations.getByRef(calculation?.[type])
          .then(result => result?.contentUrl && doDownload(type, result));
      } else if (calculation?.reportable) {
        setLoading({ ...loading, [type]: true });
        let reportsList = calculation?.reports;
        if (!reportsList?.[0]?.format) {
          reportsList = await Promise.all(reportsList?.map(r => calculations.getReportRef(r)));
        }
        const report = reportsList?.find(r => r?.format === format);
        if (report?.id) retryDownloadReport(type, report);
        else {
          calculations.createReport({ format, calculation: calculation?.['@id'] })
            .then(result => result?.id && retryDownloadReport(type, result));
        }
      }
    };

    const download = [
      {
        id: 'download-8',
        type: 'download',
        action: calculation ? downloadReport('reviewerReport', 'reviewer') : downloadAs('reviewer'),
        label: t('Review Report (PDF)'),
        img: downloadIcon,
        isDownload: true,
        disabled: calculation ? !calculation?.reportable : restrictions.reports,
        loading: loading.reviewerReport,
      },
      {
        id: 'download-9',
        type: 'download',
        action: calculation ? downloadReport('csvExport', 'csv') : downloadAs('csv'),
        label: t('Export (CSV)'),
        img: downloadIcon,
        isDownload: true,
        disabled: calculation ? !calculation?.reportable : restrictions.reports,
        loading: loading.csvExport,
      },
      {
        id: 'download-10',
        type: 'download',
        action: calculation ? downloadReport('shortReport', 'short') : downloadAs('short'),
        label: t('EPD summary (PDF)'),
        img: downloadIcon,
        isDownload: true,
        disabled: calculation ? !calculation?.reportable : restrictions.reports,
        loading: loading.shortReport,
      },
      {
        id: 'download-11',
        type: 'download',
        action: calculation ? downloadReport('longReport', 'long') : downloadAs('long'),
        label: t('EPD (PDF)'),
        img: downloadIcon,
        isDownload: true,
        disabled: calculation ? !calculation?.reportable : restrictions.reports,
        loading: loading.longReport,
      },
    ];

    const viewer = user?.selectedMembership?.view === 'Viewer';

    const helpers = viewer ? [] : [
      edit,
      copy,
      move,
      remove,
    ];

    if (!viewer) helpers.push((calculation?.state === 'rejected') ? resubmit : submit);

    const options = reviewActions || helpers;

    const buttonsData = [
      ...options,
      ...download,
    ];

    return (
      <ChildComponent
        buttonsConfig={_.keyBy(buttonsData, 'id')}
        buttonsData={buttonsData}
        downloadButtons={download}
        {...props}
      />
    );
  };

  return ComposedComponent;
};
