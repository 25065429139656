import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Field, reduxForm, Form, formValues,
} from 'redux-form';
import { alertToast } from 'config/toast';
import Button from 'components/Button/Button';
import { FormSelect, FormAsyncSelect } from 'components/Select/Select';
import { FormInput } from 'components/Input/Input';
import MainModal from 'components/Modal';
import * as S from 'routes/GlobalStyle.styled';
import LocationEditor from 'components/LocationEditor/LocationEditor';
import { useAllMethods, useMethods } from 'hooks/methods.hooks';
import GField from 'components/Field';
import { FormRadio } from 'components/Radio/Radio';
import { FormTextArea } from 'components/TextArea/TextArea';
import { FormSimpleContentInfo } from 'components/ContentInfo/ContentInfo';
import { findPublisherOperator } from 'utils/calculationsPublisherOperator';
import * as v from '../Basics/validation';
import * as AV from '../Advanced/validation';
import { predefinedConstructionWasteOpt } from '../Advanced/Editor.helpers';

const FORM_NAME = 'template-form';

const TemplateForm = (props) => {
  const { t } = useTranslation();
  const {
    handleSubmit,
    submitting,
    isEdit,
    saveForm,
    pristine,
    template,
    locationSelected,
    locationHook,
    change,
    wasteRegions,
    customFunctionalUnit,
    customUnitForLCA,
    standardUnitForLCA,
    functionalUnit,
    productType,
    method,
    predefinedConstructionWaste,
    companies,
    operators,
    ownerCompany,
  } = props;
  const syncErr = useSelector(({ form }) => form[FORM_NAME]?.syncErrors);
  const { isHidden } = useSelector(state => state.sidebar);
  const history = useHistory();
  const { result: methods, loading } = useAllMethods();
  const [hasEditedForm, setHasEditedForm] = useState(false);
  const [isCustom, setfunctionalUnitType] = useState(!customFunctionalUnit);
  const handleRadio = ({ target }) => setfunctionalUnitType(target.value !== 'custom');
  const methodsAdvanced = useMethods({ method });

  const onChange = (value, other) => {
    // eslint-disable-next-line no-unused-expressions
    other?.input?.onChange(value);
    change('productType', null);
  };

  const canEditPublisher = !method?.publisher && !!ownerCompany.parentCompany;
  const canEditOperator = !method?.operator;

  return (
    <>
      <S.FormContainer isHidden={isHidden} style={{ paddingBottom: '6rem', margin: '0' }}>
        <Form
          onSubmit={(ev) => {
            ev.preventDefault();
            setHasEditedForm(true);
            if (syncErr) {
              alertToast(t('form has errors'), 'alert');
            }

            handleSubmit((values) => saveForm(values, !isCustom))();
          }}
          style={{ width: '100%' }}>
          <S.GroupFields>
            <S.GroupFieldsHeader>{t('Template characteristics')}</S.GroupFieldsHeader>
            <Field
              component={FormInput}
              name="Code"
              props={{
                placeholder: t('Code.label'),
              }}
              validate={v.required}
            />
            <Field
              component={FormInput}
              name="name"
              props={{
                placeholder: t('name.label'),
              }}
              validate={v.required}
            />
            <S.Wrapper>
              <S.AlignItems>
                <Field
                  component={FormAsyncSelect}
                  name="location"
                  props={{
                    isClearable: true,
                    defaultValue: locationSelected,
                    options: locationHook.list,
                    isSearchable: true,
                    loadOptions: locationHook.loadPageOptions,
                    debounceTimeout: 1000,
                    getOptionLabel: (l) => `${l.name} - ${l.city}, ${l.country}`,
                    getOptionValue: ({ '@id': id }) => id,
                    custom: () => <LocationEditor onLocationCreated={locationHook.prepend} />,
                  }}
                  validate={[v.requiredAfterInitialSave, v.location]}
                />
              </S.AlignItems>
            </S.Wrapper>

            <Field
              component={FormInput}
              name="productLifeTime"
              props={{ type: 'number', min: 1 }}
              validate={[v.requiredAfterInitialSave]}

            />
          </S.GroupFields>
          <S.GroupFieldsDivider />
          <S.GroupFields>
            <S.GroupFieldsHeader>{t('lca method')}</S.GroupFieldsHeader>
            <Field
              component={(other) => (
                <FormSelect
                  {...other}
                  input={{
                    ...other.input,
                    onChange: (p) => {
                      onChange(p, other);
                      change('operator', p.operator || ownerCompany);
                      change('publisher', p.publisher || ownerCompany);
                    },
                  }}
                />
              )}
              name="method"
              props={{
                options: methods?.methods,
                isLoading: loading,
                getOptionLabel: ({ name }) => name,
                getOptionValue: ({ '@id': id }) => id,
                isSearchable: true,
              }}
              validate={[v.required, v.method]}
            />
            {method && (
            <>
              {canEditPublisher ? (
                <Field
                  component={FormSelect}
                  disabled={!canEditPublisher}
                  name="publisher"
                  props={{
                    name: 'calculationPublisher',
                    options: (ownerCompany.parentCompany
                      ? [ownerCompany, ownerCompany.parentCompany, method.publisher]
                      : [method.publisher]).filter(o => !!o),
                    getOptionLabel: o => o.name,

                    getOptionValue: ({ '@id': id }) => id,
                    isDisabled: !canEditPublisher,
                    isSearchable: true,
                  }}
                  validate={[v.required]}
                />
              ) : (
                <FormSimpleContentInfo
                  content={findPublisherOperator('publisher', null, template, method,
                    [ownerCompany, ownerCompany.parentCompany, method.publisher, ...operators, ...companies])?.name || '-'}
                  name="calculationPublisher"
                />
              )}
              {canEditOperator ? (
                <Field
                  component={FormSelect}
                  disabled={!canEditOperator}
                  name="operator"
                  props={{
                    name: 'calculationOperator',
                    options: (operators.find(o => o['@id'] === ownerCompany?.['@id'])
                      ? operators : [ownerCompany, ...operators])
                      .filter(o => !!o['@id']),

                    getOptionLabel: o => o.name
                  || operators?.find(c => c['@id'] === o['@id'] || c['@id'] === o)?.name,
                    getOptionValue: ({ '@id': id }) => id,
                    isDisabled: !canEditOperator,
                    isSearchable: true,
                  }}
                  validate={[v.required]}
                />
              ) : (
                <FormSimpleContentInfo
                  content={findPublisherOperator('operator', null, template, method,
                    [ownerCompany, ...operators, ...companies])?.name || '-'}
                  name="calculationOperator"
                />
              )}
            </>
            )}

            <Field
              component={FormSelect}
              name="wasteRegion"
              props={{
                options: wasteRegions.list,
                getOptionLabel: w => `${w.name}, ${w.code}`,
                getOptionValue: ({ '@id': id }) => id,
                isSearchable: false,
              }}
              validate={[v.required, v.wasteRegion]}
            />
          </S.GroupFields>
          <S.GroupFieldsDivider />
          <S.GroupFields>
            <S.GroupFieldsHeader>{t('standardUnit.name')}</S.GroupFieldsHeader>
            <div>
              <GField name="functionalUnit">
                <S.RadioWrapper>
                  <FormRadio
                    checked={isCustom}
                    id="functionalUnit-standard"
                    onChange={handleRadio}
                    translation={{ placeholder: t('functionalUnit.standard') }}
                    value="standard"
                  />
                  <FormRadio
                    checked={!isCustom}
                    id="functionalUnit-custom"
                    onChange={handleRadio}
                    translation={{ placeholder: t('functionalUnit.custom') }}
                    value="custom"
                  />
                </S.RadioWrapper>
              </GField>

              {!isCustom ? (
                <div>
                  <Field
                    component={FormInput}
                    name="functionalUnitTitle"
                    validate={v.required}
                  />

                  <Field
                    component={FormTextArea}
                    name="functionalUnitDescription"
                    props={{
                      cols: '80',
                      rows: '4',
                      spellCheck: true,
                    }}
                    validate={v.required}
                  />

                  <Field
                    component={FormSelect}
                    name="standardUnit"
                    props={{
                      options: customUnitForLCA.list,
                      getOptionLabel: (i) => `(${i.abbreviation})  ${i.unit}`,
                      getOptionValue: ({ '@id': id }) => id,
                      isSearchable: true,
                    }}
                    validate={v.required}
                  />
                </div>
              ) : (
                <div>
                  <Field
                    component={FormSelect}
                    name="functionalUnit"
                    props={{
                      options: standardUnitForLCA.list,
                      isSearchable: true,
                      getOptionLabel: (i) => `${i.unit} (${i.elementName})`,
                      getOptionValue: ({ '@id': id }) => id,
                    }}
                    validate={v.required}
                  />
                  <p>{functionalUnit && functionalUnit.description}</p>
                </div>
              )}
            </div>
          </S.GroupFields>
          <S.GroupFieldsDivider />
          <S.GroupFields>
            <S.GroupFieldsHeader>{`${t('productType.label')} (A4)`}</S.GroupFieldsHeader>
            <Field
              component={FormSelect}
              name="productType"
              props={{
                options: methodsAdvanced?.productTypes,
                getOptionLabel: ({ name }) => name,
                getOptionValue: ({ id }) => id,
                isSearchable: true,
              }}
              validate={v.required}
            />
            <fieldset>
              {productType?.id === 3 && (
                <>
                  <Field
                    component={FormSelect}
                    name="transportProfileToConstructionSite"
                    props={{
                      options: methodsAdvanced.profiles,
                      getOptionLabel: ({ name }) => name,
                      getOptionValue: ({ id }) => id,
                      isSearchable: true,
                    }}
                    validate={v.required}
                  />

                  <Field
                    component={FormInput}
                    name="distanceToConstructionSite"
                    props={{ type: 'number' }}
                    validate={v.required}
                  />
                </>
              )}
            </fieldset>
          </S.GroupFields>

          <S.GroupFieldsDivider />
          <S.GroupFields>
            <S.GroupFieldsHeader>{t('construction waste')}</S.GroupFieldsHeader>
            <Field
              component={FormSelect}
              name="predefinedConstructionWaste"
              props={{
                options: predefinedConstructionWasteOpt,
              }}
              validate={[v.required, AV.predefinedConstructionWaste]}
            />

            {predefinedConstructionWaste?.value === 0 && (
              <Field
                component={FormInput}
                name="constructionWaste"
                props={{
                  type: 'decimal',
                  min: 0,
                  max: 100,
                }}
                validate={[v.required, AV.constructionWaste]}
              />
            )}
          </S.GroupFields>

          <div style={{ display: 'flex', marginTop: '2rem', marginLeft: '2.25rem' }}>
            <Button
              loading={submitting}
              style={{ marginRight: '2rem' }}
              styling="select"
              type="submit">
              {t(isEdit ? 'save' : 'add template')}
            </Button>
            <Button
              onClick={() => history
                .push(isEdit ? `/calculations/templates/${template?.id}`
                  : '/calculations/templates')}
              styling="underline">
              {t('cancel')}
            </Button>
          </div>
        </Form>
      </S.FormContainer>
      <MainModal
        content="prompt"
        exitModalContent={{
          navigateSideEffects: () => null,
        }}
        prompt
        promptCondition={!pristine && !hasEditedForm}
      />
    </>
  );
};

export default reduxForm({
  form: FORM_NAME,
  enableReinitialize: true,
})(
  formValues({
    functionalUnit: 'functionalUnit',
    method: 'method',
    productType: 'productType',
    predefinedConstructionWaste: 'predefinedConstructionWaste',
    publisher: 'publisher',
    operator: 'operator',
  })(TemplateForm),
);
