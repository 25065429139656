import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { setBreadcrumb } from 'state/sidebar/sidebar.actions';
import { Redirect, useParams, useHistory } from 'react-router-dom';

import { methods } from 'services';
import { useCalculationMethod } from 'hooks/methods.hooks';

import { Placeholder } from 'components/Splash/Loading';

import * as S from 'routes/GlobalStyle.styled';
import LCAMethodForm from './LCAMethodForm';

const EditLCAMethod = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const methodHook = useCalculationMethod(id);
  const { result: method } = methodHook;

  useEffect(() => {
    if (method?.name) dispatch(setBreadcrumb(method?.name));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [method]);

  const handleSubmitValues = values => methods
    .editMethod(id, values)
    .then(() => history.push(`/manage/lca-methods/${id}`, { routeName: values?.name }));

  const superAdmin = user?.selectedMembership?.view === 'Super Admin'
    || user?.selectedMembership?.view === 'Nibe Employee';

  if (!superAdmin) return <Redirect to="/" />;

  return (
    <S.Background isEditable>
      <Placeholder loaders={[methodHook]}>
        <S.HeaderTitle>{t('edit lca method')}</S.HeaderTitle>
        <LCAMethodForm
          handleSubmitValues={handleSubmitValues}
          initialValues={method}
          method={method}
        />
      </Placeholder>
    </S.Background>
  );
};

export default EditLCAMethod;
