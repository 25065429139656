import styled, { css } from 'styled-components';
import { GREY } from 'config/style';

export const Option = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 20px;
  transition: .3s;
  opacity: ${(props) => (props.isClickable ? 1 : 0.3)};
  cursor: ${(props) => (props.isClickable ? 'pointer' : 'auto')};

  &:hover {
    background: ${(props) => (props.isClickable ? GREY : 'none')};
  }

  div {
    min-width: 25px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 1rem;
  }
`;

export const Main = styled.main`
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const OverviewRows = styled.ul`
  flex: 1;
  height: 100%;
  overflow: scroll;
`;

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`;

export const FilterWrapper = styled.div`
  padding: 1.13rem 2.5rem;
  border-top: 1px solid #e4e4e4;
`;

export const Results = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  margin-top: 3rem;
  margin-right: 5px;
`;

export const ClearFilter = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  margin-top: 1.13rem;
  cursor: pointer;
  text-decoration: underline;
`;

export const StyledLabel = styled.span`
  ${(props) => props.isRegular && css`font-weight: normal;`};
  ${(props) => props.color && css`color: ${props.color};`};
`;

export const ButtosContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const DownloadTemplate = styled.div`
  margin-top: 2rem;
  cursor: pointer;
  text-decoration: underline;
`;
