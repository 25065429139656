import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Calculation, useCanEdit } from 'hooks/calculation.hooks';
import { useCalculationMethod } from 'hooks/methods.hooks';

import Button from 'components/Button/Button';
import { ReadOnlyField } from 'components/Field';
import { Placeholder } from 'components/Splash/Loading';
import HTML from 'components/RenderHTML';

import { ViewModeContainer, TitleBar } from 'routes/GlobalStyle.styled';

const DefaultSubstantiations = ({ template }) => {
  const { t } = useTranslation();
  const canEdit = useCanEdit();
  const calcResult = useContext(Calculation);
  let { result: calculationData } = calcResult;

  if (template) calculationData = template;

  const calculationMethod = useCalculationMethod(calculationData?.method?.id);
  const { result: method = {} } = calculationMethod;
  const data = { ...method, ...calculationData };

  const isSemi = (data?.calculationType === 'semi_finished_product');

  const getValue = (calcKey, templateKey = calcKey, methodKey = calcKey, defaultValue = '') => {
    if (data.template) {
      return ((data.template[templateKey] || method?.[methodKey] || defaultValue)
        .concat(calculationData?.[calcKey] || '') || '');
    }

    return calculationData?.[calcKey] || method?.[methodKey] || defaultValue;
  };

  return (
    <>
      <TitleBar>
        {t('expert functions default substantiations')}

        {canEdit && (
          <Button as={Link} styling="select" to="edit">
            {t('edit')}
          </Button>
        )}
      </TitleBar>

      <ViewModeContainer>
        <Placeholder loaders={[calculationMethod]}>

          <ReadOnlyField name="used abbreviations">
            <HTML html={getValue('abbreviations')} />
          </ReadOnlyField>

          <ReadOnlyField name="product categorie rules">
            <HTML html={getValue('pcr')} />
          </ReadOnlyField>

          <ReadOnlyField name="purpose and target groups">
            <HTML
              html={getValue('purposeTargetGroups', 'purposeTargetGroups', 'purposeDescription')}
            />
          </ReadOnlyField>

          <ReadOnlyField name="environmental profile and ratings representive">
            <HTML html={getValue('environmentalProfileRatingsRepresentative',
              'environmentalProfileRatingsRepresentative',
              null,
              `The input data are representative for ${data?.template?.productName
              || data?.productName || data?.name},
                  a product of ${data?.template?.owner?.name || data?.owner?.name}.
                  The data are representative for ${data?.template?.wasteScenarioSet?.name
              || data?.wasteScenarioSet?.name}.`)}
            />
          </ReadOnlyField>

          <ReadOnlyField name="cut off criteria">
            <HTML html={getValue(
              'cutoffCriteria',
              'cutoffCriteria',
              null,
              (isSemi
                ? `<b>${t('Product Stage (A1-A3).label')}</b>
                    <br/>
                    ${method.cutoffCriteriaProduct}`
                : `<b>${t('Product Stage (A1-A3).label')}</b>
                    <br/>${method.cutoffCriteriaProduct}
                    <br/>
                    <b>${t('Construction process stage (A4-A5).label')}</b>
                    <br/>${method.cutoffCriteriaConstruction}
                    <br/>
                    <b>${t('Use stage (B1-B3).label')}</b>
                    <br/>${method.cutoffCriteriaUse}
                    <br/>
                    <b>${t('End of life stage (C1-C4).label')}</b>
                    <br/>${method.cutoffCriteriaEOL}
                    <br/>
                    <b>${t('Benefits and Loads beyond the system boundary (Module D).label')}</b>
                    <br/>${method.cutoffCriteriaSupplementary}`),
            )}
            />
          </ReadOnlyField>

          <ReadOnlyField name="annex 2 references">
            <HTML
              html={getValue('annex2References')}
            />
          </ReadOnlyField>
        </Placeholder>
      </ViewModeContainer>
    </>
  );
};

export default DefaultSubstantiations;
