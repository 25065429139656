import _ from 'lodash';
import { useState } from 'react';
import { useAsync } from 'react-async-hook';

import { locations as locationService } from 'services';

export const useLocations = (defaultLocation) => {
  const [locationsList, setLocationsList] = useState([]);
  const [selected, setLocation] = useState(undefined);
  const [page, setPage] = useState(null);
  const [search, setSearch] = useState(null);

  const locations = useAsync(() => locationService.all({ itemsPerPage: 30, 'order[name]': 'asc' })
    .then(res => {
      const { data } = res;
      setLocationsList(data);
      setLocation(defaultLocation);
      return {
        ...res,
        data,
      };
    }), []);

  const prepend = newLocation => {
    setLocation(newLocation);
    setLocationsList(_.sortBy([newLocation, ...locationsList], l => l?.name?.toLowerCase()));
  };

  const loadPageOptions = async (searchValue) => {
    if (searchValue && (search !== searchValue)) {
      setSearch(searchValue);
      const searchResult = await locationService.all({
        name: searchValue, itemsPerPage: 30, 'order[name]': 'asc',
      });
      const data = searchResult.locations;
      setLocationsList(data);
      setPage(searchResult.nextPage);
      return {
        options: data || [],
        hasMore: searchResult.nextPage,
      };
    }
    if (page) {
      const values = await locationService.getNextPageLocations(page);
      const data = values.locations;
      setLocationsList(data);
      setPage(values.nextPage);
      return {
        options: data || [],
        hasMore: values.nextPage,
      };
    }
    if (!page) {
      setPage(locations?.result?.nextPage);
    }
    return {
      options: [],
      hasMore: page || locations?.result?.nextPage,
    };
  };

  return {
    ...locations,
    selected,
    set: setLocation,
    list: locationsList,
    prepend,
    loadPageOptions,
  };
};
