import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { withLabels } from 'components/Field';

import * as S from './Radio.styled';

const Radio = ({
  placeholder, input, innerRef, style, ...props
}) => (
  <S.Wrapper style={style}>
    <S.LabelRadio>
      <input {...props} {...input} ref={innerRef} type="radio" />
      <span className="checkmark" />
      {placeholder}
    </S.LabelRadio>
  </S.Wrapper>
);

Radio.defaultProps = {
  type: 'radio',
  required: false,
  disabled: false,
  readOnly: false,
  placeholder: undefined,
  value: undefined,
  autoComplete: 'on',
};

Radio.propTypes = {
  type: PropTypes.oneOf(['radio']),
  required: PropTypes.bool,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  id: PropTypes.string.isRequired,
  autoComplete: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export const FormRadio = withLabels(Radio);

export default memo(Radio);
