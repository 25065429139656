import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory, Redirect } from 'react-router-dom';
import { useAsyncCallback } from 'react-async-hook';

import { methods as methodsServices } from 'services';

import ListItem from 'components/ListItem/ListItem';
import { Loading } from 'components/Splash/Loading';

import edit from 'assets/edit.svg';
import * as S from 'routes/Calculations/Overview.styled';
import InfiniteScroll from 'react-infinite-scroll-component';
import Name from 'components/TopBar/Name';
import TopBar from 'components/TopBar/TopBar';

const LCAMethods = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const user = useSelector(state => state.user);
  const { selectedCompany, id: companyId } = useSelector(({ company }) => company || {});
  const [page, setPage] = useState(null);
  const [methods, setMethods] = useState(null);
  const [sorting, setSorting] = useState('asc');
  const {
    loading, execute,
  } = useAsyncCallback(() => methodsServices.all({ params: { 'order[name]': sorting } })
    .then(values => {
      setMethods(values.methods);
      setPage(values.nextPage);
    }));
  const isEditable = companyId === selectedCompany?.id;

  useEffect(() => {
    execute();
  }, [sorting]); // eslint-disable-line react-hooks/exhaustive-deps

  const onEditItem = (data) => history
    .push(`/manage/lca-methods/${data.id}/edit`, { routeName: data.name });

  const ListItemContent = ({ data }) => (
    <S.Option key="edit" isClickable={isEditable} onClick={() => isEditable && onEditItem(data)}>
      <div>
        <img alt="icon" src={edit} />
      </div>

      <S.StyledLabel isRegular>
        {t('edit')}
      </S.StyledLabel>
    </S.Option>
  );

  const fetchData = () => {
    if (page) {
      methodsServices
        .getNextPage(page)
        .then((values) => {
          setPage(values.nextPage);
          setMethods(methods.concat(values.methods));
        });
    }
  };

  const methodsList = () => (
    <S.OverviewRows id="overviewRowsId">
      <InfiniteScroll
        dataLength={methods?.length}
        hasMore
        next={fetchData}
        scrollableTarget="overviewRowsId"
        scrollThreshold={0.4}>
        {_.map(methods, (data) => (
          <ListItem
            key={data.id}
            dropdownContent={(<ListItemContent data={data} />)}
            name={data.name}
            to={{
              pathname: `/manage/lca-methods/${data.id}`,
              state: {
                routeName: data.name,
              },
            }}
          />
        ))}
      </InfiniteScroll>
    </S.OverviewRows>
  );

  const superAdmin = user?.selectedMembership?.view === 'Super Admin'
    || user?.selectedMembership?.view === 'Nibe Employee';

  if (!superAdmin) return <Redirect to="/" />;

  return (
    <>
      <TopBar>
        <Name
          onOrderBy={order => {
            setSorting(order);
          }}
          order={sorting}
        />
      </TopBar>

      {!methods || loading
        ? Loading
        : methodsList()}
    </>
  );
};

export default LCAMethods;
