import _ from 'lodash';
import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { setBreadcrumb } from 'state/sidebar/sidebar.actions';
import { useParams, Redirect, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { calculations } from 'services';
import { useCalculationType, Calculation, useCanEdit } from 'hooks/calculation.hooks';
import { useLocations } from 'hooks/location.hooks';
import { useMethods } from 'hooks/methods.hooks';
import { useWasteRegion } from 'hooks/wasteRegion.hooks';
import {
  useStandardUnits,
  useCustomUnitForLCA,
  useStandardUnitForLCA,
} from 'hooks/functionalUnit.hooks';
import { useAsync } from 'react-async-hook';

import { Placeholder } from 'components/Splash/Loading';

import * as S from 'routes/GlobalStyle.styled';
import handleFailSubmit from './handleFailSubmit';
import BasicsForm from './BasicsForm';

// eslint-disable-next-line import/no-anonymous-default-export
export default (props) => {
  const { folderId } = useParams();
  const h = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { result: calculation, execute } = useContext(Calculation);
  const canEdit = useCanEdit();
  const calculationType = useCalculationType(calculation.calculationType);
  const locationHook = useLocations(calculation?.location);
  const methods = useMethods(calculation);
  const wasteRegions = useWasteRegion(calculation);
  const standardUnits = useStandardUnits();
  const customUnitForLCA = useCustomUnitForLCA(methods?.selected?.id, calculation);
  const standardUnitForLCA = useStandardUnitForLCA(methods?.selected?.id, calculation);
  const { companies, operators } = props;

  const { result: templates, loading } = useAsync(calculations.getTemplates, []);

  const loaderList = [
    loading,
    locationHook,
    methods,
    wasteRegions,
    standardUnits,
    standardUnitForLCA,
    companies.loading,
    operators.loading,
  ];

  React.useEffect(() => {
    if (calculation?.productName) dispatch(setBreadcrumb(calculation?.productName));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calculation]);

  const save = (formData, isCustom, template) => {
    let payload = {};
    if (template) {
      const semifinished = formData.calculationType.value === 'semi_finished_product';
      payload = {
        template: template?.['@id'],
        calculationType: formData.calculationType.value,
        productName: formData.productName,
        code: formData.Code,
        location: template?.location?.['@id'] || template?.location || formData.location['@id'],
        method: template?.method?.['@id'] || template?.method,
        productLifeTime: parseInt(template.productLifeTime, 10) || parseInt(formData.productLifeTime, 10),
        wasteRegion: template?.wasteScenarioSet?.['@id'] || template?.wasteScenarioSet,
        wasteScenarioSet: template?.wasteScenarioSet?.['@id'] || template?.wasteScenarioSet,
      };

      if (!semifinished) {
        payload.productType = template?.productType?.['@id'] || template?.productType;
        if ((template?.productType === '/2/product_types/3') || (template?.productType?.id === 3)) {
          payload.distanceToConstructionSite = _.parseInt(template.distanceToConstructionSite);
          payload.transportProfileToConstructionSite = template
            ?.transportProfileToConstructionSite?.['@id']
            || template?.transportProfileToConstructionSite;
        }
        /* if is standard else custom. */
        if (!template?.customFunctionalUnit) {
          payload.functionalUnit = template?.functionalUnit?.['@id'] || template?.functionalUnit;
        } else {
          payload.functionalUnitTitle = template.functionalUnitTitle;
          payload.functionalUnitDescription = template.functionalUnitDescription;
          payload.standardUnit = template?.standardUnit?.['@id'] || template?.standardUnit;
        }
        payload.customFunctionalUnit = template?.customFunctionalUnit;
      } else {
        payload.standardUnit = template?.standardUnit;
      }
    } else {
      const semifinished = formData.calculationType.value === 'semi_finished_product';
      payload = {
        calculationType: calculation.calculationType,
        productName: formData.productName,
        code: formData.Code,
        location: formData.location['@id'],
        method: formData.method['@id'],
        productLifeTime: parseInt(formData.productLifeTime, 10),
        wasteRegion: formData?.wasteRegion?.['@id'],
        wasteScenarioSet: formData?.wasteRegion?.['@id'],
      };

      if (calculation?.template && !template) payload.template = null;

      if (!formData.method.publisher && formData.publisher['@id']) {
        payload.publisher = formData.publisher['@id'];
      }
      if (!formData.method.operator && formData.operator['@id']) {
        payload.operator = formData.operator['@id'];
      }

      // Functional Units
      if (!semifinished) {
        payload.productType = formData.productType && formData.productType['@id'];
        if (formData?.productType?.id === 3) {
          payload.distanceToConstructionSite = _.parseInt(formData.distanceToConstructionSite);
          payload.transportProfileToConstructionSite = formData
            .transportProfileToConstructionSite['@id'];
        }
        /* if is standard else custom. */
        if (!isCustom) {
          payload.functionalUnit = formData.functionalUnit['@id'];
        } else {
          payload.functionalUnitTitle = formData.functionalUnitTitle;
          payload.functionalUnitDescription = formData.functionalUnitDescription;
          payload.standardUnit = formData.standardUnit['@id'];
        }
        payload.customFunctionalUnit = isCustom;
      } else {
        payload.standardUnit = formData.standardUnit['@id'];
      }
    }

    if (folderId && folderId !== 'overview') payload.folder = folderId;

    return calculations
      .save(calculation.id, payload)
      .then((r) => {
        execute();
        h.push(`/calculation/${r.id}/basics/view`, { routeName: r?.productName });
      })
      .catch(handleFailSubmit);
  };

  if (!canEdit) return <Redirect to="view" />;

  return (
    <S.Background isEditable>
      <S.HeaderTitle>{t('Change calculation basics')}</S.HeaderTitle>

      <Placeholder loaders={loaderList}>
        <BasicsForm
          {...props}
          calculationType={calculationType}
          // Functional Unit
          companies={companies.data}
          customFunctionalUnit={calculation.customFunctionalUnit}
          customUnitForLCA={customUnitForLCA}
          folderId={folderId}
          initialValues={{
            calculationType: calculationType.item,
            productName: calculation.productName,
            Code: calculation.code,
            productType: methods.productType,
            location: locationHook.selected,
            method: methods?.list?.find(m => m['@id'] === calculation?.method?.['@id'])
            || calculation.method,
            productLifeTime: calculation.productLifeTime,
            wasteRegion: wasteRegions.selected,
            standardUnit: (calculation.calculationType === 'product')
              ? customUnitForLCA.selected : (customUnitForLCA.selected || standardUnits.selected),
            // Functional Unit
            functionalUnit: standardUnitForLCA.selected,
            functionalUnitTitle: customUnitForLCA.title,
            functionalUnitDescription: customUnitForLCA.description,
            // Transport
            distanceToConstructionSite: methods.distanceToConstructionSite,
            transportProfileToConstructionSite: methods.transportProfileToConstructionSite,
            chooseTemplate: calculation?.template,
            publisher: calculation?.publisher,
            operator: calculation?.operator,
          }}
          isEdit
          location={locationHook}
          locationSelected={locationHook.selected}
          methods={methods}
          operators={operators.data}
          saveForm={save}
          standardUnitForLCA={standardUnitForLCA}
          standardUnits={standardUnits}
          templates={templates?.data}
          wasteRegions={wasteRegions}
        />
      </Placeholder>
    </S.Background>
  );
};
