import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAsync } from 'react-async-hook';
import { inputs, methods as methodsService, countries as countriesService } from 'services';
import { Placeholder } from 'components/Splash/Loading';
import * as S from 'routes/GlobalStyle.styled';
import PdisForm from './PdisForm';
import { networkErrorToast } from '../../../../../config/toast';

const EditPdis = ({ pdis }) => {
  const h = useHistory();
  const { t } = useTranslation();

  const { result, loading } = useAsync(() => methodsService.all(), []);
  const {
    result: countries,
    loading: countriesLoading,
  } = useAsync(() => countriesService.getWasteRegions(), []);

  const loaderList = [loading, countriesLoading];

  const save = (values) => {
    const pdisInfo = {};

    if (pdis?.predefinedInputSetUsages?.lenght === 0) {
      if (values.methodLabel) pdisInfo.method = values?.methodLabel?.['@id'];
      if (values.WasteScenarioSet) pdisInfo.wasteScenarioSet = values?.WasteScenarioSet?.['@id'];
    }

    if (values.Code) pdisInfo.code = values.Code;
    if (values.name) pdisInfo.name = values.name;
    if (values.Unit) pdisInfo.unit = values.Unit;

    return inputs.editPdis(pdis?.id, pdisInfo).then(res => {
      h.push(`/calculations/predefined-input-sets/${res?.id}`);
    }).catch(networkErrorToast);
  };

  return (
    <Placeholder loaders={loaderList}>
      <S.Background isEditable>
        <S.HeaderTitle>{t('change predefined input set basics')}</S.HeaderTitle>
        <PdisForm
          initialValues={{
            Code: pdis?.code,
            name: pdis?.name,
            Unit: pdis?.unit,
            methodLabel: pdis?.method,
            WasteScenarioSet: pdis?.wasteScenarioSet,
          }}
          isEdit
          methods={result?.methods}
          pdis={pdis}
          saveForm={save}
          wasteScenarioSets={countries}
        />
      </S.Background>
    </Placeholder>
  );
};

export default EditPdis;
