import _ from 'lodash';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { folders as folderService } from 'services';
import * as actions from 'state/calculations/calculations.actions';
import { actionsHoc } from 'config/bulk.helpers';

import BulkActionsBar from 'components/BulkActionsBar/BulkActionsBar';
import Button from 'components/Button/Button';
import CalculationItem from 'components/CalculationItem/CalculationItem';
import FolderItem from 'components/FolderItem/FolderItem';
import LastChangedStatus from 'components/TopBar/LastChangedStatusCalculations';
import Name from 'components/TopBar/NameCalculations';
import Type from 'components/TopBar/Name';
import NewFolder from 'components/Modal/ModalContent/Folder';
import TopBar from 'components/TopBar/TopBar';
import TopbarActions from 'components/TopbarActions/TopbarActions';
import { NoContent } from 'components/Splash/Loading';
import { Prompt } from 'components/Modal';
import Spinner from 'components/Spinner';
import * as S from './Overview.styled';

const selector = (state) => _.pick(state, ['calculations', 'company']);

const CalculationsList = () => {
  const { t } = useTranslation();
  const { folderId } = useParams();
  const dispatch = useDispatch();
  const { calculations: c, company } = useSelector(selector);
  const user = useSelector(state => state.user);
  const [sortingDate, setSortingDate] = React.useState(null);
  const [sortingStatus, setSortingStatus] = React.useState(null);
  const [sortingCode, setSortingCode] = React.useState(null);
  const isOverview = folderId === 'root';
  const {
    orderBy,
    list: calculations,
    nextPage,
    loading,
    folders,
    filters,
    selection: selectedCalculations,
  } = c;
  const { selectedCompany, id } = company || {};
  const companyId = ((selectedCompany?.value === 'all') ? 'all' : selectedCompany?.id) || id;
  const refreshContext = () => {
    const params = { ...orderBy, ...filters };
    if (!isOverview) _.set(params, 'folder', folderId);
    if (isOverview) params['exists[folder]'] = false;
    if (companyId) _.set(params, 'owner', companyId);

    actions.getList({ params })(dispatch);
  };
  const selectedCalc = selectedCalculations?.length === 1
    ? calculations?.find(calc => calc['@id'] === selectedCalculations[0]) : undefined;
  const BulkBar = actionsHoc(BulkActionsBar, selectedCalculations, selectedCalc, refreshContext);

  // Populate list
  useEffect(() => {
    const params = { ...orderBy, ...filters };
    if (!isOverview) _.set(params, 'folder', folderId);
    if (isOverview) params['exists[folder]'] = false;
    if (companyId) _.set(params, 'owner', companyId);

    actions.getList({ params })(dispatch);
  },
  [isOverview, folderId, orderBy, filters, companyId, dispatch]);

  // didUnmount? Cleanup state (optimize memory)
  useEffect(() => () => dispatch(actions.reset), [dispatch]);

  const fetchData = () => {
    if (nextPage) actions.getNextPageList(nextPage)(dispatch);
  };

  return (
    <S.Wrapper>
      <S.Main>
        {user?.selectedMembership?.view !== 'Viewer'
          && (
            <TopbarActions>
              <S.ButtosContainer>
                <Link to={`${folderId}/new`}>
                  <Button style={{ marginRight: '10px' }} styling="select">
                    {t('Create calculation')}
                  </Button>
                </Link>

                <Button
                  onClick={() => Prompt.Custom(
                    <NewFolder
                      onConfirm={(value) => {
                        const payload = value;
                        payload.parent = null; // add to root unless folderID is something else.
                        if (!isOverview) payload.parent = folderId;
                        return folderService
                          .create(payload)
                          .then(() => actions.getFolders({ parent: folderId || null })(dispatch));
                      }}
                      title={t('createFolder')}
                    />,
                  )}
                  styling="select">
                  {t('createFolder')}
                </Button>
              </S.ButtosContainer>
            </TopbarActions>
          )}

        <TopBar>
          <Name hasCheckbox />
          <div style={{ display: 'flex' }}>
            <div style={{ width: '17rem' }}>
              <Type
                inverted
                label="Code.label"
                onOrderBy={() => {
                  if (sortingDate) {
                    dispatch(actions.sortLastModified(null));
                    setSortingDate(null);
                  }
                  if (sortingStatus) {
                    dispatch(actions.sortStatus(null));
                    setSortingStatus(null);
                  }
                  if (!sortingCode) {
                    dispatch(actions.orderByCode('asc'));
                    setSortingCode('asc');
                  } else if (sortingCode === 'asc') {
                    dispatch(actions.orderByCode('desc'));
                    setSortingCode('desc');
                  } else {
                    dispatch(actions.orderByCode(null));
                    setSortingCode(null);
                  }
                }}
                order={sortingCode}
              />
            </div>
            <div style={{ marginRight: '9rem' }}>
              <Type label="Type" noSorting />
            </div>
            <LastChangedStatus
              hasStatus
              inverted
              onOrderByDate={() => {
                if (sortingCode) {
                  dispatch(actions.orderByCode(null));
                  setSortingCode(null);
                }
                if (sortingStatus) setSortingStatus(null);
                if (!sortingDate) setSortingDate('asc');
                else if (sortingDate === 'asc') setSortingDate('desc');
                else setSortingDate(null);
              }}
              onOrderByStatus={() => {
                if (sortingCode) {
                  dispatch(actions.orderByCode(null));
                  setSortingCode(null);
                }
                if (sortingDate) setSortingDate(null);
                if (!sortingStatus) setSortingStatus('asc');
                else if (sortingStatus === 'asc') setSortingStatus('desc');
                else setSortingStatus(null);
              }}
              order={sortingDate}
              orderStatus={sortingStatus}
            />
          </div>
        </TopBar>

        {loading ? (
          <div
            style={{
              height: 'calc(100vh - 4rem)',
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            <Spinner />
          </div>
        ) : (
          <S.OverviewRows id="overviewRowsId">
            {_.chain(folders)
              .filter((folder) => {
                if (isOverview) return !folder.parent;
                return !!folder.parent;
              })
              .map((f) => f && <FolderItem key={f.id} folder={f} />)
              .value()}

            <InfiniteScroll
              dataLength={calculations?.length}
              hasMore={nextPage}
              loader={<Spinner />}
              next={fetchData}
              scrollableTarget="overviewRowsId"
              scrollThreshold={0.4}
              style={{ overflow: 'unset' }}>
              {!calculations.length ? (
                <div
                  style={{
                    height: '70vh',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}>
                  {NoContent}
                </div>
              ) : (
                _.map(calculations, (data) => (
                  <CalculationItem
                    key={data.id}
                    calculation={data}
                    refreshContext={refreshContext}
                    selected={_.includes(selectedCalculations, data['@id'])}
                    selection={[data['@id']]}
                    type={data?.calculationType === 'product'
                      ? t('product') : t('semiFinishedProduct.label')}
                  />
                ))
              )}
            </InfiniteScroll>
          </S.OverviewRows>
        )}

        {!_.isEmpty(selectedCalculations) && <BulkBar count={selectedCalculations.length} />}
      </S.Main>
    </S.Wrapper>
  );
};

export default CalculationsList;
