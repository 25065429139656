import React, { useState } from 'react';
import swal from '@sweetalert/with-react';
import i18n from 'i18next';

import { isReference } from 'utils';

import Button from 'components/Button/Button';

import closeIcon from 'assets/close2.svg';
import { Header, Close, ButtonsWrapper } from '../styled';

const cancelPrompt = () => swal.close();

export const Heading = ({ title, loading }) => (
  <Header>
    <div style={{ marginRight: '1.4rem' }}>{title}</div>

    <Close disabled={loading} onClick={cancelPrompt} type="button">
      <img alt="icon" src={closeIcon} />
    </Close>
  </Header>
);

export const ButtonSet = ({
  loading, onConfirm, disabled, confirmText,
}) => (
  <ButtonsWrapper>
    <Button
      disabled={loading}
      onClick={cancelPrompt}
      style={{ marginRight: '1rem' }}
      styling="underline">

      {i18n.t('cancel')}
    </Button>

    <Button
      disabled={disabled || loading}
      loading={loading}
      onClick={onConfirm}
      style={{ margin: 0 }}
      styling="white">
      {i18n.t(confirmText) || i18n.t('confirm')}
    </Button>
  </ButtonsWrapper>
);

const DefaultContent = ({
  options: {
    content: Content, text, title, onConfirm, returnPromise,
  },
}) => {
  const [loading, setLoading] = useState(false);

  const handleConfirm = () => {
    const confirm = swal.getState().actions.confirm.value;
    if (returnPromise) swal.setActionValue({ cancel: onConfirm });
    else swal.setActionValue({ cancel: confirm });
    setLoading(true);
    return onConfirm(confirm)
      .finally(() => {
        swal.stopLoading();
        setLoading(false);
        swal.close();
      });
  };

  return (
    <div>
      <Heading loading={loading} title={title} />

      {text && <p>{text}</p>}
      {isReference(Content) ? <Content /> : Content}

      <ButtonSet loading={loading} onConfirm={handleConfirm} />
    </div>
  );
};

export default ({
  content, text, title, onConfirm, ...rest
}) => swal({
  content: (
    <DefaultContent
      options={{
        content, text, title, onConfirm, ...rest,
      }}
    />
  ),
  button: false,
  ...rest,
});
