import React, { useEffect, useState } from 'react';
import { reduxForm, Form, Field } from 'redux-form';
import Button from 'components/Button/Button';
import { FormInput } from 'components/Input/Input';
import { FormSelect } from 'components/Select/Select';
import { FormFileUpload } from 'components/FileUpload/FileUpload';
import { FormRadio } from 'components/Radio/Radio';
import { required } from 'routes/Calculations/Detail/Basics/validation';
import { useTranslation } from 'react-i18next';
import { downloadFile } from 'utils';
import { useHistory, useParams } from 'react-router-dom';
import { InputWidth } from 'config/style';
import MainModal from 'components/Modal';

const style = {
  one: {
    fontSize: '.875rem',
    marginBottom: '.8rem',
    display: 'block',
  },
  two: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '50%',
    marginBottom: '2.25rem',
  },
  three: {
    display: 'flex',
    width: InputWidth,
    justifyContent: 'space-between',
  },
};

const SupplierForm = ({
  handleSubmit,
  handleSubmitValues,
  isEdit,
  countries,
  pristine,
  invalid,
  submitting,
  logo,
  deleteFile,
  onDeleteItem,
  reset,
  pathname,
  approachedForCompanyEPD,
}) => {
  const { t } = useTranslation();

  const history = useHistory();
  const { id } = useParams();

  useEffect(
    () => {
      reset();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname],
  );

  const [radio, setRadio] = useState(approachedForCompanyEPD || false);
  const handleRadio = ({ target }) => setRadio(target.value === 'yes');
  const [hasEditedForm, setHasEditedForm] = useState(false);

  const types = [
    {
      label: t('by letter'),
      value: 1,
    },
    {
      label: t('by email'),
      value: 0,
    },
  ];

  return (
    <>
      <Form
        onSubmit={handleSubmit((values) => {
          setHasEditedForm(true);
          return handleSubmitValues(values, radio);
        })}>
        <Field
          component={FormInput}
          name="name"
          props={{
            name: 'name',
            placeholder: t('type name'),
          }}
          validate={isEdit ? null : required}
        />
        <Field
          component={FormInput}
          name="code"
          props={{
            name: 'unique id supplier',
            placeholder: t('type code'),
          }}
          validate={isEdit ? null : required}
        />
        <Field
          component={FormInput}
          name="address"
          props={{
            name: 'address',
            placeholder: t('type address'),
          }}
          validate={isEdit ? null : required}
        />
        <Field
          component={FormInput}
          name="postalCode"
          props={{
            name: 'zip code',
            placeholder: t('type zip code'),
          }}
          validate={isEdit ? null : required}
        />
        <Field
          component={FormInput}
          name="city"
          props={{
            name: 'city',
            placeholder: t('type city'),
          }}
          validate={isEdit ? null : required}
        />
        <Field
          component={FormSelect}
          name="country"
          props={{
            name: 'country',
            options: countries,
            placeholder: t('select country'),
            isSearchable: true,
          }}
          validate={isEdit ? null : required}
        />

        <Field
          component={FormInput}
          name="documentType"
          props={{
            name: 'received document type',
            placeholder: t('type document type'),
          }}
        />
        {isEdit ? (
          <Field
            component={FormFileUpload}
            name="logoImageFile"
            props={{
              url: logo,
              id: 'logoImageFile',
              downloadFile,
              deleteFile,
              name: 'attachment',
            }}
          />
        ) : null}
        <Field
          component={FormSelect}
          name="approachedBy"
          props={{
            name: 'approachedBy',
            options: types,
            placeholder: t('approachedBy.label'),
          }}
          validate={isEdit ? null : required}
        />
        <div>
          <div style={style.one}>
            {t('supplier approached for company specific environmental data.label')}
          </div>
          <div style={style.two}>
            <FormRadio
              checked={radio}
              id="radio-yes"
              onChange={handleRadio}
              style={{ marginRight: '4.2rem' }}
              translation={{ placeholder: t('yes') }}
              value="yes"
            />

            <FormRadio
              checked={!radio}
              id="radio-no"
              onChange={handleRadio}
              translation={{ placeholder: t('no') }}
              value="no"
            />
          </div>
        </div>

        <div style={style.three}>
          <Button
            disabled={approachedForCompanyEPD !== radio ? false : pristine || invalid}
            loading={submitting}
            styling="select"
            type="submit">
            {t(isEdit ? 'edit supplier' : 'Add supplier')}
          </Button>
          {isEdit && (
            <Button color="#C26244" onClick={onDeleteItem} styling="underline">
              {t('remove supplier')}
            </Button>
          )}
          <Button
            onClick={() => history
              .push(isEdit
                ? `/locations-&-suppliers/suppliers/${id}` : '/locations-&-suppliers/suppliers')}
            styling="underline">
            {t('cancel')}
          </Button>
        </div>
      </Form>
      <MainModal
        content="prompt"
        exitModalContent={{
          navigateSideEffects: () => null,
        }}
        prompt
        promptCondition={!pristine && !hasEditedForm}
      />
    </>
  );
};

export default reduxForm({
  form: 'new-supplier',
  enableReinitialize: true,
})(SupplierForm);
