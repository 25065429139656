import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory, Redirect, Link } from 'react-router-dom';

import { users as usersService, companies } from 'services';

import Button from 'components/Button/Button';
import ListItem from 'components/ListItem/ListItem';
import MainModal from 'components/Modal';
import Name from 'components/TopBar/Name';
import TopBar from 'components/TopBar/TopBar';
import TopbarActions from 'components/TopbarActions/TopbarActions';
// import { Loading } from 'components/Splash/Loading';
import Spinner from 'components/Spinner';
import { confirmationModalSetup } from 'components/Modal/ModalContent/Delete';

import edit from 'assets/edit.svg';
import deleteIcon from 'assets/delete.svg';

import * as S from 'routes/Calculations/Overview.styled';
import InfiniteScroll from 'react-infinite-scroll-component';
import { alertToast } from 'config/toast';

const Users = ({ company: companyValue, input: inputValue, setTotalNumberOfRecords }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const user = useSelector(state => state.user);
  const { selectedCompany, id: companyId } = useSelector(({ company }) => company || {});
  const [users, setUsers] = useState(null);
  const [page, setPage] = useState(null);
  const [confirmationModal, setConfirmationModal] = useState(null);
  const [loading, setLoading] = useState(false);
  const [sorting, setSorting] = useState('asc');

  const companyAdmin = user?.selectedMembership?.view === 'Company Admin'
    || user?.selectedMembership?.view === 'Corporate Admin';

  const superAdmin = user?.selectedMembership?.view === 'Super Admin'
    || user?.selectedMembership?.view === 'Nibe Employee';

  const isClickable = superAdmin || companyAdmin;
  const isEditable = companyId === selectedCompany?.id;

  useEffect(() => {
    const params = { 'order[name]': sorting };
    if (companyValue && superAdmin) params['memberships.company'] = companyValue.id;
    if (inputValue) params.name = inputValue;
    setTotalNumberOfRecords(null);
    setLoading(true);
    usersService
      .getAll({ params })
      .then((values) => {
        setUsers(values.users);
        setPage(values.nextPage);
        setTotalNumberOfRecords(values.totalItems);
      })
      .finally(() => setLoading(false));
  }, [companyValue, inputValue, setTotalNumberOfRecords, sorting, superAdmin]);

  const onDeleteItem = (data) => {
    if (user?.selectedMembership?.view === 'Company Admin') {
      confirmationModalSetup(setConfirmationModal, (closeModal) => {
        usersService.getUserMemberships(
          data.id,
          { params: { user: data.id, company: user?.selectedMembership?.company?.id } },
          { value: false },
        ).then(v => {
          usersService.deleteUserMembership(v?.users[0]?.id)
            .then(() => {
              setUsers(users.filter(item => item.id !== data?.id));
              closeModal();
            });
        });
      });
    } else if (user?.selectedMembership?.view === 'Corporate Admin') {
      companies.getAll({ params: { parentCompany: user?.selectedMembership?.company['@id'] } })
        .then(c => {
          // eslint-disable-next-line no-unused-expressions
          c?.companies?.shift();
          usersService.getUserMemberships(
            data.id,
            { params: { user: data.id, company: c?.companies?.map(comp => comp?.id) } },
            { value: false },
          ).then(v => {
            if (v?.users && v?.users?.length > 0) {
              // eslint-disable-next-line max-len
              alertToast('The user can’t be removed since there are memberships currently assigned to this user', 'error');
            } else {
              confirmationModalSetup(setConfirmationModal, (closeModal) => {
                usersService.getUserMemberships(
                  data.id,
                  { params: { user: data.id, company: user?.selectedMembership?.company?.id } },
                  { value: false },
                ).then(v => {
                  usersService.deleteUserMembership(v?.users[0]?.id)
                    .then(() => {
                      setUsers(users.filter(item => item.id !== data?.id));
                      closeModal();
                    });
                });
              });
            }
          });
        });
    } else {
      confirmationModalSetup(setConfirmationModal, (closeModal) => {
        usersService
          .deleteUser(data?.id)
          .then(() => {
            setUsers(users.filter(item => item.id !== data?.id));
            closeModal();
          });
      });
    }
  };

  const onEditItem = (data) => history
    .push(`/manage/users/${data.id}/edit`, { routeName: data.name });

  const ListItemContent = ({ data }) => (
    <>
      <S.Option
        key="edit"
        isClickable={isClickable && isEditable}
        onClick={() => isEditable && onEditItem(data)}>
        <div>
          <img alt="icon" src={edit} />
        </div>

        <S.StyledLabel isRegular>
          {t('edit')}
        </S.StyledLabel>
      </S.Option>

      <S.Option key="delete" isClickable={isClickable} onClick={() => onDeleteItem(data)}>
        <div>
          <img alt="icon" src={deleteIcon} />
        </div>

        <S.StyledLabel color="#BF2E00" isRegular>
          {t('delete')}
        </S.StyledLabel>
      </S.Option>
    </>
  );

  const fetchData = () => {
    if (page) {
      usersService
        .getNextPage(page)
        .then((values) => {
          setPage(values.nextPage);
          setUsers(users.concat(values.users));
          setTotalNumberOfRecords(values.totalItems);
        });
    }
  };

  const usersList = () => (
    <S.OverviewRows id="overviewRowsId">
      <InfiniteScroll
        dataLength={users.length}
        hasMore={page}
        loader={<Spinner />}
        next={fetchData}
        scrollableTarget="overviewRowsId"
        scrollThreshold={0.4}
        style={{ minHeight: '50vh' }}>
        {_.map(users, (data) => (
          <ListItem
            key={data.id}
            dropdownContent={(<ListItemContent data={data} />)}
            name={data.name}
            to={{
              pathname: `/manage/users/${data.id}`,
              state: {
                routeName: data.name,
              },
            }}
          />
        ))}
      </InfiniteScroll>
    </S.OverviewRows>
  );

  if (!superAdmin && !companyAdmin) return <Redirect to="/" />;

  return (
    <>
      {(superAdmin || companyAdmin) && (
        <TopbarActions>
          <Button
            as={Link}
            styling="select"
            to="/manage/users/new">
            {t('add new user')}
          </Button>
        </TopbarActions>
      )}

      <TopBar>
        <Name onOrderBy={setSorting} order={sorting} />
      </TopBar>

      {!users || loading
        ? (
          <div
            style={{
              height: 'calc(100vh - 4rem)',
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            <Spinner />
          </div>
        )
        : usersList()}

      <MainModal
        confirmationModalInfo={confirmationModal}
        content="delete"
        isModalOpen={confirmationModal}
        setIsModalOpen={setConfirmationModal}
      />
    </>
  );
};

export default Users;
