/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-one-expression-per-line */
import _ from 'lodash';
import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { setMembership } from 'state/user/user.actions';
import membershipsSvg from 'assets/memberships.svg';
import Footer from 'components/Footer/Footer';
import { useAsyncCallback } from 'react-async-hook';
import { users as usersService, companies } from 'services';
import { Placeholder } from 'components/Splash/Loading';
import Input from 'components/Input/Input';
import { bold } from 'utils';
import * as S from './RethinkDashboard.styled';
import Dashboard from './Dashboard';

const RethinkDashboard = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector(state => state.user);
  const [clearInput, setClearInput] = useState(null);
  const [recents, setRecents] = useState([]);
  const [memberships, setResult] = useState([]);
  const [membershipsOriginal, setResultOriginal] = useState([]);
  const [companiesOriginal, setCompaniesOriginal] = useState([]);
  const [companiesList, setCompaniesResult] = useState([]);
  const time = useRef(0);

  const superAdmin = user?.selectedMembership?.view === 'Super Admin'
    || user?.selectedMembership?.view === 'Nibe Employee';

  const { loading, execute } = useAsyncCallback(() => usersService
    .getUserMemberships(user?.id).then(values => {
      const roles = values.users.map(r => r.roles.map(v => {
        const view = v?.view === 'Super Admin'
          ? v?.view?.replace('Super Admin', 'Ultra Admin') : v?.view;
        return {
          ...r,
          role: v,
          roleName: v?.name,
          view: v?.view,
          label: bold(r?.company?.name, view),
          value: `${r?.company?.name} | ${view}`,
        };
      })).flat();
      const arr = _.compact(roles?.map(v => {
        const view = v?.role?.view === 'Super Admin'
          ? v?.role?.view?.replace('Super Admin', 'Ultra Admin') : v?.role?.view;
        return {
          ...v,
          label: bold(v?.company?.name, view),
          value: `${v?.company?.name} | ${view}`,
        };
      }));
      setResult(arr);
      setResultOriginal(arr);
      setRecents(arr);
    }), []);

  useEffect(() => {
    const membership = memberships?.find(m => m.id === user.selectedMembership?.id);
    if (membership) {
      const view = membership?.view === 'Super Admin'
        ? membership?.view?.replace('Super Admin', 'Ultra Admin') : membership?.view;
      dispatch(setMembership({
        ...membership,
        label: bold(membership?.company?.name, view),
        value: `${membership?.company?.name} | ${view}`,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.selectedMembership?.id, memberships]);

  const {
    loading: companiesLoader, execute: companiesExecute,
  } = useAsyncCallback(() => {
    const params = { 'order[name]': 'asc' };
    if (user?.selectedMembership?.view === 'Corporate Admin') {
      params.parentCompany = user?.selectedMembership?.company?.id;
    }
    return companies
      .getAll({ params }).then(v => {
        // eslint-disable-next-line no-unused-expressions
        v?.companies?.shift();
        const comp = _.sortBy(v?.companies?.filter(c => !c?.corporation), c => c?.name);
        setCompaniesResult(comp);
        setCompaniesOriginal(comp);
      });
  }, []);

  const highRoles = (user?.selectedMembership?.view === 'Corporate Admin')
    || (user?.selectedMembership?.view === 'Super Admin')
    || (user?.selectedMembership?.view === 'Nibe Employee');

  useEffect(() => {
    execute();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.id]);

  useEffect(() => {
    if (highRoles) companiesExecute();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [highRoles, user.selectedMembership?.id]);

  const handleLogout = () => history.push('/logout');

  const Card = ({ maxFour, ...r }) => {
    const view = r?.view === 'Super Admin'
      ? r?.view?.replace('Super Admin', 'Ultra Admin') : r?.view;
    return (r?.value === user?.selectedMembership?.value) ? null : (
      <S.CardContainer
        maxFour={maxFour}
        onClick={() => {
          execute();
          dispatch(setMembership({
            ...r,
            label: bold(r?.company?.name, view),
            value: `${r?.company?.name} | ${view}`,
          }));
          if (maxFour) {
            const tempRecents = membershipsOriginal?.filter(rec => rec?.value !== r?.value);
            setRecents(tempRecents);
          } else {
            const tempMemberships = membershipsOriginal?.filter(rec => rec?.value !== r?.value);
            setResult(tempMemberships);
          }
        }}>
        <div>
          <S.MembershipName>{r?.company?.name}</S.MembershipName>
          <S.Role>{view}</S.Role>
        </div>
        <S.Options alt="icon" src={membershipsSvg} />
      </S.CardContainer>
    );
  };

  const Company = (v) => (
    <S.CardContainer onClick={() => {
      dispatch(setMembership({
        ...v,
        company: {
          id: v?.id,
          name: v?.name,
          '@id': v?.['@id'],
        },
        roleName: 'ROLE_VIEWER',
        view: 'Viewer',
        label: bold(v?.name, 'Viewer'),
        value: `${v?.name} | Viewer`,
      }));
    }}>
      <div>
        <S.MembershipName>{v?.name}</S.MembershipName>
        <S.Role>View user</S.Role>
      </div>
      <S.Options alt="icon" src={membershipsSvg} />
    </S.CardContainer>
  );

  const membershipView = user?.selectedMembership?.view;

  useEffect(() => {
    if (!membershipView && !loading) {
      if (memberships?.length === 1 && memberships[0]?.roles?.length === 1) {
        const view = memberships[0]?.roles[0]?.view === 'Super Admin'
          ? memberships[0]?.roles[0]?.view?.replace('Super Admin', 'Ultra Admin')
          : memberships[0]?.roles[0]?.view;
        dispatch(setMembership({
          ...memberships[0],
          roleName: memberships[0]?.roles[0]?.name,
          view: memberships[0]?.roles[0]?.view,
          label: bold(memberships[0]?.company?.name, view),
          value: `${memberships[0]?.company?.name} | ${view}`,
        }));
      } else {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < memberships.length; ++i) {
          const m = memberships[i];
          const u = m?.roles?.find(r => r?.view === 'Super Admin'
            || r?.view === 'Nibe Employee' || r?.view === 'Corporate Admin');
          if (u) {
            const view = u?.view === 'Super Admin'
              ? u?.view?.replace('Super Admin', 'Ultra Admin') : u?.view;
            dispatch(setMembership({
              ...m,
              roleName: u?.name,
              view: u?.view,
              label: bold(m?.company?.name, view),
              value: `${m?.company?.name} | ${view}`,
            }));
            break;
          }
        }
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memberships, membershipView, loading]);

  const onFocus = () => setClearInput(null);

  const handleInputChange = (e, type) => {
    if (time.current) clearTimeout(time.current);
    const inputText = e.target.value;
    time.current = setTimeout(() => {
      const clearSpaces = inputText && inputText.length > 0 && inputText.trim();
      if (clearSpaces) {
        clearTimeout(time.current);
        if (type === 'name') {
          const res = membershipsOriginal
            ?.filter(m => m?.company?.name?.toLowerCase()?.includes(inputText?.toLowerCase()));
          setResult(res);
        } else {
          setCompaniesResult(companiesOriginal
            ?.filter(m => m?.name?.toLowerCase()?.includes(inputText?.toLowerCase())));
        }
      } else if (type === 'name') setResult(membershipsOriginal);
      else setCompaniesResult(companiesOriginal);
    }, 2000);
  };

  if (user?.selectedMembership && !(superAdmin
    || user?.selectedMembership?.view === 'Corporate Admin')) return <Dashboard />;

  return (
    <Placeholder loaders={[loading, companiesLoader]}>
      {memberships && (memberships?.length <= 4) && !highRoles
        ? (
          <S.Container noFlex>
            <S.Wrapper>
              <S.Name>
                {`${t('welcome back')}, `}<S.UserName>{user?.name}</S.UserName>.
              </S.Name>
              {memberships && (memberships?.length > 0)
                ? (
                  <>
                    <S.Name>{t('Please choose a membership to continue with RETHiNK')}:</S.Name>
                    {memberships?.map(m => (
                      <Card maxFour {...m} />
                    ))}
                  </>
                ) : <S.Name>{t('no active memberships')}</S.Name>}
              <div style={{ marginTop: '2rem' }}>
                <S.Name>{`${t('not')} `}</S.Name>
                <S.Name>
                  {user?.name}
                </S.Name>? <S.SignOut onClick={handleLogout}>{t('sign out')}</S.SignOut>
              </div>
            </S.Wrapper>
          </S.Container>
        ) : (
          <S.MajorContainer>
            {/* <S.Title>{t('recently accessed memberships')}</S.Title>
            <S.List>
              {recents?.filter(re => re?.value !== user?.selectedMembership?.value)
              ?.slice(0, 4)?.map(m => (
                <Card {...m} />
              ))}
            </S.List> */}
            <div
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                // marginTop: '86px',
              }}>
              <S.Title>{t('your memberships')}</S.Title>
              <Input
                {...clearInput}
                containerStyle={{ width: 'calc((100% - 80px) / 3)' }}
                id="name"
                name="name"
                onChange={(e) => handleInputChange(e, 'name')}
                onFocus={onFocus}
                placeholder={t('filter by name')}
              />
            </div>
            <S.List>
              {memberships?.map(m => (
                <Card {...m} />
              ))}
            </S.List>
            {highRoles
              && (
                <>
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      marginTop: '86px',
                    }}>
                    <S.Title>{t('companies you have access to')}</S.Title>
                    <Input
                      {...clearInput}
                      containerStyle={{ width: 'calc((100% - 80px) / 3)' }}
                      id="company"
                      name="company"
                      onChange={(e) => handleInputChange(e, 'company')}
                      onFocus={onFocus}
                      placeholder={t('filter by name')}
                    />
                  </div>
                  <S.List>
                    {companiesList?.map(m => (
                      <Company {...m} />
                    ))}
                  </S.List>
                </>
              )}
          </S.MajorContainer>
        )}
      <S.FooterContainer><Footer /></S.FooterContainer>
    </Placeholder>
  );
};

export default RethinkDashboard;
