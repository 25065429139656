import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Calculation, useCanEdit } from 'hooks/calculation.hooks';

import Button from 'components/Button/Button';
import { ReadOnlyField } from 'components/Field';
import HTML from 'components/RenderHTML';

import { TitleBar, ViewModeContainer } from 'routes/GlobalStyle.styled';
import { Placeholder } from 'components/Splash/Loading';
import { FileDownloadButton } from '../../../../components/FileDownloadButton';

const DefaultSubstantiations = ({ template }) => {
  const { t } = useTranslation();
  const canEdit = useCanEdit();
  let { result: data } = useContext(Calculation);

  if (template) data = template;

  const isSemi = (data?.calculationType === 'semi_finished_product');

  const loaders = [];

  return (
    <>
      <TitleBar>
        {t('information-for-report')}

        {canEdit && (
          <Button as={Link} styling="select" to="edit">
            {t('edit')}
          </Button>
        )}
      </TitleBar>
      <Placeholder loaders={loaders}>
        <ViewModeContainer>
          {!isSemi && (
            <ReadOnlyField name="statement emissions during use stage">
              <HTML html={(data?.template?.noEmissionsDeclaration || '')?.concat(data.noEmissionsDeclaration || '')} />
            </ReadOnlyField>
          )}

          <ReadOnlyField name="source Reference Service Life (Rsl) Product">
            <HTML html={(data?.template?.productSubstantiationRSL || '')?.concat(data.productSubstantiationRSL || '')} />
          </ReadOnlyField>

          <ReadOnlyField name="source Reference Service Life (Rsl) Product parts">
            <HTML html={(data?.template?.productPartSubstantiationRSL || '')?.concat(data.productPartSubstantiationRSL || '')} />
          </ReadOnlyField>

          <ReadOnlyField name="general substantiation">
            {(data.constructionWasteFile || data?.template?.constructionWasteFile) && (
            <FileDownloadButton {...(data.constructionWasteFile || data?.template?.constructionWasteFile)} />
            )}
          </ReadOnlyField>

        </ViewModeContainer>
      </Placeholder>
    </>
  );
};

export default DefaultSubstantiations;
