import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { addMembership } from 'state/user/user.actions';
import { setBreadcrumb } from 'state/sidebar/sidebar.actions';
import { useAsyncCallback } from 'react-async-hook';
import { users, companies } from 'services';
import { Placeholder } from 'components/Splash/Loading';
import NewCompanyPermissionsForm from 'routes/Users/NewCompanyPermissionsForm';
import * as S from 'routes/GlobalStyle.styled';

const NewCompanyPermissions = () => {
  const { t } = useTranslation();
  const { id: idParam, companyId } = useParams();
  const dispatch = useDispatch();
  const h = useHistory();
  const { pathname } = useLocation();
  const isEdit = pathname.includes('edit');
  const { isHidden } = useSelector(state => state.sidebar);
  const [oldCompany, setOldCompany] = useState(null);
  const [company, setCompany] = useState(null);
  const currentUser = useSelector(state => state.user);

  let id = idParam;
  if (!id && pathname.includes('user-profile')) {
    // On user profile, use current user id
    id = currentUser.id;
  }

  const params = { 'order[name]': 'asc' };
  if (currentUser?.selectedMembership?.view === 'Corporate Admin') {
    params.parentCompany = currentUser?.selectedMembership?.company?.id;
  }

  const {
    result: companiesList, execute: companiesListExecute, loading,
  } = useAsyncCallback(() => companies.getAll({ params }));

  const {
    result: membership, execute: membershipExecute, loading: membershipLoading,
  } = useAsyncCallback(() => users.getUserMembership(companyId));

  const {
    result, loading: loadingUserMemberships, execute: executeUserMemberships,
  } = useAsyncCallback(() => users.getUserMemberships(id, null, { value: false }), []);

  const {
    result: rolesList, execute: rolesExecute, loading: rolesLoading,
  } = useAsyncCallback(() => users.getUserRoles({
    companyRole: isEdit ? !membership?.company?.corporation : !company?.corporation,
  }));

  useEffect(() => {
    if (membership?.company?.name && pathname.includes('user-profile')) {
      dispatch(setBreadcrumb(membership?.company?.name));
    }
    if (membership?.user?.name && pathname.includes('manage')) {
      dispatch(setBreadcrumb(membership?.user?.name));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [membership]);

  useEffect(() => {
    companiesListExecute();
    executeUserMemberships();
    if (companyId) membershipExecute();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (oldCompany?.corporation !== company?.corporation) {
      if (!isEdit) rolesExecute();
      setOldCompany(company);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company]);

  useEffect(() => {
    if (isEdit && membership) rolesExecute();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [membership]);

  const handleSubmitValues = (values) => {
    const tempValues = values;

    const roles = _.compact(Object.values(tempValues)?.map(r => {
      if (r?.value) return r['@id'];
      return null;
    }));

    const successPath = pathname.includes('user-profile')
      ? '/user-profile/memberships' : `/manage/users/${id}/memberships`;

    if (isEdit) {
      return users.updateUserMembership(companyId, { roles })
        .then(err => {
          const error = { err }?.err?.response?.status;
          if (!error) dispatch(addMembership(true));
          h.push(successPath);
        });
    }

    const params = {
      roles,
      user: `/2/users/${id}`,
      company: company['@id'],
    };

    return users.createUserMembership(params)
      .then(err => {
        const error = { err }?.err?.response?.status;
        if (!error) dispatch(addMembership(true));
        h.push(successPath);
      });
  };

  const arr = rolesList?.data?.map(r => {
    if (membership?.roles?.some(m => m?.id === r?.id)) {
      return {
        ...r,
        label: t('yes'),
        value: true,
      };
    }
    return {
      ...r,
      label: t('no'),
      value: false,
    };
  });

  let filteredCompaniesList = _.filter(companiesList?.companies,
    c => !result?.data?.some(d => d?.company?.id === c?.id));
  // eslint-disable-next-line no-unused-expressions
  filteredCompaniesList?.shift();
  filteredCompaniesList = _.sortBy(filteredCompaniesList, c => c?.name?.toLowerCase());

  return (
    <S.Background isEditable>
      <Placeholder
        loaders={[loading, membershipLoading, loadingUserMemberships]}
        rows={3}>
        <S.HeaderTitle>
          {isEdit ? t('edit company permissions') : t('new company permissions')}
        </S.HeaderTitle>
        <S.FormContainer isHidden={isHidden}>
          <NewCompanyPermissionsForm
            companiesList={filteredCompaniesList}
            handleSubmitValues={handleSubmitValues}
            id={id}
            initialValues={{
              company: membership
                ? { label: membership?.company?.name, value: membership }
                : company && { label: company?.name, value: company },
              ROLE_ADMIN: arr?.find(r => r?.name === 'ROLE_ADMIN'),
              ROLE_EPD_ADMIN: arr?.find(r => r?.name === 'ROLE_EPD_ADMIN'),
              ROLE_SUPER_ADMIN: arr?.find(r => r?.name === 'ROLE_SUPER_ADMIN'),
              ROLE_EDITOR: arr?.find(r => r?.name === 'ROLE_EDITOR'),
              ROLE_REVIEWER: arr?.find(r => r?.name === 'ROLE_REVIEWER'),
              ROLE_CORPORATE_ADMIN: arr?.find(r => r?.name === 'ROLE_CORPORATE_ADMIN'),
              ROLE_CORPORATE_EPD_ADMIN: arr?.find(r => r?.name === 'ROLE_CORPORATE_EPD_ADMIN'),
              ROLE_VIEWER: arr?.find(r => r?.name === 'ROLE_VIEWER'),
              ROLE_NIBE_EMPLOYEE: arr?.find(r => r?.name === 'ROLE_NIBE_EMPLOYEE'),
            }}
            isEdit={isEdit}
            membershipEdit={membership}
            pathname={pathname}
            roles={arr}
            rolesLoading={rolesLoading}
            setCompany={setCompany}
          />
        </S.FormContainer>
      </Placeholder>
    </S.Background>
  );
};

export default NewCompanyPermissions;
