/* eslint-disable no-nested-ternary */
import _ from 'lodash';
import i18n from 'i18next';
import { resources } from 'i18n/i18n';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { SubmissionError } from 'redux-form';
import { setBreadcrumb } from 'state/sidebar/sidebar.actions';
import {
  Redirect, useHistory, useLocation, useParams,
} from 'react-router-dom';

import { users } from 'services';

import { confirmationModalSetup } from 'components/Modal/ModalContent/Delete';
import MainModal from 'components/Modal';
import { Placeholder } from 'components/Splash/Loading';
import UserForm from 'routes/Users/UserForm';
import Button from 'components/Button/Button';

import * as S from 'routes/GlobalStyle.styled';

const status = [
  {
    value: true,
    get label() { return i18n.t('enabled'); },
  },
  {
    value: false,
    get label() { return i18n.t('disabled'); },
  },
];

const reviewerOptions = [
  {
    value: false,
    get label() { return i18n.t('no'); },
  },
  {
    value: true,
    get label() { return i18n.t('yes'); },
  },
];

/* const getPermissions = ({ superAdmin, reviewerAdmin, companyAdmin } = {}) => {
  if (superAdmin) return i18n.t('super admin');
  if (reviewerAdmin) return i18n.t('reviewer admin');
  if (companyAdmin) return i18n.t('company admin');
  return i18n.t('default');
}; */

const NewUser = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const { id: idParam } = useParams();
  const auth = useSelector(state => state.user);
  const { isHidden } = useSelector(state => state.sidebar);
  const id = idParam || auth.id;
  const isEdit = pathname.includes('edit');

  const [autograph, setAutograph] = useState(null);
  const [confirmationModal, setConfirmationModal] = useState(null);
  const [error, setError] = useState(null);
  const [loadingAutograph, setLoadingAutograph] = useState(false);
  const [user, setUser] = useState(null);
  const [completeProfile, setCompleteProfile] = useState(false);
  // const [userCompany, setUserCompany] = useState(null);

  const companyAdmin = auth?.selectedMembership?.view === 'Company Admin'
    || auth?.selectedMembership?.view === 'Corporate Admin';

  const superAdmin = auth?.selectedMembership?.view === 'Super Admin'
    || auth?.selectedMembership?.view === 'Nibe Employee';

  useEffect(() => {
    if (user?.name) dispatch(setBreadcrumb(user?.name));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (isEdit) {
      users
        .getUser(id)
        .then(userValue => {
          if (userValue?.autographImage) {
            setLoadingAutograph(true);
            users.getUserAutograph(userValue?.autographImage['@id'])
              .then(values => {
                setAutograph(values);
                setLoadingAutograph(false);
              })
              .catch(() => setLoadingAutograph(false));
          }
          setUser(userValue);
        })
        .catch(setError);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [superAdmin]);

  const isDefaultUser = !superAdmin && !companyAdmin;

  const differentId = auth.id !== id;

  const handleSubmitValues = (values) => {
    const tempValues = values;

    if (companyAdmin && !completeProfile && pathname.includes('new')) {
      return users.inviteUser({ email: tempValues?.email }).then(v => {
        if (v?.name) {
          dispatch(setBreadcrumb(v?.name));
          history.push(
            `/manage/users/${v?.id}`, { routeName: v?.name },
          );
        } else setCompleteProfile(v);
      });
    }

    delete tempValues.repeatPassword;
    const locale = tempValues.locale ? { locale: tempValues.locale.value } : null;
    /* const companyValue = tempValues.company
      ? { company: `/2/companies/${tempValues.company.id}` } : null; */
    /* const permissions = tempValues.permissions ? { ...tempValues.permissions.value }
      : !isEdit && { companyAdmin: false, superAdmin: false, reviewerAdmin: false };

    let remainingPermissions = {}; */

    /* if (permissions.superAdmin) {
      remainingPermissions = { companyAdmin: false, reviewerAdmin: false };
    } else if (permissions.companyAdmin) {
      remainingPermissions = { superAdmin: false, reviewerAdmin: false };
    } else if (permissions.reviewerAdmin) {
      remainingPermissions = { companyAdmin: true, superAdmin: false };
    } else if (permissions.default) {
      remainingPermissions = { companyAdmin: false, superAdmin: false, reviewerAdmin: false };
    } */

    delete tempValues.permissions;
    const reviewerValue = tempValues.reviewer
      ? { reviewer: tempValues.reviewer.value } : !isEdit && { reviewer: false };
    const enabled = tempValues.enabled ? { enabled: tempValues.enabled.value }
      : !isEdit && { enabled: true };

    const userInfo = {
      ...tempValues,
      ...locale,
      // ...permissions,
      // ...companyValue,
      ...reviewerValue,
      ...enabled,
      // ...remainingPermissions,
    };

    if (userInfo.autograph) delete userInfo.autograph;

    if (isEdit || completeProfile) {
      const promises = [];
      if (tempValues.autograph && tempValues.autograph.length > 0) {
        promises.push(new Promise((resolve) => {
          const bodyFormData = new FormData();
          bodyFormData.append('file', tempValues.autograph[0]);
          resolve(users.createUserAutograph(bodyFormData).then(value => {
            userInfo.autographImage = value['@id'];
            promises.push(users.editUser(id, userInfo));
          }));
        }));
      } else promises.push(users.editUser(completeProfile?.id || id, userInfo));
      return Promise.all(promises)
        .then(() => {
          if (!idParam && !completeProfile) return history.push('/user-profile');
          return history.push(
            `/manage/users/${completeProfile?.id || id}`, { routeName: userInfo?.name },
          );
        });
    }

    return users.createUser(userInfo)
      .then((value) => {
        history
          .push(`/manage/users/${value?.id}/memberships/new`,
            {
              routeName: value?.name,
              company: value?.company,
            });

        dispatch(setBreadcrumb(value?.name));
      })
      .catch((err) => {
        if (err) {
          throw new SubmissionError({
            email: t('Email address already exists'),
          });
        }
      });
  };

  const languages = Object.keys(resources)
    .map(key => ({
      value: key,
      label: t(`${key}`),
    }));

  /* let permissions = [{
    value: { default: true },
    label: t('default'),
  }]; */

  const reviewerUserValue = reviewerOptions
    .find(r => r.value === user?.reviewer);

  const deleteFile = () => confirmationModalSetup(
    setConfirmationModal,
    (closeModal) => users
      .editUser(id, { autographImage: null })
      .then(() => {
        setAutograph(null);
        closeModal();
      }),
  );

  const onDeleteItem = () => confirmationModalSetup(
    setConfirmationModal,
    () => users.deleteUser(completeProfile?.id || id)
      .then(() => history.push('/manage/users')),
  );

  /* if (companyAdmin || superAdmin) {
    permissions.push({
      value: { companyAdmin: true },
      label: t('company admin'),
    });
  }

  if (superAdmin) {
    permissions = _.concat(permissions, [
      {
        value: { reviewerAdmin: true },
        label: t('reviewer admin'),
      },
      {
        value: { superAdmin: true },
        label: t('super admin'),
      },
    ]);
  } */

  const loaders = [
    loadingAutograph, (!user /* && (isEdit && !userCompany) */ && (isEdit && !autograph) && !error),
  ];

  if (
    (!isEdit && isDefaultUser) || (isDefaultUser && isEdit && differentId)
  ) return <Redirect to="/" />;

  return (
    <S.Background isEditable>
      <Placeholder loaders={loaders}>
        <S.HeaderTitle style={{ marginBottom: '3.68rem' }}>
          {t(completeProfile ? 'complete profile' : isEdit ? 'account' : 'new user')}
          {(differentId || completeProfile)
            && (
              <Button
                onClick={onDeleteItem}
                style={{
                  margin: 0,
                  padding: '.75rem auto',
                  position: 'absolute',
                  right: 36,
                }}
                styling="select">
                {t('delete user')}
              </Button>
            )}
        </S.HeaderTitle>

        <S.FormContainer isHidden={isHidden}>
          <UserForm
            autograph={autograph}
            completeProfile={completeProfile}
            deleteFile={deleteFile}
            differentId={differentId}
            handleSubmitValues={handleSubmitValues}
            initialValues={{
              name: user?.name,
              locale: _.find(languages, ({ value }) => value === user?.locale),
              // company: userCompany,
              /* permissions: isEdit && _.find(
                permissions,
                ({ label }) => label === (user && getPermissions(user)),
              ), */
              reviewer: reviewerUserValue,
              enabled: _.find(status, ({ value }) => value === user?.enabled),
            }}
            isDefaultUser={isDefaultUser}
            isEdit={isEdit}
            languages={languages}
            onDeleteItem={onDeleteItem}
            pathname={pathname}
            // permissions={permissions}
            reviewerOptions={reviewerOptions}
            status={status}
            user={user}
          />
        </S.FormContainer>

        <MainModal
          confirmationModalInfo={confirmationModal}
          content="delete"
          isModalOpen={confirmationModal}
          setIsModalOpen={setConfirmationModal}
        />
      </Placeholder>
    </S.Background>
  );
};

export default NewUser;
