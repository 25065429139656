import _ from 'lodash';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';

import { useUnit, useWeight } from 'hooks/input.hooks';

import Pricing from 'components/Pricing/Pricing';
import Spinner from 'components/Spinner';
import { useCalculationContext } from 'hooks/calculation.hooks';

import * as S from './RealtimeCards.styled';
import { useRealTimeResultsContext } from '../../hooks/realTimeResults.hooks';

const RealtimeCards = () => {
  const { t } = useTranslation();
  const { t: translation } = useTranslation();
  const { result: calculation } = useCalculationContext();

  // TODO Falta failed
  const { results, loading, failed } = useRealTimeResultsContext();
  const { overall } = results || {};
  let { url } = useRouteMatch();
  const unit = useUnit(calculation);
  const { weight, loading: weightLoading } = useWeight();

  // Fix appended slash when /routing
  if (url.endsWith('/')) {
    url = url.substring(0, url.length - 1);
  }

  const pathname = `${url}/realtime/results`;

  const hold = content => {
    if (loading) return <Spinner />;
    if (failed) return '-';

    return content;
  };

  const co2 = null; // TODO: still need co2 API value

  return (
    <S.InfoContainer>
      <S.InfoWrapper>
        <S.InfoBox>
          <p>{t('weight')}</p>
          {unit ? <sup>{` kg / ${unit}`}</sup> : null}
          {hold((
            <p>
              {weightLoading ? '-' : _.round(weight, 8)}
            </p>
          ))}
        </S.InfoBox>
        <S.InfoBox>
          <p>
            {t(calculation?.method?.onePointScoreAbbreviation)}
          </p>
          <p>{calculation?.method?.onePointScoreUnit}</p>
          {hold(overall ? <Pricing cost={_.round(overall, 8)} /> : '-')}
        </S.InfoBox>
        {co2
          && (
            <S.InfoBox>
              <p>{translation('co2')}</p>
              {hold((
                <p>
                  {weight}
                </p>
              ))}
            </S.InfoBox>
          )}
      </S.InfoWrapper>

      <S.InfoLink to={pathname}>
        {t('To real time results')}
      </S.InfoLink>
    </S.InfoContainer>
  );
};

export default memo(RealtimeCards);
