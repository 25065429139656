import styled from 'styled-components';
import { MAX_WIDTH } from 'config/style';

export const Container = styled.div`
  max-width: ${MAX_WIDTH};
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: rgba(168, 168, 168, 0.7);
  font-size: 12px;
  font-weight: normal;
`;

export const StyledFooter = styled.div``;

export const StyledContainer = styled.div`
  display: flex;
  ${(props) => props.type === 'space-between' && 'flex-direction: column;'} p:first-child {
    margin-bottom: 5px;
  }
`;

export const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Anchor = styled.a`
  color: rgba(168, 168, 168, 0.7);
  font-size: 12px;
  font-weight: normal;
`;
