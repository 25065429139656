import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setMembership } from 'state/user/user.actions';
import { setBreadcrumb } from 'state/sidebar/sidebar.actions';
import { useAsyncCallback } from 'react-async-hook';
import {
  Redirect, useHistory, useLocation, useParams,
} from 'react-router-dom';
import { companies, locations } from 'services';
import { confirmationModalSetup } from 'components/Modal/ModalContent/Delete';
import MainModal from 'components/Modal';
import { Placeholder } from 'components/Splash/Loading';
import CompanyForm from 'routes/Companies/CompanyForm';
import Button from 'components/Button/Button';
import * as S from 'routes/GlobalStyle.styled';
import { licences, reviewerCosts } from '../Calculations/data';

const NewCompany = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const { isHidden } = useSelector(state => state.sidebar);
  const history = useHistory();
  const { pathname } = useLocation();
  const isEdit = pathname.split('/')[4] === 'edit';

  const [company, setCompany] = useState(null);
  const [confirmationModal, setConfirmationModal] = useState(null);
  const [logo, setLogo] = useState(null);
  const countries = locations.countries();

  const companyAdmin = user?.selectedMembership?.view === 'Corporate Admin'
    // eslint-disable-next-line eqeqeq
    || (id == user?.selectedMembership?.company?.id
      && user?.selectedMembership?.view === 'Company Admin');

  const superAdmin = user?.selectedMembership?.view === 'Super Admin'
    || user?.selectedMembership?.view === 'Nibe Employee';

  const corporateAdmin = user?.selectedMembership?.view === 'Corporate Admin';

  const isAdmin = superAdmin || companyAdmin;

  const canEditLicense = ['Super Admin', 'Nibe Employee']
    .includes(user?.selectedMembership?.view);

  const {
    result: companiesList, execute: companiesListExecute,
  } = useAsyncCallback(() => companies.getAll({ params: { corporation: true } })
    .then(values => {
      const comps = values?.companies;
      // eslint-disable-next-line no-unused-expressions
      comps?.shift();
      comps.unshift({ label: t('select corporation'), value: null });
      return {
        companies: comps,
      };
    }));
  const companyResult = useAsyncCallback(() => companies.getCompany(`/2/companies/${id}`)
    .then(value => {
      if (value?.logoImage) {
        companies.getCompanyLogo(value?.logoImage['@id'] || value?.logoImage).then(logoValue => {
          setCompany(value);
          setLogo(`${process.env.REACT_APP_API_URL}${logoValue?.contentUrl}`);
        });
      } else setCompany(value);
    }));

  useEffect(() => {
    if (company?.name) dispatch(setBreadcrumb(company?.name));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company]);

  useEffect(() => {
    if (isEdit && isAdmin) companyResult.execute();
    companiesListExecute();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /* eslint-disable eqeqeq */
  if (!isAdmin || (!isAdmin && !isEdit)
    || (isEdit && !isAdmin && (id != user.company))) {
    return <Redirect to="/" />;
  }
  /* eslint-enable eqeqeq */

  const handleSubmitValues = (values) => {
    const tempValues = values;

    const name = tempValues.name ? { name: tempValues.name } : isEdit && { name: company.name };
    const email = tempValues.email ? { email: tempValues.email }
      : isEdit && { email: company.email };
    const address = tempValues.address ? { address: tempValues.address }
      : isEdit && { address: company.address };
    const postalCode = tempValues.postalCode ? { postalCode: tempValues.postalCode }
      : isEdit && { postalCode: company.postalCode };
    const city = tempValues.city ? { city: tempValues.city } : isEdit && { city: company.city };
    const website = tempValues.website ? { website: tempValues.website }
      : isEdit && { website: company.website };
    const country = tempValues.country ? { country: tempValues.country.value }
      : isEdit && { country: company.country };
    const license = tempValues.licence ? { license: tempValues.licence.value }
      : isEdit && { license: company.license };
    // EPD-1350 - these two make the BE validations fail
    delete tempValues.licence;
    if (!superAdmin) delete tempValues.Corporation;

    if (!superAdmin) delete tempValues.reviewCompany;
    else tempValues.reviewCompany = tempValues.reviewCompany || false;

    const companyInfo = {
      ...tempValues,
      ...country,
      ...(canEditLicense ? license : {}),
      ...tempValues.reviewerCosts
        ? { reviewerCosts: tempValues.reviewerCosts.value }
        : isEdit && { reviewerCosts: company.reviewerCosts },
      ...name,
      ...email,
      ...address,
      ...postalCode,
      ...city,
      ...website,
    };

    if (!canEditLicense) {
      delete companyInfo.license;
    }

    if (tempValues.logoImageFile) delete companyInfo.logoImageFile;
    if (tempValues.publisherAutographPerson1) delete companyInfo.publisherAutographPerson1;
    if (tempValues.publisherAutographPerson2) delete companyInfo.publisherAutographPerson2;

    companyInfo.parentCompany = tempValues?.Corporation?.['@id'] || null;
    if (tempValues.Corporation) delete companyInfo?.Corporation;
    // Fix for RBM-82
    else if (isEdit && company && !tempValues.Corporation) {
      delete companyInfo?.Corporation;
    }

    if (tempValues.type) {
      companyInfo.corporation = isEdit && company ? company.corporation : tempValues.type?.value;
      delete companyInfo.type;
    }

    if (tempValues?.nibeCustomerNumber && superAdmin) {
      companyInfo.nibeCustomerNumber = tempValues?.nibeCustomerNumber;
    } else {
      delete companyInfo.nibeCustomerNumber;
    }

    if (companyAdmin) delete companyInfo.parentCompany;
    if (corporateAdmin) companyInfo.parentCompany = user?.selectedMembership?.company?.['@id'];

    if (isEdit) {
      const promises = [];
      if (tempValues.logoImageFile && tempValues.logoImageFile.length > 0) {
        promises.push(new Promise((resolve) => {
          const bodyFormData = new FormData();
          bodyFormData.append('file', tempValues.logoImageFile[0]);
          resolve(companies.uploadCompanyLogo(bodyFormData).then(value => {
            companyInfo.logoImage = value['@id'];
          }));
        }));
      }
      ['publisherAutographPerson1',
        'publisherAutographPerson2'].forEach(key => {
        if (tempValues[key]?.length > 0) {
          const formData = new FormData();
          formData.append('file', tempValues[key][0]);
          promises.push(
            companies.uploadPublisherAutographs(formData, key.slice(-1))
              .then(value => { companyInfo[key] = value['@id']; }),
          );
        }
      });
      return Promise
        .all(promises)
        .then(() => companies.editCompany(id, companyInfo))
        .then(() => {
          // eslint-disable-next-line eqeqeq
          if (id == user?.selectedMembership?.company?.id) {
            dispatch(setMembership({
              ...user?.selectedMembership,
              company: {
                ...user?.selectedMembership?.company,
                license: tempValues.license,
              },
            }));
          }
          history.push(
            `/manage/companies/${id}/basic-information`, { routeName: name?.name },
          );
        });
    }
    return companies
      .createCompany(companyInfo)
      .then(() => history.push('/manage/companies'));
  };

  const deleteFile = (key) => {
    confirmationModalSetup(setConfirmationModal,
      (closeModal) => companies.editCompany(id, { [key]: null })
        .then(() => {
          if (key === 'logoImage') { setLogo(undefined); }
          closeModal();
        }));
  };

  const country = isEdit && _.find(
    countries,
    countryValue => countryValue.value === company?.country,
  );

  const licence = licences?.find(l => l?.value === company?.license);

  const reviewerCostsFlag = company?.reviewerCosts ? reviewerCosts[1] : reviewerCosts[0];

  const onDeleteItem = () => confirmationModalSetup(
    setConfirmationModal,
    () => companies.deleteCompany(id)
      .then(() => history.push('/manage/companies')),
  );

  return (
    <S.Background isEditable>
      <Placeholder
        loaders={[
          (isEdit && companyResult?.loading),
        ]}
        rows={3}>
        <S.HeaderTitle>
          {t(isEdit ? 'edit company' : 'new company')}
          {user?.selectedMembership?.view === 'Super Admin' && id
            && (
              <Button
                onClick={id && onDeleteItem}
                style={{ marginTop: '-0.5rem', marginBottom: '-0.5rem' }}
                styling="select">
                {t('delete company')}
              </Button>
            )}
        </S.HeaderTitle>
        <S.FormContainer isHidden={isHidden}>
          <CompanyForm
            companiesList={companiesList?.companies}
            corporation={company?.corporation}
            countries={countries}
            deleteFile={deleteFile}
            handleSubmitValues={handleSubmitValues}
            id={id}
            initialValues={{
              name: company?.name,
              address: company?.address,
              postalCode: company?.postalCode,
              city: company?.city,
              country,
              email: company?.email,
              website: company?.website,
              licence: isEdit && licence,
              reviewerCosts: isEdit && reviewerCostsFlag,
              nibeCustomerNumber: company?.nibeCustomerNumber,
              // Needed bc of obj comparison to match initial value
              // eslint-disable-next-line max-len
              Corporation: company?.parentCompany && companiesList?.companies?.find(c => c.id === company.parentCompany.id),
              type: {
                label: t('company.label'),
                value: false,
              },

              publisher: company?.publisher,
              operator: company?.operator,
              publisherNamePerson1: company?.publisherNamePerson1,
              publisherFunctionPerson1: company?.publisherFunctionPerson1,
              publisherAutographPerson1: company?.publisherAutographPerson1,
              publisherNamePerson2: company?.publisherNamePerson2,
              publisherFunctionPerson2: company?.publisherFunctionPerson2,
              publisherAutographPerson2: company?.publisherAutographPerson2,
              reviewCompany: company?.reviewCompany,

            }}
            isEdit={isEdit}
            licences={licences}
            logo={logo}
            pathname={pathname}
            reviewerCostsOptions={reviewerCosts}
            user={user}
          />
        </S.FormContainer>
        <MainModal
          confirmationModalInfo={confirmationModal}
          content="delete"
          isModalOpen={confirmationModal}
          setIsModalOpen={setConfirmationModal}
        />
      </Placeholder>
    </S.Background>
  );
};

export default NewCompany;
