/* eslint-disable no-nested-ternary */
import _ from 'lodash';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  useParams, useRouteMatch, Switch, Route, Redirect,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAsync } from 'react-async-hook';

import { methods as methodsService, countries as countriesService } from 'services';
import * as actions from 'state/calculations/calculations.actions';

import AsideLayout from 'components/AsideLayout/AsideLayout';
import CalculationsFilter from 'components/CalculationsFilter/CalculationsFilter';
import { MenuItem } from 'components/VerticalMenu';
import * as Style from 'routes/GlobalStyle.styled';
import AsideOverview from 'components/AsideOverview/AsideOverview';
import Select from 'components/Select/Select';
import Input from 'components/Input/Input';
import Pdis from 'routes/Calculations/Detail/Inputs/PredefinedInputSets/Pdis';
import Templates from 'routes/Calculations/Detail/Templates/Templates';
// import { downloadFile } from 'utils';

import * as S from './Overview.styled';
import * as DetailStyle from './Detail/Detail.styled';
import Imports from './Import/Imports';
import NewImport from './Import/NewImport';
import ImportsPdis from './Detail/Inputs/PredefinedInputSets/Import/Imports';
import NewImportPdis from './Detail/Inputs/PredefinedInputSets/Import/NewImport';
import CalculationsList from './CalculationsList';
import { DocumentationUrl } from '../Documentation';
import { canImportCalculations } from '../../utils/featureAccess';

const selector = (state) => _.pick(state, ['calculations', 'company']);

const CalculationsOverview = () => {
  const { t } = useTranslation();
  const { folderId } = useParams();
  const { path } = useRouteMatch();
  const dispatch = useDispatch();
  const { calculations: c, company } = useSelector(selector);
  const user = useSelector(state => state.user);
  const [totalNumberOfRecords, setTotalNumberOfRecords] = React.useState(null);
  const [method, setMethod] = React.useState(null);
  const [wasteScenario, setWasteScenario] = React.useState(null);
  const [input, setInput] = React.useState('');
  const [clearInput, setClearInput] = React.useState(null);
  const time = React.useRef(0);
  const isOverview = folderId === 'root';
  const {
    orderBy,
    filters,
  } = c;
  const { selectedCompany, id } = company || {};
  const companyId = ((selectedCompany?.value === 'all') ? 'all' : selectedCompany?.id) || id;

  const { result } = useAsync(() => methodsService.all(), []);
  const { result: countries } = useAsync(() => countriesService.getWasteRegions(), []);

  // Populate list
  useEffect(() => {
    const params = { ...orderBy, ...filters };
    if (!isOverview) _.set(params, 'folder', folderId);
    if (isOverview) params['exists[folder]'] = false;
    if (companyId) _.set(params, 'owner', companyId);

    actions.getList({ params })(dispatch);
  },
  [isOverview, folderId, orderBy, filters, companyId, dispatch]);

  // didUnmount? Cleanup state (optimize memory)
  useEffect(() => () => dispatch(actions.reset), [dispatch]);

  const canImportCalcs = canImportCalculations(user);

  const clearFilter = () => {
    const inputValue = { value: '' };
    setClearInput(inputValue);
    setInput('');
    setMethod(null);
    setWasteScenario(null);
  };

  const CustomImports = React.useCallback(props => (!canImportCalcs ? <Redirect to="/" /> : (
    <Imports
      {...props}
      method={method}
      setTotalNumberOfRecords={setTotalNumberOfRecords}
      wasteScenario={wasteScenario}
    />
  )), [canImportCalcs, method, wasteScenario]);

  const CustomNewImport = React.useCallback(props => (!canImportCalcs ? <Redirect to="/" />
    : <NewImport {...props} />), [canImportCalcs]);

  const CustomPdis = React.useCallback(props => (
    <Pdis
      {...props}
      method={method}
      name={input}
      setTotalNumberOfRecords={setTotalNumberOfRecords}
      wasteScenario={wasteScenario}
    />
  ), [method, wasteScenario, input]);

  const CustomTemplates = React.useCallback(props => (
    <Templates
      {...props}
      method={method}
      name={input}
      setTotalNumberOfRecords={setTotalNumberOfRecords}
      wasteScenario={wasteScenario}
    />
  ), [method, wasteScenario, input]);

  const DownloadTemplate = () => (
    <S.DownloadTemplate onClick={() => window.open(DocumentationUrl, '_blank').focus()}>
      {t('download template')}
    </S.DownloadTemplate>
  );

  const handleInputChange = (e) => {
    if (time.current) clearTimeout(time.current);
    const inputText = e.target.value;
    time.current = setTimeout(() => {
      const clearSpaces = inputText && inputText.length > 0 && inputText.trim();
      if (clearSpaces && (input !== inputText)) {
        setInput(inputText);
        clearTimeout(time.current);
      }
    }, 2000);
  };

  const handleOnKeyDown = (event) => {
    if (event.key === 'Enter') {
      const inputText = event.target.value;
      const clearSpaces = inputText && inputText.length > 0 && inputText.trim();
      if (clearSpaces) {
        setInput(inputText);
      } else setInput('');
    }
  };

  const onFocus = () => setClearInput(null);

  return (
    <AsideLayout
      LeftPane={(
        <div>
          {path?.includes('calculations/predefined-input-sets')
            ? <Style.AsideTitle>{t('predefined-input-sets')}</Style.AsideTitle>
            : path?.includes('calculations/templates')
              ? <Style.AsideTitle>{t('templates')}</Style.AsideTitle>
              : <Style.AsideTitle>{t('calculations')}</Style.AsideTitle>}
          {path === '/calculations/import/new'
            ? (
              <DetailStyle.LinksWrapper>
                {t('import calculation.hint')}
                <DownloadTemplate />
              </DetailStyle.LinksWrapper>
            )
            : path === '/calculations/predefined-input-sets/import/new'
              ? (
                <DetailStyle.LinksWrapper>
                  {t('import predefined input sets.hint')}
                  <DownloadTemplate />
                </DetailStyle.LinksWrapper>
              )
              : (
                <>
                  {user?.selectedMembership?.company?.license === 3 && (
                  <DetailStyle.LinksWrapper>
                    <MenuItem exact style={{ marginTop: '20px' }} to="/calculations/root">
                      {t('calculation overview')}
                    </MenuItem>

                    {canImportCalcs && (
                      <MenuItem to="/calculations/import">
                        {t('calculation imports')}
                      </MenuItem>
                    )}

                    <MenuItem exact to="/calculations/templates">
                      {t('templates')}
                    </MenuItem>

                    <MenuItem exact to="/calculations/predefined-input-sets">
                      {t('predefined-input-sets')}
                    </MenuItem>

                    <MenuItem exact to="/calculations/predefined-input-sets/import">
                      {t('predefined input sets imports')}
                    </MenuItem>
                  </DetailStyle.LinksWrapper>
                    )}
                  {path === '/calculations/import'
                    || path === '/calculations/predefined-input-sets'
                    || path === '/calculations/templates'
                    ? (
                      <AsideOverview
                        clearFilter={clearFilter}
                        clearingFilterCondition={(method || wasteScenario || input)
                          && (totalNumberOfRecords !== null)}
                        results={totalNumberOfRecords}>
                        {(path === '/calculations/predefined-input-sets'
                        || path === '/calculations/templates')
                        && (
                          <div style={{ marginBottom: '1.5rem' }}>
                            <Style.Label>{t('code name')}</Style.Label>
                            <Input
                              {...clearInput}
                              id="name"
                              name="name"
                              onChange={handleInputChange}
                              onFocus={onFocus}
                              onKeyDown={handleOnKeyDown}
                              placeholder={t('filter by code/name')}
                            />
                          </div>
                        )}
                        <div style={{ marginBottom: '1.5rem' }}>
                          <Style.Label>{t('methods')}</Style.Label>
                          <Select
                            fullInputWidth
                            isSearchable
                            name="Methods"
                            onChange={setMethod}
                            options={result?.methods}
                            placeholder={t('filter by method')}
                            value={method}
                          />
                        </div>
                        <div style={{ marginBottom: '1.5rem' }}>
                          <Style.Label>{t('wasteScenarioSets.label')}</Style.Label>
                          <Select
                            fullInputWidth
                            getOptionLabel={w => w?.name}
                            getOptionValue={w => w?.['@id']}
                            isSearchable
                            name="wasteScenarioSets"
                            onChange={setWasteScenario}
                            options={countries}
                            placeholder={t('filter by waste scenario set')}
                            value={wasteScenario}
                          />
                        </div>
                      </AsideOverview>
                    )
                    : path === '/calculations/predefined-input-sets/import'
                      ? null
                      : <CalculationsFilter />}
                </>
              )}
        </div>
      )}>
      <Switch>
        <Route
          component={CustomPdis}
          exact
          path="/calculations/predefined-input-sets"
        />
        <Route
          component={CustomTemplates}
          exact
          path="/calculations/templates"
        />
        <Route
          component={CustomImports}
          exact
          path="/calculations/import"
        />
        <Route
          component={NewImportPdis}
          exact
          path="/calculations/predefined-input-sets/import/new"
        />
        <Route
          component={ImportsPdis}
          exact
          path="/calculations/predefined-input-sets/import"
        />
        <Route component={CustomNewImport} exact path="/calculations/import/new" />
        <Route component={CalculationsList} exact path={path} />
      </Switch>
    </AsideLayout>
  );
};

export default CalculationsOverview;
