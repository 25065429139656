import React from 'react';
import * as S from './AddButton.styled';

const AddButton = ({ children, style, onClick }) => (
  <S.StyledContainer onClick={onClick} style={style}>
    {children}
  </S.StyledContainer>
);

export default AddButton;
