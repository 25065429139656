import _ from 'lodash';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { saveAs } from 'file-saver';
import { store } from 'state';
import { alertToast } from 'config/toast';

export { default as errorToast } from './errorToast';

export const isReady = loaders => !_.some(loaders, entry => {
  const { loading, status } = entry;
  if (status === 'not-requested') return true;
  if (status === 'loading') return true;
  if (status === 'error') return false;
  if (_.has(entry, 'loading')) return loading; // success
  return entry;
});

export function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export const downloadFile = (url, fileName = null, setLoading) => fetch(url, {
  headers: {
    Authorization: `Bearer ${store?.getState()?.auth?.userToken}`,
    'Nibe-CompanyId': store?.getState()?.user?.selectedMembership?.company?.id,
    'Nibe-View': store?.getState()?.user?.selectedMembership?.view,
  },
}).then(async response => {
  if (response.status === 200) {
    const blobResponse = await response.blob();
    return saveAs(blobResponse, fileName);
  }
  return alertToast('The file couldn’t be downloaded', 'error');
}).finally(() => {
  if (setLoading) setLoading(false);
});

/**
 * use this when you need to show an image, because it requires headers
 */
export const showFile = (url) => fetch(url, {
  headers: {
    Authorization: `Bearer ${store?.getState()?.auth?.userToken}`,
    'Nibe-CompanyId': store?.getState()?.user?.selectedMembership?.company?.id,
    'Nibe-View': store?.getState()?.user?.selectedMembership?.view,
  },
}).then(async response => {
  const blobResponse = await response.blob();
  return window.URL.createObjectURL(blobResponse);
});

export const isReference = element => typeof element === 'function';
export const getIRI = ({ '@id': id }) => id;

export const inputName = ({
  description,
  environmentalProfile,
  supplier,
  netAmount,
}) => `
  ${description || ''}
  ${environmentalProfile?.name ? ` | ${environmentalProfile?.name}` : ''}
  ${supplier?.name ? ` | ${supplier?.name}` : ''}
  | ${netAmount || ''}
`;

export const bold = (companyName, view) => React.createElement(
  'span',
  {
    // eslint-disable-next-line max-len
    dangerouslySetInnerHTML: { __html: `<div><div style="display:inline;font-weight:500;color:#202538;">${companyName} | </div><div style="display:inline;">${view}</div></div` },
  },
);
