import React from 'react';
import i18next from 'i18next';
import { useAsync } from 'react-async-hook';
import swal from '@sweetalert/with-react';

import { folders as foldersService } from 'services';

import Select from 'components/Select/Select';
import { Heading, ButtonSet } from 'components/Modal';

const getIRI = ({ '@id': id }) => id;
const getLabel = ({ name }) => name;

const fetchFolders = () => foldersService
  .get()
  .then(({ 'hydra:member': data }) => [
    { '@id': null, get name() { return i18next.t('root_folder_name'); } },
    ...data,
  ]);

const Destinations = ({ title, onConfirm }) => {
  const folders = useAsync(fetchFolders, []);
  const options = folders.result || [];
  const [loading, setLoading] = React.useState(false);
  const [value, setValue] = React.useState(false);

  const handleConfirm = () => {
    setLoading(true);
    return onConfirm(getIRI(value))
      .finally(() => {
        swal.stopLoading();
        setLoading(false);
        swal.close();
      });
  };

  const changeValue = item => {
    setValue(item);
    swal.setActionValue(getIRI(value));
  };

  return (
    <div style={{ minWidth: '20rem' }}>
      <Heading loading={loading || folders.loading} title={title} />

      <Select
        getOptionLabel={getLabel}
        getOptionValue={getIRI}
        isLoading={folders.loading}
        onChange={changeValue}
        options={options}
      />

      <ButtonSet
        disabled={!value || folders.loading}
        loading={loading}
        onConfirm={handleConfirm}
      />
    </div>
  );
};

export default Destinations;
