import React from 'react';
import {
  useRouteMatch,
  Switch,
  Route,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Tabs, Tab } from 'components/Tabs/Tabs';

import Progress from './Progress/Progress';
import RealtimeResults from './Realtime/Realtime';

export default () => {
  const { t } = useTranslation();
  const route = useRouteMatch();
  const { path } = route;
  let { url } = route;

  // Fix appended slash when /routing
  if (url.endsWith('/')) {
    url = url.substring(0, url.length - 1);
  }

  return (
    <main>
      <Tabs>
        <Tab to={`${url}/results`}>{t('realtime results')}</Tab>
        <Tab to={`${url}/progress`}>{t('progress')}</Tab>
      </Tabs>

      <Switch>
        <Route component={RealtimeResults} exact path={[path, `${path}/results`]} />
        <Route component={Progress} exact path={`${path}/progress`} />
      </Switch>
    </main>
  );
};
