import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Arrow from 'assets/sortArrow.svg';
import { useTranslation } from 'react-i18next';

import CheckBox from 'components/CheckBox/CheckBox';

import { selectAll, selectNone, orderBy } from 'state/calculations/calculations.actions';

import * as S from './TopBar.styled';

const Name = ({
  hasCheckbox, onOrderBy, selectAllList, label, noSorting, order, inverted,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isDesc, setIsDesc] = useState(false);
  const [isNameSelected, setIsNameSelected] = useState(false);

  const sortName = () => {
    if (isDesc) {
      if (onOrderBy) onOrderBy('asc');
      else dispatch(orderBy('asc'));
      if (order === 'desc') setIsDesc(false);
      else if (order === null) setIsDesc(true);
      else setIsDesc(!isDesc);
    } else {
      if (onOrderBy) onOrderBy('desc');
      else dispatch(orderBy('desc'));
      if (order === 'desc') setIsDesc(false);
      else if (order === null) setIsDesc(true);
      else setIsDesc(!isDesc);
    }
  };

  const selectAllNames = () => {
    if (isNameSelected) {
      dispatch(selectNone);
      setIsNameSelected(!isNameSelected);
    } else {
      dispatch(selectAll(selectAllList));
      setIsNameSelected(!isNameSelected);
    }
  };

  return (
    <S.Section>
      <S.Name>
        {hasCheckbox && <CheckBox checked={isNameSelected} onChange={selectAllNames} />}
        <S.Label
          noSorting={noSorting}
          onClick={noSorting ? null : sortName}>
          {t(label || 'Name')}
        </S.Label>
        {!noSorting && order && (
          <S.Arrow
            isDesc={inverted ? !isDesc : isDesc}
            noSorting={noSorting}
            onClick={noSorting ? null : sortName}
            src={Arrow}
          />
        )}
      </S.Name>
    </S.Section>
  );
};

export default Name;
