import React, { useEffect, useState } from 'react';
import { reduxForm, Form, formValues } from 'redux-form';
import { useTranslation } from 'react-i18next';

import Button from 'components/Button/Button';

import * as S from 'routes/GlobalStyle.styled';
import { downloadFile } from 'utils';
import { confirmationModalSetup } from 'components/Modal/ModalContent/Delete';
import MainModal from 'components/Modal';
import { calculations } from 'services';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

const InputOutputSubstantiations = ({
  submitting,
  handleSubmitValues,
  formField,
  getFieldValues,
  handleSubmit,
  allocation,
  allocationImage,
  transportToManufacturer,
  productionProcessProductionWaste,
  productionProcessEnergyConsumption,
  transportToBuildingSite,
  constructionStage,
  useStage,
  maintenance,
  deconstructionDemolition,
  transportEndLifeStage,
  wasteProcessing,
  finalDisposal,
  environmentalCosts,
  hasEditedForm,
  data,
  execute,
  isTemplate,
  updateTemplate,
}) => {
  const { t } = useTranslation();
  const [confirmationModal, setConfirmationModal] = useState(null);
  const { isHidden } = useSelector(state => state.sidebar);
  const { id: calcId } = useParams();
  const [hasSubmited, setHasSubmited] = React.useState(false);
  const isSemi = (data?.calculationType === 'semi_finished_product');

  useEffect(() => {
    getFieldValues({
      allocation,
      allocationImage,
      transportToManufacturer,
      productionProcessProductionWaste,
      productionProcessEnergyConsumption,
      transportToBuildingSite,
      constructionStage,
      useStage,
      maintenance,
      deconstructionDemolition,
      transportEndLifeStage,
      wasteProcessing,
      finalDisposal,
      environmentalCosts,
    });
  }, [
    getFieldValues,
    allocation,
    allocationImage,
    transportToManufacturer,
    productionProcessProductionWaste,
    productionProcessEnergyConsumption,
    transportToBuildingSite,
    constructionStage,
    useStage,
    maintenance,
    deconstructionDemolition,
    transportEndLifeStage,
    wasteProcessing,
    finalDisposal,
    environmentalCosts,
  ]);

  const history = useHistory();
  const deleteAllocationImageFile = () => {
    confirmationModalSetup(setConfirmationModal, (closeModal) => {
      if (data?.allocationImage) {
        if (isTemplate) {
          calculations.saveTemplate(data.id, { allocationImage: null })
            .then(() => {
              updateTemplate();
              closeModal();
            });
        } else {
          calculations.save(data.id, { allocationImage: null }).then(() => {
            execute();
            closeModal();
          });
        }
      } else closeModal();
    });
  };

  return (
    <S.Background isEditable>
      <S.HeaderTitle>{t('expert functions input output substantiations')}</S.HeaderTitle>
      <S.FormContainer isHidden={isHidden}>
        <Form onSubmit={ev => {
          ev.preventDefault();
          handleSubmit(handleSubmitValues)().then(() => {
            setHasSubmited(true);
          });
        }}>
          {formField({
            label: 'allocation',
            calculationContent: data.allocation,
            calculationKey: 'allocation',
            simpleEditor: !data?.template,
            template: data?.template,
          })}
          {formField({
            label: 'allocation image',
            calculationContent: {
              url: (data.allocationImage || data?.template?.allocationImage)
                && `${process.env.REACT_APP_API_URL}${(data.allocationImage?.contentUrl || data?.template?.allocationImage?.contentUrl)}`,
              content: data.allocationImage || data?.template?.allocationImage,
            },
            calculationKey: 'allocationImage',
            simpleEditor: true,
            fileUpload: {
              allowImages: true,
              disableDelete: !!data?.template?.allocationImage?.contentUrl,
            },
            deleteFile: deleteAllocationImageFile,
            downloadFile,
          })}
          {formField({
            label: 'transport to manufacturer',
            calculationContent: data.transportToManufacturer,
            calculationKey: 'transportToManufacturer',
            simpleEditor: !data?.template,
            template: data?.template,
          })}
          {formField({
            label: 'production process (a3) | production waste',
            calculationContent: data.productionProcessProductionWaste,
            calculationKey: 'productionProcessProductionWaste',
            simpleEditor: !data?.template,
            template: data?.template,
          })}
          {formField({
            label: 'production process (a3) | energy consumption',
            calculationContent: data.productionProcessEnergyConsumption,
            calculationKey: 'productionProcessEnergyConsumption',
            simpleEditor: !data?.template,
            template: data?.template,
          })}
          {!isSemi && (
            <>
              {formField({
                label: 'transport to building site',
                calculationContent: data.transportToBuildingSite,
                calculationKey: 'transportToBuildingSite',
                simpleEditor: !data?.template,
                template: data?.template,
              })}
              {formField({
                label: 'construction stage',
                calculationContent: data.constructionStage,
                calculationKey: 'constructionStage',
                simpleEditor: !data?.template,
                template: data?.template,
              })}
              {formField({
                label: 'use stage',
                calculationContent: data.useStage,
                calculationKey: 'useStage',
                simpleEditor: !data?.template,
                template: data?.template,
              })}
              {formField({
                label: 'maintenance',
                calculationContent: data.maintenance,
                calculationKey: 'maintenance',
                simpleEditor: !data?.template,
                template: data?.template,
              })}
              {formField({
                label: 'de-construction demolition',
                calculationContent: data.deconstructionDemolition,
                calculationKey: 'deconstructionDemolition',
                simpleEditor: !data?.template,
                template: data?.template,
              })}
              {formField({
                label: 'transport end life stage',
                calculationContent: data.transportEndLifeStage,
                calculationKey: 'transportEndLifeStage',
                simpleEditor: !data?.template,
                template: data?.template,
              })}
              {formField({
                label: 'waste processing',
                calculationContent: data.wasteProcessing,
                calculationKey: 'wasteProcessing',
                simpleEditor: !data?.template,
                template: data?.template,
              })}
              {formField({
                label: 'final disposal',
                calculationContent: data.finalDisposal,
                calculationKey: 'finalDisposal',
                simpleEditor: !data?.template,
                template: data?.template,
              })}
              {formField({
                label: 'environmental costs and benefits of recycling and reuse',
                calculationContent: data.environmentalCosts,
                calculationKey: 'environmentalCosts',
                simpleEditor: !data?.template,
                template: data?.template,
              })}
            </>
          )}
          <div style={{ display: 'flex', marginTop: '1rem' }}>
            <Button
              loading={submitting}
              style={{ marginRight: '2rem' }}
              styling="select"
              type="submit">
              {t('save')}
            </Button>
            <Button
              onClick={() => {
                if (isTemplate) {
                  history
                    .push(`/calculations/templates/${calcId}/expert-functions/input-output-substantiations/view`);
                } else {
                  history
                    .push(`/calculation/${calcId}/expert-functions/input-output-substantiations/view`);
                }
              }}
              styling="underline">
              {t('cancel')}
            </Button>
          </div>
        </Form>
      </S.FormContainer>
      <MainModal
        confirmationModalInfo={confirmationModal}
        content="delete"
        isModalOpen={confirmationModal}
        setIsModalOpen={setConfirmationModal}
      />
      <MainModal
        content="prompt"
        exitModalContent={{
          navigateSideEffects: () => null,
        }}
        prompt
        promptCondition={hasEditedForm && !hasSubmited}
      />
    </S.Background>
  );
};

export default reduxForm({
  form: 'input-output-substantiations',
  enableReinitialize: true,
})(formValues({
  allocation: 'allocation',
  allocationImage: 'allocationImage',
  transportToManufacturer: 'transportToManufacturer',
  productionProcessProductionWaste: 'productionProcessProductionWaste',
  productionProcessEnergyConsumption: 'productionProcessEnergyConsumption',
  transportToBuildingSite: 'transportToBuildingSite',
  constructionStage: 'constructionStage',
  useStage: 'useStage',
  maintenance: 'maintenance',
  deconstructionDemolition: 'deconstructionDemolition',
  transportEndLifeStage: 'transportEndLifeStage',
  wasteProcessing: 'wasteProcessing',
  finalDisposal: 'finalDisposal',
  environmentalCosts: 'environmentalCosts',
})(InputOutputSubstantiations));
