import _ from 'lodash';
import { alertToast, errorToast } from 'config/toast';

import * as i18nIsoCountries from 'i18n-iso-countries';
import network from './network';

i18nIsoCountries.registerLocale(require('i18n-iso-countries/langs/en.json'));

i18nIsoCountries.registerLocale(require('i18n-iso-countries/langs/en.json'));

const err = () => { throw new Error('Failed to fetch Locations'); };

const paginatedMember = ({
  'hydra:member': data,
  'hydra:view': view,
  'hydra:totalItems': totalItems,
}) => ({
  get locations() { return this.data; },
  get suppliers() { return this.data; },
  data,
  nextPage: view && view['hydra:next'],
  totalItems,
});

// List
export const all = (params) => network
  .get('/locations', { params })
  .then(paginatedMember)
  .catch(err);

export const getNextPageLocations = (url) => network
  .get(`${process.env.REACT_APP_API_URL}${url}`, { params: { owner: 'none' } })
  .then(paginatedMember)
  .catch(err);

export const add = payload => network
  .post('/locations', payload);

export const countries = () => {
  const countriesList = i18nIsoCountries.getNames('en');
  return _.map(countriesList, (label, value) => ({ value, label }));
};

// One
export const get = id => network
  .get(`/locations/${id}`)
  .catch(err);

export const editLocation = (id, payload) => network
  .put(`/locations/${id}`, payload)
  .then(() => alertToast('Location updated with success!', 'success'));

export const createLocation = payload => network
  .post('/locations', payload)
  .then(() => alertToast('Location created with success!', 'success'));

// Suppliers
export const suppliers = (config = {}) => network
  .get('/suppliers', { ...config, cache: true })
  .then(paginatedMember)
  .then(({ data }) => data)
  .catch(err);

export const supplier = id => network
  .get(`/suppliers/${id}`)
  .catch(err);

export const getSuppliers = (params) => network
  .get('/suppliers', { params })
  .then(paginatedMember)
  .catch(err);

export const getNextPageSuppliers = (url) => network
  .get(`${process.env.REACT_APP_API_URL}${url}`, { params: { owner: 'none' } })
  .then(paginatedMember)
  .catch(err);

export const deleteSupplier = (id) => network
  .delete(`/suppliers/${id}`)
  .then(() => alertToast('Supplier deleted with success!', 'success'))
  .catch((error) => errorToast(error));

export const deleteLocation = (id) => network
  .delete(`/locations/${id}`)
  .then(() => alertToast('Location deleted with success!', 'success'))
  .catch((error) => errorToast(error));

export const getSubstantiationFile = (url) => network
  .get(`${process.env.REACT_APP_API_URL}${url}`, { params: { owner: 'none' } })
  .catch(err);

export const uploadSubstantiationFile = (payload) => network
  .post('/media_objects', payload, {
    params: { owner: 'none' }, fileHeader: { 'Content-Type': 'multipart/form-data' },
  })
  .then(result => {
    alertToast('Supplier substantiation file updated with success!', 'success');
    return result;
  })
  .catch(err);

export const deleteSubstantiationFile = (url) => network
  .delete(`${process.env.REACT_APP_API_URL}${url}`, { params: { owner: 'none' } })
  .catch(err);

export const createSupplier = (payload) => network
  .post('/suppliers', payload)
  .then(() => alertToast('Supplier created with success!', 'success'));

export const editSupplier = (id, payload) => network
  .put(`/suppliers/${id}`, payload)
  .then(() => alertToast('Supplier updated with success!', 'success'));

export const transports = (params) => network
  .get('/transports', { params })
  .then(paginatedMember)
  .catch(err);

export const suppliersWithTransports = (params) => network
  .get('/suppliers', { params })
  .then(async r => {
    const suppliersTemp = await Promise
      .all(
        _.map(r['hydra:member'], async supplierValue => {
          const supplierResult = supplierValue;
          supplierResult.transports = await transports({ supplier: supplierValue?.id });
          return supplierResult;
        }),
      );

    return {
      suppliers: _.filter(suppliersTemp, s => s?.transports?.length > 0),
      nextPage: r['hydra:view'] && r['hydra:view']['hydra:next'],
      totalItems: r['hydra:totalItems'],
    };
  })
  .catch(err);

export const getNextPageSuppliersWithTransports = (url) => network
  .get(`${process.env.REACT_APP_API_URL}${url}`, { params: { owner: 'none' } })
  .then(async r => ({
    suppliers: await Promise.all(_.map(r['hydra:member'], async supplierValue => {
      const supplierResult = supplierValue;
      supplierResult.transports = await transports({ supplier: supplierValue?.id });
      return supplierResult;
    })),
    nextPage: r['hydra:view'] && r['hydra:view']['hydra:next'],
  }))
  .catch(err);

export const deleteTransport = id => network
  .delete(`/transports/${id}`)
  .then(() => alertToast('Transport deleted with success!', 'success'))
  .catch(err);

export const editTransport = (id, payload) => network
  .put(`/transports/${id}`, payload)
  .then(r => {
    alertToast('Transport created with success!', 'success');
    return r;
  })
  .catch(err);

export const createTransportForSupplier = (payload) => network
  .post('/transports', payload)
  .then(r => {
    alertToast('Transport created with success!', 'success');
    return r;
  })
  .catch(err);
