import styled from 'styled-components';

export const LabelRadio = styled.label`
  position: relative;
  padding-left: 30px;
  height: 1.25rem;
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;
  font-size: .875rem;

  & input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 1.25rem;
    width: 1.25rem;
    background-color: white;
    border: 1px solid #e4e4e4;
    border-radius: 100%;
  }

  &:hover input ~ .checkmark {
    background-color: #ccc;
  }

  & .checkmark:after {
    content: "";
    position: absolute;
    display: none;
    left: 4px;
    top: 4px;
    width: .6rem;
    height: .6rem;
    background: #5885a4;
    border-radius: 100%;
  }

  & input:checked ~ .checkmark:after {
    display: block;
  }

  & input:checked ~ .checkmark {
    background-color: white;
    border: 1px solid #5885a4;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
  font-size: .875rem;
`;
