import _ from 'lodash';

export default (folders, folderId) => {
  let parents = [];

  const mapTree = () => {
    const tree = [];

    (function traceParents(id = folderId, parent = null) {
      // eslint-disable-next-line eqeqeq
      const help = parent || _.find(folders, v => v.id == id);
      const currentFolder = help || folders[0];
      const parentId = currentFolder?.parent;

      if (currentFolder && help) tree.push(currentFolder);

      // check if already at root
      if (!parentId) return null;
      return traceParents(parentId?.id, parentId);
    }());

    return tree.reverse();
  };

  if (!folderId) parents = [];
  else if (folderId === 'overview') {
    parents = _.reject(folders, 'parent').map(folder => folder.id);
  } else if (folderId !== 'overview') {
    parents = mapTree();
  }

  return parents;
};
