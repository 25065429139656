import { withLabels } from 'components/Field/field';
import React, { memo } from 'react';
import * as S from './SwitchToggle.styled';

const SwitchToggle = ({
  checked, label, input, ...props
}) => (
  <S.Wrapper>
    {label && (
      <S.Label htmlFor="check">
        {label}
      </S.Label>
    )}

    <label className="switch" htmlFor={props.id || input?.id || 'check'}>
      <input checked={checked || input?.value} id="check" type="checkbox" {...props} {...input} />
      <span className="slider round" />
    </label>
  </S.Wrapper>
);

export default memo(SwitchToggle);

export const ToggleField = withLabels(SwitchToggle);
