import React, { useState } from 'react';
import swal from '@sweetalert/with-react';

import Input from 'components/Input/Input';
import { Heading, ButtonSet } from '../Prompts/common';

const NewFolder = ({ title, defaultValue = '', onConfirm }) => {
  const [value, setValue] = useState(defaultValue);
  const [loading, setLoader] = useState(false);

  const changeValue = e => {
    swal.setActionValue(e.target.value);
    setValue(e.target.value);
  };

  const createFolder = () => {
    const folder = { name: value };

    setLoader(true);
    return onConfirm(folder)
      .finally(() => swal.close());
  };

  return (
    <div style={{ width: '25.5rem' }}>
      <Heading loading={loading} title={title} />

      <Input
        id="edit-folder"
        onChange={changeValue}
        placeholder="Folder name"
        value={value}
      />

      <ButtonSet disabled={!value} loading={loading} onConfirm={createFolder} />
    </div>
  );
};

export default NewFolder;
