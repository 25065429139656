import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import i18next from 'i18next';

import { folders } from 'services';
import { getFolders } from 'state/calculations/calculations.actions';

import { Prompt } from 'components/Modal';
import EditFolder from 'components/Modal/ModalContent/Folder';
import DropDown from 'components/DropDown/DropDown';
import { Option } from 'components/DropDownContent/Calculations/Calculations';

import dots from 'assets/dots.svg';
import groupIcon from 'assets/group.svg';
import editIcon from 'assets/edit.svg';
import deleteIcon from 'assets/delete.svg';

import * as S from './FolderItem.styled';

const FolderItem = ({ folder }) => {
  const { id, name } = folder;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { selectedCompany, id: companyId } = useSelector(({ company }) => company || {});
  const history = useHistory();
  const { folderId } = useParams();

  const isEditable = companyId === selectedCompany?.id;

  const edit = {
    label: t('Edit'),
    img: editIcon,
    disabled: !isEditable,
    action: () => isEditable && Prompt.Custom(
      <EditFolder
        defaultValue={name}
        onConfirm={(value) => {
          const params = { ...value };
          if (folderId && (folderId !== 'root')) params.parent = `/2/folders/${folderId}`;
          return folders
            .update(id, params)
            .then(() => getFolders({ parent: folderId })(dispatch));
        }}
        title={t('renameFolder')}
      />,
    ),
  };

  const exclude = {
    label: t('Delete'),
    img: deleteIcon,
    action: () => Prompt.Default({
      title: i18next.t('confirmDeletion'),
      text: i18next.t('this action cannot be undone'),
      onConfirm: () => folders
        .remove(id)
        .then(() => getFolders({ parent: folderId })(dispatch)),
    }),
  };

  const onClick = () => history.push(`/calculations/${id}`);

  return (
    <S.Wrapper onClick={onClick}>
      <S.Section>
        <S.Icon alt="icon" src={groupIcon} />
        <S.Title>{name}</S.Title>
      </S.Section>

      <S.SecondSection>
        <DropDown
          content={(
            <ul>
              <Option {...edit} />
              <Option {...exclude} />
            </ul>
          )}>
          <S.Dots alt="actions menu toggler" src={dots} />
        </DropDown>
      </S.SecondSection>
    </S.Wrapper>
  );
};

FolderItem.propTypes = {
  folder: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }).isRequired,
};

export default FolderItem;
