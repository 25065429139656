import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';
import Button from 'components/Button/Button';
import ListItem from 'components/ListItem/ListItem';
import Name from 'components/TopBar/Name';
import ImportsTopBar from 'components/TopBar/Imports';
import TopBar from 'components/TopBar/TopBar';
import TopbarActions from 'components/TopbarActions/TopbarActions';
import Spinner from 'components/Spinner';
import * as S from 'routes/Calculations/Overview.styled';
import InfiniteScroll from 'react-infinite-scroll-component';
import { imports as importsService } from 'services';
import Success from 'assets/success.svg';
import { canImportLocationsAndSuppliers } from '../../utils/featureAccess';

const Imports = () => {
  const { t } = useTranslation();
  const user = useSelector(state => state.user);

  const [imports, setImports] = useState(null);
  const [page, setPage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [sorting, setSorting] = useState(null);
  const [sortingDate, setSortingDate] = useState('desc');
  const [sortingStatus, setSortingStatus] = useState(null);

  useEffect(() => {
    const params = {};
    if (sorting) params['order[upload.originalFilename]'] = sorting;
    if (sortingStatus) params['order[status]'] = sortingStatus;
    if (sortingDate) params['order[updatedAt]'] = sortingDate;
    setLoading(true);
    importsService
      .imports(params)
      .then((values) => {
        setImports(values.imports);
        setPage(values.nextPage);
      })
      .finally(() => setLoading(false));
  }, [sorting, sortingDate, sortingStatus]);

  const fetchData = () => {
    if (page) {
      importsService
        .getNextPage(page)
        .then((values) => {
          setPage(values.nextPage);
          setImports(imports.concat(values.imports));
        });
    }
  };

  const getStatus = (data) => {
    if (data?.state === 'validation_started') return `${t('validating')}...`;
    if (data?.state === 'import_started') return `${t('importing')}...`;
    return t(data?.state);
  };

  const getComplete = (data) => ((data?.state === 'import_completed') ? Success : null);

  const importsList = () => (
    <S.OverviewRows id="overviewRowsId">
      <InfiniteScroll
        dataLength={imports.length}
        hasMore={page}
        loader={<Spinner />}
        next={fetchData}
        scrollableTarget="overviewRowsId"
        scrollThreshold={0.4}
        style={{ minHeight: '50vh' }}>
        {_.map(imports, (data) => (
          <ListItem
            key={data?.id}
            changes={data?.unseenChanges}
            complete={getComplete(data)}
            date={data?.updatedAt}
            imports
            name={data?.upload?.originalFilename}
            status={getStatus(data)}
            to={{
              pathname: `/locations-&-suppliers/import/${data.id}`,
              state: {
                routeName: data?.upload?.originalFilename,
              },
            }}
          />
        ))}
      </InfiniteScroll>
    </S.OverviewRows>
  );

  const canImport = canImportLocationsAndSuppliers(user);
  if (!canImport) return <Redirect to="/" />;

  return (
    <>
      {(canImport) && (
        <TopbarActions>
          <Button
            as={Link}
            styling="select"
            to="/locations-&-suppliers/import/new">
            {t('run new import')}
          </Button>
        </TopbarActions>
      )}

      <TopBar>
        <Name
          inverted
          label="file"
          onOrderBy={() => {
            if (sortingStatus) setSortingStatus(null);
            if (!sorting) setSorting('asc');
            else if (sorting === 'asc') setSorting('desc');
            else setSorting(null);
          }}
          order={sorting}
        />
        <ImportsTopBar
          inverted
          onOrderByDate={order => {
            setSortingDate(order);
          }}
          onOrderByStatus={() => {
            if (sorting) setSorting(null);
            if (!sortingStatus) setSortingStatus('asc');
            else if (sortingStatus === 'asc') setSortingStatus('desc');
            else setSortingStatus(null);
          }}
          order={sortingDate}
          orderStatus={sortingStatus}
        />
      </TopBar>

      {!imports || loading
        ? (
          <div
            style={{
              height: 'calc(100vh - 4rem)',
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            <Spinner />
          </div>
        )
        : importsList()}
    </>
  );
};

export default Imports;
