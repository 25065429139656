import _ from 'lodash';
import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { setBreadcrumb } from 'state/sidebar/sidebar.actions';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Calculation, useCalculationType, useCanEdit } from 'hooks/calculation.hooks';
import { useWasteRegion } from 'hooks/wasteRegion.hooks';
import { useMethods } from 'hooks/methods.hooks';
import {
  useCustomUnitForLCA,
  useStandardUnitForLCA,
  useStandardUnits,
} from 'hooks/functionalUnit.hooks';

import Button from 'components/Button/Button';
import { ReadOnlyField } from 'components/Field';
import { Placeholder } from 'components/Splash/Loading';

import {
  GroupFields,
  GroupFieldsDivider,
  GroupFieldsHeader,
  TitleBar,
  ViewModeContainer,
} from 'routes/GlobalStyle.styled';
import { findPublisherOperator } from 'utils/calculationsPublisherOperator';

const ViewBasics = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { result: calculation } = useContext(Calculation);
  const methods = useMethods(calculation);
  const wasteRegions = useWasteRegion(calculation);
  const standardUnits = useStandardUnits(calculation?.standardUnit);
  const customUnitForLCA = useCustomUnitForLCA(
    methods?.selected?.id, calculation?.template || calculation,
  );
  const standardUnitForLCA = useStandardUnitForLCA(methods?.selected?.id, calculation);
  const isSemifinished = calculation.calculationType === 'semi_finished_product';
  const { item } = useCalculationType(calculation.calculationType);
  const canEdit = useCanEdit();

  React.useEffect(() => {
    if (calculation?.productName) dispatch(setBreadcrumb(calculation?.productName));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calculation]);

  return (
    <>
      <TitleBar>
        {t('edit basics')}

        {canEdit && (
          <Button as={Link} styling="select" to="edit">
            {`${t('edit')}`}
          </Button>
        )}
      </TitleBar>

      <Placeholder loaders={[wasteRegions, standardUnits, standardUnitForLCA]}>
        <ViewModeContainer style={{ padding: '1.87rem 0', display: 'unset' }}>
          <GroupFields>
            <GroupFieldsHeader>
              {t('Product characteristics')}
            </GroupFieldsHeader>
            {calculation?.template && (
              <ReadOnlyField name="template">
                {calculation?.template?.name}
              </ReadOnlyField>
            )}

            <ReadOnlyField name="calculationType">
              {item?.label}
            </ReadOnlyField>

            <ReadOnlyField name="productName">
              {calculation.productName}
            </ReadOnlyField>

            <ReadOnlyField name="location">
              {`${(calculation.template?.location || calculation.location).name}
                  - ${(calculation.template?.location || calculation.location).city}
                  , ${(calculation.template?.location || calculation.location).country}`}
            </ReadOnlyField>
            <ReadOnlyField name="productLifeTime">
              {calculation.template?.productLifeTime || calculation.productLifeTime}
            </ReadOnlyField>
          </GroupFields>
          <GroupFieldsDivider />
          <GroupFields>
            <GroupFieldsHeader>{t('lca method')}</GroupFieldsHeader>
            <ReadOnlyField name="method">
              {calculation.template?.method.name || calculation.method.name}
            </ReadOnlyField>
            <ReadOnlyField name="calculationPublisher">
              {findPublisherOperator('publisher', calculation, calculation.template)?.name || '-'}
            </ReadOnlyField>
            <ReadOnlyField name="calculationOperator">
              {findPublisherOperator('operator', calculation, calculation.template)?.name || '-'}
            </ReadOnlyField>

            <ReadOnlyField name="wasteRegion">
              {wasteRegions.selected
                  && `${wasteRegions.selected.name}, ${wasteRegions.selected.code}`}
            </ReadOnlyField>
          </GroupFields>
          <GroupFieldsDivider />
          <GroupFields>
            <GroupFieldsHeader>{t('standardUnit.name')}</GroupFieldsHeader>
            {/* eslint-disable-next-line no-nested-ternary */}
            {isSemifinished ? (
              <ReadOnlyField name="standardUnit">
                {standardUnits.selected
                    && `(${standardUnits.selected.abbreviation}) ${standardUnits.selected.unit}`}
              </ReadOnlyField>
            ) : (
              standardUnitForLCA.selected ? (
                <ReadOnlyField name="functionalUnit">
                  <div>
                    <p>
                      {`${standardUnitForLCA.selected.unit}
                        (${standardUnitForLCA.selected.elementName})`}
                    </p>
                    <p><sub>{standardUnitForLCA.selected.description}</sub></p>
                  </div>
                </ReadOnlyField>
              ) : (
                <>
                  <ReadOnlyField name="functionalUnitTitle">
                    {calculation?.template?.functionalUnitTitle || customUnitForLCA.title}
                  </ReadOnlyField>

                  <ReadOnlyField name="functionalUnitDescription">
                    {customUnitForLCA.description}
                  </ReadOnlyField>

                  <ReadOnlyField name="standardUnit">
                    {`(${customUnitForLCA?.selected?.abbreviation})
                      ${customUnitForLCA?.selected?.unit}`}
                  </ReadOnlyField>
                </>
              )
            )}
          </GroupFields>
          {!isSemifinished && (
            <>
              <GroupFieldsDivider />
              <GroupFields>
                <GroupFieldsHeader>{`${t('productType.label')} (A4)`}</GroupFieldsHeader>
                <ReadOnlyField name="productType">
                  {methods.productType?.name}
                </ReadOnlyField>
                {methods.productType?.id === 3 && (
                  <>
                    <ReadOnlyField name="transportProfileToConstructionSite">
                      {_.find(
                        methods.profiles,
                        ({ '@id': id }) => (calculation.template
                          ? calculation.template.transportProfileToConstructionSite === id
                          : id === calculation.transportProfileToConstructionSite),
                      )?.name}
                    </ReadOnlyField>

                    <ReadOnlyField name="distanceToConstructionSite">
                      {calculation.template?.distanceToConstructionSite
                      || calculation.distanceToConstructionSite}
                    </ReadOnlyField>
                  </>
                  )}
              </GroupFields>
            </>
          )}
        </ViewModeContainer>
      </Placeholder>
    </>
  );
};

export default ViewBasics;
