import * as types from './mercure.types';

export const setTopic = (payload) => ({
  type: types.SET_TOPIC,
  payload,
});

export const setUpdateMercure = (payload) => ({
  type: types.SET_UPDATE_MERCURE,
  payload,
});

export const setEnvTopic = (payload) => ({
  type: types.SET_ENV_TOPIC,
  payload,
});

export const setUpdateEnvMercure = (payload) => ({
  type: types.SET_UPDATE_ENV_MERCURE,
  payload,
});

// Calculation imports
export const setCalculationTopic = (payload) => ({
  type: types.SET_CALCULATION_TOPIC,
  payload,
});
// Calculation imports
export const setUpdateCalculationMercure = (payload) => ({
  type: types.SET_UPDATE_CALCULATION_MERCURE,
  payload,
});

export const setPdisTopic = (payload) => ({
  type: types.SET_PDIS_TOPIC,
  payload,
});

export const setUpdatePdisMercure = (payload) => ({
  type: types.SET_UPDATE_PDIS_MERCURE,
  payload,
});

export const setCalculationRealTimeResultsTopic = payload => ({
  type: types.SET_CALCULATION_REAL_TIME_RESULTS_TOPIC,
  payload,
});

export const setUpdateCalculationRealTimeResultsContent = payload => ({
  type: types.SET_UPDATE_CALCULATION_REAL_TIME_RESULTS,
  payload,
});
