import styled from 'styled-components/macro';
import { black } from 'config/style';

export const reactModalStyles = {
  overlay: {
    background: black(0.4),
    height: '100%',
    width: '100%',
    position: 'fixed',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  content: {
    boxShadow: '0 2px 5px rgba(57, 57, 57, 0.4)',
    background: '#ffffff',
    padding: '1.68rem 2.37rem',
    top: 'unset',
    left: 'unset',
    right: 'unset',
    bottom: 'unset',
    border: 'none',
    borderRadius: 0,
  },

};

export const Wrapper = styled.div`
  background: ${black(0.4)};
  height: 100vh;
  width: 100%;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

export const Card = styled.div`
  box-shadow: 0 2px 5px rgba(57, 57, 57, 0.4);
  background: #ffffff;
  padding: 1.9rem;
  min-width: 30rem;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  font-size: 1.625rem;
  margin-bottom: 1.5rem;
`;

export const Close = styled.button`
  width: 1.88rem;
  height: 1.88rem;
  border-radius: 100%;
  background: white;
  border: 0;
  padding: 10px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 1.9rem;
  font-size: 14px;
`;
