import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Calculation, useCanEdit } from 'hooks/calculation.hooks';

import Button from 'components/Button/Button';
import { ReadOnlyField } from 'components/Field';

import { ViewModeContainer, TitleBar } from 'routes/GlobalStyle.styled';
import HTML from 'components/RenderHTML';
import { Placeholder } from 'components/Splash/Loading';
import { showFile } from 'utils';
import { ReadOnlyImageField } from '../../../../components/Field/field';

const DefaultSubstantiations = ({ template }) => {
  const { t } = useTranslation();
  const canEdit = useCanEdit();
  let { result: data } = useContext(Calculation);

  if (template) data = template;

  const [allocationImage, setAllocationImage] = React.useState(null);
  const isSemi = (data?.calculationType === 'semi_finished_product');

  const loaders = [];

  if (data?.allocationImage?.contentUrl || data?.template?.allocationImage?.contentUrl) {
    loaders.push(allocationImage === null);
  }

  React.useEffect(() => {
    if (data?.allocationImage?.contentUrl || data?.template?.allocationImage?.contentUrl) {
      showFile(
        `${process.env.REACT_APP_API_URL}${(data?.allocationImage?.contentUrl || data?.template?.allocationImage?.contentUrl)}`,
      ).then(setAllocationImage);
    }
  }, [data]);

  return (
    <>
      <TitleBar>
        {t('expert functions input output substantiations')}

        {canEdit && (
          <Button as={Link} styling="select" to="edit">
            {t('edit')}
          </Button>
        )}
      </TitleBar>
      <Placeholder loaders={loaders}>
        <ViewModeContainer>
          <ReadOnlyField name="allocation">
            <HTML html={(data?.template?.allocation || '')?.concat(data.allocation || '')} />
          </ReadOnlyField>

          <ReadOnlyImageField alt="Allocation" name="allocation image" src={allocationImage} />

          <ReadOnlyField name="transport to manufacturer">
            <HTML html={(data?.template?.transportToManufacturer || '')?.concat(data.transportToManufacturer || '')} />
          </ReadOnlyField>

          <ReadOnlyField name="production process (a3) | production waste">
            <HTML html={(data?.template?.productionProcessProductionWaste || '')?.concat(data.productionProcessProductionWaste || '')} />
          </ReadOnlyField>

          <ReadOnlyField name="production process (a3) | energy consumption">
            <HTML html={(data?.template?.productionProcessEnergyConsumption || '')?.concat(data.productionProcessEnergyConsumption || '')} />
          </ReadOnlyField>

          {!isSemi && (
            <>
              <ReadOnlyField name="transport to building site">
                <HTML html={(data?.template?.transportToBuildingSite || '')?.concat(data.transportToBuildingSite || '')} />
              </ReadOnlyField>

              <ReadOnlyField name="construction stage">
                <HTML html={(data?.template?.constructionStage || '')?.concat(data.constructionStage || '')} />
              </ReadOnlyField>

              <ReadOnlyField name="use stage">
                <HTML html={(data?.template?.useStage || '')?.concat(data.useStage || '')} />
              </ReadOnlyField>

              <ReadOnlyField name="maintenance">
                <HTML html={(data?.template?.maintenance || '')?.concat(data.maintenance || '')} />
              </ReadOnlyField>

              <ReadOnlyField name="de-construction demolition">
                <HTML html={(data?.template?.deconstructionDemolition || '')?.concat(data.deconstructionDemolition || '')} />
              </ReadOnlyField>

              <ReadOnlyField name="transport end life stage">
                <HTML html={(data?.template?.transportEndLifeStage || '')?.concat(data.transportEndLifeStage || '')} />
              </ReadOnlyField>

              <ReadOnlyField name="waste processing">
                <HTML html={(data?.template?.wasteProcessing || '')?.concat(data.wasteProcessing || '')} />
              </ReadOnlyField>

              <ReadOnlyField name="final disposal">
                <HTML html={(data?.template?.finalDisposal || '')?.concat(data.finalDisposal || '')} />
              </ReadOnlyField>

              <ReadOnlyField name="environmental costs and benefits of recycling and reuse">
                <HTML html={(data?.template?.environmentalCosts || '')?.concat(data.environmentalCosts || '')} />
              </ReadOnlyField>
            </>
          )}
        </ViewModeContainer>
      </Placeholder>
    </>
  );
};

export default DefaultSubstantiations;
