/* eslint-disable react-hooks/exhaustive-deps */
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useAsync } from 'react-async-hook';

import { methods } from 'services';

export const useMethods = (calculation = {}) => {
  const {
    productType: calcProductType,
    transportProfileToConstructionSite: calcProfile,
    distanceToConstructionSite: distance = '',
    calculationType,
    method: { id: methodId } = {},
    template: {
      productType: templateProductType,
      transportProfileToConstructionSite: templateProfile,
      distanceToConstructionSite: templateDistance,
    } = {},
  } = calculation || {};
  const isSemi = calculationType === 'semi_finished_product';

  const [methodLoader, setMethodBusy] = useState(true);
  const [profilesLoader, setProfilesBusy] = useState(true);
  const [productTypesLoader, setProductTypesLoader] = useState(true);

  const [methodsList, setMethodsList] = useState([]);
  const [productTypes, setProductTypes] = useState([]);
  const [profiles, setProfiles] = useState([]);
  const [productType, setProductType] = useState(null);
  const [transportProfileToConstructionSite, setProfile] = useState(null);
  const [distanceToConstructionSite, setDistanceToConstructionSite] = useState(
    templateDistance || distance,
  );

  useEffect(() => {
    if (calculation) {
      methods.all()
        .then(({ data }) => setMethodsList(data))
        .finally(() => setMethodBusy(false));
    }
  }, []);

  useEffect(() => {
    if (!isSemi) {
      setProfilesBusy(true);
      methods
        .profiles()
        .then(setProfiles)
        .finally(() => setProfilesBusy(false));

      if (methodId) {
        setProductTypesLoader(true);
        methods
          .get(methodId)
          .then(values => setProductTypes(values?.productTypes))
          .finally(() => setProductTypesLoader(false));
      }
    } else {
      setProfilesBusy(false);
      setProductTypesLoader(false);
    }
  }, [isSemi, methodId]);

  useEffect(() => {
    const value = templateProductType || calcProductType;
    if (value && calculation) {
      if (value?.id) {
        setProductType(_.find(productTypes, type => type?.id === value?.id));
      } else setProductType(_.find(productTypes, type => type?.['@id'] === value));
    }
  }, [calcProductType, templateProductType, productTypes]);

  useEffect(() => {
    const value = templateProfile || calcProfile;
    if (value && calculation) setProfile(_.find(profiles, profl => profl['@id'] === value));
  }, [calcProfile, profiles]);

  return !calculation ? null : {
    list: methodsList,

    productTypes, // aka: Transport to construction site
    productType,
    setProductType,

    profiles, // aka: the method of transport
    transportProfileToConstructionSite,
    setProfile,

    distanceToConstructionSite,
    setDistanceToConstructionSite,
    loading: _.some([methodLoader, profilesLoader, productTypesLoader]),
  };
};

export const useCalculationMethod = methodId => useAsync(
  () => methodId && methods.get(methodId), [methodId],
);

export const useAllMethods = () => useAsync(() => methods.all(), []);
