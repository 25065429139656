import React from 'react';
import Button from 'components/Button/Button';
import { useTranslation } from 'react-i18next';
import { reduxForm, Form, Field } from 'redux-form';
import { FormInput } from 'components/Input/Input';
import { FormSelect } from 'components/Select/Select';
import { required } from 'routes/Calculations/Detail/Basics/validation';

const LocationModalForm = ({
  pristine,
  invalid,
  submitting,
  handleSubmit,
  handleSubmitValues,
  transportProfiles,
  isEdit,
  loadPageOptions,
  loadPageOptionsSuppliers,
  suppliers,
  isSupplier,
}) => {
  const { t } = useTranslation();
  return (
    <Form onSubmit={handleSubmit(handleSubmitValues)}>
      <Field
        component={FormSelect}
        name="transportProfile"
        props={{
          name: 'transportProfile',
          options: transportProfiles,
          placeholder: t('choose transportation'),
          isSearchable: true,
          loadOptions: loadPageOptions,
          isDisabled: !transportProfiles,
          fullInputWidth: true,
        }}
        validate={isEdit ? null : required}
      />
      <Field
        component={FormInput}
        name="distance"
        props={{
          name: 'distance',
          placeholder: t('distance (km)'),
          fullInputWidth: true,
        }}
        validate={isEdit ? null : required}
      />
      {isSupplier
        && (
          <Field
            component={FormSelect}
            name="supplier"
            props={{
              name: 'supplier',
              options: suppliers,
              placeholder: t('choose supplier'),
              isSearchable: true,
              loadOptions: loadPageOptionsSuppliers,
              isDisabled: !suppliers,
              fullInputWidth: true,
            }}
            validate={isEdit ? null : required}
          />
        )}
      <Button disabled={pristine || invalid} loading={submitting} styling="select" type="submit">
        {t(isEdit ? 'save changes' : 'add transportation')}
      </Button>
    </Form>
  );
};

export default reduxForm({
  form: 'modal-location',
  enableReinitialize: true,
})(LocationModalForm);
