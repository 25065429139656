import React, { Suspense, lazy, useRef } from 'react';
import { render } from 'react-dom';
import {
  Router, Route, Switch, Redirect,
} from 'react-router-dom';
import { Provider, useSelector } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ThemeProvider } from 'styled-components';
import { ToastContainer } from 'react-toastify';
import { store, persistor } from 'state';
import { history } from 'services/network';

import GlobalStyles, { theme } from 'components/Global.styled';
import ScrollToTop from 'components/ScrollTop';
import requireAuth from 'components/requireAuth';
import Splash from 'components/Splash/Splash';
import Nav from 'components/Nav/Nav';

import RethinkDashboard from 'routes/RethinkDashboard';
import Calculations from 'routes/Calculations/Overview';
import EditCalculation from 'routes/Calculations/Detail/Basics';
import NewCalculation from 'routes/Calculations/Detail/Basics/NewCalculation';
import CalculationsSearch from 'routes/Calculations/Search';
import Detail from 'routes/Calculations/Detail/Detail';
import Profiles from 'routes/Profiles/Overview';
import ImportProfile from 'routes/Profiles/Import/Import';
import ProfileDetail from 'routes/Profiles/Detail/ProfileDetail';
import Scenarios from 'routes/Scenarios/Overview';
import ScenarioDetail from 'routes/Scenarios/Detail/ScenarioDetail';

import NotFound from 'routes/NotFound';
import Login from 'routes/Auth/Login/Login';
import Logout from 'routes/Auth/Logout/Logout';
import MyProfile from 'routes/MyProfile/MyProfile';
import Manage from 'routes/Manage/Manage';
import SuppliersAndLocations from 'routes/SuppliersAndLocations/SuppliersAndLocations';
import Location from 'routes/SuppliersAndLocations/Location';
import Import from 'routes/SuppliersAndLocations/Import';
import ImportCalculation from 'routes/Calculations/Import/Import';
import ImportPdis from 'routes/Calculations/Detail/Inputs/PredefinedInputSets/Import/Import';
import PdisDetail from 'routes/Calculations/Detail/Inputs/PredefinedInputSets/PdisDetail';
import NewPdis from 'routes/Calculations/Detail/Inputs/PredefinedInputSets/NewPdis';
import NewTemplate from 'routes/Calculations/Detail/Templates/NewTemplate';
import TemplateDetail from 'routes/Calculations/Detail/Templates/TemplateDetail';
import ReviewsManage from 'routes/Reviews/ReviewsManage';
import { alertToast } from 'config/toast';
import * as serviceWorker from './serviceWorker';
import './i18n/i18n';
import Documentation from './routes/Documentation';
import { MercureSubscriber } from './utils/MercureSubscriber';

// Lazy-Loaded routes
const Forgot = lazy(() => import('./routes/Auth/Forgot/Forgot'));
const Reset = lazy(() => import('./routes/Auth/Reset'));

const Mercure = () => {
  const {
    updateMercure,
    importTopic,
    importEnvTopic,
    updateEnvMercure,
    importCalculationTopic,
    updateCalculationMercure,
    importPdisTopic,
    updatePdisMercure,
    calculationRealTimeResultsTopic,
    updateCalculationRealTimeResults,
  } = useSelector(state => state.mercure);
  const sentToastState = useRef(null);
  const sentToastType = useRef(null);

  const toast = (message, type, name, link) => {
    alertToast(
      message,
      type,
      {
        to: link,
        routeName: name,
      },
    );
  };

  const topicsList = [];
  let topic = null;
  let update = null;
  let link = null;

  if (importTopic) {
    topicsList.push(`${process.env.REACT_APP_API_URL}${importTopic['@id']}`);
    link = `/locations-&-suppliers/import/${importTopic?.id}`;
    topic = importTopic;
    update = updateMercure;
  } else if (importEnvTopic) {
    topicsList.push(`${process.env.REACT_APP_API_URL}${importEnvTopic['@id']}`);
    link = `/environmental-profiles/import/${importEnvTopic?.id}`;
    topic = importEnvTopic;
    update = updateEnvMercure;
  } else if (importCalculationTopic) {
    topicsList.push(`${process.env.REACT_APP_API_URL}${importCalculationTopic['@id']}`);
    link = `/calculations/import/${importCalculationTopic?.id}`;
    topic = importCalculationTopic;
    update = updateCalculationMercure;
  } else if (importPdisTopic) {
    topicsList.push(`${process.env.REACT_APP_API_URL}${importPdisTopic['@id']}`);
    link = `/calculations/predefined-input-sets/import/${importPdisTopic?.id}`;
    topic = importPdisTopic;
    update = updatePdisMercure;
  } else if (calculationRealTimeResultsTopic) {
    topicsList.push(`${process.env.REACT_APP_API_URL}${calculationRealTimeResultsTopic['@id']}`);
    link = null;
    topic = calculationRealTimeResultsTopic;
    update = updateCalculationRealTimeResults;
  }

  console.log('Mercure topics:', topicsList);

  return topic ? (
    <MercureSubscriber
      hub={process.env.REACT_APP_MERCURE_HUB}
      json
      topics={topicsList}
      update={data => {
        if (data?.unseenChanges) {
          const checkType = ((sentToastState.current === data?.state)
            && (sentToastType.current !== data['@type']))
            || (sentToastState.current !== data?.state);
          if ((data?.state === 'validation_completed') && checkType) {
            sentToastState.current = data?.state;
            sentToastType.current = data['@type'];
            toast('Validation completed with success!', 'success', topic?.originalFilename, link);
          } else if ((data?.state === 'import_completed') && checkType) {
            sentToastState.current = data?.state;
            sentToastType.current = data['@type'];
            toast('Import completed with success!', 'success', topic?.originalFilename, link);
          } else if ((data?.state === 'validation_failed') && checkType) {
            sentToastState.current = data?.state;
            sentToastType.current = data['@type'];
            toast('Validation failed!', 'error', topic?.originalFilename, link);
          } else if ((data?.state === 'import_failed') && checkType) {
            sentToastState.current = data?.state;
            sentToastType.current = data['@type'];
            toast('Import failed!', 'error', topic?.originalFilename, link);
          }
        }
        if (update) update(data);
      }}
    />
  ) : null;
};

const RootApp = (
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      <PersistGate loading={<Splash />} persistor={persistor}>
        <Router history={history}>
          <Mercure />
          <ScrollToTop />

          <ToastContainer />

          <Nav />

          <Suspense fallback={<Splash />}>
            <Switch>
              <Redirect exact from="/calculations" to="/calculations/root" />
              <Redirect exact from="/calculations/overview" to="/calculations/root" />
              <Route
                component={requireAuth(NewPdis)}
                exact
                path="/calculations/predefined-input-sets/new"
              />
              <Route
                component={requireAuth(NewTemplate)}
                exact
                path="/calculations/templates/new"
              />
              <Redirect
                exact
                from="/calculations/predefined-input-sets/:id/edit"
                to="/calculations/predefined-input-sets/:id/set-basics/edit"
              />
              <Route
                component={(props) => {
                  const { id } = props.match.params;
                  const { search } = props.location;
                  return (
                    <Redirect
                      to={`/calculations/predefined-input-sets/${id}/inputs/add-input${search}`}
                    />
                  );
                }}
                exact
                path="/calculations/predefined-input-sets/:id/add-input"
              />
              <Route
                component={(props) => {
                  const { id } = props.match.params;
                  const { search } = props.location;
                  return (
                    <Redirect
                      to={`/calculations/predefined-input-sets/${id}/inputs/edit-input${search}`}
                    />
                  );
                }}
                exact
                path="/calculations/predefined-input-sets/:id/edit-input"
              />
              <Redirect
                exact
                from="/calculations/templates/:id"
                to="/calculations/templates/:id/template-basics"
              />
              <Redirect
                exact
                from="/calculations/templates/:id/edit"
                to="/calculations/templates/:id/template-basics/edit"
              />
              <Route component={requireAuth(RethinkDashboard)} exact path="/" />
              <Route component={requireAuth(ReviewsManage)} exact path="/reviews" />
              <Route component={requireAuth(CalculationsSearch)} exact path="/search" />
              <Route component={requireAuth(Detail)} path="/calculation/:id" />
              <Route component={requireAuth(EditCalculation)} exact path="/calculation/:id/edit" />
              <Route component={requireAuth(TemplateDetail)} path="/calculations/templates/:id" />
              <Route
                component={requireAuth(Calculations)}
                exact
                path={[
                  '/calculations/import',
                  '/calculations/import/new',
                  '/calculations/predefined-input-sets',
                  '/calculations/templates',
                  '/calculations/predefined-input-sets/import',
                  '/calculations/predefined-input-sets/import/new',
                  '/calculations/:folderId',
                ]}
              />
              <Route
                component={requireAuth(ImportPdis)}
                exact
                path="/calculations/predefined-input-sets/import/:id"
              />
              <Redirect
                exact
                from="/calculations/predefined-input-sets/:id"
                to="/calculations/predefined-input-sets/:id/set-basics"
              />
              <Route
                component={requireAuth(PdisDetail)}
                path={[
                  '/calculations/predefined-input-sets/:id',
                ]}
              />
              <Route
                component={requireAuth(ImportCalculation)}
                exact
                path="/calculations/import/:id"
              />
              <Route
                component={requireAuth(NewCalculation)}
                exact
                path="/calculations/:folderId/new"
              />
              <Route
                component={() => <Redirect to="/environmental-profiles/general" />}
                exact
                path="/environmental-profiles"
              />
              <Route
                component={requireAuth(Profiles)}
                exact
                path={[
                  '/environmental-profiles/import',
                  '/environmental-profiles/import/new',
                  '/environmental-profiles/general',
                  '/environmental-profiles/company',
                ]}
              />
              <Route
                component={requireAuth(ImportProfile)}
                exact
                path="/environmental-profiles/import/:id"
              />
              <Route
                component={({ match }) => (
                  <Redirect
                    exact
                    to={`/environmental-profiles/${match.params.id}/basic-information`}
                  />
                )}
                exact
                path="/environmental-profiles/:id"
              />
              <Route
                component={requireAuth(ProfileDetail)}
                exact
                path={[
                  '/environmental-profiles/:id/basic-information',
                  '/environmental-profiles/:id/extra-information',
                  '/environmental-profiles/:id/environmental-effects',
                ]}
              />
              <Route
                component={() => <Redirect to="/waste-scenarios/general" />}
                exact
                path="/waste-scenarios"
              />
              <Route
                component={requireAuth(Scenarios)}
                exact
                path={['/waste-scenarios/general', '/waste-scenarios/company']}
              />
              <Route
                component={({ match }) => (
                  <Redirect exact to={`/waste-scenarios/${match.params.id}/waste-scenario`} />
                )}
                exact
                path="/waste-scenarios/:id"
              />
              <Route
                component={requireAuth(ScenarioDetail)}
                exact
                path={[
                  '/waste-scenarios/:id/waste-scenario',
                  '/waste-scenarios/:id/transport-end-of-life',
                  '/waste-scenarios/:id/used-env-profile-for-loads',
                  '/waste-scenarios/:id/used-env-profile-for-benefits',
                  '/waste-scenarios/:id/substantations',
                  '/waste-scenarios/:id/qualifyfactors',
                ]}
              />
              <Route component={() => <Redirect exact to="/manage/users" />} exact path="/manage" />
              <Route
                component={requireAuth(Manage)}
                exact
                path={[
                  '/manage/users',
                  '/manage/users/new',
                  '/manage/users/:id',
                  '/manage/users/:id/memberships',
                  '/manage/users/:id/memberships/new',
                  '/manage/users/:id/memberships/:companyId/edit',
                  '/manage/users/:id/edit',
                  '/manage/companies',
                  '/manage/companies/new',
                  '/manage/companies/:id/basic-information',
                  '/manage/companies/:id/companies',
                  '/manage/companies/new',
                  '/manage/companies/:id/edit',
                  '/manage/lca-methods',
                  '/manage/lca-methods/:id',
                  '/manage/lca-methods/:id/edit',
                  '/manage/announcements',
                  '/manage/announcements/:id',
                  '/manage/announcements/:id/edit',
                ]}
              />
              <Route
                component={
                  (props) => (
                    <Redirect
                      exact
                      to={`/manage/users/${props?.match?.params?.id}`}
                    />
                  )
                }
                exact
                path="/manage/users/:id"
              />
              <Route
                component={
                  (props) => (
                    <Redirect
                      exact
                      to={`/manage/companies/${props?.match?.params?.id}/basic-information`}
                    />
                  )
                }
                exact
                path="/manage/companies/:id"
              />
              <Route
                component={requireAuth(MyProfile)}
                exact
                path={[
                  '/user-profile/memberships/new',
                  '/user-profile/memberships/:companyId/edit',
                  '/user-profile/memberships/:companyId?',
                  '/user-profile',
                  '/user-profile/:id',
                  '/updates',
                  '/updates/:id',
                  '/announcements',
                  '/announcements/:id',
                ]}
              />
              <Route
                component={() => <Redirect exact to="/locations-&-suppliers/locations" />}
                exact
                path="/locations-&-suppliers"
              />
              <Route
                component={requireAuth(SuppliersAndLocations)}
                exact
                path={[
                  '/locations-&-suppliers/import',
                  '/locations-&-suppliers/import/new',
                  '/locations-&-suppliers/suppliers',
                  '/locations-&-suppliers/suppliers/:id',
                  '/locations-&-suppliers/suppliers/:id/edit',
                  '/locations-&-suppliers/suppliers/new',
                  '/locations-&-suppliers/locations',
                  '/locations-&-suppliers/locations/:id/edit',
                  '/locations-&-suppliers/locations/new',
                ]}
              />
              <Route
                component={requireAuth(Import)}
                exact
                path="/locations-&-suppliers/import/:id"
              />
              <Route
                component={requireAuth(Location)}
                exact
                path="/locations-&-suppliers/locations/:id"
              />

              <Route
                component={Documentation}
                exact
                path="/documentation"
              />

              <Route component={Login} exact path="/login" />
              <Route component={Logout} exact path="/logout" />
              <Route component={Forgot} exact path="/forgot" />
              <Route component={Reset} exact path="/password-reset" />
              <Route component={NotFound} path="*" />
            </Switch>
          </Suspense>
        </Router>
      </PersistGate>
    </Provider>

    <GlobalStyles />
  </ThemeProvider>
);

render(RootApp, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
/*
serviceWorker.register({
  onUpdate: registration => {
    const waitingServiceWorker = registration.waiting;

    if (waitingServiceWorker) {
      waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' });
    }
  },
});
*/
// EPD-799 unregister SW - this way everything gets fetched from the server
serviceWorker.unregister();
