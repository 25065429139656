import styled from 'styled-components';

export const InputStyle = styled.textarea`
  width: 100%;  
  height: 13.37rem;
  resize: none;
  padding: 1.13rem;
  font-size: .875rem;
  color: rgba(32, 37, 56, 0.5);
  border-radius: 2px;
  border: 1px solid rgba(0, 0, 0, 0.13);
  line-height: 21px;
`;
