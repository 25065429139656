import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import closeIcon from 'assets/close2.svg';

import Delete from './ModalContent/Delete';
import Destinations from './ModalContent/Destinations';
import Download from './ModalContent/Download';
import Location from './ModalContent/Location';
import PromptModal, { ExitModalContent } from './ModalContent/Prompt';
import Reviews from './ModalContent/Reviews';
import Submit from './ModalContent/Submit';

import * as S from './styled';

const MainModal = ({
  actions,
  confirmationModalInfo,
  content,
  exitModalContent,
  isModalOpen,
  prompt,
  promptCondition,
  selection,
  setIsModalOpen,
}) => {
  const { t } = useTranslation();
  const addTransportOrSupplier = confirmationModalInfo?.isSupplier
    ? t('add transport with supplier')
    : `${t('add transportation for')} ${confirmationModalInfo?.supplier?.name}`;
  const transportProfileName = confirmationModalInfo?.transport?.transportProfile?.name;
  const supplierName = confirmationModalInfo?.supplier?.name;
  const contentModal = (type, props) => {
    switch (type) {
      case 'download':
        return {
          component: <Download actions={actions} />,
          title: 'Download',
        };

      case 'submit':
        return {
          component: <Submit selection={selection} setIsModalOpen={setIsModalOpen} />,
          title: 'Submit for review',
        };

      case 'copy':
        return {
          component: (
            <Destinations
              selection={selection}
              setIsModalOpen={setIsModalOpen}
              type="copy"
            />
          ),
          title: 'Copy to ...',
        };

      case 'move':
        return {
          component: (
            <Destinations
              selection={selection}
              setIsModalOpen={setIsModalOpen}
              type="move"
            />
          ),
          title: 'Move to ...',
        };

      case 'prompt':
        return {
          component: <ExitModalContent {...props} />,
          title: t('there are unsaved changes'),
        };

      case 'delete':
        return {
          component: <Delete {...confirmationModalInfo} />,
          title: t('are you sure you want to delete this'),
        };

      case 'confirm':
        return {
          component: <Delete {...confirmationModalInfo} />,
          title: t('are you sure you want to do this'),
        };

      case 'supplierTransport':
        return {
          component: <Location {...confirmationModalInfo} />,
          title: confirmationModalInfo?.isEdit
            ? `${t('edit')} ${transportProfileName} ${t('for')} ${supplierName}`
            : addTransportOrSupplier,
        };
      case 'review calculation':
        // eslint-disable-next-line no-case-declarations
        const suffix = (confirmationModalInfo?.action === 'deny') ? 'request' : 'calculation';
        return {
          component: <Reviews {...confirmationModalInfo} />,
          title: t(`${confirmationModalInfo?.action} ${suffix}`),
        };

      default: return {};
    }
  };

  const ModalWrapperWithContent = (props) => (
    <S.Wrapper>
      <S.Card>
        <div>
          <S.Header>
            <p style={{ marginRight: '1.4rem' }}>
              {contentModal(content, props).title}
            </p>

            <S.Close
              onClick={() => (prompt ? props.closeModalFunc() : setIsModalOpen(false))}
              type="button">
              <img alt="close" src={closeIcon} />
            </S.Close>
          </S.Header>

          {contentModal(content, props).component}
        </div>
      </S.Card>
    </S.Wrapper>
  );

  return (
    prompt
      ? (
        <PromptModal
          condition={promptCondition}
          content={(props) => <ModalWrapperWithContent {...props} />}
          {...exitModalContent}
        />
      )
      : isModalOpen && <ModalWrapperWithContent />
  );
};

export default memo(MainModal);
