import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 1rem;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.15);
  background: white;
  margin-right: 2.5rem;
  margin-bottom: 1.3rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  position: relative;
`;

export const Section = styled.div`
  display: flex;
  align-items: center;
`;

export const Icon = styled.img`
  margin-right: 1.25rem;
  display: block;
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  font-size: .875rem;
`;

export const Options = styled.img`
  padding: .5rem;
  cursor: pointer;
  visibility: ${({ hideDropdown }) => (hideDropdown ? 'hidden' : 'visible')};
`;

export const Description = styled.p`
  font-size: .75rem
`;

export const NameDetail = styled.p`
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 11.86rem;
  white-space: nowrap
`;
