import styled, { css } from 'styled-components';
import AlertIcon from 'assets/alert.svg';
import SuccessIcon from 'assets/tick.svg';

const AlertContainer = css`
  background: url(${AlertIcon}) no-repeat;
  background-position: 1.3rem 50%;
  background-color: #fff6d0;
  border: 1px solid #ffce01;
`;

const SuccessContainer = css`
  background-image: url(${SuccessIcon});
  background-repeat: no-repeat;
  background-color: white;
  background-position: 1.3rem 50%;
  border: 1px solid #EBEBEB;
`;

export const WrapperDiv = styled.div`
  display: flex;
  align-items: center;
  img {
    cursor: pointer;
    padding: .5rem;
  }
`;

export const Row = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: white;
  border: 0;
  border-bottom: 1px solid #e4e4e4;
  font-size: .875rem;
  padding: 1.37rem 1.6rem 1.37rem 3.75rem;
  width: 100%;
  cursor: pointer;
  ${({ $alert, active, removeBorder }) => $alert
    && removeBorder && !active && css`margin-top: -1px`};
  ${({ noRemoveBorder }) => noRemoveBorder && css`margin-top: -1px`};
  ${({ $alert }) => $alert && AlertContainer};
  ${({ removeBorder, active }) => active && removeBorder && css`border: none`};

  ${({ success }) => success && SuccessContainer};

  ${({ active }) => active && css`border-bottom: none;`};
  img {
    transition: transform .15s;
    transform: rotate(-90deg);
  }

  &:disabled {
    img {
      display: none;
    }
  }

  ${({ active }) => active && css`
    img {
      transform: rotate(0);
    }
  `};
`;

export const Items = styled.p`
  font-size: .63rem;
`;

export const Padding = styled.div`
  padding: 1rem 2rem 0;
  background: white;
  border-bottom: 1px solid #e4e4e4;
`;

export const ActionsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 34rem;
`;

export const ErrorLabel = styled.div`
  background: #ffce01;
  padding: .437rem .8rem;
  color: white;
  border-radius: 1.25rem;
  font-size: .75rem;
`;

export const CollapseRow = styled.ul`
  padding: 0 1.75rem 0 4.31rem;
  list-style-type: none;
  font-size: .875rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  &:last-child {
    padding-bottom: 1.5rem;
  }
`;

export const StyledRow = styled.div`
  padding: 1rem 1rem 1rem 3.13rem;
  border: 1px solid #ffce01;
  border-top: none;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: -1px;

  ${({ $alert }) => ($alert ? AlertContainer : SuccessContainer)};

`;
