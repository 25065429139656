import styled from 'styled-components';
import { YELLOW, DARK_BLUE, footerHeight } from 'config/style';

export const StyledContainer = styled.div`
  display: flex;
  width: 100%;
  min-height: 100vh;
  color: ${DARK_BLUE};
`;

export const InputContainer = styled.form`
  background: white;
  width: 50%;
  height: calc(100vh - ${footerHeight});
`;

export const MessageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 1.6rem;
`;

export const Wrapper = styled.div`
  background: ${YELLOW};
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ButtonContainer = styled.div`
  font-size: 14px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  margin-top: 10px;
`;

export const StyledLink = styled.span`
  color: black;
  font-weight: normal;
  align-self: flex-end;
`;

export const WelcomeText = styled.p`
  color: black;
  font-weight: 500;
  font-size: 30px;
  line-height: 38px;
  width: 390px;
  text-align: center;
`;

export const StyledError = styled.p`
  color: #ff0000;
  font-weight: normal;
  font-size: 14px;
  display: block;
`;

export const StyledImg = styled.img`
  margin-bottom: 4.18rem;
  align-self: flex-start;
`;

export const HeroImg = styled.img`
  height: 100%;
  min-width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  max-height: 100vh;
  object-fit: cover;
  object-position: top left;
`;

export const StyledColumn = styled.div`
  max-width: 50%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
`;

export const StyledTitle = styled.h1`
  font-weight: 500;
  font-size: 30px;
  line-height: 40px;
  margin-bottom: 2.8rem;
`;

export const StyledSubTitle = styled.p`
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  margin-bottom: 1.5rem;
`;

export const RememberWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 19px;
`;

export const Text = styled.p`
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
`;

export const Success = styled.div`
  display: flex;
  align-items: center;

  img {
    margin-right: 10px;
  }
`;
