import React from 'react';
import {
  useRouteMatch,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';

import InputsList from './Inputs';
import ViewInput from './ViewInput';
import ViewSemifinished from './ViewSemifinished';
import EditSemifinished from './AddSemifinished';
import EditInput from './EditInput';

export default () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route component={() => <Redirect to="inputs/list" />} exact path={path} />
      <Route component={InputsList} exact path={`${path}/list`} />
      <Route component={ViewInput} exact path={`${path}/view-input`} />
      <Route component={EditInput} exact path={`${path}/add-input`} />
      <Route component={EditInput} exact path={`${path}/edit-input`} />
      <Route component={EditInput} exact path={`${path}/edit-input-predefined-input-set`} />
      <Route component={EditSemifinished} exact path={`${path}/edit-input-semifinished`} />
      <Route component={ViewSemifinished} exact path={`${path}/view-input-semifinished`} />
    </Switch>
  );
};
