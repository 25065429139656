import React from 'react';
import * as S from './TopBar.styled';

const TopBar = ({ children, ...props }) => (
  <S.Wrapper {...props}>
    {children}
  </S.Wrapper>
);

export default TopBar;
