import network from './network';

const paginatedMember = ({
  'hydra:member': data,
  'hydra:view': view,
  'hydra:totalItems': totalItems,
}) => ({
  get reviews() {
    return this.data;
  },
  data,
  nextPage: view['hydra:next'],
  totalItems,
});

export const getNextPage = (url) => network
  .get(`${process.env.REACT_APP_API_URL}${url}`, { params: { owner: 'none' } })
  .then(paginatedMember);

export const get = (params) => network
  .get('/reviews', params)
  .then(paginatedMember);

export const getOne = id => network
  .get(`/reviews/${id}`, { cache: true })
  .then(({ review }) => review);

export const getReviewers = config => network
  .get('/companies', { ...config })
  .then(res => paginatedMember(res)?.data);
