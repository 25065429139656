/* eslint-disable no-nested-ternary */
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import { mapCalculationStatus } from 'utils/calculation-statuses';
import { selectCalculation } from 'state/calculations/calculations.actions';

import DropDown from 'components/DropDown/DropDown';
import CalculationContent from 'components/DropDownContent/Calculations/Calculations';
import CheckBox from 'components/CheckBox/CheckBox';

import { actionsHoc } from 'config/bulk.helpers';

import dots from 'assets/dots.svg';
import folderVector from 'assets/calculationsIcons/folder-vector.svg';
import folderVector2 from 'assets/calculationsIcons/folder-vector-2.svg';
import * as S from './CalculationItem.styled';

const CalculationItem = ({
  selected, calculation, isReviews, reviewContent, refreshContext, company, type, isPdis,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const status = mapCalculationStatus(calculation);
  const Actions = actionsHoc(CalculationContent, [calculation['@id']], calculation, refreshContext);
  const {
    id, '@id': ref, method, productName, lastModifiedAt, folder,
  } = calculation;

  const iconBackgroundColor = status?.iconColor ? { color: status?.buttonBackgroundColor } : null;

  const select = (e) => dispatch(selectCalculation(ref, e.target.checked));

  const onClick = () => {
    history.push({
      pathname: `/calculation/${id}/`,
      state: {
        routeName: productName,
        calculation,
      },
    });
  };

  return (
    <S.Wrapper isSelected={selected} onClick={onClick}>
      <S.AlignCenter>
        <S.StyledWrapper style={{ width: '40rem', justifyContent: 'flex-start' }}>
          {!isPdis && (<CheckBox checked={selected} onChange={select} />)}

          <div style={{ minWidth: '30rem' }}>

            <S.Title>{productName}</S.Title>

            {!isPdis
              ? (
                <S.SubTitle>
                  <span>{method?.name}</span>
                </S.SubTitle>
              )
              : folder?.name && (
              <div style={{ display: 'flex', marginTop: '6px' }}>
                <p style={{ fontSize: '13px' }}>
                  <img alt="folderVector" src={folderVector} />
                  {' '}
                  {folder?.name}
                  {folder?.parent && (
                    <>
                      {' '}
                      <img alt="folderVector2" src={folderVector2} />
                      {' '}
                      {t('subfolder')}
                    </>
                  )}
                </p>
              </div>
            )}
          </div>
        </S.StyledWrapper>
      </S.AlignCenter>

      <S.AlignCenter>
        <S.StyledWrapper
          style={{ width: (company || type) ? '30.5rem' : '20rem' }}>
          {company && (
            <S.LastChanged style={{ width: '10rem' }}>
              <p>{company}</p>
            </S.LastChanged>
          )}
          {calculation?.code && (
            <S.LastChanged style={{ width: '10rem', marginLeft: '-17.5rem' }}>
                {calculation?.code}
            </S.LastChanged>
          )}
          {type && (
            <S.LastChanged style={{ marginLeft: '-0.5rem', width: '8.25rem' }}>
              <p>{type}</p>
            </S.LastChanged>
          )}
          <S.LastChanged style={{ marginLeft: company ? '-12rem' : type ? '-5rem' : 'unset' }}>
            <p>
              {moment(isReviews ? calculation.lastSubmissionJournal.createdAt
                : lastModifiedAt).format('DD-MM-YYYY HH:mm')}
            </p>
          </S.LastChanged>

          <div style={{ display: 'flex'/* , alignItems: 'center' */ }}>
            {status && (
              <status.icon
                style={{ marginRight: '2rem', marginLeft: company ? '-6rem' : 'unset' }}
                {...iconBackgroundColor}
              />
            )}

            <div style={{ visibility: isPdis ? 'hidden' : null }}>
              <DropDown content={isReviews ? reviewContent : <Actions />}>
                <S.Options alt="icon" src={dots} />
              </DropDown>
            </div>
          </div>
        </S.StyledWrapper>
      </S.AlignCenter>
    </S.Wrapper>
  );
};

CalculationItem.defaultProps = {
  selected: false,
};

export default memo(CalculationItem);
