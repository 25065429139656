import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const InfoWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: .5rem;

  *:not(:last-child) {
    margin-right: .69rem;
  }
`;

export const InfoContainer = styled.div`padding: 1rem 2.5rem;`;

export const InfoBox = styled.div`
  background: #5885A4;
  border: 1px solid #e4e4e4;
  border-radius: 4px;
  width: 8.63rem;
  height: 5rem;
  padding: .69rem;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: .562rem;
  font-weight: normal;

  p:last-child {
    font-size: 1rem;
    font-weight: 600;
  }

  span:last-child {
    font-size: 1rem;
    font-weight: 600;
  }
`;

export const InfoLink = styled(Link)`
  text-decoration: underline;
  font-size: .875rem;
  &:hover {
    cursor: pointer;
  }
`;
