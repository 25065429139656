import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAsync } from 'react-async-hook';

import { folders as foldersService, calculations } from 'services';

import Select from 'components/Select/Select';
import Button from 'components/Button/Button';

import { ButtonsWrapper } from '../styled';

const { bulk } = calculations;

const hydraMember = ({ 'hydra:member': data }) => data;
const getIRI = ({ '@id': id }) => id;

const Destinations = ({ selection, type, setIsModalOpen }) => {
  const { t } = useTranslation();
  const [destination, setDestination] = useState(undefined);
  const closeModal = () => setIsModalOpen(false);
  const { result: options, loading } = useAsync(
    () => foldersService.get().then(hydraMember),
    [],
  );

  const action = () => {
    if (type === 'copy') return bulk.copy(selection, destination).then(closeModal);
    if (type === 'move') return bulk.move(selection, destination).then(closeModal);

    throw new Error('Unknown action to perform');
  };

  return (
    <div style={{ minWidth: '20rem' }}>
      <Select
        getOptionLabel={({ name }) => name}
        getOptionValue={getIRI}
        isLoading={loading}
        onChange={({ value }) => setDestination(value)}
        options={options}
        placeholder="Select the target folder"
      />

      <ButtonsWrapper>
        <Button
          onClick={() => setIsModalOpen(false)}
          style={{ marginRight: '1rem' }}
          styling="underline">
          {t('cancel')}
        </Button>

        <Button
          disabled={!destination}
          onClick={action}
          style={{ margin: 0 }}
          styling="white">
          {t('confirm')}
        </Button>
      </ButtonsWrapper>
    </div>
  );
};

export default Destinations;
