import React, { useState, useEffect } from 'react';
import { profiles, locations } from 'services';
import LocationModalForm from 'routes/SuppliersAndLocations/LocationModalForm';

const Location = ({
  isEdit,
  transport,
  supplier,
  location,
  updateSuppliers,
  isSupplier,
}) => {
  const [transportProfiles, setTransportProfiles] = useState(null);
  const [suppliers, setSuppliers] = useState(null);
  const [page, setPage] = useState(null);
  const [pageSuppliers, setPageSuppliers] = useState(null);

  useEffect(() => {
    profiles.transport({ itemsPerPage: 999999 }).then(values => {
      setTransportProfiles(values?.transportprofiles);
      setPage(values.nextPage);
    });
    if (isSupplier) {
      locations.getSuppliers({ itemsPerPage: 999999 }).then(values => {
        const temp = values.suppliers?.map(r => ({ ...r, value: r['@id'], label: r.name }));
        setSuppliers(temp);
        setPageSuppliers(values.nextPage);
      });
    }
  }, [isEdit, isSupplier]);

  const loadPageOptions = () => {
    if (page) {
      profiles
        .getNextPageTransport(page)
        .then((values) => {
          const temp = transportProfiles.concat(values.transportprofiles);
          setTransportProfiles(temp);
          setPage(values.nextPage);
          return {
            options: temp,
            hasMore: values.nextPage,
          };
        });
    }
    return {
      options: transportProfiles || [],
      hasMore: page,
    };
  };

  const loadPageOptionsSuppliers = () => {
    if (pageSuppliers) {
      locations
        .getNextPageSuppliers(pageSuppliers)
        .then((values) => {
          const mapped = values.suppliers?.map(r => ({ ...r, value: r['@id'], label: r.name }));
          const temp = suppliers.concat(mapped);
          setSuppliers(temp);
          setPageSuppliers(values.nextPage);
          return {
            options: temp,
            hasMore: values.nextPage,
          };
        });
    }
    return {
      options: suppliers || [],
      hasMore: pageSuppliers,
    };
  };

  const handleSubmitValues = (values) => {
    const tempSupplier = values?.supplier || supplier;
    const info = {
      distance: parseFloat(values?.distance || transport?.distance),
      transportProfile: values?.transportProfile
        && (values?.transportProfile['@id'] || transport?.transportProfile['@id']),
      location: location['@id'],
      supplier: tempSupplier && tempSupplier['@id'],
    };

    if (isEdit) {
      return locations.editTransport(transport?.id, info)
        .then((result) => updateSuppliers(result, tempSupplier));
    }
    return locations.createTransportForSupplier(info)
      .then((result) => updateSuppliers(result, tempSupplier));
  };

  return (
    <LocationModalForm
      handleSubmitValues={handleSubmitValues}
      initialValues={{
        distance: transport?.distance,
        transportProfile: isEdit && {
          value: transport?.transportProfile?.id,
          label: transport?.transportProfile?.name,
        },
      }}
      isEdit={isEdit}
      isSupplier={isSupplier}
      loadPageOptions={loadPageOptions}
      loadPageOptionsSuppliers={loadPageOptionsSuppliers}
      suppliers={suppliers}
      transportProfiles={transportProfiles}
    />
  );
};

export default Location;
