import React from 'react';
import _ from 'lodash';
import { alertToast } from 'config/toast';

const ErrMsg = ({ payload: { errors = {}, message, code } }) => {
  const fields = [];
  _.forEach(errors.children, (arr, label) => {
    if (!_.isArray(arr)) {
      fields.push([label, arr]);
    }
  });

  return (
    <div>
      <b>{`Error: ${code}, ${message}`}</b>

      {fields.length && (
        <ul>
          {fields.map(([label, e]) => e.errors
            .map((err) => <li key={err}>{`${label} - ${err}`}</li>))}
        </ul>
      )}
    </div>
  );
};

export default (error) => {
  // Error 😨
  alertToast(<ErrMsg payload={error.response.data} />, 'alert');
  return error.response.data;
};
