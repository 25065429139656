import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { reduxForm, Form, Field } from 'redux-form';
import Button from 'components/Button/Button';
import { FormInput } from 'components/Input/Input';
import { FormSelect } from 'components/Select/Select';
import { required } from 'routes/Calculations/Detail/Basics/validation';
import { useHistory, useParams } from 'react-router-dom';
import MainModal from 'components/Modal';

const LocationForm = ({
  reset,
  pristine,
  invalid,
  submitting,
  handleSubmit,
  handleSubmitValues,
  pathname,
  isEdit,
  countries,
  onDeleteItem,
}) => {
  const { t } = useTranslation();

  const history = useHistory();
  const { id } = useParams();
  const [hasEditedForm, setHasEditedForm] = useState(false);

  useEffect(() => {
    reset();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return (
    <>
      <Form onSubmit={handleSubmit((props) => {
        setHasEditedForm(true);
        return handleSubmitValues(props);
      })}>
        <Field
          component={FormInput}
          name="name"
          props={{
            name: 'name',
            placeholder: t('type name'),
          }}
          validate={isEdit ? null : required}
        />
        <Field
          component={FormInput}
          name="code"
          props={{
            name: 'unique id',
            placeholder: t('type code'),
          }}
          validate={isEdit ? null : required}
        />
        <Field
          component={FormInput}
          name="address"
          props={{
            name: 'address',
            placeholder: t('type address'),
          }}
          validate={isEdit ? null : required}
        />
        <Field
          component={FormInput}
          name="postalCode"
          props={{
            name: 'zip code',
            placeholder: t('type zip code'),
          }}
          validate={isEdit ? null : required}
        />
        <Field
          component={FormInput}
          name="city"
          props={{
            name: 'city',
            placeholder: t('type city'),
          }}
          validate={isEdit ? null : required}
        />
        <Field
          component={FormSelect}
          name="country"
          props={{
            name: 'country',
            options: countries,
            placeholder: t('select country'),
            isSearchable: true,
          }}
          validate={isEdit ? null : required}
        />
        <div style={{ display: 'flex', paddingTop: '1.8rem' }}>
          <Button
            disabled={pristine || invalid}
            loading={submitting}
            style={{ marginRight: '24px' }}
            styling="select"
            type="submit">
            {t('Add location')}
          </Button>

          {isEdit && (
          <Button onClick={onDeleteItem} style={{ marginRight: '24px' }} styling="select">
            {t('remove location')}
          </Button>
          )}

          <Button
            onClick={() => history
              .push(isEdit
                ? `/locations-&-suppliers/locations/${id}` : '/locations-&-suppliers/locations')}
            styling="underline">
            {t('cancel')}
          </Button>
        </div>
      </Form>
      <MainModal
        content="prompt"
        exitModalContent={{
          navigateSideEffects: () => null,
        }}
        prompt
        promptCondition={!pristine && !hasEditedForm}
      />
    </>
  );
};

export default reduxForm({
  form: 'new-location',
  enableReinitialize: true,
})(LocationForm);
