import * as types from './company.types';

const initialState = null;

const company = (state = initialState, action) => {
  switch (action.type) {
    case types.UPDATE: {
      return { ...action.payload, selectedCompany: action.selectedCompany };
    }
    case types.SELECTED_COMPANY: {
      return { ...state, selectedCompany: action.selectedCompany };
    }
    case types.CLEAR: return initialState;
    default: return state;
  }
};

export default company;
