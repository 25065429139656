import React from 'react';
import { useTranslation } from 'react-i18next';

import * as S from '../Content.styled';

const HelpDesk = ({ close = () => {} }) => {
  const { t } = useTranslation();

  const contacts = [
    {
      label: t('Email'),
      contact: 'helpdesk@rethink-epd.com',
      value: 'mailto:helpdesk@rethink-epd.com',
      text: t('helpdesk email response'),
    },
  ];

  return (
    <S.StyledContainer>
      <S.StyledTitle>{t('Documentation')}</S.StyledTitle>
      <S.ContactsWrapper>
        <S.StyledContact>
          <a
            className="contact"
            href="/documentation"
            onClick={close}
            target="_blank">
            {t('Rethink documentation (external)')}
          </a>
        </S.StyledContact>
      </S.ContactsWrapper>

      <S.StyledTitle>{t('HelpDesk request')}</S.StyledTitle>

      <S.ContactsWrapper>
        {contacts.map((contact) => (
          <S.StyledContact key={contact.contact}>
            <p className="label">{contact.label}</p>

            <a
              className="contact"
              href={contact.value}
              onClick={close}>
              {contact.contact}
            </a>

            <p className="text">{contact.text}</p>
          </S.StyledContact>
        ))}
      </S.ContactsWrapper>
    </S.StyledContainer>
  );
};

export default HelpDesk;
