import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Calculation, useCanEdit } from 'hooks/calculation.hooks';

import Button from 'components/Button/Button';
import { ReadOnlyField } from 'components/Field';

import { ViewModeContainer, TitleBar } from 'routes/GlobalStyle.styled';
import HTML from 'components/RenderHTML';
import { Placeholder } from 'components/Splash/Loading';
import { showFile } from 'utils';
import { ReadOnlyImageField } from '../../../../components/Field/field';

const Interpretation = ({ template }) => {
  const { t } = useTranslation();
  const canEdit = useCanEdit();
  let { result: data } = useContext(Calculation);

  if (template) data = template;

  const [sensitiveAnalysisImage, setSensitiveAnalysisImage] = React.useState(null);

  const loaders = [];

  if (data?.sensitiveAnalysisImage?.contentUrl || data?.template?.sensitiveAnalysisImage?.contentUrl) {
    loaders.push(sensitiveAnalysisImage === null);
  }

  React.useEffect(() => {
    if (data?.sensitiveAnalysisImage?.contentUrl || data?.template?.sensitiveAnalysisImage?.contentUrl) {
      showFile(
        `${process.env.REACT_APP_API_URL}${(data?.sensitiveAnalysisImage?.contentUrl || data?.template?.sensitiveAnalysisImage?.contentUrl)}`,
      ).then(setSensitiveAnalysisImage);
    }
  }, [data]);

  return (
    <>
      <TitleBar>
        {t('expert functions interpretation')}

        {canEdit && (
          <Button as={Link} styling="select" to="edit">
            {t('edit')}
          </Button>
        )}
      </TitleBar>
      <Placeholder loaders={loaders}>
        <ViewModeContainer>
          <ReadOnlyField name="contribution analysis of the stages">
            <HTML html={(data?.template?.contributionAnalysisStages || '')?.concat(data.contributionAnalysisStages || '')} />
          </ReadOnlyField>

          <ReadOnlyField name="contribution analysis of the raw materials (a1)">
            <HTML html={(data?.template?.contributionAnalysisMaterialsA1 || '')?.concat(data.contributionAnalysisMaterialsA1 || '')} />
          </ReadOnlyField>

          <ReadOnlyField name="contribution analysis of the raw materials (a1-d)">
            <HTML html={(data?.template?.contributionAnalysisMaterialsA1D || '')?.concat(data.contributionAnalysisMaterialsA1D || '')} />
          </ReadOnlyField>

          <ReadOnlyField name="sensative analysis">
            <HTML html={(data?.template?.sensitiveAnalysis || '')?.concat(data.sensitiveAnalysis || '')} />
          </ReadOnlyField>

          <ReadOnlyImageField
            alt="sensative analysis"
            name="sensative analysis image"
            src={sensitiveAnalysisImage}
          />
        </ViewModeContainer>
      </Placeholder>
    </>
  );
};

export default Interpretation;
