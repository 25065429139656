import _ from 'lodash';
import network from './network';

const hydraMember = ({ 'hydra:member': data }) => data;

export const get = () => network
  .get('/countries')
  .then(hydraMember)
  .then(countries => _.toPairs(countries));

export const getWasteRegions = () => network
  .get('/waste_scenario_sets', {
    params: {
      itemsPerPage: 999999,
    },
  })
  .then(hydraMember);

export const getWasteRegionById = (url) => network
  .get(`${process.env.REACT_APP_API_URL}${url}`);

export const getWasteScenarios = config => network
  .get('/waste_scenarios', config)
  .then(hydraMember);

export const getWasteProfiles = () => network
  .get('/waste_profiles')
  .then(hydraMember);
