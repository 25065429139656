import styled, { css } from 'styled-components';
import { paddingRows, DARK_BLUE } from 'config/style';
import { Link } from 'react-router-dom';

export const Wrapper = styled.div`
  min-height: 4.69rem;
  border-bottom: 1px solid #e4e4e4;
  background: white;
  display: flex;
  justify-content: space-between;
  padding: ${paddingRows};
  cursor: pointer;
  ${({ isSelected }) => isSelected && css`background: #eeeee4;`};
  img[alt="alert icon"] {
    display: ${(props) => (props.alert ? 'block' : 'none')};
  }
`;

export const StyledWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const CheckBox = styled.label`
  display: block;
  position: relative;
  padding-left: 45px;
  margin-bottom: 20px;
  cursor: pointer;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    background: white;
    position: absolute;
    top: 0;
    left: 0;
    height: 1.25rem;
    width: 1.25rem;
    border: 1px solid #202538;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      display: none;
    }
  }

  input:checked ~ .checkmark {
    img {
      display: block;
    }
  }
`;

export const Title = styled.div`
  font-size: 1rem;
  line-height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const SubTitle = styled.p`
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: ${DARK_BLUE};
`;

export const SubTitleGrey = styled(SubTitle)`
  color: #ADADAD;
`;

export const LastChanged = styled.div`
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #a8a8a8;
  min-width: 7rem;
`;

export const Options = styled.img`
  padding: .5rem;
  cursor: pointer;
`;

export const StyledLink = styled(Link)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;
  border: 0;
  cursor: pointer;
  text-align: left;
`;

export const AlignCenter = styled.div`
  display: flex;
  align-items: center;
  visibility: ${({ hideDropdown }) => (hideDropdown ? 'hidden' : 'visible')};
`;
