import React, { useEffect } from 'react';
import { useParams, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setBreadcrumb } from 'state/sidebar/sidebar.actions';
import { useAsync } from 'react-async-hook';
import { users } from 'services';
import { Loading } from 'components/Splash/Loading';
import Memberships from 'routes/Users/Memberships';
import CompaniesPermissions from 'routes/Users/CompaniesPermissions';

const EditProfile = () => {
  const { id: userId, companyId } = useParams();
  const auth = useSelector(state => state.user);
  const dispatch = useDispatch();
  const id = userId || auth?.id;
  const { result: user, loading } = useAsync(() => id && users.getUser(id), [id]);

  useEffect(() => {
    if (user?.name) dispatch(setBreadcrumb(user?.name));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const companyAdmin = auth?.selectedMembership?.view === 'Company Admin'
    || auth?.selectedMembership?.view === 'Corporate Admin';

  const superAdmin = auth?.selectedMembership?.view === 'Super Admin'
    || auth?.selectedMembership?.view === 'Nibe Employee';

  if ((id !== auth.id) && !superAdmin && !companyAdmin) return <Redirect to="/" />;

  if (!userId && companyId) {
    // User clicked the breadcrumb with company name on profile > memberships > company
    //  go back
    return <Redirect to="/user-profile/memberships" />;
  }

  if (loading && !user) return Loading;

  if (superAdmin) return <CompaniesPermissions id={id} />;
  return <Memberships id={id} />;
};

export default EditProfile;
