import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Calculation, useCanEdit } from 'hooks/calculation.hooks';

import Button from 'components/Button/Button';
import { ReadOnlyField } from 'components/Field';

import {
  GroupFields,
  GroupFieldsDivider,
  GroupFieldsHeader,
  TitleBar,
  ViewModeContainer,
} from 'routes/GlobalStyle.styled';
import HTML from 'components/RenderHTML';
import { Placeholder } from 'components/Splash/Loading';
import { showFile } from 'utils';
import { FileDownloadButton } from '../../../../components/FileDownloadButton';
import { ReadOnlyImageField } from '../../../../components/Field/field';

const DefaultSubstantiations = ({ template }) => {
  const { t } = useTranslation();
  const canEdit = useCanEdit();
  let { result: data } = useContext(Calculation);

  if (template) data = template;

  const [productImage, setProductImage] = React.useState(null);
  const [productFlowImageFile, setProductFlowImageFile] = React.useState(null);
  const [productDescriptionImageFile, setProductDescriptionImageFile] = React.useState(null);
  const [productionProcessImageFile, setProductionProcessImageFile] = React.useState(null);
  const [constructionProcessFile, setConstructionProcessFile] = React.useState(null);
  const isSemi = (data?.calculationType === 'semi_finished_product');

  const loaders = [];

  if (data?.productImage?.contentUrl || data?.template?.productImage?.contentUrl) {
    loaders.push(productImage === null);
  }
  if (data?.productDescriptionImageFile?.contentUrl
    || data?.template?.productDescriptionImageFile?.contentUrl) {
    loaders.push(productDescriptionImageFile === null);
  }
  if (data?.productionProcessImageFile?.contentUrl
    || data?.template?.productionProcessImageFile?.contentUrl) {
    loaders.push(productionProcessImageFile === null);
  }
  if (data?.constructionProcessImageFile?.contentUrl
    || data?.template?.constructionProcessImageFile?.contentUrl) {
    loaders.push(constructionProcessFile === null);
  }

  React.useEffect(() => {
    if (data?.productImage?.contentUrl || data?.template?.productImage?.contentUrl) {
      loaders.push(productImage === null);
      showFile(
        `${process.env.REACT_APP_API_URL}${data?.productImage?.contentUrl || data?.template?.productImage?.contentUrl}`,
      ).then(setProductImage);
    }
    if (data?.productFlowImageFile?.contentUrl || data?.template?.productFlowImageFile?.contentUrl) {
      loaders.push(productImage === null);
      showFile(
        `${process.env.REACT_APP_API_URL}${data?.productFlowImageFile?.contentUrl || data?.template?.productFlowImageFile?.contentUrl}`,
      ).then(setProductFlowImageFile);
    }
    if (data?.productDescriptionImageFile?.contentUrl || data?.template?.productDescriptionImageFile?.contentUrl) {
      loaders.push(productDescriptionImageFile === null);
      showFile(
        `${process.env.REACT_APP_API_URL}${data?.productDescriptionImageFile?.contentUrl || data?.template?.productDescriptionImageFile?.contentUrl}`,
      ).then(setProductDescriptionImageFile);
    }
    if (data?.productionProcessImageFile?.contentUrl || data?.template?.productionProcessImageFile?.contentUrl) {
      loaders.push(productionProcessImageFile === null);
      showFile(
        `${process.env.REACT_APP_API_URL}${data?.productionProcessImageFile?.contentUrl || data?.template?.productionProcessImageFile?.contentUrl}`,
      ).then(setProductionProcessImageFile);
    }
    if (data?.constructionProcessImageFile?.contentUrl || data?.template?.constructionProcessImageFile?.contentUrl) {
      loaders.push(constructionProcessFile === null);
      showFile(
        `${process.env.REACT_APP_API_URL}${data?.constructionProcessImageFile?.contentUrl || data?.template?.constructionProcessImageFile?.contentUrl}`,
      ).then(setConstructionProcessFile);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <>
      <TitleBar>
        {t('information-for-report')}

        {canEdit && (
          <Button as={Link} styling="select" to="edit">
            {t('edit')}
          </Button>
        )}
      </TitleBar>
      <Placeholder loaders={loaders}>
        <ViewModeContainer style={{ padding: '1.87rem 0', display: 'unset' }}>
          <GroupFields>
            <GroupFieldsHeader>{t('general')}</GroupFieldsHeader>
            <ReadOnlyField name="teammembers">
              <HTML html={(data?.template?.teamMembers || '')?.concat(data.teamMembers || '')} />
            </ReadOnlyField>

            <ReadOnlyField name="applicable time period collected data">
              <HTML html={(data?.template?.dataCollectionYear || '')?.concat(data.dataCollectionYear || '')} />
            </ReadOnlyField>

            <ReadOnlyField name="estimations and assumptions">
              <HTML html={(data?.template?.estimatesAndAssumptions || '').concat(data?.estimatesAndAssumptions || '') || ''} />
            </ReadOnlyField>
            <ReadOnlyField name="data quality">
              <HTML html={(data?.template?.dataQuality || '').concat(data?.dataQuality || '') || ''} />
            </ReadOnlyField>
            <ReadOnlyField name="guaranties of origin">
              <HTML html={(data?.template?.guarantiesOfOrigin || '').concat(data?.guarantiesOfOrigin || '') || ''} />
            </ReadOnlyField>
          </GroupFields>
          <GroupFieldsDivider />
          <GroupFields>
            <GroupFieldsHeader>{t('product details')}</GroupFieldsHeader>

            <ReadOnlyImageField alt="product" name="picture of the product" src={productImage} />

            <ReadOnlyImageField
              alt="product-flow"
              name="custom product flow diagram"
              src={productFlowImageFile}
            />

            <ReadOnlyField name="product description for EPD (summary)">
              <HTML html={(data?.template?.description || '')?.concat(data.description || '')} />
            </ReadOnlyField>

            <ReadOnlyImageField
              alt="product description"
              name="product description, image"
              src={productDescriptionImageFile}
            />

            <ReadOnlyField name="product description (confidential)">
              <HTML html={(data?.template?.productDescriptionConfidential || '')?.concat(data.productDescriptionConfidential || '')} />
            </ReadOnlyField>

            <ReadOnlyField name="attachments supporting the product description">
              {data.productPerformanceFile || data?.template?.productPerformanceFile ? (
                <FileDownloadButton {...(data.productPerformanceFile || data?.template?.productPerformanceFile)} />
              ) : '-'}
            </ReadOnlyField>

            <ReadOnlyField name="application (intended use of product)">
              <HTML html={(data?.template?.application || '').concat(data?.application || '') || ''} />
            </ReadOnlyField>
            <ReadOnlyField name="technical data">
              <HTML html={(data?.template?.technicalData || '').concat(data?.technicalData || '') || ''} />
            </ReadOnlyField>
            <ReadOnlyField name="substances of very high concern (svhc)">
              <HTML html={(data?.template?.substancesOfVeryHighConcern || '').concat(data?.substancesOfVeryHighConcern || '') || ''} />
            </ReadOnlyField>

          </GroupFields>
          <GroupFieldsDivider />
          <GroupFields>
            <GroupFieldsHeader>{t('production details')}</GroupFieldsHeader>

            <ReadOnlyField name="description production process for EPD (summary)">
              <HTML html={(data?.template?.productionProcess || '')?.concat(data.productionProcess || '')} />
            </ReadOnlyField>

            <ReadOnlyImageField
              alt="description construction process"
              name="description production process, image"
              src={productionProcessImageFile}
            />

            <ReadOnlyField name="description production process (confidential)">
              <HTML html={(data?.template?.productionProcessConfidential || '')?.concat(data.productionProcessConfidential || '')} />
            </ReadOnlyField>

            <ReadOnlyField name="attachments supporting the production process description">
              {data.productionProcessFile || data?.template?.productionProcessFile ? (
                <FileDownloadButton {...(data.productionProcessFile || data?.template?.productionProcessFile)} />
              ) : '-'}
            </ReadOnlyField>

          </GroupFields>
          {!isSemi && (
            <>
              <GroupFieldsDivider />
              <GroupFields>
                <GroupFieldsHeader>{t('construction details')}</GroupFieldsHeader>

                <ReadOnlyField name="description construction process for EPD (summary)">
                  <HTML html={(data?.template?.constructionProcess || '')?.concat(data.constructionProcess || '')} />
                </ReadOnlyField>

                <ReadOnlyImageField
                  alt="description construction process"
                  name="description construction process, image"
                  src={constructionProcessFile}
                />

                <ReadOnlyField name="description construction process (confidential)">
                  <HTML html={(data?.template?.constructionProcessConfidential || '')?.concat(data.constructionProcessConfidential || '')} />
                </ReadOnlyField>

                <ReadOnlyField name="attachments supporting the construction process description">
                  {data.constructionProcessFile || data?.template?.constructionProcessFile ? (
                    <FileDownloadButton {...(data.constructionProcessFile || data?.template?.constructionProcessFile)} />
                  ) : '-'}
                </ReadOnlyField>

              </GroupFields>
            </>
          )}
        </ViewModeContainer>
      </Placeholder>
    </>
  );
};

export default DefaultSubstantiations;
