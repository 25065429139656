import i18next from 'i18next';
import React, { useState, useRef } from 'react';
import {
  useLocation, Switch, Route, useRouteMatch,
} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useAsync } from 'react-async-hook';

import { profiles as profilesService } from 'services';

import { selectCompany } from 'state/company/company.actions';

import * as S from 'routes/GlobalStyle.styled';

import AsideOverview from 'components/AsideOverview/AsideOverview';
import AsideLayout from 'components/AsideLayout/AsideLayout';
import { MenuItem } from 'components/VerticalMenu';
import Select from 'components/Select/Select';
import Input from 'components/Input/Input';
import NewImport from 'routes/Profiles/Import/NewImport';
import Imports from 'routes/Profiles/Import/Imports';
import Profiles from './ProfilesList';

const allSections = {
  id: 0,
  get name() { return i18next.t('all'); },
  get label() { return i18next.t('all'); },
  value: undefined,
};

const ProfilesOverview = ({ onSelect }) => {
  const { t } = useTranslation();
  const {
    id: companyId, selectedCompany, name: companyName, ...other
  } = useSelector(({ company }) => company || {});
  const { selectedMembership } = useSelector(state => state.user);
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { url } = useRouteMatch();
  const pathArray = pathname.split('/');
  const profileType = pathArray[2]; // if it's general or company
  const [selected, setSelected] = useState(profileType);
  const [input, setInput] = useState('');
  const [section, setSection] = useState(null);
  const [sections, setSections] = useState(null);
  const [subSection, setSubSection] = useState(null);
  const [company, setCompany] = useState(null);
  const [totalNumberOfRecords, setTotalNumberOfRecords] = useState(null);
  const [subSections, setSubSections] = useState([]);
  const [clearInput, setClearInput] = useState(null);

  const reviewer = selectedMembership?.roleName === 'ROLE_REVIEWER';

  const highRoles = selectedMembership?.view === 'Super Admin'
    || selectedMembership?.view === 'Nibe Employee'
    || selectedMembership?.roleName === 'ROLE_CORPORATE_EPD_ADMIN'
    || selectedMembership?.roleName === 'ROLE_EPD_ADMIN';

  const time = useRef(0);

  useAsync(
    () => profilesService
      .allSections({
        itemsPerPage: 9999, parent: 'root',
      })
      .then(({ data }) => setSections([allSections, ...data]?.filter(s => s?.parent === null))),
    [],
  );

  if (profileType !== selected) setSelected(profileType);

  const handleSection = (value) => {
    setSubSection(null);
    setSection(value);
    return profilesService
      .allSections({
        itemsPerPage: 9999, parent: value?.id,
      })
      .then(r => {
        setSubSections(r.sections);
        return r;
      });
  };

  const handleInputChange = (e) => {
    if (time.current) clearTimeout(time.current);
    const inputText = e.target.value;
    time.current = setTimeout(() => {
      const clearSpaces = inputText && inputText.length > 0 && inputText.trim();
      if (clearSpaces && (input !== inputText)) {
        setInput(inputText);
        clearTimeout(time.current);
      }
    }, 2000);
  };

  const clearFilter = () => {
    const inputValue = { value: '' };
    setClearInput(inputValue);
    setInput('');
    setSection(null);
    setSubSection(null);
    setCompany({ label: companyName, value: companyName });
    selectCompany({
      id: companyId, name: companyName, label: companyName, value: companyName, ...other,
    })(dispatch);
  };

  const handleOnKeyDown = (event) => {
    if (event.key === 'Enter') {
      const inputText = event.target.value;
      const clearSpaces = inputText && inputText.length > 0 && inputText.trim();
      if (clearSpaces) {
        setInput(inputText);
      } else setInput('');
    }
  };

  const onFocus = () => setClearInput(null);

  const CustomProfiles = React.useCallback(props => (
    <Profiles
      {...props}
      name={input}
      onSelect={onSelect}
      section={section}
      setTotalNumberOfRecords={setTotalNumberOfRecords}
      subSection={subSection}
    />
  ), [input, onSelect, section, subSection]);

  return (
    <AsideLayout
      LeftPane={(
        <div>
          <S.AsideTitle size="1.33rem">
            {t(url?.includes('/import/new')
              ? 'import environmental profiles.label' : 'environmentalProfile.name')}
          </S.AsideTitle>

          {url?.includes('/import/new')
            ? (
              <S.LinksWrapper>{t('import environmental profiles.hint')}</S.LinksWrapper>
            )
            : (
              <S.LinksWrapper style={{ padding: '1.56rem 2.5rem' }}>
                <MenuItem to="/environmental-profiles/general">
                  {t('general')}
                </MenuItem>

                {!reviewer && (
                  <MenuItem to="/environmental-profiles/company">
                    {t('Company')}
                  </MenuItem>
                )}

                {highRoles && (
                  <MenuItem to="/environmental-profiles/import">
                    {t('import')}
                  </MenuItem>
                )}
              </S.LinksWrapper>
            )}

          {selected !== 'import' && (
          <AsideOverview
            clearFilter={clearFilter}
            clearingFilterCondition={
              (input || section || (company
                && (company.id !== 'none'))) && (totalNumberOfRecords !== null)
            }
            padding="1.87rem 2.5rem"
            results={totalNumberOfRecords}>
            <div style={{ marginBottom: '1.5rem' }}>
              <S.Label>{t('Name / ID')}</S.Label>
              <Input
                {...clearInput}
                id="name"
                name="name"
                onChange={handleInputChange}
                onFocus={onFocus}
                onKeyDown={handleOnKeyDown}
                placeholder={t('filter by name or id')}
              />
            </div>

            <div style={{ marginBottom: '1.5rem' }}>
              <S.Label>{t('Category')}</S.Label>
              <Select
                isDisabled={!sections}
                isSearchable
                name="section"
                onChange={handleSection}
                options={sections}
                placeholder={t('category')}
                value={section}
              />
            </div>

            <div style={{ marginBottom: '1.5rem' }}>
              <S.Label>{t('Sub category')}</S.Label>
              <Select
                isDisabled={!subSections}
                isSearchable
                name="subSection"
                onChange={setSubSection}
                options={[allSections, ...subSections]}
                placeholder={t('sub category')}
                value={subSection}
              />
            </div>
          </AsideOverview>
          )}
        </div>
      )}>
      <Switch>
        <Route component={Imports} exact path="/environmental-profiles/import" />
        <Route component={NewImport} exact path="/environmental-profiles/import/new" />
        <Route component={CustomProfiles} exact path="/environmental-profiles/general" />
        {!reviewer
          && <Route component={CustomProfiles} exact path="/environmental-profiles/company" />}
      </Switch>
    </AsideLayout>
  );
};

export default ProfilesOverview;
