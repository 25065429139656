import _ from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import moment from 'moment';

import { versions } from 'services';

import { confirmationModalSetup } from 'components/Modal/ModalContent/Delete';
import { Loading, Placeholder } from 'components/Splash/Loading';
import ListItem from 'components/ListItem/ListItem';
import Button from 'components/Button/Button';
import MainModal from 'components/Modal';

import * as S from 'routes/Calculations/Overview.styled';
import TopbarActions from 'components/TopbarActions/TopbarActions';
import edit from 'assets/edit.svg';
import deleteIcon from 'assets/delete.svg';
import AlertIcon from 'assets/alert.svg';
import TopBar from 'components/TopBar/TopBar';
import LastChangedStatus from 'components/TopBar/LastChangedStatus';
import Name from 'components/TopBar/Name';

const stickyStyle = {
  background: '#FFF6D0',
  border: '1px solid #FFCE01',
};

const Announcements = ({ isManage }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [announcements, setAnnouncements] = useState(null);
  const [page, setPage] = useState(null);
  const [confirmationModal, setConfirmationModal] = useState(null);
  const [sorting, setSorting] = useState(undefined);
  const [createdAt, setCreatedAt] = useState('desc');
  const [loading, setLoading] = useState(false);

  React.useEffect(() => {
    const params = { owner: 'none' };
    if (sorting) params['order[name]'] = sorting;
    if (createdAt) params['order[publishedAt]'] = createdAt;
    setLoading(true);
    versions
      .allAnnouncements(params)
      .then(values => {
        const arr = values.versions
          .sort((a, b) => ((moment(a.stickyUntil).isAfter()
            || moment(b.stickyUntil).isAfter())
            ? new Date(b.stickyUntil) - new Date(a.stickyUntil) : 0));
        setPage(values.nextPage);
        setAnnouncements(arr);
      })
      .finally(() => setLoading(false));
  }, [sorting, createdAt]);

  const fetchData = () => {
    if (!page) return undefined;
    return versions
      .getNextPageAnnouncements(page)
      .then((values) => {
        setPage(values.nextPage);
        setAnnouncements(announcements.concat(values.announcements));
        return values;
      });
  };

  const onDeleteItem = id => confirmationModalSetup(
    setConfirmationModal,
    (closeModal) => versions
      .deleteAnnouncement(id)
      .then(() => {
        setAnnouncements(_.filter(announcements, item => item.id !== id));
        return closeModal();
      }),
  );

  const onEditItem = (data) => history
    .push(`/manage/announcements/${data.id}/edit`, { routeName: data.title });

  const ListItemContent = ({ data }) => (
    <>
      <S.Option
        key="edit"
        isClickable
        onClick={() => onEditItem(data)}>
        <div>
          <img alt="icon" src={edit} />
        </div>

        <S.StyledLabel isRegular>
          {t('edit')}
        </S.StyledLabel>
      </S.Option>

      <S.Option key="delete" isClickable onClick={() => onDeleteItem(data.id)}>
        <div>
          <img alt="icon" src={deleteIcon} />
        </div>

        <S.StyledLabel color="#BF2E00" isRegular>
          {t('delete')}
        </S.StyledLabel>
      </S.Option>
    </>
  );

  const announcementsList = () => (
    <S.OverviewRows id="overviewRowsId">
      <InfiniteScroll
        dataLength={announcements?.length}
        hasMore={page}
        loader={Loading}
        next={fetchData}
        scrollableTarget="overviewRowsId"
        scrollThreshold={0.4}>
        {_.map(announcements, (data) => (
          <ListItem
            key={data.id}
            $alert={moment(data.stickyUntil).isAfter()}
            dropdownContent={isManage && <ListItemContent data={data} />}
            icon={<img alt="alert icon" src={AlertIcon} />}
            lastChanged={data.publishedAt}
            linkStyle={moment(data.stickyUntil).isAfter()
              ? { background: stickyStyle.background } : null}
            name={data.title}
            style={moment(data.stickyUntil).isAfter() ? stickyStyle : null}
            to={{
              pathname: isManage
                ? `/manage/announcements/${data.id}` : `/announcements/${data.id}`,
              state: {
                routeName: data.title,
              },
            }}
          />
        ))}
      </InfiniteScroll>
    </S.OverviewRows>
  );

  return (
    <>
      {isManage
        && (
        <TopbarActions>
          <Button
            as={Link}
            styling="select"
            to="/manage/announcements/new">
            {t('add new announcement')}
          </Button>
        </TopbarActions>
        )}

      <TopBar>
        <Name
          inverted
          onOrderBy={() => {
            if (!sorting) setSorting('asc');
            else if (sorting === 'asc') setSorting('desc');
            else setSorting(null);
          }}
          order={sorting}
        />

        <div
          style={isManage && {
            marginRight: '60px',
          }}>
          <LastChangedStatus
            inverted
            label={t('date')}
            onOrderBy={order => {
              setCreatedAt(order);
            }}
            order={createdAt}
          />
        </div>
      </TopBar>

      <Placeholder loaders={[loading]}>
        {(announcements?.length > 0) ? announcementsList() : 'No items to show'}
      </Placeholder>

      <MainModal
        confirmationModalInfo={confirmationModal}
        content="delete"
        isModalOpen={confirmationModal}
        setIsModalOpen={setConfirmationModal}
      />
    </>
  );
};

export default Announcements;
