import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useAsync } from 'react-async-hook';
import { standardunits, methods } from 'services';

const requestConfig = { cache: true };

export const useStandardUnits = (defaultSelectedId) => {
  const asyncUnits = useAsync(standardunits, []);

  return {
    ...asyncUnits,
    set: () => { throw new Error('deprecated method'); },
    get selected() {
      if (!defaultSelectedId) return _.head(this.result);
      return _.find(this.result, l => l['@id'] === defaultSelectedId);
    },
    get list() {
      const grouped = _.groupBy(this.result, 'quantityName');
      const categories = Object.keys(grouped);

      return _.map(categories, category => ({
        label: category,
        options: grouped[category],
      }));
    },
    get units() { return this.result; },
  };
};

export const useCustomUnitForLCA = (lcaMethodId, data = {}) => {
  const {
    functionalUnitTitle = '',
    functionalUnitDescription = '',
    standardUnit,
  } = _.defaults({}, data);
  const [title, setTitle] = useState(functionalUnitTitle);
  const [description, setDescription] = useState(functionalUnitDescription);
  const { list, selected } = useStandardUnits(standardUnit);
  const [unit, setUnit] = useState(null);

  useEffect(() => {
    if (selected) setUnit(selected);
    setDescription(functionalUnitDescription);
    setTitle(functionalUnitTitle);
  }, [functionalUnitDescription, functionalUnitTitle, selected]);

  return {
    selected: unit,
    set: setUnit,
    list,

    title: title || '',
    setTitle: ({ target }) => setTitle(target.value),

    description: description || '',
    setDescription: ({ target }) => setDescription(target.value),
  };
};

export const useStandardUnitForLCA = (id, data) => {
  const { functionalUnit } = _.defaults({}, data);

  const [list, setList] = useState([]);
  const [loading, setBusy] = useState(true);
  const [selected, setSelected] = useState(null);

  useEffect(() => {
    setBusy(true);
    methods
      .functionalunits(requestConfig)
      .then(setList)
      .finally(() => setBusy(false));
  }, []);

  useEffect(() => {
    if (functionalUnit && list.length) {
      setSelected(_.find(list, l => l['@id'] === functionalUnit));
    } else if (!functionalUnit) setSelected(null);
  }, [functionalUnit, list]);

  return {
    selected,
    list,
    set: setSelected,
    loading,
  };
};
