import _ from 'lodash';
import React, {
  memo, useState, useEffect, useRef,
} from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useAsync } from 'react-async-hook';

import { /* companies as companiesServices, */ methods as methodsService } from 'services';
import { selectCompany } from 'state/company/company.actions';
import * as actions from 'state/calculations/calculations.actions';

import Radio from 'components/Radio/Radio';
import Select from 'components/Select/Select';
import Input from 'components/Input/Input';
import AsideOverview from 'components/AsideOverview/AsideOverview';

import { statusStyle } from 'config/style';
import { Label, WrapperInput } from 'routes/GlobalStyle.styled';
import {
  ValueSelect, RadioContainer,
} from './CalculationsFilter.styled';

const CalculationsFilter = ({ hasFilterIcon, query }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  // const userDetails = useSelector(({ user }) => user);
  const { url } = useRouteMatch();
  const h = useHistory();
  const {
    id: companyId, selectedCompany, name: companyName, ...other
  } = useSelector(({ company }) => company || {});
  const { filters, totalItems } = useSelector(({ calculations }) => calculations);
  const {
    state, calculationType, productName = '', method,
  } = filters;

  /* const superAdmin = userDetails?.selectedMembership?.view === 'Super Admin'
    || userDetails?.selectedMembership?.view === 'Nibe Employee'; */

  /* const { result: companies } = useAsync(
    () => superAdmin && companiesServices
      .getAll()
      .then(({ companies: c, data }) => c || data),
    [superAdmin],
  ); */

  const statusOptions = _.map(statusStyle, status => ({
    value: status.id,
    label: (
      <ValueSelect>
        <status.icon />
        <p>{t(status.text)}</p>
      </ValueSelect>
    ),
  }));

  const { result } = useAsync(() => methodsService.all(), []);

  const [stateState, setStateState] = useState(
    _.find(statusOptions, ({ value }) => value === state),
  );
  const time = useRef(0);
  const [input, setInput] = useState('');
  const [clearInput, setClearInput] = useState(null);
  const [isFirst, setIsFirst] = useState(true);
  // const [company, setCompany] = useState(selectedCompany);
  const [productNameState, setProductNameState] = useState(productName);
  const [methodState, setMethodState] = useState(
    _.find(result?.methods, ({ value }) => value === method),
  );

  useEffect(() => {
    if (query) {
      const inputValue = { value: query };
      setClearInput(inputValue);
      setInput(query);
      setProductNameState(query);
      h.replace('/search');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  useEffect(() => {
    if ((productName !== productNameState) && (productName !== undefined) && isFirst) {
      setIsFirst(false);
      setProductNameState(productName);
    }
  }, [productName, isFirst, productNameState]);

  const clearFilters = () => {
    const inputValue = { value: '' };
    setClearInput(inputValue);
    setInput('');
    dispatch(actions.clearFilters);
    setStateState(null);
    // setCompany({ label: companyName, value: companyName });
    selectCompany({
      id: companyId, name: companyName, label: companyName, value: companyName, ...other,
    })(dispatch);
    setMethodState(null);
    setProductNameState('');
  };

  useEffect(() => {
    if (!isFirst) {
      dispatch(actions.byName(input));
      if (url?.includes('search')) h.replace('/search');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [input]);

  const handleInputChange = (e) => {
    if (time.current) clearTimeout(time.current);
    const inputText = e.target.value;
    time.current = setTimeout(() => {
      const clearSpaces = inputText && inputText.length > 0 && inputText.trim();
      if (clearSpaces) {
        setInput(inputText);
        clearTimeout(time.current);
      } else {
        setInput('');
      }
    }, 2000);
  };

  const handleOnKeyDown = (event) => {
    if (event.key === 'Enter') {
      const inputText = event.target.value;
      const clearSpaces = inputText && inputText.length > 0 && inputText.trim();
      if (clearSpaces) setInput(inputText);
      else setInput('');
    }
  };

  const typeHandler = e => dispatch(actions.setFilter({ calculationType: e.target.value }));
  const statusHandler = ({ value }) => dispatch(actions.setFilter({ state: value }));
  const methodHandler = ({ value }) => dispatch(actions.setFilter({ method: value }));

  /* const handleCompany = (companyTemp) => {
    selectCompany(companyTemp)(dispatch);
    setCompany(companyTemp);
  }; */

  const onFocus = () => {
    setProductNameState(null);
    setClearInput(null);
  };

  const filtersCondition = Object.keys(filters)?.filter(f => !f?.includes('order['))?.length;

  return (
    <AsideOverview
      clearFilter={clearFilters}
      clearingFilterCondition={filtersCondition || (companyId !== selectedCompany?.id) || null}
      hasFilterIcon={hasFilterIcon}
      results={totalItems}>

      <WrapperInput>
        <Label>{t('code name')}</Label>

        <Input
          {...clearInput}
          id="filter-productName"
          onChange={handleInputChange}
          onFocus={onFocus}
          onKeyDown={handleOnKeyDown}
          placeholder={t('filter by code/name')}
          /* type="search"
          value={input || productNameState} */
        />
      </WrapperInput>

      <WrapperInput>
        <Label>{t('Status')}</Label>

        <Select
          onChange={statusHandler}
          options={statusOptions}
          placeholder={`${t('Select')}...`}
          value={stateState}
        />
      </WrapperInput>

      <WrapperInput>
        <Label>{t('methods')}</Label>

        <Select
          name="method"
          onChange={methodHandler}
          options={result?.methods}
          placeholder={t('all methods')}
          value={methodState}
        />
      </WrapperInput>

      {/* {superAdmin && (
      <WrapperInput>
        <Label>{t('Company')}</Label>

        <Select
          name="company"
          onChange={handleCompany}
          options={companies}
          placeholder={t('all companies')}
          value={company}
        />
      </WrapperInput>
      )} */}

      <RadioContainer>
        <Radio
          checked={!calculationType || calculationType === 'all'}
          id="filter-all"
          onChange={typeHandler}
          placeholder={t('All')}
          style={{ marginBottom: '.63rem' }}
          value="all"
        />

        <Radio
          checked={calculationType === 'semi_finished_product'}
          id="filter-semi"
          onChange={typeHandler}
          placeholder={t('Semi Finished Products')}
          style={{ marginBottom: '.63rem' }}
          value="semi_finished_product"
        />

        <Radio
          checked={calculationType === 'product'}
          id="filter-product"
          onChange={typeHandler}
          placeholder={t('product')}
          value="product"
        />
      </RadioContainer>
    </AsideOverview>
  );
};

export default memo(CalculationsFilter);
