import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { withLabels } from 'components/Field';

import { InputStyle } from './TextArea.styled';

const inputTypes = ['textarea'];

const Input = ({
  ref,
  innerRef = ref,
  translation,
  input,
  ...props
}) => (
  <InputStyle
    {...props}
    {...input}
    ref={innerRef || ref}
    type="textarea"
  />
);

Input.defaultProps = {
  type: 'textarea',
  required: false,
  disabled: false,
  readOnly: false,
  placeholder: undefined,
  value: undefined,
  autoComplete: 'on',
};

Input.propTypes = {
  type: PropTypes.oneOf(inputTypes),
  required: PropTypes.bool,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  id: PropTypes.string.isRequired,
  autoComplete: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export const FormTextArea = withLabels(Input);

export default memo(Input);
