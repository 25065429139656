import _ from 'lodash';
import * as types from './calculations.types';

const initialFilters = Object.freeze({
  'order[productName]': 'asc',
});

const initialState = Object.freeze({
  list: [] /* Array of calculations */,
  orderBy: {}, /* @deprecated (using filters) */
  selection: [] /* Array of calculations ID's */,
  loading: true /* Bool */,
  folders: [] /* Object of folders by ID */,
  filters: initialFilters /* Object of filters */,
});

const calculations = (state = initialState, action) => {
  switch (action.type) {
    case types.LIST:
      return {
        ...state,
        list: action.payload?.list || [],
        totalItems: action.payload?.totalItems,
        nextPage: action.payload?.nextPage,
      };

    case types.NEXT_PAGE_LIST:
      return {
        ...state,
        list: state.list.concat(action.payload?.list),
        totalItems: action.payload?.totalItems,
        nextPage: action.payload?.nextPage,
      };

    case types.PRUNE_LIST:
      return {
        ...state,
        list: _.differenceWith(
          state.list, action.payload,
          (item, deletionId) => item['@id'] === deletionId,
        ),
      };

    case types.SELECTION: {
      const selection = action.payload.value
        ? _.concat(state.selection, action.payload.id)
        : _.without(state.selection, action.payload.id);

      return { ...state, selection };
    }

    case types.CLEAR_SELECTION:
      return {
        ...state,
        selection: initialState.selection,
      };

    case types.FULL_SELECTION: {
      const selection = action?.payload?.list || _.map(state.list, ({ id }) => id);
      return { ...state, selection };
    }

    case types.SELECT_ALL_CALCULATIONS: {
      return { ...state, selection: _.map(state.list, ({ '@id': id }) => id) };
    }

    case types.ORDER:
      return {
        ...state,
        orderBy: action.payload,
      };

    case types.ORDER_BY_NAME:
      return {
        ...state,
        filters: { ...state.filters, 'order[productName]': action.payload },
      };

    case types.ORDER_BY_CODE:
      return {
        ...state,
        filters: { ...state.filters, 'order[code]': action.payload },
      };

    case types.SORT_LAST_MODIFIED:
      return {
        ...state,
        filters: { ...state.filters, 'order[lastModifiedAt]': action.payload },
      };

    case types.SORT_STATUS:
      return {
        ...state,
        filters: { ...state.filters, 'order[state]': action.payload },
      };

    case types.FIND_NAME:
      return {
        ...state,
        filters: {
          ...state.filters,
          search: action.payload || undefined,
        },
      };

    case types.FOLDERS:
      return {
        ...state,
        folders: action.payload,
      };

    case types.DELETE_FOLDER: {
      const folders = _.clone(state.folders);
      const parentFolders = _.remove(state.parentFolders, action.id);
      delete folders[action.id];
      return { ...state, folders, parentFolders };
    }

    case types.UPDATE_FILTER:
      return {
        ...state,
        filters: { ...state.filters, ...action.payload },
      };

    case types.CLEAR_FILTERS:
      return {
        ...state,
        filters: initialFilters,
      };

    case types.LOADING:
      return {
        ...state,
        loading: action.payload,
      };

    case types.RELEASE_MEMORY: return initialState;

    default: return state;
  }
};

export default calculations;
