import _ from 'lodash';
import React, { useEffect, createContext } from 'react';
import { useAsyncCallback } from 'react-async-hook';

import { locations } from 'services';

export const Suppliers = createContext({
  loading: true,
  result: undefined,
  get suppliersById() { return _.keyBy(this.result, 'id'); },
});

export const useSuppliers = () => {
  const suppliers = useAsyncCallback(() => locations.getSuppliers({ itemsPerPage: 999999999 }));
  const { execute } = suppliers;

  useEffect(() => {
    execute();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    ...suppliers,
    get suppliersById() { return _.keyBy(this.result?.data, 'id'); },
  };
};

export default ({ children }) => {
  const supplier = useSuppliers();

  return (
    <Suppliers.Provider value={supplier}>
      {children}
    </Suppliers.Provider>
  );
};
