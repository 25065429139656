import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

import { MAX_WIDTH } from 'config/style';

export const StyledContainer = styled.div`
  max-width: ${MAX_WIDTH};
  margin: 0 auto;
  padding: 1.6rem 0 5.25rem;
  min-height: 89vh;
`;

export const StyledTitle = styled.h1`
  font-size: 1.625rem;
  font-weight: 500;
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StyledSection = styled.div`
  height: 26.5rem;
  width: ${MAX_WIDTH};
`;

export const UpdateAndNotificationSection = styled.div`
  margin-top: 3.6rem;
  display: flex;
  width: ${MAX_WIDTH};
`;

export const StyledColumn = styled.div`
  width: 50%;
  &:first-child {
    margin-right: 2.5rem;
  }
`;

export const UpdatesContainer = styled.div`
  font-size: 14px;
  font-weight: normal;
  border-bottom: 1px solid #ebebeb;
`;

export const UpdateRow = styled(Link)`
  padding: 15px 20px;
  display: flex;
  justify-content: space-between;
  border: 1px solid ${({ $alert }) => ($alert ? '#FFCE01' : '#ebebeb')};
  border-bottom: ${({ $alert }) => ($alert ? '1px solid #FFCE01' : 'none')};
  cursor: pointer;
  ${({ $alert }) => $alert && css`
    background: #FFF6D0;
    font-weight: 600
  `};

  p:first-child {
    margin-bottom: 5px;
  }

  p:nth-child(2) {
    font-size: 12px;
  }

  img {
    display: ${({ $alert }) => ($alert ? 'block' : 'none')};
  }
`;

export const StyledTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 1.5rem;
  margin-left: 20px;
  margin-right: 20px;
`;

export const NotificationsContainer = styled.div`
  border-bottom: 1px solid #ebebeb;
`;

export const FooterContainer = styled.div`
  margin-bottom: 5rem;
  border-top: 1px solid #E4E4E4;
  padding-top: 1.625rem;
`;
