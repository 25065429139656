import React, { memo } from 'react';

import iconArrow from 'assets/ArrowSelection.svg';

import { AsideLink, Arrow } from './VerticalMenu.styled';

const MenuItem = ({ children, ...props }) => (
  <AsideLink {...props}>
    <Arrow alt="selected item arrow" className="arrow" src={iconArrow} />
    {children}
  </AsideLink>
);

export default memo(MenuItem);
