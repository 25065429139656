import _ from 'lodash';
import React, {
  memo, useState, useEffect, useRef,
} from 'react';
import { useDispatch } from 'react-redux';
import { setSearchValue } from 'state/search/search.actions';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'utils';

import { Wrapper, Input } from './NavSearch.styled';

const NavSearch = () => {
  const dispatch = useDispatch();
  const urlQuery = useQuery().get('q');
  const [query, setQuery] = useState('');
  const match = useRouteMatch('/search');
  const restoreQuery = useRef(true);
  const h = useHistory();
  const { t } = useTranslation();
  const placeholder = t('Search all calculations');
  const isExact = _.get(match, 'isExact');

  const handleInput = ({ target }) => {
    restoreQuery.current = false;
    setQuery(target.value);
  };

  const didSubmit = e => {
    e.preventDefault();
    dispatch(setSearchValue(query));
    h.push(`/search?q=${query}`);
  };

  // * Restore query from URL
  useEffect(() => {
    if (isExact && restoreQuery.current && urlQuery) {
      restoreQuery.current = false;
      setQuery(urlQuery);
    }
  }, [isExact, urlQuery]);

  // * Cleanup field when leave /search route
  useEffect(() => () => {
    if (isExact || !urlQuery) {
      restoreQuery.current = true;
      setQuery('');
    }
  }, [isExact, urlQuery]);

  return (
    <Wrapper onSubmit={didSubmit}>
      <Input
        onChange={handleInput}
        placeholder={placeholder}
        type="search"
        value={query}
      />
    </Wrapper>
  );
};

export default memo(NavSearch);
