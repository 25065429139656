import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  Field, Form, formValues, reduxForm,
} from 'redux-form';
import { alertToast } from 'config/toast';

import Button from 'components/Button/Button';
import GField from 'components/Field';
import { FormSelect } from 'components/Select/Select';
import { FormInput } from 'components/Input/Input';
import { FormRadio } from 'components/Radio/Radio';
import { useHistory, useParams } from 'react-router-dom';
import {
  FormContainer,
  GroupFields,
  GroupFieldsDivider,
  GroupFieldsHeader,
  RadioWrapper,
} from 'routes/GlobalStyle.styled';
import MainModal from 'components/Modal';
import {
  availableAmounts,
  predefinedConstructionWasteOpt,
  scalingOptions,
  wasteScenarioInputMethodsOpt,
} from './Editor.helpers';
import * as v from './validation';
import { FormSimpleContentInfo } from '../../../../components/ContentInfo/ContentInfo';

const BasicsForm = props => {
  const { t } = useTranslation();
  const {
    handleSubmit, pristine, submitting, form: calcBasics, isSemi,
    wasteScenarioInputMethod, predefinedConstructionWaste, scalingType,
    amountScalingDimensions, /* productType, */ productionWasteInputMethod,
    // methods, // hooks
    wasteRegions, // hooks
    saveForm,
    template,
  } = props;
  const syncErr = useSelector(({ form }) => form[calcBasics].syncErrors);
  const { isHidden } = useSelector(state => state.sidebar);
  const companyName = useSelector(state => state.user.selectedMembership.company.name);
  const [hasEditedForm, setHasEditedForm] = useState(false);
  const [radio, setRadio] = useState(wasteScenarioInputMethod);
  useEffect(() => { setRadio(wasteScenarioInputMethod); }, [wasteScenarioInputMethod]);
  const handleRadio = ({ target }) => setRadio(target.value);
  const history = useHistory();
  const { id: calcId } = useParams();

  return (
    <>
      <FormContainer isHidden={isHidden} style={{ paddingBottom: '6rem', margin: '0' }}>
        <Form
          onSubmit={ev => {
            ev.preventDefault();
            setHasEditedForm(true);
            if (syncErr) {
              alertToast(t('form has errors'), 'alert');
            }/*  else {
              history.goBack();
            } */

            handleSubmit((params) => saveForm({ ...params, wasteScenarioInputMethod: radio }))();
          }}
          style={{ width: '100%' }}>
          <GroupFields>
            <GroupFieldsHeader>{t('production waste and end of life')}</GroupFieldsHeader>
            {/* Transports */}
            {/* {!isSemi && (
              <fieldset>
                {productType?.id === 3 && (
                  <>
                    <Field
                      component={FormSelect}
                      name="transportProfileToConstructionSite"
                      props={{
                        options: methods.profiles,
                        getOptionLabel: ({ name }) => name,
                        getOptionValue: ({ id }) => id,
                        isSearchable: false,
                      }}
                      validate={[v.required, v.transportProfileToConstructionSite]}
                    />

                    <Field
                      component={FormInput}
                      name="distanceToConstructionSite"
                      props={{ type: 'number' }}
                      validate={[v.required, v.distanceToConstructionSite]}
                    />
                  </>
                )}
              </fieldset>
            )} */}

            <GField name="wasteScenarioInputMethod">
              <RadioWrapper>
                <FormRadio
                  checked={radio === '2'}
                  id="byProduct"
                  onChange={handleRadio}
                  translation={{ placeholder: t('wasteScenarioInputMethod.byProduct') }}
                  value="2"
                />
                <FormRadio
                  checked={radio === '1'}
                  id="byRawMaterial"
                  onChange={handleRadio}
                  translation={{ placeholder: t('wasteScenarioInputMethod.byRawMaterial') }}
                  value="1"
                />
              </RadioWrapper>
            </GField>

            {radio === '2' && (
              <>
                <Field
                  component={FormSelect}
                  name="wasteScenarioProductionWasteForm"
                  props={{
                    options: wasteRegions.scenarios,
                    getOptionLabel: l => (l.public ? l.name : `${l.name} (${companyName})`),
                    getOptionValue: ({ id }) => id,
                  }}
                  validate={[v.required, v.wasteScenarioProductionWaste]}
                />

                <Field
                  component={FormSelect}
                  name="wasteScenarioForm"
                  props={{
                    options: wasteRegions.scenarios,
                    getOptionLabel: l => (l.public ? l.name : `${l.name} (${companyName})`),
                    getOptionValue: ({ id }) => id,
                  }}
                  validate={[v.required, v.wasteScenario]}
                />
              </>
            )}

            <Field
              component={FormSelect}
              name="productionWasteInputMethod"
              props={{
                options: wasteScenarioInputMethodsOpt,
                isSearchable: false,
              }}
              validate={v.required}
            />

            {productionWasteInputMethod?.value === 'overall_percentage' && (
              <Field
                component={FormInput}
                name="productionWastePercentageForm"
                props={{
                  min: 0,
                  max: 100,
                  type: 'decimal',
                }}
                validate={[v.required, v.productionWastePercentage]}
              />
            )}
          </GroupFields>
          {!isSemi && (
            <>
              <GroupFieldsDivider />
              <GroupFields>
                <GroupFieldsHeader>{t('construction waste')}</GroupFieldsHeader>
                {template ? (
                  <FormSimpleContentInfo
                    content={predefinedConstructionWasteOpt
                      .find(({ value }) => value === (template.predefinedConstructionWaste
                        || 0))?.label}
                    name="predefinedConstructionWaste"
                  />
                ) : (
                  <Field
                    component={FormSelect}
                    name="predefinedConstructionWaste"
                    props={{
                      options: predefinedConstructionWasteOpt,
                    }}
                    validate={[v.required, v.predefinedConstructionWaste]}
                  />
                )}

                {!template && predefinedConstructionWaste?.value === 0 && (
                  <Field
                    component={FormInput}
                    name="constructionWaste"
                    props={{
                      type: 'decimal',
                      min: 0,
                      max: 100,
                    }}
                    validate={[v.required, v.constructionWaste]}
                  />
                )}
                {template && !template.predefinedConstructionWaste
                && (
                <FormSimpleContentInfo
                  content={template.constructionWaste}
                  name="constructionWaste"
                />
                ) }
              </GroupFields>
            </>
          )}
          {!isSemi ? (
            <>
              <GroupFieldsDivider />
              <GroupFields>
                <GroupFieldsHeader>{t('scaling')}</GroupFieldsHeader>
                <Field
                  component={FormSelect}
                  name="scalingType"
                  props={{
                    options: scalingOptions,
                    isSearchable: false,
                  }}
                  validate={v.required}
                />

                {/* Scaling extras */}
                {(!scalingType || scalingType?.value !== 'linear') ? null : (
                  <fieldset>
                    <Field
                      component={FormSelect}
                      name="amountScalingDimensions"
                      props={{
                        options: availableAmounts,
                        getOptionLabel: o => o.value,
                        getOptionValue: o => o.value,
                      }}
                      validate={[v.required, v.amountScalingDimensions]}
                    />

                    <div>
                      <Field
                        component={FormInput}
                        count={{ count: 1 }}
                        name="descriptionDimension1"
                        props={{
                          translation: {
                            placeholder: t('descriptionDimension.placeholder', { count: 1 }),
                            hint: t('descriptionDimension.hint'),
                          },
                        }}
                        translationName="descriptionDimension"
                        validate={[v.genericRequired, v.descriptionDimension1]}
                      />

                      <Field
                        component={FormInput}
                        count={{ count: 1 }}
                        name="unitDimension1"
                        props={{
                          placeholder: t('unitDimension.placeholder', { count: 1 }),
                        }}
                        translationName="unitDimension"
                        validate={[v.genericRequired, v.unitDimension1]}
                      />

                      <Field
                        component={FormInput}
                        count={{ count: 1 }}
                        name="dimension1"
                        props={{
                          placeholder: t('dimension.placeholder', { count: 1 }),
                        }}
                        translationName="dimension"
                        validate={[v.genericRequired, v.dimension1]}
                      />

                      <Field
                        component={FormInput}
                        count={{ count: 1 }}
                        name="scalableDimension1"
                        props={{
                          placeholder: t('scalableDimension.placeholder', { count: 1 }),
                        }}
                        translationName="scalableDimension"
                        validate={[v.genericRequired, v.scalableDimension1]}
                      />

                    </div>

                    {amountScalingDimensions.value === 2 && (
                      <div>
                        <Field
                          component={FormInput}
                          count={{ count: 2 }}
                          name="descriptionDimension2"
                          props={{
                            placeholder: t('descriptionDimension.placeholder', { count: 2 }),
                          }}
                          translationName="descriptionDimension"
                          validate={[v.genericRequired, v.descriptionDimension2]}
                        />

                        <Field
                          component={FormInput}
                          count={{ count: 2 }}
                          name="unitDimension2"
                          props={{
                            placeholder: t('unitDimension.placeholder', { count: 2 }),
                          }}
                          translationName="unitDimension"
                          validate={[v.genericRequired, v.unitDimension2]}
                        />

                        <Field
                          component={FormInput}
                          count={{ count: 2 }}
                          name="dimension2"
                          props={{
                            placeholder: t('dimension.placeholder', { count: 2 }),
                          }}
                          translationName="dimension"
                          validate={[v.genericRequired, v.dimension2]}
                        />

                        <Field
                          component={FormInput}
                          count={{ count: 2 }}
                          name="scalableDimension2"
                          props={{
                            placeholder: t('scalableDimension.placeholder', { count: 2 }),
                          }}
                          translationName="scalableDimension"
                          validate={[v.genericRequired, v.scalableDimension2]}
                        />

                      </div>
                    )}
                  </fieldset>
                )}
              </GroupFields>
            </>
          ) : null}

          <div style={{ display: 'flex', margin: '1rem 2.25rem 0' }}>
            <Button
              // eslint-disable-next-line eqeqeq
              disabled={!pristine ? pristine : (wasteScenarioInputMethod == radio)}
              loading={submitting}
              style={{ marginRight: '2rem' }}
              styling="select"
              type="submit">
              {t('Save calculation')}
            </Button>
            <Button
              onClick={() => history.push(`/calculation/${calcId}/advanced/view`)}
              styling="underline">
              {t('cancel')}
            </Button>
          </div>
        </Form>
      </FormContainer>
      <MainModal
        content="prompt"
        exitModalContent={{
          navigateSideEffects: () => null,
        }}
        prompt
        promptCondition={!pristine && !hasEditedForm}
      />
    </>
  );
};

export default reduxForm({
  form: 'calculation-advanced',
  enableReinitialize: true,
})(formValues({
  wasteScenarioInputMethod: 'wasteScenarioInputMethod',
  productionWasteInputMethod: 'productionWasteInputMethod',
  predefinedConstructionWaste: 'predefinedConstructionWaste',
  amountScalingDimensions: 'amountScalingDimensions',
  scalingType: 'scalingType',
  productType: 'productType',
})(BasicsForm));
