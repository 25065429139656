import React from 'react';
import { useDispatch } from 'react-redux';
import { setBreadcrumb } from 'state/sidebar/sidebar.actions';
import {
  Route, Switch, useParams, useRouteMatch,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAsyncCallback } from 'react-async-hook';

import { calculations } from 'services';

import { MenuCollapse, MenuItem } from 'components/VerticalMenu';
import AsideLayout from 'components/AsideLayout/AsideLayout';
import { Placeholder } from 'components/Splash/Loading';
import { useResolveUnit } from 'components/FunctionalUnit/FunctionalUnit.hook';

import * as S from '../Detail.styled';
import ViewTemplateBasics from './ViewTemplateBasics';
import EditTemplate from './EditTemplate';
import UsedCalculations from '../Inputs/PredefinedInputSets/UsedCalculations';
import Reports from '../Reports/Reports';
import ExpertFunctions from '../ExpertFunctions/ExpertFunctions';
import { useTemplateContext, WithTemplateContext } from '../../../../hooks/template.hooks';

const TemplateDetail = () => {
  const { id } = useParams();
  const route = useRouteMatch();
  const { url } = route;
  const { path } = route;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    result, loading, execute,
  } = useTemplateContext();

  const useFetchCalculationsSorting = (params, updateList) => useAsyncCallback(() => calculations
    .getTemplateCalculations(id, params).then(updateList), []);

  if (!result && !loading) execute();

  const unit = useResolveUnit({ ...result, isTemplate: true });

  const fetchDataCalculations = (updateList, page) => {
    calculations
      .getNextPageByFolder(page)
      .then((values) => {
        if (updateList) updateList(values);
      });
  };

  React.useEffect(() => {
    if (result?.code) {
      dispatch(setBreadcrumb(result?.code));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result]);

  const CustomViewTemplateBasics = React.useCallback(props => (
    <ViewTemplateBasics
      {...props}
      loading={loading}
      template={result}
    />
  ), [result, loading]);

  const CustomEditTemplate = React.useCallback(props => (
    <EditTemplate
      {...props}
      loading={loading}
      template={result}
    />
  ), [result, loading]);

  const CustomReports = React.useCallback(props => (
    <Reports
      {...props}
      template={result}
      updateTemplate={execute}
    />
  // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [result, loading]);

  const CustomExpertFunctions = React.useCallback(props => (
    <ExpertFunctions
      {...props}
      template={result}
      updateTemplate={execute}
    />
  // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [result, loading]);

  const CustomUsedCalculations = React.useCallback(props => (
    <UsedCalculations
      {...props}
      fetchData={fetchDataCalculations}
      useFetchCalculations={useFetchCalculationsSorting}
    />
  // eslint-disable-next-line react-hooks/exhaustive-deps
  ), []);

  return (
    <Placeholder loaders={[]}>
      <AsideLayout
        LeftPane={(
          <aside>
            <S.ProductWrapper>
              <S.ProductName>{result?.name}</S.ProductName>
              <p>{`${t('Code.label')}: ${result?.code}`}</p>
              <p>{`${t('standardUnit.name')}: ${unit}`}</p>
            </S.ProductWrapper>

            <S.LinksWrapper>
              <MenuItem
                style={{ marginTop: '1rem' }}
                to={`/calculations/templates/${id}/template-basics`}>
                {t('template basics')}
              </MenuItem>

              <MenuCollapse
                label={t('information for report')}
                to={`${url}/information-for-report`}>
                <MenuItem to={`${url}/information-for-report/general`}>
                  {t('general')}
                </MenuItem>

                <MenuItem to={`${url}/information-for-report/substantiations`}>
                  {t('substantiations')}
                </MenuItem>
              </MenuCollapse>

              <MenuCollapse
                label={t('expert functions')}
                to={`${url}/expert-functions`}>
                <MenuItem
                  to={`${url}/expert-functions/default-substantiations`}>
                  {t('default-substantiations')}
                </MenuItem>

                <MenuItem
                  to={
                    `${url}/expert-functions/input-output-substantiations`
                  }>
                  {t('input-output-substantiations')}
                </MenuItem>

                <MenuItem to={`${url}/expert-functions/interpretation`}>
                  {t('interpretation')}
                </MenuItem>
              </MenuCollapse>

              <MenuItem to={`/calculations/templates/${id}/used-calculations`}>
                {`${t('used calculations')} (${result?.calculations?.length})`}
              </MenuItem>
            </S.LinksWrapper>
          </aside>
      )}>
        <Placeholder loaders={[loading, !result]}>
          <Switch>
            <Route
              component={CustomViewTemplateBasics}
              exact
              path={`${path}/template-basics`}
            />
            <Route
              component={CustomEditTemplate}
              exact
              path={`${path}/template-basics/edit`}
            />
            <Route
              component={CustomReports}
              path={`${path}/information-for-report`}
            />
            <Route
              component={CustomExpertFunctions}
              path={`${path}/expert-functions`}
            />
            <Route
              component={CustomUsedCalculations}
              exact
              path={`${path}/used-calculations`}
            />
          </Switch>
        </Placeholder>
      </AsideLayout>
    </Placeholder>
  );
};

export default () => <WithTemplateContext><TemplateDetail /></WithTemplateContext>;
