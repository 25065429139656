import _ from 'lodash';
import i18next from 'i18next';
import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useAsync } from 'react-async-hook';
import InfiniteScroll from 'react-infinite-scroll-component';
import styled from 'styled-components';

import { profiles as profilesService } from 'services';

import * as S from 'routes/GlobalStyle.styled';

import ListItem from 'components/ListItem/ListItem';
import * as AsideOverview from 'components/AsideOverview/AsideOverview.styled';
import Input from 'components/Input/Input';
import Select from 'components/Select/Select';
import { Placeholder, Loading } from 'components/Splash/Loading';
import TopBar from 'components/TopBar/TopBar';
import Name from 'components/TopBar/Name';
import ID from 'components/TopBar/ID';
import LastChangedStatus from 'components/TopBar/LastChangedStatus';

const InputContainer = styled.div`
  flex: 1 0 auto;

  :not(:last-child) {
    margin-right: 40px;
  }
`;

const hackyStyle = {
  header: {
    display: 'flex', flexFlow: 'wrap', width: '80vw', marginBottom: '30px',
  },
};

const allSections = {
  id: 0,
  get name() { return i18next.t('all'); },
  get label() { return i18next.t('all'); },
  value: undefined,
};

const ProfilesOverviewModal = ({ onSelect }) => {
  const { t } = useTranslation();
  const company = useSelector(state => state.company);
  const { pathname } = useLocation();
  const pathArray = pathname.split('/');
  const profileType = pathArray[2]; // if it's general or company
  const [selected, setSelected] = useState(profileType);
  const [input, setInput] = useState('');
  const [section, setSection] = useState(null);
  const [sections, setSections] = useState(null);
  const [subSection, setSubSection] = useState(null);
  const [profile, setProfile] = useState({ label: t('all profiles'), value: null });
  const [sorting, setSorting] = useState('asc');
  const [sortingID, setSortingID] = useState(null);
  const [lastChange, setLastChange] = useState(null);
  const [page, setPage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [totalNumberOfRecords, setTotalNumberOfRecords] = useState(null);
  const [profiles, setProfiles] = useState([]);
  const [subSections, setSubSections] = useState([]);
  const [clearInput, setClearInput] = useState(null);
  const time = useRef(0);
  const result = useAsync(
    () => profilesService
      .allSections({
        itemsPerPage: 9999, parent: 'root',
      })
      .then(({ data }) => setSections([allSections, ...data]?.filter(s => s?.parent === null))),
    [],
  );

  if (profileType !== selected) setSelected(profileType);

  const clearFilter = () => {
    const inputValue = { value: '' };
    setClearInput(inputValue);
    setInput('');
    setSection(null);
    setSubSection(null);
    setProfile({ label: t('all profiles'), value: null });
  };

  const itemProps = profileValue => {
    if (onSelect) {
      return { onClick: e => onSelect(profileValue, e), as: 'button' };
    }

    return {
      to: {
        pathname: `/environmental-profiles/${profileValue.id}/basic-information`,
        state: {
          routeName: profileValue.name,
        },
      },
    };
  };

  const getProfiles = args => {
    const query = { owner: 'none', show: false };

    setTotalNumberOfRecords(null);

    if (_.isBoolean(args.profile?.value)) {
      query.public = args.profile?.value;
      if (!query.public && args.dataOwner) query.dataOwner = args.dataOwner;
    }
    if (args.section) query.sections = args.section;
    if (args.name) query.search = args.name;
    if (args.sortingID) query['order[code]'] = args.sortingID;
    if (args.lastChange) query['order[lastModifiedAt]'] = args.lastChange;
    if (args.stage) query.stage = args.stage;
    if (args.sorting) query['order[name]'] = args.sorting;

    setLoading(true);
    return profilesService
      .all(query)
      .then(response => {
        setProfiles(response.profiles);
        setPage(response.nextPage);
        setTotalNumberOfRecords(response.totalItems);
        return response;
      }).finally(() => setLoading(false));
  };

  const handleSection = (value) => {
    setSubSection(null);
    setSection(value);
    return profilesService
      .allSections({
        itemsPerPage: 9999, parent: value?.id,
      })
      .then(r => {
        setSubSections(r.sections);
        return r;
      });
  };

  useEffect(() => {
    const query = {
      selected,
      sorting,
      sortingID,
      lastChange,
      profile,
      name: input,
      dataOwner: company?.id,
    };

    const hasSection = (subSection && subSection.id) || (section && section.id);
    if (hasSection) query.section = hasSection;

    getProfiles(query);
  }, [selected, sorting, sortingID, lastChange, subSection, section, profile, input, company]);

  const handleInputChange = (e) => {
    if (time.current) clearTimeout(time.current);
    const inputText = e.target.value;
    time.current = setTimeout(() => {
      const clearSpaces = inputText && inputText.length > 0 && inputText.trim();
      if (clearSpaces && (input !== inputText)) {
        setInput(inputText);
        clearTimeout(time.current);
      }
    }, 2000);
  };

  const handleOnKeyDown = (event) => {
    if (event.key === 'Enter') {
      const inputText = event.target.value;
      const clearSpaces = inputText && inputText.length > 0 && inputText.trim();
      if (clearSpaces) {
        setInput(inputText);
      } else setInput('');
    }
  };

  const onFocus = () => setClearInput(null);

  const handleDropdownChangeProfile = (profileValue) => {
    setProfile(profileValue);
  };

  const fetchData = () => {
    if (!page) return undefined;
    return profilesService
      .getNextPageProfiles(page)
      .then((values) => {
        setPage(values.nextPage);
        setTotalNumberOfRecords(values.totalItems);
        setProfiles(profiles.concat(values.profiles));
        return values;
      });
  };

  const loaders = [result, loading];

  const profilesOptions = [
    {
      value: null,
      label: t('all profiles'),
    },
    {
      value: true,
      label: t('global profiles'),
    },
    {
      value: false,
      label: t('company profiles'),
    },
  ];

  return (
    <div style={{ maxWidth: '80vw' }}>
      <header style={hackyStyle.header}>
        <InputContainer>
          <S.Label>{t('Name / ID')}</S.Label>
          <Input
            {...clearInput}
            id="name"
            name="name"
            onChange={handleInputChange}
            onFocus={onFocus}
            onKeyDown={handleOnKeyDown}
            placeholder={t('filter by name or id')}
          />
        </InputContainer>

        <InputContainer>
          <S.Label>{t('Category')}</S.Label>
          <Select
            isDisabled={!sections}
            isSearchable
            name="section"
            onChange={handleSection}
            options={sections}
            placeholder={t('category')}
            value={section}
          />
        </InputContainer>

        <InputContainer>
          <S.Label>{t('Sub category')}</S.Label>
          <Select
            isDisabled={!subSections}
            isSearchable
            name="subSection"
            onChange={setSubSection}
            options={[allSections, ...subSections]}
            placeholder={t('sub category')}
            value={subSection}
          />
        </InputContainer>

        <InputContainer>
          <S.Label>{t('profiles')}</S.Label>
          <Select
            name="profile"
            onChange={handleDropdownChangeProfile}
            options={profilesOptions}
            placeholder={t('all profiles')}
            value={profile}
          />
        </InputContainer>
      </header>

      <header>
        {(input || section) && (totalNumberOfRecords !== null) && (
        <AsideOverview.Results>
          <AsideOverview.Results>
            {t('results found', { count: profiles.length })}
          </AsideOverview.Results>

          <AsideOverview.ClearFilter
            onClick={() => clearFilter()}>
            {t('clear all filters')}
          </AsideOverview.ClearFilter>
        </AsideOverview.Results>
        )}
      </header>

      <div
        style={{
          border: '1px solid #E4E4E4',
          boxSizing: 'border-box',
        }}>
        <TopBar>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={{ marginRight: '5rem' }}>
              <ID
                inverted
                onOrderBy={() => {
                  if (lastChange) setLastChange(null);
                  if (!sortingID) setSortingID('asc');
                  else if (sortingID === 'asc') setSortingID('desc');
                  else setSortingID(null);
                }}
                order={sortingID}
              />
            </div>
            <Name
              onOrderBy={order => {
                setSorting(order);
              }}
              order={sorting}
            />
          </div>
          <LastChangedStatus
            inverted
            onOrderBy={() => {
              if (sortingID) setSortingID(null);
              if (!lastChange) setLastChange('asc');
              else if (lastChange === 'asc') setLastChange('desc');
              else setLastChange(null);
            }}
            order={lastChange}
          />
        </TopBar>

        <Placeholder loaders={loaders}>
          {profiles.length ? (
            <S.OverviewRows id="overviewRowsId" style={{ height: 'calc(100vh - 348.85px)' }}>
              <InfiniteScroll
                dataLength={profiles.length}
                hasMore={page}
                loader={Loading}
                next={fetchData}
                scrollableTarget="overviewRowsId"
                scrollThreshold={0.4}>
                {_.map(profiles, p => {
                  const { unit, code } = p;
                  const subtitles = _.compact([
                    `${t('process used')}: ${p?.processUsed}`,
                    `${t('unit')}: ${unit}`,
                  ]);
                  return (
                    <ListItem
                      key={p.id}
                      description={subtitles}
                      id={code}
                      lastChanged={p?.lastModifiedAt}
                      name={p?.name}
                      {...itemProps(p)}
                    />
                  );
                })}
              </InfiniteScroll>
            </S.OverviewRows>
          ) : 'No items to show'}
        </Placeholder>
      </div>
    </div>
  );
};

export default ProfilesOverviewModal;
