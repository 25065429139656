import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { sortLastModified, sortStatus } from 'state/calculations/calculations.actions';

import Arrow from 'assets/sortArrow.svg';
import * as S from './TopBar.styled';

const LastChangedStatus = ({
  hasStatus, inverted, order, orderStatus, onOrderByDate, onOrderByStatus,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const filters = useSelector(({ calculations }) => calculations.filters);
  const hasLastModFilter = filters['order[lastModifiedAt]'];
  const isLastModAsc = hasLastModFilter === 'asc';
  const hasStatusFilter = filters['order[state]'];
  const isStatusAsc = hasStatusFilter === 'asc';

  const [isDesc, setIsDesc] = React.useState(false);
  const [status, setStatus] = React.useState(false);

  const lastModified = (direction) => () => {
    dispatch(sortStatus(null));
    dispatch(sortLastModified(direction));
    onOrderByDate();
    if (order === 'desc') {
      dispatch(sortLastModified(null));
      setIsDesc(false);
    } else if (order === null) setIsDesc(true);
    else setIsDesc(!isDesc);
  };

  const sortByStatus = (direction) => () => {
    dispatch(sortLastModified(null));
    dispatch(sortStatus(direction));
    onOrderByStatus();
    if (orderStatus === 'desc') {
      dispatch(sortStatus(null));
      setStatus(false);
    } else if (orderStatus === null) setStatus(true);
    else setStatus(!status);
  };

  return (
    <S.Section hasStatus={hasStatus}>
      <S.Changed onClick={lastModified(isLastModAsc ? 'desc' : 'asc')}>
        <S.Label>{t('last changed')}</S.Label>
        {order && <S.Arrow isDesc={inverted ? !isDesc : isDesc} src={Arrow} />}
      </S.Changed>

      <S.Status onClick={sortByStatus(isStatusAsc ? 'desc' : 'asc')}>
        <S.Label>{t('Status')}</S.Label>
        {orderStatus && <S.Arrow isDesc={inverted ? !status : status} src={Arrow} />}
      </S.Status>
    </S.Section>
  );
};

export default React.memo(LastChangedStatus);
