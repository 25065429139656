import styled, { css } from 'styled-components';
import AlertIcon from 'assets/alert.svg';
import { AsideWidth, NAV_HEIGHT } from 'config/style';

export const Container = styled.div`
  display: flex;
  height: 100%;
`;

export const StyledSection = styled.section`
  width: ${(props) => (props.rightPane ? AsideWidth : `calc(100% - ${AsideWidth})`)};
  ${(props) => props.borderLeft && css`border-left: 1px solid #e4e4e4;`};
`;

export const TitleSection = styled.div`
  padding: .625rem 1.6rem;
  height: ${NAV_HEIGHT};
  border-bottom: 1px solid #e4e4e4;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 500;
  background: #F4F8FA;

  h1 {
    font-size: 1rem;
    font-weight: 500;
  }
`;

export const ScrollWrapper = styled.div`
  height: calc(100vh - 3 * ${NAV_HEIGHT});
  overflow: auto;
`;

export const Wrapper = styled.ul``;

export const Row = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: white;
  border-bottom: 1px solid #e4e4e4;
  font-size: .875rem;
  padding: 1.5rem 1.25rem 1.5rem 3.13rem;
  ${(props) => props.alert && css`background: url(${AlertIcon}) no-repeat;`};
  ${(props) => props.alert && css`background-position: 3% 50%`};
  ${(props) => props.alert && css`background-color: #FFF6D0`};
  ${(props) => props.alert && css`border: 1px solid #FFCE01`};
`;

export const Items = styled.p`font-size: .63rem;`;

export const WrapperDiv = styled.div`
  display: flex;
  align-items: center;

  img {
    cursor: pointer;
    padding: .5rem;
  }
`;

export const Padding = styled.div`
  padding: 1rem 2rem 0;
  background: white;
  border-bottom: 1px solid #e4e4e4;
`;

export const ActionsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 34rem;
`;
