import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { PredefinedInputSetRow } from '../Rows/PredefinedInputSetRow';
import { InputView } from '../../../../../../components/Field';
import { Option } from '../../../../../../components/DropDownContent/Calculations/Calculations';
import dots from '../../../../../../assets/dots.svg';
import DropDown from '../../../../../../components/DropDown/DropDown';
import editIcon from '../../../../../../assets/edit.svg';
import { useCanEdit } from '../../../../../../hooks/calculation.hooks';
import deleteIcon from '../../../../../../assets/delete.svg';
import { Prompt } from '../../../../../../components/Modal';
import { inputs as inputService } from '../../../../../../services';
import { useInputsContext } from '../../../../../../hooks/input.hooks';
import Button from '../../../../../../components/Button/Button';
// import groupIcon from '../../../../../../assets/group.svg';

const useActions = (inputId, updateContext) => {
  const canEdit = useCanEdit();
  const history = useHistory();
  const { t } = useTranslation();
  const { refresh } = useInputsContext();
  const [copyLoader] = useState();

  return useMemo(() => ({
    copyLoader,
    canEdit,
    actions: {
      // copy: {
      //  label: t('copy'),
      //  img: groupIcon,
      //  disable: !canEdit,
      //  action: () => {
      //    setCopyLoader(true);
      //    calculationService.copyInput(inputId).finally(() => {
      //      setCopyLoader(false);
      //      refresh();
      //      if (updateContext) updateContext();
      //    });
      //  },
      // },
      edit: {
        label: t('Edit'),
        img: editIcon,
        disable: !canEdit,
        action: () => canEdit && history.push(
          `edit-input-predefined-input-set?inputId=${inputId}`,

        ),
      },
      delete: {
        label: t('Delete'),
        img: deleteIcon,
        disable: !canEdit,
        action: () => canEdit && Prompt.Default({
          title: t('confirmDeletion'),
          text: t('this action cannot be undone'),
          onConfirm: () => (inputService.deletePdisUsage(inputId).then(() => {
            refresh();
              // eslint-disable-next-line no-unused-expressions
              updateContext?.();
          })
          ),
        }),
      },
    },
  }), [canEdit, copyLoader, refresh, history, inputId, t, updateContext]);
};

export const PredefinedInputSetDetails = ({
  updateContext,
  blueBackground,
  editable,
  pdis: {
    id: inputId,
    code, name, amount, unit,
    method: { name: methodName } = {},
    wasteScenarioSet: { name: wasteScenarioSetName } = {},
  },
  hideArrow = false, forceExpanded = false,
}) => {
  const { t } = useTranslation();
  const { actions, canEdit } = useActions(inputId, updateContext);
  return (
    <PredefinedInputSetRow
      blueBackground={blueBackground}
      forceExpanded={forceExpanded}
      hideArrow={hideArrow}
      pdis={{
        code,
        name,
        amount,
        unit,
      }}
      renderActions={editable && canEdit && !forceExpanded && (() => (
        <DropDown
          content={(
            <div>
              <Option {...actions.edit} />
              <Option {...actions.delete} />
            </div>
        )}>
          <img alt="icon" src={dots} />
        </DropDown>
      ))}>
      <article style={{ display: 'flex' }}>
        <div style={{
          marginRight: '1rem',
          width: '50%',
        }}>
          <InputView label={t('Code.label')}>
            {code}
          </InputView>
          <InputView label={t('name.label')}>
            {name}
          </InputView>
          <InputView label={t('Unit.label')}>
            {unit}
          </InputView>
        </div>

        <div style={{ width: '50%' }}>
          <InputView label={t('amount.label')}>
            {amount}
          </InputView>
          <InputView label={t('methodLabel.label')}>
            {methodName}
          </InputView>
          <InputView label={t('WasteScenarioSet.label')}>
            {wasteScenarioSetName}
          </InputView>
        </div>
      </article>

      {canEdit && editable && !forceExpanded && (
      <div style={{
        display: 'flex',
        justifyContent: 'space-between',
        paddingBottom: '19px',
      }}>
        <div style={{
          display: 'flex',
        }}>
          <Button
            as={Link}
            style={{
              width: 'fit-content',
              alignSelf: 'flex-start',
              background: '#FDFEFF',
              border: '1px solid #DCDCDC',
              boxSizing: 'border-box',
              boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.1)',
              borderRadius: '2px',
              marginRight: '18px',

            }}
            styling="select"
            to={
              `edit-input-predefined-input-set?inputId=${inputId}`

            }>
            {t('edit')}
          </Button>
          {/*! isPdisItem && (
          <Button
            disabled={copyLoader}
            loading={copyLoader}
            onClick={actions.copy.action}
            style={{
              width: 'fit-content',
              alignSelf: 'flex-start',
              background: '#FDFEFF',
              border: '1px solid #DCDCDC',
              boxSizing: 'border-box',
              boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.1)',
              borderRadius: '2px',
            }}
            styling="select">
            {t('copy')}
          </Button>
          ) */}
        </div>
        <Button
          onClick={actions.delete.action}
          styling="underline">
          {t('delete this input')}
        </Button>
      </div>
      )}
    </PredefinedInputSetRow>
  );
};
