import React from 'react';

import { Container, Title, Spacer } from './TopbarActions.styled';

const TopbarActions = ({ children, title, ...props }) => (
  <Container {...props}>
    <Title>{title}</Title>

    <Spacer />

    {children}
  </Container>
);

export default TopbarActions;
