import styled, { keyframes, css } from 'styled-components';
import { YELLOW } from 'config/style';
import alertIcon from 'assets/alert.svg';

const AnimatedWidth = (width) => keyframes`
    from { width: 0; }
    to { width: ${Math.round(width * 100)}%; }
  `;

const TotalStyled = css`
  border: 1px solid rgba(98, 130, 147, 0.3);
  background: #f4f8fa;
  font-weight: bold;
  margin-top: -1px;
`;

export const Container = styled.div`
  background: white;
  padding-bottom: 3rem;
`;

export const TitleHeader = styled.h1`
  font-weight: 500;
  font-size: 1rem;
  background: #f4f8fa;
  border-bottom: 1px solid #e4e4e4;
  padding: 1rem 2.25rem;
`;

export const InfoContainer = styled.section`
  display: flex;
  margin-bottom: 2.18rem;
`;

export const MaterialInfo = styled.div`
  width: calc(100% - 21.25rem);
  margin-right: 2.5rem;
`;

export const Title = styled.h2`
  font-size: 1rem;
  font-weight: 500;
  padding: 1.6rem 0 1rem;
  border-bottom: 1px solid #ebebeb;
`;

export const Name = styled.p`z-index: 2;`;

export const Cost = styled.p`z-index: 2;`;

export const AditionInformation = styled.section`
  width: 21.25rem;
  margin-right: 1.9rem;
`;

export const Row = styled.div`
  border-right: 1px solid #ebebeb;
  border-left: 1px solid #ebebeb;
  border-bottom: 1px solid #ebebeb;
  display: flex;
  justify-content: space-between;
  font-size: .875rem;
  padding: 1.4rem 1.6rem;
  position: relative;
  background: transparent;
  ${({ totalRow }) => totalRow && TotalStyled};
`;

export const ProgressBackground = styled.div`
  height: 100%;
  background: ${YELLOW};
  position: absolute;
  top: 0;
  left: 0;
  animation: ${(props) => AnimatedWidth(props.width)} 1s forwards linear;
`;

export const TableContainer = styled.div`
  display: block;
  background: white;
  margin-right: 1.9rem;
`;

export const AlertContainer = styled.div`
  border: 1px solid #FFCE01;
  background: #FFF6D0;
  margin: 1.5rem 1.93rem .625rem 1.6rem;
  padding: .625rem 1.25rem;
  font-weight: 500;
  font-size: .875rem;
  background-image: url(${alertIcon});
  background-repeat: no-repeat;
  background-position: 98.5% 50%;
`;
