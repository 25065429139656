import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, Redirect } from 'react-router-dom';
import * as S from 'routes/GlobalStyle.styled';
import { useTranslation } from 'react-i18next';
import { FormFileUpload } from 'components/FileUpload/FileUpload';
import {
  reduxForm, Form, Field, formValues,
} from 'redux-form';
import Button from 'components/Button/Button';
import { FormSimpleContentInfo } from 'components/ContentInfo/ContentInfo';
import { imports, methods as methodsService, countries as countriesService } from 'services';
import { required } from 'routes/Calculations/Detail/Basics/validation';
import { FormSelect } from 'components/Select/Select';
import { useAsync } from 'react-async-hook';
import { Placeholder } from 'components/Splash/Loading';
import { canImportCalculations } from '../../../utils/featureAccess';

const NewImport = ({
  handleSubmit,
  pristine,
  invalid,
  submitting,
  file,
}) => {
  const { t } = useTranslation();
  const user = useSelector(state => state.user);
  const [fileValue, setFile] = useState(null);
  const h = useHistory();

  const { result, loading } = useAsync(() => methodsService.all(), []);
  const {
    result: countries,
    loading: countriesLoading,
  } = useAsync(() => countriesService.getWasteRegions(), []);

  useEffect(() => {
    if (file?.length > 0) setFile(file);
  }, [file]);

  if (!canImportCalculations(user)) return <Redirect to="/" />;

  const handleSubmitValues = (values) => {
    const tempValues = values?.file || file;
    const promises = [];

    const params = {
      method: values?.Methods?.['@id'],
      wasteScenarioSet: values?.wasteScenarioSets?.['@id'],
    };

    promises.push(
      new Promise((resolve) => {
        const bodyFormData = new FormData();
        bodyFormData.append('file', tempValues[0]);
        resolve(imports.importFile(bodyFormData).then(value => {
          params.upload = value['@id'];
          promises.push(imports.importCalculation(params)
            .then(r => {
              h.push(`/calculations/import/${r?.id}`,
                { routeName: r?.upload?.originalFilename });
            }));
        }));
      }),
    );
    return Promise.all(promises);
  };

  const importForm = () => (
    <S.FormContainer style={{ marginTop: '1.6875rem' }}>
      {!fileValue
        && (
          <div
            style={{
              height: '117px',
              width: '242px',
              backgroundColor: '#FFFFFF',
              position: 'absolute',
              top: 115,
              border: '1px dashed #DEDEDE',
              boxSizing: 'border-box',
              borderRadius: '2px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              color: 'rgba(32, 37, 56, 0.5)',
            }}>
            {t('select a file')}
          </div>
        )}
      <Form onSubmit={handleSubmit(handleSubmitValues)}>
        {!fileValue
          ? (
            <Field
              // eslint-disable-next-line max-len
              accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              component={FormFileUpload}
              name="file"
              props={{
                id: 'file to import',
                name: 'file to import',
                maxSize: 5_000_000,
                style: {
                  opacity: 0,
                  height: fileValue ? '0' : '117px',
                  width: fileValue ? '0' : '242px',
                },
              }}
            />
          ) : <FormSimpleContentInfo content={fileValue[0]?.name} name="file to import" />}
        <Field
          component={FormSelect}
          fullInputWidth
          name="Methods"
          props={{
            name: 'Methods',
            options: result?.methods,
            placeholder: t('select method'),
            isSearchable: true,
          }}
          validate={required}
        />
        <Field
          component={FormSelect}
          fullInputWidth
          name="wasteScenarioSets"
          props={{
            name: 'wasteScenarioSets',
            options: countries,
            getOptionLabel: w => w?.name,
            getOptionValue: w => w?.['@id'],
            placeholder: t('select waste scenario set'),
            isSearchable: true,
          }}
          validate={required}
        />
        <div
          style={{ display: 'flex', justifyContent: 'space-between', margin: '1.8rem 0 6.25rem' }}>
          <Button
            disabled={file?.length === 0 || file === undefined || pristine || invalid}
            loading={submitting}
            style={{ minWidth: '9.87rem' }}
            styling="select"
            type="submit">
            {t('start validation')}
          </Button>
        </div>
      </Form>
    </S.FormContainer>
  );

  return (
    <S.Background isEditable style={{ paddingBottom: 0, height: '100%' }}>
      <Placeholder loaders={[loading, countriesLoading]}>
        <S.Bar>{t('select a file')}</S.Bar>
        {importForm()}
      </Placeholder>
    </S.Background>
  );
};

export default reduxForm({
  form: 'imports',
  enableReinitialize: true,
})(formValues({
  file: 'file',
})(NewImport));
