import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { setBreadcrumb, setBreadcrumbCalculation } from 'state/sidebar/sidebar.actions';
import {
  useRouteMatch,
  Switch,
  Route,
  useHistory,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { mapCalculationStatus } from 'utils/calculation-statuses';
import CalculationContext, { Calculation } from 'hooks/calculation.hooks';
import SuppliersContext from 'hooks/suppliers.hooks';
import { WithInputsContext2 } from 'hooks/input.hooks';

import { MenuCollapse, MenuItem } from 'components/VerticalMenu';
import AsideLayout from 'components/AsideLayout/AsideLayout';
import { NoContent, Placeholder } from 'components/Splash/Loading';
import Logs from 'components/Logs/Logs';
import RealtimeCards from 'components/RealtimeCards/RealtimeCards';
import FunctionalUnit from 'components/FunctionalUnit/FunctionalUnit';

import Basics from './Basics';
import Advanced from './Advanced';
import Dashboard from './DetailDashboard/DetailDashboard';
import Inputs from './Inputs';
import ExpertFunctions from './ExpertFunctions/ExpertFunctions';
import Reports from './Reports/Reports';
import RealtimeResults from './Realtime';

import * as S from './Detail.styled';
import {
  RealTimeInputsUpdater,
  WithRealTimeResultsContext,
} from '../../../hooks/realTimeResults.hooks';

const Detail = () => {
  const route = useRouteMatch();
  const h = useHistory();
  let { url } = route;
  const { path } = route;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { result: calculation, loading } = useContext(Calculation);

  React.useEffect(() => {
    if (calculation?.productName) {
      dispatch(setBreadcrumb(calculation?.productName));
      dispatch(setBreadcrumbCalculation(calculation));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calculation]);

  // Fix appended slash when /routing
  if (url.endsWith('/')) {
    url = url.substring(0, url.length - 1);
  }

  const getStatusContainer = (calculations) => {
    const result = mapCalculationStatus(calculations);

    const goToProgress = () => { h.push(`${url}/realtime/progress`); };
    const shouldLinkToProgress = result?.text === 'In progress' || result.text === 'Incomplete';

    return (
      <S.StatusContainer
        color={result.buttonBackgroundColor || result.color}
        onClick={shouldLinkToProgress ? goToProgress : null}
        style={{ cursor: shouldLinkToProgress ? 'pointer' : 'unset' }}>
        <result.icon color="white" />
        <p>{t(result.text)}</p>
      </S.StatusContainer>
    );
  };

  if (calculation?.error) return NoContent;
  if (!calculation && loading) return <Placeholder loaders={[true]} />;
  if (!calculation && !loading) return NoContent;

  return (
    <SuppliersContext>
      <WithInputsContext2 calculationId={calculation?.id} calculationPath={calculation?.['@id']}>
        <WithRealTimeResultsContext>
          <RealTimeInputsUpdater />
          <AsideLayout
            LeftPane={(
              <aside>
                <S.ProductWrapper>
                  <S.ProductName>{calculation.productName}</S.ProductName>

                  <S.FunctionalUnit>
                    <span>
                      {`${t('functional unit')}: `}
                      <FunctionalUnit calculation={calculation} />
                    </span>
                  </S.FunctionalUnit>

                  <S.Wrapper>
                    {getStatusContainer(calculation)}
                  </S.Wrapper>
                </S.ProductWrapper>

                <S.LinksWrapper>
                  <MenuItem exact to={`${url}/`}>
                    {t('root_folder_name')}
                  </MenuItem>

                  <MenuItem to={`${url}/basics`}>
                    {t('edit basics')}
                  </MenuItem>

                  <MenuItem to={`${url}/advanced`}>
                    {t('advanced settings')}
                  </MenuItem>

                  <MenuItem to={`${url}/inputs`}>
                    {t('inputs')}
                  </MenuItem>

                  <MenuCollapse
                    label={t('information for report')}
                    to={`${url}/information-for-report`}>
                    <MenuItem to={`${url}/information-for-report/general`}>
                      {t('general')}
                    </MenuItem>

                    <MenuItem to={`${url}/information-for-report/substantiations`}>
                      {t('substantiations')}
                    </MenuItem>
                  </MenuCollapse>

                  <MenuCollapse label={t('expert functions')} to={`${url}/expert-functions`}>
                    <MenuItem to={`${url}/expert-functions/default-substantiations`}>
                      {t('default-substantiations')}
                    </MenuItem>

                    <MenuItem to={`${url}/expert-functions/input-output-substantiations`}>
                      {t('input-output-substantiations')}
                    </MenuItem>

                    <MenuItem to={`${url}/expert-functions/interpretation`}>
                      {t('interpretation')}
                    </MenuItem>
                  </MenuCollapse>
                </S.LinksWrapper>

                <RealtimeCards />
              </aside>
          )}>
            <Switch>
              <Route component={Dashboard} exact path={path} />
              <Route component={Basics} path={`${path}/basics`} />
              <Route component={Logs} exact path={`${path}/logs`} />
              <Route component={Advanced} path={`${path}/advanced`} />
              <Route component={Inputs} path={`${path}/inputs`} />
              <Route component={RealtimeResults} path={`${path}/realtime`} />
              <Route component={ExpertFunctions} path={`${path}/expert-functions`} />
              <Route component={Reports} path={`${path}/information-for-report`} />
            </Switch>
          </AsideLayout>
        </WithRealTimeResultsContext>
      </WithInputsContext2>
    </SuppliersContext>
  );
};

export default () => (
  <CalculationContext>
    <Detail />
  </CalculationContext>
);
