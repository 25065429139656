import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Field, reduxForm, Form } from 'redux-form';
import { alertToast } from 'config/toast';
import Button from 'components/Button/Button';
import { FormSelect } from 'components/Select/Select';
import { FormInput } from 'components/Input/Input';
import MainModal from 'components/Modal';
import * as S from 'routes/GlobalStyle.styled';
import { FormSimpleContentInfo } from 'components/ContentInfo/ContentInfo';
import * as v from '../../Basics/validation';

const FORM_NAME = 'pdis-form';

const PdisForm = (props) => {
  const { t } = useTranslation();
  const {
    handleSubmit,
    submitting,
    isEdit,
    saveForm,
    pristine,
    methods,
    wasteScenarioSets,
    pdis,
  } = props;
  const syncErr = useSelector(({ form }) => form[FORM_NAME]?.syncErrors);
  const { isHidden } = useSelector(state => state.sidebar);
  const history = useHistory();
  const [hasEditedForm, setHasEditedForm] = useState(false);

  return (
    <>
      <S.FormContainer isHidden={isHidden} style={{ paddingBottom: '6rem', margin: '0' }}>
        <Form
          onSubmit={(ev) => {
            ev.preventDefault();
            setHasEditedForm(true);
            if (syncErr) {
              alertToast(t('form has errors'), 'alert');
            }

            handleSubmit((values) => saveForm(values))();
          }}
          style={{ width: '100%' }}>
          <S.GroupFields>
            <Field
              component={FormInput}
              name="Code"
              props={{
                placeholder: t('Code.label'),
              }}
              validate={isEdit ? null : v.required}
            />
            <Field
              component={FormInput}
              name="name"
              props={{
                placeholder: t('name.label'),
              }}
              validate={isEdit ? null : v.required}
            />
            <Field
              component={FormInput}
              name="Unit"
              props={{
                placeholder: t('Unit.label'),
              }}
              validate={isEdit ? null : v.required}
            />
            {pdis?.predefinedInputSetUsages?.length
              ? (
                <>
                  <FormSimpleContentInfo content={pdis?.method?.name} name="methodLabel" />
                  <FormSimpleContentInfo
                    content={pdis?.wasteScenarioSet?.name}
                    name="WasteScenarioSet"
                  />
                </>
              )
              : (
                <>
                  <Field
                    component={FormSelect}
                    name="methodLabel"
                    props={{
                      options: methods,
                      getOptionLabel: w => w.name,
                      getOptionValue: w => w.id,
                      isSearchable: true,
                      placeholder: t('methodLabel.label'),
                    }}
                    validate={isEdit ? null : v.required}
                  />
                  <Field
                    component={FormSelect}
                    name="WasteScenarioSet"
                    props={{
                      options: wasteScenarioSets,
                      getOptionLabel: w => w.name,
                      getOptionValue: w => w.id,
                      isSearchable: true,
                      placeholder: t('WasteScenarioSet.label'),
                    }}
                    validate={isEdit ? null : v.required}
                  />
                </>
              )}
          </S.GroupFields>
          <div style={{ display: 'flex', marginTop: '2rem', marginLeft: '2.25rem' }}>
            <Button
              loading={submitting}
              style={{ marginRight: '2rem' }}
              styling="select"
              type="submit">
              {t(isEdit ? 'save' : 'add set')}
            </Button>
            <Button
              onClick={() => history
                .push(isEdit ? `/calculations/predefined-input-sets/${pdis?.id}`
                  : '/calculations/predefined-input-sets')}
              styling="underline">
              {t('cancel')}
            </Button>
          </div>
        </Form>
      </S.FormContainer>
      <MainModal
        content="prompt"
        exitModalContent={{
          navigateSideEffects: () => null,
        }}
        prompt
        promptCondition={!pristine && !hasEditedForm}
      />
    </>
  );
};

export default reduxForm({
  form: FORM_NAME,
  enableReinitialize: true,
})(PdisForm);
