import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, Redirect } from 'react-router-dom';
import * as S from 'routes/GlobalStyle.styled';
import { useTranslation } from 'react-i18next';
import { FormFileUpload } from 'components/FileUpload/FileUpload';
import {
  reduxForm, Form, Field, formValues,
} from 'redux-form';
import Button from 'components/Button/Button';
import { FormSimpleContentInfo } from 'components/ContentInfo/ContentInfo';
import { imports, companies as companiesServices } from 'services';
import { required } from 'routes/Calculations/Detail/Basics/validation';
import { FormSelect } from 'components/Select/Select';
import { FormInput } from 'components/Input/Input';
import { useAsync } from 'react-async-hook';
import { FormSimpleCKEditor } from 'components/SimpleCKEditor';

const NewImport = ({
  handleSubmit,
  pristine,
  invalid,
  submitting,
  file,
}) => {
  const { t } = useTranslation();
  const user = useSelector(state => state.user);
  const [fileValue, setFile] = useState(null);
  const h = useHistory();

  const companyAdmin = user?.selectedMembership?.roleName === 'ROLE_EPD_ADMIN';

  const corporateAdmin = user?.selectedMembership?.roleName === 'ROLE_CORPORATE_EPD_ADMIN';

  const nibeEmployee = user?.selectedMembership?.view === 'Nibe Employee';

  const superAdmin = user?.selectedMembership?.view === 'Super Admin'
    || nibeEmployee;

  const { result: companies = [] } = useAsync(() => {
    const params = {};
    if (corporateAdmin) params.parentCompany = user?.selectedMembership?.company['@id'];
    return companiesServices.getAll({ params });
  }, []);

  useEffect(() => {
    if (file?.length > 0) setFile(file);
  }, [file]);

  const highRoles = superAdmin
    || corporateAdmin
    || user?.selectedMembership?.roleName === 'ROLE_EPD_ADMIN';

  if (!highRoles) return <Redirect to="/" />;

  const handleSubmitValues = (values) => {
    const tempValues = values?.file || file;
    const promises = [];

    const params = {
      versionName: values?.versionName,
      versionDescription: values?.versionDescription,
    };

    if (superAdmin && !values?.company) ;
    else {
      params.owner = companyAdmin
        ? user?.selectedMembership?.company['@id'] : values?.company && values?.company['@id'];
    }
    params.public = !params.owner;

    promises.push(
      new Promise((resolve) => {
        const bodyFormData = new FormData();
        bodyFormData.append('file', tempValues[0]);
        resolve(imports.importFile(bodyFormData).then(value => {
          if (superAdmin && !values?.company) ;
          else {
            params.owner = companyAdmin
              ? user?.selectedMembership?.company['@id']
              : values?.company && values?.company['@id'];
          }
          params.public = !params.owner;
          params.upload = value['@id'];
          promises.push(imports.importEnv(params)
            .then(r => {
              h.push(`/environmental-profiles/import/${r?.id}`,
                { routeName: r?.upload?.originalFilename });
            }));
        }));
      }),
    );
    return Promise.all(promises);
  };

  const onChange = (editor, input) => {
    const data = editor.getData();
    input.onChange(data || null);
  };

  const importForm = () => (
    <S.FormContainer style={{ marginTop: '1.6875rem' }}>
      {!fileValue
        && (
          <div
            style={{
              height: '117px',
              width: '242px',
              backgroundColor: '#FFFFFF',
              position: 'absolute',
              top: 115,
              border: '1px dashed #DEDEDE',
              boxSizing: 'border-box',
              borderRadius: '2px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              color: 'rgba(32, 37, 56, 0.5)',
            }}>
            {t('select a file')}
          </div>
        )}
      <Form onSubmit={handleSubmit(handleSubmitValues)}>
        {!fileValue
          ? (
            <Field
              // eslint-disable-next-line max-len
              accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              component={FormFileUpload}
              name="file"
              props={{
                id: 'file to import',
                importName: 'file',
                name: 'file to import',
                maxSize: 5_000_000,
                style: {
                  opacity: 0,
                  height: fileValue ? '0' : '117px',
                  width: fileValue ? '0' : '242px',
                },
              }}
            />
          ) : <FormSimpleContentInfo content={fileValue[0]?.name} name="file to import" />}
        <Field
          component={FormInput}
          fullInputWidth
          name="versionName"
          props={{
            name: 'versionName',
            placeholder: t('VersionName'),
          }}
          validate={required}
        />
        <Field
          component={FormSimpleCKEditor}
          fullInputWidth
          name="versionDescription"
          props={{
            name: 'versionDescription',
            onChange,
          }}
          validate={required}
        />
        {!companyAdmin && (
          <Field
            component={FormSelect}
            fullInputWidth
            name="company"
            props={{
              name: 'company',
              options: companies?.companies,
              placeholder: t('Company'),
              isSearchable: true,
            }}
            // EPD-1157
            // Nibe Employee - Required
            // Super Admin   - not required
            // whatever      - required
            // eslint-disable-next-line no-nested-ternary
            validate={superAdmin ? (nibeEmployee ? required : null) : required}
          />
        )}
        <div
          style={{ display: 'flex', justifyContent: 'space-between', margin: '1.8rem 0 6.25rem' }}>
          <Button
            disabled={pristine || invalid || file?.length === 0}
            loading={submitting}
            style={{ minWidth: '9.87rem' }}
            styling="select"
            type="submit">
            {t('start validation')}
          </Button>
        </div>
      </Form>
    </S.FormContainer>
  );

  return (
    <S.Background isEditable style={{ paddingBottom: 0, height: '100%' }}>
      <S.Bar>{t('select a file')}</S.Bar>
      {importForm()}
    </S.Background>
  );
};

export default reduxForm({
  form: 'imports',
  enableReinitialize: true,
})(formValues({
  file: 'file',
})(NewImport));
