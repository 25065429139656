import * as types from './sidebar.types';

const initialState = {
  isHidden: false,
  routeName: null,
  calculation: null,
};

const sidebar = (state = initialState, action) => {
  switch (action.type) {
    case types.HIDE_SIDEBAR: {
      return { ...state, isHidden: !state.isHidden };
    }
    case types.SET_BREADCRUMB: {
      return { ...state, routeName: action.payload };
    }
    case types.SET_BREADCRUMB_CALCULATION: {
      return { ...state, calculation: action.payload };
    }

    default:
      return state;
  }
};

export default sidebar;
