import React from 'react';
import { Redirect } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setMembership } from 'state/user/user.actions';
import * as authActions from 'state/auth/auth.actions';
import * as companyActions from 'state/company/company.actions';

const Logout = () => {
  const dispatch = useDispatch();

  React.useLayoutEffect(() => {
    dispatch(authActions.logout);
    dispatch(companyActions.clear);
    dispatch(setMembership(null));
  },
  [dispatch]);

  return <Redirect to="/login" />;
};

export default Logout;
