import _ from 'lodash';
import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
  Field, reduxForm, formValues, Form,
} from 'redux-form';
import { alertToast } from 'config/toast';
import { useAllMethods, useMethods } from 'hooks/methods.hooks';
import { Calculation } from 'hooks/calculation.hooks';

import Button from 'components/Button/Button';
import GField from 'components/Field';
import { FormSelect, FormAsyncSelect } from 'components/Select/Select';
import { FormRadio } from 'components/Radio/Radio';
import { FormTextArea } from 'components/TextArea/TextArea';
import { FormInput } from 'components/Input/Input';
import LocationEditor from 'components/LocationEditor/LocationEditor';
import MainModal from 'components/Modal';
import * as S from 'routes/GlobalStyle.styled';
import { FormSimpleContentInfo } from 'components/ContentInfo/ContentInfo';
import { useResolveUnit } from 'components/FunctionalUnit/FunctionalUnit.hook';
import { findPublisherOperator } from 'utils/calculationsPublisherOperator';
import * as v from './validation';

const getIRI = ({ '@id': id }) => id;

const FORM_NAME = 'calculation-basics';

const BasicsForm = (props) => {
  const { t } = useTranslation();
  const { result: methods, loading } = useAllMethods();
  const {
    handleSubmit,
    submitting,
    isEdit,
    functionalUnit,
    fieldCalculationType,
    calculationType, // hook
    location: locationHook, // hooks
    wasteRegions, // hooks
    standardUnits, // hooks
    customUnitForLCA, // hooks
    standardUnitForLCA, // hooks
    saveForm,
    pristine,
    method,
    productType,
    customFunctionalUnit,
    change,
    locationSelected,
    template,
    templates,
    companies,
    operators,
  } = props;
  const user = useSelector(state => state.user);
  const { result: calculation } = useContext(Calculation);
  const methodsAdvanced = useMethods(template || (method ? { method } : calculation));
  const syncErr = useSelector(({ form }) => form[FORM_NAME]?.syncErrors);
  const { isHidden } = useSelector(state => state.sidebar);
  const history = useHistory();
  const { id: calcId } = useParams();
  const [hasEditedForm, setHasEditedForm] = useState(false);
  /* false stands for standard | true is custom. */
  const [isCustom, setfunctionalUnitType] = useState(!customFunctionalUnit);
  const handleRadio = ({ target }) => setfunctionalUnitType(target.value !== 'custom');
  const semifinished = calculation?.calculationType === 'semi_finished_product';
  const unit = useResolveUnit({
    calculationType: calculation?.calculationType || fieldCalculationType?.value,
    isTemplate: template,
    ...template,
  });

  const onChange = (value, other) => {
    // eslint-disable-next-line no-unused-expressions
    other?.input?.onChange(value);
    change('productType', null);
  };

  const onChangeTemplate = (value, other) => {
    // eslint-disable-next-line no-unused-expressions
    other?.input?.onChange(!value?.['@id'] ? null : value);
  };

  React.useEffect(() => {
    if (methodsAdvanced?.productTypes?.length === 1) {
      change('productType', methodsAdvanced?.productTypes[0]);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [methodsAdvanced?.productTypes]);

  const currentCompany = isEdit && calculation
    ? companies?.find(c => c['@id'] === calculation.owner?.['@id'])
    : companies?.find(c => c['@id'] === user.selectedMembership?.company?.['@id']);

  return (
    <>
      <S.FormContainer isHidden={isHidden} style={{ paddingBottom: '6rem', margin: '0' }}>
        <Form
          onSubmit={(ev) => {
            ev.preventDefault();
            setHasEditedForm(true);
            if (syncErr) {
              alertToast(t('form has errors'), 'alert');
            }

            handleSubmit((values) => saveForm(values, !isCustom, template))();
          }}
          style={{ width: '100%' }}>
          {user?.selectedMembership?.company?.license === 3 && (
          <>
            <S.GroupFields>
              <S.GroupFieldsHeader>{t('template.label')}</S.GroupFieldsHeader>
              <Field
                component={(other) => (
                  <FormSelect
                    {...other}
                    input={{
                      ...other.input,
                      onChange: (p) => onChangeTemplate(p, other),
                    }}
                  />
                )}
                name="chooseTemplate"
                props={{
                  options: [{ name: 'Select...', '@id': null }, ...templates],
                  isSearchable: true,
                  getOptionLabel: (l) => l?.name,
                  getOptionValue: getIRI,
                }}
              />
            </S.GroupFields>
            <S.GroupFieldsDivider />
          </>
)}
          {!isEdit && (
            <S.GroupFields>
              <Field
                component={FormSelect}
                name="calculationType"
                props={{
                  options: calculationType.options,
                  isSearchable: false,
                }}
                validate={[v.required, v.calculationType]}
              />
            </S.GroupFields>
          )}
          <S.GroupFields>
            <S.GroupFieldsHeader>{t('Product characteristics')}</S.GroupFieldsHeader>
            <Field
              component={FormInput}
              name="productName"
              validate={[v.required, v.productName]}
            />

            <Field
              component={FormInput}
              name="Code"
              props={{
                placeholder: t('type code'),
              }}
              validate={v.required}
            />

            <S.Wrapper>
              <S.AlignItems>
                {!template || !template?.location
                  ? (
                    <Field
                      component={FormAsyncSelect}
                      name="location"
                      props={{
                        // Needs a key to update the default options onLocationCreated
                        key: locationHook.list?.length,
                        defaultValue: locationSelected,
                        options: locationHook.list,
                        isSearchable: true,
                        loadOptions: locationHook.loadPageOptions,
                        debounceTimeout: 1000,
                        getOptionLabel: (l) => `${l.name} - ${l.city}, ${l.country}`,
                        getOptionValue: getIRI,
                        custom: () => <LocationEditor onLocationCreated={locationHook.prepend} />,
                      }}
                      validate={[v.required, v.location]}
                    />
                  ) : <FormSimpleContentInfo content={template?.location?.name} name="location" />}
              </S.AlignItems>
            </S.Wrapper>

            {!template || !template?.productLifeTime
              ? (
                <Field
                  component={FormInput}
                  name="productLifeTime"
                  props={{ type: 'number', min: 1 }}
                  validate={[v.required, v.productLifeTime]}
                />
              ) : (
                <FormSimpleContentInfo content={template?.productLifeTime} name="productLifeTime" />
              )}
          </S.GroupFields>
          <S.GroupFieldsDivider />
          <S.GroupFields>
            <S.GroupFieldsHeader>{t('lca method')}</S.GroupFieldsHeader>
            {!template
              ? (
                <Field
                  component={(other) => (
                    <FormSelect
                      {...other}
                      input={{
                        ...other.input,
                        onChange: (p) => {
                          change('operator', p.operator || currentCompany);
                          change('publisher', p.publisher || currentCompany);
                          return onChange(p, other);
                        },
                      }}
                    />
                  )}
                  name="method"
                  props={{
                    options: methods?.methods,
                    isLoading: loading,
                    getOptionLabel: ({ name }) => name,
                    getOptionValue: getIRI,
                    isSearchable: true,
                  }}
                  validate={[v.required, v.method]}
                />
              ) : (
                <FormSimpleContentInfo content={template?.method?.name} name="method" />
              )}

            {method && (
            <>
              {template?.method?.publisher || template?.publisher
              || method?.publisher || !currentCompany.parentCompany
                ? (
                  <FormSimpleContentInfo
                    content={findPublisherOperator('publisher',
                      calculation, template, method, companies)?.name
                      || currentCompany?.name || '-'}
                    name="calculationPublisher"
                  />
)
                : (
                  <Field
                    component={FormSelect}
                    name="publisher"
                    props={{
                      name: 'calculationPublisher',
                      options: (currentCompany.parentCompany
                        ? [currentCompany, currentCompany.parentCompany, method.publisher]
                        : [method.publisher]).filter(o => !!o),
                      getOptionLabel: o => o.name
                    || companies?.find(c => c['@id'] === o['@id'] || c['@id'] === o)?.name,
                      getOptionValue: ({ '@id': id }) => id,
                      isSearchable: true,
                    }}
                    validate={[v.required]}
                  />
                )}
              {template?.method?.operator || template?.operator || method?.operator
                ? (
                  <FormSimpleContentInfo
                    content={findPublisherOperator(
                      'operator',
                      calculation, template, method, companies,
                    )?.name || currentCompany?.name || '-'}
                    name="calculationOperator"
                  />
)
                : (
                  <Field
                    component={FormSelect}
                    name="operator"
                    props={{
                      name: 'calculationOperator',
                      options: (operators.find(o => o['@id'] === currentCompany?.['@id'])
                        ? operators : [currentCompany, ...operators])
                      ?.filter(o => !!o?.['@id']),
                      getOptionLabel: o => o.name
                    || (operators?.find(c => c['@id'] === o['@id'] || c['@id'] === o)
                     || companies?.find(c => c['@id'] === o['@id'] || c['@id'] === o))?.name,
                      getOptionValue: ({ '@id': id }) => id,
                      isSearchable: true,
                    }}
                    validate={[v.required]}
                  />
)}
            </>
            )}

            {!template
              ? (
                <Field
                  component={FormSelect}
                  name="wasteRegion"
                  props={{
                    options: wasteRegions.list,
                    getOptionLabel: w => `${w.name}, ${w.code}`,
                    getOptionValue: getIRI,
                    isSearchable: true,
                  }}
                  validate={[v.required, v.wasteRegion]}
                />
              ) : (
                <FormSimpleContentInfo
                  content={template?.wasteScenarioSet?.name}
                  name="wasteRegion"
                />
              )}
          </S.GroupFields>
          <S.GroupFieldsDivider />
          <S.GroupFields>
            <S.GroupFieldsHeader>{t('standardUnit.name')}</S.GroupFieldsHeader>
            {/* Functional Units */}
            {fieldCalculationType?.value === 'semi_finished_product' ? (
              <div>
                {!template ? (
                  <Field
                    component={FormSelect}
                    name="standardUnit"
                    props={{
                      options: standardUnits.list,
                      getOptionLabel: (i) => `(${i.abbreviation}) ${i.unit}`,
                      getOptionValue: getIRI,
                      isSearchable: true,
                    }}
                    validate={[v.required, v.standardUnit]}
                  />
                ) : (
                  <FormSimpleContentInfo
                    content={unit}
                    name="standardUnit"
                  />
                )}
              </div>
            ) : (
              <div>
                {!template && (
                  <GField name="functionalUnit">
                    <S.RadioWrapper>
                      <FormRadio
                        checked={isCustom}
                        id="functionalUnit-standard"
                        onChange={handleRadio}
                        translation={{ placeholder: t('functionalUnit.standard') }}
                        value="standard"
                      />
                      <FormRadio
                        checked={!isCustom}
                        id="functionalUnit-custom"
                        onChange={handleRadio}
                        translation={{ placeholder: t('functionalUnit.custom') }}
                        value="custom"
                      />
                    </S.RadioWrapper>
                  </GField>
                )}

                {(template ? template?.customFunctionalUnit : !isCustom) ? (
                  <div>
                    {!template
                      ? (
                        <>
                          <Field
                            component={FormInput}
                            name="functionalUnitTitle"
                            validate={[v.required, v.functionalUnitTitle]}
                          />

                          <Field
                            component={FormTextArea}
                            name="functionalUnitDescription"
                            props={{
                              cols: '80',
                              rows: '4',
                              spellCheck: true,
                            }}
                            validate={[v.required, v.functionalUnitDescription]}
                          />

                          <Field
                            component={FormSelect}
                            name="standardUnit"
                            props={{
                              options: customUnitForLCA.list,
                              getOptionLabel: (i) => `(${i.abbreviation})  ${i.unit}`,
                              getOptionValue: getIRI,
                              isSearchable: true,
                            }}
                            validate={[v.required, v.standardUnit]}
                          />
                        </>
                      ) : (
                        <>
                          <FormSimpleContentInfo
                            content={template?.functionalUnitTitle}
                            name="functionalUnitTitle"
                          />
                          <FormSimpleContentInfo
                            content={template?.functionalUnitDescription}
                            name="functionalUnitDescription"
                          />
                          <FormSimpleContentInfo
                            content={unit}
                            name="standardUnit"
                          />
                        </>
                      )}
                  </div>
                ) : (
                  <div>
                    {!template
                      ? (
                        <>
                          <Field
                            component={FormSelect}
                            name="functionalUnit"
                            props={{
                              options: standardUnitForLCA.list,
                              isSearchable: true,
                              getOptionLabel: (i) => `${i.unit} (${i.elementName})`,
                              getOptionValue: getIRI,
                            }}
                            validate={[v.required, v.functionalUnit]}
                          />
                          <p>{functionalUnit && functionalUnit.description}</p>
                        </>
                      ) : (
                        <FormSimpleContentInfo
                          content={unit}
                          name="functionalUnit"
                        />
                      )}
                  </div>
                )}
              </div>
            )}
          </S.GroupFields>
          {!semifinished && (fieldCalculationType?.value !== 'semi_finished_product') && (
            <>
              <S.GroupFieldsDivider />
              <S.GroupFields>
                <S.GroupFieldsHeader>{`${t('productType.label')} (A4)`}</S.GroupFieldsHeader>
                {!template
                  ? (
                    <>
                      <Field
                        component={FormSelect}
                        name="productType"
                        props={{
                          options: methodsAdvanced?.productTypes,
                          getOptionLabel: ({ name }) => name,
                          getOptionValue: ({ id }) => id,
                          isSearchable: true,
                        }}
                        validate={v.required}
                      />
                      <fieldset>
                        {productType?.id === 3 && (
                          <>
                            <Field
                              component={FormSelect}
                              name="transportProfileToConstructionSite"
                              props={{
                                options: methodsAdvanced.profiles,
                                getOptionLabel: ({ name }) => name,
                                getOptionValue: ({ id }) => id,
                                isSearchable: true,
                              }}
                              validate={[v.required, v.transportProfileToConstructionSite]}
                            />

                            <Field
                              component={FormInput}
                              name="distanceToConstructionSite"
                              props={{ type: 'number' }}
                              validate={[v.required, v.distanceToConstructionSite]}
                            />
                          </>
                        )}
                      </fieldset>
                    </>
                  ) : (
                    <>
                      <FormSimpleContentInfo
                        content={methodsAdvanced?.productType?.name}
                        name="productType"
                      />
                      {template?.transportProfileToConstructionSite && (
                        <FormSimpleContentInfo
                          content={_.find(
                            methodsAdvanced?.profiles,
                            ({ '@id': id }) => id === template?.transportProfileToConstructionSite,
                          )?.name}
                          name="transportProfileToConstructionSite"
                        />
                      )}
                      {template?.distanceToConstructionSite && (
                        <FormSimpleContentInfo
                          content={template?.distanceToConstructionSite}
                          name="distanceToConstructionSite"
                        />
                      )}
                    </>
                  )}
              </S.GroupFields>
            </>
          )}
          <div style={{ display: 'flex', marginTop: '2rem', marginLeft: '2.25rem' }}>
            <Button
              loading={submitting}
              style={{ marginRight: '2rem' }}
              styling="select"
              type="submit">
              {t('Save calculation')}
            </Button>
            <Button
              onClick={() => history
                .push(isEdit ? `/calculation/${calcId}/basics/view` : '/calculations')}
              styling="underline">
              {t('cancel')}
            </Button>
          </div>
        </Form>
      </S.FormContainer>
      <MainModal
        content="prompt"
        exitModalContent={{
          navigateSideEffects: () => null,
        }}
        prompt
        promptCondition={!pristine && !hasEditedForm}
      />
    </>
  );
};

export default reduxForm({
  form: FORM_NAME,
  enableReinitialize: true,
})(
  formValues({
    fieldCalculationType: 'calculationType',
    functionalUnit: 'functionalUnit',
    amountScalingDimensions: 'amountScalingDimensions',
    method: 'method',
    productType: 'productType',
    template: 'chooseTemplate',
  })(BasicsForm),
);
