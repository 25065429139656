import styled, { css } from 'styled-components';
import { DARK_BLUE } from 'config/style';

export const whiteButton = css`
  font-style: normal;
  font-size: 14px;
  border: 1px solid #e2e2d2;
  background: #fafaf5;
  color: ${DARK_BLUE};
  margin-right: 10px;
`;

export const underlineButton = css`
  background-color: transparent;
  text-decoration: underline;
  border: 0;
  color: ${({ color }) => (color || DARK_BLUE)};
  font-weight: 400;
  &:hover,
  &:focus {
    outline: none;
    box-shadow: none;
    background-color: transparent;
  }
`;

export const styledSelect = css`
  font-size: .875rem;
  color: ${DARK_BLUE};
  background: #fdfeff;
  border: 1px solid #ffffff;
  padding: .6 1.2rem;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  min-width: 9.8rem;
  text-align: center;
  font-weight: 500;
  height: 2.813rem;
`;

export const StyledButton = styled.button`
  padding: .68rem 1.25rem;
  cursor: pointer;
  background: ${DARK_BLUE};
  color: white;
  font-size: 14px;
  border: none;
  font-weight: normal;

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  flex: 0 0 auto;

  &:disabled {
    opacity: .45;
    cursor: not-allowed;
  }

  > div {
    flex: 0 1 100%;
  }

  ${(props) => props.styling === 'select' && styledSelect};
  ${({ styling }) => styling === 'white' && whiteButton};
  ${({ styling }) => styling === 'underline' && underlineButton};
`;

export const Icon = styled.i`
  flex: 0 0 auto;
  display: inline-flex;
  ${({ left }) => !left && css`margin-left: .75em;`};
  ${({ left }) => left && css`margin-right: .75em;`};
`;
