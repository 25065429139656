import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { reduxForm, Form, formValues } from 'redux-form';
import { useTranslation } from 'react-i18next';

import { useCalculationMethod } from 'hooks/methods.hooks';

import Button from 'components/Button/Button';
import { Placeholder } from 'components/Splash/Loading';
import { useHistory, useParams } from 'react-router-dom';
import MainModal from 'components/Modal';
import * as S from 'routes/GlobalStyle.styled';

const DefaultSubstantiations = ({
  submitting,
  formField,
  handleSubmitValues,
  getFieldValues,
  handleSubmit,
  abbreviations,
  pcr,
  purposeTargetGroups,
  environmentalProfileRatingsRepresentative,
  cutoffCriteria,
  annex2References,
  hasEditedForm,
  data: calculationData,
  isTemplate,
}) => {
  const { t } = useTranslation();
  const methodHook = useCalculationMethod(calculationData?.method?.id);
  const { result: method = {} } = methodHook;
  const history = useHistory();
  const { id: calcId } = useParams();
  const { isHidden } = useSelector(state => state.sidebar);
  const [hasSubmited, setHasSubmited] = React.useState(false);
  const isSemi = (calculationData?.calculationType === 'semi_finished_product');

  useEffect(
    () => {
      getFieldValues({
        abbreviations,
        pcr,
        purposeTargetGroups,
        environmentalProfileRatingsRepresentative,
        cutoffCriteria,
        annex2References,
      });
    },
    [
      getFieldValues,
      abbreviations,
      pcr,
      purposeTargetGroups,
      environmentalProfileRatingsRepresentative,
      cutoffCriteria,
      annex2References,
    ],
  );

  return (
    <S.Background isEditable>
      <S.HeaderTitle>{t('expert functions default substantiations')}</S.HeaderTitle>

      <S.FormContainer isHidden={isHidden}>
        <Placeholder loaders={[methodHook]}>
          {() => (
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit(handleSubmitValues)().then(() => {
                  setHasSubmited(true);
                });
              }}>
              {formField({
                label: 'used abbreviations',
                calculationContent: calculationData.abbreviations,
                methodContent: method.abbreviations,
                calculationKey: 'abbreviations',
                isFromCalculation: !!calculationData.abbreviations && !calculationData?.template,
                template: calculationData?.template,
                simpleEditor: false,
              })}
              {formField({
                label: 'product categorie rules',
                calculationContent: calculationData.pcr,
                methodContent: method.pcr,
                calculationKey: 'pcr',
                isFromCalculation: !!calculationData.pcr && !calculationData?.template,
                template: calculationData?.template,
                simpleEditor: false,
              })}
              {formField({
                label: 'purpose and target groups',
                calculationContent: calculationData.purposeTargetGroups,
                methodContent: method.purposeDescription,
                calculationKey: 'purposeTargetGroups',
                isFromCalculation: !!calculationData.purposeTargetGroups && !calculationData?.template,
                template: calculationData?.template,
                simpleEditor: false,
              })}
              {formField({
                label: 'environmental profile and ratings representive',
                calculationContent: calculationData.environmentalProfileRatingsRepresentative,
                methodContent: `The input data are representative for ${(calculationData.productName || calculationData?.template?.productName || calculationData.name)}
                , a product of ${(calculationData?.owner?.name || calculationData?.template?.owner?.name)}. The data are representative for
                ${(calculationData.wasteScenarioSet?.name || calculationData?.template?.wasteScenarioSet?.name)}.`,
                calculationKey: 'environmentalProfileRatingsRepresentative',
                isFromCalculation: !!calculationData.environmentalProfileRatingsRepresentative && !calculationData?.template,
                template: calculationData?.template,
                simpleEditor: false,
              })}
              {formField({
                label: 'cut off criteria',
                calculationContent: calculationData.cutoffCriteria,
                methodContent: isSemi ? ` <b>${t('Product Stage (A1-A3).label')}</b>
                                          <br/>
                                          ${method.cutoffCriteriaProduct}`
                  : `
                    <b>${t('Product Stage (A1-A3).label')}</b>
                    <br/>${method.cutoffCriteriaProduct}
                    <br/>
                    <b>${t('Construction process stage (A4-A5).label')}</b>
                    <br/>${method.cutoffCriteriaConstruction}
                    <br/>
                    <b>${t('Use stage (B1-B3).label')}</b>
                    <br/>${method.cutoffCriteriaUse}
                    <br/>
                    <b>${t('End of life stage (C1-C4).label')}</b>
                    <br/>${method.cutoffCriteriaEOL}
                    <br/>
                    <b>${t('Benefits and Loads beyond the system boundary (Module D).label')}</b>
                    <br/>${method.cutoffCriteriaSupplementary}`,
                calculationKey: 'cutoffCriteria',
                isFromCalculation: !!calculationData.cutoffCriteria && !calculationData?.template,
                template: calculationData?.template,
                simpleEditor: false,
              })}
              {formField({
                label: 'annex 2 references',
                calculationContent: calculationData.annex2References,
                methodContent: method.annex2References,
                calculationKey: 'annex2References',
                isFromCalculation: !!calculationData.annex2References && !calculationData?.template,
                template: calculationData?.template,
                simpleEditor: false,
              })}
              <div style={{ display: 'flex', marginTop: '1rem' }}>
                <Button
                  loading={submitting}
                  style={{ marginRight: '2rem' }}
                  styling="select"
                  type="submit">
                  {t('save')}
                </Button>
                <Button
                  onClick={() => {
                    if (isTemplate) {
                      history
                        .push(`/calculations/templates/${calcId}/expert-functions/default-substantiations/view`);
                    } else {
                      history
                        .push(`/calculation/${calcId}/expert-functions/default-substantiations/view`);
                    }
                  }}
                  styling="underline">
                  {t('cancel')}
                </Button>
              </div>
            </Form>
          )}
        </Placeholder>
      </S.FormContainer>
      <MainModal
        content="prompt"
        exitModalContent={{
          navigateSideEffects: () => null,
        }}
        prompt
        promptCondition={hasEditedForm && !hasSubmited}
      />
    </S.Background>
  );
};

export default reduxForm({
  form: 'default-substantiations',
  enableReinitialize: true,
})(
  formValues({
    abbreviations: 'abbreviations',
    pcr: 'pcr',
    purposeTargetGroups: 'purposeTargetGroups',
    environmentalProfileRatingsRepresentative: 'environmentalProfileRatingsRepresentative',
    cutoffCriteria: 'cutoffCriteria',
    annex2References: 'annex2References',
  })(DefaultSubstantiations),
);
