import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setBreadcrumb } from 'state/sidebar/sidebar.actions';
import { SubmissionError } from 'redux-form';
import { useAsyncCallback } from 'react-async-hook';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { locations } from 'services';

import { confirmationModalSetup } from 'components/Modal/ModalContent/Delete';
import MainModal from 'components/Modal';
import { Placeholder } from 'components/Splash/Loading';

import SupplierForm from 'routes/SuppliersAndLocations/SupplierForm';
import * as S from 'routes/GlobalStyle.styled';

const NewSupplier = () => {
  const { t } = useTranslation();
  const { isHidden } = useSelector(state => state.sidebar);
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const [supplier, setSupplier] = useState(null);
  const [confirmationModal, setConfirmationModal] = useState(null);
  const [logo, setLogo] = useState(null);

  const supplierResult = useAsyncCallback(() => locations.supplier(id).then(value => {
    if (value?.substantiationFile) {
      locations.getSubstantiationFile(value?.substantiationFile['@id']).then(logoValue => {
        setSupplier(value);
        setLogo(`${process.env.REACT_APP_API_URL}${logoValue?.contentUrl}`);
      });
    } else setSupplier(value);
  }));

  useEffect(() => {
    if (supplier?.name) dispatch(setBreadcrumb(supplier?.name));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supplier]);

  const countries = locations.countries();
  const isEdit = pathname.split('/')[4] === 'edit';

  useEffect(() => {
    if (isEdit) supplierResult.execute();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSubmitValues = (values, radio) => {
    const tempValues = values;

    const name = tempValues.name ? { name: tempValues.name } : isEdit && { name: supplier.name };
    const address = tempValues.address ? { address: tempValues.address }
      : isEdit && { address: supplier.address };
    const postalCode = tempValues.postalCode ? { postalCode: tempValues.postalCode }
      : isEdit && { postalCode: supplier.postalCode };
    const city = tempValues.city ? { city: tempValues.city } : isEdit && { city: supplier.city };
    const approachedForCompanyEPD = { approachedForCompanyEPD: radio };
    const country = tempValues.country ? { country: tempValues.country.value }
      : isEdit && { country: supplier.country };
    const documentType = tempValues.documentType ? { documentType: tempValues.documentType }
      : isEdit && { documentType: supplier.documentType || '' };

    const approachedBy = tempValues?.approachedBy
      ? { approachedBy: tempValues?.approachedBy?.value } : null;

    if (approachedBy) delete tempValues?.approachedBy?.value;

    const supplierInfo = {
      ...tempValues,
      ...country,
      ...documentType,
      ...name,
      ...address,
      ...postalCode,
      ...city,
      ...approachedForCompanyEPD,
      ...approachedBy,
    };

    if (tempValues.logoImageFile) delete supplierInfo.logoImageFile;

    if (isEdit) {
      const promises = [];
      if (tempValues.logoImageFile && tempValues.logoImageFile.length > 0) {
        promises.push(new Promise((resolve) => {
          const bodyFormData = new FormData();
          bodyFormData.append('file', tempValues.logoImageFile[0]);
          resolve(locations.uploadSubstantiationFile(bodyFormData).then(value => {
            supplierInfo.substantiationFile = value['@id'];
            promises.push(locations.editSupplier(id, supplierInfo)
              .catch((error) => {
                if (error?.response?.data?.violations) {
                  throw new SubmissionError({
                    code: t('unique ID has already been used for another supplier'),
                  });
                }
              }));
          }));
        }));
      } else {
        promises.push(locations.editSupplier(id, supplierInfo)
          .catch((error) => {
            if (error?.response?.data?.violations) {
              throw new SubmissionError({
                code: t('unique ID has already been used for another supplier'),
              });
            }
          }));
      }
      return Promise.all(promises).then(() => {
        history.push(`/locations-&-suppliers/suppliers/${id}`, { routeName: name?.name });
      });
    }
    return locations.createSupplier(supplierInfo).then(() => {
      history.push('/locations-&-suppliers/suppliers');
    }).catch((error) => {
      if (error?.response?.data?.violations) {
        throw new SubmissionError({
          code: t('unique ID has already been used for another supplier'),
        });
      }
    });
  };

  const deleteFile = () => confirmationModalSetup(
    setConfirmationModal,
    (closeModal) => locations
      .editSupplier(id, { substantiationFile: null })
      .then(() => {
        setLogo(undefined);
        closeModal();
      }),
  );

  const onDeleteItem = () => confirmationModalSetup(
    setConfirmationModal, () => locations
      .deleteSupplier(id)
      .then(() => history.push('/locations-&-suppliers/suppliers')),
  );

  const country = isEdit && _.find(countries, ({ value }) => value === supplier?.country);

  const types = [
    {
      label: t('by letter'),
      value: 1,
    },
    {
      label: t('by email'),
      value: 0,
    },
  ];

  return (
    <S.Background isEditable>
      <Placeholder
        loaders={[isEdit && supplierResult.loading]}>
        <div key={isEdit}>
          <S.HeaderTitle>
            {t(isEdit ? 'edit supplier' : 'new supplier')}
          </S.HeaderTitle>

          <S.FormContainer isHidden={isHidden}>
            <SupplierForm
              approachedForCompanyEPD={supplier?.approachedForCompanyEPD}
              countries={countries}
              deleteFile={deleteFile}
              handleSubmitValues={handleSubmitValues}
              initialValues={{
                name: supplier?.name,
                code: supplier?.code,
                address: supplier?.address,
                postalCode: supplier?.postalCode,
                city: supplier?.city,
                country,
                documentType: supplier?.documentType,
                approachedBy: supplier?.approachedBy ? types[0] : types[1],
              }}
              isEdit={isEdit}
              logo={logo}
              onDeleteItem={onDeleteItem}
              pathname={pathname}
            />
          </S.FormContainer>

          <MainModal
            confirmationModalInfo={confirmationModal}
            content="delete"
            isModalOpen={confirmationModal}
            setIsModalOpen={setConfirmationModal}
          />
        </div>
      </Placeholder>
    </S.Background>
  );
};

export default NewSupplier;
