import { createGlobalStyle } from 'styled-components/macro';
import * as Style from 'config/style';
import 'react-toastify/dist/ReactToastify.min.css';
import { whiteButton, underlineButton } from 'components/Button/Button.styled';
import IBMPlexMonoRegular from '../fonts/IBMPlexMono-Regular.ttf';

export const theme = {
  // Colors
  primary: Style.YELLOW,

  // Typography
  baseFont: `'Poppins', -apple-system, BlinkMacSystemFont,
    Roboto,"Helvetica Neue", Arial, "Noto Sans",sans-serif,
    "Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"`,
};

export default createGlobalStyle`
  @font-face {
    font-family: "IBM-Plex-Mono";
    src: local("IBMPlexMono-Regular"), url(${IBMPlexMonoRegular}) format("truetype");
  }

  * {
    padding: 0;
    box-sizing: border-box;
    font-family: ${theme.baseFont};
  }

  :not(.ck, .with-p-margins) > * {
    margin: 0;
  }


  a {
    text-decoration: none;
    color: ${Style.DARK_BLUE};

    &:hover {
      text-decoration: none;
    }
  }


  *:focus {
    outline: none;
  }

  :root, html, body {
    height: 100%;
  }

  :root, html, body, #root {
    --theme: ${({ theme: t }) => t.primary};
    --textColor: ${({ theme: t }) => t.textColor};
    font-family: ${({ theme: t }) => t.baseFont};
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 0 1 100%;
  }

  a:hover:not(.button) {
    text-decoration: inherit;
  }

  fieldset {
    border: 0;
  }

  .ReactModal__Overlay {
    opacity: 0;
    z-index: 998;
    transition: opacity 200ms ease-in-out;
    will-change: opacity;
  }

  .ReactModal__Overlay--after-open{
    opacity: 1;
    will-change: opacity;
  }

  .ReactModal__Overlay--before-close{
    opacity: 0;
    will-change: opacity;
  }

  .ReactModal__Content {
    overflow-y: auto;
    z-index: 999;
    max-height: 90vh;
    transform: translate3d(0, 100%, 0);
    transition: transform 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  .ReactModal__Content--after-open {
    will-change: transform;
    transform: translate3d(0, 0, 0);
  }

  .swal-overlay {
    background-color: ${Style.backdrop};
    will-change: opacity;
  }

  .swal-content {
    margin: 0;
    padding: 0;
    &:last-child {
      margin: 0;
    }
  }

  .swal-modal.swal-modal.swal-modal {
    box-shadow: 0 2px 5px rgba(57, 57, 57, 0.4);
    background: #ffffff;
    min-width: 10vw;
    padding: 1.68rem 2.37rem;
    border-radius: 0;
    text-align: left;
    animation: none;
    transition: opacity .1s;
    will-change: opacity;
    width: auto;
    margin: 0;
  }

  .swal-title {
    text-align: left;
  }

  .swal-button {
    padding: .75rem 1.25rem;
    font-size: 14px;
    border: 1pt solid transparent;
    border-radius: 0;
  }

  .swal-button--confirm:not([disabled]) {
    ${whiteButton}

    &.swal-button--danger {
      ${whiteButton}
    }
  }

  .swal-button--cancel:not([disabled]) {
    ${underlineButton}
  }

  .pulse {
    will-change: opacity;
    opacity: 1;
    animation-name: pulse;
    animation-duration: .5s;
    animation-direction: alternate;
    animation-iteration-count: infinite;
  }

  @keyframes pulse {
    from { opacity: 1; }
    to { opacity: .1; }
  }

  .infinite-scroll-component {
    //EPD-706
    overflow: visible !important;
  }
`;
