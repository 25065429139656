/* eslint-disable react/destructuring-assignment */
import React, { memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Link, Route, useLocation, NavLink, useParams,
} from 'react-router-dom';
import { useSelector } from 'react-redux';
import mapParentTree from 'utils/mapParentTree';
import correctPaths from 'utils/correctPaths';
import * as S from './Breadcrumb.styled';

const Breadcrumb = ({ foldersList }) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { folderId } = useParams();
  const { folders } = useSelector(s => s.calculations);
  const breadcrumb = useSelector(s => s.sidebar);
  const user = useSelector(s => s.user);
  const [treeFromStorage, setTreeResult] = React.useState([]);

  const calculationFromStorage = breadcrumb?.calculation;

  const calcId = calculationFromStorage?.folder?.id;

  useEffect(() => {
    if (foldersList) {
      const foldersValues = mapParentTree(foldersList, folderId || calcId);
      setTreeResult(foldersValues);
    }
  }, [calcId, folderId, folders, foldersList]);

  const isNewCalculation = (pathname.includes('calculations') && pathname.includes('new')
    && !pathname.includes('predefined-input-sets')
    && !pathname.includes('templates')
    && !pathname.includes('import'));

  const BreadcrumbsItem = (props) => {
    const routeName = breadcrumb?.routeName;

    const { path } = props.match.params;

    let name = isNaN(path) ? path : routeName;

    const calculations = pathname.includes('calculations') || (name === 'calculation');
    const newCalculation = pathname.includes('calculations') && (name === 'new-calculation');

    if ((user?.selectedMembership?.view === 'Company Admin') && (name === 'companies')) name = null;

    if (name) name = t(name === 'company' ? 'company.label' : name);

    // eslint-disable-next-line no-param-reassign
    if (props.match.isExact) props.location.pathname = props?.initialPath;

    const link = !props.match.isExact ? props.match.url || '' : {};

    return (
      <>
        <div className={props.match.isExact ? 'breadcrumb-active' : undefined}>
          {((!calculations || newCalculation) || pathname.includes('calculations/import')
          || pathname.includes('calculations/predefined-input-sets')
          || pathname.includes('calculations/templates'))
            && name
            && (
              <>
                <span> &gt; </span>

                <Link to={props.match.url === '/calculation' ? '/calculations/overview' : link}>
                  {name}
                </Link>
              </>
            )}
        </div>

        <Route
          component={params => (
            <BreadcrumbsItem
              {...params}
              initialPath={props?.initialPath}
            />
          )}
          path={`${props.match.url}/:path`}
        />
      </>
    );
  };

  const reviewer = user?.selectedMembership?.roleName === 'ROLE_REVIEWER';

  return (
    <div>
      <S.Wrapper>
        <NavLink to="/">
          {t('dashboard')}
        </NavLink>

        {pathname.includes('calculation') && !pathname.includes('calculations/import')
          && !pathname.includes('calculations/predefined-input-sets')
          && !pathname.includes('calculations/templates') && (
          <>
            <span> &gt; </span>

            <NavLink to={reviewer ? '/reviews' : '/calculations/overview'}>
              {reviewer ? t('reviews') : t('calculations')}
            </NavLink>

            {treeFromStorage?.length && (folderId || calcId)
              && !pathname.includes('calculations/root')
              ? treeFromStorage?.map(v => <Folder key={v?.id} folder={v} />)
              : null}
          </>
        )}

        {(isNewCalculation || !pathname.includes('calculations/root')) && (
        <Route
          component={(props) => {
            const initialPath = props?.location?.pathname;
            /* eslint-disable no-param-reassign */
            if (isNewCalculation) props.location.pathname = correctPaths['/calculations/new'];
            else if (!pathname.includes('user-profile')
              && pathname.includes('memberships')
              && pathname.includes('edit')) {
              const userId = pathname?.split('/')[3];
              props.location.pathname = `/manage/users/${userId}/memberships/edit`;
            } else {
              const correctPath = correctPaths[props.location.pathname];
              if (correctPath) props.location.pathname = correctPath;
            }
            /* eslint-enable no-param-reassign */
            return <BreadcrumbsItem {...props} initialPath={initialPath} />;
          }}
          path="/:path"
        />
        )}
      </S.Wrapper>
    </div>
  );
};

const Folder = ({ folder: { id: folderId, name } }) => (
  <div>
    <span> &gt; </span>

    <NavLink to={`/calculations/${folderId}`}>
      {name}
    </NavLink>
  </div>
);

Breadcrumb.defaultProps = {
  folderId: null,
  folders: {},
};

export default memo(Breadcrumb);
