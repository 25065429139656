import styled from 'styled-components/macro';
import { NAV_HEIGHT, AsideWidth } from 'config/style';

export const Container = styled.div`
  height: calc(100vh - ${NAV_HEIGHT});
  position: relative;
`;

export const Wrapper = styled.div`
  height: calc(100vh - (2 * ${NAV_HEIGHT}));
  display: flex;
`;

export const LeftPane = styled.aside`
  width: ${AsideWidth};
  height: calc(100vh - (2 * ${NAV_HEIGHT}));
  overflow-y: auto;
  overflow-x: visible;
  transform: translate(${(props) => (props.isVisible ? 0 : `calc(-${AsideWidth} + 2rem)`)});
  transition: 1s;
  box-shadow: 0 4px 14px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 1;
`;

export const MainPane = styled.main`
  position: absolute;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  right: 0;
  left: ${(props) => (props.isVisible ? AsideWidth : '2rem')};
  height: calc(100vh - (2 * ${NAV_HEIGHT}));
  transition: 1s;
  background: white;
  overflow: auto;
`;

export const HideAside = styled.aside`
  width: 2.4rem;
  height: 2.4rem;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  cursor: pointer;
  position: absolute;
  z-index: 5;
  bottom: 7%;
  left: ${(props) => (props.isVisible ? `calc(${AsideWidth} - 1.3rem)` : '1rem')};
  box-shadow: 4px 0 14px rgba(0, 0, 0, 0.2);
  transition: 1s;

`;

export const AsideArrow = styled.img`
  transform: rotate(${(props) => (props.isVisible ? 0 : '180deg')});
  transition: 1s;
`;

export const FooterWrapper = styled.div`
  padding: 1.4rem 2.75rem;
  border-top: 1px solid #e4e4e4;
`;

export const BarWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e4e4e4;
  padding: 0.8rem 1.3rem 0.8rem 2.5rem;
  height: ${NAV_HEIGHT};
  background: white;
  position: relative;
  z-index: 9;
`;

export const ButtosContainer = styled.div`
  display: flex;
  align-items: center;
`;
