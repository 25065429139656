import _ from 'lodash';
import { errorToast } from 'config/toast';

import network from './network';

const paginatedMember = ({
  'hydra:member': data,
  'hydra:view': view,
  'hydra:totalItems': totalItems,
}) => ({
  get profiles() {
    return this.data;
  },
  get wastescenarios() {
    return this.data;
  },
  data,
  nextPage: view && view['hydra:next'],
  totalItems,
});

export const all = (params) => network
  .get('/environmental_profiles', { params: { ...params, visible: true } })
  .then(paginatedMember)
  .catch((err) => errorToast(err));

export const getNextPageProfiles = (url) => network
  .get(`${process.env.REACT_APP_API_URL}${url}`, { params: { owner: 'none' } })
  .then(paginatedMember)
  .catch((err) => errorToast(err));

export const allSections = (params) => network
  .get('/sections', { params })
  .then((r) => ({
    get sections() {
      return this.data;
    },
    data: _.map(r['hydra:member'], (section) => ({
      ...section,
      label: section.name,
      value: section.name,
    })),
    nextPage: r['hydra:view'] && r['hydra:view']['hydra:next'],
    totalItems: r['hydra:totalItems'],
  }))
  .catch((err) => errorToast(err));

export const getNextPageAllSections = (url) => network
  .get(`${process.env.REACT_APP_API_URL}${url}`, { params: { owner: 'none' } })
  .then((r) => ({
    get sections() {
      return this.data;
    },
    data: _.map(r['hydra:member'], (section) => ({
      ...section,
      label: section.name,
      value: section.name,
    })),
    nextPage: r['hydra:view'] && r['hydra:view']['hydra:next'],
    totalItems: r['hydra:totalItems'],
  }))
  .catch((err) => errorToast(err));

export const getProfileById = (params) => network
  .get(`/environmental_profiles/${params.id}`)
  .catch((err) => errorToast(err));

export const environmentalProfileEffects = ({ id }) => network
  .get(`/profiles/${id}/effects`)
  .catch((err) => errorToast(err));

export const getSectionById = ({ id }) => network
  .get(`/sections/${id}`)
  .catch((err) => errorToast(err));

export const getOwnerById = ({ id }) => network
  .get(`/companies/${id}`)
  .catch((err) => errorToast(err));

export const getAllWasteScenarios = (params) => network
  .get('/waste_scenarios', { params: { ...params, visible: true } }).then(paginatedMember)
  .catch((err) => errorToast(err));

export const getNextPageWasteScenarios = (url) => network
  .get(`${process.env.REACT_APP_API_URL}${url}`, { params: { owner: 'none' } })
  .then(paginatedMember)
  .catch((err) => errorToast(err));

export const getWasteScenarioById = (params) => network
  .get(`/waste_scenarios/${params.id}`)
  .catch((err) => errorToast(err));

export const getNextPageTransport = (url) => network
  .get(`${process.env.REACT_APP_API_URL}${url}`, { params: { owner: 'none' } })
  .then((r) => ({
    get transportprofiles() {
      return this.data;
    },
    data: _.map(r['hydra:member'], (profile) => ({ value: profile.id, label: profile.name })),
    nextPage: r['hydra:view'] && r['hydra:view']['hydra:next'],
    totalItems: r['hydra:totalItems'],
  }))
  .catch((err) => errorToast(err));

export const transport = (params) => network
  .get('/transport_profiles', { params })
  .then((r) => ({
    get transportprofiles() {
      return this.data;
    },
    data: _.map(r['hydra:member'], (profile) => ({
      ...profile,
      value: profile.id,
      label: profile.name,
    })),
    nextPage: r['hydra:view'] && r['hydra:view']['hydra:next'],
    totalItems: r['hydra:totalItems'],
  }))
  .catch((err) => errorToast(err));

export const transportById = (id) => network
  .get(`/transport_profiles/${id}`, { cache: true })
  .catch((err) => errorToast(err));

export const waste = () => network
  .get('/wasteprofiles', { cache: true })
  .catch((err) => errorToast(err));

export const bulk = () => Promise.resolve(null);
// Note: at current date, it doesn't exist in API.
