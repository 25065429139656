/* eslint-disable no-underscore-dangle */
import axios from 'axios';
import { cacheAdapterEnhancer, throttleAdapterEnhancer } from 'axios-extensions';
import { createBrowserHistory } from 'history';
import { store } from 'state';
import * as types from 'state/auth/auth.types';
/* import { isUsingProxy } from './proxy'; */

export const history = createBrowserHistory();

const cacheAdapter = throttleAdapterEnhancer(
  cacheAdapterEnhancer(axios.defaults.adapter), // defaults: { maxAge: FIVE_MINUTES, max: 100 }
  { threshold: 4000 }, // 4s in milliseconds
);

const Config = {
  baseURL: `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}`,
  crossdomain: true,
  headers: {
    Accept: 'application/ld+json, application/json',
    'Content-Type': 'application/json',
  },
};

const instance = axios.create(Config);

instance.interceptors.request.use((req) => {
  const { auth, user, company } = store.getState();
  const { userToken } = auth;
  const { selectedMembership } = user || {};
  const { id: companyId } = company || {};

  if (selectedMembership && !req?.removeNibeHeaders) {
    req.headers = {
      ...req.headers,
      'Nibe-CompanyId': selectedMembership?.company?.id,
      'Nibe-View': selectedMembership?.view,
      'Nibe-UserId': selectedMembership?.user?.id,
      'Nibe-MembershipId': selectedMembership?.id,
    };
  }

  /* if (isUsingProxy(req?.url)) {
    req.baseURL = process.env.REACT_APP_MOCK_API_URL;
    req.url = req.url?.replace(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}`,
      process.env.REACT_APP_MOCK_API_URL
    );
  } */

  if (req?.fileHeader) {
    req.headers = { ...req.headers, ...req.fileHeader };
  }

  if (userToken) {
    req.headers.Authorization = `Bearer ${userToken}`;
  }

  if (companyId && !req.params?.owner) {
    req.params = { ...req.params, owner: companyId };
  }

  if ((req.params?.owner === 'none')) {
    delete req.params.owner;
  }

  if (req.cache) {
    req.adapter = cacheAdapter;
  }

  return req;
});

instance.interceptors.response.use(
  ({ data }) => data,
  // eslint-disable-next-line consistent-return
  async (error) => {
    const originalRequest = error?.config;
    if (error?.response?.status === 401 && !originalRequest._retry
      && !originalRequest?.url?.includes('login_check')) {
      originalRequest._retry = true;
      const { auth } = store.getState();

      try {
        const res = await axios.post(
          `${process.env.REACT_APP_API_URL}/token/refresh`,
          { refreshToken: auth?.refreshToken },
        );

        if (res) {
          originalRequest.headers.Authorization = `Bearer ${res?.data?.token}`;
          store.dispatch({
            type: types.REFRESH,
            payload: {
              userToken: res?.data?.token,
              refreshToken: res?.data?.refreshToken,
            },
          });
          const axiosRequest = await axios(originalRequest);
          return axiosRequest?.data;
        }
      } catch (err) {
        if (err?.response?.status === 401) history.push('/logout');
      }
    } else throw error;
  },
);

export default instance;
