import styled from 'styled-components';

export const Wrapper = styled.ul`
  background: white;
  min-height: 15rem;
`;

export const Row = styled.li`
  padding: .9rem 2rem;
  font-size: .875rem;
  border-bottom: 1px solid #ebebeb;

  &:last-child {
    margin-bottom: -1px;
  }

  ul, ol {
    margin-left: 1rem;
  }
`;

export const Data = styled.p`
  font-size: .75rem;
  font-weight: 300;
  margin-top: 4px;
`;

export const Center = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 15rem;
`;
