import React, { useState } from 'react';
import Button from 'components/Button/Button';
import { useTranslation } from 'react-i18next';

import { ButtonsWrapper } from '../styled';

const Delete = ({
  executeAction,
  discardAction,
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  return (
    <div>
      <p style={{ marginBottom: '1.2rem' }}>
        {t('this action cannot be undone')}
      </p>

      <ButtonsWrapper>
        <Button
          onClick={discardAction}
          style={{ marginRight: '1rem' }}
          styling="underline">
          {t('cancel')}
        </Button>

        <Button
          loading={loading}
          onClick={() => {
            setLoading(true);
            executeAction(() => setLoading(false));
          }}
          style={{ margin: 0 }}
          styling="white">
          {t('confirm')}
        </Button>
      </ButtonsWrapper>
    </div>
  );
};

export const confirmationModalSetup = (setConfirmation, executeRequest) => {
  const confirmationModalData = {
    executeAction: (stopLoading) => executeRequest(() => {
      stopLoading();
      setConfirmation(null);
    }),
    discardAction: () => setConfirmation(null),
  };
  setConfirmation(confirmationModalData);
};

export default Delete;
