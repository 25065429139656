import i18next from 'i18next';

export const licences = [
  {
    value: 2,
    get label() { return i18next.t('master licence'); },
  },
  {
    value: 3,
    get label() { return i18next.t('professional licence'); },
  },
];

export const reviewerCosts = [
  {
    value: false,
    get label() { return i18next.t('no'); },
  },
  {
    value: true,
    get label() { return i18next.t('yes'); },
  },
];
