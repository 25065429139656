import styled from 'styled-components';

export const ValueSelect = styled.div`
  display: flex;
  align-items: center;

  p {
    margin-left: 1rem;
  }
`;

export const RadioContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2.13rem;
`;
