import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAsyncCallback } from 'react-async-hook';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useTranslation } from 'react-i18next';

import { versions } from 'services';

import * as S from 'routes/Calculations/Overview.styled';
import { Placeholder, Loading } from 'components/Splash/Loading';
import ListItem from 'components/ListItem/ListItem';
import TopBar from 'components/TopBar/TopBar';
import LastChangedStatus from 'components/TopBar/LastChangedStatus';
import Name from 'components/TopBar/Name';

const Updates = () => {
  const { selectedMembership } = useSelector(state => state.user);
  const [updates, setUpdates] = useState(null);
  const [page, setPage] = useState(null);
  const [sorting, setSorting] = useState(undefined);
  const [createdAt, setCreatedAt] = useState('desc');
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const result = useAsyncCallback(() => {
    const params = {};

    if (sorting) params['order[versionName]'] = sorting;
    if (createdAt) params['order[createdAt]'] = createdAt;

    setLoading(true);
    return versions
      .all({ params }, selectedMembership?.company['@id'])
      .then(values => {
        setPage(values.nextPage);
        setUpdates(values.versions);
        return values;
      })
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    result.execute();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sorting, createdAt, selectedMembership]);

  const fetchData = () => {
    if (!page) return undefined;
    return versions
      .getNextPageVersions(page)
      .then((values) => {
        setPage(values.nextPage);
        setUpdates(updates.concat(values.versions));
        return values;
      });
  };

  const updatesList = () => (
    <S.OverviewRows id="overviewRowsId">
      <InfiniteScroll
        dataLength={updates?.length}
        hasMore={page}
        loader={Loading}
        next={fetchData}
        scrollableTarget="overviewRowsId"
        scrollThreshold={0.4}>
        {_.map(updates, (data) => {
          let name = '';
          if (data?.versionName) {
            name = `${(data?.public
              ? 'Global' : 'Company')} database update - ${data?.versionName}`;
          } else name = `${(data?.public ? 'Global' : 'Company')} database update`;
          return (
            <ListItem
              key={data.id}
              lastChanged={data?.createdAt}
              name={name}
              to={{
                pathname: `/updates/${data.id}`,
                state: {
                  routeName: name,
                },
              }}
            />
          );
        })}
      </InfiniteScroll>
    </S.OverviewRows>
  );

  return (
    <>
      <TopBar>
        <Name
          inverted
          onOrderBy={() => {
            if (!sorting) setSorting('asc');
            else if (sorting === 'asc') setSorting('desc');
            else setSorting(null);
          }}
          order={sorting}
        />

        <LastChangedStatus
          inverted
          label={t('date')}
          onOrderBy={() => {
            if (!createdAt || createdAt === 'desc') setCreatedAt('asc');
            else if (createdAt === 'asc') setCreatedAt('desc');
          }}
          order={createdAt}
        />
      </TopBar>

      <Placeholder loaders={[loading]}>
        {updates?.length ? updatesList() : 'No items to show'}
      </Placeholder>
    </>
  );
};

export default Updates;
