import React from 'react';

const FinalRejected = ({ color, ...props }) => (
  <svg fill="none" height="16" viewBox="0 0 13 16" width="13" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      // eslint-disable-next-line max-len
      d="M12.2216 6.54461H11.3653V4.63499C11.3653 2.07648 9.18563 0 6.5 0C3.81437 0 1.63473 2.07648 1.63473 4.63499V6.54461H0.778443C0.350299 6.54461 0 6.87833 0 7.28621V15.2584C0 15.6663 0.350299 16 0.778443 16H12.2216C12.6497 16 13 15.6663 13 15.2584V7.28621C13 6.87833 12.6497 6.54461 12.2216 6.54461ZM3.19162 4.63499C3.19162 2.89224 4.67066 1.4832 6.5 1.4832C8.32934 1.4832 9.80838 2.89224 9.80838 4.63499V6.54461H3.19162V4.63499ZM11.4431 14.5353H1.55689V8.04635H11.4431V14.5353ZM5.13772 10.6419C5.13772 9.91889 5.74102 9.34415 6.5 9.34415C7.25898 9.34415 7.86228 9.91889 7.86228 10.6419C7.86228 11.0684 7.6482 11.4577 7.27844 11.6987V12.4959C7.27844 12.9038 6.92814 13.2375 6.5 13.2375C6.07186 13.2375 5.72156 12.9038 5.72156 12.4959V11.6987C5.3518 11.4577 5.13772 11.0684 5.13772 10.6419Z"
      fill={color || '#BF2E00'}
    />
  </svg>
);

export default FinalRejected;
