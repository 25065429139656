import _ from 'lodash';
import React from 'react';
import { useAsync } from 'react-async-hook';
import swal from '@sweetalert/with-react';

import { reviews } from 'services';

import Select from 'components/Select/Select';
import { Heading, ButtonSet } from 'components/Modal';

const getIRI = ({ '@id': id }) => id;
const getLabel = ({ name }) => name;

const Submit = ({ title, onConfirm }) => {
  const reviewers = useAsync(() => reviews.getReviewers({ params: { reviewCompany: true } }), []);
  const options = _.sortBy(reviewers?.result, c => c?.name?.toLowerCase()) || [];
  const [loading, setLoading] = React.useState(false);
  const [hasValue, setHasValue] = React.useState(false);

  const handleConfirm = () => {
    setLoading(true);
    return onConfirm(hasValue)
      .finally(() => {
        swal.stopLoading();
        setLoading(false);
        swal.close();
      });
  };

  const changeValue = value => {
    swal.setActionValue(getIRI(value));
    setHasValue(getIRI(value));
  };

  return (
    <div style={{ minWidth: '20rem' }}>
      <Heading loading={loading || reviewers.loading} title={title} />

      <Select
        getOptionLabel={getLabel}
        getOptionValue={getIRI}
        isLoading={reviewers.loading}
        onChange={changeValue}
        options={options}
      />

      <ButtonSet
        disabled={!hasValue || reviewers.loading}
        loading={loading}
        onConfirm={handleConfirm}
      />
    </div>
  );
};

export default Submit;
