import i18n from 'i18next';

// amountScalingDimensions
export const availableAmounts = [{ value: 1 }, { value: 2 }];

export const functionalUnitsOptions = [
  { value: 'cm', label: 'cm' },
  { value: 'm', label: 'm' },
  { value: 'cm2', label: 'cm2' },
];

export const scalingOptions = [
  { value: 'none', get label() { return i18n.t('none'); } },
  { value: 'linear', get label() { return i18n.t('linear'); } },
];

export const wasteMethodOptLabels = {
  get per_raw_material_kg() { return 'Amount per raw material'; },
  get per_raw_material_percentage() { return '% per raw material'; },
  get based_on_production_site() { return 'Total per product'; },
  get overall_percentage() { return 'Overall % of the product'; },
};

export const productionWasteInputMethodsOptions = [
  'per_raw_material_kg',
  'per_raw_material_percentage',
  'based_on_production_site',
  'overall_percentage',
];

export const wasteScenarioInputMethodsOpt = [
  { value: 'per_raw_material_kg', label: 'Amount per raw material' },
  { value: 'per_raw_material_percentage', label: '% per raw material' },
  { value: 'based_on_production_site', label: 'Total per product' },
  { value: 'overall_percentage', label: 'Overall % of the product' },
];

export const predefinedConstructionWasteOpt = [
  { value: 3, label: 'Prefab products (3%)' },
  { value: 5, label: 'In-situ products (5%)' },
  { value: 15, label: 'Auxiliary and finishing materials (15%)' },
  { value: 0, label: 'Custom percentage of construction waste/dropout' },
];
