import _ from 'lodash';
import React, { useContext, useState } from 'react';
import {
  Field, Form, formValues, reduxForm,
} from 'redux-form';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useAsync } from 'react-async-hook';

import { inputs as inputService, locations as locationsServices, profiles } from 'services';

import { Calculation } from 'hooks/calculation.hooks';
import Button from 'components/Button/Button';
import { FormInput } from 'components/Input/Input';
import EnviromnentProfilePicker from 'components/EnviromnentProfiles';
import { FormTextArea } from 'components/TextArea/TextArea';
import { FormAsyncSelect, FormSelect } from 'components/Select/Select';
import MainModal from 'components/Modal';
import * as S from 'routes/GlobalStyle.styled';
import { Placeholder } from 'components/Splash/Loading';
import * as v from './validation';
import { useInputsContext } from '../../../../hooks/input.hooks';

const FORM_NAME = 'add-input';

export const AddInputForm = props => {
  const {
    handleSubmit, submitting, saveForm, reset, stage, scalable, pristine, change,
    dataQualityOptions, productionWasteInputMethod, environmentalProfile, productionWasteName,
    wasteScenario, wasteScenarioProductionWaste, addAnother, wasteOptions,
    inputResult, wasteScenarioEndOfLife,
    wasteScenarioProductionWasteSelected, predefinedInputSet,
  } = props;

  const calculation = useContext(Calculation);
  const inputs = useInputsContext();
  const { pathname } = useLocation();
  const { isHidden } = useSelector(state => state.sidebar);
  const companyName = useSelector(state => state.user.selectedMembership.company.name);
  const { location } = calculation.result || {};
  const { t } = useTranslation();
  const h = useHistory();
  const { id } = useParams();
  const [hasEditedForm, setHasEditedForm] = useState(false);
  const [saveSpinner, setSaveSpinner] = useState(false);
  const [saveContinueSpinner, setSaveContinueSpinner] = useState(false);
  const [envProfile, setEnvProfile] = useState(null);
  const [page, setPage] = useState(null);
  const [pagePdis, setPagePdis] = useState(null);
  const [locationsList, setLocationsList] = useState(null);
  const [searchProductionWaste, setSearchProductionWaste] = useState(null);
  const [searchEndOfLife, setSearchEndOfLife] = useState(null);
  const [wasteScenarioProductionWasteList, setWasteScenarioProductionWasteList] = useState(null);
  const [wasteScenarioEndOfLifeList, setWasteScenarioEndOfLifeList] = useState(null);
  const [wasteScenarioProductionWastePage, setWasteScenarioProductionWastePage] = useState(null);
  const [wasteScenarioEndOfLifePage, setWasteScenarioEndOfLifePage] = useState(null);
  const [pdis, setPdis] = useState(null);
  const [searchPdis, setSearchPdis] = useState(null);
  const [searchSuppliers, setSearchSuppliers] = useState(null);
  const isEdit = pathname?.includes('edit');
  const scenarios = useAsync(() => profiles
    .getAllWasteScenarios({ owner: 'none', itemsPerPage: 30, 'order[name]': 'asc' })
    .then(res => {
      const data = _.sortBy(res.data, w => w?.name?.toLowerCase());
      setWasteScenarioProductionWasteList(data);
      setWasteScenarioEndOfLifeList(data);
      return res;
    }), []);

  const methodId = calculation.result?.template?.method?.id || calculation.result?.method?.id;

  const pdisResult = useAsync(
    () => inputService.getPdis({ itemsPerPage: 30, method: methodId })
      .then(res => {
        setPdis(res.data);
        return res;
      }),
    [],
  );

  const supps = useAsync(() => locationsServices
    .transports({ location: location?.['@id'], 'order[name]': 'asc', itemsPerPage: 30 })
    .then(res => {
      const suppliersFromTransports = _.sortBy(
        _.uniqBy(res.data?.map(tr => tr?.supplier), 'id'), (s) => s?.name?.toLowerCase(),
      );
      setLocationsList(suppliersFromTransports);
      setPage(res.nextPage);
    }), []);

  const predefinedWasteScenario = envProfile?.wasteScenarios && scenarios?.result?.data
    ? _.find(scenarios?.result?.data, s => (s && s['@id'])
      === (envProfile?.wasteScenarios && envProfile?.wasteScenarios[0]))
    : null;

  if ((!wasteScenario || !wasteScenarioProductionWaste) && predefinedWasteScenario) {
    if (!wasteScenario) change('wasteScenario', predefinedWasteScenario);
    if (!wasteScenarioProductionWaste) {
      change('wasteScenarioProductionWaste', predefinedWasteScenario);
    }
  }

  const profileLoader = useAsync(async () => {
    if ((!wasteScenario || !wasteScenarioProductionWaste)
      && envProfile?.wasteScenarios && envProfile.wasteScenarioNeeded) {
      let scenario = envProfile?.wasteScenarios && scenarios?.result?.data
        ? _.find(scenarios?.result?.data, s => (s && s['@id'])
          === (envProfile?.wasteScenarios && envProfile?.wasteScenarios[0]))
        : null;

      if (!scenario) {
        // Get waste scenario from backend
        const scenarioId = envProfile.wasteScenarios[0].split('/').slice(-1)[0];
        scenario = await profiles.getWasteScenarioById({ id: scenarioId }).then(result => {
          setWasteScenarioProductionWasteList(l => _.sortBy(
            _.uniqBy(l.concat(result), 'id'), w => w?.name?.toLowerCase(),
          ));
          setWasteScenarioEndOfLifeList(l => _.sortBy(
            _.uniqBy(l.concat(result), 'id'), w => w?.name?.toLowerCase(),
          ));
          return result;
        });
      }

      if (!wasteScenario) change('wasteScenario', scenario);
      if (!wasteScenarioProductionWaste) {
        change('wasteScenarioProductionWaste', scenario);
      }
    }
  }, [envProfile?.wasteScenarioNeeded, wasteScenario, wasteScenarioProductionWaste]);

  React.useEffect(() => {
    // if not first render, when user changes env profile, clear wastes EPD-814
    if (envProfile) {
      change('wasteScenario', '');
      change('wasteScenarioProductionWaste', '');
    }
    if (!environmentalProfile?.wasteScenarios && environmentalProfile?.id
      && !environmentalProfile?.unit) {
      profiles.getProfileById({ id: environmentalProfile?.id }).then(result => {
        change('environmentalProfile', result);
        setEnvProfile(result);
      });
    } else setEnvProfile(environmentalProfile);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [environmentalProfile]);

  const save = handleSubmit(data => {
    setSaveSpinner(true);
    setHasEditedForm(true);
    return saveForm(data)
      .then(() => {
        if (inputs.isPdis) h.push(`/calculations/predefined-input-sets/${inputs.calculationId}/inputs`);
        else h.push(`/calculation/${id}/inputs`);
      });
  });
  const saveAndReset = handleSubmit(data => {
    setSaveContinueSpinner(true);
    setHasEditedForm(true);
    return saveForm(data)
      .then(r => {
        reset();
        return r;
      })
      .finally(() => setSaveContinueSpinner(false))
      .then(() => addAnother());
  });

  const loadPageOptions = async (searchValue) => {
    console.log(searchValue, page);
    if (searchValue && (searchSuppliers !== searchValue)) {
      setSearchSuppliers(searchValue);
      const searchResult = await locationsServices
        .transports({
          location: location?.['@id'],
          'supplier.name': searchValue,
          'order[name]': 'asc',
          itemsPerPage: 30,
        });
      const suppliersFromTransports = _.sortBy(
        _.uniqBy(searchResult.data?.map(tr => tr?.supplier), 'id'), (s) => s?.name?.toLowerCase(),
      );
      setLocationsList(suppliersFromTransports);
      setPage(searchResult.nextPage);
      return {
        options: suppliersFromTransports || [],
        hasMore: searchResult.nextPage,
      };
    }
    if (page) {
      const { data: values, nextPage } = await locationsServices.getNextPageLocations(page);
      const suppliersFromTransports = _.sortBy(
        _.uniqBy(values?.map(tr => tr?.supplier), 'id'), (s) => s?.name?.toLowerCase(),
      );
      setLocationsList(suppliersFromTransports);
      setPage(nextPage);
      return {
        options: suppliersFromTransports || [],
        hasMore: nextPage,
      };
    }
    return {
      options: [],
      hasMore: page,
    };
  };

  const loadPageOptionsPdis = async (searchValue) => {
    if (searchValue && (searchPdis !== searchValue)) {
      setSearchPdis(searchValue);
      const searchResult = await inputService
        .getPdis({ search: searchValue, itemsPerPage: 30, method: methodId });
      setPdis(searchResult.data);
      setPagePdis(searchResult.nextPage);
      return {
        options: searchResult.data || [],
        hasMore: searchResult.nextPage,
      };
    }
    if (pagePdis) {
      const values = await inputService.getNextPageInputs(pagePdis);
      setPdis(values.data);
      setPagePdis(values.nextPage);
      return {
        options: values.data || [],
        hasMore: values.nextPage,
      };
    }
    if (!pagePdis) setPagePdis(pdisResult?.result?.nextPage);
    return {
      options: [],
      hasMore: pagePdis || pdisResult?.result.nextPage,
    };
  };

  const loadPageOptionsProductionWaste = async (searchValue) => {
    if (searchValue && (searchProductionWaste !== searchValue)) {
      setSearchProductionWaste(searchValue);
      const searchResult = await profiles
        .getAllWasteScenarios({
          owner: 'none', search: searchValue, itemsPerPage: 30, 'order[name]': 'asc',
        });
      const data = _.sortBy(searchResult.data, w => w?.name?.toLowerCase());
      setWasteScenarioProductionWasteList(data);
      setWasteScenarioProductionWastePage(searchResult.nextPage);
      return {
        options: data || [],
        hasMore: searchResult.nextPage,
      };
    }
    if (wasteScenarioProductionWastePage) {
      const values = await profiles.getNextPageWasteScenarios(wasteScenarioProductionWastePage);
      const data = _.sortBy(values.data, w => w?.name?.toLowerCase());
      setWasteScenarioProductionWasteList(data);
      setWasteScenarioProductionWastePage(values.nextPage);
      return {
        options: data || [],
        hasMore: values.nextPage,
      };
    }
    if (!wasteScenarioProductionWastePage) {
      setWasteScenarioProductionWastePage(scenarios?.result?.nextPage);
    }
    return {
      options: wasteScenarioProductionWasteList,
      hasMore: wasteScenarioProductionWastePage || scenarios?.result?.nextPage,
    };
  };

  const loadPageOptionsEndOfLife = async (searchValue) => {
    if (searchValue && (searchEndOfLife !== searchValue)) {
      setSearchEndOfLife(searchValue);
      const searchResult = await profiles
        .getAllWasteScenarios({
          owner: 'none', search: searchValue, itemsPerPage: 30, 'order[name]': 'asc',
        });
      const data = _.sortBy(searchResult.data, w => w?.name?.toLowerCase());
      setWasteScenarioEndOfLifeList(data);
      setWasteScenarioEndOfLifePage(searchResult.nextPage);
      return {
        options: data || [],
        hasMore: searchResult.nextPage,
      };
    }
    if (wasteScenarioEndOfLifePage) {
      const values = await profiles.getNextPageWasteScenarios(wasteScenarioEndOfLifePage);
      const data = _.sortBy(values.data, w => w?.name?.toLowerCase());
      setWasteScenarioEndOfLifeList(data);
      setWasteScenarioEndOfLifePage(values.nextPage);
      return {
        options: data || [],
        hasMore: values.nextPage,
      };
    }
    if (!wasteScenarioEndOfLifePage) setWasteScenarioEndOfLifePage(scenarios?.result?.nextPage);
    return {
      options: wasteScenarioEndOfLifeList,
      hasMore: wasteScenarioEndOfLifePage || scenarios?.result?.nextPage,
    };
  };

  let wasteScenarioProductionWasteFormField = wasteScenarioProductionWasteSelected;
  let wasteScenarioFormField = wasteScenarioEndOfLife;

  if (environmentalProfile?.wasteScenarioNeeded
    && !wasteScenario && !wasteScenarioProductionWaste) {
    const predefinedWasteScenarioTemp = _.find(wasteOptions, s => (s?.['@id']
      === environmentalProfile?.wasteScenarios?.[0]));

    wasteScenarioProductionWasteFormField = predefinedWasteScenarioTemp;
    wasteScenarioFormField = predefinedWasteScenarioTemp;
    change('wasteScenario', predefinedWasteScenarioTemp);
    change('wasteScenarioProductionWaste', predefinedWasteScenarioTemp);
  }

  return (
    <Placeholder
      loaders={[
        !environmentalProfile?.wasteScenarios && environmentalProfile?.id ? !envProfile : false,
        supps, pdisResult,
        profileLoader,
      ]}>
      <S.FormContainer isHidden={isHidden} style={{ margin: '0' }}>
        <Form onSubmit={save}>
          {stage?.id === 'pdis'
            ? (
              <>
                <S.GroupFieldsDivider />
                <S.GroupFields>
                  <S.GroupFieldsHeader>{t('Predefined input set details')}</S.GroupFieldsHeader>
                  <Field
                    component={FormInput}
                    name="amount"
                    namespace="inputs"
                    props={{ type: 'decimal', min: 1 }}
                    validate={[v.required, v.checkPositive]}
                  />

                  <Field
                    component={FormAsyncSelect}
                    isLoading={pdisResult?.loading}
                    name="PDIS"
                    props={{
                      options: pdis,
                      defaultValue: predefinedInputSet,
                      loadOptions: loadPageOptionsPdis,
                      debounceTimeout: 200,
                      getOptionLabel: l => `[${l?.code}] ${l?.name} (${l?.unit})`,
                      getOptionValue: l => l?.['@id'],
                    }}
                    validate={[v.required]}
                  />
                </S.GroupFields>
              </>
            ) : (
              <>
                <S.GroupFieldsDivider />
                <S.GroupFields>
                  <S.GroupFieldsHeader>{t('input details')}</S.GroupFieldsHeader>
                  <Field
                    component={FormInput}
                    name="descriptionInputs"
                    namespace="inputs"
                    props={{
                      placeholder: t('type the description'),
                    }}
                    validate={[v.required]}
                  />

                  <Field
                    component={EnviromnentProfilePicker}
                    name="environmentalProfile"
                    namespace="inputs"
                    validate={[v.required]}
                  />

                  {envProfile?.transportNeeded && (
                    <Field
                      component={FormAsyncSelect}
                      name="supplierInputs"
                      namespace="inputs"
                      props={{
                        defaultValue: inputResult?.supplier,
                        isLoading: supps.loading,
                        loadOptions: loadPageOptions,
                        debounceTimeout: 1000,
                        options: locationsList,
                        getOptionLabel: l => {
                          if (l.city && l.country) {
                            return `${l.name} - ${l.city}, ${l.country}`;
                          }
                          const details = locationsList?.find(s => s.id === l.id);
                          if (details && details.city && details.country) {
                            return `${details.name} - ${details.city}, ${details.country}`;
                          }
                          return l.name;
                        },
                        getOptionValue: l => l['@id'],
                      }}
                      validate={[v.required]}
                    />
                  )}

                  <Field
                    component={FormInput}
                    name="netAmount"
                    namespace="inputs"
                    props={{ unit: envProfile?.unit }} // * In advance for [EPD-288]
                    validate={[v.required, v.requiredNumber]} // EPD-986 - allow negatives
                  />

                  {scalable && (
                    <Field
                      component={FormSelect}
                      name="linearScalable"
                      namespace="inputs"
                      props={{
                        options: [
                          { value: true, label: t('yes') },
                          { value: false, label: t('no') },
                        ],
                      }}
                    />
                  )}

                  {_.includes([3, 4], stage?.id) ? null : envProfile?.wasteScenarioNeeded && (
                    <>
                      {productionWasteInputMethod !== 'based_on_production_site'
                        && (
                          <Field
                            component={FormInput}
                            name={productionWasteName}
                            namespace="inputs"
                            props={{ type: 'decimal', min: 0 }}
                            validate={[v.required, v.minValue]}
                          />
                        )}

                      <Field
                        component={FormAsyncSelect}
                        name="wasteScenarioProductionWaste"
                        namespace="inputs"
                        props={{
                          defaultValue: wasteScenarioProductionWasteFormField,
                          loadOptions: loadPageOptionsProductionWaste,
                          debounceTimeout: 100,
                          options: wasteScenarioProductionWasteList,
                          isLoading: scenarios.loading,
                          getOptionLabel: l => (l.public ? l.name : `${l.name} (${companyName})`),
                          getOptionValue: l => l['@id'],
                          reduceOptions: (prev, loaded) => _.sortBy(_.uniqBy(
                            [...prev, ...loaded], 'id',
                          ), w => w?.name?.toLowerCase()),
                        }}
                        validate={[v.required]}
                      />
                    </>
                  )}

                  {envProfile?.wasteScenarioNeeded && (
                    <Field
                      component={FormAsyncSelect}
                      name="wasteScenario"
                      namespace="inputs"
                      props={{
                        reduceOptions: (prev, loaded) => _.sortBy(_.uniqBy(
                          [...prev, ...loaded], 'id',
                        ), w => w?.name?.toLowerCase()),
                        defaultValue: wasteScenarioFormField,
                        loadOptions: loadPageOptionsEndOfLife,
                        debounceTimeout: 100,
                        options: wasteScenarioEndOfLifeList,
                        isLoading: scenarios.loading,
                        getOptionLabel: l => (l.public ? l.name : `${l.name} (${companyName})`),
                        getOptionValue: l => l['@id'],
                      }}
                      validate={[v.required]}
                    />
                  )}

                  {_.includes([4, 3, 5, 6, 7, 8, 11], stage?.id) ? null : (
                    <Field
                      component={FormInput}
                      name="lifeTime"
                      namespace="inputs"
                      props={{ type: 'number', min: 1 }}
                      validate={[v.required, v.checkPositive]}
                    />
                  )}

                  <Field
                    component={FormSelect}
                    name="dataQuality"
                    namespace="inputs"
                    props={{
                      options: dataQualityOptions,
                    }}
                  />

                  <Field
                    component={FormInput}
                    name="dataSource"
                    namespace="inputs"
                  />

                  <Field
                    component={FormTextArea}
                    name="comments"
                    namespace="inputs"
                  />

                  <Field
                    component={FormTextArea}
                    name="epSubstantiation"
                  />
                </S.GroupFields>
              </>
            )}

          <S.GroupFields>
            <S.ButtonsWrapper>
              <Button
                disabled={submitting}
                loading={saveSpinner}
                style={{ marginRight: '1rem', minWidth: '9.8rem' }}
                styling="select"
                type="submit">
                {t('save')}
              </Button>
              {!isEdit && (
                <Button
                  disabled={submitting}
                  loading={saveContinueSpinner}
                  onClick={saveAndReset}
                  style={{ marginRight: '1rem' }}
                  styling="underline">
                  {t('Add other')}
                </Button>
              )}
              <Button
                onClick={() => {
                  // eslint-disable-next-line max-len
                  if (inputs.isPdis) h.push(`/calculations/predefined-input-sets/${inputs.calculationId}/inputs`);
                  else h.push(`/calculation/${id}/inputs/list`);
                }}
                styling="underline">
                {t('cancel')}
              </Button>
            </S.ButtonsWrapper>
          </S.GroupFields>
        </Form>
      </S.FormContainer>
      <MainModal
        content="prompt"
        exitModalContent={{
          navigateSideEffects: () => null,
        }}
        prompt
        promptCondition={!pristine && !hasEditedForm}
      />
    </Placeholder>
  );
};

export default reduxForm({
  form: FORM_NAME,
  enableReinitialize: true,
})(formValues({
  environmentalProfile: 'environmentalProfile',
  wasteScenarioProductionWaste: 'wasteScenarioProductionWaste',
  wasteScenario: 'wasteScenario',
})(AddInputForm));
