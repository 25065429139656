import React from 'react';
import { useDispatch } from 'react-redux';
import { setBreadcrumb } from 'state/sidebar/sidebar.actions';
import { Placeholder } from 'components/Splash/Loading';
import { TitleBar } from 'routes/GlobalStyle.styled';
import { useAsync } from 'react-async-hook';
import { versions } from 'services';
import { useParams, Link } from 'react-router-dom';
import moment from 'moment';
import HTML from 'components/RenderHTML';
import Button from 'components/Button/Button';
import { useTranslation } from 'react-i18next';

const styles = {
  title: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '26px',
    lineHeight: '34px',
    color: '#202538',
    textAlign: 'left',
  },
  date: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '18px',
    color: '#202538',
    textAlign: 'left',
    marginTop: '0.5rem',
    marginBottom: '2.2rem',
  },
  container: { width: '38.75rem', marginLeft: '9.4375rem', marginTop: '5.1875rem' },
};

const AnnouncementDetail = ({ isManage }) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();
  const announcementHook = useAsync(() => versions.getAnnouncementById(id), []);
  const { result } = announcementHook;

  React.useEffect(() => {
    if (result?.title) dispatch(setBreadcrumb(result?.title));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result]);

  return (
    <>
      <TitleBar style={{ justifyContent: 'flex-end' }}>
        {isManage && (
        <Button
          as={Link}
          styling="select"
          to={`/manage/announcements/${id}/edit`}>
          {t('edit')}
        </Button>
        )}
      </TitleBar>

      <Placeholder loaders={[announcementHook]}>
        <div style={styles.container}>
          <p
            style={styles.title}>
            {result?.title}
          </p>

          <p style={styles.date}>
            {moment(result?.publishedAt).format('DD-MM-YYYY HH:mm')}
          </p>

          <HTML html={result?.description} />
        </div>
      </Placeholder>
    </>
  );
};

export default AnnouncementDetail;
