import React, { useEffect, useState } from 'react';
import { Placeholder } from '../../../../../../components/Splash/Loading';
import { SemiFinishedProductDetails } from './SemiFinishedProductDetails';

export const SemiFinishedProductDetailsList = ({
  loading: parentLoading,
  sfpUsages,
  updateContext,
  forceExpanded,
}) => {
  const [readyStates, setReady] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(!!sfpUsages?.find(usage => !readyStates[usage.id]));
  }, [readyStates, sfpUsages]);

  if (!sfpUsages || sfpUsages.length === 0) return null;
  return (
    <Placeholder loaders={[loading, parentLoading || false]} renderChildren>
      {sfpUsages.map(usage => (
        <SemiFinishedProductDetails
          key={usage.id}
          forceExpanded={forceExpanded}
          onReady={() => setReady(r => {
            if (r[usage.id]) return r;
            return ({
              ...r,
              [usage.id]: true,
            });
          })}
          updateContext={updateContext}
          usage={usage}
        />
      ))}
    </Placeholder>
  );
};
