import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';

import { withLabels } from 'components/Field';
import viewIcon from 'assets/calculationsIcons/review.svg';

import * as S from './Input.styled';

const inputTypes = [
  'color',
  'date',
  'datetime-local',
  'email',
  'hidden',
  'image',
  'month',
  'number',
  'password',
  'search',
  'tel',
  'text',
  'time',
  'url',
  'week',
  'file',
];

const Input = ({
  id,
  name,
  ref,
  innerRef = ref,
  type,
  translation,
  input,
  hideShowIcon,
  containerStyle,
  ...props
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const inputName = name || id;

  const viewPassword = () => setIsVisible(!isVisible);

  const fileInputValue = (type === 'file') ? { value: undefined } : null;

  return (
    <S.InputContainer style={containerStyle}>
      <S.Input
        id={inputName}
        name={inputName}
        type={isVisible ? 'text' : type}
        {...props}
        {...input}
        {...fileInputValue}
        ref={innerRef || ref}
        autoComplete="new-password"
      />

      {type === 'password' && !hideShowIcon && (
        <button onClick={viewPassword} type="button">
          <img alt="view password" src={viewIcon} />
        </button>
      )}
    </S.InputContainer>
  );
};

Input.defaultProps = {
  type: 'text',
  name: undefined,
  required: false,
  disabled: false,
  readOnly: false,
  placeholder: undefined,
  value: undefined,
  autoComplete: 'on',
};

Input.propTypes = {
  type: PropTypes.oneOf(inputTypes),
  required: PropTypes.bool,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  id: PropTypes.string.isRequired,
  autoComplete: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export const FormInput = withLabels(Input);

export default memo(Input);
