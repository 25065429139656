import _ from 'lodash';
import { useSelector } from 'react-redux';

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ state, reviewState, ...data } = {}, selection, list) => {
  const userDetails = useSelector(({ user }) => user);

  const checkViewer = userDetails?.selectedMembership?.view === 'Viewer';
  const userCompanyId = userDetails?.selectedMembership?.company?.id;

  const restrictions = {};

  restrictions.reports = !_.isEmpty(data) ? !data?.reportable : !!data?.reportable;

  if (selection?.length > 1) {
    const intersection = list?.filter(l => selection?.some(c => c === l?.['@id']));

    restrictions.isOwner = intersection?.every(i => userCompanyId === i?.owner?.id);
    restrictions.edit = intersection?.every(i => (!restrictions.isOwner || i?.locked))
    || checkViewer;
    restrictions.review = intersection?.every(i => (restrictions.edit || !i?.submittable))
    || checkViewer;
    restrictions.copy = !restrictions.isOwner || checkViewer;
    restrictions.move = !restrictions.isOwner || checkViewer;
    restrictions.delete = restrictions.edit || checkViewer;
  } else {
    restrictions.isOwner = userCompanyId === data?.owner?.id;
    restrictions.edit = (!restrictions.isOwner || data?.locked) || checkViewer;
    restrictions.review = (restrictions.edit || !data?.submittable) || checkViewer;
    restrictions.copy = !restrictions.isOwner || checkViewer;
    restrictions.move = !restrictions.isOwner || checkViewer;
    restrictions.delete = restrictions.edit || checkViewer;
  }

  return restrictions;
};
