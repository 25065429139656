import styled from 'styled-components';
import { DARK_BLUE } from 'config/style';

export const Wrapper = styled.div`
  padding: 1.25rem;
  background-color: white;
  z-index: 5;
  box-shadow: 0 0 14px rgba(57, 57, 57, 0.15);

  width: 24.88rem;
`;

export const WrapperTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2.05rem;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.88rem;
  height: 1.88rem;
  border-radius: 100%;
  background: white;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  margin-left: 5px;
  /* position: absolute;
  right: -2.2rem; */


  img {
    width: 10.16px;
  }
`;

export const CloseButton = styled(ButtonWrapper)`
  right: 1rem;
`;

export const Title = styled.h3`
  color: ${DARK_BLUE};
  font-size: 1rem;
  font-weight: 500;
`;

export const Button = styled.button`
  font-size: 14px;
  height: 2.8rem;
  width: 6.9rem;
  cursor: pointer;
  font-weight: ${(props) => (props.bold ? 600 : 500)};
  background: ${(props) => (props.bold ? '#FAFAF5' : 'none')};
  border: ${(props) => (props.bold ? '1px solid #E2E2D2' : 'none')};
  ${(props) => props.underline && 'text-decoration: underline'};
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 1.5rem;
`;
