import styled, { css } from 'styled-components/macro';
import { Link } from 'react-router-dom';

import { DARK_BLUE } from 'config/style';

const bottomPosition = css`
  top: 20px;
  right: -10px;
`;

const topPosition = css`
  top: auto;
  bottom: 50px;
  right: 0;
`;

const leftAligned = css`
  right: -100px;
`;

const leftPosition = (top) => css`
  right: 95px;
  top: ${top - 170}px;
`;

const getPosition = (pos, top) => {
  if (pos === 'bottom') {
    return bottomPosition;
  }
  if (pos === 'top') {
    return topPosition;
  }

  return leftPosition(top);
};

const bottomPositionArrow = css`
  top: 9px;
  right: 10px;
`;

const topPositionArrow = css`
  top: auto;
  bottom: 39px;
  right: 7px;
  transform: rotate(135deg);
`;

const leftPositionArrow = css`
  right: 33px;
  top: -25px;
  transform: rotate(45deg);
`;

const getArrowPosition = (pos) => {
  if (pos === 'bottom') {
    return bottomPositionArrow;
  }
  if (pos === 'top') {
    return topPositionArrow;
  }

  return leftPositionArrow;
};

const bottom = css`
  left: -80%;
  bottom: 130%;
  top: auto;
  right: auto;

  .arrow {
    bottom: -12px;
    top: auto;
    border-right: none;
    border-top: none;
    border-bottom: 1px solid #e2e2e2;
    border-left: 1px solid #e2e2e2;
  }
`;

const addBtn = css`
  top: -3.12rem;
  left: -.8rem;
  right: auto;
  bottom: auto;

  .arrow {
    display: none;
  }
`;

export const StyledContainer = styled.div`
  border: none;
  font-size: 12px;
  min-width: 20px;
  color: ${DARK_BLUE};
  display: flex;
  align-items: center;
`;

export const StyledText = styled.span`
  color: ${DARK_BLUE};
  cursor: pointer;
`;

export const StyledDropDown = styled.div`
  border: 1px solid #e2e2e2;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.4);
  background: white;
  position: ${({ placement }) => (placement === 'left' ? 'fixed' : 'absolute')};
  ${({ placement, top }) => getPosition(placement, top)};
  opacity: ${({ placement }) => (placement ? 1 : 0)};
  bottom: ${(props) => (props.type === 'bottom' ? '20px' : null)};
  ${(props) => props.type === 'bottom' && bottom};
  ${(props) => props.type === 'addBtn' && addBtn};
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  min-width: 255px;
  cursor: auto;
  z-index: 10;
  ${({ alignLeft }) => alignLeft && leftAligned}
`;

export const Overlay = styled.div`
  position: fixed;
  background: rgba(0, 0, 0, 0);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  cursor: auto;
`;

export const Wrapper = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const StyledArrow = styled.div`
  position: absolute;
  background: white;
  width: 22px;
  height: 22px;
  border-right: 1px solid #e2e2e2;
  border-top: 1px solid #e2e2e2;
  transform: matrix(0.71, -0.74, 0.68, 0.71, 0, 0);
  z-index: 11;
  ${({ placement }) => getArrowPosition(placement)};
  ${({ type }) => type === 'addBtn' && css`display: none`};
`;

export const StyledLink = styled(Link)`
  display: flex;
  width: auto;
  &:not(:last-child){
    margin-bottom: 1.3rem;
  }
  img{
    margin-right: 1rem;
  }
`;

export const StyledLogout = styled(Link)`
  font-weight: 300;
  width: 100%;
  border-top: 1px solid #E4E4E4;
  padding: 20px;
  display: block;
`;
