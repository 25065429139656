import _ from 'lodash';
import { useAsync } from 'react-async-hook';
import { errorToast } from 'config/toast';

import network from './network';

/**
 * Helpers
*/

const paginatedMember = ({
  'hydra:member': data,
  'hydra:view': view,
  'hydra:totalItems': totalItems,
}) => ({
  data,
  nextPage: view && view['hydra:next'],
  totalItems,
});

/**
 * Network
*/

export const all = config => network
  .get('/environmental_profiles', config)
  .then(paginatedMember)
  .catch((err) => errorToast(err));

export const allFlat = config => network
  .get('/environmental_profiles', { ...config, cache: true })
  .then(_.flatMap)
  .then((r) => _.keyBy(r, 'id'))
  .catch((err) => errorToast(err));

export const getProfileById = (id, config) => id && network
  .get(`/environmental_profiles/${id}`, config)
  .catch((err) => errorToast(err));

/**
 * Hooks
*/

export const useEnvironmentalProfile = (id) => useAsync(
  getProfileById,
  [id],
);
