import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { /* companies as companiesServices, */ profiles } from 'services';
import { selectCompany } from 'state/company/company.actions';

import AsideLayout from 'components/AsideLayout/AsideLayout';
import AsideOverview from 'components/AsideOverview/AsideOverview';
import ListItem from 'components/ListItem/ListItem';
import Input from 'components/Input/Input';
import { Placeholder } from 'components/Splash/Loading';
import Spinner from 'components/Spinner';
// import Select from 'components/Select/Select';
import TopBar from 'components/TopBar/TopBar';
import Name from 'components/TopBar/Name';
import LastChangedStatus from 'components/TopBar/LastChangedStatus';
import InfiniteScroll from 'react-infinite-scroll-component';
import * as S from 'routes/GlobalStyle.styled';
import Arrow from 'assets/ArrowSelection.svg';

const ScenariosOverview = () => {
  const { t } = useTranslation();
  const path = useLocation().pathname;
  const {
    id: companyId, selectedCompany, name: companyName, ...other
  } = useSelector(({ company }) => company || {});
  const { selectedMembership } = useSelector(state => state.user);
  const dispatch = useDispatch();
  // const user = useSelector(state => state.user);
  const scenarioType = path.split('/')[2]; // if it's general or company
  const [selected, setSelected] = useState(scenarioType);
  const [input, setInput] = useState('');
  const [clearInput, setClearInput] = useState(null);
  const [company, setCompany] = useState(null);
  // const [companies, setCompanies] = useState(null);
  const [sorting, setSorting] = useState('asc');
  const [lastChange, setLastChange] = useState(null);
  const [page, setPage] = useState(null);
  const [totalNumberOfRecords, setTotalNumberOfRecords] = useState(null);

  const reviewer = selectedMembership?.roleName === 'ROLE_REVIEWER';

  if (scenarioType !== selected) setSelected(scenarioType);

  const [scenarios, setScenarios] = useState([]);
  const [loading, setLoading] = useState(false);
  const time = useRef(0);
  const isMounted = useRef(false); // pattern to avoid memory leaks in the application

  /* const superAdmin = user?.selectedMembership?.view === 'Super Admin'
    || user?.selectedMembership?.view === 'Nibe Employee'; */

  /* useEffect(() => {
    if (superAdmin) companiesServices.getAll().then(values => setCompanies(values.companies));
  }, [superAdmin]); */

  const clearFilter = () => {
    const inputValue = { value: '' };
    setClearInput(inputValue);
    setInput('');
    setCompany({ label: companyName, value: companyName });
    selectCompany({
      id: companyId, name: companyName, label: companyName, value: companyName, ...other,
    })(dispatch);
  };

  const getScenarios = (props) => {
    setTotalNumberOfRecords(null);
    if (!isMounted.current) setLoading(true);
    const params = {};
    if (props.companyId) params.owner = (props.selected === 'company') ? props.companyId : 'none';
    if (props.lastChange) params['order[lastModifiedAt]'] = props.lastChange;
    if (props.sorting) params['order[name]'] = props.sorting;
    if (props.search) params.search = props.search;
    params.public = !(props.selected === 'company');
    profiles.getAllWasteScenarios(params).then(values => {
      if (!isMounted.current) {
        setScenarios(values.wastescenarios);
        setPage(values.nextPage);
        setTotalNumberOfRecords(values.totalItems);
      }
    }).finally(() => !isMounted.current && setLoading(false));
  };

  useEffect(() => {
    isMounted.current = false;
    getScenarios({
      companyId: (company && company.id) || companyId,
      selected,
      sorting,
      lastChange,
      search: input,
    });
    return () => {
      isMounted.current = true;
    };
  }, [companyId, selected, company, sorting, lastChange, input]);

  const handleInputChange = (e) => {
    if (time.current) clearTimeout(time.current);
    const inputText = e.target.value;
    time.current = setTimeout(() => {
      setInput(inputText);
      clearTimeout(time.current);
    }, 2000);
  };

  const handleOnKeyDown = (event) => {
    if (event.key === 'Enter') {
      const inputText = event.target.value;
      const clearSpaces = inputText && inputText.length > 0 && inputText.trim();
      if (clearSpaces) setInput(inputText);
      else setInput('');
    }
  };

  const itemLink = (data) => ({
    pathname: `/waste-scenarios/${data.id}/waste-scenario`,
    state: {
      routeName: data.name,
    },
  });

  const onFocus = () => {
    setClearInput(null);
  };

  /* const handleDropdownChangeCompany = (companyValue) => {
    setCompany(companyValue);
    selectCompany(companyValue)(dispatch);
    getScenarios({ selected, companyId: companyValue.id });
  }; */

  const fetchData = () => {
    if (page) {
      profiles
        .getNextPageWasteScenarios(page)
        .then((values) => {
          setPage(values.nextPage);
          setTotalNumberOfRecords(values.totalItems);
          setScenarios(scenarios.concat(values.wastescenarios));
        });
    }
  };

  return (
    <AsideLayout
      LeftPane={(
        <div>
          <S.AsideTitle>
            {t('waste scenarios')}
          </S.AsideTitle>
          <S.LinksWrapper>
            <S.AsideLink to="/waste-scenarios/general">
              {selected === 'general' && <S.ArrowSelection alt="arrowSelect" src={Arrow} />}
              {t('general')}
            </S.AsideLink>
            {!reviewer && (
              <S.AsideLink to="/waste-scenarios/company">
                {selected === 'company' && <S.ArrowSelection alt="arrowSelect" src={Arrow} />}
                {t('Company')}
              </S.AsideLink>
            )}
          </S.LinksWrapper>
          <AsideOverview
            clearFilter={clearFilter}
            clearingFilterCondition={(input || (company /* && (companyId !== selectedCompany?.id) */
              && (company.id !== 'none'))) && (totalNumberOfRecords !== null)}
            results={totalNumberOfRecords}>
            <S.Label>{t('Name / ID')}</S.Label>
            <div style={{ marginBottom: '1.5rem' }}>
              <Input
                {...clearInput}
                id="name"
                name="name"
                onChange={handleInputChange}
                onFocus={onFocus}
                onKeyDown={handleOnKeyDown}
                placeholder={t('filter by name or id')}
              />
            </div>
            {/* {superAdmin
              && (
                <>
                  <S.Label>{t('Company')}</S.Label>
                  <Select
                    isSearchable
                    name="company"
                    onChange={handleDropdownChangeCompany}
                    options={companies}
                    placeholder={t('all companies')}
                    value={company}
                  />
                </>
              )} */}
          </AsideOverview>
        </div>
      )}>
      <TopBar>
        <Name
          onOrderBy={order => {
            setSorting(order);
          }}
          order={sorting}
        />
        <LastChangedStatus
          inverted
          onOrderBy={() => {
            if (!lastChange) setLastChange('asc');
            else if (lastChange === 'asc') setLastChange('desc');
            else setLastChange(null);
          }}
          order={lastChange}
        />
      </TopBar>

      <Placeholder loaders={[loading]}>
        {scenarios.length ? (
          <S.OverviewRows id="overviewRowsId">
            <InfiniteScroll
              dataLength={scenarios?.length}
              hasMore={page}
              loader={<Spinner />}
              next={fetchData}
              scrollableTarget="overviewRowsId"
              scrollThreshold={0.4}
              style={{ minHeight: '50vh' }}>
              {scenarios.map((profile) => (
                <ListItem
                  key={profile.id}
                  description={`ID: ${profile?.code}`}
                  lastChanged={profile?.lastModifiedAt}
                  name={profile.name}
                  to={() => itemLink(profile)}
                />
              ))}
            </InfiniteScroll>
          </S.OverviewRows>
        ) : 'No items to show'}
      </Placeholder>
    </AsideLayout>
  );
};

export default ScenariosOverview;
