import React, { useState, useEffect } from 'react';
import {
  Link,
  useRouteMatch,
  useParams,
  useHistory,
  Redirect,
} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setBreadcrumb } from 'state/sidebar/sidebar.actions';
import { setMembership } from 'state/user/user.actions';
import { useTranslation } from 'react-i18next';
import { useAsync } from 'react-async-hook';
import { companies } from 'services';
import Button from 'components/Button/Button';
import { FormContentInfoNoBorder } from 'components/ContentInfo/ContentInfo';
import { Loading } from 'components/Splash/Loading';
import { styledButtonForHeaderInViews } from 'config/style';
import { Tabs, Tab } from 'components/Tabs/Tabs';
import * as S from 'routes/GlobalStyle.styled';
import Companies from 'routes/Companies/Companies';
import { bold } from 'utils';
import { licences } from '../Calculations/data';

const CompanyProfile = ({
  input,
  isCorp,
  setTotalNumberOfRecords,
}) => {
  const { t } = useTranslation();
  const { url } = useRouteMatch();
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const { selectedCompany, id: companyId } = useSelector(({ company }) => company || {});

  const [company, setCompany] = useState(null);

  const companyResult = useAsync(companies.getCompany, [`/2/companies/${id}`]);

  useEffect(() => {
    if (company?.name) dispatch(setBreadcrumb(company?.name));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company]);

  const companyAdmin = user?.selectedMembership?.view === 'Company Admin'
    || user?.selectedMembership?.view === 'Corporate Admin';

  const superAdmin = user?.selectedMembership?.view === 'Super Admin'
    || user?.selectedMembership?.view === 'Nibe Employee';

  // eslint-disable-next-line eqeqeq
  if ((id != user.id) && !superAdmin && !companyAdmin) {
    return <Redirect to="/" />;
  }

  if (!company && !companyResult.loading && !companyResult.error) setCompany(companyResult.result);

  const isEditable = companyId === selectedCompany?.id;

  const content = () => {
    if (url === `/manage/companies/${id}/companies`) {
      return (
        <Companies
          input={input}
          isCorp={isCorp}
          parentCorporation={company}
          setTotalNumberOfRecords={setTotalNumberOfRecords}
        />
      );
    }

    return (
      <S.Container>
        <S.ItemsContainer>
          <div style={{ marginRight: '6.87rem' }}>
            <FormContentInfoNoBorder content={company?.name} name="name" />
            <FormContentInfoNoBorder content={company?.id} name="company code" />
            {superAdmin && company?.nibeCustomerNumber && (
              <FormContentInfoNoBorder
                content={company?.nibeCustomerNumber}
                name="nibeCustomerNumber"
              />
            )}
            <FormContentInfoNoBorder
              content={company?.corporation ? t('corporation') : t('company.label')}
              name="type"
            />
            {company?.parentCompany?.name
            && (
              <FormContentInfoNoBorder
                content={company.parentCompany.name}
                name="Corporation"
              />
            )}
            {company?.license
            && (
              <FormContentInfoNoBorder
                content={licences?.find(l => l?.value === company?.license)
                  ?.label}
                name="licence"
              />
            )}
            <FormContentInfoNoBorder
              content={company?.publisher ? `${t('yes')}: ${
                company?.publisherNamePerson1} (${
                company.publisherFunctionPerson1}), ${
                    company?.publisherNamePerson2} (${
                company.publisherFunctionPerson2
              })` : t('no')}
              name="publisher"
            />
            <FormContentInfoNoBorder
              content={company?.operator ? t('yes') : t('no')}
              name="operator"
            />
            <FormContentInfoNoBorder
              content={company?.reviewCompany ? t('yes') : t('no')}
              name="reviewCompany"
            />
          </div>
          <div>
            <FormContentInfoNoBorder content={company?.address} name="address" />
            <FormContentInfoNoBorder content={company?.postalCode} name="postal code" />
            <FormContentInfoNoBorder content={company?.city} name="city" />
            <FormContentInfoNoBorder content={company?.country} name="country" />
            {company?.email && <FormContentInfoNoBorder content={company.email} name="email" />}
            {/* eslint-disable-next-line max-len */}
            {company?.website && <FormContentInfoNoBorder content={company.website} name="website" />}
          </div>
        </S.ItemsContainer>
      </S.Container>
    );
  };

  const showProfileContent = () => (
    <>
      <Tabs>
        <Tab to={`/manage/companies/${id}/basic-information`}>{t('basic-information')}</Tab>
        {company?.corporation
          && (
            <Tab to={`/manage/companies/${id}/companies`}>
              {t('companies')}
            </Tab>
          )}
        {url === `/manage/companies/${id}/companies`
          ? (
            <Button
              as={Link}
              style={{
                ...styledButtonForHeaderInViews,
                marginLeft: 'auto',
                marginRight: 0,
                pointerEvents: !isEditable && 'none',
              }}
              to="/manage/companies/new">
              {t('add new company')}
            </Button>
          )
          : (
            <>
              {(superAdmin || user?.selectedMembership?.view === 'Corporate Admin')
                && (company && !company?.corporation)
                && (
                  <Button
                    onClick={() => {
                      dispatch(setMembership({
                        ...user?.selectedMembership,
                        company: {
                          id,
                          name: company?.name,
                          '@id': company?.['@id'],
                        },
                        roleName: 'ROLE_VIEWER',
                        view: 'Viewer',
                        label: bold(company?.name, 'Viewer'),
                        value: `${company?.name} | Viewer`,
                      }));
                      history.push('/');
                    }}
                    style={{
                      ...styledButtonForHeaderInViews,
                      marginLeft: 'auto',
                      marginRight: 0,
                      pointerEvents: !isEditable && 'none',
                    }}>
                    {t('access rethink')}
                  </Button>
                )}
              <Button
                as={Link}
                style={{
                  ...styledButtonForHeaderInViews,
                  marginLeft: !(company && !company?.corporation)
                  || (!company?.corporation
                    && user?.selectedMembership?.view === 'Company Admin') ? 'auto' : '2.3rem',
                  marginRight: 0,
                  pointerEvents: !isEditable && 'none',
                }}
                to={isEditable && `/manage/companies/${id}/edit`}>
                {t('edit company')}
              </Button>
            </>
          )}
      </Tabs>
      {companyResult.loading
        ? Loading
        : content()}
    </>
  );

  return showProfileContent();
};

export default CompanyProfile;
