import _ from 'lodash';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { calculations } from 'services';
import { Placeholder } from 'components/Splash/Loading';
import * as S from 'routes/GlobalStyle.styled';
import { useLocations } from 'hooks/location.hooks';
import { useMethods } from 'hooks/methods.hooks';
import { useWasteRegion } from 'hooks/wasteRegion.hooks';
import { useCustomUnitForLCA, useStandardUnitForLCA } from 'hooks/functionalUnit.hooks';
import { useCompanies } from 'hooks/companies.hooks';
import TemplateForm from './TemplateForm';
import { predefinedConstructionWasteOpt } from '../Advanced/Editor.helpers';

const EditTemplate = ({ template, loading }) => {
  const h = useHistory();
  const { t } = useTranslation();
  const locationHook = useLocations(template?.location);
  const methods = useMethods(template);
  const wasteRegions = useWasteRegion(template);
  const customUnitForLCA = useCustomUnitForLCA(null, template);
  const standardUnitForLCA = useStandardUnitForLCA(null, template);
  const operators = useCompanies({ operator: true });
  const companies = useCompanies();

  const loaderList = [
    locationHook,
    loading || false,
    companies.loading,
    operators.loading,
    /* methods,
    wasteRegions,
    standardUnitForLCA, */
  ];

  const save = (values, isCustom) => {
    const templateInfo = {};

    if (values.Code) templateInfo.code = values.Code;
    if (values.name) templateInfo.name = values.name;
    if (values.location) templateInfo.location = values.location['@id'];
    if (values.productLifeTime) {
      templateInfo.productLifeTime = parseInt(values.productLifeTime, 10);
    }
    if (values.method) templateInfo.method = values.method['@id'];
    if (values.wasteRegion) templateInfo.wasteRegion = values.wasteRegion['@id'];
    if (values.wasteRegion) templateInfo.wasteScenarioSet = values.wasteRegion['@id'];

    templateInfo.productType = values.productType && values.productType['@id'];
    if (values?.productType?.id === 3) {
      templateInfo.distanceToConstructionSite = _.parseInt(values.distanceToConstructionSite);
      templateInfo.transportProfileToConstructionSite = values
        .transportProfileToConstructionSite['@id'];
    }
    /* if is standard else custom. */
    if (!isCustom) {
      templateInfo.functionalUnit = values.functionalUnit['@id'];
    } else {
      templateInfo.functionalUnitTitle = values.functionalUnitTitle;
      templateInfo.functionalUnitDescription = values.functionalUnitDescription;
      templateInfo.standardUnit = values.standardUnit['@id'];
    }
    templateInfo.customFunctionalUnit = isCustom;

    templateInfo.predefinedConstructionWaste = values.predefinedConstructionWaste.value;
    if (templateInfo.predefinedConstructionWaste === 0) {
      templateInfo.predefinedConstructionWaste = null;
      templateInfo.constructionWaste = parseFloat(values.constructionWaste);
    } else {
      templateInfo.constructionWaste = templateInfo.predefinedConstructionWaste;
    }

    if (values.method.publisher) {
      templateInfo.publisher = null;
    } else if (values.publisher) {
      templateInfo.publisher = values.publisher['@id'] || values.publisher;
    }

    if (!values.method.operator && values.operator) {
      templateInfo.operator = values.operator['@id'] || values.operator;
    }

    return calculations.saveTemplate(template?.id, templateInfo).then(res => {
      h.push(`/calculations/templates/${res?.id}`);
    });
  };

  const method = methods?.list?.find(m => m['@id'] === template?.method?.['@id']);

  return (
    <Placeholder loaders={loaderList}>
      <S.Background isEditable>
        <S.HeaderTitle>{t('change template basics')}</S.HeaderTitle>
        <TemplateForm
          companies={companies?.data || []}
          customFunctionalUnit={template.customFunctionalUnit}
          customUnitForLCA={customUnitForLCA}
          initialValues={{
            Code: template?.code,
            name: template?.name,
            productType: _.find(methods?.productTypes, p => p?.['@id'] === template.productType),
            location: locationHook.selected,
            method,
            productLifeTime: template.productLifeTime,
            wasteRegion: wasteRegions.selected,
            standardUnit: customUnitForLCA.selected,
            // Functional Unit
            functionalUnit: standardUnitForLCA.selected,
            functionalUnitTitle: customUnitForLCA.title,
            functionalUnitDescription: customUnitForLCA.description,
            // Transport
            distanceToConstructionSite: methods.distanceToConstructionSite,
            transportProfileToConstructionSite: methods.transportProfileToConstructionSite,
            // Construction waste
            predefinedConstructionWaste: wasteRegions.predefinedConstructionWaste
              || predefinedConstructionWasteOpt.find(opt => opt.value === 0),
            constructionWaste: wasteRegions.constructionWaste,
            publisher: method?.publisher || template?.publisher,
            operator: method?.operator || template?.operator,
          }}
          isEdit
          locationHook={locationHook}
          locationSelected={locationHook.selected}
          methods={methods}
          operators={operators.data || []}
          ownerCompany={companies?.data?.find(c => c['@id'] === template?.owner?.['@id'])}
          saveForm={save}
          standardUnitForLCA={standardUnitForLCA}
          template={template}
          wasteRegions={wasteRegions}
        />
      </S.Background>
    </Placeholder>
  );
};

export default EditTemplate;
