import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useRouteMatch } from 'react-router-dom';

import { useInputsContext } from 'hooks/input.hooks';
import { Calculation } from 'hooks/calculation.hooks';

import arrow from 'assets/arrow_show.svg';
import { useReportItems, useStages } from './Progress.hooks';
import { Row, WrapperDiv, Items } from './Progress.styled';

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  const { t } = useTranslation();
  let { url } = useRouteMatch();
  const inputsContext = useInputsContext();
  const { result } = React.useContext(Calculation);
  const { status } = useReportItems();
  const { errors } = useStages();
  const isSemi = (result?.calculationType === 'semi_finished_product');

  // Fix appended slash when /routing
  if (url.endsWith('/')) {
    url = url.substring(0, url.length - 1);
  }

  const pathname = `${url}/realtime/progress`;

  return (
    <ul>
      <Row
        $alert={status.errorCount > 0}
        as={Link}
        disabled={status.errorCount === 0}
        to={{ pathname, hash: '#stage-0', state: { collapse: 0 } }}>
        <p>{t('Information for report')}</p>

        <WrapperDiv>
          {(status.errorCount > 0)
            && <Items>{t('items incomplete', { count: status.errorCount })}</Items>}
          <img alt="icon" src={arrow} />
        </WrapperDiv>
      </Row>

      {_.compact(_.map(_.drop(inputsContext.stages), ({ id: code, code: label }) => {
        const stageErrors = _.get(errors, code);

        if (isSemi && _.includes([8, 9, 10, 11], code)) return null;

        return (
          <Row
            key={code}
            $alert={stageErrors}
            as={Link}
            disabled={!stageErrors}
            to={{ pathname, hash: `#stage-${code}`, state: { collapse: code } }}>
            <p>{t(`${label}.label`)}</p>

            <WrapperDiv>
              {!!stageErrors
                && <Items>{t('items incomplete', { count: stageErrors })}</Items>}
              <img alt="collapse arrow closed" src={arrow} />
            </WrapperDiv>
          </Row>
        );
      }))}
    </ul>
  );
};
