import { alertToast, errorToast } from 'config/toast';

import network from './network';

const paginatedMember = ({
  'hydra:member': data,
  'hydra:view': view,
  'hydra:totalItems': totalItems,
}) => ({
  get users() {
    return this.data;
  },
  data,
  nextPage: view['hydra:next'],
  totalItems,
});

export const getUser = (id) => network.get(`/users/${id}`).catch((err) => errorToast(err));

export const editUser = (id, payload) => network
  .put(`/users/${id}`, payload)
  .then(() => alertToast('User data updated!', 'success'))
  .catch((err) => errorToast(err));

export const getAll = (config) => network
  .get('/users', config)
  .then(paginatedMember)
  .catch((err) => errorToast(err));

export const getNextPage = (url) => network
  .get(`${process.env.REACT_APP_API_URL}${url}`, { params: { owner: 'none' } })
  .then(paginatedMember)
  .catch((err) => errorToast(err));

export const createUser = (payload) => network
  .post('/users', payload)
  .then(value => {
    alertToast('User created with success!', 'success');
    return value;
  })
  .catch((error) => {
    throw new Error(error);
  });

export const createUserAutograph = (payload) => network
  .post('/media_objects', payload, {
    params: { owner: 'none' }, fileHeader: { 'Content-Type': 'multipart/form-data' },
  })
  .then(result => {
    alertToast('User autograph uploaded with success!', 'success');
    return result;
  })
  .catch(errorToast);

export const getUserAutograph = (url) => network
  .get(`${process.env.REACT_APP_API_URL}${url}`, { params: { owner: 'none' } })
  .catch(errorToast);

export const deleteUserAutograph = (url) => network
  .get(`${process.env.REACT_APP_API_URL}${url}`, { params: { owner: 'none' } })
  .then(() => alertToast('User autograph deleted with success!', 'success'))
  .catch((err) => errorToast(err));

export const deleteUser = (id) => network
  .delete(`/users/${id}`)
  .then(() => alertToast('User deleted with success!', 'success'))
  .catch((err) => errorToast(err));

export const getMe = () => network
  .get('/me', { params: { owner: 'none' } })
  .catch((err) => errorToast(err));

export const getUserMemberships = (id, config, removeNibeHeaders) => {
  const params = config || { params: { user: id } };
  params.removeNibeHeaders = removeNibeHeaders ? removeNibeHeaders?.value : true;
  return network
    .get('/memberships', params)
    .then(paginatedMember);
};

export const createUserMembership = (params) => network
  .post('/memberships', params)
  .then(result => {
    alertToast('Membership created with success!', 'success');
    return result;
  })
  .catch((err) => {
    errorToast(err);
    return err;
  });

export const updateUserMembership = (id, params) => network
  .put(`/memberships/${id}`, params)
  .then(result => {
    alertToast('Membership updated with success!', 'success');
    return result;
  })
  .catch((err) => {
    errorToast(err);
    return err;
  });

export const deleteUserMembership = (id) => network
  .delete(`/memberships/${id}`)
  .then(() => alertToast('Membership deleted with success!', 'success'))
  .catch((err) => errorToast(err));

export const getUserMembership = (id) => network
  .get(`/memberships/${id}`)
  .catch((err) => errorToast(err));

export const getUserRoles = (params) => network
  .get('/roles', { params })
  .then(paginatedMember)
  .catch((err) => errorToast(err));

export const inviteUser = (params) => network
  .post('/invite', params);
