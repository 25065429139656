import styled from 'styled-components';
import arrowIcon from 'assets/ArrowSelection.svg';

export const Download = styled.p`
  cursor: pointer;
  text-decoration-line: underline;
  font-size: .875rem;
  font-weight: normal;
  margin-top: 9px;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.15);
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Icon = styled.img.attrs({ src: arrowIcon })`
  transform: rotate(90deg)
`;
