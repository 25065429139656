import styled from 'styled-components/macro';
import { NavLink } from 'react-router-dom';
import { NAV_HEIGHT } from 'config/style';

export const Container = styled.nav`
  border-bottom: 1px solid #202538;
  padding: 0.8rem 1.3rem;
  display: flex;
  height: ${NAV_HEIGHT};
  justify-content: space-between;
  flex: 0 0 auto;
  background-color: #202538;
`;

export const ContainerSection = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledLinks = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  height: 100%;
  border-right: 1px solid #e4e4e4;
  margin-right: 20px;
  text-decoration: none;
  text-transform: capitalize;
`;
export const StyledLink = styled.span`color: white;`;

export const NavButton = styled(NavLink)`
  margin-right: 20px;

  &.active {
    font-weight: bold;
  }
`;

export const StyledImage = styled.img`width: 1.3rem;`;
