import styled from 'styled-components/macro';
import { GREEN } from 'config/style';

const height = 18;

export const Label = styled.label`
  font-size: ${height - 6}px;
  line-height: 1;
  margin-right: .5em;
`;

export const Wrapper = styled.div`
  .switch {
    position: relative;
    display: inline-block;
    width: 36px;
    height: ${height}px;
    line-height: 1;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 1px;
    bottom: 1px;
    background-color: white;
    transition: .4s;
  }

  input:checked + .slider {
    background-color: ${GREEN};
  }

  input:focus + .slider {
    box-shadow: 0 0 1px ${GREEN};
  }

  input:checked + .slider:before {
    transform: translateX(18px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
`;
