import { DARK_BLUE, InputHeight } from 'config/style';

export default {
  control: () => ({
    border: '1px solid #e4e4e4;',
    display: 'flex',
    alignItems: 'center',
    height: InputHeight,
    color: 'rgba(32, 37, 56, 0.5)',
    fontSize: '14px',
    background: 'white',
    cursor: 'pointer',
    padding: '.62rem 1rem',
  }),
  container: (provided) => ({
    ...provided,
    width: '100%',
  }),
  menuList: () => ({
    color: 'rgba(32, 37, 56, 0.5)',
    fontSize: '14px',
    maxHeight: '15rem',
    overflowY: 'auto',
  }),
  indicatorSeparator: () => null,
  dropdownIndicator: (provided) => ({
    ...provided,
    color: DARK_BLUE,
    padding: 0,
  }),
  valueContainer: (provided) => ({
    ...provided,
    color: 'rgba(32, 37, 56, 0.5)',
    fontSize: '14px',
    padding: 0,
  }),
};

export const theme = (themeProvider) => ({
  ...themeProvider,
  colors: {
    ...themeProvider.colors,
    primary25: '#F4F8FA',
    neutral80: 'rgba(32, 37, 56, 0.5)',
  },
});
