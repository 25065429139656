import _ from 'lodash';
import React, { useState, useContext } from 'react';
import { useAsync } from 'react-async-hook';
import { useTranslation } from 'react-i18next';

import { inputs } from 'services';
import { Calculation } from 'hooks/calculation.hooks';
import { useInput, useInputsContext } from 'hooks/input.hooks';
import { FormAsyncSelect } from 'components/Select/Select';
import { useQuery } from 'utils';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import AddInput from './AddInput';
import AddSemifinished from './AddSemifinished';

const Wrapper = () => {
  const { t } = useTranslation();
  const user = useSelector(state => state.user);
  const inputId = useQuery().get('inputId');
  const { result } = useContext(Calculation);
  const { calculationType, id: calculationId } = result || {};
  const [selectedStage, setSelect] = useState(null);
  const [stagesList, setStagesList] = useState(null);
  const [page, setPage] = useState(null);
  const isSemi = (calculationType === 'semi_finished_product');
  const history = useHistory();
  const location = useLocation();

  const { isPdis: isPdisCalculation, calculationId: pdisId } = useInputsContext();
  const isPdisInput = location?.pathname?.includes('predefined-input-set')
    && location?.pathname?.includes('edit-input') && !isPdisCalculation;

  const stages = useAsync(() => inputs.inputStages({ 'order[name]': 'asc' }).then(res => {
    const arr = isSemi
      ? res.data?.filter(s => !_.includes([0, 8, 9, 10, 11], s?.id))
      : res.data;
    if (isPdisCalculation) {
      arr.shift();
      arr.shift();
    }
    setStagesList(arr.filter(
      stage => stage.id !== 'pdis' || user?.selectedMembership?.company?.license === 3,
    ));
    setPage(res.nextPage);
  }), []);

  const input = useInput(calculationId, inputId, isPdisInput);

  const disableIfSemifinished = ({ id }) => {
    if (isSemi) return _.includes([0, 8, 9, 10, 11], id);
    return false;
  };

  React.useEffect(() => {
    if (input?.result && inputId && _.isEmpty(selectedStage)) {
      setSelect(
        isPdisInput
          ? {
            ...input?.result,
            code: 'PDIS',
            id: 'pdis',
            pdisId: input?.result?.id,
          }
          : input?.result?.inputStage,
      );
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [input, inputId]);

  // EPD-813/1120 pre-select input type select if it has a ?stage param
  React.useEffect(() => {
    if (!stagesList || inputId) return;
    let stageParam = location.search?.replace('?stage=', '');
    if (!stageParam) return;
    // Stage can be
    //  0     - SFP
    //  1+    - Any stage
    //  pdis  - Predefined input set
    if (!isNaN(Number(stageParam))) {
      stageParam = Number(stageParam);
    }
    const defaultStage = _.find(stagesList, ({ id }) => id === stageParam);
    if (defaultStage) {
      setSelect(defaultStage);
    } else {
      setSelect(_.find(stagesList, ({ id }) => id === (isSemi ? 0 : 1)));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stagesList, isSemi]);

  const loadPageOptions = async (searchValue) => {
    if (searchValue) {
      const searchResult = stagesList
        ?.filter(s => t(`${s?.code}.label`)?.toLowerCase()?.includes(searchValue?.toLowerCase()));
      return {
        options: searchResult || [],
        hasMore: false,
      };
    }
    if (page) {
      const values = await inputs.getNextPageInputs(page);
      setStagesList(values.data);
      setPage(values.nextPage);
      return {
        options: values.data || [],
        hasMore: values.nextPage,
      };
    }
    return {
      options: [],
      hasMore: page,
    };
  };

  const Switcher = React.useCallback(() => (
    <FormAsyncSelect
      defaultValue={selectedStage}
      getOptionLabel={({ code }) => t(`${code}.label`)}
      getOptionValue={({ id }) => id}
      isLoading={stages.loading}
      isOptionDisabled={disableIfSemifinished}
      isSearchable
      loadOptions={loadPageOptions}
      onChange={setSelect}
      options={stagesList}
    />
  // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [selectedStage, stages, stagesList]);

  const addAnotherInput = () => {
    if (isPdisCalculation && pdisId) {
      history.push(`/calculations/predefined-input-sets/${pdisId}/inputs/add-input`);
    } else {
      history.push(`/calculation/${calculationId}/inputs/add-input`);
    }
    setSelect(stagesList?.find(a => a?.id === 0));
  };

  if (!_.isEmpty(selectedStage)) {
    return !stages.loading && selectedStage?.id === 0
      ? <AddSemifinished withSelect={<Switcher />} />
      : (
        <AddInput
          addAnother={addAnotherInput}
          inputStage={selectedStage}
          isPdisInput={isPdisInput}
          withSelect={<Switcher />}
        />
      );
  }
  return !stages?.loading && stagesList?.length && (
    <AddInput
      inputStage={selectedStage}
      isPdisInput={isPdisInput}
      withSelect={<Switcher />}
    />
  );
};

export default Wrapper;
