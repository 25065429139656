import React, { useEffect } from 'react';
import { useResolveUnit } from '../../../../../../components/FunctionalUnit/FunctionalUnit.hook';
import { SemifinishedDetail } from '../Details/SemifinishedDetail';

export const SemiFinishedProductDetails = ({
  usage, onReady, forceExpanded, updateContext,
}) => {
  const { semiFinishedProduct, standardUnit } = usage;
  const calcType = semiFinishedProduct ? 'semi_finished_product' : 'product';
  const unit = useResolveUnit({
    calculationType: calcType, standardUnit,
  });
  useEffect(() => {
    if (unit && standardUnit) {
      onReady(true);
    }
  }, [onReady, standardUnit, unit]);

  return !(unit && standardUnit) ? null : (
    <SemifinishedDetail
      key={usage.id}
      expandDetails={forceExpanded}
      funcUnit={unit}
      semifinished={usage}
      semifinishedProduct={usage}
      updateContext={updateContext}
    />
  );
};
