import Joi from '@hapi/joi';
import i18next from 'i18next';

export const required = (value, allValues, props, name) => (
  !value && i18next.t('required', { fieldName: i18next.t(`${name}.label`) })
) || undefined;
export const genericRequired = value => (!value && i18next.t('generic required')) || undefined;

export const objWithID = val => Joi.object({
  id: Joi.number().required(),
}).unknown().validate(val)?.error?.message;

export const wasteRegion = val => Joi.object({
  id: Joi.number().required(),
  code: Joi.string().required(),
  name: Joi.string().required(),
}).validate(val)?.error?.message;

export const scalingType = val => Joi.object({
  value: Joi.number().required(),
  label: Joi.string(),
}).validate(val)?.error?.message;

export const amountScalingDimensions = val => Joi.object({
  value: Joi.number().required(),
  label: Joi.string(),
}).validate(val)?.error?.message;

export const descriptionDimension1 = val => Joi.string().validate(val)?.error?.message;
export const dimension1 = val => Joi.number().validate(val)?.error?.message;
export const scalableDimension1 = val => Joi.number().validate(val)?.error?.message;
export const unitDimension1 = val => Joi.string().validate(val)?.error?.message;

export const descriptionDimension2 = val => Joi.string().validate(val)?.error?.message;
export const dimension2 = val => Joi.number().validate(val)?.error?.message;
export const scalableDimension2 = val => Joi.number().validate(val)?.error?.message;
export const unitDimension2 = val => Joi.string().validate(val)?.error?.message;

export const standardUnit = val => Joi.object({
  id: Joi.number().required(),
  quantityName: Joi.string(),
  unit: Joi.string(),
  abbreviation: Joi.string().max(5).min(1),
}).validate(val)?.error?.message;

export const productType = val => Joi.object({
  id: Joi.number().required(),
  name: Joi.string(),
}).validate(val)?.error?.message;

export const transportProfileToConstructionSite = val => Joi.object({
  id: Joi.number().required(),
  name: Joi.string(),
}).unknown().validate(val)?.error?.message;

export const distanceToConstructionSite = val => Joi
  .number()
  .validate(val)?.error?.message;

export const wasteScenarioInputMethod = val => Joi
  .number()
  .required()
  .validate(val)?.error?.message;

export const wasteScenarioProductionWaste = val => Joi.object({
  id: Joi.number().required(),
  name: Joi.string().required(),
}).unknown().validate(val)?.error?.message;

export const wasteScenario = val => Joi.object({
  id: Joi.number().required(),
  name: Joi.string().required(),
}).unknown().validate(val)?.error?.message;

export const productionWasteInputMethod = val => Joi.object({
  label: Joi.string().required(),
  value: Joi.number().required(),
}).unknown().validate(val)?.error?.message;

export const productionWastePercentage = val => Joi
  .number()
  .default(0)
  .min(0).max(100)
  .required()
  .validate(val)?.error?.message;

export const predefinedConstructionWaste = val => Joi.object({
  value: Joi.number().required(),
  label: Joi.string().required(),
}).unknown().validate(val)?.error?.message;

export const constructionWaste = val => Joi
  .number()
  .min(0).max(100)
  .validate(val)?.error?.message;
