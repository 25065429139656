import _ from 'lodash';

export const mapSubtotals = (totals) => {
  const subtotals = {
    Production: 0,
    Construction: 0,
    'Use stage': 0,
    'End of life': 0,
  };

  _.forOwn(totals, (value, key) => {
    switch (key) {
      case 'A1':
      case 'A2':
      case 'A3':
        subtotals.Production += value;
        break;
      case 'A4':
      case 'A5':
        subtotals.Construction += value;
        break;
      case 'B1':
      case 'B2':
      case 'B3':
        subtotals['Use stage'] += value;
        break;
      case 'C1':
      case 'C2':
      case 'C3':
      case 'C4':
      case 'D':
        subtotals['End of life'] += value;
        break;
      default: break;
    }
  });

  return _.toPairs(subtotals);
};
