export const canImportCalculations = user => user?.selectedMembership?.company?.license === 3 && [
  'ROLE_NIBE_EMPLOYEE',
  'ROLE_SUPER_ADMIN', // super admin
  'ROLE_EPD_ADMIN', // company admin + db
  'ROLE_CORPORATE_EPD_ADMIN', // corporate admin + db'
  'ROLE_EPD_ADMIN', // corporate admin
  'ROLE_ADMIN', // company admin
  'ROLE_EDITOR',
].includes(user?.selectedMembership?.role?.name);

// Improvements phase: lets have access control logic in a separate file

// eslint-disable-next-line max-len
export const canImportLocationsAndSuppliers = user => user?.selectedMembership?.view === 'Super Admin'
  || user?.selectedMembership?.view === 'Nibe Employee'
  || user?.selectedMembership?.roleName === 'ROLE_CORPORATE_EPD_ADMIN'
  || user?.selectedMembership?.roleName === 'ROLE_CORPORATE_ADMIN'
  || user?.selectedMembership?.roleName === 'ROLE_EPD_ADMIN'
  || user?.selectedMembership?.roleName === 'ROLE_ADMIN'
  || user?.selectedMembership?.view === 'Editor';
