import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setBreadcrumb } from 'state/sidebar/sidebar.actions';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { useAsync } from 'react-async-hook';

import { versions } from 'services';

import { Placeholder } from 'components/Splash/Loading';
import { TitleBar } from 'routes/GlobalStyle.styled';
import RenderHTML from '../../components/RenderHTML';

const UpdateDetail = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const versionsHook = useAsync(() => versions.getById(id), []);
  const { result } = versionsHook;
  const [versionName, setName] = useState();

  React.useEffect(() => {
    let name = null;
    if (result?.name) {
      name = `${(result?.public
        ? 'Global' : 'Company')} database update - ${result?.name}`;
    } else name = `${(result?.public ? 'Global' : 'Company')} database update`;
    if (name) dispatch(setBreadcrumb(name));
    setName(name);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result]);

  return (
    <>
      <TitleBar />

      <Placeholder loaders={[versionsHook]}>
        <div style={{ width: '38.75rem', marginLeft: '9.4375rem', marginTop: '5.1875rem' }}>
          <p
            style={{
              fontStyle: 'normal',
              fontWeight: 500,
              fontSize: '26px',
              lineHeight: '34px',
              color: '#202538',
              textAlign: 'left',
            }}>
            {versionName || 'Version Name'}
          </p>
          <p
            style={{
              fontStyle: 'normal',
              fontWeight: 500,
              fontSize: '12px',
              lineHeight: '18px',
              color: '#202538',
              textAlign: 'left',
              marginTop: '0.5rem',
            }}>
            {moment(result?.created).format('DD-MM-YYYY HH:mm')}
          </p>
          <RenderHTML
            as="div"
            html={result?.description}
            style={{
              fontStyle: 'normal',
              fontWeight: 'normal',
              fontSize: '14px',
              lineHeight: '21px',
              color: '#202538',
              textAlign: 'left',
              marginTop: '2.2rem',
              width: '38.75rem',
            }}
          />
        </div>
      </Placeholder>
    </>
  );
};

export default UpdateDetail;
