export const SET_TOPIC = 'SET_TOPIC';
export const SET_UPDATE_MERCURE = 'SET_UPDATE_MERCURE';
export const SET_ENV_TOPIC = 'SET_ENV_TOPIC';
export const SET_UPDATE_ENV_MERCURE = 'SET_UPDATE_ENV_MERCURE';
export const SET_CALCULATION_TOPIC = 'SET_CALCULATION_TOPIC';
export const SET_UPDATE_CALCULATION_MERCURE = 'SET_UPDATE_CALCULATION_MERCURE';
export const SET_PDIS_TOPIC = 'SET_PDIS_TOPIC';
export const SET_UPDATE_PDIS_MERCURE = 'SET_UPDATE_PDIS_MERCURE';

export const SET_CALCULATION_REAL_TIME_RESULTS_TOPIC = 'SET_CALCULATION_REAL_TIME_RESULTS_TOPIC';
export const SET_UPDATE_CALCULATION_REAL_TIME_RESULTS = 'SET_UPDATE_CALCULATION_REAL_TIME_RESULTS';
