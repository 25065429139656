/* eslint-disable no-nested-ternary */
import _ from 'lodash';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, Link } from 'react-router-dom';
import { inputName } from 'utils';
import { calculations as calculationService, inputs as inputService } from 'services';
import { useCanEdit, Calculation } from 'hooks/calculation.hooks';
import { useInputsContext } from 'hooks/input.hooks';
import { Prompt } from 'components/Modal';
import { InputView } from 'components/Field';
import DropDown from 'components/DropDown/DropDown';
import { Option } from 'components/DropDownContent/Calculations/Calculations';
import dots from 'assets/dots.svg';
import editIcon from 'assets/edit.svg';
import deleteIcon from 'assets/delete.svg';
import * as S from 'routes/GlobalStyle.styled';
import arrow from 'assets/arrow_show.svg';
import Button from 'components/Button/Button';
import groupIcon from 'assets/group.svg';
import { dataQualityOptions } from '../inputs.helpers';

const CalculationInputItem = ({
  input, expandDetails, blueBackground, isSfp, isPdisItem, updateContext,
}) => {
  const {
    environmentalProfile,
    wasteScenario,
    supplier,
    netAmount,
    id: inputId,
  } = input;
  const canEdit = useCanEdit();
  const { t } = useTranslation();
  const history = useHistory();
  const inputs = useInputsContext();
  const calculation = useContext(Calculation);
  const [expand, setExpand] = useState(false);
  const [copyLoader, setCopyLoader] = useState(false);

  const handleCopyAction = () => {
    setCopyLoader(true);
    calculationService.copyInput(inputId).finally(() => {
      setCopyLoader(false);
      inputs.refresh();
      if (updateContext) updateContext();
    });
  };

  const editInput = {
    label: t('Edit'),
    img: editIcon,
    disable: !canEdit,
    action: () => canEdit && history.push(
      isPdisItem
        ? `edit-input-predefined-input-set?inputId=${inputId}`
        : `edit-input?inputId=${inputId}`,
    ),
  };

  const copyInput = {
    label: t('copy'),
    img: groupIcon,
    disable: !canEdit,
    action: () => canEdit && handleCopyAction(),
  };

  const deleteInput = {
    label: t('Delete'),
    img: deleteIcon,
    disable: !canEdit,
    action: () => canEdit && Prompt.Default({
      title: t('confirmDeletion'),
      text: t('this action cannot be undone'),
      onConfirm: () => (isPdisItem
        ? inputService.deletePdisUsage(inputId).then(() => {
          inputs.refresh();
          if (updateContext) updateContext();
        })
        : calculationService.removeInput(inputId).then(() => {
          inputs.refresh();
          if (updateContext) updateContext();
        })),
    }),
  };

  let wasteUnit = '';
  if (calculation.result?.productionWasteInputMethod !== 'based_on_production_site') {
    wasteUnit = calculation.result?.productionWasteInputMethod === 'per_raw_material_kg'
    || inputs.isPdis
      ? input.environmentalProfile?.unit
      : '%';
  }

  return (
    <li style={{ listStyle: 'none' }}>
      <S.RowContainer blueBackground={blueBackground}>
        <S.SimpleRow onClick={() => setExpand(!expand)}>
          <S.Expand>
            {isPdisItem
              ? `[${input.code}] ${input?.name} | ${input?.netAmount} ${input?.unit}`
              : (
                (expandDetails || expand)
                  ? input?.description
                  : (
                    <>
                      {inputName(input)}
                      {environmentalProfile?.unit || 'kg'}
                    </>
                  )
              )}
          </S.Expand>
          <div style={{ display: 'flex', alignItems: 'center', marginLeft: '2rem' }}>
            {canEdit && !expandDetails && !isSfp && (
            <DropDown
              content={(
                <div>
                  <Option {...editInput} />
                  {!isPdisItem && <Option {...copyInput} />}
                  <Option {...deleteInput} />
                </div>
              )}>
              <img alt="icon" src={dots} />
            </DropDown>
            )}
            {canEdit
              && (
                <S.Img
                  alt="icon"
                  src={arrow}
                  style={{
                    marginLeft: '29px',
                    transform: (expandDetails || expand) && 'rotate(180deg)',
                  }}
                />
              )}
          </div>
        </S.SimpleRow>
        {(expandDetails || expand) && (
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            paddingBottom: '19px',
          }}>
            <article
              style={{
                display: 'flex',
              }}>
              {isPdisItem
                ? (
                  <>
                    <div style={{ marginRight: '1rem', width: '50%' }}>
                      <InputView label={t('Code.label')}>
                        {input?.code}
                      </InputView>
                      <InputView label={t('name.label')}>
                        {input?.name}
                      </InputView>
                      <InputView label={t('Unit.label')}>
                        {input?.unit}
                      </InputView>
                    </div>
                    <div style={{ width: '50%' }}>
                      <InputView label={t('amount.label')}>
                        {input?.netAmount}
                      </InputView>
                      <InputView label={t('methodLabel.label')}>
                        {input?.method?.name}
                      </InputView>
                      <InputView label={t('WasteScenarioSet.label')}>
                        {input?.wasteScenarioSet?.name}
                      </InputView>
                    </div>
                  </>
                )
                : (
                  <>
                    <div style={{ marginRight: '1rem', width: '50%' }}>
                      <InputView label={t('Description')} namespace="inputs">
                        {input?.description}
                      </InputView>

                      {supplier?.name && (
                        <InputView label={t('supplier.label')} namespace="inputs">
                          {supplier?.name}
                        </InputView>
                      )}

                      <InputView
                        label={t('Environmental Profile')}
                        namespace="inputs">
                        {environmentalProfile?.code && environmentalProfile?.name
                          && `[${environmentalProfile?.code}] ${environmentalProfile?.name}`}
                      </InputView>

                      <InputView label={t('Net Amount')} namespace="inputs">
                        {netAmount && `${netAmount} ${environmentalProfile?.unit}`}
                      </InputView>

                      {calculation?.result?.scalingType === 'linear' && (
                      <InputView label={t('linearScalable.label')} namespace="inputs">
                        {input?.linearScalable
                          ? t('yes')
                          : t('no')}
                      </InputView>
                      )}
                      {_.includes([3, 4], input.inputStage?.id) ? null
                        : environmentalProfile?.wasteScenarioNeeded && (
                        <div>
                          {calculation.result?.productionWasteInputMethod
                          !== 'based_on_production_site'
                            && (
                              <InputView
                                label={`${t('productionWaste.label')} (${wasteUnit})`}
                                namespace="inputs">
                                {input?.productionWaste && `${input?.productionWaste} ${wasteUnit}`}
                              </InputView>
                            )}
                          <InputView label={t('Waste Scenario Production')} namespace="inputs">
                            {input?.wasteScenarioProductionWaste?.name}
                          </InputView>
                        </div>
                      )}
                    </div>

                    <div style={{ width: '50%' }}>
                      {environmentalProfile?.wasteScenarioNeeded && (
                        <InputView label={t('wasteScenario.label')} namespace="inputs">
                          {wasteScenario?.name}
                        </InputView>
                      )}

                      {_.includes([4, 3, 5, 6, 7, 8, 11], input?.inputStage?.id) ? null : (
                        <InputView label={t('lifeTime.label')} namespace="inputs">
                          {input?.lifeTime && `${input?.lifeTime} yr`}
                        </InputView>
                      )}

                      {input?.dataQuality && (
                      <InputView label={t('dataQuality.label')} namespace="inputs">
                        {_.find(dataQualityOptions, ({ value }) => value === input?.dataQuality)
                          ?.label}
                      </InputView>
                      )}

                      {input.dataSource && (
                      <InputView label={t('dataSource.label')} namespace="inputs">
                        {input?.dataSource}
                      </InputView>
                      )}

                      {input?.comments && (
                      <InputView label={t('comments.label')} namespace="inputs">
                        {input?.comments}
                      </InputView>
                      )}
                      {input.epSubstantiation && (
                      <InputView label={t('epSubstantiation.label')} namespace="inputs">
                        {input.epSubstantiation}
                      </InputView>
                      )}
                    </div>
                  </>
                )}
            </article>
            {!expandDetails && !isSfp
              && (
                <div style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}>
                  <div style={{
                    display: 'flex',
                  }}>
                    <Button
                      as={Link}
                      style={{
                        width: 'fit-content',
                        alignSelf: 'flex-start',
                        background: '#FDFEFF',
                        border: '1px solid #DCDCDC',
                        boxSizing: 'border-box',
                        boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.1)',
                        borderRadius: '2px',
                        marginRight: '18px',
                      }}
                      styling="select"
                      to={
                        isPdisItem
                          ? `edit-input-predefined-input-set?inputId=${inputId}`
                          : `edit-input?inputId=${inputId}`
                      }>
                      {t('edit')}
                    </Button>
                    {!isPdisItem && (
                      <Button
                        disabled={copyLoader}
                        loading={copyLoader}
                        onClick={handleCopyAction}
                        style={{
                          width: 'fit-content',
                          alignSelf: 'flex-start',
                          background: '#FDFEFF',
                          border: '1px solid #DCDCDC',
                          boxSizing: 'border-box',
                          boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.1)',
                          borderRadius: '2px',
                        }}
                        styling="select">
                        {t('copy')}
                      </Button>
                    )}
                  </div>
                  <Button
                    onClick={deleteInput.action}
                    styling="underline">
                    {t('delete this input')}
                  </Button>
                </div>
              )}
          </div>
        )}
      </S.RowContainer>
    </li>
  );
};

export default React.memo(CalculationInputItem);
