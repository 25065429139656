import React, { useEffect, useState, useRef } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import DatePicker from 'react-datepicker';
import _ from 'lodash';
import { companies as companiesServices, reviews as reviewsServices, calculations } from 'services';
import { selectCompany } from 'state/company/company.actions';
import Name from 'components/TopBar/Name';
import { Loading } from 'components/Splash/Loading';
import CalculationItem from 'components/CalculationItem/CalculationItem';
import BulkActionsBar from 'components/BulkActionsBar/BulkActionsBar';
import TopBar from 'components/TopBar/TopBar';
import AsideOverview from 'components/AsideOverview/AsideOverview';
import AsideLayout from 'components/AsideLayout/AsideLayout';
import Select from 'components/Select/Select';
import Input from 'components/Input/Input';
import LastChangedStatus from 'components/TopBar/LastChangedStatus';
import MainModal from 'components/Modal';
import * as S from 'routes/Calculations/Overview.styled';
import { Label, WrapperInput } from 'routes/GlobalStyle.styled';
import { actionsHoc } from 'config/bulk.helpers';
import moment from 'moment';
import InfiniteScroll from 'react-infinite-scroll-component';
import Spinner from 'components/Spinner';
import { alertToast } from 'config/toast';
import { downloadFile } from 'utils';
import 'react-datepicker/dist/react-datepicker.css';

const { bulk } = calculations;

const ReviewsManage = ({
  selectedCalculations,
}) => {
  const { t } = useTranslation();
  const userDetails = useSelector(({ user }) => user || {});
  const {
    selectedCompany, name: companyName, ...other
  } = useSelector(({ company }) => company || {});
  const [company, setCompany] = useState(selectedCompany);
  const [companies, setCompanies] = useState(null);
  const [reviews, setReviews] = useState(null);
  const [page, setPage] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [clearInput, setClearInput] = useState(null);
  const [sorting, setSorting] = useState('asc');
  const [sortingCompany, setSortingCompany] = useState(null);
  const [lastChange, setLastChange] = useState(null);
  const [status, setStatus] = useState(null);
  const [loading, setLoading] = useState(false);
  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const time = useRef(0);
  const [confirmationModal, setConfirmationModal] = useState(null);
  const [modalContent, setModalContent] = useState(null);
  const [update, setUpdate] = useState(false);
  const [totalNumberOfRecords, setTotalNumberOfRecords] = useState(null);

  const selectedCalc = selectedCalculations?.length === 1
    ? reviews?.find(calc => calc['@id'] === selectedCalculations[0]) : undefined;

  const superAdmin = user?.selectedMembership?.view === 'Super Admin'
    || user?.selectedMembership?.view === 'Nibe Employee';

  const reviewer = user?.selectedMembership?.roleName === 'ROLE_REVIEWER';

  useEffect(() => {
    if (update) setUpdate(false);
    const inputTemp = inputValue ? { productName: inputValue } : null;
    const companyTemp = company?.id ? { owner: company.id } : null;

    const tempFrom = from ? { from } : null;
    const tempTo = to ? { to } : null;

    const params = {
      ...inputTemp, ...companyTemp, ...tempFrom, ...tempTo,
    };

    if (sortingCompany) params['order[owner.name]'] = sortingCompany;
    if (lastChange) params['order[lastSubmissionJournal.createdAt]'] = lastChange;
    if (status) params['order[state]'] = status;
    if (sorting) params['order[productName]'] = sorting;
    if (inputValue) params.search = inputValue;
    params.itemsPerPage = 30;

    if (!companies) {
      companiesServices.getAll()
        .then(values => {
          const arr = _.sortBy(values.companies, c => c?.name?.toLowerCase());
          arr.unshift({ label: 'All', value: 'all' });
          setCompanies(arr);
        });
    }
    if (reviewer) {
      setLoading(true);
      reviewsServices.get({
        params,
      })
        .then(res => {
          setReviews(res.data);
          setPage(res.nextPage);
          setTotalNumberOfRecords(res.totalItems);
        })
        .finally(() => setLoading(false));
    }
  // eslint-disable-next-line max-len
  }, [superAdmin, reviewer, inputValue, company, sorting, lastChange, status, to, from, sortingCompany, update, companies]);

  const handleInputChange = (e) => {
    if (time.current) clearTimeout(time.current);
    const inputText = e.target.value;
    time.current = setTimeout(() => {
      const clearSpaces = inputText && inputText.length > 0 && inputText.trim();
      if (clearSpaces) {
        setInputValue(inputText);
        clearTimeout(time.current);
      } else setInputValue('');
    }, 2000);
  };

  const handleOnKeyDown = (event) => {
    if (event.key === 'Enter') {
      const inputText = event.target.value;
      const clearSpaces = inputText && inputText.length > 0 && inputText.trim();
      if (clearSpaces) setInputValue(inputText);
      else setInputValue('');
    }
  };

  const clearFilter = () => {
    const value = { value: '' };
    setClearInput(value);
    setInputValue('');
    setCompany(null);
    selectCompany({
      name: companyName, label: companyName, value: companyName, ...other,
    })(dispatch);
    setFrom(null);
    setTo(null);
  };

  const onFocus = () => {
    setClearInput(null);
  };

  const handleDropdownChange = (companyValue) => {
    setCompany(companyValue);
    selectCompany(companyValue)(dispatch);
    setLoading(true);
    reviewsServices.get({
      params: {
        owner: companyValue.id, sorting, lastChange, status,
      },
    })
      .then(res => {
        setReviews(res.data);
        setPage(res.nextPage);
        setTotalNumberOfRecords(res.totalItems);
      })
      .finally(() => setLoading(false));
  };

  const CustomDatePicker = ({ text, option, setOption }) => (
    <DatePicker
      customInput={<Input id={text} name={text} style={{ width: '20rem' }} />}
      dateFormat="dd/MM/yyyy"
      maxDate={new Date()}
      onChange={setOption}
      placeholderText={t(text)}
      selected={option}
    />
  );

  const handleReviewAction = (action, func) => {
    const actionFunc = (values) => (func(values));
    setModalContent('review calculation');
    setConfirmationModal({ action, actionFunc, setConfirmationModal });
  };

  const uploadFile = (values, func) => {
    const bodyFormData = new FormData();
    bodyFormData.append('file', values?.documentFile?.[0]);
    return calculations.uploadAllocationImageFile(bodyFormData).then(value => {
      const validationTables = value['@id'];
      return func(validationTables);
    });
  };

  const execute = (calc) => {
    const result = reviews?.map(e => {
      const review = calc?.affectedEntities?.find(r => r?.id === e?.id);
      if (review) {
        e.state = review?.state;
        e.reviewState = review?.state;
      }
      return e;
    });
    setReviews([...result]);
    setUpdate(true);
  };

  const ReviewContent = ({ data }) => {
    const [loadingReport, setLoadingReport] = React.useState({});

    const doDownload = (type, fileObj) => {
      setLoadingReport({ ...loadingReport, [type]: true });
      downloadFile(
        `${process.env.REACT_APP_API_URL}${fileObj?.contentUrl}`,
        fileObj?.originalFilename,
        () => {
          setLoadingReport({ ...loadingReport, [type]: false });
        },
      );
    };

    const retryDownloadReport = async (type, result) => {
      const res = await calculations.getReport(result?.id);
      if (res?.response?.status && (res?.response?.status !== 200)) {
        setLoadingReport({ ...loadingReport, [type]: false });
        return;
      }
      if (res?.mediaObject) {
        const report = await calculations.getReportRef(res?.mediaObject);
        doDownload(type, report);
      } else setTimeout(() => retryDownloadReport(type, result), 5000);
    };

    const downloadReport = (type, format) => async () => {
      alertToast(t('The download of the report has started!'), 'success');
      if (data?.[type]?.contentUrl) {
        doDownload(type, data?.[type]);
      } else if (data?.[type]) {
        calculations.getByRef(data?.[type])
          .then(result => result?.contentUrl && doDownload(type, result));
      } else if (data?.reportable) {
        setLoadingReport({ ...loadingReport, [type]: true });
        let reportsList = data?.reports;
        if (!reportsList?.[0]?.format) {
          reportsList = await Promise.all(reportsList?.map(r => calculations.getReportRef(r)));
        }
        const report = reportsList?.find(r => r?.format === format);
        if (report?.id) retryDownloadReport(type, report);
        else {
          calculations.createReport({ format, calculation: data?.['@id'] })
            .then(result => result?.id && retryDownloadReport(type, result));
        }
      }
    };

    return (
      <>
        <S.Option
          key="accept request"
          isClickable={data?.state === 'submitted'}
          onClick={() => data?.state === 'submitted'
          && bulk.accept([data['@id']]).then(calc => execute(calc))}>
          <S.StyledLabel isRegular>
            {t('accept request')}
          </S.StyledLabel>
        </S.Option>
        <S.Option
          key="deny request"
          isClickable={data?.state === 'submitted'}
          onClick={() => data?.state === 'submitted'
          && handleReviewAction('deny',
            (values) => {
              const reviewStatement = values?.comment;
              return bulk.refuse([data['@id']], { reviewStatement }).then(calc => execute(calc));
            })}>
          <S.StyledLabel isRegular>
            {t('deny request')}
          </S.StyledLabel>
        </S.Option>
        <S.Option
          key="approve calculation"
          isClickable={(data?.state === 'accepted' || data?.state === 'resubmitted')
          && userDetails?.autographImage}
          onClick={() => (data?.state === 'accepted' || data?.state === 'resubmitted')
          && userDetails?.autographImage
          && handleReviewAction('approve',
            (values) => {
              const reviewStatement = values?.comment;
              const reviewApprovedDate = moment().format();

              const func = (validationTables) => {
                const params = { reviewStatement, reviewApprovedDate };
                if (validationTables) params.validationTables = validationTables;
                return bulk.complete([data['@id']], params).then(calc => execute(calc));
              };

              if (values?.documentFile?.[0]) return uploadFile(values, func);
              return func();
            })}>
          <S.StyledLabel isRegular>
            {t('approve calculation')}
          </S.StyledLabel>
        </S.Option>
        <S.Option
          key="reject calculation"
          isClickable={data?.state === 'accepted' || data?.state === 'resubmitted'}
          onClick={() => (data?.state === 'accepted' || data?.state === 'resubmitted')
          && handleReviewAction('reject',
            (values) => {
              const reviewStatement = values?.comment;

              const func = (validationTables) => {
                const params = { reviewStatement };
                if (validationTables) params.validationTables = validationTables;
                return bulk.reject([data['@id']], params).then(calc => execute(calc));
              };

              if (values?.documentFile?.[0]) return uploadFile(values, func);
              return func();
            })}>
          <S.StyledLabel isRegular>
            {t('reject calculation')}
          </S.StyledLabel>
        </S.Option>
        <S.Option
          key="decline calculation"
          isClickable={data?.state === 'accepted' || data?.state === 'resubmitted'}
          onClick={() => (data?.state === 'accepted' || data?.state === 'resubmitted')
          && handleReviewAction('decline',
            (values) => {
              const reviewStatement = values?.comment;
              const func = (validationTables) => {
                const params = { reviewStatement };
                if (validationTables) params.validationTables = validationTables;
                return bulk.decline([data['@id']], params).then(calc => execute(calc));
              };
              if (values?.documentFile?.[0]) return uploadFile(values, func);
              return func();
            })}>
          <S.StyledLabel isRegular>
            {t('decline calculation')}
          </S.StyledLabel>
        </S.Option>
        <S.Option
          key="Review Report (PDF)"
          isClickable={data?.reportable}
          onClick={downloadReport('reviewerReport', 'reviewer')}>
          <S.StyledLabel isRegular>
            {t('Review Report (PDF)')}
          </S.StyledLabel>
        </S.Option>
        <S.Option
          key="Export (CSV)"
          isClickable={data?.reportable}
          onClick={downloadReport('csvExport', 'csv')}>
          <S.StyledLabel isRegular>
            {t('Export (CSV)')}
          </S.StyledLabel>
        </S.Option>
        <S.Option
          key="EPD summary (PDF)"
          isClickable={data?.reportable}
          onClick={downloadReport('shortReport', 'short')}>
          <S.StyledLabel isRegular>
            {t('EPD summary (PDF)')}
          </S.StyledLabel>
        </S.Option>
        <S.Option
          key="EPD (PDF)"
          isClickable={data?.reportable}
          onClick={downloadReport('longReport', 'long')}>
          <S.StyledLabel isRegular>
            {t('EPD (PDF)')}
          </S.StyledLabel>
        </S.Option>
      </>
    );
  };

  const getValidated = (action) => {
    let calc = reviews?.filter(r => selectedCalculations?.some(c => c === r['@id']));
    if (action === 'accept') {
      calc = calc?.filter(r => r?.state === 'submitted');
    }
    if (action === 'deny') {
      calc = calc?.filter(r => r?.state === 'submitted');
    }
    if (action === 'approve') {
      calc = calc?.filter(r => (r?.state === 'accepted' || r?.state === 'resubmitted')
      && userDetails?.autographImage);
    }
    if (action === 'reject') {
      calc = calc?.filter(r => r?.state === 'accepted' || r?.state === 'resubmitted');
    }
    if (action === 'decline') {
      calc = calc?.filter(r => r?.state === 'accepted' || r?.state === 'resubmitted');
    }
    return (calc?.length > 0) ? calc?.map(c => c['@id']) : null;
  };

  const buttonsData = [
    {
      id: 'accept request',
      action: () => {
        const calc = getValidated('accept');
        if (calc) return bulk.accept(calc).then(result => execute(result));
        return null;
      },
      label: t('accept request'),
      disabled: !getValidated('accept'),
    },
    {
      id: 'deny request',
      action: () => handleReviewAction('deny',
        (values) => {
          const reviewStatement = values?.comment;
          const calc = getValidated('deny');
          if (calc) return bulk.refuse(calc, { reviewStatement }).then(result => execute(result));
          return null;
        }),
      label: t('deny request'),
      disabled: !getValidated('deny'),
    },
    {
      id: 'approve calculation',
      action: () => handleReviewAction('approve',
        (values) => {
          const reviewStatement = values?.comment;
          const reviewApprovedDate = moment().format();
          const calc = getValidated('approve');
          if (calc) {
            const func = (validationTables) => {
              const params = { reviewStatement, reviewApprovedDate };
              if (validationTables) params.validationTables = validationTables;
              return bulk.complete(calc, params).then(result => execute(result));
            };
            if (values?.documentFile?.[0]) return uploadFile(values, func);
            return func();
          }
          return null;
        }),
      label: t('approve calculation'),
      disabled: !getValidated('approve'),
    },
    {
      id: 'reject calculation',
      action: () => handleReviewAction('reject',
        (values) => {
          const reviewStatement = values?.comment;
          const calc = getValidated('reject');
          if (calc) {
            const func = (validationTables) => {
              const params = { reviewStatement };
              if (validationTables) params.validationTables = validationTables;
              return bulk.reject(calc, params).then(result => execute(result));
            };
            if (values?.documentFile?.[0]) return uploadFile(values, func);
            return func();
          }
          return null;
        }),
      label: t('reject calculation'),
      disabled: !getValidated('reject'),
    },
    {
      id: 'decline calculation',
      action: () => handleReviewAction('decline',
        (values) => {
          const reviewStatement = values?.comment;
          const calc = getValidated('decline');
          if (calc) {
            const func = (validationTables) => {
              const params = { reviewStatement };
              if (validationTables) params.validationTables = validationTables;
              return bulk.decline(calc, params).then(result => execute(result));
            };
            if (values?.documentFile?.[0]) return uploadFile(values, func);
            return func();
          }
          return null;
        }),
      label: t('decline calculation'),
      disabled: !getValidated('decline'),
    },
  ];

  const selectAllList = reviews?.map(r => r['@id']);

  const BulkBar = actionsHoc(BulkActionsBar, selectedCalculations, selectedCalc, null, buttonsData);

  const fetchData = () => {
    if (page) {
      reviewsServices
        .getNextPage(page)
        .then((values) => {
          setPage(values.nextPage);
          setReviews(reviews.concat(values.data));
          setTotalNumberOfRecords(values.totalItems);
        });
    }
  };

  return (
    <>
      <AsideLayout
        LeftPane={(
          <AsideOverview
            clearFilter={clearFilter}
            clearingFilterCondition={
              (inputValue || company || to || from) && (totalNumberOfRecords !== null)
            }
            hasFilterIcon
            results={totalNumberOfRecords}>
            <WrapperInput>
              <Label>{t('Company')}</Label>

              <Select
                isSearchable
                name="company"
                onChange={handleDropdownChange}
                options={companies}
                placeholder={t('all companies')}
                value={company}
              />
            </WrapperInput>

            <WrapperInput>
              <Label>{t('Name')}</Label>

              <Input
                {...clearInput}
                id="name"
                name="name"
                onChange={handleInputChange}
                onFocus={onFocus}
                onKeyDown={handleOnKeyDown}
                placeholder={t('filter by name or id')}
                style={{ marginBottom: '.68rem' }}
              />
            </WrapperInput>

            <WrapperInput>
              <Label>{t('from')}</Label>

              <CustomDatePicker option={from} setOption={setFrom} text="from" />
            </WrapperInput>

            <WrapperInput>
              <Label>{t('to')}</Label>

              <CustomDatePicker option={to} setOption={setTo} text="to" />
            </WrapperInput>
          </AsideOverview>
        )}>
        <S.Wrapper>
          <S.Main>
            <TopBar>
              <Name
                hasCheckbox
                onOrderBy={order => {
                  setSorting(order);
                }}
                order={sorting}
                selectAllList={selectAllList}
              />
              <div style={{ display: 'flex' }}>
                <div style={{ marginRight: '5rem' }}>
                  <Name
                    inverted
                    label="Company"
                    onOrderBy={() => {
                      if (lastChange) setLastChange(null);
                      if (status) setStatus(null);
                      if (!sortingCompany) setSortingCompany('asc');
                      else if (sortingCompany === 'asc') setSortingCompany('desc');
                      else setSortingCompany(null);
                    }}
                    order={sortingCompany}
                  />
                </div>
                <LastChangedStatus
                  hasStatus
                  inverted
                  lastChangedLabel="resubmitted at"
                  onOrderBy={() => {
                    if (sortingCompany) setSortingCompany(null);
                    if (status) setStatus(null);
                    if (!lastChange) setLastChange('asc');
                    else if (lastChange === 'asc') setLastChange('desc');
                    else setLastChange(null);
                  }}
                  onOrderByStatus={() => {
                    if (sortingCompany) setSortingCompany(null);
                    if (lastChange) setLastChange(null);
                    if (!status) setStatus('asc');
                    else if (status === 'asc') setStatus('desc');
                    else setStatus(null);
                  }}
                  order={lastChange}
                  orderStatus={status}
                />
              </div>
            </TopBar>
            {(loading || !reviews)
              ? Loading
              : (
                <S.OverviewRows id="overviewRowsId">
                  <InfiniteScroll
                    dataLength={reviews?.length}
                    hasMore={page}
                    loader={<Spinner />}
                    next={fetchData}
                    scrollableTarget="overviewRowsId"
                    scrollThreshold={0.4}
                    style={{ minHeight: '50vh' }}>
                    {_.map(reviews, (data) => (
                      <CalculationItem
                        key={data.id}
                        calculation={data}
                        company={data?.owner?.name}
                        isReviews
                        reviewContent={(<ReviewContent data={data} />)}
                        selected={_.includes(selectedCalculations, data['@id'])}
                      />
                    ))}
                  </InfiniteScroll>
                </S.OverviewRows>
              )}
            {!!selectedCalculations.length && !_.isEmpty(selectedCalculations) && (
              <BulkBar count={selectedCalculations.length} />
            )}
          </S.Main>
        </S.Wrapper>
      </AsideLayout>
      <MainModal
        confirmationModalInfo={confirmationModal}
        content={modalContent}
        isModalOpen={confirmationModal}
        setIsModalOpen={setConfirmationModal}
      />
    </>
  );
};

const mapStateToProps = (state) => ({ selectedCalculations: state.calculations.selection });

export default connect(mapStateToProps)(ReviewsManage);
