import React, { useEffect, useCallback, useState } from 'react';
import {
  reduxForm, Form, Field, formValues,
} from 'redux-form';
import { FormInput } from 'components/Input/Input';
import { FormSelect } from 'components/Select/Select';
import Button from 'components/Button/Button';
import { required } from 'routes/Calculations/Detail/Basics/validation';
import { FormFileUpload } from 'components/FileUpload/FileUpload';
import { FormSimpleContentInfo } from 'components/ContentInfo/ContentInfo';
import { isEmailValid } from 'utils/validation';
import { useTranslation } from 'react-i18next';
import { downloadFile } from 'utils';
import { useHistory } from 'react-router-dom';
import MainModal from 'components/Modal';
import { ToggleField } from 'components/SwitchToggle/SwitchToggle';

const CompanyForm = ({
  reset,
  handleSubmit,
  handleSubmitValues,
  pathname,
  isEdit,
  countries,
  licences,
  logo,
  deleteFile,
  pristine,
  submitting,
  corporation,
  companiesList,
  user,
  id,
  type,
  isPublisher,
  autograph1,
  autograph2,
  change,
}) => {
  const { t } = useTranslation();
  const [hasEditedForm, setHasEditedForm] = useState(false);

  const history = useHistory();

  useEffect(() => {
    reset();
  }, [pathname]); // eslint-disable-line react-hooks/exhaustive-deps

  const emailCriteria = useCallback(value => (
    (value && !isEmailValid(value)) ? t('this is not a valid email address') : undefined
  ), []); // eslint-disable-line react-hooks/exhaustive-deps

  const types = [
    {
      label: t('company.label'),
      value: false,
    },
    {
      label: t('corporation'),
      value: true,
    },
  ];

  const superAdmin = user?.selectedMembership?.view === 'Super Admin'
    || user?.selectedMembership?.view === 'Nibe Employee';

  return (
    <>
      <Form onSubmit={handleSubmit((props) => {
        setHasEditedForm(true);
        return handleSubmitValues(props);
      })}>
        <Field
          component={FormInput}
          name="name"
          props={{
            name: 'name',
            placeholder: t('type name'),
          }}
          validate={isEdit ? null : required}
        />
        {superAdmin && (
          <Field
            component={FormInput}
            name="nibeCustomerNumber"
            props={{
              name: 'nibeCustomerNumber',
              placeholder: t('type nibe customer number'),
            }}
            validate={required}
          />
        )}
        {!isEdit && superAdmin
          ? (
            <Field
              component={FormSelect}
              name="type"
              props={{
                name: 'type',
                options: types,
                placeholder: t('select type'),
                isSearchable: true,
              }}
              validate={isEdit ? null : required}
            />
          )
          : isEdit && (
            <FormSimpleContentInfo
              content={corporation ? t('corporation') : t('company.label')}
              name="type"
            />
          )}
        {superAdmin && !type?.value
          && (
            <Field
              component={FormSelect}
              name="Corporation"
              props={{
                name: 'Corporation',
                options: companiesList,
                placeholder: t('select corporation'),
                isSearchable: true,
              }}
            />
          )}
        <Field
          component={FormInput}
          name="address"
          props={{
            name: 'address',
            placeholder: t('type address'),
          }}
          validate={isEdit ? null : required}
        />
        <Field
          component={FormInput}
          name="postalCode"
          props={{
            name: 'postal code',
            placeholder: t('type postal code'),
          }}
          validate={isEdit ? null : required}
        />
        <Field
          component={FormInput}
          name="city"
          props={{
            name: 'city',
            placeholder: t('type city'),
          }}
          validate={isEdit ? null : required}
        />
        <Field
          component={FormSelect}
          name="country"
          props={{
            name: 'country',
            options: countries,
            placeholder: t('select country'),
            isSearchable: true,
          }}
          validate={isEdit ? null : required}
        />
        <Field
          component={FormInput}
          name="email"
          props={{
            name: 'email',
            placeholder: t('type email address'),
          }}
          validate={emailCriteria}
        />
        <Field
          component={FormInput}
          name="website"
          props={{
            name: 'website',
            placeholder: t('type website'),
          }}
        />
        {superAdmin && (
          <Field
            component={FormSelect}
            name="licence"
            props={{
              name: 'licence',
              options: licences,
              placeholder: t('type licence'),
              isSearchable: false,
            }}
          />
        )}
        {isEdit
          ? (
            <Field
              component={FormFileUpload}
              name="logoImageFile"
              props={{
                url: logo,
                id: 'logoImageFile',
                deleteFile: () => deleteFile('logoImage'),
                downloadFile,
                name: 'company logo',
              }}
            />
          ) : null}
        {isEdit && superAdmin && (
        <>
          <Field
            component={ToggleField}
            name="publisher"
            props={{ disabled: !superAdmin }}
          />
          {isPublisher && (
          <>
            <Field
              component={FormInput}
              name="publisherNamePerson1"
              props={{
                placeholder: t('publisherNamePerson1.placeholder'),
                disabled: !superAdmin,
              }}
              validate={isPublisher ? required : null}
            />
            <Field
              component={FormInput}
              name="publisherFunctionPerson1"
              props={{
                placeholder: t('publisherFunctionPerson1.placeholder'),
                disabled: !superAdmin,
              }}
              validate={isPublisher ? required : null}
            />
            <Field
              component={FormFileUpload}
              name="publisherAutographPerson1"
              props={{
                url: (autograph1?.contentUrl)
                && `${process.env.REACT_APP_API_URL}${(autograph1?.contentUrl)}`,
                downloadFile,
                disabled: !superAdmin,
                deleteFile: () => change('publisherAutographPerson1', null),
              }}
              validate={isPublisher ? required : null}
            />
            <Field
              component={FormInput}
              name="publisherNamePerson2"
              props={{
                placeholder: t('publisherNamePerson2.placeholder'),
                disabled: !superAdmin,
              }}
              validate={isPublisher ? required : null}
            />
            <Field
              component={FormInput}
              name="publisherFunctionPerson2"
              props={{
                placeholder: t('publisherFunctionPerson2.placeholder'),
                disabled: !superAdmin,
              }}
              validate={isPublisher ? required : null}
            />
            <Field
              component={FormFileUpload}
              name="publisherAutographPerson2"
              props={{
                url: (autograph2?.contentUrl)
                && `${process.env.REACT_APP_API_URL}${(autograph2?.contentUrl)}`,
                downloadFile,
                disabled: !superAdmin,

                deleteFile: () => change('publisherAutographPerson2', null),
              }}
              validate={isPublisher ? required : null}
            />
          </>
          )}
          <Field component={ToggleField} name="operator" props={{}} />
          {superAdmin && (
          <Field
            component={ToggleField}
            name="reviewCompany"
            props={{ disabled: !superAdmin }}
          />
          )}
        </>
        )}
        <div style={{ display: 'flex' }}>
          <Button
            disabled={pristine}
            loading={submitting}
            style={{ marginRight: '24px' }}
            styling="select"
            type="submit">
            {t('save')}
          </Button>
          <Button
            onClick={() => history
              .push(isEdit
                ? `/manage/companies/${id}` : '/manage/companies')}
            styling="underline">
            {t('cancel')}
          </Button>
        </div>
      </Form>
      <MainModal
        content="prompt"
        exitModalContent={{
          navigateSideEffects: () => null,
        }}
        prompt
        promptCondition={!pristine && !hasEditedForm}
      />
    </>
  );
};

export default reduxForm({
  form: 'new-company',
  enableReinitialize: true,
})(formValues({
  type: 'type',
  isPublisher: 'publisher',
  autograph1: 'publisherAutographPerson1',
  autograph2: 'publisherAutographPerson2',
})(CompanyForm));
