import React from 'react';

const NotFound = () => (
  <main>
    <h1 style={{ color: 'red', fontSize: '3rem' }}>
      404, Page Not Found
    </h1>
  </main>
);

export default NotFound;
