import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';

import { profiles as profilesService } from 'services';

import * as S from 'routes/GlobalStyle.styled';

import LastChangedStatus from 'components/TopBar/LastChangedStatus';
import { Placeholder } from 'components/Splash/Loading';
import Spinner from 'components/Spinner';
import ListItem from 'components/ListItem/ListItem';
import TopBar from 'components/TopBar/TopBar';
import Name from 'components/TopBar/Name';
import ID from 'components/TopBar/ID';

const ProfilesList = ({
  onSelect,
  name,
  section,
  subSection,
  setTotalNumberOfRecords,
}) => {
  const { t } = useTranslation();
  const { id: companyId } = useSelector(({ company }) => company || {});
  const { pathname } = useLocation();
  const pathArray = pathname.split('/');
  const profileType = pathArray[2]; // if it's general or company
  const [selected, setSelected] = useState(profileType);
  const [sorting, setSorting] = useState('asc');
  const [sortingID, setSortingID] = useState(null);
  const [lastChange, setLastChange] = useState(null);
  const [page, setPage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [profiles, setProfiles] = useState([]);

  if (profileType !== selected) setSelected(profileType);

  const itemProps = profile => {
    if (onSelect) {
      return { onClick: e => onSelect(profile, e), as: 'button', type: 'button' };
    }

    return {
      name: profile.name,
      to: {
        pathname: `/environmental-profiles/${profile.id}/basic-information`,
        state: {
          routeName: profile.name,
        },
      },
    };
  };

  const getProfiles = args => {
    const query = {
      public: args.selected !== 'company',
      show: false,
    };

    if (args.section) query.sections = args.section;
    if (args.name) query.search = args.name;
    if (args.sortingID) query['order[code]'] = args.sortingID;
    if (args.lastChange) query['order[lastModifiedAt]'] = args.lastChange;
    if (args.sorting) query['order[name]'] = args.sorting;

    setLoading(true);
    return profilesService
      .all(query)
      .then(response => {
        setProfiles(response.profiles);
        setPage(response.nextPage);
        setTotalNumberOfRecords(response.totalItems);
        return response;
      }).finally(() => setLoading(false));
  };

  useEffect(() => {
    const query = {
      selected,
      sorting,
      sortingID,
      lastChange,
      name,
    };

    const hasSection = (subSection && subSection.id) || (section && section.id);
    if (hasSection) query.section = hasSection;

    getProfiles(query);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId, selected, sorting, sortingID, lastChange, name, section, subSection]);

  const fetchData = () => {
    if (!page) return undefined;
    return profilesService
      .getNextPageProfiles(page)
      .then((values) => {
        setPage(values.nextPage);
        setTotalNumberOfRecords(values.totalItems);
        setProfiles(profiles.concat(values.profiles));
        return values;
      });
  };

  const loaders = [loading];

  return (
    <>
      <TopBar>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div style={{ marginRight: '5rem' }}>
            <ID
              inverted
              onOrderBy={() => {
                if (lastChange) setLastChange(null);
                if (!sortingID) setSortingID('asc');
                else if (sortingID === 'asc') setSortingID('desc');
                else setSortingID(null);
              }}
              order={sortingID}
            />
          </div>
          <Name
            onOrderBy={order => {
              setSorting(order);
            }}
            order={sorting}
          />
        </div>
        <LastChangedStatus
          inverted
          onOrderBy={() => {
            if (sortingID) setSortingID(null);
            if (!lastChange) setLastChange('asc');
            else if (lastChange === 'asc') setLastChange('desc');
            else setLastChange(null);
          }}
          order={lastChange}
        />
      </TopBar>

      <Placeholder loaders={loaders} rows={1}>
        {profiles.length ? (
          <S.OverviewRows id="overviewRowsId">
            <InfiniteScroll
              dataLength={profiles.length}
              hasMore={page}
              loader={<Spinner />}
              next={fetchData}
              scrollableTarget="overviewRowsId"
              scrollThreshold={0.4}>
              {_.map(profiles, p => {
                const { code, unit } = p;
                const subtitles = _.compact([
                  `${t('process used')}: ${p?.processUsed}`,
                  `${t('unit')}: ${unit}`,
                ]);
                return (
                  <ListItem
                    key={p.id}
                    description={subtitles}
                    id={code}
                    lastChanged={p?.lastModifiedAt}
                    {...itemProps(p)}
                  />
                );
              })}
            </InfiniteScroll>
          </S.OverviewRows>
        ) : 'No items to show'}
      </Placeholder>
    </>
  );
};

export default ProfilesList;
