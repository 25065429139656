import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { folders as foldersServices } from 'services';
import Breadcrumb from 'components/Breadcrumb/Breadcrumb';
import Footer from 'components/Footer/Footer';
import { Placeholder } from 'components/Splash/Loading';
import Arrow from 'assets/arrow_big.svg';

import { hideSidebar } from 'state/sidebar/sidebar.actions';

import * as S from './AsideLayout.styled';

const AsideLayout = ({
  LeftPane, RightPane, children, hidePanel, setHidePanel,
}) => {
  const { folderId } = useParams();
  const [isAsideVisible, setIsAsideVisible] = useState(true);
  const [loadingFolders, setLoadingFolders] = React.useState(false);
  const [foldersList, setFolders] = React.useState(null);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (!folderId) setLoadingFolders(true);
    foldersServices.get().then(values => {
      setFolders(values?.['hydra:member']);
    }).finally(() => !folderId && setLoadingFolders(false));
  }, [folderId]);

  return (
    <Placeholder loaders={[loadingFolders]}>
      <S.Container>
        <S.BarWrapper>
          <div>
            <Breadcrumb foldersList={foldersList} />
          </div>
        </S.BarWrapper>

        <S.HideAside
          isVisible={isAsideVisible}
          onClick={() => {
            setIsAsideVisible(!isAsideVisible);
            if (setHidePanel) setHidePanel(!hidePanel);
            else dispatch(hideSidebar());
          }}>
          <S.AsideArrow isVisible={isAsideVisible} src={Arrow} />
        </S.HideAside>

        <S.Wrapper>
          {LeftPane && (
            <S.LeftPane isVisible={isAsideVisible}>
              {LeftPane}

              <S.FooterWrapper>
                <Footer />
              </S.FooterWrapper>
            </S.LeftPane>
          )}

          <S.MainPane isVisible={isAsideVisible}>{children}</S.MainPane>

          {RightPane && <aside>{RightPane}</aside>}
        </S.Wrapper>
      </S.Container>
    </Placeholder>
  );
};

AsideLayout.defaultProps = {
  LeftPane: null,
  RightPane: null,
};

AsideLayout.propTypes = {
  LeftPane: PropTypes.node,
  RightPane: PropTypes.node,
  children: PropTypes.node.isRequired,
};

export default AsideLayout;
