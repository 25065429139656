import React from 'react';

const Verified = ({ color, ...props }) => (
  <svg fill="none" height="10" viewBox="0 0 12 10" width="12" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M3.96 6.43995L1.68 4.15995L0 5.83995L3.96 9.91995L12 1.87995L10.32 0.199951L3.96 6.43995Z"
      fill={color || '#51864C'}
    />
  </svg>
);

export default Verified;
