import styled from 'styled-components/macro';
import { InputWidth, DARK_BLUE } from 'config/style';

export const FieldWrapper = styled.div`
  margin-bottom: 1.5rem;
  width: 100%;
`;

export const LabelWrapper = styled.div`max-width: ${InputWidth};`;

export const Hint = styled.p`
  padding: 2px 3px;
  width: 100%;
  margin-top: 2px;
  margin-bottom: 2px;
  font-family: 'IBM-Plex-Mono', monospace;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 21px;
  color: ${({ hide }) => (hide ? '#202538' : 'transparent')};
`;

export const Label = styled.label`
  font-size: .875rem;
  display: flex;
  margin-bottom: .4rem;
`;

export const MaxWidth = styled.div`
  max-width: ${({ fullInputWidth }) => (fullInputWidth ? InputWidth : '620px')};
`;

export const ReadOnlyLabel = styled(Label)`
  font-size: 1rem;
  font-weight: 500;
`;

export const ErrorContainer = styled.div`
  color: ${DARK_BLUE};
  width: ${InputWidth};
  background: #FFECEC;
  border-radius: 2px;
  padding: .8rem 1.12rem;
  font-size: .875rem;
  border: 1px solid #F03232;
  margin-top: .875rem;
`;

export const HintWrapper = styled.div`
  margin-left: 68px;
  /* position: absolute;
  right: -41.75rem; */
`;

export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  font-size: 14px;
  font-weight: normal;

  & > * {
    width: 100%;
  }

`;

export const StyledLabel = styled.p`
  font-size: 1rem;
  margin-bottom: 6px;
  font-weight: 500;
`;

export const ContentWrapper = styled.p`
  font-weight: 100;
  font-size: .75rem;
`;
