import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: .875rem;

  span {
    margin: 0 .7rem;
  }
`;
