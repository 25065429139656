import React from 'react';
import * as S from 'components/ContentInfo/ContentInfo.styled';
import { withLabels } from 'components/Field/field';
import RenderHTML from '../RenderHTML';

const ContentInfo = ({ content, hasHtml }) => (
  hasHtml
    ? <S.ContentWrapper><RenderHTML as={S.ContentWrapper} html={content} /></S.ContentWrapper>
    : <S.ContentWrapper>{content}</S.ContentWrapper>
);

export const SimpleContentInfo = ({ content }) => (
  <S.SimpleText>{content}</S.SimpleText>
);

export const ContentInfoNoBorder = ({ content, hasHtml }) => (
  hasHtml
    ? <S.ContentWrapperNOBorder dangerouslySetInnerHTML={{ __html: content }} />
    : <S.ContentWrapperNOBorder>{content}</S.ContentWrapperNOBorder>
);

export const FormContentInfo = withLabels(ContentInfo);
export const FormContentInfoNoBorder = withLabels(ContentInfoNoBorder);
export const FormSimpleContentInfo = withLabels(SimpleContentInfo);

export default ContentInfo;

FormContentInfoNoBorder.defaultProps = {
  wrapperStyle: {
    style: {
      marginBottom: '2rem',
    },
  },
  labelStyle: {
    style: {
      fontSize: '1rem',
      marginBottom: '5px',
      fontWeight: '500',
    },
  },
};
