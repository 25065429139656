import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useRouteMatch, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setBreadcrumb } from 'state/sidebar/sidebar.actions';
import { actionsHoc } from 'config/bulk.helpers';
import { Calculation } from 'hooks/calculation.hooks';
import { calculations } from 'services';
import Button from 'components/Button/Button';
import Logs from 'components/Logs/Logs';
import MainModal from 'components/Modal';
import Arrow from 'assets/ArrowSelection.svg';
import TopbarActions from 'components/TopbarActions/TopbarActions';
import moment from 'moment';
import Progress from '../Realtime/Progress/ProgressAside';
import ActionsComponent from './Actions';
import * as S from './DetailDashboard.styled';

const { TitleSection } = S;
const { bulk } = calculations;

const DetailDashboard = () => {
  const { t } = useTranslation();
  const user = useSelector(state => state.user);
  let { url } = useRouteMatch();
  const h = useHistory();
  const dispatch = useDispatch();
  const calculationContext = useContext(Calculation);
  const { result: calculation, execute } = calculationContext;
  const [modalContent, setModalContent] = useState(null);
  const Actions = actionsHoc(
    ActionsComponent, [calculation['@id']], calculation, calculationContext.execute,
  );

  // Fix appended slash when /routing
  if (url.endsWith('/')) {
    url = url.substring(0, url.length - 1);
  }

  React.useEffect(() => {
    if (calculation?.productName) dispatch(setBreadcrumb(calculation?.productName));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calculation]);

  const [confirmationModal, setConfirmationModal] = useState(null);

  const handleReviewAction = (action, func) => {
    const actionFunc = (values) => (func(values));
    setModalContent('review calculation');
    setConfirmationModal({ action, actionFunc, setConfirmationModal });
  };

  const uploadFile = (values, func) => {
    const bodyFormData = new FormData();
    bodyFormData.append('file', values?.documentFile[0]);
    return calculations.uploadAllocationImageFile(bodyFormData).then(value => {
      const validationTables = value['@id'];
      return func(validationTables);
    });
  };

  const validations = (calculation?.state === 'submitted')
    || (calculation?.state === 'resubmitted')
    || ((calculation?.state === 'accepted') && user?.autographImage)
    || (calculation?.state === 'accepted');

  const reviewer = user?.selectedMembership?.roleName === 'ROLE_REVIEWER';

  return (
    <>
      {reviewer && validations
        && (
          <TopbarActions>
            {calculation?.state === 'submitted'
              && (
                <>
                  <Button
                    disabled={calculation?.state !== 'submitted'}
                    onClick={() => bulk.accept([calculation['@id']]).then(() => execute())}
                    style={{ marginRight: '1.875rem' }}
                    styling="select">
                    {t('accept request')}
                  </Button>
                  <Button
                    disabled={calculation?.state !== 'submitted'}
                    onClick={() => handleReviewAction('deny',
                      (values) => {
                        const reviewStatement = values?.comment;
                        return bulk.refuse([calculation['@id']], { reviewStatement })
                          .then(() => {
                            h.push('/reviews');
                          });
                      })}
                    styling="select">
                    {t('deny request')}
                  </Button>
                </>
              )}

            {((calculation?.state === 'accepted' || calculation?.state === 'resubmitted'))
              && (
                <Button
                  disabled={!((calculation?.state === 'accepted'
                  || calculation?.state === 'resubmitted') && user?.autographImage)}
                  onClick={() => handleReviewAction('approve',
                    (values) => {
                      const reviewStatement = values?.comment;
                      const reviewApprovedDate = moment().format();

                      const func = (validationTables) => {
                        const params = { reviewStatement, reviewApprovedDate };
                        if (validationTables) params.validationTables = validationTables;
                        return bulk.complete([calculation['@id']], params).then(() => execute());
                      };

                      if (values?.documentFile?.[0]) return uploadFile(values, func);
                      return func();
                    })}
                  style={{ marginRight: '1.875rem' }}
                  styling="select"
                  title={!user?.autographImage
                  && t('please upload your signature to to approve this calculation')}>
                  {t('approve calculation')}
                </Button>
              )}

            {(calculation?.state === 'accepted' || calculation?.state === 'resubmitted')
              && (
                <>
                  <Button
                    // disabled={calculation?.state !== 'accepted'}
                    onClick={() => handleReviewAction('reject',
                      (values) => {
                        const reviewStatement = values?.comment;

                        const func = (validationTables) => {
                          const params = { reviewStatement };
                          if (validationTables) params.validationTables = validationTables;
                          return bulk.reject([calculation['@id']], params)
                            .then(calc => execute(calc));
                        };

                        if (values?.documentFile?.[0]) return uploadFile(values, func);
                        return func();
                      })}
                    style={{ marginRight: '1.875rem' }}
                    styling="underline">
                    {t('reject calculation')}
                  </Button>
                  <Button
                    // disabled={calculation?.state !== 'accepted'}
                    onClick={() => handleReviewAction('decline',
                      (values) => {
                        const reviewStatement = values?.comment;

                        const func = (validationTables) => {
                          const params = { reviewStatement };
                          if (validationTables) params.validationTables = validationTables;
                          return bulk.decline([calculation['@id']], params)
                            .then(() => {
                              h.push('/reviews');
                            });
                        };

                        if (values?.documentFile?.[0]) return uploadFile(values, func);
                        return func();
                      })}
                    styling="underline">
                    {t('decline calculation')}
                  </Button>
                </>
              )}
          </TopbarActions>
        )}
      <S.Container>
        <S.StyledSection style={{ width: reviewer ? '100%' : null }}>
          <S.TitleSection>
            <h1>{t('log')}</h1>

            <Button as={Link} styling="select" to={`${url}/logs`}>
              {t('See all')}
              <img alt="icon" src={Arrow} style={{ marginLeft: '1.2rem' }} />
            </Button>
          </S.TitleSection>

          <S.ScrollWrapper>
            <Logs noTitle rowsToShow={3} />

            <Actions />
          </S.ScrollWrapper>
        </S.StyledSection>

        {!reviewer && (
          <S.StyledSection borderLeft rightPane>
            <TitleSection>
              <h1>{t('progress')}</h1>

              <Button as={Link} styling="select" to={`${url}/realtime/progress`}>
                {t('See all')}
                <img alt="icon" src={Arrow} style={{ marginLeft: '1.2rem' }} />
              </Button>
            </TitleSection>

            <S.ScrollWrapper>
              <Progress />
            </S.ScrollWrapper>
          </S.StyledSection>
        )}
      </S.Container>

      <MainModal
        confirmationModalInfo={confirmationModal}
        content={modalContent}
        isModalOpen={confirmationModal}
        setIsModalOpen={setConfirmationModal}
      />
    </>
  );
};

export default DetailDashboard;
