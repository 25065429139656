import React, {
  useState, useRef, useEffect, cloneElement,
} from 'react';

import * as S from './DropDown.styled';

import { getDropDownPosition } from './dropDownPosition';

const DropDown = ({
  children, content, style, type, alignLeft,
}) => {
  const dropDownRef = useRef(null);
  const divRef = useRef(null);

  const [isVisible, setIsVisible] = useState(false);
  const [placementDropDown, setPlacementDropDown] = useState('');

  const show = (e) => {
    e.stopPropagation();
    setIsVisible(true);
  };

  const close = (e) => {
    e.stopPropagation();
    setPlacementDropDown('');
    setIsVisible(false);
  };

  useEffect(
    () => {
      if (isVisible) {
        const dropDownReference = dropDownRef.current;
        const dotsIconReference = divRef.current;

        setPlacementDropDown(getDropDownPosition(dropDownReference, dotsIconReference));
      }
    },
    [isVisible],
  );

  return (
    <S.StyledContainer style={style}>
      <S.StyledText onClick={(e) => show(e)}>
        <div ref={divRef}>
          {children}

          {isVisible && (
            <div style={{ position: 'relative' }}>
              <S.Overlay onClick={(e) => close(e)} />
              <S.StyledArrow
                className="arrow"
                placement={placementDropDown.placement}
                type={type}
              />

              <S.StyledDropDown
                ref={dropDownRef}
                alignLeft={alignLeft}
                placement={placementDropDown.placement}
                top={placementDropDown.toTop}
                type={type}>
                <div
                  onClick={(e) => close(e)}
                  onKeyDown={(e) => close(e)}
                  role="button"
                  tabIndex="0">
                  {cloneElement(content, { close })}
                </div>
              </S.StyledDropDown>
            </div>
          )}
        </div>
      </S.StyledText>
    </S.StyledContainer>
  );
};

export default DropDown;
