import React, { useEffect } from 'react';
import {
  Link,
  useRouteMatch,
  useParams,
  Redirect,
} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setBreadcrumb } from 'state/sidebar/sidebar.actions';
import { useTranslation } from 'react-i18next';

import { useCalculationMethod } from 'hooks/methods.hooks';

import Button from 'components/Button/Button';
import { FormContentInfoNoBorder } from 'components/ContentInfo/ContentInfo';
import { Loading } from 'components/Splash/Loading';

import * as S from 'routes/GlobalStyle.styled';

const styleOverrides = {
  items: { marginRight: '6.87rem' },
  header: {
    marginBottom: 0,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
};

const LCAMethod = () => {
  const { t } = useTranslation();
  const { url } = useRouteMatch();
  const { id } = useParams();
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const { selectedCompany, id: companyId } = useSelector(({ company }) => company || {});
  const { result: method, loading } = useCalculationMethod(id);

  useEffect(() => {
    if (method?.name) dispatch(setBreadcrumb(method?.name));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [method]);

  const superAdmin = user?.selectedMembership?.view === 'Super Admin'
    || user?.selectedMembership?.view === 'Nibe Employee';

  if (!superAdmin) return <Redirect to="/" />;
  if (loading) return Loading;

  const isEditable = companyId === selectedCompany?.id;

  return (
    <>
      <S.HeaderTitle style={styleOverrides.header}>
        {t('lcamethod profile')}

        <Button
          as={Link}
          style={{ pointerEvents: !isEditable && 'none' }}
          styling="select"
          to={isEditable && `${url}/edit`}>
          {t('edit lca method')}
        </Button>
      </S.HeaderTitle>

      <S.Container>
        <S.ItemsContainer>
          <div style={{ marginRight: '9.18rem' }}>
            <FormContentInfoNoBorder content={method?.name} name="name" />
            <FormContentInfoNoBorder content={method?.pcr} name="pcr" />
            <FormContentInfoNoBorder content={method?.software} name="lca software" />
            <FormContentInfoNoBorder
              content={method?.characterizationMethod}
              name="characterization method"
            />
            <FormContentInfoNoBorder
              content={method?.publisher?.name || '-'}
              name="methodPublisher"
            />
            <FormContentInfoNoBorder
              content={method?.operator?.name || '-'}
              name="methodOperator"
            />
            <FormContentInfoNoBorder
              content={method?.registrationNumberPrefix?.name || '-'}
              name="registrationNumberPrefix"
            />
            <FormContentInfoNoBorder
              content={method?.registrationNumberStartValue?.name || '-'}
              name="registrationNumberStartValue"
            />
          </div>
          <div>
            <FormContentInfoNoBorder content={method?.lcaDatabase} name="lca database profiles" />
            <FormContentInfoNoBorder
              content={method?.purposeDescription}
              hasHtml
              name="simple purpose and target groups"
            />
          </div>
        </S.ItemsContainer>
      </S.Container>
    </>
  );
};

export default LCAMethod;
