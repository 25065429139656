import React from 'react';
import { useTranslation } from 'react-i18next';
import { Field, Form, reduxForm } from 'redux-form';
import { required } from 'routes/Calculations/Detail/Basics/validation';
import Button from 'components/Button/Button';
import { FormFileUpload } from 'components/FileUpload/FileUpload';
import { FormSimpleCKEditor } from '../../SimpleCKEditor';

const Reviews = ({
  pristine,
  invalid,
  submitting,
  handleSubmit,
  action,
  actionFunc,
  setConfirmationModal,
}) => {
  const { t } = useTranslation();

  const handleSubmitValues = (values) => {
    if (actionFunc) {
      const promises = [];
      promises.push(new Promise(resolve => {
        resolve(actionFunc(values));
      }));
      return Promise.all(promises).finally(() => setConfirmationModal(null));
    }
    return null;
  };

  const onChange = (editor, input) => {
    input.onChange(editor.getData() || null);
  };

  return (
    <div style={{ width: '57.5rem', height: '32rem' }}>
      <p style={{ marginBottom: '1rem' }}>
        {t(`you are about to ${action} this calculation`)}
      </p>

      <Form onSubmit={handleSubmit(handleSubmitValues)}>
        <div>
          <Field
            component={FormSimpleCKEditor}
            name="comment"
            props={{
              cols: '80',
              rows: '4',
              spellCheck: true,
              name: 'comment',
              placeholder: t('Type-comments'),
              onChange,
            }}
            validate={required}
          />
        </div>
        {action !== 'deny'
          && (
            <Field
              component={FormFileUpload}
              name="documentFile"
              props={{
                id: 'documentFile',
                name: 'documentFile',
              }}
            />
          )}

        <div style={{ display: 'flex', justifyContent: 'space-between', width: '21rem' }}>
          <Button
            disabled={pristine || invalid}
            loading={submitting}
            style={{ marginRight: '1rem' }}
            styling="select"
            type="submit">
            {t(action)}
          </Button>

          <Button onClick={() => setConfirmationModal(null)} styling="underline">
            {t('cancel')}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default reduxForm({
  form: 'modal-review',
  enableReinitialize: true,
})(Reviews);
