import _ from 'lodash';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { inputName } from 'utils';
import {
  useInputsByKey, useInputsContext, useUnit, useWeight,
} from 'hooks/input.hooks';
import { Calculation } from 'hooks/calculation.hooks';
import Table from 'components/CalculationTable/Table';
import Pricing from 'components/Pricing/Pricing';
import { NoContent, Placeholder } from 'components/Splash/Loading';
import * as S from './Realtime.styled';
import { useRealTimeResultsContext } from '../../../../../hooks/realTimeResults.hooks';

const barWidth = (amount, overall) => {
  const width = amount / overall;
  if (width < 0) return width * -1;
  return width;
};

const Realtime = () => {
  const { t } = useTranslation();
  const inputs = useInputsContext();
  const { results: realtimeContextValue = {}, loading, failed } = useRealTimeResultsContext();
  const { result: calculation } = useContext(Calculation);
  const unit = useUnit(calculation);
  const {
    top,
    other,
    subtotals,
    overall,
  } = realtimeContextValue || {};

  const inputsByKey = useInputsByKey();

  const { weight, loading: weightLoading } = useWeight();

  if (failed) return NoContent;

  return (
    <Placeholder loaders={[{ loading }, inputs]}>
      {() => (
        <S.Container>
          {!calculation.submittable && (
            <S.AlertContainer>
              This calculation is not complete yet.&nbsp;
              <Link style={{ textDecoration: 'underline' }} to="progress">
                Check progress
              </Link>
            </S.AlertContainer>
          )}

          <S.InfoContainer>
            <S.MaterialInfo>
              <S.Title style={{ marginLeft: '1.62rem' }}>
                Material
              </S.Title>

              <div>
                {inputsByKey && top && _.map(_.keys(top), (key) => (inputsByKey[key] ? (
                  <S.Row key={key} total={top[key].total}>
                    <S.ProgressBackground width={barWidth(top[key].total, overall)} />
                    <S.Name>{inputName(inputsByKey[key])}</S.Name>

                    <Pricing cost={top[key].total} style={{ zIndex: 2 }} />
                  </S.Row>
                ) : console.log('Missing', key, Object.keys(inputsByKey))))?.sort((a, b) => b?.props?.total - a?.props?.total)}

                {!_.isEmpty(other) && (
                <S.Row>
                  <S.ProgressBackground width={barWidth(other.total, overall, true)} />
                  <S.Name>{t('remaining materials')}</S.Name>

                  <Pricing cost={other.total} />
                </S.Row>
                )}
              </div>
            </S.MaterialInfo>

            <S.AditionInformation>
              <S.Title>Aditional Information</S.Title>

              <div>
                {_.map(subtotals, ([label, cost]) => (
                  <S.Row key={label}>
                    <S.Name>{t(label)}</S.Name>
                    <Pricing cost={cost} />
                  </S.Row>
                ))}

                <S.Row>
                  <S.Name>
                    <span>{t('weight')}</span>
                    {unit ? <sup>{` kg / ${unit}`}</sup> : null}
                  </S.Name>
                  <span>{weightLoading ? '-' : weight}</span>
                </S.Row>

                <S.Row totalRow>
                  <S.Name>
                    {t(calculation?.method?.onePointScoreName)}
                    {' '}
                    (
                    {calculation?.method?.onePointScoreUnit}
                    )
                  </S.Name>
                  <Pricing cost={overall} />
                </S.Row>
              </div>
            </S.AditionInformation>
          </S.InfoContainer>

          <S.TableContainer>
            <Table inputsByKey={inputsByKey} />
          </S.TableContainer>
        </S.Container>
      )}
    </Placeholder>
  );
};

export default Realtime;
