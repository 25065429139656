import { alertToast, errorToast } from 'config/toast';
import i18next from 'i18next';

import network from './network';

const hydraMember = ({ 'hydra:member': data }) => data;

const paginatedMember = ({
  'hydra:member': data,
  'hydra:view': view,
  'hydra:totalItems': totalItems,
}) => ({
  data,
  nextPage: view && view['hydra:next'],
  totalItems,
});

export const stages = () => network
  .get('/input_stages', { cache: true })
  .then(hydraMember)
  .then((r = []) => [
    {
      '@id': '/2/input_stages/0',
      '@type': 'InputStage',
      id: 0,
      code: 'SEMI_FINISHED',
      description: 'SEMI_FINISHED',
    },
    {
      '@id': '/2/input_stages/pdis',
      '@type': 'InputStage',
      id: 'pdis',
      code: 'PDIS',
      description: 'PDIS',
    },
    ...r,
  ])
  .catch(errorToast);

export const inputStages = (params) => network
  .get('/input_stages', { params })
  .then(paginatedMember)
  .then(res => ({
    ...res,
    data: [
      {
        '@id': '/2/input_stages/0',
        '@type': 'InputStage',
        id: 0,
        code: 'SEMI_FINISHED',
        description: 'SEMI_FINISHED',
      },
      {
        '@id': '/2/input_stages/pdis',
        '@type': 'InputStage',
        id: 'pdis',
        code: 'PDIS',
        description: 'PDIS',
      },
      ...res.data,
    ],
  }))
  .catch(errorToast);

export const getNextPageInputs = (url) => network
  .get(`${process.env.REACT_APP_API_URL}${url}`, { params: { owner: 'none' } })
  .then(paginatedMember)
  .catch(errorToast);

export const getByRef = ref => ref && network
  .get(`${process.env.REACT_APP_API_URL}${ref}`)
  .catch(errorToast);

export const inputs = (id) => network
  .get(`/calculations/${id}/inputs`, { headers: { Accept: 'application/json' } })
  .catch(errorToast);

export const input = (inputId) => network
  .get(`/inputs/${inputId}`)
  .catch(errorToast);

export const removeInput = (inputId) => network
  .delete(`/inputs/${inputId}`)
  .catch(errorToast);

export const saveInput = (payload, inputId) => {
  const [verb, url] = inputId ? ['put', `/inputs/${inputId}`] : ['post', '/inputs'];

  return network[verb](url, payload)
    .catch(errorToast);
};

export const usages = id => id && network
  .get(`/product_calculations/${id}/semi_finished_product_usages`)
  .then(hydraMember)
  .catch(errorToast);

export const usage = id => id && network
  .get(`/semi_finished_product_usages/${id}`)
  .catch(errorToast);

export const removeUsage = (usageId) => network
  .delete(`/semi_finished_product_usages/${usageId}`)
  .catch(errorToast);

export const saveUsage = (payload, inputId) => {
  const [verb, url] = inputId
    ? ['put', `/semi_finished_product_usages/${inputId}`]
    : ['post', '/semi_finished_product_usages'];

  return network[verb](url, payload).catch(errorToast);
};

export const getPdis = (params) => network
  .get('/predefined_input_sets', { params })
  .then(paginatedMember)
  .catch(errorToast);

export const copyPdis = id => network
  .post('/group_actions/copy_predefined_input_sets', { predefinedInputSets: [id] })
  .then(() => alertToast('Copied!', 'success'))
  .catch(errorToast);

export const deletePdis = (id) => network
  .delete(`/predefined_input_sets/${id}`)
  .then(result => {
    alertToast(i18next.t('The predefined input set has been deleted successfully!'), 'success');
    return result;
  })
  .catch(errorToast);

export const deletePdisUsage = (id) => network
  .delete(`/predefined_input_set_usages/${id}`)
  .then(result => {
    alertToast(
      i18next.t('The predefined input set usage has been deleted successfully!'), 'success',
    );
    return result;
  })
  .catch(errorToast);

export const getNextPage = (url) => network
  .get(`${process.env.REACT_APP_API_URL}${url}`, { params: { owner: 'none' } })
  .then(paginatedMember)
  .catch(errorToast);

export const createPdis = (params) => network
  .post('/predefined_input_sets', params)
  .then(result => {
    alertToast(i18next.t('The predefined input set has been created successfully!'), 'success');
    return result;
  });

export const editPdis = (id, params) => network
  .put(`/predefined_input_sets/${id}`, params)
  .then(result => {
    alertToast(i18next.t('The predefined input set has been edited successfully!'), 'success');
    return result;
  });

export const getPdisDetail = (id) => network
  .get(`/predefined_input_sets/${id}`)
  .catch(errorToast);

export const getPdisUsages = (id) => network
  .get(`/calculations/${id}/predefined_input_set_usages`)
  .then(async res => {
    const data = paginatedMember(res);

    return {
      ...data,
      inputs: data.data.map(pdis => pdis.inputs.map(i => ({ ...i, pdis }))).flat(),
    };
  })
  .catch(errorToast);

export const getPdisUsage = (id) => network
  .get(`/predefined_input_set_usages/${id}`)
  .catch(errorToast);

export const saveUsagePdis = (payload, inputId) => {
  const [verb, url] = inputId
    ? ['put', `/predefined_input_set_usages/${inputId}`]
    : ['post', '/predefined_input_set_usages'];

  return network[verb](url, payload).catch(errorToast);
};

export const getPdisUsedCalculations = (id, params) => network
  .get(`/predefined_input_sets/${id}/predefined_input_set_usages`, { params })
  .then(paginatedMember)
  .catch(errorToast);

export const getPdisInputs = (id) => network
  .get(`/predefined_input_sets/${id}/inputs`)
  .then(paginatedMember)
  .catch(errorToast);
