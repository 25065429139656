import React, { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import HTML from 'components/RenderHTML';

import {
  Label,
  Hint,
  FieldWrapper,
  ErrorContainer,
  HintWrapper,
  InputWrapper,
  LabelWrapper,
  ContentWrapper,
  StyledLabel,
  ReadOnlyLabel,
  MaxWidth,
} from './field.styled';

export const withLabels = Comp => {
  const ComposedComponent = memo(({
    name,
    count,
    translationName,
    id,
    translation,
    children,
    input,
    meta,
    // namespace = 'forms',
    ...other
  }) => {
    const { t } = useTranslation();
    const [hover, setHover] = useState(false);
    const inputName = name || input?.name || id;
    const props = other;

    let hasLabel = translation?.label
    ?? (t(`${inputName}.label`).endsWith('.label')
      ? null
      : t(`${inputName}.label`));

    if (translationName && count) hasLabel = t(`${translationName}.label`, count);

    const renderLabel = hasLabel && (
      <Label {...props?.labelStyle} htmlFor={inputName}>
        {hasLabel}
      </Label>
    );

    const hasHint = translation?.hint
      ?? (t(`${inputName}.hint`).endsWith('.hint')
        ? null
        : t(`${inputName}.hint`));
    const renderHint = hasHint
      && <Hint hide={(meta?.active || hover)}><HTML html={hasHint} /></Hint>;

    const placeholder = props.placeholder
      || (translation?.placeholder ?? (t(`${inputName}.placeholder`).endsWith('.placeholder')
        ? undefined
        : t(`${inputName}.placeholder`)));

    if (input) props.input = input;
    if (meta) props.meta = meta;
    if (inputName) { props.name = inputName; props.id = inputName; }
    if (placeholder) props.placeholder = placeholder;

    return (
      <FieldWrapper
        {...props?.wrapperStyle}
        onMouseLeave={() => setHover(false)}
        onMouseOver={() => setHover(true)}>
        <LabelWrapper>{renderLabel}</LabelWrapper>

        <InputWrapper>
          <MaxWidth fullInputWidth={props?.fullInputWidth}>
            <Comp {...props}>
              {children}
            </Comp>
          </MaxWidth>

          {!props?.fullInputWidth
            && (
              <HintWrapper
                style={props?.custom && { marginLeft: '0', width: 'calc(100% + 144.1px)' }}>
                {props?.custom
                  && (
                    <div style={{ width: 'auto' }}>
                      {props?.custom()}
                    </div>
                  )}
                {renderHint}
              </HintWrapper>
            )}
        </InputWrapper>

        {meta?.touched && meta?.error && (
          <ErrorContainer>
            {meta?.touched && meta?.error}
          </ErrorContainer>
        )}
      </FieldWrapper>
    );
  });

  return ComposedComponent;
};

export const ReadOnlyField = memo(({
  name,
  id,
  translation,
  children,
  input,
  meta,
  translationParams,
  // namespace = 'forms',
  ...other
}) => {
  const { t } = useTranslation();
  const inputName = name || input?.name || id;
  const props = other;

  function hasLabel() {
    return t(`${inputName}.label`, translationParams)
      .endsWith('.label')
      ? null
      : t(`${inputName}.label`, translationParams);
  }

  const hasName = translation?.name
  ?? (t(`${inputName}.name`, translationParams).endsWith('.name')
    ? hasLabel()
    : t(`${inputName}.name`, translationParams));

  const renderLabel = hasName && (
    <ReadOnlyLabel {...props?.labelStyle} htmlFor={inputName}>
      {hasName}
    </ReadOnlyLabel>
  );

  if (input) props.input = input;
  if (inputName) { props.name = inputName; props.id = inputName; }

  return (
    <FieldWrapper {...props?.wrapperStyle}>
      <LabelWrapper>{renderLabel}</LabelWrapper>

      <InputWrapper>
        {children}
      </InputWrapper>
    </FieldWrapper>
  );
});

export const ReadOnlyImageField = memo(({ name, alt, src }) => (
  <ReadOnlyField name={name}>
    {src ? <img alt={alt} src={src} style={{ maxWidth: 500 }} /> : '-'}
  </ReadOnlyField>
));

export const InputView = ({ label, children }) => (
  <div style={{ marginBottom: '1.31rem' }}>
    <StyledLabel>{label}</StyledLabel>
    <ContentWrapper>{children}</ContentWrapper>
  </div>
);

export default withLabels(({ children }) => children);
