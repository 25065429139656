import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import {
  Link, useHistory, useLocation, useParams,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { users as usersService } from 'services';
import ListItem from 'components/ListItem/ListItem';
import Name from 'components/TopBar/Name';
import TopBar from 'components/TopBar/TopBar';
import Spinner from 'components/Spinner';
import * as S from 'routes/Calculations/Overview.styled';
import InfiniteScroll from 'react-infinite-scroll-component';
import Button from 'components/Button/Button';
import edit from 'assets/edit.svg';
import deleteIcon from 'assets/delete.svg';
import { confirmationModalSetup } from 'components/Modal/ModalContent/Delete';
import MainModal from 'components/Modal';

const CompaniesPermissions = ({ id: userIdProp }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { id: userId } = useParams();
  const [memberships, setMemberships] = useState(null);
  const [page, setPage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(null);
  const [sorting, setSorting] = useState('asc');
  const { pathname } = useLocation();

  const id = userIdProp || userId;

  useEffect(() => {
    setLoading(true);
    const params = { user: id };
    if (sorting) params['order[company.name]'] = sorting;
    usersService.getUserMemberships(id, { params }, { value: false })
      .then((values) => {
        setMemberships(values.data);
        setPage(values.nextPage);
      })
      .finally(() => setLoading(false));
  }, [id, sorting]);

  const fetchData = () => {
    if (page) {
      usersService
        .getNextPage(page)
        .then((values) => {
          setPage(values.nextPage);
          setMemberships(memberships.concat(values.data));
        });
    }
  };

  const getEditUrl = data => (pathname.includes('user-profile')
    ? `/user-profile/memberships/${data?.id}/edit`
    : `/manage/users/${id}/memberships/${data?.id}/edit`);

  const onEditItem = (data) => history
    .push(getEditUrl(data),
      getRouteParams(data));

  const getRouteParams = data => ({
    routeName: pathname.includes('user-profile') ? data?.company?.name : data?.name,
  });

  const onDeleteItem = (data) => {
    confirmationModalSetup(setConfirmationModal, (closeModal) => {
      usersService.deleteUserMembership(data?.id).then(() => {
        const tempMemberships = memberships.filter(membership => membership.id !== data?.id);
        setMemberships(tempMemberships);
        closeModal();
      });
    });
  };

  const ListItemContent = ({ data }) => (
    <>
      <S.Option key="edit" isClickable onClick={() => onEditItem(data)}>
        <div>
          <img alt="icon" src={edit} />
        </div>

        <S.StyledLabel isRegular>
          {t('edit')}
        </S.StyledLabel>
      </S.Option>
      <S.Option key="delete" isClickable onClick={() => onDeleteItem(data)}>
        <div>
          <img alt="icon" src={deleteIcon} />
        </div>

        <S.StyledLabel color="#BF2E00" isRegular>
          {t('delete')}
        </S.StyledLabel>
      </S.Option>
    </>
  );

  const usersList = () => (
    memberships
      ? (
        <S.OverviewRows id="overviewRowsId">
          <InfiniteScroll
            dataLength={memberships.length}
            hasMore={page}
            loader={<Spinner />}
            next={fetchData}
            scrollableTarget="overviewRowsId"
            scrollThreshold={0.4}
            style={{ minHeight: '50vh' }}>
            {_.map(memberships, (data) => {
              const description = data.roles.map(v => (v?.view === 'Super Admin'
                ? v?.view?.replace('Super Admin', 'Ultra Admin') : v?.view)).join(', ');
              return (
                <ListItem
                  key={data.id}
                  dropdownContent={(<ListItemContent data={data} />)}
                  isCompaniesPermissions
                  name={data.company.name}
                  permissions={description}
                  to={{
                    pathname: getEditUrl(data),
                    state: getRouteParams(data),
                  }}
                />
              );
            })}
          </InfiniteScroll>
        </S.OverviewRows>
      )
      : null
  );

  return (
    <>
      <TopBar>
        <div style={{
          display: 'flex',
        }}>
          <Name
            label="companies"
            onOrderBy={order => {
              setSorting(order);
            }}
            order={sorting}
          />
          <div style={{ marginLeft: '22.5rem' }}>
            <Name
              label="Permissions"
              noSorting
            />
          </div>
        </div>
        <Button
          as={Link}
          style={{ margin: 0, padding: '.75rem auto' }}
          styling="select"
          to={{
            pathname: pathname.includes('user-profile')
              ? '/user-profile/memberships/new'
              : `/manage/users/${id}/memberships/new`,
            state: {
              routeName: memberships && memberships[0]?.user?.name,
            },
          }}>
          {t('Assign new company permissions')}
        </Button>
      </TopBar>

      {!memberships || loading
        ? (
          <div
            style={{
              height: 'calc(100vh - 4rem)',
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            <Spinner />
          </div>
        )
        : usersList()}
      <MainModal
        confirmationModalInfo={confirmationModal}
        content="delete"
        isModalOpen={confirmationModal}
        setIsModalOpen={setConfirmationModal}
      />
    </>
  );
};

export default CompaniesPermissions;
