import React from 'react';

const Review = ({ color, ...props }) => (
  <svg fill="none" height="10" viewBox="0 0 15 10" width="15" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      // eslint-disable-next-line max-len
      d="M7.4026 2.49584C6.08766 2.49584 5.03247 3.59401 5.03247 4.94176C5.03247 6.28952 6.1039 7.38769 7.41883 7.38769C8.73377 7.38769 9.80519 6.28952 9.80519 4.94176C9.78896 3.59401 8.71753 2.49584 7.4026 2.49584ZM14.6591 4.52579C14.5779 4.39268 13.3279 2.62895 11.4286 1.34775C10.5195 0.732113 9.70779 0.415973 9.12338 0.232945C8.6039 0.0831947 8.06818 0 7.5 0C3.53896 0 0.470779 4.32612 0.340909 4.50915L0 4.99168L0.340909 5.47421C0.438312 5.60732 1.98052 7.78702 4.26948 9.06822C5.0974 9.53411 5.81169 9.75042 6.26623 9.85025C6.37987 9.86689 6.49351 9.90017 6.60714 9.91681C6.62338 9.91681 6.63961 9.91681 6.65584 9.91681C6.72078 9.93344 6.76948 9.93344 6.83442 9.93344H6.85065C7.06169 9.96672 7.28896 9.98336 7.5 9.98336C11.461 9.98336 14.5292 5.65724 14.6591 5.47421L15 4.99168L14.6591 4.52579ZM7.5 8.31947C7.20779 8.31947 6.89935 8.28619 6.62338 8.21964C6.62338 8.21964 5.99026 8.1198 5.08117 7.62063C4.78896 7.45424 4.51299 7.28785 4.25325 7.08819C4.23701 7.07155 4.23701 7.07155 4.22078 7.07155L4.20455 7.05491C3.23052 6.33944 2.46753 5.50749 2.04545 4.99168C2.88961 3.96007 5.0487 1.66389 7.5 1.66389C7.71104 1.66389 7.92208 1.68053 8.13312 1.71381C8.13312 1.71381 9.26948 1.86356 10.4221 2.66223C10.4221 2.66223 10.4221 2.66223 10.4383 2.66223C11.5584 3.41098 12.4675 4.39268 12.9545 4.97504C12.1104 6.02329 9.9513 8.31947 7.5 8.31947Z"
      fill={color || '#336282'}
    />
  </svg>
);

export default Review;
