import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { FormWidth, InputWidth } from 'config/style';

export const Container = styled.div`
  width: ${FormWidth};
  margin-left: 9.31rem;
  padding: 0 0 3rem;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  width: ${InputWidth};
  justify-content: space-between;
`;

export const Header = styled.header`
  width: ${FormWidth};
  margin-bottom: 1.5rem;
`;

export const TitleHeader = styled.label`
  font-size: .875rem;
  margin-bottom: .4rem;
  display: block;
`;

export const StyledLink = styled(Link)`
  font-size: .875rem;
  text-decoration: underline;
  margin-bottom: .75rem;
`;

export const InputWrapper = styled.section`
  width: ${FormWidth};
`;
