import _ from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import { useAsync } from 'react-async-hook';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import moment from 'moment';
import {
  versions, calculations as calculationsService, reviews as reviewsServices,
} from 'services';

import Button from 'components/Button/Button';
import CalculationCard from 'components/CalculationCard/CalculationCard';
import Footer from 'components/Footer/Footer';
import Spinner from 'components/Spinner';

import { DashboardButton } from 'config/style';
import AlertIcon from 'assets/alert.svg';
import * as S from './Dashboard.styled';

const get5Versions = company => versions
  .all({ params: { 'order[created]': 'desc' } }, company);

const get3Calculations = () => calculationsService
  .all({ itemsPerPage: 3, 'order[lastModifiedAt]': 'desc' })
  .then(({ data }) => data);

const get3Reviews = () => reviewsServices
  .get({ params: { itemsPerPage: 3, 'order[lastModifiedAt]': 'desc' } });

const get5Announcements = () => versions
  .allAnnouncements({ owner: 'none', 'order[publishedAt]': 'desc' });

const Dashboard = () => {
  const { t } = useTranslation();
  const {
    id: companyId,
    '@id': companyRef,
    memberShip,
  } = useSelector(({
    company,
    user,
  }) => ({
    company: company || {},
    memberShip: user.selectedMembership || {},
  }));
  const {
    result: updates = [], loading: loadUpdates,
  } = useAsync(get5Versions, [companyRef, memberShip]);

  const isReviewer = memberShip?.view === 'Reviewer';

  const { result, loading } = useAsync(
    isReviewer ? get3Reviews : get3Calculations, [memberShip],
  );

  const {
    result: announcements = [], loading: loadAnnouncements,
  } = useAsync(get5Announcements, [companyId, memberShip]);

  return (
    <>
      <S.StyledContainer>
        <S.StyledSection>
          <S.StyledTop>
            <S.StyledTitle>
              {t(isReviewer ? 'Recent reviews' : 'Recent calculations')}
            </S.StyledTitle>
            <Button
              as={Link}
              style={DashboardButton}
              to={isReviewer ? '/reviews' : '/calculations'}>
              {t(isReviewer ? 'all reviews' : 'all calculations')}
            </Button>
          </S.StyledTop>

          {loading
            ? <Spinner />
            : (
              <S.Wrapper>
                {_.map(isReviewer ? result?.data : result, (data) => (
                  <CalculationCard
                    key={data.id}
                    data={data}
                  />
                ))}
              </S.Wrapper>
            )}
        </S.StyledSection>

        <S.UpdateAndNotificationSection>
          <S.StyledColumn>
            <S.StyledTop>
              <S.StyledTitle>{t('announcements')}</S.StyledTitle>

              <Button as={Link} style={DashboardButton} to="/announcements">{t('See more')}</Button>
            </S.StyledTop>

            <S.UpdatesContainer>
              {loadAnnouncements
                ? <Spinner />
                : _.map(announcements?.versions?.sort((a, b) => ((moment(a.stickyUntil).isAfter()
                || moment(b.stickyUntil).isAfter())
                  ? new Date(b.stickyUntil) - new Date(a.stickyUntil) : 0)).slice(0, 5), ({
                  publishedAt, title, stickyUntil, id,
                }) => (
                  <S.UpdateRow
                    key={id}
                    $alert={moment(stickyUntil).isAfter()}
                    to={`/announcements/${id}`}>
                    <div>
                      <p>{title}</p>
                      <p>{moment(publishedAt).fromNow()}</p>
                    </div>
                    <img alt="alert icon" src={AlertIcon} />
                  </S.UpdateRow>
                ))}
            </S.UpdatesContainer>

          </S.StyledColumn>
          <S.StyledColumn>
            <S.StyledTop>
              <S.StyledTitle>{t('updates')}</S.StyledTitle>
              <Button
                as={Link}
                style={DashboardButton}
                to="/updates">
                {t('all notifications')}
              </Button>
            </S.StyledTop>

            <S.UpdatesContainer>
              {loadUpdates
                ? <Spinner />
                : _.map(_.take(updates?.versions
                  ?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)), 5), (data) => {
                  let name = '';
                  if (data?.versionName) {
                    name = `${(data?.public
                      ? 'Global' : 'Company')} database update - ${data?.versionName}`;
                  } else name = `${(data?.public ? 'Global' : 'Company')} database update`;
                  return (
                    <S.UpdateRow key={data?.id} to={`/updates/${data?.id}`}>
                      <div>
                        <p>{name}</p>
                        <p>{moment(data?.createdAt).fromNow()}</p>
                      </div>
                    </S.UpdateRow>
                  );
                })}
            </S.UpdatesContainer>
          </S.StyledColumn>
        </S.UpdateAndNotificationSection>
      </S.StyledContainer>

      <S.FooterContainer><Footer /></S.FooterContainer>
    </>
  );
};

export default Dashboard;
