import React, { memo, lazy, Suspense } from 'react';

import { withLabels } from 'components/Field/field';
import { Loading } from 'components/Splash/Loading';

// Lazy-Load editor
const SimpleCKEditor = lazy(() => import('./SimpleCKEditor'));

const HTMLEditor = props => (
  <Suspense fallback={Loading}>
    <SimpleCKEditor {...props} />
  </Suspense>
);

export const FormSimpleCKEditor = withLabels(HTMLEditor);

export default memo(HTMLEditor);
