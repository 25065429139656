import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';

import { calculations as inputService } from 'services';
import { useCanEdit } from 'hooks/calculation.hooks';

import { Prompt } from 'components/Modal';
import { InputView } from 'components/Field';
import DropDown from 'components/DropDown/DropDown';
import { Option } from 'components/DropDownContent/Calculations/Calculations';
import FunctionalUnit from 'components/FunctionalUnit/FunctionalUnit';

import dots from 'assets/dots.svg';
import editIcon from 'assets/edit.svg';
import deleteIcon from 'assets/delete.svg';
import Button from 'components/Button/Button';
import { scalingOptions } from '../../../Advanced/Editor.helpers';
import { SemiFinishedRow } from '../Rows/SemiFinishedRow';

const SemifinishedDetailComponent = ({
  semifinished, expandDetails, updateContext, semifinishedProduct = {}, funcUnit,
}) => {
  const { id: usageId, amount, scalingType } = semifinished;
  const canEdit = useCanEdit();
  const history = useHistory();
  const { t } = useTranslation();

  const editInputSfp = {
    label: t('Edit'),
    img: editIcon,
    disabled: !canEdit,
    action: () => canEdit && history.push(`edit-input-semifinished?inputId=${usageId}`),
  };

  const deleteInputSfp = {
    label: t('Delete'),
    img: deleteIcon,
    disabled: !canEdit,
    action: () => canEdit
      && Prompt.Default({
        title: t('confirmDeletion'),
        text: t('this action cannot be undone'),
        onConfirm: () => inputService.removeUsage(usageId).then(updateContext),
      }),
  };

  return (
    <li style={{ listStyle: 'none' }}>
      <SemiFinishedRow
        forceExpanded={expandDetails}
        funcUnit={funcUnit}
        product={{
          amount,
          productName: semifinishedProduct.productName,
        }}
        renderActions={() => (
          canEdit && !expandDetails ? (
            <DropDown
              content={(
                <div>
                  <Option {...editInputSfp} />
                  <Option {...deleteInputSfp} />
                </div>
              )}>
              <img alt="icon" src={dots} />
            </DropDown>
          ) : null)}>
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          paddingBottom: '19px',
        }}>
          <article
            style={{
              display: 'flex',
            }}>
            <div>
              <InputView label={t('amount.label')} namespace="inputs">
                {amount}
                <FunctionalUnit
                  calculation={{
                    calculationType: 'semi_finished_product',
                    standardUnit: semifinishedProduct?.standardUnit,
                  }}
                />
              </InputView>

              <InputView label={t('scalingType.label')}>
                {_.find(scalingOptions, ({ value }) => value === scalingType).label}
              </InputView>

              <InputView label={t('semiFinishedProduct.label')} namespace="inputs">
                {semifinishedProduct.productName}
              </InputView>
            </div>
          </article>
          {!expandDetails
          && (
            <div style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}>
              <div style={{
                display: 'flex',
              }}>
                <Button
                  as={Link}
                  style={{
                    width: 'fit-content',
                    alignSelf: 'flex-start',
                    background: '#FDFEFF',
                    border: '1px solid #DCDCDC',
                    boxSizing: 'border-box',
                    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.1)',
                    borderRadius: '2px',
                    marginRight: '18px',
                  }}
                  styling="select"
                  to={`edit-input-semifinished?inputId=${usageId}`}>
                  {t('edit')}
                </Button>
              </div>
              <Button
                onClick={deleteInputSfp.action}
                styling="underline">
                {t('delete this semi finished product')}
              </Button>
            </div>
          )}
        </div>
      </SemiFinishedRow>
    </li>
  );
};

export const SemifinishedDetail = React.memo(SemifinishedDetailComponent);
