import _ from 'lodash';
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Button from 'components/Button/Button';
/* import DropDown from 'components/DropDown/DropDown';
import CalculationsContent from 'components/DropDownContent/Calculations/Calculations';

import openIcon from 'assets/open.svg'; */

import * as S from './BulkActionsBar.styled';

const BulkActionsBar = ({
  buttonsData, downloadButtons, count, isVisible, noDownload,
}) => {
  const { t } = useTranslation();

  return (
    <S.Wrapper isVisible={isVisible}>
      <S.Elements>
        {t('items selected', { count })}
      </S.Elements>

      <span style={{ display: 'flex' }} />

      <S.Section>
        {_.chain(buttonsData)
          .reject(({ isDownload, id }) => isDownload || id === 'edit')
          .map(({ action, label, disabled }) => (
            <Button
              key={label}
              disabled={disabled}
              onClick={action}
              styling="white">
              {label}
            </Button>
          ))
          .value()}

        {/* EPD-919 */}
        {/* !noDownload && (
          <DropDown
            content={<CalculationsContent buttonsData={downloadButtons} type="download" />}
            type="bottom">
            <Button styling="white">
              <S.OpenWrapper>
                Download

                <S.OpenContainer>
                  <S.OpenImage alt="icon" src={openIcon} />
                </S.OpenContainer>
              </S.OpenWrapper>
            </Button>
          </DropDown>
        ) */}
      </S.Section>
    </S.Wrapper>
  );
};

BulkActionsBar.propTypes = {
  count: PropTypes.number.isRequired,
  buttonsData: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default memo(BulkActionsBar);
