import React, { useEffect, useState, useContext } from 'react';
import {
  reduxForm,
  Form,
  formValues,
} from 'redux-form';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Redirect, useHistory, useParams } from 'react-router-dom';

import { calculations } from 'services';
import { useCanEdit, Calculation } from 'hooks/calculation.hooks';

import Button from 'components/Button/Button';
import * as S from 'routes/GlobalStyle.styled';
import { downloadFile } from 'utils';
import { confirmationModalSetup } from 'components/Modal/ModalContent/Delete';
import MainModal from 'components/Modal';

const Substantiations = ({
  data = {},
  handleSubmitValues,
  formField,
  getFieldValues,
  submitting,
  handleSubmit,
  noEmissionsDeclaration,
  productSubstantiationRSL,
  productPartSubstantiationRSL,
  constructionWasteFile,
  hasEditedForm,
  isTemplate,
  updateTemplate,
}) => {
  const { t } = useTranslation();
  const { isHidden } = useSelector(state => state.sidebar);
  const { execute, result } = useContext(Calculation);
  const canEdit = useCanEdit();
  const [confirmationModal, setConfirmationModal] = useState(null);
  const [hasSubmited, setHasSubmited] = useState(false);
  const isSemi = (result?.calculationType === 'semi_finished_product');

  useEffect(() => {
    getFieldValues({
      noEmissionsDeclaration,
      productSubstantiationRSL,
      productPartSubstantiationRSL,
      constructionWasteFile,
    });
  }, [
    getFieldValues,
    noEmissionsDeclaration,
    productSubstantiationRSL,
    productPartSubstantiationRSL,
    constructionWasteFile,
  ]);

  const history = useHistory();
  const { id: calcId } = useParams();

  if (!canEdit) return <Redirect to="view" />;

  const deleteFile = () => confirmationModalSetup(setConfirmationModal, (closeModal) => {
    if (data?.constructionWasteFile) {
      if (isTemplate) {
        calculations.saveTemplate(data.id, { constructionWasteFile: null })
          .then(() => {
            updateTemplate();
            closeModal();
          });
      } else {
        calculations.save(data.id, { constructionWasteFile: null })
          .then(() => {
            execute();
            closeModal();
          });
      }
    } else closeModal();
  });

  return (
    <S.Background isEditable>
      <S.HeaderTitle>{t('information-for-report')}</S.HeaderTitle>
      <S.FormContainer isHidden={isHidden}>
        <Form onSubmit={ev => {
          ev.preventDefault();

          handleSubmit(handleSubmitValues)().then(() => {
            setHasSubmited(true);
          });
        }}>
          {!isSemi && formField({
            simpleEditor: !data?.template,
            label: 'statement emissions during use stage',
            calculationContent: data.noEmissionsDeclaration,
            calculationKey: 'noEmissionsDeclaration',
            template: data?.template,
          })}
          {formField({
            simpleEditor: !data?.template,
            label: 'source Reference Service Life (Rsl) Product',
            calculationContent: data.productSubstantiationRSL,
            calculationKey: 'productSubstantiationRSL',
            template: data?.template,
          })}
          {formField({
            simpleEditor: !data?.template,
            label: 'source Reference Service Life (Rsl) Product parts',
            calculationContent: data.productPartSubstantiationRSL,
            calculationKey: 'productPartSubstantiationRSL',
            template: data?.template,
          })}
          {formField({
            simpleEditor: true,
            label: 'general substantiation',
            calculationContent: {
              url: (data.constructionWasteFile || data?.template?.constructionWasteFile)
                && `${process.env.REACT_APP_API_URL}${(data.constructionWasteFile?.contentUrl || data?.template?.constructionWasteFile?.contentUrl)}`,
              content: data.constructionWasteFile || data?.template?.constructionWasteFile,
            },
            calculationKey: 'constructionWasteFile',
            fileUpload: {
              allowPdf: true,
              disableDelete: !!data?.template?.constructionWasteFile?.contentUrl,
            },
            deleteFile,
            downloadFile,
          })}
          <div style={{ display: 'flex', marginTop: '1rem' }}>
            <Button
              loading={submitting}
              style={{ marginRight: '2rem' }}
              styling="select"
              type="submit">
              {t('save')}
            </Button>
            <Button
              onClick={() => {
                if (isTemplate) {
                  history
                    .push(`/calculations/templates/${calcId}/information-for-report/substantiations/view`);
                } else {
                  history
                    .push(`/calculation/${calcId}/information-for-report/substantiations/view`);
                }
              }}
              styling="underline">
              {t('cancel')}
            </Button>
          </div>
        </Form>
      </S.FormContainer>
      <MainModal
        confirmationModalInfo={confirmationModal}
        content="delete"
        isModalOpen={confirmationModal}
        setIsModalOpen={setConfirmationModal}
      />
      <MainModal
        content="prompt"
        exitModalContent={{
          navigateSideEffects: () => null,
        }}
        prompt
        promptCondition={hasEditedForm && !hasSubmited}
      />
    </S.Background>
  );
};

export default reduxForm({
  form: 'substantiations',
  enableReinitialize: true,
})(formValues({
  noEmissionsDeclaration: 'noEmissionsDeclaration',
  productSubstantiationRSL: 'productSubstantiationRSL',
  productPartSubstantiationRSL: 'productPartSubstantiationRSL',
  constructionWasteFile: 'constructionWasteFile',
})(Substantiations));
