import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useAsyncCallback } from 'react-async-hook';
import { locations } from 'services';
import { Placeholder } from 'components/Splash/Loading';
import * as S from 'routes/GlobalStyle.styled';

import { confirmationModalSetup } from 'components/Modal/ModalContent/Delete';
import MainModal from 'components/Modal';
import LocationForm from 'routes/SuppliersAndLocations/LocationForm';
import { SubmissionError } from 'redux-form';
import { setBreadcrumb } from 'state/sidebar/sidebar.actions';

const NewLocation = () => {
  const { t } = useTranslation();
  const { isHidden } = useSelector(state => state.sidebar);
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const isEdit = pathname.split('/')[4] === 'edit';

  const [location, setLocation] = useState(null);
  const [confirmationModal, setConfirmationModal] = useState(null);

  const locationResult = useAsyncCallback(() => locations.get(id));

  const countries = locations.countries();

  useEffect(() => {
    if (location?.name) dispatch(setBreadcrumb(location?.name));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    if (isEdit) {
      locationResult.execute();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isEdit && !location && !locationResult.loading
    && !locationResult.error && locationResult.result) setLocation(locationResult.result);

  const handleSubmitValues = (values) => {
    const tempValues = values;

    const name = tempValues.name ? { name: tempValues.name } : isEdit && { name: location.name };
    const address = tempValues.address ? { address: tempValues.address }
      : isEdit && { address: location.address };
    const postalCode = tempValues.postalCode ? { postalCode: tempValues.postalCode }
      : isEdit && { postalCode: location.postalCode };
    const city = tempValues.city ? { city: tempValues.city } : isEdit && { city: location.city };
    const country = tempValues.country ? { country: tempValues.country.value }
      : isEdit && { country: location.country };

    const locationInfo = {
      ...tempValues,
      ...country,
      ...name,
      ...address,
      ...postalCode,
      ...city,
    };

    if (isEdit) {
      return locations.editLocation(id, locationInfo)
        .then(() => {
          history.push(`/locations-&-suppliers/locations/${id}`, { routeName: name?.name });
        })
        .catch((error) => {
          if (error?.response?.data?.errors?.children?.code?.errors) {
            throw new SubmissionError({
              code: t('unique ID has already been used for another location'),
            });
          }
        });
    }
    return locations.createLocation(locationInfo)
      .then(() => history.push('/locations-&-suppliers/locations'))
      .catch((error) => {
        if (error?.response?.data?.errors?.children?.code?.errors) {
          throw new SubmissionError({
            code: t('unique ID has already been used for another location'),
          });
        }
      });
  };

  const country = isEdit
    && countries?.find(countryValue => countryValue.value === location?.country);

  const onDeleteItem = () => {
    confirmationModalSetup(setConfirmationModal, () => {
      locations.deleteLocation(id).then(() => history.push('/locations-&-suppliers/locations'));
    });
  };

  return (
    <S.Background isEditable>
      <Placeholder
        loaders={[isEdit && locationResult]}
        rows={3}>
        <S.HeaderTitle>{t(isEdit ? 'edit location' : 'add location')}</S.HeaderTitle>
        <S.FormContainer key={isEdit} isHidden={isHidden}>
          <LocationForm
            countries={countries}
            handleSubmitValues={handleSubmitValues}
            initialValues={{
              name: location?.name,
              code: location?.code,
              address: location?.address,
              postalCode: location?.postalCode,
              city: location?.city,
              country,
            }}
            isEdit={isEdit}
            onDeleteItem={onDeleteItem}
            pathname={pathname}
          />
        </S.FormContainer>
        <MainModal
          confirmationModalInfo={confirmationModal}
          content="delete"
          isModalOpen={confirmationModal}
          setIsModalOpen={setConfirmationModal}
        />
      </Placeholder>
    </S.Background>
  );
};

export default NewLocation;
