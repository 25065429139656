import styled, { css } from 'styled-components/macro';
import { Link } from 'react-router-dom';

import { GREY } from 'config/style';

export const StyledTitle = styled.h1`
  font-weight: 500;
  font-size: 1.2rem;
  margin-bottom: 20px;
`;

export const StyledContainer = styled.div`
  width: 480px;
  padding: 25px 35px;
`;

export const ContactsWrapper = styled.div`
  display: flex;
  flex-direction: column;

  &:not(:last-child) {
    margin-bottom: 40px;
  }
`;

export const StyledContact = styled.div`
  font-size: 14px;
  line-height: 21px;
  font-weight: bold;
  &:not(:last-child) {
    margin-bottom: 20px;
  }

  .contact {
    text-decoration: underline;
    cursor: pointer;
  }

  .text {
    font-weight: normal;
  }
`;

export const Wrapper = styled.div`
  min-width: 290px;
  padding: 10px 0;
  min-height: 5rem;
`;

export const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  padding: 10px 20px;
  cursor: pointer;
  transition: .3s;

  &:hover {
    background: ${GREY};
  }

  div {
    min-width: 25px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 1rem;
  }
`;

export const StyledLogout = styled.button`
  appearance: none;
  text-align: left;
  border: 0;
  cursor: pointer;
  transition: .25s;
  font-weight: normal;
  width: 100%;
  border-top: 1px solid #E4E4E4;
  padding: 20px;
  display: block;
  background: white;
  &:hover {
    background: ${GREY};
  }
`;

export const StyledLabel = styled.span`
  color: ${({ labelColor }) => labelColor || 'currentColor'};
  ${({ isRegular }) => isRegular && css`font-weight: normal`};
`;

export const StyledLine = styled.div`
  ${(props) => props.hasLine && 'border-top: 1px solid #E4E4E4; margin: .4rem 0 '};
`;

export const NotificationsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  min-width: 25rem;
`;

export const NotificationWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

export const NotificationCard = styled(Link)`
  border: 1px solid #EBEBEB;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  width: 100%;
  z-index: 10;

  img {
    display: ${(props) => (props.isNew ? 'block' : 'none')};
  }
  &:not(:last-child) {
    margin-bottom: 10px;
  }
`;

export const NotificationIsNew = styled.img`margin-right: 14px;`;

export const NotificationContent = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  margin-right: 30px;

  p:nth-child(2) {
    margin-top: 5px;
    font-size: 12px;
    font-weight: normal;
  }
`;

export const NotificationButton = styled.button`
  display: flex;
  align-items: center;
  background: none;
  align-self: flex-end;
  border: none;
  font-weight: 600;
  cursor: pointer;
  margin: 6px 0;
  line-height: 21px;
  img {
    margin-left: 10px;
    width: 16.5px;
  }

  &:focus {
    outline: none;
  }
`;

export const Option = styled.button`
  display: flex;
  align-items: center;
  background: white;
  border: 0;
  width: 100%;
  padding: 10px 20px;
  transition: .3s;
  cursor: pointer;
  font-size: .875rem;

  &:disabled {
    cursor: not-allowed;
    opacity: .3;
  }

  &:hover {
    background: ${GREY};
  }

  img {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 1rem;
  }
`;
