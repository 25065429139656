import { useAsync } from 'react-async-hook';
import { errorToast } from 'config/toast';

import network from './network';

/**
 * Helpers
*/

const hydraMember = ({ 'hydra:member': data }) => data;

/**
 * Network
*/

export const getWasteScenarios = config => network
  .get('/waste_scenarios', config)
  .then(hydraMember)
  .catch((err) => errorToast(err));

export const getWasteScenarioById = (id, config) => id && network
  .get(`/waste_scenarios/${id}`, config)
  .catch((err) => errorToast(err));

/**
 * Hooks
*/

export const useWasteScenario = (id) => useAsync(
  getWasteScenarioById,
  [id],
);
