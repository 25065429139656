import _ from 'lodash';
import React, { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Collapse } from '@material-ui/core';
import { Placeholder } from 'components/Splash/Loading';
import arrow from 'assets/arrow_show.svg';

import { inputName } from 'utils';
import { useInputsContext } from 'hooks/input.hooks';
import { Calculation } from 'hooks/calculation.hooks';
import { useReportItems, useStages } from './Progress.hooks';
import {
  Row, WrapperDiv, Items, CollapseRow, ErrorLabel, StyledRow,
} from './Progress.styled';

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  const { t } = useTranslation();
  const inputsContext = useInputsContext();
  const { result } = useContext(Calculation);
  const { state, hash } = useLocation();
  const { collapse } = state || {};
  const parseCollapseCode = collapse || _.toInteger(hash && hash.match(/stage-\d/)[0].split('-')[1]);
  const { status, items } = useReportItems();
  const { inputs, errors, allReady } = useStages();
  const [collapseCode, setCollapse] = useState(parseCollapseCode);
  const isSemi = (result?.calculationType === 'semi_finished_product');

  const handleCollapse = (code) => {
    if (code === 0) return setCollapse(collapseCode === 0 ? false : 0);
    if (collapseCode === code) return setCollapse(false);
    return setCollapse(code || collapse);
  };

  useEffect(
    () => {
      setCollapse(parseCollapseCode);
    },
    [parseCollapseCode],
  );

  const loaders = [!allReady];

  const [collapseId, setCollapseId] = React.useState(null);

  const handleCollapseId = (id) => {
    if (id === 0) return setCollapseId(collapseId === 0 ? false : 0);
    if (collapseId === id) return setCollapseId(false);
    return setCollapseId(id || collapse);
  };

  return (
    <Placeholder loaders={loaders}>
      <main>
        <div>
          <Row
            $alert={!!status.errorCount}
            active={collapseCode === 0}
            onClick={() => handleCollapse(0)}>
            <p>{t('Information for report')}</p>

            <WrapperDiv>
              {!!status.errorCount && (
                <Items>{t('items incomplete', { count: status.errorCount })}</Items>
              )}
              <img alt="icon" src={arrow} />
            </WrapperDiv>
          </Row>

          <Collapse
            in={collapseCode === 0}
            style={{ background: status.errorCount ? '#FFF6D0' : null }}>
            {_.map(items, ({ name, errors: reportErrors }) => (
              <CollapseRow key={name} $alert={reportErrors.length}>
                <StyledRow $alert={reportErrors.length}>
                  <p>{name}</p>
                  {_.map(reportErrors, (error) => <ErrorLabel key={error}>{error}</ErrorLabel>)}
                </StyledRow>
              </CollapseRow>
            ))}
          </Collapse>
        </div>

        {_.compact(_.map(_.drop(inputsContext.stages), ({ id: code, code: label }) => {
          const stageInputs = _.has(inputs, code) && !_.isNil(inputs[code]) && inputs[code];
          const stageErrors = _.get(errors, code);

          if (isSemi && _.includes([8, 9, 10, 11], code)) return null;

          return (
            <div key={code} id={`stage-${code}`}>
              <Row
                $alert={stageErrors}
                active={collapseCode === code}
                disabled={!stageErrors}
                noRemoveBorder
                onClick={() => handleCollapse(code)}>
                <p>{t(`${label}.label`)}</p>

                <WrapperDiv>
                  {!!stageErrors && <Items>{t('items incomplete', { count: stageErrors })}</Items>}
                  <img alt="collapse arrow closed" src={arrow} />
                </WrapperDiv>
              </Row>

              {!!stageErrors && (
                <Collapse in={collapseCode === code} style={{ background: '#FFF6D0' }}>
                  {_.map(stageInputs, ({ input, errors: inputErrors }) => (
                    <CollapseRow>
                      <Row
                        $alert={inputErrors?.length}
                        active={collapseId === input?.id}
                        onClick={() => handleCollapseId(input?.id)}
                        removeBorder>
                        <div style={{ maxWidth: '85%', textAlign: 'left' }}>
                          {input['@type'] === 'PredefinedInputSetUsage'
                            ? `[${input?.predefinedInputSet.code}] ${
                            input?.predefinedInputSet?.name
                            } | ${
                            input?.amount
                            } ${
                            input?.predefinedInputSet?.unit
                            }` : inputName(input)}
                          {input?.environmentalProfile?.unit}
                        </div>

                        <WrapperDiv>
                          {!!inputErrors?.length && (
                            <Items>{t('items incomplete', { count: inputErrors?.length })}</Items>
                          )}
                          <img alt="collapse arrow closed" src={arrow} />
                        </WrapperDiv>
                      </Row>
                      <Collapse in={collapseId === input?.id} style={{ background: '#FFF6D0' }}>
                        <CollapseRow
                          key={input.id}
                          $alert={inputErrors.length}
                          invalid={inputErrors.length}>
                          {_.map(inputErrors, (error) => (
                            <StyledRow key={error} $alert>
                              {error}
                            </StyledRow>
                          ))}
                        </CollapseRow>
                      </Collapse>
                    </CollapseRow>
                  ))}
                </Collapse>
              )}
            </div>
          );
        }))}
      </main>
    </Placeholder>
  );
};
