import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Arrow from 'assets/sortArrow.svg';
import * as S from './TopBar.styled';

const Imports = ({
  onOrderByDate, onOrderByStatus, inverted, order, orderStatus,
}) => {
  const { t } = useTranslation();
  const [date, setDate] = useState(false);
  const [status, setStatus] = useState(false);

  const handleStatusChange = () => {
    if (status) {
      onOrderByStatus('asc');
      if (orderStatus === 'desc') setStatus(false);
      else if (orderStatus === null) setStatus(true);
      else setStatus(!status);
    } else {
      onOrderByStatus('desc');
      if (orderStatus === 'desc') setStatus(false);
      else if (orderStatus === null) setStatus(true);
      else setStatus(!status);
    }
  };

  const handleDateChange = () => {
    if (date) {
      onOrderByDate('desc');
      if (order === null) setDate(true);
      else setDate(!date);
    } else {
      onOrderByDate('asc');
      if (order === null) setDate(true);
      else setDate(!date);
    }
  };

  return (
    <S.Section style={{ width: '25rem', marginRight: '1rem' }}>
      <S.Changed style={{ width: 'calc(100% / 2)', marginLeft: '6rem' }}>
        <S.Label onClick={handleDateChange}>{t('date')}</S.Label>
        {order && <S.Arrow isDesc={inverted ? !date : date} onClick={handleDateChange} src={Arrow} />}
      </S.Changed>
      <S.Changed style={{ width: 'calc(100% / 2)', marginLeft: '3rem' }}>
        <S.Label onClick={onOrderByStatus ? handleStatusChange : undefined}>{t('Status')}</S.Label>
        {orderStatus && <S.Arrow isDesc={inverted ? !status : status} onClick={handleStatusChange} src={Arrow} />}
      </S.Changed>
    </S.Section>
  );
};

export default Imports;
