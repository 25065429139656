import * as types from './auth.types';
import { login as apiLogin } from './auth.service';

export const login = ({ email, password }, cb) => dispatch => apiLogin(email, password)
  .then(data => {
    const { token, refreshToken } = data;
    dispatch({
      type: types.LOGIN,
      payload: {
        email,
        userToken: token,
        refreshToken,
      },
    });
    // eslint-disable-next-line callback-return
    if (cb) cb(data);
    return data;
  });

export const logout = { type: types.LOGOUT };
