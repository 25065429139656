import _ from 'lodash';
import { useContext, useEffect, useReducer } from 'react';
import { useTranslation } from 'react-i18next';

import { Suppliers } from 'hooks/suppliers.hooks';
import { Calculation } from 'hooks/calculation.hooks';
import { useInputsContext } from 'hooks/input.hooks';

const reportFields = [
  { field: 'productImage', path: '' },
  { field: 'teamMembers', path: '' },
  { field: 'dataCollectionYear', path: '' },
  //  { field: 'dataSource', path: '' }, for inputs
  { field: 'descriptionRealtime', path: '' },
  { field: 'productionProcess', path: '' },
  { field: 'constructionProcess', disabledForSemiFinishedProduct: true, path: '' },
  { field: 'noEmissionsDeclaration', disabledOnCalculationInputs: 9, path: '' },
  { field: 'productSubstantiationRSL', path: '' },
  { field: 'productPartSubstantiationRSL', path: '' },
];

const validationFields = [
  'dataQuality',
  'dataSource',
];

function inputsReducer(state, payload) {
  return [...state, payload];
}

function errorsReducer(state, payload) {
  return { ...state, [payload.stage]: payload.count + (state[payload.stage] || 0) };
}

export const useStages = () => {
  const { t } = useTranslation();
  const { result: calculation } = useContext(Calculation);
  const transports = calculation.location?.transports || calculation.template?.location?.transports;
  const inputsContext = useInputsContext();
  const { inputs, stages } = inputsContext;
  const suppliers = useContext(Suppliers);
  const [stageInputs, dispatchStageInputs] = useReducer(inputsReducer, []);
  const [errorsByStage, dispatchStageErrors] = useReducer(errorsReducer, {});
  const inputsByStage = {
    ..._.groupBy(inputs, 'inputStage.id'),
    pdis: inputsContext.pdisUsages,
  };

  const allReady = !_.some([
    { loading: !stages?.length },
    inputsContext,
    suppliers,
  ], ['loading', true]);

  useEffect(() => {
    if (allReady) {
      _.forEach(stages, ({ id: stageId }) => {
        if (_.has(inputsByStage, stageId)) {
          _.forEach(inputsByStage[stageId], input => {
            const errors = [];

            if (stageId === 'pdis') {
              if (input.predefinedInputSet.method.id !== calculation.method.id) {
                errors.push(t('lca method of pdis does not match calculation'));
              }

              dispatchStageInputs({
                input,
                inputId: input,
                stage: 'pdis',
                errors,
              });
              dispatchStageErrors({ stage: 'pdis', count: errors.length && 1 });

              return;
            }

            if (!input.environmentalProfile) return;

            _.forEach(validationFields, field => {
              if (!_.has(input, field) || _.isEmpty(input[field])) {
                errors.push(t(`${field}.missing`));
              }
            });

            // Suppliers must be added to the calculations' location (defined in basic settings)
            if (input.supplier) {
              if (transports.filter(t => t.supplier.id === input.supplier.id).length === 0) {
                errors.push(t('supplierInputs.supplierLocationError'));
              }
            }

            if (input?.environmentalProfile?.method) {
              if (calculation.method['@id'] !== input?.environmentalProfile?.method) {
                errors.push(t('Profile is not active for calculation method'));
              }
            }

            // EPD-968 show error message if input description is missing
            if (!input.description) {
              errors.push(t('descriptionRealtime.missing'));
            }

            dispatchStageInputs({
              input,
              inputId: input.id,
              stage: input.inputStage.id,
              errors,
            });
            dispatchStageErrors({ stage: input.inputStage.id, count: errors.length && 1 });
          });
        }
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allReady]);

  return {
    errors: errorsByStage,
    stages,
    allReady,
    inputs: _.groupBy(stageInputs, 'stage'),
  };
};

export const useReportItems = () => {
  const { t } = useTranslation();
  const { result: calculation } = useContext(Calculation);
  const { inputs: calculationInputs } = useInputsContext();
  const isSemi = (calculation?.calculationType === 'semi_finished_product');

  const status = {
    errorCount: 0,
    get valid() { return !this.errorCount; },
    sfp: calculation.calculationType === 'semi_finished_product',
  };

  let items = _.map(reportFields, section => {
    const errors = [];
    const sectionField = section.field === 'descriptionRealtime' ? 'description' : section.field;
    const isValid = !!calculation[sectionField]
      || (calculation.template && !!calculation.template[sectionField]);
    const visible = _.filter(
      calculationInputs,
      ({ inputStage }) => inputStage?.id === section?.disabledOnCalculationInputs,
    )?.length === 0;

    if (isSemi && (section?.field === 'constructionProcess'
      || section?.field === 'noEmissionsDeclaration')) {
      return null;
    }

    if (!isValid) {
      errors.push(t(`${section.field}.missing`));
      if (!(isSemi && (['constructionProcess', 'noEmissionsDeclaration']
        .includes(section?.field)))) {
        status.errorCount += 1;
      }
    }

    return {
      name: t(`${section.field}.label`),
      valid: isValid,
      errors,
      visible,
      path: section.path,
      disabled: visible && (!status.sfp || !section.disabledForSemiFinishedProduct),
    };
  });

  items = _.compact(items);

  return { status, items };
};
