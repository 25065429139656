import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

import alert from 'assets/alert.svg';
import SearchIcon from 'assets/search.svg';
import {
  NAV_HEIGHT,
  FormWidth,
  InputWidth,
  LIGHT_BLUE,
  GREY,
  DARK_BLUE,
  paddingRows,
} from 'config/style';

export { ReadOnlyField } from 'components/Field';

export const Container = styled.div`
  background: white;
  padding: 1.87rem 2.37rem;
  overflow-y: auto;
  height: 100%;
  max-height: calc(100% - 4rem);
`;

export const TitleContainer = styled.div`
  font-style: normal;
  font-size: 1rem;
  line-height: 27px;
  border-bottom: 1px solid #e4e4e4;
  padding: 1rem 2.3rem 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  background: ${LIGHT_BLUE};

  & > * {
    margin-right: 4.37rem;
    border-bottom: 3px solid transparent;
    margin-bottom: 1rem;
  }
`;

export const Bar = styled.div`
  padding: ${paddingRows};
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e4e4e4;
  height: ${NAV_HEIGHT};
  background: #E7EDF0;
`;

export const ItemWrapper = styled.div`margin-bottom: 2rem;`;

export const ItemsContainer = styled.div`display: flex;`;

export const ViewModeContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const StyledLink = styled(NavLink)`
  font-weight: normal;
`;

export const Expand = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
`;

export const ProfileName = styled.h1`
  font-weight: 500;
  font-size: 1.37rem;
  line-height: 28px;
`;

export const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: .5fr 2fr .5fr .5fr;
  width: 100%;
  border-bottom: 1px solid #e4e4e4;
  padding: 1.56rem 2.5rem;
`;

export const Header = styled.p`
  font-weight: 500;
  font-size: 1rem;
`;

export const Data = styled.p`
  font-size: ${({ size }) => (size || '.875rem')};
  color: ${({ isGrey }) => (isGrey ? '#a8a8a8' : DARK_BLUE)};
`;

export const Wrapper = styled.form``;

export const StyledContainer = styled.div`
  margin: 2.31rem 4.68rem;
  min-height: 89vh;
`;

export const ButtonContainer = styled.div`
  color: white;
  width: 100%;
  cursor: pointer;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  text-decoration-line: underline;
  padding: 0.563rem 9.625rem 0.5rem 1.063rem;
  background-color: ${(props) => (props.selected ? 'black' : 'lightgray')};
  margin-top: 0.93rem;
`;

export const Section = styled.h2`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #000000;
  margin-top: 1.68rem;
`;

export const WrapperInput = styled.div`
  width: 100%;
  margin-bottom: 1.5rem;
`;

export const Label = styled.h1` 
  font-style: normal;
  font-weight: normal;
  font-size: .875rem;
  line-height: 21px;
  margin-bottom: 5px;
  color: ${({ color }) => (color || '#202538')};
`;

export const LabelText = styled.h2`
  font-size: 1rem;
  font-style: normal;
  margin-bottom: .37rem;
  font-weight: 500;
`;

export const SearchBar = styled.input`
  width: 21.7rem;
  color: rgba(0, 0, 0, 0.3);
  border: 1px solid #e4e4e4;
  border-radius: 2px;
  padding: 0.61rem 11.03rem 0.61rem 1.02rem;
  background: url(${SearchIcon}) no-repeat;
  background-position: 95% 50%;
  background-image: rgba(29, 29, 29, 0.7);
  font-family: ${({ theme: t }) => t.baseFont};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  margin-top: 0.55rem;

  &:focus {
    outline: none;
  }
`;

export const Select = styled.select`
  margin-top: 0.58rem;
  width: 21.7rem;
  border: 1px solid #e4e4e4;
  appearance: none;
  border-radius: 0;
  padding: 0.61rem 11.03rem 0.61rem 1.02rem;
  background-color: #ffffff;
  color: rgba(0, 0, 0, 0.3);
  font-family: ${({ theme: t }) => t.baseFont};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;

  &:focus {
    outline: none;
  }
`;

export const PanelContainer = styled.div`margin-right: '7.25rem';`;

export const List = styled.details`
  background: #eaeaea;
  margin-top: 0.87rem;
`;

export const ListItem = styled.summary`padding: 0.75rem 3.62rem 1rem 2rem;`;

export const OverviewRows = styled.div`
  overflow-y: auto;
  height: calc(100vh - (2 * ${NAV_HEIGHT}) - 4rem);
`;

export const AsideTitle = styled.h1`
  padding: 1rem 2.5rem;
  border-bottom: 1px solid #e4e4e4;
  height: 4rem;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: ${({ size }) => size || '1.37rem'};
`;

export const AsideLink = styled(NavLink)`
  background-color: white;
  color: inherit;
`;

export const Row = styled.div`
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.15);
  min-height: 4.7rem;
  font-size: .875rem;
  margin-bottom: 1.1rem;
  background: ${({ blueBackground }) => (blueBackground ? '#F6F9FB' : 'white')};
  display: flex;
  flex-direction: ${({ expand }) => (expand ? 'column' : 'row')};
  justify-content: space-between;
  align-items: stretch;
  padding: 0 1.37rem;

  a {
    width: 100%;
    align-self: center;
  }
`;

export const SimpleRow = styled.div`
  min-height: 4.7rem;
  font-size: .875rem;
  margin-bottom: 1.1rem;
  display: flex;
  flex-direction: ${({ expand }) => (expand ? 'column' : 'row')};
  justify-content: space-between;
  align-items: stretch;
  cursor: pointer;

  a {
    width: 100%;
    align-self: center;
  }
`;

export const RowContainer = styled.div`
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.15);
  background: ${({ blueBackground }) => (blueBackground ? '#F6F9FB' : 'white')};
  min-height: 4.7rem;
  font-size: .875rem;
  margin-bottom: 1.1rem;
  padding: 0 1.37rem;
`;

export const LinksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-size: .875rem;
  padding: 1.56rem 2.5rem;
  border-bottom: 1px solid #e4e4e4;
  min-height: 4rem;

  a {
    margin-bottom: 1.25rem;
    &:last-child {
      margin-bottom: 0;
    }
  }

  ${({ isFirst }) => isFirst && 'padding-top: unset;'}
`;

export const ArrowSelection = styled.img`margin-right: 8px;`;

export const Details = styled.p`font-size: .875rem;`;

export const FormContainer = styled.section`
  width: ${FormWidth};
  /* margin: 0 ${({ isHidden }) => (isHidden ? '32.37rem' : '2.25rem')}; */
  margin: 0 2.25rem;
  min-height: calc(100vh - 4 * ${NAV_HEIGHT});
  /* transition: 1s; */
`;

export const TitleBar = styled.h1`
  padding: 1rem 2.25rem;
  font-size: 1rem;
  font-weight: 500;
  border-bottom: 1px solid #e4e4e4;
  background: ${LIGHT_BLUE};
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 4rem;
`;

export const RadioWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  width: ${InputWidth};
  justify-content: space-between;
`;

export const Background = styled.main`
  background: ${({ isEditable }) => (isEditable ? LIGHT_BLUE : 'white')};
  padding-bottom: 6rem;
`;

export const Description = styled.div`
  margin-bottom: 1.2rem;
  font-size: .875rem;
  font-weight: 100;
`;

export const HeaderTitle = styled.div`
  padding: 1rem 2.25rem;
  border-bottom: 1px solid #e4e4e4;
  margin-bottom: 4.2rem;
  background: #E7EDF0;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1rem;
  min-height: ${NAV_HEIGHT};
`;

export const SectionTitile = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1.4rem;
  font-size: 1rem;
  font-weight: 500;

  h4 {
    font-weight: 500;
    font-size: 1rem;
    line-height: 24px;
    margin-right: 10px;
  }

  legend {
    font-weight: 100;
    font-size: .75rem;
  }
`;

export const SectionWrapper = styled.div`
  padding: 0 2.25rem;
  margin-bottom: 3.75rem;
`;

export const AlignItems = styled.div`
  display: flex;
  align-items: center;
`;

export const Option = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 20px;
  transition: .3s;
  opacity: ${(props) => (props.isClickable ? 1 : 0.3)};
  cursor: ${(props) => (props.isClickable ? 'pointer' : 'auto')};

  &:hover {
    background: ${(props) => (props.isClickable ? GREY : 'none')};
  }

  div {
    min-width: 25px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 1rem;
  }
`;

export const PaddingRow = styled.div`
  padding: ${paddingRows};
  padding-top: 1.3rem;
  padding-bottom: 1.3rem;
  border-bottom: 1px solid #e4e4e4;
`;

export const ItemsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const NameTitle = styled.h3`
  font-size: 1rem;
  font-weight: 600;
  color: ${DARK_BLUE};
`;

export const DetailsInfo = styled.p`
  font-size: .75rem;
  font-weight: normal;
`;

export const SubText = styled.p`
  font-size: .75rem;
  font-weight: normal;
  color: #a8a8a8;
`;

export const AlertContainer = styled.div`
  background: #FFF6D0;
  border: 1px solid #FFCE01;
  background-image: url(${alert}) ;
  background-repeat: no-repeat;
  background-position: ${({ imageRight }) => (imageRight ? '98% 50%' : '3% 50%')}
`;

export const SectionDivider = styled.div`
  :not(:last-child) {
    height: 1px;
    width: 100%;
    background: #E4E4E4;
    margin-top: -1rem;
    margin-bottom: 2rem;  
  }
`;

export const Img = styled.img`
  cursor: pointer;
`;

export const GroupFields = styled.div`
  margin: 0 2.25rem;
  margin-bottom: 3rem;
`;

export const GroupFieldsHeader = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 34px;
  color: #202538;
  margin-bottom: 2.3rem;
`;

export const GroupFieldsDivider = styled.div`
  height: 1px;
  width: 100%;
  background: #E4E4E4;
  margin-bottom: 1rem;
`;

export const Download = styled.div`
  cursor: pointer;
  text-decoration-line: underline;
  font-size: .875rem;
  font-weight: normal;
  margin-top: 9px;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.15);
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Icon = styled.img`
  transform: rotate(90deg)
`;
