import _ from 'lodash';
import React from 'react';
import * as S from '../Content.styled';

export const Option = ({
  action, label, img, id, ...rest
}) => (
  <S.Option onClick={action} type="button" {...rest}>
    <img alt="icon" src={img} />

    <S.StyledLabel isRegular labelColor={id === 'remove' && '#BF2E00'}>
      {label}
    </S.StyledLabel>
  </S.Option>
);

const Calculations = ({
  buttonsData,
  close,
}) => (
  <S.Wrapper onClick={close}>
    {_.map(
      buttonsData,
      item => <Option key={item.label} {...item} />,
    )}
  </S.Wrapper>
);

export default Calculations;
