import React from 'react';
import Button from 'components/Button/Button';

import { ButtonsWrapper } from '../styled';

const Download = ({ actions }) => {
  const style = {
    width: '100%',
    marginBottom: '1.2rem',
  };
  return (
    <ButtonsWrapper>
      {actions.map((action) => (
        <Button onClick={() => action[1]} style={style} styling="white">
          {action[0]}
        </Button>
      ))}
    </ButtonsWrapper>
  );
};

export default Download;
