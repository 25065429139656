import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import LastChangedStatus from 'components/TopBar/LastChangedStatusCalculations';
import Name from 'components/TopBar/Name';
import TopBar from 'components/TopBar/TopBar';
import CalculationItem from 'components/CalculationItem/CalculationItem';
import Spinner from 'components/Spinner';

import * as S from '../../../Overview.styled';

const UsedCalculations = ({
  isPdis,
  fetchData,
  useFetchCalculations,
}) => {
  const { t } = useTranslation();
  const [productName, setProductName] = React.useState('asc');
  const [lastModifiedAt, setLastModifiedAt] = React.useState(null);
  const [state, setState] = React.useState(null);
  const [calculations, setCalculations] = React.useState([]);
  const [page, setPage] = React.useState(null);

  const params = {};
  if (lastModifiedAt) {
    params[`order[${isPdis
      ? 'calculation.lastModifiedAt' : 'lastModifiedAt'}]`] = lastModifiedAt;
  }
  if (state) params[`order[${isPdis ? 'calculation.' : ''}state]`] = state;

  if (productName) {
    params[`order[${isPdis ? 'calculation.productName' : 'productName'}]`] = productName;
  }

  const { execute, loading } = useFetchCalculations(params, res => {
    setCalculations(res.data);
    return res;
  });

  React.useEffect(() => {
    execute();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productName, lastModifiedAt, state]);

  const customFetchData = (values) => page && fetchData(() => {
    setPage(values.nextPage);
    setCalculations(calculations.concat(values.data));
  }, page);

  return (
    <>
      <TopBar>
        <Name
          onOrderBy={order => {
            setProductName(order);
          }}
          order={productName}
        />
        <div style={{ display: 'flex' }}>
          <div style={{ marginRight: '9rem' }}>
            <Name label="Type" noSorting />
          </div>
          <LastChangedStatus
            hasStatus
            inverted
            onOrderByDate={() => {
              if (state) setState(null);
              if (!lastModifiedAt) setLastModifiedAt('asc');
              else if (lastModifiedAt === 'asc') setLastModifiedAt('desc');
              else setLastModifiedAt(null);
            }}
            onOrderByStatus={() => {
              if (lastModifiedAt) setLastModifiedAt(null);
              if (!state) setState('asc');
              else if (state === 'asc') setState('desc');
              else setState(null);
            }}
            order={lastModifiedAt}
            orderStatus={state}
          />
        </div>
      </TopBar>

      {loading ? (
        <div
          style={{
            height: 'calc(100vh - 4rem)',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <Spinner />
        </div>
      ) : (
        <S.OverviewRows id="overviewRowsId">
          <InfiniteScroll
            dataLength={calculations?.length}
            hasMore={page}
            loader={<Spinner />}
            next={customFetchData}
            scrollableTarget="overviewRowsId"
            scrollThreshold={0.4}
            style={{ overflow: 'unset' }}>
            {_.map(calculations, (item) => (
              <CalculationItem
                key={item?.id}
                calculation={item}
                isPdis
                type={item?.calculationType
                  === 'semi_finished_product' ? t('semiFinishedProduct.label') : t('product')}
              />
            ))}
          </InfiniteScroll>
        </S.OverviewRows>
      )}
    </>
  );
};

export default UsedCalculations;
