import React, { createRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import * as authActions from 'state/auth/auth.actions';

import Button from 'components/Button/Button';
import Input from 'components/Input/Input';
import Footer from 'components/Footer/Footer';
import SwitchToggle from 'components/SwitchToggle/SwitchToggle';

import hero from 'assets/rethink-hero.jpg';

import * as S from './Login.styled';

const Login = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const h = useHistory();
  const emailField = createRef();
  const passwordField = createRef();
  const [errorMessage, setErrorMessage] = useState('');
  const [didSubmit, setSubmit] = useState(true);

  // Switch Toggle State
  const [isChecked, setIsChecked] = useState(false);

  const onSubmit = (e) => {
    e.preventDefault();
    const payload = {
      email: emailField.current.value,
      password: passwordField.current.value,
      persist: isChecked,
    };

    setSubmit(false);
    return dispatch(authActions.login(payload))
      .then(() => h.push('/'))
      .catch(() => {
        setErrorMessage(t('wrong credentials'));
        setSubmit(true);
      });
  };

  return (
    <S.StyledContainer>
      <S.InputContainer onSubmit={onSubmit}>
        <S.StyledColumn>
          <S.StyledTitle>{t('welcome')}</S.StyledTitle>

          <Input
            autoComplete="email"
            error={errorMessage}
            id="email"
            innerRef={emailField}
            placeholder={t('email address')}
            required
            style={{ marginBottom: '1.7rem' }}
            type="email"
          />

          <Input
            autoComplete="password"
            error={errorMessage}
            id="password"
            innerRef={passwordField}
            placeholder={t('password')}
            required
            type="password"
          />

          <S.ButtonContainer>
            <S.MessageContainer>
              <S.StyledError>{errorMessage}</S.StyledError>

              <S.StyledLink>
                <Link to="/forgot">{t('forgot password')}</Link>
              </S.StyledLink>
            </S.MessageContainer>

            <Button loading={!didSubmit} type="submit">
              {didSubmit ? t('login') : t('submitting')}
            </Button>
          </S.ButtonContainer>

          <S.RememberWrapper>
            <S.Text>{t('remember credentials')}</S.Text>

            <SwitchToggle checked={isChecked} onChange={() => setIsChecked(!isChecked)} />
          </S.RememberWrapper>
        </S.StyledColumn>

        <Footer style={{ width: '50%', margin: '0 auto' }} type="space-between" />
      </S.InputContainer>

      <S.Wrapper>
        <S.HeroImg src={hero} />
      </S.Wrapper>
    </S.StyledContainer>
  );
};

export default Login;
