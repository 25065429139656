import _ from 'lodash';
import React, { useState } from 'react';
import ListItem from 'components/ListItem/ListItem';
import Name from 'components/TopBar/Name';
import TopBar from 'components/TopBar/TopBar';
import Spinner from 'components/Spinner';
import * as S from 'routes/Calculations/Overview.styled';
import InfiniteScroll from 'react-infinite-scroll-component';

const ValidationFailed = ({ errors }) => {
  const [imports, setImports] = useState(errors);
  const [sorting, setSorting] = useState('asc');
  const [sortingLine, setSortingLine] = useState('asc');
  const [sortingError, setSortingError] = useState('asc');

  const importsList = () => (
    <S.OverviewRows id="overviewRowsId">
      <InfiniteScroll
        dataLength={imports.length}
        hasMore={false}
        loader={<Spinner />}
        next={null}
        scrollableTarget="overviewRowsId"
        scrollThreshold={0.4}
        style={{ minHeight: '50vh' }}>
        {_.map(imports, (data) => (
          <ListItem
            key={data.id}
            error={data.message}
            importFailed
            line={data.lineNumber}
            name={data.sheet}
          />
        ))}
      </InfiniteScroll>
    </S.OverviewRows>
  );

  const sortName = () => {
    const arr = imports?.sort((a, b) => {
      if (a?.sheet?.toLowerCase() > b?.sheet?.toLowerCase()) {
        return ((sorting || sortingLine || sortingError) === 'asc' ? 1 : -1);
      }
      if (a?.sheet?.toLowerCase() < b?.sheet?.toLowerCase()) {
        return ((sorting || sortingLine || sortingError) === 'asc' ? -1 : 1);
      }
      return 0;
    });
    setImports(arr);
  };

  const sortLine = () => {
    const arr = imports?.sort((a, b) => {
      if (a?.lineNumber > b?.lineNumber) {
        return ((sorting || sortingLine || sortingError) === 'asc' ? 1 : -1);
      }
      if (a?.lineNumber < b?.lineNumber) {
        return ((sorting || sortingLine || sortingError) === 'asc' ? -1 : 1);
      }
      return 0;
    });
    setImports(arr);
  };

  const sortError = () => {
    const arr = imports?.sort((a, b) => {
      if (a?.message?.toLowerCase() > b?.message?.toLowerCase()) {
        return ((sorting || sortingLine || sortingError) === 'asc' ? 1 : -1);
      }
      if (a?.message?.toLowerCase() < b?.message?.toLowerCase()) {
        return ((sorting || sortingLine || sortingError) === 'asc' ? -1 : 1);
      }
      return 0;
    });
    setImports(arr);
  };

  return (
    <>
      <TopBar>
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          width: '22rem',
        }}>
          <Name
            label="sheet"
            onOrderBy={order => {
              setSorting(order);
              setSortingLine(null);
              setSortingError(null);
              sortName();
            }}
          />
          <Name
            label="line nr"
            onOrderBy={order => {
              setSorting(null);
              setSortingLine(order);
              setSortingError(null);
              sortLine();
            }}
          />
          <Name
            label="error"
            onOrderBy={order => {
              setSorting(null);
              setSortingLine(null);
              setSortingError(order);
              sortError();
            }}
          />
        </div>
      </TopBar>

      {!imports
        ? (
          <div
            style={{
              height: 'calc(100vh - 4rem)',
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            <Spinner />
          </div>
        )
        : importsList()}
    </>
  );
};

export default ValidationFailed;
