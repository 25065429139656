import * as types from './sidebar.types';

export const hideSidebar = () => ({
  type: types.HIDE_SIDEBAR,
});

export const setBreadcrumb = (payload) => ({
  type: types.SET_BREADCRUMB,
  payload,
});

export const setBreadcrumbCalculation = (payload) => ({
  type: types.SET_BREADCRUMB_CALCULATION,
  payload,
});
