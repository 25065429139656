import _ from 'lodash';
import React from 'react';
import { useDispatch } from 'react-redux';
import { setBreadcrumb } from 'state/sidebar/sidebar.actions';
import {
  useParams, Switch, Route, useRouteMatch, Redirect,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAsync, useAsyncCallback } from 'react-async-hook';

import { inputs as inputsServices } from 'services';

import { MenuItem } from 'components/VerticalMenu';
import AsideLayout from 'components/AsideLayout/AsideLayout';
import { Placeholder } from 'components/Splash/Loading';

import { WithInputsContext2 } from 'hooks/input.hooks';
import * as S from '../../Detail.styled';
import ViewPdisBasics from './ViewPdisBasics';
import EditPdis from './EditPdis';
import Inputs from '../Inputs';
import AddInput from '../EditInput';
import UsedCalculations from './UsedCalculations';

const PdisDetail = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { path, url } = useRouteMatch();

  const [usedCalculations, setUsedCalculations] = React.useState(0);

  const { result, loading } = useAsync(() => inputsServices.getPdisDetail(id)
    .then(values => {
      const pdisUsages = values?.predefinedInputSetUsages;
      const uniq = _.uniqBy(pdisUsages, p => p?.calculation?.id);
      setUsedCalculations(uniq?.length);
      return values;
    }), []);

  const useFetchCalculationsSorting = (params, updateList) => useAsyncCallback(() => inputsServices
    .getPdisUsedCalculations(id, params).then(values => {
      const uniq = _.map(_.uniqBy(values.data, p => p?.calculation?.id), c => ({
        ...c?.calculation,
        calculationType: c?.calculation?.['@type'] === 'ProductCalculation'
          ? 'product' : 'semi_finished_product',
      }));
      updateList({
        ...values,
        data: uniq,
      });
    }), []);

  const fetchDataCalculations = (updateList, page) => {
    inputsServices
      .getNextPageInputs(page)
      .then((values) => {
        const uniq = _.map(_.uniqBy(values.data, p => p?.calculation?.id), c => ({
          ...c?.calculation,
          calculationType: c?.calculation?.['@type'] === 'ProductCalculation'
            ? 'product' : 'semi_finished_product',
        }));
        if (updateList) {
          updateList({
            ...values,
            data: uniq,
          });
        }
      });
  };

  React.useEffect(() => {
    if (result?.code) {
      dispatch(setBreadcrumb(result?.code));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result]);

  const CustomViewPdisBasics = React.useCallback(props => (
    <ViewPdisBasics
      {...props}
      loading={loading}
      pdis={result}
    />
  ), [result, loading]);

  const CustomEditPdis = React.useCallback(props => (
    <EditPdis
      {...props}
      loading={loading}
      pdis={result}
    />
  ), [result, loading]);

  const CustomUsedCalculations = React.useCallback(props => (
    <UsedCalculations
      {...props}
      fetchData={fetchDataCalculations}
      isPdis
      useFetchCalculations={useFetchCalculationsSorting}
    />
  // eslint-disable-next-line react-hooks/exhaustive-deps
  ), []);

  return (
    <WithInputsContext2 calculationId={result?.id} calculationPath={result?.['@id']} isPdis>
      <Placeholder loaders={[loading]}>
        <AsideLayout
          LeftPane={(
            <aside>
              <S.ProductWrapper>
                <S.ProductName>{result?.name}</S.ProductName>
                <p>{`${t('Code.label')}: ${result?.code}`}</p>
                <p>{`${t('unit')}: ${result?.unit}`}</p>
              </S.ProductWrapper>

              <S.LinksWrapper>
                <MenuItem
                  style={{ marginTop: '1rem' }}
                  to={`/calculations/predefined-input-sets/${id}/set-basics`}>
                  {t('set basics')}
                </MenuItem>

                <MenuItem to={`/calculations/predefined-input-sets/${id}/inputs`}>
                  {t('inputs')}
                </MenuItem>

                <MenuItem to={`/calculations/predefined-input-sets/${id}/used-calculations`}>
                  {`${t('used calculations')} (${usedCalculations})`}
                </MenuItem>
              </S.LinksWrapper>
            </aside>
      )}>
          <Switch>
            <Route
              component={CustomViewPdisBasics}
              exact
              path={`${path}/set-basics`}
            />
            <Route
              component={CustomEditPdis}
              exact
              path={`${path}/set-basics/edit`}
            />
            <Route
              component={Inputs}
              exact
              path={`${path}/inputs`}
            />
            <Route
              path={`${path}/add-input`}
              render={props => (
                <Redirect to={`${url}/inputs/add-input${props.location.search
              || ''}`}
                />
              )}
            />
            <Route
              component={AddInput}
              exact
              path={`${path}/inputs/add-input`}
            />
            <Route
              path={`${path}/edit-input`}
              render={props => <Redirect to={`${url}/inputs/edit-input${props.location.search}`} />}
            />
            <Route
              component={AddInput}
              exact
              path={`${path}/inputs/edit-input`}
            />
            <Route
              component={CustomUsedCalculations}
              exact
              path={`${path}/used-calculations`}
            />
          </Switch>
        </AsideLayout>
      </Placeholder>
    </WithInputsContext2>
  );
};

export default PdisDetail;
