import React, { useState, useCallback } from 'react';
import { Field } from 'redux-form';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import Joi from '@hapi/joi';

import { FormFileUpload } from 'components/FileUpload/FileUpload';
import { FormSimpleCKEditor } from 'components/SimpleCKEditor';
import { FormContentInfo } from 'components/ContentInfo/ContentInfo';
import Button from 'components/Button/Button';

const getAcceptsMediaType = (allowImages, allowPdf, allowAny = false) => {
  if (allowAny) return '*';
  const accepts = [];
  if (allowImages) {
    accepts.push('image/*');
  }
  if (allowPdf) {
    accepts.push('application/pdf');
  }

  return accepts.join(',');
};

const SimpleItem = ({
  label,
  calculationKey,
  calculationContent,
  removeHTMLTags,
  // @deprecated
  isImage,
  // @deprecated
  isFile,
  /**
   * Pass an object like {
   *   allowImages?: boolean
   *   allowPdf?: boolean
   *   allowAny?: boolean
   * }
   */
  fileUpload,
  downloadFile,
  deleteFile,
  setHasEditedForm,
}) => {
  const props = isImage || isFile || fileUpload
    ? {
      fileName: calculationContent?.filename,
      url: calculationContent?.url,
      content: calculationContent?.content,
      calculationKey,
      id: calculationKey,
      downloadFile,
      deleteFile: fileUpload?.disableDelete ? null : deleteFile,
      name: label,
      onChange: () => setHasEditedForm(true),
      accept: getAcceptsMediaType(
        isImage || fileUpload?.allowImages,
        isFile || fileUpload?.allowPdf,
        isFile || fileUpload?.allowAny,
      ),
    }
    : {
      content: calculationContent,
      name: label,
      onChange: (editor, input) => {
        const data = removeHTMLTags
          ? editor.getData().replace(/<(.|\n)*?>/g, '')
          : editor.getData();
        input.onChange(data || null);
        setHasEditedForm(true);
      },
    };
  return (
    <Field
      component={(isImage || isFile || fileUpload) ? FormFileUpload : FormSimpleCKEditor}
      name={calculationKey}
      props={props}
    />
  );
};

const CustomEditor = ({
  name,
  input,
  meta,
  content,
  calculationContent,
  methodContent,
  setContent,
  onInit,
  onChange,
  isEditable,
  setEditable,
  isHtml,
}) => {
  const { t } = useTranslation();

  const handleChangeValue = () => {
    if (isEditable && (calculationContent !== null)) input.onChange(null);
    setContent(methodContent);
    setEditable(!isEditable);
  };

  return (
    <>
      {isEditable
        ? (
          <div style={{ margin: 0, fontSize: '.875rem' }}>
            <FormSimpleCKEditor
              content={content || calculationContent}
              input={input}
              meta={meta}
              name={name}
              onChange={onChange}
              onInit={onInit}
            />
          </div>
        )
        : <FormContentInfo content={content} hasHtml={isHtml} name={name} />}

      <Button
        onClick={handleChangeValue}
        style={{ marginBottom: '2.5rem' }}
        styling="select">
        {t(isEditable ? 'restore default value' : 'change value')}
      </Button>
    </>
  );
};

const CustomTemplateEditor = ({
  name,
  input,
  meta,
  content,
  onInit,
  onChange,
  isEditable,
  setEditable,
  isHtml,
  template,
  calculationKey,
  methodContent,
}) => {
  const { t } = useTranslation();

  const handleChangeValue = () => {
    setEditable(true);
  };

  const onBlur = () => {
    if ((input?.value === '')) setEditable(false);
  };

  return (
    <>
      <FormContentInfo content={template[calculationKey] || methodContent} hasHtml={isHtml} name={name} />
      {(content && (isEditable !== false)) || isEditable
        ? (
          <div style={{ margin: 0, fontSize: '.875rem' }}>
            <FormSimpleCKEditor
              content={content}
              input={{ ...input, name: null }}
              meta={meta}
              onBlur={onBlur}
              onChange={onChange}
              onInit={onInit}
            />
          </div>
        ) : (
          <Button
            onClick={handleChangeValue}
            style={{ marginBottom: '2.5rem' }}
            styling="select">
            {t('add content')}
          </Button>
        )}
    </>
  );
};

const CustomItem = ({
  label,
  calculationContent,
  methodContent,
  calculationKey,
  isFromCalculation,
  removeHTMLTags,
  setHasEditedForm,
  template,
}) => {
  const [isEditable, setEditable] = useState(template ? null : isFromCalculation);
  const [content, setContent] = useState(template ? calculationContent : (calculationContent || methodContent));

  const required = useCallback((value) => {
    const validate = Joi.string().allow(null).required().validate(value)?.error;
    return validate
      ? (!value && i18next.t('required', { fieldName: i18next.t(`${label}.label`) }))
      : null;
  }, [label]);

  return (
    <Field
      component={template ? CustomTemplateEditor : CustomEditor}
      name={calculationKey}
      props={{
        calculationKey,
        template,
        name: label,
        content,
        calculationContent,
        isEditable,
        setEditable,
        methodContent,
        setContent,
        isHtml: !removeHTMLTags,
        onChange: (editor, input) => {
          const data = removeHTMLTags ? editor.getData().replace(/<(.|\n)*?>/g, '') : editor.getData();
          if (template) input.onChange(data === '' ? null : data);
          else input.onChange(data);
          setHasEditedForm(true);
        },
        onInit: (input) => {
          if (calculationContent !== null) input.onChange(content);
          else input.onChange(null);
        },
      }}
      validate={isEditable && !template ? required : null}
    />
  );
};

const FormField = ({
  label,
  calculationContent,
  methodContent,
  calculationKey,
  isFromCalculation,
  removeHTMLTags,
  simpleEditor,
  isImage,
  isFile,
  fileUpload,
  downloadFile,
  deleteFile,
  setHasEditedForm,
  template,
}) => (simpleEditor
  ? (
    <SimpleItem
      calculationContent={calculationContent}
      calculationKey={calculationKey}
      deleteFile={deleteFile}
      downloadFile={downloadFile}
      fileUpload={fileUpload}
      isFile={isFile}
      isImage={isImage}
      label={label}
      removeHTMLTags={removeHTMLTags}
      setHasEditedForm={setHasEditedForm}
    />
  )
  : (
    <CustomItem
      calculationContent={calculationContent}
      calculationKey={calculationKey}
      isFromCalculation={isFromCalculation}
      label={label}
      methodContent={methodContent}
      removeHTMLTags={removeHTMLTags}
      setHasEditedForm={setHasEditedForm}
      template={template}
    />
  ));

export default FormField;
