import React, { useEffect, useState } from 'react';
import { reduxForm, Form, formValues } from 'redux-form';
import { useSelector } from 'react-redux';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useCanEdit } from 'hooks/calculation.hooks';

import Button from 'components/Button/Button';

import * as S from 'routes/GlobalStyle.styled';
import { downloadFile } from 'utils';
import { confirmationModalSetup } from 'components/Modal/ModalContent/Delete';
import { calculations } from 'services';
import MainModal from 'components/Modal';

const Interpretation = ({
  submitting,
  handleSubmitValues,
  formField,
  handleSubmit,
  getFieldValues,
  contributionAnalysisStages,
  contributionAnalysisMaterialsA1,
  contributionAnalysisMaterialsA1D,
  sensitiveAnalysis,
  sensitiveAnalysisImage,
  hasEditedForm,
  data,
  execute,
  isTemplate,
  updateTemplate,
}) => {
  const { t } = useTranslation();
  const { isHidden } = useSelector(state => state.sidebar);
  const canEdit = useCanEdit();
  const [confirmationModal, setConfirmationModal] = useState(null);
  const [hasSubmited, setHasSubmited] = React.useState(false);
  const history = useHistory();
  const { id: calcId } = useParams();
  useEffect(() => {
    getFieldValues({
      contributionAnalysisStages,
      contributionAnalysisMaterialsA1,
      contributionAnalysisMaterialsA1D,
      sensitiveAnalysis,
      sensitiveAnalysisImage,
    });
  }, [
    getFieldValues,
    contributionAnalysisStages,
    contributionAnalysisMaterialsA1,
    contributionAnalysisMaterialsA1D,
    sensitiveAnalysis,
    sensitiveAnalysisImage,
  ]);

  if (!canEdit) return <Redirect to="view" />;

  const deleteSensativeAnalysisImageFile = () => confirmationModalSetup(
    setConfirmationModal,
    closeModal => {
      if (data?.sensitiveAnalysisImage) {
        if (isTemplate) {
          calculations.saveTemplate(data.id, { sensitiveAnalysisImage: null })
            .then(() => {
              updateTemplate();
              closeModal();
            });
        } else {
          calculations
            .save(data.id, { sensitiveAnalysisImage: null })
            .then(() => {
              execute();
              closeModal();
            });
        }
      } else closeModal();
    },
  );

  return (
    <S.Background isEditable>
      <S.HeaderTitle>{t('expert functions interpretation')}</S.HeaderTitle>
      <S.FormContainer isHidden={isHidden}>
        <Form onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(handleSubmitValues)().then(() => {
            setHasSubmited(true);
          });
        }}>
          {formField({
            label: 'contribution analysis of the stages',
            calculationContent: data.contributionAnalysisStages,
            calculationKey: 'contributionAnalysisStages',
            simpleEditor: !data?.template,
            template: data?.template,
          })}
          {formField({
            label: 'contribution analysis of the raw materials (a1)',
            calculationContent: data.contributionAnalysisMaterialsA1,
            calculationKey: 'contributionAnalysisMaterialsA1',
            simpleEditor: !data?.template,
            template: data?.template,
          })}
          {formField({
            label: 'contribution analysis of the raw materials (a1-d)',
            calculationContent: data.contributionAnalysisMaterialsA1D,
            calculationKey: 'contributionAnalysisMaterialsA1D',
            simpleEditor: !data?.template,
            template: data?.template,
          })}
          {formField({
            label: 'sensative analysis',
            calculationContent: data.sensitiveAnalysis,
            calculationKey: 'sensitiveAnalysis',
            simpleEditor: !data?.template,
            template: data?.template,
          })}
          {formField({
            label: 'sensative analysis image',
            calculationContent: {
              url: (data.sensitiveAnalysisImage || data?.template?.sensitiveAnalysisImage)
                && `${process.env.REACT_APP_API_URL}${(data.sensitiveAnalysisImage?.contentUrl || data?.template?.sensitiveAnalysisImage?.contentUrl)}`,
              content: data.sensitiveAnalysisImage || data?.template?.sensitiveAnalysisImage,
            },
            calculationKey: 'sensitiveAnalysisImage',
            simpleEditor: true,
            fileUpload: {
              allowImages: true,
              disableDelete: !!data?.template?.sensitiveAnalysisImage?.contentUrl,
            },
            deleteFile: deleteSensativeAnalysisImageFile,
            downloadFile,
          })}
          <div style={{ display: 'flex', marginTop: '1rem' }}>
            <Button
              loading={submitting}
              style={{ marginRight: '2rem' }}
              styling="select"
              type="submit">
              {t('save')}
            </Button>
            <Button
              onClick={() => {
                if (isTemplate) {
                  history
                    .push(`/calculations/templates/${calcId}/expert-functions/interpretation/view`);
                } else {
                  history
                    .push(`/calculation/${calcId}/expert-functions/interpretation/view`);
                }
              }}
              styling="underline">
              {t('cancel')}
            </Button>
          </div>
        </Form>
      </S.FormContainer>
      <MainModal
        confirmationModalInfo={confirmationModal}
        content="delete"
        isModalOpen={confirmationModal}
        setIsModalOpen={setConfirmationModal}
      />
      <MainModal
        content="prompt"
        exitModalContent={{
          navigateSideEffects: () => null,
        }}
        prompt
        promptCondition={hasEditedForm && !hasSubmited}
      />
    </S.Background>
  );
};

export default reduxForm({
  form: 'interpretation',
  enableReinitialize: true,
})(formValues({
  contributionAnalysisStages: 'contributionAnalysisStages',
  contributionAnalysisMaterialsA1: 'contributionAnalysisMaterialsA1',
  contributionAnalysisMaterialsA1D: 'contributionAnalysisMaterialsA1D',
  sensitiveAnalysis: 'sensitiveAnalysis',
  sensitiveAnalysisImage: 'sensitiveAnalysisImage',
})(Interpretation));
