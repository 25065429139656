import styled from 'styled-components';

import { Link } from 'react-router-dom';

export const CardWrapper = styled(Link)`
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 25rem;
  height: 21.8rem;
  color: white;
  cursor: pointer;
  background: ${(props) => props.background && props.background};

  :not(:last-child) {
    margin-right: 2.5rem;
  }
`;

export const CardHeader = styled.div`
  display: flex;
  flex-direction: column;
  color: #FAFAF5;

  p:first-child {
    font-weight: 500;
    font-size: .875rem;
    line-height: 21px;
  }

  p:nth-child(2) {
    margin-top: 8px;
    font-weight: 600;
    font-size: 2rem;
    line-height: 36px;
  }
`;

export const CardStatus = styled.div`
  display: flex;
  align-items: center;
  padding: 7px 15px;
  background: ${({ buttonBackgroundColor }) => (buttonBackgroundColor || '#fafaf5')};
  border-radius: 20px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: ${(props) => (props.color ? props.color : 'black')};

  span {
    margin-left: 10px;
  }
`;

export const CardEdit = styled.div`
  font-size: 12px;
  line-height: 18px;
  font-weight: normal;
  color: #FAFAF5;

  p:nth-child(3) {
    font-weight: 600;
  }
`;

export const CardFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
