import React, { useEffect } from 'react';
import {
  Field, Form, formValues, getFormValues, reduxForm,
} from 'redux-form';
import { useHistory } from 'react-router-dom';
import { FormSelect } from 'components/Select/Select';
import Button from 'components/Button/Button';
import { useTranslation } from 'react-i18next';
import { required } from 'routes/Calculations/Detail/Basics/validation';
import { FormSimpleContentInfo } from 'components/ContentInfo/ContentInfo';
import { Placeholder } from 'components/Splash/Loading';
import { connect } from 'react-redux';

const filterDuplicateViewRoles = (roles, currentValues) => {
  if (!roles || !currentValues) return roles;
  const yesViews = [];
  roles.filter(r => currentValues[r.name]?.value).forEach(r => yesViews.push(r.view));
  return roles.filter(r => !yesViews.includes(r.view) || currentValues[r.name]?.value);
};

const NewCompanyPermissionsForm = ({
  reset,
  handleSubmit,
  handleSubmitValues,
  pathname,
  pristine,
  submitting,
  invalid,
  id,
  companiesList,
  company,
  isEdit,
  membershipEdit,
  roles,
  rolesLoading,
  setCompany,
  currentValues,
}) => {
  const { t } = useTranslation();
  const h = useHistory();

  useEffect(() => {
    reset();
  }, [pathname]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (company?.id) setCompany(company);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company]);

  const noYes = (role) => [
    {
      ...role,
      label: t('no'),
      value: false,
    },
    {
      ...role,
      label: t('yes'),
      value: true,
    },
  ];

  const hasAtLeastOneRole = Object.keys(currentValues || {})
    ?.find(f => f.startsWith('ROLE_') && currentValues?.[f]?.value);

  return (
    <Form onSubmit={handleSubmit(handleSubmitValues)}>
      {!isEdit
        ? (
          <Field
            component={FormSelect}
            name="company"
            props={{
              name: 'company',
              options: companiesList,
              placeholder: t('select company'),
              isSearchable: true,
            }}
            validate={isEdit ? null : required}
          />
        )
        : <FormSimpleContentInfo content={membershipEdit?.company?.name} name="company" />}
      <Placeholder loaders={[rolesLoading]}>
        {filterDuplicateViewRoles(roles, currentValues)?.map(r => (
          <Field
            key={r?.id}
            component={FormSelect}
            name={r?.name}
            props={{
              name: r?.name,
              options: noYes(r),
              isSearchable: false,
            }}
            validate={isEdit ? null : required}
          />
        ))}
      </Placeholder>
      <div style={{ display: 'flex', margin: '1.8rem 0 0' }}>
        <Button
          disabled={pristine
          || !hasAtLeastOneRole || invalid}
          loading={submitting}
          styling="select"
          type="submit">
          {t('add permissions')}
        </Button>
        <Button
          onClick={() => h.push(pathname.includes('user-profile')
            ? '/user-profile/memberships'
            : `/manage/users/${id}`)}
          style={{ minWidth: '9.87rem' }}
          styling="underline">
          {t('cancel')}
        </Button>
      </div>
    </Form>
  );
};

const form = reduxForm({
  form: 'new-permissions',
  enableReinitialize: true,
})(formValues({
  company: 'company',
})(NewCompanyPermissionsForm));

export default connect(state => ({
  currentValues: getFormValues('new-permissions')(state),
}))(form);
