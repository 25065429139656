import { mapSubtotals } from 'utils/calculationProcess';

import network from './network';

const paginatedMember = ({
  'hydra:member': data,
  'hydra:view': view,
  'hydra:totalItems': totalItems,
}) => ({
  data,
  nextPage: view && view['hydra:next'],
  totalItems,
});

const realtimeResults = (calculationId, config) => network
  .get(`/calculations/${calculationId}/results`, config)
  .then(r => {
    if (!r || typeof r === 'string') return { 'hydra:member': [[], [], []] };
    return r;
  })
  .then(paginatedMember)
  .then(({ data }) => data);

export const progress = (calculationId, config) => realtimeResults(calculationId, config)
  .then(r => {
    const [top, other, total] = r;
    return {
      other,
      total,
      overall: total.total,
      subtotals: mapSubtotals(total),
      top,
    };
  });
