import React, { memo } from 'react';
import moment from 'moment';
import _ from 'lodash';

import DropDown from 'components/DropDown/DropDown';
import dots from 'assets/dots.svg';

import * as S from 'components/CalculationItem/CalculationItem.styled';

const ListItem = ({
  name,
  dropdownContent,
  description,
  style,
  linkStyle,
  icon,
  lastChanged,
  balance,
  id,
  code,
  codeName,
  nameCode,
  permissions,
  type,
  corporation,
  isCorp,
  hideDropdown,
  company,
  imports,
  date,
  status,
  error,
  line,
  importFailed,
  complete,
  changes,
  isCompaniesOverview,
  isCompaniesPermissions,
  calculationImport,
  method = ' ',
  wasteScenario = ' ',
  ...rest
}) => {
  const subtitle = _.isString(description) ? [description] : description;

  const overrideStyle = importFailed || isCompaniesOverview || isCompaniesPermissions
    ? { justifyContent: 'flex-start' } : null;

  const importFailedWidth = importFailed ? { width: '10rem' } : null;

  const companiesType = isCompaniesOverview || isCompaniesPermissions ? { width: '25rem' } : null;

  return (
    <S.Wrapper style={style}>
      <S.StyledLink {...rest} style={{ ...linkStyle, ...overrideStyle }}>
        {(codeName || nameCode) && (
          <div style={{ display: 'flex' }}>
            {codeName && (
              <S.Title style={{ marginRight: '2rem', width: '7rem' }}>
                {codeName}
              </S.Title>
            )}
            {nameCode && (
              <S.Title style={{ maxWidth: '20rem' }}>
                {nameCode}
              </S.Title>
            )}
          </div>
        )}
        <div
          style={
            id ? {
              marginRight: '1.5rem', display: 'flex', flexDirection: 'row', alignItems: 'center',
            }
              : { marginRight: '1.5rem' }
          }>
          {id && (
            <S.LastChanged>
              {id}
            </S.LastChanged>
          )}
          <div>
            <S.Title
              style={{
                marginBottom: '5px',
                ...importFailedWidth,
                ...companiesType,
                fontWeight: changes ? '500' : 'normal',
              }}>
              {changes
                ? (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{
                      width: '9px',
                      height: '9px',
                      borderRadius: '50%',
                      background: '#FF0000',
                      border: '1px solid #FFFFFF',
                      boxSizing: 'border-box',
                      marginRight: '13px',
                    }}
                    />
                    {name}
                  </div>
                ) : name}
            </S.Title>
            {_.map(subtitle, i => <S.SubTitleGrey key={i}>{i}</S.SubTitleGrey>)}
          </div>
        </div>

        {permissions
          && (
            <div style={{ marginLeft: '2.5rem' }}>
              <S.LastChanged style={{ color: '#202538' }}>
                {permissions}
              </S.LastChanged>
            </div>
          )}

        {type && !isCorp
          && (
            <div style={{ marginLeft: '2.5rem' }}>
              <S.LastChanged>
                {type}
              </S.LastChanged>
            </div>
          )}

        {corporation && !isCorp
          && (
            <div style={{ marginLeft: '11.5rem' }}>
              <S.LastChanged>
                {corporation}
              </S.LastChanged>
            </div>
          )}

        {importFailed
          && (
            <div style={{
              display: 'flex',
              alignItems: 'center',
              width: '30rem',
            }}>
              <S.LastChanged>
                {line}
              </S.LastChanged>
              <S.LastChanged>
                {error}
              </S.LastChanged>
            </div>
          )}

        {imports
          && (
            <div style={{
              display: 'flex',
              alignItems: 'center',
              width: '38rem',
              marginRight: '0',
            }}>
              {method && (
                <S.LastChanged
                  style={{ marginRight: '2.5rem', marginLeft: '-5.5rem', width: '7rem' }}>
                  {method}
                </S.LastChanged>
              )}
              {wasteScenario && (
                <S.LastChanged style={{ marginRight: '7rem' }}>
                  {wasteScenario}
                </S.LastChanged>
              )}
              {date && (
                <S.LastChanged style={{ width: '22rem', fontWeight: changes ? '500' : 'normal' }}>
                  {moment(date).format('DD-MM-YYYY HH:mm')}
                </S.LastChanged>
              )}
              {status && (
                <S.LastChanged
                  style={{
                    width: '18rem',
                    display: 'flex',
                    alignItems: 'center',
                    fontWeight: changes ? '500' : 'normal',
                  }}>
                  {status}
                  {complete && <img alt="Complete" src={complete} style={{ marginLeft: '6px' }} />}
                </S.LastChanged>
              )}
            </div>
          )}

        {!(imports || importFailed || calculationImport) && (
          <div
            style={
              balance || company ? (
                {
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: '35rem',
                }
              ) : null
            }>
            {code && (
              <S.LastChanged style={{ marginRight: '2rem' }}>
                {code}
              </S.LastChanged>
            )}
            {company && (
              <S.LastChanged
                style={
                  balance || company
                    ? { width: 'calc(100% / 4)', marginRight: '5rem' } : null
                }>
                {company}
              </S.LastChanged>
            )}
            {lastChanged && (
              <S.LastChanged
                style={balance || company ? { width: 'calc(100% / 4)' } : null}>
                {moment(lastChanged).format('DD-MM-YYYY HH:mm')}
              </S.LastChanged>
            )}
            {Number.isInteger(balance) && (
              <S.LastChanged style={{ minWidth: 'calc(100% / 4)', textAlign: 'center' }}>
                {balance}
              </S.LastChanged>
            )}
          </div>
        )}
      </S.StyledLink>

      {icon && icon}

      {dropdownContent && (
        <S.AlignCenter hideDropdown={hideDropdown}>
          <DropDown content={dropdownContent}>
            <S.Options alt="icon" src={dots} />
          </DropDown>
        </S.AlignCenter>
      )}
    </S.Wrapper>
  );
};

export default memo(ListItem);
