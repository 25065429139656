import _ from 'lodash';
import React, {
  useState, useRef, useCallback, useEffect,
} from 'react';
import {
  Switch, Route, useLocation, useRouteMatch, useParams,
} from 'react-router-dom';
import { useAsyncCallback } from 'react-async-hook';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { /* companies as companiesServices, */ locations } from 'services';
import { selectCompany } from 'state/company/company.actions';

import Imports from 'routes/SuppliersAndLocations/Imports';
import NewImport from 'routes/SuppliersAndLocations/NewImport';
import Locations from 'routes/SuppliersAndLocations/Locations';
import NewLocation from 'routes/SuppliersAndLocations/NewLocation';
import NewSupplier from 'routes/SuppliersAndLocations/NewSupplier';
import Supplier from 'routes/SuppliersAndLocations/Supplier';
import Suppliers from 'routes/SuppliersAndLocations/Suppliers';
import AsideLayout from 'components/AsideLayout/AsideLayout';
import AsideOverview from 'components/AsideOverview/AsideOverview';
import { MenuItem } from 'components/VerticalMenu';
import { FormSelect } from 'components/Select/Select';
import { FormInput } from 'components/Input/Input';
import { Loading } from 'components/Splash/Loading';
import * as S from 'routes/GlobalStyle.styled';
import { canImportLocationsAndSuppliers } from '../../utils/featureAccess';

const SuppliersAndLocations = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { url } = useRouteMatch();
  const { id } = useParams();
  const user = useSelector(state => state.user);
  const {
    selectedCompany, id: companyId, name: companyName, ...other
  } = useSelector(({ company }) => company || {});
  const dispatch = useDispatch();
  const pathArray = pathname.split('/');
  const tempSelected = pathArray[2];
  const currentPath = pathArray[pathArray.length - 1];
  const [selected, setSelected] = useState(tempSelected);
  const [totalNumberOfRecords, setTotalNumberOfRecords] = useState(null);
  const [input, setInput] = useState('');
  const [clearInput, setClearInput] = useState(null);
  const [country, setCountry] = useState(null);
  const [city, setCity] = useState(null);
  const [company, setCompany] = useState(null);
  const time = useRef(0);

  const countries = locations.countries();

  const canImport = canImportLocationsAndSuppliers(user);

  /* const {
    result: companies, execute: executeCompanies,
  } = useAsyncCallback(companiesServices.getAll); */

  const {
    result: supplier, execute: executeSupplier, loading,
  } = useAsyncCallback(() => locations.supplier(id), []);

  useEffect(() => {
    // if (superAdmin) executeCompanies();
    if (url === `/locations-&-suppliers/suppliers/${id}` && !url.includes('new')) executeSupplier();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [/* superAdmin,  */url]);

  const clearFilter = () => {
    const inputValue = { value: '' };
    setClearInput(inputValue);
    setInput('');
    setCountry(null);
    setCity('');
    setCompany({ label: companyName, value: companyName });
    selectCompany({
      id: companyId, name: companyName, label: companyName, value: companyName, ...other,
    })(dispatch);
  };

  const handleInputChange = (e, type) => {
    if (time.current) clearTimeout(time.current);
    const inputText = e.target.value;
    time.current = setTimeout(() => {
      const clearSpaces = inputText && inputText.length > 0 && inputText.trim();
      if (clearSpaces) {
        if (type === 'search') setInput(inputText);
        else setCity(inputText);
        clearTimeout(time.current);
      } else if (type === 'search') setInput('');
      else setCity('');
    }, 2000);
  };

  const handleOnKeyDown = (event, type) => {
    if (event.key === 'Enter') {
      const inputText = event.target.value;
      const clearSpaces = inputText && inputText.length > 0 && inputText.trim();
      if (clearSpaces) {
        if (type === 'search') setInput(inputText);
        else setCity(inputText);
      } else if (type === 'search') setInput('');
      else setCity('');
    }
  };

  const onFocus = () => setClearInput(null);

  const suppliersComp = useCallback((props) => (
    <Suppliers
      {...props}
      city={city}
      company={company}
      country={country}
      input={input}
      setTotalNumberOfRecords={setTotalNumberOfRecords}
    />
  ), [input, country, city, company]);

  const locationsComp = useCallback((props) => (
    <Locations
      {...props}
      city={city}
      company={company}
      country={country}
      input={input}
      setTotalNumberOfRecords={setTotalNumberOfRecords}
    />
  ), [input, country, city, company]);

  /* const handleDropdownChangeCompany = (companyValue) => {
    setCompany(companyValue);
    selectCompany(companyValue)(dispatch);
  }; */

  if (selected !== tempSelected) {
    setSelected(tempSelected);
    const inputValue = { value: '' };
    setClearInput(inputValue);
    setInput('');
    setCountry(null);
    setCity('');
  }

  const isLocations = url === '/locations-&-suppliers/locations';

  return (
    <AsideLayout
      LeftPane={(
        !loading
          ? (
            <div>
              {url !== `/locations-&-suppliers/suppliers/${id}`
                ? (
                  <>
                    <S.AsideTitle>
                      {t(url?.includes('/import/new')
                        ? 'import locations & suppliers.label' : 'Locations and Suppliers')}
                    </S.AsideTitle>
                    {url?.includes('/import/new')
                      ? (
                        <S.LinksWrapper>{t('import locations & suppliers.hint')}</S.LinksWrapper>
                      )
                      : (
                        <S.LinksWrapper>
                          <MenuItem to="/locations-&-suppliers/locations">
                            {t('locations')}
                          </MenuItem>

                          <MenuItem to="/locations-&-suppliers/suppliers">
                            {t('suppliers')}
                          </MenuItem>

                          {canImport
                            && (
                              <MenuItem to="/locations-&-suppliers/import">
                                {t('import')}
                              </MenuItem>
                            )}
                        </S.LinksWrapper>
                      )}
                  </>
                ) : (
                  <S.LinksWrapper>
                    <p style={{ fontWeight: '500', fontSize: '22px', lineHeight: '29px' }}>
                      {supplier?.name}
                    </p>

                    <p style={{ marginBottom: '1.5rem' }}>
                      {`ID: ${supplier?.code}`}
                    </p>

                    <p>{supplier?.address}</p>

                    <div style={{ flexDirection: 'row', display: 'flex' }}>
                      <p style={{ marginRight: '0.2rem' }}>{supplier?.postalCode}</p>
                      <p>{supplier?.city}</p>
                    </div>

                    <p>{_.find(countries, value => value.value === supplier?.country)?.label}</p>
                  </S.LinksWrapper>
                )}
              {(selected === currentPath) && (selected !== 'import')
                && (
                  <AsideOverview
                    clearFilter={clearFilter}
                    clearingFilterCondition={
                      (input || city || country || (company/*  && (companyId !== selectedCompany?.id) */
                        && (company.id !== 'none'))) && (totalNumberOfRecords !== null)
                    }
                    results={totalNumberOfRecords}>
                    <FormInput
                      {...clearInput}
                      fullInputWidth
                      id="name"
                      name="name"
                      onChange={(e) => handleInputChange(e, 'search')}
                      onFocus={onFocus}
                      onKeyDown={(e) => handleOnKeyDown(e, 'search')}
                      placeholder={t(isLocations
                        ? 'find location by name' : 'find supplier by name')}
                    />
                    <FormSelect
                      fullInputWidth
                      isSearchable
                      name="country"
                      onChange={setCountry}
                      options={countries}
                      placeholder={t(isLocations
                        ? 'find location by country' : 'find supplier by country')}
                      value={country}
                    />
                    <FormInput
                      {...clearInput}
                      fullInputWidth
                      id="city"
                      name="city"
                      onChange={(e) => handleInputChange(e, 'city')}
                      onFocus={onFocus}
                      onKeyDown={(e) => handleOnKeyDown(e, 'city')}
                      placeholder={t(isLocations
                        ? 'find location by city' : 'find supplier by city')}
                    />
                    {/* {superAdmin
                      && (
                        <FormSelect
                          fullInputWidth
                          isSearchable
                          name="company"
                          onChange={handleDropdownChangeCompany}
                          options={companies?.companies}
                          placeholder={t('all companies')}
                          value={company}
                        />
                      )} */}
                  </AsideOverview>
                )}
            </div>
          ) : Loading
    )}>
      <Switch>
        <Route component={Imports} exact path="/locations-&-suppliers/import" />
        <Route component={NewImport} exact path="/locations-&-suppliers/import/new" />
        <Route component={suppliersComp} exact path="/locations-&-suppliers/suppliers" />
        <Route component={NewSupplier} exact path="/locations-&-suppliers/suppliers/:id/edit" />
        <Route component={NewSupplier} exact path="/locations-&-suppliers/suppliers/new" />
        <Route component={Supplier} exact path="/locations-&-suppliers/suppliers/:id" />
        <Route component={locationsComp} exact path="/locations-&-suppliers/locations" />
        <Route component={NewLocation} exact path="/locations-&-suppliers/locations/:id/edit" />
        <Route component={NewLocation} exact path="/locations-&-suppliers/locations/new" />
      </Switch>
    </AsideLayout>
  );
};

export default SuppliersAndLocations;
