import * as types from './company.types';

export const getCompany = (usersList) => dispatch => {
  if (usersList) {
    dispatch({
      type: types.UPDATE,
    });
  }
};

export const selectCompany = company => dispatch => {
  dispatch({
    type: types.SELECTED_COMPANY,
    selectedCompany: company,
  });
};

export const clear = { type: types.CLEAR };
