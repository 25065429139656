import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { FormInput } from 'components/Input/Input';
import { reduxForm, Form, Field } from 'redux-form';
import Button from 'components/Button/Button';
import { FormSimpleCKEditor } from 'components/SimpleCKEditor';
import { useHistory, useParams } from 'react-router-dom';
import * as S from 'routes/GlobalStyle.styled';
import MainModal from 'components/Modal';

const LCAMethodForm = ({
  handleSubmit,
  pristine,
  invalid,
  submitting,
  handleSubmitValues,
  method,
}) => {
  const { t } = useTranslation();
  const { isHidden } = useSelector(state => state.sidebar);
  const [hasEditedForm, setHasEditedForm] = useState(false);

  const history = useHistory();
  const { id } = useParams();

  const onChange = (editor, input, removeHTMLTags) => {
    const data = removeHTMLTags ? editor.getData().replace(/<(.|\n)*?>/g, '') : editor.getData();
    input.onChange(data || null);
  };

  return (
    <>
      <S.FormContainer isHidden={isHidden}>
        <Form onSubmit={handleSubmit((props) => {
          setHasEditedForm(true);
          return handleSubmitValues(props);
        })}>
          <Field
            component={FormInput}
            name="name"
            props={{
              name: 'name',
            }}
          />
          <Field
            component={FormSimpleCKEditor}
            name="pcr"
            props={{
              name: 'pcr',
              content: method?.pcr,
              onChange: (editor, input) => onChange(editor, input, true),
            }}
          />
          <Field
            component={FormInput}
            name="software"
            props={{
              name: 'lca software',
            }}
          />
          <Field
            component={FormInput}
            name="characterizationMethod"
            props={{
              name: 'characterization method',
            }}
          />
          <Field
            component={FormInput}
            name="lcaDatabase"
            props={{
              name: 'lca database profiles',
            }}
          />
          <Field
            component={FormSimpleCKEditor}
            name="purposeDescription"
            props={{
              name: 'simple purpose and target groups',
              content: method?.purposeDescription,
              onChange,
            }}
          />
          <Field
            component={FormSimpleCKEditor}
            name="abbreviations"
            props={{
              name: 'abbreviations',
              content: method?.abbreviations,
              onChange,
            }}
          />
          <Field
            component={FormSimpleCKEditor}
            name="cutoffCriteriaProduct"
            props={{
              name: 'Cut-off criteria Product Stage (A1-A3)',
              content: method?.cutoffCriteriaProduct,
              onChange,
            }}
          />
          <Field
            component={FormSimpleCKEditor}
            name="cutoffCriteriaConstruction"
            props={{
              name: 'Cut-off criteria Construction process stage (A4-A5)',
              content: method?.cutoffCriteriaConstruction,
              onChange,
            }}
          />
          <Field
            component={FormSimpleCKEditor}
            name="cutoffCriteriaUse"
            props={{
              name: 'Cut-off criteria Use stage (B1-B3)',
              content: method?.cutoffCriteriaUse,
              onChange,
            }}
          />
          <Field
            component={FormSimpleCKEditor}
            name="cutoffCriteriaEOL"
            props={{
              name: 'Cut-off criteria End of life stage (C1-C4)',
              content: method?.cutoffCriteriaEOL,
              onChange,
            }}
          />
          <Field
            component={FormSimpleCKEditor}
            name="cutoffCriteriaSupplementary"
            props={{
              name: 'Cutt-off criteria Benefits and Loads beyond the system boundary (Module D)',
              content: method?.cutoffCriteriaSupplementary,
              onChange,
            }}
          />
          <Field
            component={FormSimpleCKEditor}
            name="annex2References"
            props={{
              name: 'Annex 2 References',
              content: method?.annex2References,
              onChange,
            }}
          />
          <div style={{ display: 'flex' }}>
            <Button
              disabled={pristine || invalid}
              loading={submitting}
              style={{ marginRight: '24px' }}
              styling="select"
              type="submit">
              {t('save')}
            </Button>
            <Button
              onClick={() => history
                .push(`/manage/lca-methods/${id}`)}
              styling="underline">
              {t('cancel')}
            </Button>
          </div>
        </Form>
      </S.FormContainer>
      <MainModal
        content="prompt"
        exitModalContent={{
          navigateSideEffects: () => null,
        }}
        prompt
        promptCondition={!pristine && !hasEditedForm}
      />
    </>
  );
};

export default reduxForm({
  form: 'edit-lcamethod',
  enableReinitialize: true,
})(LCAMethodForm);
