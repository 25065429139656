import _ from 'lodash';
/* import network from 'services';
import { useAsync } from 'react-async-hook'; */
import {
  useStandardUnits, useCustomUnitForLCA, useStandardUnitForLCA,
} from 'hooks/functionalUnit.hooks';

// const BASE_URL = process.env.REACT_APP_API_URL;

export const useResolveUnit = (props) => {
  const isSfp = props?.calculationType === 'semi_finished_product';
  const standardUnits = useStandardUnits(props?.standardUnit);
  const customUnitForLCA = useCustomUnitForLCA(null, props);
  const standardUnitForLCA = useStandardUnitForLCA(null, props);

  let data = null;

  if (isSfp) {
    data = standardUnits?.selected;
  } else if (!props?.customFunctionalUnit && standardUnitForLCA?.selected) {
    data = standardUnitForLCA.selected;
  } else {
    data = customUnitForLCA?.selected;
  }

  /* const { result: data = {}, loading } = useAsync(() => {
    if (isSfp || (props?.standardUnit && props?.customFunctionalUnit)) {
      return network.get(`${BASE_URL}${props?.standardUnit}`, { cache: true });
    }
    if (props?.functionalUnit) {
      return network.get(`${BASE_URL}${props?.functionalUnit}`, { cache: true });
    }
    return {};
  }, [props?.functionalUnit, props?.standardUnit]); */

  // if (loading) return null;
  if (_.isEmpty(data)) return null;

  // return data[(props?.functionalUnit && !props?.customFunctionalUnit) ? 'unit' : 'abbreviation'];
  return data?.abbreviation || data?.unit;
};
