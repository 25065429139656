import React, { memo } from 'react';
import ReactSelect from 'react-select';
import ReactSelectAsync from 'react-select/async';
import AsyncPaginate from 'react-select-async-paginate';

import { withLabels } from 'components/Field';

import styles, { theme } from './Select.styles';

const Select = props => (
  <ReactSelect styles={styles} theme={theme} {...props} />
);

export const SelectAsync = props => (
  <ReactSelectAsync styles={styles} theme={theme} {...props} />
);

export const AsyncSelect = props => (
  <AsyncPaginate
    onBlur={() => props?.input?.onBlur(props?.input?.value)}
    onChange={props?.input?.onChange}
    shouldLoadMore={(scrollHeight, clientHeight, scrollTop) => {
      const bottomBorder = (scrollHeight - clientHeight) / 1.4;
      return bottomBorder < scrollTop;
    }}
    styles={styles}
    theme={theme}
    value={props?.input?.value}
    {...props}
  />
);

const LabeledSelect = ({ input, ...props }) => (
  <Select {...props} {...input} onBlur={() => input?.onBlur(input.value)} />
);

const LabeledSelectAsync = ({ input, ...props }) => (
  <SelectAsync {...props} {...input} onBlur={() => input?.onBlur(input.value)} />
);

export const FormSelect = withLabels(LabeledSelect);
export const FormAsyncSelect = withLabels(AsyncSelect);
export const FormSelectAsync = withLabels(LabeledSelectAsync);

export default memo(Select);
