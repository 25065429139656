import _ from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import AddIcon from 'assets/add.svg';
import FinishProduct from 'assets/finished-product.svg';
import Import from 'assets/import.svg';
import Location from 'assets/location.svg';
import Supplier from 'assets/supplier.svg';
import * as S from '../Content.styled';

const Add = ({ close }) => {
  const { t } = useTranslation();
  const { selectedMembership } = useSelector(state => state.user);

  const highRoles = selectedMembership?.view === 'Super Admin'
    || selectedMembership?.view === 'Nibe Employee'
    || selectedMembership?.view === 'Corporate Admin'
    || selectedMembership?.roleName === 'ROLE_EPD_ADMIN';

  const quickLinks = [
    { path: '/calculations/root/new?type=product', label: t('new product'), img: AddIcon },
    { path: '/calculations/root/new?type=semi', label: t('new semi'), img: FinishProduct },
    { path: '/locations-&-suppliers/suppliers/new', label: t('add supplier'), img: Supplier },
    { path: '/locations-&-suppliers/locations/new', label: t('add location'), img: Location },
    { path: '/locations-&-suppliers/import', label: t('Import suppliers'), img: Import },
  ];

  if (highRoles) {
    quickLinks.push({
      path: '/environmental-profiles/import',
      label: t('Import environmental profiles'),
      img: Import,
    });
  }

  return (
    <S.Wrapper>
      {_.map(quickLinks, ({ path, label, img }, i) => (
        <div key={label}>
          <S.StyledLine hasLine={i === 2 || i === 4} />

          <S.StyledLink key={label} exact onClick={(e) => close(e)} to={path}>
            <div>
              <img alt="icon" src={img} />
            </div>

            <S.StyledLabel isRegular={i > 1}>{label}</S.StyledLabel>
          </S.StyledLink>
        </div>
      ))}
    </S.Wrapper>
  );
};

export default Add;
