import React from 'react';
import { SemiFinishedRow } from '../Rows/SemiFinishedRow';
import CalculationInputItem from '../CalculationInputItem';
import { PredefinedInputSetInputs } from '../PredefinedInputSets/PredefinedInputSetInputs';
import { useResolveUnit } from '../../../../../../components/FunctionalUnit/FunctionalUnit.hook';

export const SemiFinishedProductInputs = ({
  sfpUsage, inputs, predefinedInputSetUsages, forceExpanded, updateContext,
}) => {
  const unit = useResolveUnit({
    calculationType: 'product', standardUnit: sfpUsage.standardUnit,
  });
  return (
    <SemiFinishedRow
      blueBackground
      forceExpanded={forceExpanded}
      forSubInputs
      funcUnit={unit}
      product={sfpUsage}>
      <ul style={{ paddingBottom: 1.1 }}>
        {predefinedInputSetUsages.map(pdisUsage => (
          <PredefinedInputSetInputs
            amount={pdisUsage.amount}
            code={pdisUsage.predefinedInputSet.code}
            forceExpanded={forceExpanded}
            inputs={pdisUsage.inputs}
            name={pdisUsage.predefinedInputSet.name}
            unit={pdisUsage.predefinedInputSet.unit}
          />
        ))}
        {inputs.map(input => (
          <CalculationInputItem
            key={input?.id}
            expandDetails={forceExpanded}
            input={input /* this one comes calculated from the backend, unlike PDIS... */}
            isSfp
            updateContext={updateContext}
          />
        ))}
      </ul>
    </SemiFinishedRow>
  );
};
