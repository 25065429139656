import React, {
  useState, useEffect, useRef, useCallback,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import {
  Redirect, Switch, Route, useLocation, useRouteMatch, useParams, Link,
} from 'react-router-dom';
import _ from 'lodash';
import {
  companies as companiesServices, locations, users, methods, versions,
} from 'services';
import { selectCompany } from 'state/company/company.actions';
import AsideLayout from 'components/AsideLayout/AsideLayout';
import AsideOverview from 'components/AsideOverview/AsideOverview';
import { FormInput } from 'components/Input/Input';
import { FormSelect } from 'components/Select/Select';
import { MenuItem } from 'components/VerticalMenu';
import Users from 'routes/Users/Users';
import NewCompanyPermissions from 'routes/Users/NewCompanyPermissions';
import Companies from 'routes/Companies/Companies';
import LCAMethods from 'routes/LCAMethods/LCAMethods';
import EditLCAMethod from 'routes/LCAMethods/EditLCAMethod';
import LCAMethod from 'routes/LCAMethods/LCAMethod';
import EditProfile from 'routes/MyProfile/EditProfile';
import NewUser from 'routes/Users/NewUser';
import NewCompany from 'routes/Companies/NewCompany';
import CompanyProfile from 'routes/Companies/CompanyProfile';
import * as S from 'routes/GlobalStyle.styled';
import Announcements from 'routes/MyProfile/Announcements';
import AnnouncementDetail from 'routes/MyProfile/AnnouncementDetail';
import NewAnnouncement from 'routes/MyProfile/NewAnnouncement';
import { Placeholder } from 'components/Splash/Loading';
import Button from 'components/Button/Button';
import { useAsyncCallback } from 'react-async-hook';

const Manage = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { url } = useRouteMatch();
  const { id } = useParams();
  const {
    selectedCompany, id: companyId, name: companyName, ...other
  } = useSelector(({ company }) => company || {});
  const dispatch = useDispatch();
  const pathArray = pathname.split('/');
  const tempSelected = pathArray[2];
  const currentPath = pathArray[pathArray.length - 1];
  const [selected, setSelected] = useState(tempSelected);
  const user = useSelector(state => state.user);
  const [companies, setCompanies] = useState(null);
  const [input, setInput] = useState('');
  const [company, setCompany] = useState(selectedCompany);
  const [totalNumberOfRecords, setTotalNumberOfRecords] = useState(null);
  const [companyInfo, setCompanyInfo] = useState(null);
  const [clearInput, setClearInput] = useState(null);
  const [loading, setLoading] = useState(false);
  // const [loadingUser, setLoadingUser] = useState(false);
  const [loadingCompanyInfo, setLoadingCompanyInfo] = useState(false);
  // const [userValue, setUser] = useState(null);
  const [type, setType] = useState(null);
  const [corporation, setCorporation] = useState(null);
  const time = useRef(0);

  const types = [
    {
      label: t('company.label'),
      value: false,
    },
    {
      label: t('corporation'),
      value: true,
    },
  ];

  const countries = locations.countries();

  const isUserProfile = url.includes('manage/users') && id;

  const {
    result: userResult, execute: executeUser, loading: loadingUser,
  } = useAsyncCallback(() => users.getUser(id), []);

  const {
    result: lcamethodResult, execute: executeLcamethod, loading: loadingLcamethod,
  } = useAsyncCallback(() => methods.get(id), []);

  const {
    result: announcementResult, execute: executeAnnouncement, loading: loadingAnnouncement,
  } = useAsyncCallback(() => versions.getAnnouncementById(id), []);

  useEffect(() => {
    if (isUserProfile && (id || !url.includes('new'))) executeUser();
    if (url === `/manage/lca-methods/${id}`) executeLcamethod();
    if ((url === `/manage/announcements/${id}`
      || url === `/manage/announcements/${id}/edit`)
      && !url.includes('new')) executeAnnouncement();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  const clearFilter = () => {
    const inputValue = { value: '' };
    setClearInput(inputValue);
    setInput('');
    setType(null);
    setCorporation(null);
    setCompany({ label: companyName, value: companyName });
    selectCompany({
      id: companyId, name: companyName, label: companyName, value: companyName, ...other,
    })(dispatch);
  };

  const companyAdmin = user?.selectedMembership?.view === 'Company Admin'
    || user?.selectedMembership?.view === 'Corporate Admin';

  const corporateAdmin = user?.selectedMembership?.view === 'Corporate Admin';

  const superAdmin = user?.selectedMembership?.view === 'Super Admin'
    || user?.selectedMembership?.view === 'Nibe Employee';

  useEffect(() => {
    /* if (isUserProfile) {
      setLoadingUser(true);
      users.getUser(id).then(value => {
        setUser(value);
      }).finally(() => setLoadingUser(false));
    } */
    if (superAdmin || corporateAdmin) {
      setLoading(true);
      const params = {};
      if ((url === '/manage/companies') && !corporateAdmin) params.corporation = true;
      companiesServices.getAll({ params })
        .then(values => setCompanies(values.companies))
        .finally(() => setLoading(false));
    } else if (user?.selectedMembership?.view === 'Company Admin'
      && url?.includes('/manage/companies')) {
      setLoadingCompanyInfo(true);
      companiesServices.getCompany(user?.selectedMembership?.company['@id'])
        .then(setCompanyInfo)
        .finally(() => setLoadingCompanyInfo(false));
    }
  }, [superAdmin, user.company, isUserProfile, id, url, user, corporateAdmin]);

  const handleDropdownChangeCompany = (companyValue) => {
    setCompany(companyValue);
    selectCompany(companyValue)(dispatch);
  };

  const handleInputChange = (e) => {
    if (time.current) clearTimeout(time.current);
    const inputText = e.target.value;
    time.current = setTimeout(() => {
      const clearSpaces = inputText && inputText.length > 0 && inputText.trim();
      if (clearSpaces) {
        setInput(inputText);
        clearTimeout(time.current);
      } else {
        setInput('');
      }
    }, 2000);
  };

  const onFocus = () => setClearInput(null);

  const usersComp = useCallback((props) => (
    <Users
      {...props}
      company={company}
      input={input}
      setTotalNumberOfRecords={setTotalNumberOfRecords}
    />
  ), [company, input]);

  const companiesComp = useCallback(props => (
    <Companies
      {...props}
      corporation={corporation}
      input={input}
      setTotalNumberOfRecords={setTotalNumberOfRecords}
      type={type}
    />
  ), [corporation, input, type]);

  const CustomAnnouncements = useCallback(props => (
    <Announcements
      {...props}
      isManage
    />
  ), []);

  const corpCompanies = useCallback(props => (
    <CompanyProfile
      {...props}
      input={input}
      isCorp
      setTotalNumberOfRecords={setTotalNumberOfRecords}
    />
  ), [input]);

  // eslint-disable-next-line eqeqeq
  const companySelected = companies?.find(c => c?.id == id) || companyInfo;

  if (!superAdmin && !companyAdmin) return <Redirect to="/" />;

  if (selected !== tempSelected) {
    const inputValue = { value: '' };
    setClearInput(inputValue);
    setInput('');
    setSelected(tempSelected);
  }

  const isCompanyProfile = url.includes('manage/companies') && !url.includes('new') && id;

  const handleOnKeyDown = (event) => {
    if (event.key === 'Enter') {
      const inputText = event.target.value;
      const clearSpaces = inputText && inputText.length > 0 && inputText.trim();
      if (clearSpaces) setInput(inputText);
      else setInput('');
    }
  };

  return (
    <AsideLayout
      LeftPane={(
        <div>
          <Placeholder
            loaders={[
              loading, loadingUser, loadingLcamethod, loadingAnnouncement, loadingCompanyInfo]}>
            {isUserProfile
              && (
                <S.LinksWrapper>
                  <p style={{ fontWeight: '500', fontSize: '22px', lineHeight: '29px' }}>
                    {userResult?.name}
                  </p>

                  <p style={{ marginTop: '13px' }}>{userResult?.email}</p>
                  <p style={{ marginTop: '2rem', fontStyle: 'italic' }}>
                    {userResult?.enabled ? t('enabled account') : t('disabled account')}
                  </p>
                  {url !== `/manage/users/${id}/edit`
                    && (
                      <Button
                        as={Link}
                        style={{ marginTop: '1rem', padding: 0 }}
                        styling="underline"
                        to={`/manage/users/${userResult?.id}/edit`}>
                        {t('edit user')}
                      </Button>
                    )}
                </S.LinksWrapper>
              )}
            {url === `/manage/lca-methods/${id}`
              && (
                <S.LinksWrapper>
                  <p style={{ fontWeight: '500', fontSize: '22px', lineHeight: '29px' }}>
                    {lcamethodResult?.name}
                  </p>
                </S.LinksWrapper>
              )}
            {isCompanyProfile && (superAdmin || companyAdmin)
              && (
                <S.LinksWrapper>
                  <p style={{ fontWeight: '500', fontSize: '22px', lineHeight: '29px' }}>
                    {companySelected?.name}
                  </p>

                  <p style={{ marginTop: '13px' }}>{companySelected?.address}</p>

                  <div style={{ flexDirection: 'row', display: 'flex' }}>
                    <p style={{ marginRight: '0.2rem' }}>{companySelected?.postalCode}</p>
                    <p>{companySelected?.city}</p>
                  </div>

                  <p>
                    {_.find(countries, value => value.value === companySelected?.country)
                      ?.label}
                  </p>
                </S.LinksWrapper>
              )}
            {((url === '/manage/users') || (url === '/manage/users/new'))
              && !(superAdmin
                || user?.selectedMembership?.view === 'Company Admin')
              && (
                <S.AsideTitle>
                  {t('users')}
                </S.AsideTitle>
              )}
            {((url === '/manage/companies') || (url === '/manage/companies/new'))
              && !(superAdmin
                || user?.selectedMembership?.view === 'Company Admin')
              && (
                <S.AsideTitle>
                  {t('companies')}
                </S.AsideTitle>
              )}
            {(url === `/manage/announcements/${id}` || url === `/manage/announcements/${id}/edit`)
              && (
                <S.AsideTitle>
                  {url.includes('new') ? t('new announcement') : announcementResult?.title}
                </S.AsideTitle>
              )}
            {!isCompanyProfile
              && !isUserProfile && (superAdmin
                || user?.selectedMembership?.view === 'Company Admin')
              && ((url !== `/manage/users/${id}`) || url.includes('new'))
              && (url !== `/manage/lca-methods/${id}`)
              && (url !== `/manage/announcements/${id}`)
              && (url !== `/manage/announcements/${id}/edit`)
              && (
                <>
                  <S.AsideTitle>
                    Manage
                  </S.AsideTitle>
                  <S.LinksWrapper>
                    <MenuItem to="/manage/users">
                      {t('users')}
                    </MenuItem>

                    {!superAdmin && companyAdmin && (
                      <MenuItem to={{
                        // eslint-disable-next-line max-len
                        pathname: `/manage/companies/${user?.selectedMembership?.company?.id}/basic-information`,
                        state: {
                          routeName: user?.selectedMembership?.company?.name,
                        },
                      }}>
                        {t('Company')}
                      </MenuItem>
                    )}

                    {superAdmin
                      && (
                        <MenuItem to="/manage/companies">
                          {t('companies')}
                        </MenuItem>
                      )}

                    {superAdmin && (
                      <>
                        <MenuItem to="/manage/lca-methods">
                          {t('lca methods')}
                        </MenuItem>

                        <MenuItem to="/manage/announcements">
                          {t('announcements')}
                        </MenuItem>
                      </>
                    )}
                  </S.LinksWrapper>
                </>
              )}

            {((selected !== 'lca-methods')
              && (selected !== 'announcements') && (selected === currentPath))
              && (
                <AsideOverview
                  clearFilter={clearFilter}
                  clearingFilterCondition={
                    (input || type || corporation
                      || (company && (companyId !== selectedCompany?.id)
                      && (company.id !== 'none'))) && (totalNumberOfRecords !== null)
                  }
                  results={totalNumberOfRecords}>
                  <FormInput
                    {...clearInput}
                    fullInputWidth
                    id="name"
                    name="name"
                    onChange={handleInputChange}
                    onFocus={onFocus}
                    onKeyDown={handleOnKeyDown}
                    placeholder={t('filter by name or id')}
                  />

                  {superAdmin && (selected === 'users') && (
                    <FormSelect
                      fullInputWidth
                      isSearchable
                      name="company"
                      onChange={handleDropdownChangeCompany}
                      options={companies}
                      placeholder={t('all companies')}
                      value={company}
                    />
                  )}
                  {superAdmin
                    && (url === '/manage/companies')
                    && (
                      <>
                        <FormSelect
                          fullInputWidth
                          isSearchable
                          name="type"
                          onChange={setType}
                          options={types}
                          placeholder={t('filter by type')}
                          value={type}
                        />
                        <FormSelect
                          fullInputWidth
                          isSearchable
                          name="Corporation"
                          onChange={setCorporation}
                          options={companies}
                          placeholder={t('filter by corporation')}
                          value={corporation}
                        />
                      </>
                    )}
                </AsideOverview>
              )}
          </Placeholder>
        </div>
    )}>
      <Switch>
        <Route component={usersComp} exact path="/manage/users" />
        <Route component={NewUser} exact path="/manage/users/new" />
        <Route component={EditProfile} exact path="/manage/users/:id" />
        <Route component={NewCompanyPermissions} exact path="/manage/users/:id/memberships/new" />
        <Route
          component={
            (props) => (
              <Redirect
                exact
                to={`/manage/users/${props?.match?.params?.id}`}
              />
            )
          }
          exact
          path="/manage/users/:id/memberships"
        />
        <Route
          component={NewCompanyPermissions}
          exact
          path="/manage/users/:id/memberships/:companyId/edit"
        />
        <Route component={NewUser} exact path="/manage/users/:id/edit" />
        <Route component={companiesComp} exact path="/manage/companies" />
        <Route component={corpCompanies} exact path="/manage/companies/:id/companies" />
        <Route component={NewCompany} exact path="/manage/companies/new" />
        <Route
          component={CompanyProfile}
          exact
          path="/manage/companies/:id/basic-information"
        />
        <Route
          component={
            (props) => (
              <Redirect
                exact
                to={`/manage/companies/${props?.match?.params?.id}/basic-information`}
              />
            )
          }
          exact
          path="/manage/companies/:id"
        />
        <Route component={NewCompany} exact path="/manage/companies/:id/edit" />
        <Route component={LCAMethods} exact path="/manage/lca-methods" />
        <Route component={LCAMethod} exact path="/manage/lca-methods/:id" />
        <Route component={EditLCAMethod} exact path="/manage/lca-methods/:id/edit" />
        <Route
          component={CustomAnnouncements}
          exact
          path="/manage/announcements"
        />
        <Route component={NewAnnouncement} exact path="/manage/announcements/new" />
        <Route
          component={props => <AnnouncementDetail {...props} isManage />}
          exact
          path="/manage/announcements/:id"
        />
        <Route component={NewAnnouncement} exact path="/manage/announcements/:id/edit" />
      </Switch>
    </AsideLayout>
  );
};

export default Manage;
