import styled from 'styled-components/macro';
import { DARK_BLUE } from 'config/style';

export const StyledContainer = styled.button`
  background: white;
  min-width: 7rem;
  padding: .6rem 1.3rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${DARK_BLUE};
  border-radius: 40px;
  font-size: 1rem;
  cursor: pointer;
  font-weight: 600;
  box-shadow: 0 0 4px rgba(129, 129, 129, 0.25);
  border: 1px solid #E2E2D2;

  *:first-child {
    margin-right: 10px;
  }

  &:focus {
    outline: none;
  }
`;
