import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAsync } from 'react-async-hook';
import { inputs, methods as methodsService, countries as countriesService } from 'services';
import { Placeholder } from 'components/Splash/Loading';
import AsideLayout from 'components/AsideLayout/AsideLayout';
import * as S from 'routes/GlobalStyle.styled';
import PdisForm from './PdisForm';
import { networkErrorToast } from '../../../../../config/toast';

const NewPdis = () => {
  const h = useHistory();
  const { t } = useTranslation();

  const { result, loading } = useAsync(() => methodsService.all(), []);
  const {
    result: countries,
    loading: countriesLoading,
  } = useAsync(() => countriesService.getWasteRegions(), []);

  const loaderList = [loading, countriesLoading];

  const save = (values) => {
    const pdisInfo = {};

    if (values.Code) pdisInfo.code = values.Code;
    if (values.name) pdisInfo.name = values.name;
    if (values.Unit) pdisInfo.unit = values.Unit;
    if (values.methodLabel) pdisInfo.method = values?.methodLabel?.['@id'];
    if (values.WasteScenarioSet) pdisInfo.wasteScenarioSet = values?.WasteScenarioSet?.['@id'];

    return inputs.createPdis(pdisInfo).then(res => {
      h.push(`/calculations/predefined-input-sets/${res?.id}`);
    }).catch(networkErrorToast);
  };

  const [hidePanel, setHidePanel] = useState(true);

  return (
    <AsideLayout
      hidePanel={hidePanel}
      LeftPane={(
        <p style={{ padding: '2.5rem' }}>
          {t('hint new predefined input set')}
        </p>
      )}
      setHidePanel={setHidePanel}>
      <S.Background isEditable>
        <S.HeaderTitle>{t('new predefined input set')}</S.HeaderTitle>
        <Placeholder loaders={loaderList}>
          <div style={{ paddingLeft: hidePanel ? '0' : '0', transition: '1s' }}>
            <PdisForm
              methods={result?.methods}
              saveForm={save}
              wasteScenarioSets={countries}
            />
          </div>
        </Placeholder>
      </S.Background>
    </AsideLayout>
  );
};

export default NewPdis;
