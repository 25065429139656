import styled from 'styled-components';
import { DARK_BLUE } from 'config/style';

export const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  color: ${DARK_BLUE};

  th,
  td {
    text-align: center;
  }

  td {
    border: 1px solid #ebebeb;
  }

  th:first-child,
  td:first-child {
    width: auto;
    text-align: start;
    padding-left: 1.62rem;
  }

  td:first-child {
    font-size: .875rem;
  }
`;

export const TableRow = styled.tr`
  background: #F4F8FA;

  &:nth-of-type(odd) {
    background: ${({ totalRow }) => (totalRow ? '#cfe0e8' : 'white')};
  }
`;

export const TableHeader = styled.th`
  font-weight: 500;
  font-size: 1rem;
  padding: 1rem 0;
`;

export const TableData = styled.td`
  font-size: .75rem;
  padding: 1.43rem .5rem;
  border-top: 1px solid #ebebeb;
  border-bottom: 1px solid #ebebeb;
  width: 3.32rem;
  font-weight: ${({ totalRow }) => totalRow && 'bold'};
`;
