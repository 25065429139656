import styled from 'styled-components';

export const CheckBoxContainer = styled.label`
  display: block;
  position: relative;
  padding-left: 45px;
  margin-bottom: 20px;
  cursor: pointer;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    background: white;
    position: absolute;
    top: 0;
    left: 0;
    height: 1.25rem;
    width: 1.25rem;
    border: 1px solid #202538;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      display: none;
    }
  }

  input:checked ~ .checkmark {
    img {
      display: block;
    }
  }
`;
