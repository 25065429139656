import * as types from './search.types';

const initialState = {
  query: null,
};

const search = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_SEARCH_VALUE: {
      return { ...state, query: action.payload };
    }

    default:
      return state;
  }
};

export default search;
