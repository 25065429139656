import { useAsync } from 'react-async-hook';
import { errorToast } from 'config/toast';

import network from './network';

/**
 * Helpers
*/

const hydraMember = ({ 'hydra:member': data }) => data;

/**
 * Network
*/

export const getSuppliers = config => network
  .get('/suppliers', config)
  .then(hydraMember)
  .catch((err) => errorToast(err));

export const getSupplierById = (id, config) => id && network
  .get(`/suppliers/${id}`, config)
  .catch((err) => errorToast(err));

/**
 * Hooks
*/

export const useSupplier = (id) => useAsync(
  getSupplierById,
  [id],
);
