import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { setBreadcrumb } from 'state/sidebar/sidebar.actions';
import { useParams, useLocation, NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { getWasteScenarioById } from 'services/profiles';
import { countries } from 'services';

import { ContentInfoNoBorder } from 'components/ContentInfo/ContentInfo';
import { Loading } from 'components/Splash/Loading';
import AsideLayout from 'components/AsideLayout/AsideLayout';
import { theme } from 'components/Global.styled';

import * as S from 'routes/GlobalStyle.styled';

const tabActiveStyle = { borderBottom: '3px solid #FFCE01' };

const ScenarioDetail = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();
  const { pathname: path, state } = useLocation();
  const scenarioType = path.split('/')[3];
  const [selected, setSelected] = useState(scenarioType);

  if (scenarioType !== selected) setSelected(scenarioType);

  const data = state && state.data;
  const [scenario, setScenario] = useState(data || {});
  const [region, setRegion] = useState(null);
  const [regionLoading, setRegionLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const isMounted = useRef(false); // pattern to avoid memory leaks in the application

  useEffect(() => {
    if (scenario?.name) dispatch(setBreadcrumb(scenario?.name));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scenario]);

  useEffect(() => {
    isMounted.current = false;
    if (!Object.keys(scenario).length) {
      if (!isMounted.current) {
        setLoading(true);
        setRegionLoading(true);
      }
      getWasteScenarioById({ id }).then(wasteScenario => {
        countries.getWasteRegionById(wasteScenario?.wasteScenarioSet).then(value => {
          if (!isMounted.current) {
            setRegion(value);
            setScenario(wasteScenario);
          }
        }).finally(() => !isMounted.current && setRegionLoading(false));
      }).finally(() => !isMounted.current && setLoading(false));
    }
    return () => {
      isMounted.current = true;
    };
  }, [id, selected, scenario, data]);

  const InformationItem = ({ label, param, field }) => {
    let tempField = field;
    if (typeof tempField === 'boolean') tempField = tempField ? 'Yes' : 'No';
    else if ((tempField === null) || (tempField === undefined)) tempField = 'n.a.';
    return (
      <div style={{ marginBottom: '1.5rem' }}>
        <S.LabelText>{t(label, param)}</S.LabelText>
        <ContentInfoNoBorder content={tempField} />
      </div>
    );
  };

  const WasteScenarioList = () => (
    <S.Container>
      <div>
        <InformationItem field={region?.name} label="region" />
        <InformationItem field={scenario.toBeLeft} label="to be left %" />
        <InformationItem field={scenario.landfill} label="landfill %" />
        <InformationItem field={scenario.incineration} label="incineration %" />
        <InformationItem field={scenario.recycling} label="recycling %" />
        <InformationItem field={scenario.reuse} label="re-use %" />
      </div>
    </S.Container>
  );

  const TransportEndOfLifeList = () => (
    <S.Container>
      <div>
        <InformationItem field={scenario.transportProfile?.name} label="transport conveyance" />
        <InformationItem field={scenario.toBeLeftDistance} label="to be left km" />
        <InformationItem field={scenario.landfillDistance} label="landfill km" />
        <InformationItem field={scenario.incinerationDistance} label="incineration km" />
        <InformationItem field={scenario.recyclingDistance} label="recycling km" />
        <InformationItem field={scenario.reuseDistance} label="re-use km" />
      </div>
    </S.Container>
  );

  const UsedEnvProfileForLoadsList = () => (
    <S.Container>
      <div>
        <InformationItem field={scenario?.loadsToBeLeftProfile?.name} label="to be left" />
        <InformationItem field={scenario?.loadsLandfillProfile?.name} label="landfill" />
        <InformationItem field={scenario?.loadsIncinerationProfile?.name} label="incineration" />
        <InformationItem field={scenario?.loadsRecyclingProfile?.name} label="recycling" />
        <InformationItem
          field={scenario?.loadsRecyclingAfterEndOfWastePointProfile?.name}
          label="recycling after end-of-waste point"
        />
        <InformationItem field={scenario?.loadsReuseProfile?.name} label="re-use" />
        <InformationItem
          field={scenario?.loadsReuseAfterEndOfWastePointProfile?.name}
          label="re-use after end-of-waste point"
        />
      </div>
    </S.Container>
  );

  const UsedEnvProfileForBenefitsList = () => (
    <S.Container>
      <div>
        <InformationItem field={scenario?.benefitsIncinerationProfile?.name} label="incineration" />
        <InformationItem field={scenario?.benefitsRecyclingProfile?.name} label="recycling" />
        <InformationItem
          field={scenario?.benefitsReuseProfile?.id
            ? scenario.benefitsReuseProfile.name : 'n.a.'}
          label="re-use"
        />
      </div>
    </S.Container>
  );

  const SubstantationsList = () => (
    <S.Container>
      <div>
        <InformationItem
          field={scenario.substantiationEndOfWastePoint}
          label="end of waste point"
        />
        <InformationItem
          field={scenario.substantiationQualityFactorLostSecondaryMaterial}
          label="secondary material lost"
        />
        <InformationItem field={scenario.substantiationQualityFactorRecycling} label="recycling" />
        <InformationItem field={scenario.substantiationQualityFactorReuse} label="re-use" />
      </div>
    </S.Container>
  );

  const QualityfactorsList = () => (
    <S.Container>
      <div>
        <InformationItem
          field={scenario.qualityFactorLostSecondaryMaterial}
          label="secondary material lost"
        />
        <InformationItem field={scenario.qualityFactorRecycling} label="recycling" />
        <InformationItem field={scenario.qualityFactorReuse} label="re-use" />
      </div>
    </S.Container>
  );

  const InformationList = () => ({
    'waste-scenario': <WasteScenarioList />,
    'transport-end-of-life': <TransportEndOfLifeList />,
    'used-env-profile-for-loads': <UsedEnvProfileForLoadsList />,
    'used-env-profile-for-benefits': <UsedEnvProfileForBenefitsList />,
    substantations: <SubstantationsList />,
    qualifyfactors: <QualityfactorsList />,
  }[selected]);

  return (
    <AsideLayout
      LeftPane={(
        !loading && !regionLoading
          ? (
            <div>
              <S.LinksWrapper>
                <p style={{
                  fontFamily: theme.baseFont,
                  fontStyle: 'normal',
                  fontWeight: '500',
                  fontSize: '22px',
                  lineHeight: '28px',
                  color: '#202538',
                }}>
                  {scenario.name}
                </p>
                <p>{`ID: ${scenario?.code}`}</p>
              </S.LinksWrapper>
            </div>
          ) : Loading
      )}>
      <S.TitleContainer>
        <NavLink
          activeStyle={tabActiveStyle}
          to={`/waste-scenarios/${id}/waste-scenario`}>
          {t('waste-scenario')}
        </NavLink>
        <NavLink
          activeStyle={tabActiveStyle}
          to={`/waste-scenarios/${id}/transport-end-of-life`}>
          {t('transport-end-of-life')}
        </NavLink>
        <NavLink
          activeStyle={tabActiveStyle}
          to={`/waste-scenarios/${id}/used-env-profile-for-loads`}>
          {t('used-env-profile-for-loads')}
        </NavLink>
        <NavLink
          activeStyle={tabActiveStyle}
          to={`/waste-scenarios/${id}/used-env-profile-for-benefits`}>
          {t('used-env-profile-for-benefits')}
        </NavLink>
        <NavLink
          activeStyle={tabActiveStyle}
          to={`/waste-scenarios/${id}/substantations`}>
          {t('substantations')}
        </NavLink>
        <NavLink
          activeStyle={tabActiveStyle}
          to={`/waste-scenarios/${id}/qualifyfactors`}>
          {t('qualifyfactors')}
        </NavLink>
      </S.TitleContainer>
      {!loading && !regionLoading ? <InformationList /> : Loading}
    </AsideLayout>
  );
};

export default ScenarioDetail;
