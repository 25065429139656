import React from 'react';
import CheckIcon from 'assets/selected.svg';
import { CheckBoxContainer } from './CheckBox.styled';

const CheckBox = ({ onChange, checked }) => (
  <CheckBoxContainer onClick={(e) => e.stopPropagation()}>
    <input
      checked={checked}
      onChange={onChange}
      type="checkbox"
    />
    <span className="checkmark">
      <img alt="icon" src={CheckIcon} />
    </span>
  </CheckBoxContainer>
);

export default CheckBox;
