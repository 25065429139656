import React from 'react';
import { alertToast } from 'config/toast';

const ErrMsg = ({ payload: { message, code } }) => (
  <div>
    <b>{`Error: ${code}, ${message}`}</b>
  </div>
);

export default (error) => {
  // Error 😨
  alertToast(<ErrMsg payload={error.response.data} />, 'alert');
  return error.response.data;
};
