import styled from 'styled-components';
import { DARK_BLUE } from 'config/style';

export const Label = styled.p`
  margin-bottom: 9px;
  font-size: 14px;
  line-height: 21px;
  color: #202538;
`;

export const Value = styled.p`
  margin-bottom: 20px;
  font-size: 14px;
  line-height: 21px;
  color: rgba(32, 37, 56, 0.5);
`;

export const Date = styled.p`
  font-size: 12px;
  line-height: 18px;
`;

export const Author = styled.p`
  font-size: 12px;
  line-height: 21px;
  color: ${DARK_BLUE};
`;

export const LabelRegular = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #202538;
  margin-bottom: 6px;
`;

export const ValueRegular = styled.p`
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #000000;
  margin-bottom: 29px;
`;
