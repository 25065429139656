import React, { useEffect, useState, useContext } from 'react';
import {
  reduxForm,
  Form,
  formValues,
} from 'redux-form';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Redirect, useHistory, useParams } from 'react-router-dom';

import { calculations } from 'services';
import { useCanEdit, Calculation } from 'hooks/calculation.hooks';

import Button from 'components/Button/Button';

import * as S from 'routes/GlobalStyle.styled';
import { downloadFile } from 'utils';
import { confirmationModalSetup } from 'components/Modal/ModalContent/Delete';
import MainModal from 'components/Modal';

const General = ({
  data = {},
  handleSubmitValues,
  formField,
  getFieldValues,
  submitting,
  handleSubmit,
  productImage,
  teamMembers,
  dataCollectionYear,
  estimatesAndAssumptions,
  dataQuality,
  guarantiesOfOrigin,
  productFlowImageFile,
  description,
  productDescriptionImageFile,
  productDescriptionConfidential,
  productPerformanceFile,
  productionProcess,
  productionProcessImageFile,
  productionProcessConfidential,
  productionProcessFile,
  constructionProcess,
  constructionProcessImageFile,
  constructionProcessConfidential,
  constructionProcessFile,
  hasEditedForm,
  isTemplate,
  updateTemplate,
}) => {
  const { t } = useTranslation();
  const { execute, result } = useContext(Calculation);
  const { isHidden } = useSelector(state => state.sidebar);
  const canEdit = useCanEdit();
  const [confirmationModal, setConfirmationModal] = useState(null);
  const [hasSubmited, setHasSubmited] = useState(false);
  const isSemi = (result?.calculationType === 'semi_finished_product');

  useEffect(() => {
    getFieldValues({
      productImage,
      teamMembers,
      dataCollectionYear,
      estimatesAndAssumptions,
      dataQuality,
      guarantiesOfOrigin,
      productFlowImageFile,
      description,
      productDescriptionImageFile,
      productDescriptionConfidential,
      productPerformanceFile,
      productionProcess,
      productionProcessImageFile,
      productionProcessConfidential,
      productionProcessFile,
      constructionProcess,
      constructionProcessImageFile,
      constructionProcessConfidential,
      constructionProcessFile,
    });
  }, [
    getFieldValues,
    productImage,
    teamMembers,
    dataCollectionYear,
    estimatesAndAssumptions,
    dataQuality,
    guarantiesOfOrigin,
    productFlowImageFile,
    description,
    productDescriptionImageFile,
    productDescriptionConfidential,
    productPerformanceFile,
    productionProcess,
    productionProcessImageFile,
    productionProcessConfidential,
    productionProcessFile,
    constructionProcess,
    constructionProcessImageFile,
    constructionProcessConfidential,
    constructionProcessFile,
  ]);

  const history = useHistory();
  const { id: calcId } = useParams();

  if (!canEdit) return <Redirect to="view" />;

  const deleteFile = (param) => confirmationModalSetup(setConfirmationModal, (closeModal) => {
    if (param) {
      if (isTemplate) {
        calculations.saveTemplate(data.id, { [param]: null })
          .then(() => {
            updateTemplate();
            closeModal();
          });
      } else {
        calculations.save(data.id, { [param]: null })
          .then(() => {
            execute();
            closeModal();
          });
      }
    } else closeModal();
  });

  return (
    <S.Background isEditable>
      <S.HeaderTitle>{t('information-for-report')}</S.HeaderTitle>

      <S.FormContainer isHidden={isHidden} style={{ paddingBottom: '6rem', margin: '0' }}>
        <Form onSubmit={ev => {
          ev.preventDefault();

          handleSubmit(handleSubmitValues)().then(() => {
            setHasSubmited(true);
          });
        }}>
          <S.GroupFields>
            <S.GroupFieldsHeader>{t('general')}</S.GroupFieldsHeader>
            {formField({
              simpleEditor: !data?.template,
              label: 'teammembers',
              calculationContent: data.teamMembers,
              calculationKey: 'teamMembers',
              template: data?.template,
            })}
            {formField({
              simpleEditor: !data?.template,
              label: 'applicable time period collected data',
              calculationContent: data.dataCollectionYear,
              calculationKey: 'dataCollectionYear',
              template: data?.template,
            })}
            {formField({
              simpleEditor: !data?.template,
              label: 'estimations and assumptions',
              calculationContent: data.estimatesAndAssumptions,
              calculationKey: 'estimatesAndAssumptions',
              template: data?.template,
            })}
            {formField({
              simpleEditor: !data?.template,
              label: 'data quality',
              calculationContent: data.dataQuality,
              calculationKey: 'dataQuality',
              template: data?.template,
            })}
            {formField({
              simpleEditor: !data?.template,
              label: 'guaranties of origin',
              calculationContent: data.guarantiesOfOrigin,
              calculationKey: 'guarantiesOfOrigin',
              template: data?.template,
            })}
          </S.GroupFields>
          <S.GroupFieldsDivider />
          <S.GroupFields>
            <S.GroupFieldsHeader>{t('product details')}</S.GroupFieldsHeader>
            {formField({
              simpleEditor: true,
              label: 'picture of the product',
              calculationContent: {
                url: (data.productImage || data?.template?.productImage)
                  && `${process.env.REACT_APP_API_URL}${(data.productImage?.contentUrl || data?.template?.productImage?.contentUrl)}`,
                content: data.productImage || data?.template?.productImage,
              },
              calculationKey: 'productImage',
              fileUpload: {
                allowImages: true,
                disableDelete: !!data?.template?.productImage?.contentUrl,
              },
              deleteFile: () => deleteFile('productImage'),
              downloadFile,
            })}
            {formField({
              simpleEditor: true,
              label: 'custom product flow diagram',
              calculationContent: {
                url: (data.productFlowImageFile || data?.template?.productFlowImageFile)
                  && `${process.env.REACT_APP_API_URL}${(data.productFlowImageFile?.contentUrl || data?.template?.productFlowImageFile?.contentUrl)}`,
                content: data.productFlowImageFile || data?.template?.productFlowImageFile,
              },
              calculationKey: 'productFlowImageFile',
              fileUpload: {
                allowImages: true,
                disableDelete: !!data?.template?.productFlowImageFile?.contentUrl,
              },
              deleteFile: () => deleteFile('productFlowImageFile'),
              downloadFile,
            })}
            {formField({
              simpleEditor: !data?.template,
              label: 'product description for EPD (summary)',
              calculationContent: data.description,
              calculationKey: 'description',
              template: data?.template,
            })}
            {formField({
              simpleEditor: true,
              label: 'product description, image',
              calculationContent: {
                url: (data.productDescriptionImageFile || data?.template?.productDescriptionImageFile)
                && `${process.env.REACT_APP_API_URL}${(data.productDescriptionImageFile?.contentUrl || data?.template?.productDescriptionImageFile?.contentUrl)}`,
                content: data.productDescriptionImageFile || data?.template?.productDescriptionImageFile,
              },
              calculationKey: 'productDescriptionImageFile',
              fileUpload: {
                allowImages: true,
                disableDelete: !!data?.template?.productDescriptionImageFile?.contentUrl,
              },
              deleteFile: () => deleteFile('productDescriptionImageFile'),
              downloadFile,
            })}
            {formField({
              simpleEditor: !data?.template,
              label: 'product description (confidential)',
              calculationContent: data.productDescriptionConfidential,
              calculationKey: 'productDescriptionConfidential',
              template: data?.template,
            })}
            {formField({
              simpleEditor: true,
              label: 'attachments supporting the product description',
              calculationContent: {
                url: (data.productPerformanceFile || data?.template?.productPerformanceFile)
                  && `${process.env.REACT_APP_API_URL}${(data.productPerformanceFile?.contentUrl || data?.template?.productPerformanceFile?.contentUrl)}`,
                content: data.productPerformanceFile || data?.template?.productPerformanceFile,
              },
              calculationKey: 'productPerformanceFile',
              fileUpload: {
                allowPdf: true,
                disableDelete: !!data?.template?.productPerformanceFile?.contentUrl,
              },
              deleteFile: () => deleteFile('productPerformanceFile'),
              downloadFile,
            })}
            {formField({
              simpleEditor: !data?.template,
              label: 'application (intended use of product)',
              calculationContent: data.application,
              calculationKey: 'application',
              template: data?.template,
            })}
            {formField({
              simpleEditor: !data?.template,
              label: 'technical data',
              calculationContent: data.technicalData,
              calculationKey: 'technicalData',
              template: data?.template,
            })}
            {formField({
              simpleEditor: !data?.template,
              label: 'substances of very high concern (svhc)',
              calculationContent: data.substancesOfVeryHighConcern,
              calculationKey: 'substancesOfVeryHighConcern',
              template: data?.template,
            })}
          </S.GroupFields>
          <S.GroupFieldsDivider />
          <S.GroupFields>
            <S.GroupFieldsHeader>{t('production details')}</S.GroupFieldsHeader>
            {formField({
              simpleEditor: !data?.template,
              label: 'description production process for EPD (summary)',
              calculationContent: data.productionProcess,
              calculationKey: 'productionProcess',
              template: data?.template,
            })}
            {formField({
              simpleEditor: true,
              label: 'description production process, image',
              calculationContent: {
                url: (data.productionProcessImageFile || data?.template?.productionProcessImageFile)
                  && `${process.env.REACT_APP_API_URL}${(data.productionProcessImageFile?.contentUrl || data?.template?.productionProcessImageFile?.contentUrl)}`,
                content: data.productionProcessImageFile || data?.template?.productionProcessImageFile,
              },
              calculationKey: 'productionProcessImageFile',
              fileUpload: {
                allowImages: true,
                disableDelete: !!data?.template?.productionProcessImageFile?.contentUrl,
              },
              deleteFile: () => deleteFile('productionProcessImageFile'),
              downloadFile,
            })}
            {formField({
              simpleEditor: !data?.template,
              label: 'description production process (confidential)',
              calculationContent: data.productionProcessConfidential,
              calculationKey: 'productionProcessConfidential',
              template: data?.template,
            })}
            {formField({
              simpleEditor: true,
              label: 'attachments supporting the production process description',
              calculationContent: {
                url: (data.productionProcessFile || data?.template?.productionProcessFile)
                  && `${process.env.REACT_APP_API_URL}${(data.productionProcessFile?.contentUrl || data?.template?.productionProcessFile?.contentUrl)}`,
                content: data.productionProcessFile || data?.template?.productionProcessFile,
              },
              calculationKey: 'productionProcessFile',
              fileUpload: {
                allowPdf: true,
                disableDelete: !!data?.template?.productionProcessFile,
              },
              deleteFile: () => deleteFile('productionProcessFile'),
              downloadFile,
            })}
          </S.GroupFields>
          {!isSemi && (
            <>
              <S.GroupFieldsDivider />
              <S.GroupFields>
                <S.GroupFieldsHeader>{t('construction details')}</S.GroupFieldsHeader>
                {formField({
                  simpleEditor: !data?.template,
                  label: 'description construction process for EPD (summary)',
                  calculationContent: data.constructionProcess,
                  calculationKey: 'constructionProcess',
                  template: data?.template,
                })}
                {formField({
                  simpleEditor: true,
                  label: 'description construction process, image',
                  calculationContent: {
                    url: (data.constructionProcessImageFile || data?.template?.constructionProcessImageFile)
                    && `${process.env.REACT_APP_API_URL}${(data.constructionProcessImageFile?.contentUrl || data?.template?.constructionProcessImageFile?.contentUrl)}`,
                    content: data.constructionProcessImageFile || data?.template?.constructionProcessImageFile,
                  },
                  calculationKey: 'constructionProcessImageFile',
                  fileUpload: {
                    allowImages: true,
                    disableDelete: !!data?.template?.constructionProcessImageFile?.contentUrl,
                  },
                  deleteFile: () => deleteFile('constructionProcessImageFile'),
                  downloadFile,
                })}
                {formField({
                  simpleEditor: !data?.template,
                  label: 'description construction process (confidential)',
                  calculationContent: data.constructionProcessConfidential,
                  calculationKey: 'constructionProcessConfidential',
                  template: data?.template,
                })}
                {formField({
                  simpleEditor: true,
                  label: 'attachments supporting the construction process description',
                  calculationContent: {
                    url: (data.constructionProcessFile || data?.template?.constructionProcessFile)
                      && `${process.env.REACT_APP_API_URL}${(data.constructionProcessFile?.contentUrl || data?.template?.constructionProcessFile?.contentUrl)}`,
                    content: data.constructionProcessFile || data?.template?.constructionProcessFile,
                  },
                  calculationKey: 'constructionProcessFile',
                  fileUpload: {
                    allowPdf: true,
                    disableDelete: !!data?.template?.constructionProcessFile?.contentUrl,
                  },
                  deleteFile: () => deleteFile('constructionProcessFile'),
                  downloadFile,
                })}
              </S.GroupFields>
            </>
          )}
          <div style={{ display: 'flex', margin: '1rem 2.25rem 0' }}>
            <Button
              loading={submitting}
              style={{ marginRight: '2rem' }}
              styling="select"
              type="submit">
              {t('save')}
            </Button>
            <Button
              onClick={() => {
                if (isTemplate) {
                  history
                    .push(`/calculations/templates/${calcId}/information-for-report/general/view`);
                } else {
                  history
                    .push(`/calculation/${calcId}/information-for-report/general/view`);
                }
              }}
              styling="underline">
              {t('cancel')}
            </Button>
          </div>
        </Form>
      </S.FormContainer>
      <MainModal
        confirmationModalInfo={confirmationModal}
        content="delete"
        isModalOpen={confirmationModal}
        setIsModalOpen={setConfirmationModal}
      />
      <MainModal
        content="prompt"
        exitModalContent={{
          navigateSideEffects: () => null,
        }}
        prompt
        promptCondition={hasEditedForm && !hasSubmited}
      />
    </S.Background>
  );
};

export default reduxForm({
  form: 'general',
  enableReinitialize: true,
})(formValues({
  productImage: 'productImage',
  teamMembers: 'teamMembers',
  dataCollectionYear: 'dataCollectionYear',
  productFlowImageFile: 'productFlowImageFile',
  description: 'description',
  productDescriptionImageFile: 'productDescriptionImageFile',
  productDescriptionConfidential: 'productDescriptionConfidential',
  productPerformanceFile: 'productPerformanceFile',
  productionProcess: 'productionProcess',
  productionProcessImageFile: 'productionProcessImageFile',
  productionProcessConfidential: 'productionProcessConfidential',
  productionProcessFile: 'productionProcessFile',
  constructionProcess: 'constructionProcess',
  constructionProcessImageFile: 'constructionProcessImageFile',
  constructionProcessConfidential: 'constructionProcessConfidential',
  constructionProcessFile: 'constructionProcessFile',
})(General));
