import _ from 'lodash';
import React, {
  createContext, useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import { useAsync, useAsyncCallback } from 'react-async-hook';

import { calculations as calculationService, inputs as inputService } from 'services';
import { useStandardUnitForLCA, useStandardUnits } from 'hooks/functionalUnit.hooks';

// export const stages = [
//   [1, 'A1 - Raw Material'],
//   [2, 'A3 - External treatments'],
//   [3, 'A3 - Ancillary materials'],
//   [4, 'A3 - Packaging materials'],
//   [5, 'A3 - Energy'],
//   [6, 'A3 - Production emissions'],
//   [7, 'A5 - Construction'],
//   [8, 'B1 - Use stage'],
//   [9, 'B2 - Maintenance'],
//   [10, 'C1 - Demolition'],
//   [11, 'D - End of Life'],
// ];
const useStages = () => useAsync(inputService.stages, []);

export const useInputsByKey = () => {
  const inputs = useInputsContext();
  const pdisInputs = useMemo(() => inputs.pdisUsages?.map(
    pdis => pdis.inputs.map(i => ({ ...i, pdis })),
  ).flat(),
  [inputs.pdisUsages]);
  const sfpInputs = inputs.sfpUsages;

  const inputsByKey = useMemo(() => (inputs.inputs
    ? _.keyBy(inputs.inputs, 'id') : {}), [inputs.inputs]);

  const pdisInputsByKey = useMemo(() => (!pdisInputs ? {} : pdisInputs.reduce((acc, curr) => ({
    ...acc,
    [`${curr.id}:${curr.pdis.id}`]: curr,
  }), {})), [pdisInputs]);

  const sfpPdisByKey = useMemo(() => {
    const result = {};
    if (sfpInputs) {
      sfpInputs.forEach(sfp => sfp.predefinedInputSetUsages?.forEach(
        pdisUsage => pdisUsage.inputs.forEach(
          pdisInput => {
            result[`${pdisInput.id}:${pdisUsage.id}:${sfp.id}`] = pdisInput;
          },
        )));
    }

    return result;
  }, [sfpInputs]);

  const usageInputs = useMemo(() => ({
    ...(inputs.pdisUsages || []).reduce((a, c) => ({ ...a, [c.id]: c }), {}),
    ...(inputs.sfpUsages || []).reduce((a, c) => ({ ...a, [c.id]: c }), {}),
  }), [inputs.pdisUsages, inputs.sfpUsages]);

  return useMemo(() => ({
    ...inputsByKey,
    ...pdisInputsByKey,
    ...sfpPdisByKey,
    ...usageInputs,
  }), [inputsByKey, pdisInputsByKey, sfpPdisByKey, usageInputs]);
};

export const calculateWeight = (inputs) => {
  let totalWeight = 0;

  _.chain(inputs)
    .filter(({ inputStage }) => inputStage?.id === 1 || inputStage?.endsWith?.('/1'))
    .forEach(({ netAmount, environmentalProfile }) => {
      if (environmentalProfile && environmentalProfile?.['@type'] === 'MaterialProfile') {
        totalWeight += netAmount;
      }
    })
    .value();

  return totalWeight;
};

const usePdisUsages = (calculationId, isPdis) => {
  const getUsages = useAsyncCallback(() => inputService.getPdisUsages(calculationId)
    .then(res => ({
      usages: res.data,
      pdisInputs: res.inputs,
    })));

  useEffect(() => {
    if (calculationId && !isPdis) getUsages.execute();
    else getUsages.reset();
  }, [calculationId, isPdis]); // eslint-disable-line react-hooks/exhaustive-deps

  return getUsages;
};

const useSfpUsages = (calculationId, isPdis) => {
  const getUsages = useAsyncCallback(() => calculationService.usages(calculationId)
    .then(usages => usages?.map(sfpUsage => ({
      ...sfpUsage,
      predefinedInputSetUsages: sfpUsage.predefinedInputSetUsages?.map(pdisUsage => ({
        ...pdisUsage,
        amount: pdisUsage.amount * sfpUsage.amount,
        inputs: pdisUsage.inputs?.map(input => ({
          ...input,
          netAmount: input.netAmount * sfpUsage.amount,
        })),
      })),
    }))));

  useEffect(() => {
    if (calculationId && !isPdis) getUsages.execute();
    else getUsages.reset();
  }, [calculationId, isPdis]); // eslint-disable-line react-hooks/exhaustive-deps

  return getUsages;
};

const useInputs = (calculationId, isPdis) => {
  const fetchInputsForCalculation = useAsyncCallback(() => calculationService
    .inputs(calculationId, { itemsPerPage: 999999 }), {
    setLoading: state => ({
      ...state,
      loading: true,
    }),
  });

  const fetchInputsForPdis = useAsyncCallback(() => inputService.getPdisInputs(calculationId), {
    setLoading: state => ({
      ...state,
      loading: true,
    }),
  });

  useEffect(() => {
    if (!calculationId) return;
    (isPdis ? fetchInputsForPdis : fetchInputsForCalculation).execute();
  }, [calculationId]); // eslint-disable-line react-hooks/exhaustive-deps

  const inputsData = isPdis ? fetchInputsForPdis : fetchInputsForCalculation;

  return {
    ...inputsData,
    ...inputsData?.result,
    result: inputsData?.result?.data,
  };
};

export const useInput = (calculationId, inputId, isPdis) => {
  const input = useAsync(
    () => {
      if (isPdis && inputId) return inputService.getPdisUsage(inputId);
      if (!inputId) return null;
      return calculationService.input(inputId);
    },
    [calculationId, inputId],
  );

  return input;
};

export const useUnit = (calculation) => {
  const functionalUnits = useStandardUnitForLCA(calculation?.method);
  const standardUnitsList = useStandardUnits();
  const customUnits = _.keyBy(functionalUnits.list, '@id');
  const standardUnits = _.keyBy(standardUnitsList.units, '@id');
  const [unit, setUnit] = useState();

  // Unit
  useEffect(() => {
    if (calculation.functionalUnit) setUnit(customUnits[calculation.functionalUnit]?.unit);
    else setUnit(standardUnits[calculation.standardUnit]?.abbreviation);
  }, [calculation.functionalUnit, calculation.standardUnit, customUnits, standardUnits]);

  return unit;
};

export const useWeight = () => {
  const {
    sfpUsages, pdisUsages, inputs, loading,
  } = useInputsContext();
  return useMemo(() => ({
    loading,
    weight: calculateWeight([
      // Inputs from pdisUsages
      ...(pdisUsages?.map(pdis => pdis.inputs.map(i => ({
        ...i,
        pdis,
      }))).flat() || []),
      // Inputs from sfpUsages' pdisUsages
      ...(sfpUsages?.map(
        sfp => sfp.predefinedInputSetUsages?.map(
          pdis => pdis.inputs || []) || [])
        .flat(2) || []),
      // Rest
      ...(inputs || []),
    ]),
  }), [pdisUsages, sfpUsages, inputs, loading]);
};

const InputsContext = createContext({
  calculationId: null,
  calculationPath: null,
  isPdis: false,
  loading: true,
  inputs: [],
  inputsNextPage: undefined,
  sfpUsages: [],
  pdisUsages: [],
  stages: [],
  refresh: () => null,
});

export const useInputsContext = () => useContext(InputsContext);

const InputsContextCache = ({ children }) => {
  const parent = useInputsContext();

  const [inputs, setInputs] = useState(parent.inputs);
  const [inputsNextPage, setInputsNextPage] = useState(parent.inputsNextPage);
  const [sfpUsages, setSfpUsages] = useState(parent.sfpUsages);
  const [pdisUsages, setPdisUsages] = useState(parent.pdisUsages);
  const [stages, setStages] = useState(parent.stages);

  useEffect(() => {
    // When any of these change, clear cached state
    setInputs([]);
    setInputsNextPage(undefined);
    setSfpUsages([]);
    setPdisUsages([]);
  }, [parent.calculationPath, parent.calculationId, parent.isPdis]);

  // Then, when any of these change to a non null value, set the actual value
  // useEffect(() => parent.inputs && setInputs(parent.inputs), [parent.inputs]);
  // useEffect(() => parent.inputsNextPage && setInputsNextPage(parent.inputsNextPage), [parent.inputsNextPage]);
  // useEffect(() => parent.sfpUsages && setSfpUsages(parent.sfpUsages), [parent.sfpUsages]);
  // useEffect(() => parent.pdisUsages && setPdisUsages(parent.pdisUsages), [parent.pdisUsages]);
  // useEffect(() => parent.stages && setStages(parent.stages), [parent.stages]);

  useEffect(() => {
    if (parent.loading) {
      return;
    }
    setInputs(parent.inputs);
    setInputsNextPage(parent.inputsNextPage);
    setSfpUsages(parent.sfpUsages);
    setPdisUsages(parent.pdisUsages);
    setStages(parent.stages);
    // eslint-disable-next-line
  }, [parent.loading])

  return (
    <InputsContext.Provider value={{
      ...parent,
      inputs,
      inputsNextPage,
      sfpUsages,
      pdisUsages,
      stages,
      loading: parent.loading && inputs?.length === 0
        && sfpUsages.length === 0 && pdisUsages.length === 0,
      actuallyLoading: parent.loading,
    }}>
      {children}
    </InputsContext.Provider>
  );
};

export const WithInputsContext2 = ({
  calculationId, calculationPath, isPdis, children,
}) => {
  const inputs = useInputs(calculationId, isPdis);
  const stages = useStages();
  const pdis = usePdisUsages(calculationId, isPdis);
  const sfp = useSfpUsages(calculationId, isPdis);

  const refresh = useCallback(() => {
    inputs.execute();
    if (!isPdis) {
      pdis.execute();
      sfp.execute();
    }
  }, [inputs, isPdis, pdis, sfp]);

  const value = useMemo(() => ({
    calculationId,
    calculationPath,
    isPdis,
    inputs: inputs.result
        // RBM-135 - Calculation is locked, filter out inputs with copiedFromPDIS attribute set
        ?.filter(i => !i.copiedFromPredefinedInputSet),
    inputsNextPage: inputs.nextPage,
    sfpUsages: sfp.result,
    // RBM-135 - Calculation is locked, replace with locked copy of inputs
    pdisUsages: pdis.result?.usages?.map(usage => {
      const copiedInputs = inputs?.result
          ?.filter(i => i.copiedFromPredefinedInputSet === usage.predefinedInputSet['@id']) || [];

      if (!copiedInputs.length) {
        return usage;
      }

      return {
        ...usage,
        inputs: copiedInputs,
      };
    }),
    stages: stages.result,
    refresh,
    loading: inputs.loading || sfp.loading || pdis.loading || stages.loading,

  }), [
    calculationId,
    calculationPath,
    inputs,
    isPdis,
    pdis.loading,
    pdis.result,
    refresh,
    sfp.loading,
    sfp.result,
    stages.loading,
    stages.result,
  ]);

  return (
    <InputsContext.Provider value={value}>
      <InputsContextCache>
        {children}
      </InputsContextCache>
    </InputsContext.Provider>
  );
};
