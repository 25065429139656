import network from 'services';
import moment from 'moment';
import _ from 'lodash';

const interpretStatus = (status, data) => {
  switch (status) {
    case 'purchased': return 'Calculation purchased';
    case 'submitted': return `Calculation submitted for review with ${data.companyName}`;
    case 'resubmitted': return 'Calculation resubmitted for review';
    case 'accepted': return `${data.name} accepted the review request`;
    case 'denied': return `${data.name} denied the review request`;
    case 'rejected': return `${data.name} rejected the calculation`;
    case 'declined': return `${data.name} permanently rejected the calculation`;
    case 'approved': return `
    ${data.name} approved the calculation
    ${data.reviewApprovedDate && `at ${moment(data.reviewApprovedDate).format('llll')}`}
    `;
    case 'verified': return 'Calculation has been verified';
    default: return 'Unhandled review status';
  }
};

const interpretStatusReviewer = (status, data) => {
  switch (status) {
    case 'purchased': return 'Calculation purchased';
    case 'submitted': return `Calculation submitted for review by ${data.name}`;
    case 'resubmitted': return `Calculation resubmitted for review by ${data.name}`;
    case 'accepted': return `Review request accepted by ${data.name}`;
    case 'denied': return `Review request denied by ${data.name}`;
    case 'rejected': return `Calculation rejected by ${data.name}`;
    case 'declined': return `Calculation permanently rejected by ${data.name}`;
    case 'approved': return `
    Calculation approved by ${data.name}
    ${data.reviewApprovedDate && `at ${moment(data.reviewApprovedDate).format('llll')}`}
    `;
    case 'verified': return 'Calculation has been verified';
    default: return 'Unhandled review status';
  }
};

const paginatedMember = (user, {
  'hydra:member': data,
  'hydra:view': view,
  'hydra:totalItems': totalItems,
}) => ({
  data: _.map(data, log => ({
    ...log,
    message: user?.reviewer
      ? interpretStatusReviewer(log.reviewState, log)
      : interpretStatus(log.reviewState, log),
  })),
  nextPage: view?.['hydra:next'],
  totalItems,
});

export const journals = (user, id, config) => network
  .get(`/calculations/${id}/journals`, { cache: true, ...config })
  .then(values => {
    const tempValues = paginatedMember(user, values);
    return { data: tempValues.data };
  });
