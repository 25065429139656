import _ from 'lodash';
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { setBreadcrumb } from 'state/sidebar/sidebar.actions';
import { useParams, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getProfileById } from 'services/profiles';
import { Loading } from 'components/Splash/Loading';
import AsideLayout from 'components/AsideLayout/AsideLayout';
import { ContentInfoNoBorder } from 'components/ContentInfo/ContentInfo';
import { Tabs, Tab } from 'components/Tabs/Tabs';
import * as Styled from 'routes/Calculations/Detail/Detail.styled';
import * as S from 'routes/GlobalStyle.styled';

const ProfileDetail = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();
  const { pathname: path, state } = useLocation();
  const profileType = path.split('/')[3];
  const [selected, setSelected] = useState(profileType);

  if (profileType !== selected) setSelected(profileType);

  const data = state && state.data;
  const [profile, setProfile] = useState(data || {});
  /* const [owner, setOwner] = useState(null);
  const [sections, setSections] = useState(null); */
  const [loading, setLoading] = useState(false);
  /* const [loadingEnvironmentalProfileEffects,
    setLoadingEnvironmentalProfileEffects] = useState(false);
  const [profileEffects, setProfileEffects] = useState(null); */
  const isMounted = useRef(false); // pattern to avoid memory leaks in the application

  useEffect(() => {
    if (profile?.name) dispatch(setBreadcrumb(profile?.name));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile]);

  useEffect(() => {
    isMounted.current = false;
    if (_.isEmpty(profile)) {
      if (!isMounted.current) setLoading(true);
      getProfileById({ id })
        .then(profileValue => {
          if (!isMounted.current) setProfile(profileValue);
        })
        .finally(() => !isMounted.current && setLoading(false))
        .catch(_.noop);
    }
    return () => {
      isMounted.current = true;
    };
  }, [id, selected, profile]);

  const InformationItem = ({ label, param, field }) => {
    let tempField = field;
    if (typeof tempField === 'boolean') tempField = tempField ? 'Yes' : 'No';
    return (
      <S.ItemWrapper>
        <S.LabelText>{t(label, param)}</S.LabelText>
        <ContentInfoNoBorder
          content={((tempField === null) || (tempField === undefined)) ? 'n.a.' : tempField}
        />
      </S.ItemWrapper>
    );
  };

  const BasicInformationList = () => {
    const transportNeeded = profile.transportNeeded ? 'Yes' : 'No';
    const wasteScenarios = profile?.wasteScenarioNeeded ? 'Yes' : 'No';

    return (
      <S.Container>
        <S.ItemsContainer>
          <div style={{ marginRight: '6.87rem' }}>
            <InformationItem field={profile?.code} label="id" />
            <InformationItem field={profile.processUsed} label="process used" />
            <InformationItem field={profile.thirdPartyVerified} label="third party verified" />
            <InformationItem field={transportNeeded} label="transport applicable" />
            <InformationItem field={profile?.shadowPrice} label="shadowPrice" />
            <InformationItem field={profile?.secondaryContent} label="secondary content" />
            <InformationItem field={profile?.lowHeatValue} label="lower heat value" />
          </div>
          <div>
            <InformationItem field={profile.unit} label="unit" />
            <InformationItem field={profile.source} label="source" />
            <InformationItem field={profile.expireDate} label="valid until" />
            <InformationItem field={wasteScenarios} label="waste scenario applicable" />
            <InformationItem field={profile?.lastModifiedAt} label="last change date" />
            <InformationItem
              field={profile?.primaryEquivalentProfileCode}
              label="primary equivalent"
            />
            <InformationItem field={profile.comments} label="Comments" />
          </div>
        </S.ItemsContainer>
      </S.Container>
    );
  };

  const ExtraInformationList = () => (
    <S.Container>
      <S.ItemsContainer>
        <div style={{ marginRight: '6.87rem' }}>
          <InformationItem field={profile?.dataOwner?.name} label="data owner" />
          <InformationItem field={profile.geographicCoverage} label="geographic coverage" />
          <InformationItem field={profile.allocation} label="Allocation" />
          {_.map(profile?.sections, (s, index) => (
            <InformationItem
              key={s.name}
              field={s?.name}
              label="section"
              param={{ count: index + 1 }}
            />
          ))}
          <InformationItem
            field={profile?.wasteScenarios?.[0]
              ? `[${profile.wasteScenarios[0].code}] ${profile.wasteScenarios[0].name}` : 'n.a'}
            label="standard waste scenario"
          />
        </div>
        <div>
          <InformationItem field={profile.geographicAccuracy} label="geographic accuracy" />
          <InformationItem field={profile.allocationExplanation} label="explanation allocation" />
        </div>
      </S.ItemsContainer>
    </S.Container>
  );

  const EnvironmentalEffectsList = () => (
    <S.Container style={{ padding: 0 }}>
      <S.GridWrapper style={{ padding: '1.25rem 2.5rem .63rem' }}>
        <S.Header>{t('order')}</S.Header>
        <S.Header>{t('effect')}</S.Header>
        <S.Header>{t('unit')}</S.Header>
        <S.Header>{t('value')}</S.Header>
      </S.GridWrapper>
      {
        profile?.effects && profile?.effects.map(({ effect, amount }, i) => (
          <S.GridWrapper key={effect?.id + i}>
            <S.Data size=".75rem">{effect?.code}</S.Data>
            <S.Data size="1rem">{effect?.name}</S.Data>
            <S.Data isGrey>{effect?.unit}</S.Data>
            <S.Data isGrey>{amount}</S.Data>
          </S.GridWrapper>
        ))
      }
    </S.Container>
  );

  const InformationList = () => ({
    'basic-information': <BasicInformationList />,
    'extra-information': <ExtraInformationList />,
    'environmental-effects': <EnvironmentalEffectsList />,
  }[selected]);

  return (
    <AsideLayout
      LeftPane={(
        !loading
          ? (
            <div>
              <Styled.LinksWrapper>
                <S.ProfileName>
                  {profile.externalName}
                </S.ProfileName>
                <p>{`ID: ${profile?.code}`}</p>
              </Styled.LinksWrapper>
            </div>
          ) : Loading
      )}>
      <Tabs>
        <Tab to={`/environmental-profiles/${id}/basic-information`}>{t('basic-information')}</Tab>
        <Tab to={`/environmental-profiles/${id}/extra-information`}>{t('extra-information')}</Tab>
        <Tab to={`/environmental-profiles/${id}/environmental-effects`}>
          {t('environmental-effects')}
        </Tab>
      </Tabs>

      {loading ? Loading : <InformationList />}
    </AsideLayout>
  );
};

export default ProfileDetail;
