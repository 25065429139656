export const LOADING = 'CALC_LIST LOADING';
export const RELEASE_MEMORY = 'CALC_LIST RELEASE_MEMORY';

export const LIST = 'CALC_LIST GET';
export const PRUNE_LIST = 'CALC_LIST PRUNE';
export const NEXT_PAGE_LIST = 'CALC_LIST NEXT_PAGE_LIST';
export const GET_DETAIL_DATA = 'GET_DETAIL_DATA';
export const UPDATE_LIST = 'CALC_LIST UPDATE';
export const DELETE_ITEM = 'CALC_LIST DELETE_ITEM';

export const ORDER = 'CALC_LIST SORT/ORDER';
export const ORDER_BY_NAME = 'CALC_LIST orderByName';
export const ORDER_BY_CODE = 'CALC_LIST orderByCode';
export const SORT_LAST_MODIFIED = 'CALC_LIST sortLastModified';
export const SORT_STATUS = 'CALC_LIST sortStatus';

export const SELECTION = 'CALC_LIST SELECTION';
export const CLEAR_SELECTION = 'CALC_LIST CLEAR_SELECTION';
export const FULL_SELECTION = 'FULL_SELECTION';
export const SELECT_ALL_CALCULATIONS = 'CALC_LIST SELECT_ALL';

export const FOLDERS = 'FOLDERS GET';
export const UPDATE_FOLDER = 'UPDATE_FOLDER';
export const CREATE_FOLDER = 'CREATE_FOLDER';
export const DELETE_FOLDER = 'DELETE_FOLDER';

export const UPDATE_FILTER = 'CALC_LIST UPDATE_FILTER';
export const CLEAR_FILTERS = 'CALC_LIST CLEAR_FILTERS';
export const FIND_NAME = 'CALC_LIST FIND_NAME';
