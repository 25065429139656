import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, Link } from 'react-router-dom';
import Button from 'components/Button/Button';
import ID from 'components/TopBar/ID';
import { locations as locationsService } from 'services';
import edit from 'assets/edit.svg';
import deleteIcon from 'assets/delete.svg';
import ListItem from 'components/ListItem/ListItem';
import { confirmationModalSetup } from 'components/Modal/ModalContent/Delete';
import MainModal from 'components/Modal';
import TopBar from 'components/TopBar/TopBar';
import Name from 'components/TopBar/Name';
import { Loading } from 'components/Splash/Loading';
import * as S from 'routes/Calculations/Overview.styled';
import { useSelector } from 'react-redux';
import TopbarActions from 'components/TopbarActions/TopbarActions';
import InfiniteScroll from 'react-infinite-scroll-component';

const Suppliers = ({
  input: inputValue, city: cityValue,
  country: countryValue, company: companyValue, setTotalNumberOfRecords,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { selectedCompany, id: companyId } = useSelector(({ company }) => company || {});
  const user = useSelector(state => state.user);
  const [suppliers, setSuppliers] = useState(null);
  const [confirmationModal, setConfirmationModal] = useState(null);
  const [loading, setLoading] = useState(false);
  const [sorting, setSorting] = useState('asc');
  const [sortingCode, setSortingCode] = useState(null);
  const [page, setPage] = useState(null);

  useEffect(() => {
    const inputTemp = inputValue ? { name: inputValue } : null;
    const cityTemp = cityValue ? { city: cityValue } : null;
    const countryTemp = countryValue ? { country: countryValue.value } : null;
    const companyTemp = companyValue ? { owner: companyValue.id } : null;
    const order = {};
    if (sortingCode) order['order[code]'] = sortingCode;
    if (sorting) order['order[name]'] = sorting;
    setTotalNumberOfRecords(null);
    setLoading(true);
    locationsService.getSuppliers({
      ...inputTemp, ...cityTemp, ...countryTemp, ...companyTemp, ...order,
    }).then((values) => {
      setSuppliers(values.suppliers);
      setPage(values.nextPage);
      setTotalNumberOfRecords(values.totalItems);
    }).finally(() => setLoading(false));
  }, [inputValue, cityValue, countryValue,
    companyValue, sorting, setTotalNumberOfRecords, sortingCode]);

  const onDeleteItem = (id) => {
    confirmationModalSetup(setConfirmationModal, (closeModal) => {
      locationsService.deleteSupplier(id).then(() => {
        const tempSuppliers = suppliers.filter(supplier => supplier.id !== id);
        setSuppliers(tempSuppliers);
        closeModal();
      });
    });
  };

  const onEditItem = (data) => {
    history.push(`/locations-&-suppliers/suppliers/${data.id}/edit`, { routeName: data.name });
  };

  const isEditable = companyId === selectedCompany?.id;

  const ListItemContent = ({ data }) => (
    <>
      <S.Option
        key="edit"
        isClickable={isEditable && user?.selectedMembership?.view !== 'Viewer'}
        onClick={() => isEditable && user?.selectedMembership?.view !== 'Viewer'
          && onEditItem(data)}>
        <div>
          <img alt="icon" src={edit} />
        </div>
        <S.StyledLabel isRegular>
          {t('edit')}
        </S.StyledLabel>
      </S.Option>
      <S.Option
        key="delete"
        isClickable={user?.selectedMembership?.view !== 'Viewer'}
        onClick={() => user?.selectedMembership?.view !== 'Viewer'
          && onDeleteItem(data.id)}>
        <div>
          <img alt="icon" src={deleteIcon} />
        </div>
        <S.StyledLabel color="#BF2E00" isRegular>
          {t('delete')}
        </S.StyledLabel>
      </S.Option>
    </>
  );

  const fetchData = () => {
    if (page) {
      locationsService
        .getNextPageSuppliers(page)
        .then((values) => {
          setPage(values.nextPage);
          setSuppliers(suppliers.concat(values.suppliers));
        });
    }
  };

  const suppliersList = () => (
    <S.OverviewRows id="overviewRowsId">
      <InfiniteScroll
        dataLength={suppliers?.length}
        hasMore={page}
        loader={Loading}
        next={fetchData}
        scrollableTarget="overviewRowsId"
        scrollThreshold={0.4}
        style={{ minHeight: '50vh' }}>
        {suppliers?.map((data) => (
          <ListItem
            key={data?.id}
            code={data?.code}
            description={[data?.address, data?.postalCode, data?.city, data?.country].join(', ')}
            dropdownContent={(<ListItemContent data={data} />)}
            hideDropdown={user?.selectedMembership?.view === 'Viewer'}
            name={data?.name}
            to={{
              pathname: `/locations-&-suppliers/suppliers/${data?.id}`,
              state: {
                routeName: data?.name,
              },
            }}
          />
        ))}
      </InfiniteScroll>
    </S.OverviewRows>
  );

  return (
    <>
      {user?.selectedMembership?.view !== 'Viewer'
        && (
          <TopbarActions>
            <Button as={Link} styling="select" to="/locations-&-suppliers/suppliers/new">
              {t('add new supplier')}
            </Button>
          </TopbarActions>
        )}
      <TopBar style={{ paddingRight: '11.5rem' }}>
        <Name
          onOrderBy={order => {
            setSorting(order);
          }}
          order={sorting}
        />
        <ID
          inverted
          onOrderBy={() => {
            if (!sortingCode) setSortingCode('asc');
            else if (sortingCode === 'asc') setSortingCode('desc');
            else setSortingCode(null);
          }}
          order={sortingCode}
        />
      </TopBar>
      {!suppliers || loading ? Loading : suppliersList()}
      <MainModal
        confirmationModalInfo={confirmationModal}
        content="delete"
        isModalOpen={confirmationModal}
        setIsModalOpen={setConfirmationModal}
      />
    </>
  );
};

export default Suppliers;
