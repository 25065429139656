import _ from 'lodash';
import Joi from '@hapi/joi';
import i18next from 'i18next';

export const required = (value, allValues, props, name) => {
  const hasValue = _.isNumber(value) ? false : !value;
  return (hasValue && i18next.t('required', { fieldName: i18next.t(`${name}.label`) }))
  || undefined;
};

export const scalingType = val => Joi.object({
  value: Joi.string().required(),
  label: Joi.string(),
}).validate(val)?.error?.message;

export const requiredNumber = val => Joi
  .number()
  .required()
  .validate(val)?.error?.message;

export const checkPositive = val => Joi
  .number()
  .positive()
  .required()
  .validate(val)?.error?.message;

export const minValue = val => Joi
  .number()
  .min(0)
  .required()
  .validate(val)?.error?.message;

// const submitSchema = Joi.object({
//   productName: Joi.string().required().min(6),
// }).unknown();

// export const validate = values => {
//   const { error = {} } = submitSchema.validate(values);

//   return error;
// };
