/* eslint-disable eqeqeq */
import _ from 'lodash';
import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { setBreadcrumb } from 'state/sidebar/sidebar.actions';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Calculation, useCanEdit } from 'hooks/calculation.hooks';
import { useMethods } from 'hooks/methods.hooks';
import { useWasteRegion } from 'hooks/wasteRegion.hooks';
import { useStandardUnits } from 'hooks/functionalUnit.hooks';

import Button from 'components/Button/Button';
import { ReadOnlyField } from 'components/Field';
import { Placeholder } from 'components/Splash/Loading';

import {
  ViewModeContainer, TitleBar, GroupFields, GroupFieldsDivider, GroupFieldsHeader,
} from 'routes/GlobalStyle.styled';
import {
  predefinedConstructionWasteOpt,
  wasteMethodOptLabels, wasteScenarioInputMethodsOpt,
} from './Editor.helpers';

const ViewAdvanced = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { result: calculation } = useContext(Calculation);
  const methods = useMethods(calculation);
  const wasteRegions = useWasteRegion(calculation);
  const standardUnits = useStandardUnits();
  // const customUnitForLCA = useCustomUnitForLCA(methods?.selected?.id, calculation);
  const canEdit = useCanEdit();
  const isSemifinished = calculation.calculationType === 'semi_finished_product';
  const loaderList = [methods, wasteRegions, standardUnits];

  React.useEffect(() => {
    if (calculation?.productName) dispatch(setBreadcrumb(calculation?.productName));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calculation]);

  /* const unitName = () => {
    const findType = calculation.functionalUnit
      ? standardUnits.selected : customUnitForLCA.selected;
    const { quantityName, unit, abbreviation } = findType || {};
    return `${quantityName} ${unit}(${abbreviation})`;
  }; */

  const renderScalingDimensions = () => _.times(calculation.amountScalingDimensions, i => (
    <>
      <ReadOnlyField name="descriptionDimension" translationParams={{ count: i + 1 }}>
        {calculation[`descriptionDimension${i + 1}`]}
      </ReadOnlyField>
      <ReadOnlyField name="unitDimension" translationParams={{ count: i + 1 }}>
        {calculation[`unitDimension${i + 1}`]}
      </ReadOnlyField>
      <ReadOnlyField name="dimension" translationParams={{ count: i + 1 }}>
        {calculation[`dimension${i + 1}`]}
      </ReadOnlyField>
      <ReadOnlyField name="scalableDimension" translationParams={{ count: i + 1 }}>
        {calculation[`scalableDimension${i + 1}`]}
      </ReadOnlyField>
      <br />
    </>
  ));

  const checkProductionWastePercentageForm = _.find(
    wasteScenarioInputMethodsOpt,
    ({ value }) => value === calculation.productionWasteInputMethod,
  );

  return (
    <>
      <TitleBar>
        {t('Advanced settings')}

        {canEdit && (
          <Button as={Link} styling="select" to="edit">
            {`${t('edit')}`}
          </Button>
        )}
      </TitleBar>

      <Placeholder loaders={loaderList}>
        {() => (
          <ViewModeContainer style={{ padding: '1.87rem 0', display: 'unset' }}>
            {/* <ReadOnlyField name="scalingType">
              {_.find(scalingOptions, ({ value }) => calculation.scalingType === value).label}
            </ReadOnlyField>

            <ReadOnlyField name="productType">
              {methods.productType?.name}
            </ReadOnlyField> */}

            <GroupFields>
              <GroupFieldsHeader>{t('production waste and end of life')}</GroupFieldsHeader>
              {/* {!isSemifinished && (
                methods.productType?.id === 3 && (
                <>
                  <ReadOnlyField name="transportProfileToConstructionSite">
                    {_.find(
                      methods.profiles,
                      ({ '@id': id }) => id === calculation.transportProfileToConstructionSite,
                    )?.name}
                  </ReadOnlyField>

                  <ReadOnlyField name="distanceToConstructionSite">
                    {calculation.distanceToConstructionSite}
                  </ReadOnlyField>
                </>
                )
              )} */}
              <ReadOnlyField name="wasteScenarioInputMethod">
                {calculation.wasteScenarioInputMethod == 1
                  ? t('wasteScenarioInputMethod.byRawMaterial')
                  : t('wasteScenarioInputMethod.byProduct')}
              </ReadOnlyField>

              {calculation.wasteScenarioInputMethod == 2 && (
              <>
                <ReadOnlyField name="wasteScenarioProductionWasteForm">
                  {_.find(
                    wasteRegions.scenarios,
                    (val) => val['@id'] == calculation.wasteScenarioProductionWaste,
                  )?.name}
                </ReadOnlyField>

                <ReadOnlyField name="wasteScenarioForm">
                  {_.find(wasteRegions.scenarios, (val) => val['@id']
                    === calculation.wasteScenario)?.name}
                </ReadOnlyField>
              </>
              )}
              <ReadOnlyField name="productionWasteInputMethod">
                {
                calculation.productionWasteInputMethod === 4
                  ? `${calculation.productionWastePercentage}% of the overall product`
                  : wasteMethodOptLabels[calculation.productionWasteInputMethod]
                }
              </ReadOnlyField>

              {checkProductionWastePercentageForm?.value === 'overall_percentage' && (
                <ReadOnlyField name="productionWastePercentageForm">
                  {calculation.productionWastePercentage}
                </ReadOnlyField>
              )}
            </GroupFields>

            {!isSemifinished && (
              <>
                <GroupFieldsDivider />
                <GroupFields>
                  <GroupFieldsHeader>{t('construction waste')}</GroupFieldsHeader>
                  <ReadOnlyField name="predefinedConstructionWaste">
                    {_.find(
                      predefinedConstructionWasteOpt,
                      ({ value }) => value === ((calculation.template || calculation)
                        .predefinedConstructionWaste || 0),
                    )?.label}
                  </ReadOnlyField>

                  {!(calculation.template || calculation).predefinedConstructionWaste && (
                  <ReadOnlyField name="constructionWaste">
                    <pre>{(calculation.template || calculation).constructionWaste}</pre>
                  </ReadOnlyField>
                  )}
                </GroupFields>
                <GroupFieldsDivider />
                <GroupFields>
                  <GroupFieldsHeader>{t('scaling')}</GroupFieldsHeader>
                  <ReadOnlyField name="scalingType">
                    {t(calculation.scalingType)}
                  </ReadOnlyField>
                  {calculation.scalingType === 'linear' ? (
                    <>
                      <ReadOnlyField name="scalingDimensions">
                        {calculation.amountScalingDimensions}
                      </ReadOnlyField>
                      {renderScalingDimensions()}
                    </>
                  ) : null}
                </GroupFields>
              </>
            )}
          </ViewModeContainer>
        )}
      </Placeholder>
    </>
  );
};

export default ViewAdvanced;
