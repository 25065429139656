import * as types from './mercure.types';

const initialState = {
  importTopic: null,
  updateMercure: null,
  importEnvTopic: null,
  updateEnvMercure: null,
  importCalculationTopic: null,
  updateCalculationMercure: null,
  importPdisTopic: null,
  updatePdisMercure: null,
  calculationRealTimeResultsTopic: null,
  updateCalculationRealTimeResults: null,
};

const mercure = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_TOPIC: {
      return {
        ...state,
        importTopic: action.payload,
        importEnvTopic: null,
        importCalculationTopic: null,
        calculationRealTimeResultsTopic: null,
      };
    }
    case types.SET_UPDATE_MERCURE: {
      return { ...state, updateMercure: action.payload };
    }
    case types.SET_ENV_TOPIC: {
      return {
        ...state,
        importEnvTopic: action.payload,
        importTopic: null,
        importCalculationTopic: null,
        calculationRealTimeResultsTopic: null,
      };
    }
    case types.SET_UPDATE_ENV_MERCURE: {
      return { ...state, updateEnvMercure: action.payload };
    }
    case types.SET_CALCULATION_TOPIC: {
      return {
        ...state,
        importCalculationTopic: action.payload,
        importTopic: null,
        importEnvTopic: null,
        calculationRealTimeResultsTopic: null,
      };
    }
    case types.SET_UPDATE_CALCULATION_MERCURE: {
      return { ...state, updateCalculationMercure: action.payload };
    }
    case types.SET_PDIS_TOPIC: {
      return {
        ...state,
        importPdisTopic: action.payload,
        importTopic: null,
        importEnvTopic: null,
        importCalculationTopic: null,
        calculationRealTimeResultsTopic: null,
      };
    }
    case types.SET_UPDATE_PDIS_MERCURE: {
      return { ...state, updatePdisMercure: action.payload };
    }
    case types.SET_CALCULATION_REAL_TIME_RESULTS_TOPIC:
      return {
        ...state,
        importCalculationTopic: null,
        importTopic: null,
        importEnvTopic: null,
        calculationRealTimeResultsTopic: action.payload,
      };
    case types.SET_UPDATE_CALCULATION_REAL_TIME_RESULTS:
      return {
        ...state,
        updateCalculationRealTimeResults: action.payload,
      };
    default:
      return state;
  }
};

export default mercure;
