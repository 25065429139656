// import React from 'react';
import { useResolveUnit } from './FunctionalUnit.hook';

const FunctionalUnit = ({ calculation }) => {
  const unit = useResolveUnit(calculation);

  return unit || '...';
};

export default FunctionalUnit;
