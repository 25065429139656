import i18next from 'i18next';

import { EnvironmentalProfile } from 'services';
import { useMemo } from 'react';

export const dataQualityOptions = [
  { value: null, get label() { return i18next.t('none'); } },
  { value: 'measured', get label() { return i18next.t('Measured'); } },
  { value: 'estimated', get label() { return i18next.t('Estimated'); } },
  { value: 'average product', get label() { return i18next.t('Average product'); } },
  { value: 'average factory', get label() { return i18next.t('Average factory'); } },
];

export const getProfiles = (stageId) => {
  if (!stageId && stageId !== 0) return null;

  return EnvironmentalProfile
    .all({ params: { code: stageId } });
};

const parsePdisUsagesForStage = (pdisUsages, stage) => pdisUsages?.map(pdisUsage => {
  const inputsForStage = pdisUsage.inputs.filter(i => i.inputStage === stage['@id']
    || i.inputStage['@id'] === stage['@id']);
  if (inputsForStage.length === 0) return null;

  return {
    ...pdisUsage,
    inputs: inputsForStage,
  };
}).filter(u => !!u);

export const useInputsByStage = (stages,
  sfpUsages,
  pdisUsages, inputs,
  isSemiFinishedProduct) => (
  useMemo(() => stages?.filter(({ code }) => !isSemiFinishedProduct || !(code === 'SEMI_FINISHED'
      || code === 'A5_CONSTRUCTION_STAGE'
      || code === 'B1_USE_STAGE'
      || code === 'B2_MAINTENANCE'
      || code === 'C1_DEMOLITION'))
      .map(stage => {
        // Add semiFinishedUsages
        const semiFinishedUsages = sfpUsages?.map(sfpUsage => {
          // List pdisUsages under this SFP that have inputs for this stage
          const predefinedInputSetUsages = parsePdisUsagesForStage(
            sfpUsage.predefinedInputSetUsages, stage,
          );

          // List inputs who have this sfpUsage under this stage
          const usageInputs = inputs?.filter(i => i.semiFinishedProductUsage?.id === sfpUsage.id
            && i.inputStage['@id'] === stage['@id']);

          // Check length of both, discard this usage if both 0
          if (predefinedInputSetUsages?.length > 0 || usageInputs?.length > 0) {
            return {
              sfpUsage,
              predefinedInputSetUsages,
              inputs: usageInputs,
              inputCount: (usageInputs?.length || 0)
                + (predefinedInputSetUsages?.reduce((acc, curr) => acc + curr.inputs.length, 0)
                  || 0),
            };
          }
          return null;
        })
          .filter(u => !!u);

        // Add pdisUsages
        const predefinedInputSetUsages = parsePdisUsagesForStage(pdisUsages, stage);

        // And lastly, add inputs that don't belong to any sfp
        const stageInputs = inputs?.filter(i => !i.semiFinishedProductUsage
          && i.inputStage['@id'] === stage['@id']);
        return ({
          stage,
          semiFinishedUsages,
          predefinedInputSetUsages,
          inputs: stageInputs,
          inputCount: (stageInputs?.length || 0)
            + (semiFinishedUsages?.reduce((acc, curr) => acc + curr.inputCount, 0) || 0)
            + (predefinedInputSetUsages?.reduce((a, c) => a + c.inputs.length, 0) || 0),
        });
      })
      .filter(stage => stage.semiFinishedUsages?.length > 0
        || stage.predefinedInputSetUsages?.length > 0 || stage.inputs?.length > 0),
  [inputs, isSemiFinishedProduct, pdisUsages, sfpUsages, stages]));
