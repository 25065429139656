import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Button from 'components/Button/Button';

import * as S from './DetailDashboard.styled';

const styleOverride = { width: '14.5rem', marginRight: '1.37rem', marginBottom: '1rem' };

const Option = ({
  action, label, disabled, img, loading,
}) => (
  <Button
    key={label}
    disabled={disabled}
    loading={loading}
    onClick={action}
    style={styleOverride}
    styling="select">
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <span color={label === 'Delete' ? '#BF2E00' : 'currentColor'}>{label}</span>
      {!loading && <img alt="icon" src={img} />}
    </div>
  </Button>
);

const extendAction = (obj, action) => ({
  ...obj,
  action: () => obj.action().then(action),
});

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ buttonsConfig, downloadButtons }) => {
  const { t } = useTranslation();
  const user = useSelector(state => state.user);
  const viewer = user?.selectedMembership?.view === 'Viewer';
  const h = useHistory();
  const removeAction = viewer ? null : extendAction(
    buttonsConfig.remove,
    (yes) => yes && h.replace('/calculations/overview'),
  );

  const actionButtons = viewer ? null
    : [buttonsConfig.resubmit || buttonsConfig.submit, removeAction].map(Option);

  return (
    <section>
      {!viewer
        && (
          <>
            <S.TitleSection style={{ borderTop: '1px solid #e4e4e4' }}>
              <h1>{t('Actions')}</h1>
            </S.TitleSection>
            <S.Padding>
              <S.ActionsWrapper>{actionButtons}</S.ActionsWrapper>
            </S.Padding>
          </>
        )}

      <S.TitleSection>
        <h1>{t('Downloads')}</h1>
      </S.TitleSection>
      <S.Padding>
        <S.ActionsWrapper>{downloadButtons.map(Option)}</S.ActionsWrapper>
      </S.Padding>
    </section>
  );
};
