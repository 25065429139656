import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { reviews, calculations } from 'services';

import Select from 'components/Select/Select';
import Button from 'components/Button/Button';

import { ButtonsWrapper } from '../styled';

const { bulk } = calculations;

const Submit = ({ selection, setIsModalOpen }) => {
  const { t } = useTranslation();
  const [options, setOptions] = useState([]);
  const [reviewerID, setReviewer] = useState(undefined);

  const closeModal = () => setIsModalOpen(false);

  useEffect(() => {
    reviews
      .getReviewers()
      .then((companies) => companies.map(({ name, id }) => ({ label: name, value: id })))
      .then(setOptions);
  },
  [setOptions]);

  return (
    <div style={{ minWidth: '20rem' }}>
      <Select
        onChange={({ value }) => setReviewer(value)}
        options={options}
      />

      <ButtonsWrapper>
        <Button
          onClick={() => setIsModalOpen(false)}
          style={{ marginRight: '1rem' }}
          styling="underline">
          {t('cancel')}
        </Button>

        <Button
          disabled={!reviewerID}
          onClick={() => bulk.submit(selection, reviewerID).then(closeModal)}
          style={{ margin: 0 }}
          styling="white">
          {t('confirm')}
        </Button>
      </ButtonsWrapper>
    </div>
  );
};

export default Submit;
