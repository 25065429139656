import * as types from './user.types';

const initialState = {
  email: null,
  locale: process.env.REACT_APP_DEFAULT_LANG,
  isRecentMembership: false,
  membershipSize: 0,
};

const user = (state = initialState, action) => {
  switch (action.type) {
    case types.UPDATE_USER:
    case types.GET_USER: {
      return { ...state, ...action.payload };
    }
    case types.SET_USER_MEMBERSHIP: {
      return { ...state, ...action.payload };
    }
    case types.IS_RECENT_MEMBERSHIP: {
      return { ...state, ...action.payload };
    }
    case types.SET_SIZE_MEMBERSHIP: {
      return { ...state, ...action.payload };
    }

    default:
      return state;
  }
};

export default user;
