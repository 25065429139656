import React from 'react';
import { versions } from 'services';
import AnnouncementForm from 'routes/MyProfile/AnnouncementForm';
import {
  useLocation, useParams, useHistory, Redirect,
} from 'react-router-dom';
import { useAsync } from 'react-async-hook';
import { Placeholder } from 'components/Splash/Loading';
import * as S from 'routes/GlobalStyle.styled';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { setBreadcrumb } from 'state/sidebar/sidebar.actions';

const NewAnnouncement = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const isEdit = pathname.includes('edit');
  const history = useHistory();
  const { id } = useParams();
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const { isHidden } = useSelector(state => state.sidebar);
  const announcement = useAsync(() => isEdit && versions.getAnnouncementById(id), []);
  const { result } = announcement;

  React.useEffect(() => {
    if (result?.title) dispatch(setBreadcrumb(result?.title));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result]);

  const handleSubmitValues = (values) => {
    const params = isEdit
      ? {
        title: result?.title,
        description: result?.description,
        publishedAt: moment(result.publishedAt).format(),
        stickyUntil: moment(result.stickyUntil).format(),
      }
      : {};

    if (values.title) params.title = values.title;
    if (values.description) params.description = values.description;
    if (values.publishedAt) params.publishedAt = moment(values.publishedAt).format();
    if (values.stickyUntil) params.stickyUntil = moment(values.stickyUntil).format();

    if (isEdit) {
      return versions.editAnnouncement(id, params)
        .then(() => history
          .push(`/manage/announcements/${id}`, { routeName: params.title }));
    }

    return versions
      .createAnnouncement(params)
      .then(newAnnouncement => history
        .push(`/manage/announcements/${newAnnouncement.id}`, { routeName: newAnnouncement.title }));
  };

  const superAdmin = user?.selectedMembership?.view === 'Super Admin'
    || user?.selectedMembership?.view === 'Nibe Employee';

  if (!superAdmin) return <Redirect to="/" />;

  return (
    <S.Background isEditable>
      <Placeholder loaders={[announcement]}>
        <S.Bar style={{ marginBottom: '3.68rem' }}>
          {t(isEdit ? 'edit announcement' : 'new announcement')}
        </S.Bar>

        <S.FormContainer isHidden={isHidden}>
          <AnnouncementForm
            announcementDescription={result?.description}
            handleSubmitValues={handleSubmitValues}
            initialValues={{
              title: result?.title,
              publishedAt: moment(result?.publishedAt).format('YYYY-MM-DD'),
              stickyUntil: moment(result?.stickyUntil).format('YYYY-MM-DD'),
            }}
            isEdit={isEdit}
          />
        </S.FormContainer>
      </Placeholder>
    </S.Background>
  );
};

export default NewAnnouncement;
