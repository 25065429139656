import { useEffect, useMemo, useState } from 'react';
import { getAll } from 'services/companies';

export const useCompanies = (_params) => {
  const [companies, setCompanies] = useState();
  const [loading, setLoading] = useState(true);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedParams = useMemo(() => _params, [JSON.stringify(_params)]);
  useEffect(() => {
    getAll({ params: memoizedParams })
      .then(data => setCompanies(data))
      .then(() => setLoading(false));
  }, [memoizedParams]);

  return {
    loading,
    ...companies,
  };
};
