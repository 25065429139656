import React, { useState } from 'react';
import Arrow from 'assets/sortArrow.svg';
import { useTranslation } from 'react-i18next';

import * as S from './TopBar.styled';

const ID = ({ onOrderBy, order, inverted }) => {
  const { t } = useTranslation();
  const [isDesc, setIsDesc] = useState(false);

  const sortName = () => {
    if (isDesc) {
      onOrderBy('asc');
      if (order === 'desc') setIsDesc(false);
      else if (order === null) setIsDesc(true);
      else setIsDesc(!isDesc);
    } else {
      onOrderBy('desc');
      if (order === 'desc') setIsDesc(false);
      else if (order === null) setIsDesc(true);
      else setIsDesc(!isDesc);
    }
  };

  return (
    <S.Section>
      <S.Name>
        <S.Label onClick={sortName}>{t('ID')}</S.Label>
        {order && <S.Arrow isDesc={inverted ? !isDesc : isDesc} onClick={sortName} src={Arrow} />}
      </S.Name>
    </S.Section>
  );
};

export default ID;
