import styled from 'styled-components';
import { paddingRows, BLUE } from 'config/style';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: ${paddingRows};
  padding-top: 1rem;
  padding-bottom: 1rem;
  color: white;
  background: ${BLUE};
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  transition: 1s;
`;

export const Elements = styled.span`
  font-weight: normal;
  font-size: 14px;
  margin-left: 1.5rem;
`;

export const Select = styled.select`background-color: white;`;

export const Section = styled.div`
  display: flex;
`;

export const OpenWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const OpenContainer = styled.div`
  width: 1.9rem;
  height: 1.9rem;
  border-radius: 100%;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 1.5rem;
`;

export const OpenImage = styled.img``;
