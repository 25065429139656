import React from 'react';

const Submitted = ({ color, ...props }) => (
  <svg fill="none" height="14" viewBox="0 0 17 14" width="17" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      // eslint-disable-next-line max-len
      d="M0.276748 9.24498L6.55644 11.4537L11.211 6.74049C11.2913 6.66161 11.4117 6.66161 11.4919 6.74049C11.5722 6.81938 11.5722 6.9377 11.4919 7.01658L6.97776 11.5917L13.759 13.9779C13.9998 14.0567 14.2807 13.9187 14.3409 13.6623L16.9892 0.508869C17.0694 0.153904 16.6882 -0.122181 16.3672 0.0553019L0.236622 8.45616C-0.104447 8.63365 -0.0643214 9.10693 0.276748 9.24498Z"
      fill={color || '#5885A4'}
    />
  </svg>
);

export default Submitted;
