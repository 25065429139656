import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Manager, Reference, Popper } from 'react-popper';
import { locations } from 'services';
import Button from 'components/Button/Button';
import { FormInput as Input } from 'components/Input/Input';
import { FormSelect } from 'components/Select/Select';
import { DARK_BLUE } from 'config/style';
import closeIcon from 'assets/close2.svg';
import plusSign from 'assets/plus_sign.svg';
import {
  Field, reduxForm, Form, SubmissionError,
} from 'redux-form';
import * as v from 'routes/Calculations/Detail/Basics/validation';
import * as S from './LocationEditor.styled';

const LocationEditor = ({
  onLocationCreated,
  submitting,
  handleSubmit,
}) => {
  const { t } = useTranslation();
  const [showPopper, togglePopper] = useState(false);

  const countries = locations.countries();

  const createLocation = (values) => locations
    .add({
      name: values?.name,
      code: values?.code,
      address: values?.address,
      postalCode: values?.postalCode,
      city: values?.city,
      country: values?.country?.value,
    })
    .then(r => {
      onLocationCreated(r, 'done');
      togglePopper(false);
    })
    .catch((error) => {
      if (error?.response?.data?.violations?.find(e => e?.propertyPath === 'code')) {
        throw new SubmissionError({
          code: t('unique ID has already been used for another location'),
        });
      }
    });

  return (
    <Manager>
      <Reference>
        {({ ref }) => (
          <S.ButtonWrapper
            ref={ref}
            onClick={() => togglePopper(!showPopper)}
            title={t('add location')}
            type="button">
            <img alt="icon" src={plusSign} />
          </S.ButtonWrapper>
        )}
      </Reference>

      {showPopper && (
        <Popper placement="right-start">
          {(props) => (
            <S.Wrapper {...props}>
              <S.WrapperTitle>
                <S.Title>Add new location</S.Title>

                <S.CloseButton onClick={() => togglePopper(false)}>
                  <img alt="icon" src={closeIcon} />
                </S.CloseButton>
              </S.WrapperTitle>
              <Form onSubmit={(ev) => {
                ev.preventDefault();
                // handleSubmit((values) => createLocation(values))();
              }}>
                <Field
                  component={Input}
                  name="name"
                  props={{
                    fullInputWidth: true,
                  }}
                  validate={[v.required]}
                />
                <Field
                  component={Input}
                  name="code"
                  props={{
                    fullInputWidth: true,
                  }}
                  validate={[v.required]}
                />
                <Field
                  component={Input}
                  name="address"
                  props={{
                    fullInputWidth: true,
                  }}
                  validate={[v.required]}
                />
                <Field
                  component={Input}
                  name="postalCode"
                  props={{
                    fullInputWidth: true,
                  }}
                  validate={[v.required]}
                />
                <Field
                  component={Input}
                  name="city"
                  props={{
                    fullInputWidth: true,
                  }}
                  validate={[v.required]}
                />
                <Field
                  component={FormSelect}
                  name="country"
                  props={{
                    options: countries,
                    fullInputWidth: true,
                  }}
                  validate={[v.required]}
                />

                <S.ButtonContainer>
                  <Button
                    onClick={() => togglePopper(false)}
                    style={{ background: 'white', color: DARK_BLUE, textDecoration: 'underline' }}>
                    {t('Cancel')}
                  </Button>

                  <Button
                    loading={submitting}
                    onClick={() => {
                      handleSubmit((values) => createLocation(values))();
                    }}
                    style={{
                      background: 'white',
                      color: DARK_BLUE,
                      boxShadow: '0 0 4px rgba(0, 0, 0, 0.1)',
                    }}>
                    {t('add location')}
                  </Button>
                </S.ButtonContainer>
              </Form>
            </S.Wrapper>
          )}
        </Popper>
      )}
    </Manager>
  );
};

LocationEditor.defaultProps = {
  onLocationCreated: (data, status) => ({ data, status }),
};

LocationEditor.propTypes = {
  onLocationCreated: PropTypes.func,
};

export default reduxForm({
  form: 'add-location-form_ex',
  enableReinitialize: true,
})(LocationEditor);
