import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Arrow from 'assets/sortArrow.svg';
import { useTranslation } from 'react-i18next';

import CheckBox from 'components/CheckBox/CheckBox';

import {
  selectAllCalculations,
  selectNone,
  orderByName,
} from 'state/calculations/calculations.actions';

import * as S from './TopBar.styled';

const Name = ({ hasCheckbox }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const selection = useSelector(({ calculations }) => calculations.selection);
  const filters = useSelector(({ calculations }) => calculations.filters);
  const isDesc = !(filters['order[productName]'] === 'asc');

  const sortName = direction => () => dispatch(orderByName(direction));
  const clearSelection = () => dispatch(selectNone);
  const allSelection = () => dispatch(selectAllCalculations);

  return (
    <S.Section>
      <S.Name>
        {hasCheckbox && (
          <CheckBox
            checked={selection.length}
            onChange={selection.length ? clearSelection : allSelection}
          />
        )}

        <S.Label onClick={sortName(isDesc ? 'asc' : 'desc')}>{t('Name')}</S.Label>
        <S.Arrow isDesc={isDesc} onClick={sortName(isDesc ? 'asc' : 'desc')} src={Arrow} />

      </S.Name>
    </S.Section>
  );
};

export default React.memo(Name);
