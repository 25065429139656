import React, { memo } from 'react';

import Spinner from 'components/Splash/Spinner';

import * as S from './Button.styled';

const Button = ({
  children, loading, disabled, ref, innerRef = ref, type = 'button',
  LeftIcon = null, RightIcon = null,
  ...props
}) => (
  <S.StyledButton type={type} {...props} ref={innerRef || ref} disabled={disabled || loading}>
    {LeftIcon && <S.Icon left><LeftIcon /></S.Icon>}

    <div>{children}</div>

    {loading ? <S.Icon as={Spinner} /> : RightIcon && <S.Icon as={RightIcon} />}
  </S.StyledButton>
);

export default memo(Button);
