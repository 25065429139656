import React, { memo } from 'react';
import _ from 'lodash';
import styled from 'styled-components';

import Spinner from 'components/Spinner';

import { isReady, isReference } from 'utils';

const Wrapper = styled.div`
  display: flex;
  height: 100%;
  position: relative;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  img {
    width: auto;
    height: 10rem;
    max-width: 100%;
    display: block;
  }
  p {
    font-size: 1.1rem;
    color: #a8a8a8;
  }
`;

export const Loading = (
  <div
    style={{
      height: 'calc(100vh - 4rem)',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}>
    <Spinner width="5rem" />
  </div>
);

export const NoContent = (
  <Wrapper>
    <p>No items found</p>
  </Wrapper>
);

export const Placeholder = memo(({
  loaders, children, style, renderChildren,
}) => {
  if (isReady(loaders)) {
    return isReference(children) ? children() : children;
  }

  return (
    <div
      key={_.uniqueId('placeholder')}
      style={{
        width: '100%',
        height: '80vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        ...style,
      }}>
      {renderChildren && <div style={{ display: 'none' }}>{isReference(children) ? children() : children}</div>}
      <Spinner />
    </div>
  );
});

export default {
  Loading,
  NoContent,
  Placeholder,
};
