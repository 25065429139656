import React, { useContext, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setBreadcrumb } from 'state/sidebar/sidebar.actions';
import {
  Redirect, Route, Switch, useHistory, useRouteMatch,
} from 'react-router-dom';

import { calculations } from 'services';
import { Calculation, useCanEdit } from 'hooks/calculation.hooks';

import MainModal from 'components/Modal';
import formField from 'components/SimpleCKEditorFormItem/SimpleCKEditorFormItem';

import General from './General';
import GeneralView from './General.View';
import Substantiations from './Substantiations';
import SubstantiationsView from './Substantiations.View';

const Reports = ({ template, updateTemplate }) => {
  const { path } = useRouteMatch();
  const dispatch = useDispatch();
  const history = useHistory();
  const { result: calculation, execute } = useContext(Calculation);
  const canEdit = useCanEdit();
  const [hasEditedForm, setHasEditedForm] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(null);
  const formFields = useRef(null);

  React.useEffect(() => {
    if (calculation?.productName) dispatch(setBreadcrumb(calculation?.productName));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calculation]);

  const handleSubmitValues = async (values) => {
    setHasEditedForm(false);
    const tempValues = values;

    if (!canEdit) return null;

    const uploadFile = async (data, key) => {
      delete tempValues[key];
      if (!data || data.length === 0) {
        return;
      }

      const formData = new FormData();
      formData.append('file', data[0]);
      const file = await calculations.uploadAllocationImageFile(formData);

      if (file?.['@id']) {
        tempValues[key] = file['@id'];
      }
    };

    await Promise.all([
      uploadFile(tempValues.productImage, 'productImage'),
      uploadFile(tempValues.productFlowImageFile, 'productFlowImageFile'),
      uploadFile(tempValues.productDescriptionImageFile, 'productDescriptionImageFile'),
      uploadFile(tempValues.productPerformanceFile, 'productPerformanceFile'),
      uploadFile(tempValues.productionProcessImageFile, 'productionProcessImageFile'),
      uploadFile(tempValues.productionProcessFile, 'productionProcessFile'),
      uploadFile(tempValues.constructionProcessImageFile, 'constructionProcessImageFile'),
      uploadFile(tempValues.constructionProcessFile, 'constructionProcessFile'),
      uploadFile(tempValues.constructionWasteFile, 'constructionWasteFile'),
    ]);

    setHasEditedForm(false);

    if (template) {
      return calculations.saveTemplate(template?.id, tempValues)
        .then(() => {
          updateTemplate();
          setHasEditedForm(false);
          history.goBack();
        });
    } else if (JSON.stringify(calculation) !== JSON.stringify({ ...calculation, ...tempValues })) {
      return calculations
        .save(calculation.id, tempValues)
        .then(() => {
          execute();
          setHasEditedForm(false);
          history.goBack();
        });
    }

    return null;
  };

  const getFieldValues = (fields) => {
    formFields.current = fields;
  };

  const CustomGeneralView = React.useCallback(props => (
    <GeneralView
      {...props}
      template={template}
    />
  ), [template]);

  const CustomSubstantiationsView = React.useCallback(props => (
    <SubstantiationsView
      {...props}
      template={template}
    />
  ), [template]);

  return (
    <Switch>
      <Route
        component={() => <Redirect to="information-for-report/general/view" />}
        exact
        path={path}
      />
      <Route component={() => <Redirect to="general/view" />} exact path={`${path}/general`} />
      <Route
        component={CustomGeneralView}
        exact
        path={`${path}/general/view`}
      />

      <Route
        component={() => <Redirect to="information-for-report/general/edit" />}
        exact
        path={`${path}/edit`}
      />
      <Route exact path={`${path}/general/edit`}>
        <General
          data={template || calculation}
          formField={(props) => formField({ ...props, setHasEditedForm })}
          getFieldValues={getFieldValues}
          handleSubmitValues={handleSubmitValues}
          hasEditedForm={hasEditedForm}
          isTemplate={template}
          updateTemplate={updateTemplate}
        />
      </Route>

      <Route
        component={() => <Redirect to="substantiations/view" />}
        exact
        path={`${path}/substantiations`}
      />
      <Route component={CustomSubstantiationsView} exact path={`${path}/substantiations/view`} />

      <Route exact path={`${path}/substantiations/edit`}>
        <Substantiations
          data={template || calculation}
          formField={(props) => formField({ ...props, setHasEditedForm })}
          getFieldValues={getFieldValues}
          handleSubmitValues={handleSubmitValues}
          hasEditedForm={hasEditedForm}
          isTemplate={template}
          updateTemplate={updateTemplate}
        />
      </Route>

      <MainModal
        content="prompt"
        exitModalContent={{
          backToText: 'back to calculation',
          saveFunc: () => handleSubmitValues(formFields.current),
          navigateSideEffects: () => setHasEditedForm(false),
        }}
        prompt
        promptCondition={hasEditedForm}
      />

      <MainModal
        confirmationModalInfo={confirmationModal}
        content="delete"
        isModalOpen={confirmationModal}
        setIsModalOpen={setConfirmationModal}
      />
    </Switch>
  );
};

export default Reports;
