import React, { useState, useRef, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { setBreadcrumb } from 'state/sidebar/sidebar.actions';
import {
  Route, Switch, useRouteMatch, Redirect, useHistory,
} from 'react-router-dom';

import { calculations } from 'services';
import { Calculation } from 'hooks/calculation.hooks';

import MainModal from 'components/Modal';
import FormField from 'components/SimpleCKEditorFormItem/SimpleCKEditorFormItem';

import ViewDefaultSubstantiations from './DefaultSubstantiations.View';
import DefaultSubstantiations from './DefaultSubstantiations';
import InputOutputSubstantiationsView from './InputOutputSubstantiations.View';
import InputOutputSubstantiations from './InputOutputSubstantiations';
import InterpretationView from './Interpretation.View';
import Interpretation from './Interpretation';

const ExpertFunctions = ({ template, updateTemplate }) => {
  const { path } = useRouteMatch();
  const dispatch = useDispatch();
  const history = useHistory();
  const { result: calculation, execute } = useContext(Calculation);
  const [hasEditedForm, setHasEditedForm] = useState(false);
  const formFields = useRef(null);

  React.useEffect(() => {
    if (calculation?.productName) dispatch(setBreadcrumb(calculation?.productName));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calculation]);

  const handleSubmitValues = async (values) => {
    setHasEditedForm(false);
    const tempValues = values;

    const uploadFile = async (data, key) => {
      delete tempValues[key];
      if (!data || data.length === 0) {
        return;
      }

      const formData = new FormData();
      formData.append('file', data[0]);
      const file = await calculations.uploadAllocationImageFile(formData);

      if (file?.['@id']) {
        tempValues[key] = file['@id'];
      }
    };

    await Promise.all([
      uploadFile(tempValues.allocationImage, 'allocationImage'),
      uploadFile(tempValues.sensitiveAnalysisImage, 'sensitiveAnalysisImage'),
    ]);

    setHasEditedForm(false);

    if (template) {
      return calculations.saveTemplate(template?.id, tempValues)
        .then(() => {
          updateTemplate();
          setHasEditedForm(false);
          history.goBack();
        });
    } else if (JSON.stringify(calculation) !== JSON.stringify({ ...calculation, ...tempValues })) {
      return calculations.save(calculation.id, tempValues).then(() => {
        execute();
        setHasEditedForm(false);
        history.goBack();
      });
    }

    return null;
  };

  const getFieldValues = (fields) => {
    formFields.current = fields;
  };

  const CustomViewDefaultSubstantiations = React.useCallback(props => (
    <ViewDefaultSubstantiations
      {...props}
      template={template}
    />
  ), [template]);

  const CustomInputOutputSubstantiationsView = React.useCallback(props => (
    <InputOutputSubstantiationsView
      {...props}
      template={template}
    />
  ), [template]);

  const CustomInterpretationView = React.useCallback(props => (
    <InterpretationView
      {...props}
      template={template}
    />
  ), [template]);

  return (
    <Switch>
      <Route
        component={() => <Redirect to="expert-functions/default-substantiations/view" />}
        exact
        path={path}
      />
      <Route
        component={() => <Redirect to="default-substantiations/view" />}
        exact
        path={`${path}/default-substantiations`}
      />

      <Route
        component={CustomViewDefaultSubstantiations}
        exact
        path={`${path}/default-substantiations/view`}
      />

      <Route exact path={`${path}/default-substantiations/edit`}>
        <DefaultSubstantiations
          data={template || calculation}
          formField={(props) => <FormField {...props} setHasEditedForm={setHasEditedForm} />}
          getFieldValues={getFieldValues}
          handleSubmitValues={handleSubmitValues}
          hasEditedForm={hasEditedForm}
          isTemplate={template}
          setHasEditedForm={setHasEditedForm}
          updateTemplate={updateTemplate}
        />
      </Route>

      <Route
        component={() => <Redirect to="input-output-substantiations/view" />}
        exact
        path={`${path}/input-output-substantiations`}
      />
      <Route
        component={CustomInputOutputSubstantiationsView}
        exact
        path={`${path}/input-output-substantiations/view`}
      />

      <Route exact path={`${path}/input-output-substantiations/edit`}>
        <InputOutputSubstantiations
          data={template || calculation}
          execute={execute}
          formField={(props) => <FormField {...props} setHasEditedForm={setHasEditedForm} />}
          getFieldValues={getFieldValues}
          handleSubmitValues={handleSubmitValues}
          hasEditedForm={hasEditedForm}
          isTemplate={template}
          setHasEditedForm={setHasEditedForm}
          updateTemplate={updateTemplate}
        />
      </Route>

      <Route
        component={() => <Redirect to="interpretation/view" />}
        exact
        path={`${path}/interpretation`}
      />
      <Route component={CustomInterpretationView} exact path={`${path}/interpretation/view`} />

      <Route exact path={`${path}/interpretation/edit`}>
        <Interpretation
          data={template || calculation}
          execute={execute}
          formField={(props) => <FormField {...props} setHasEditedForm={setHasEditedForm} />}
          getFieldValues={getFieldValues}
          handleSubmitValues={handleSubmitValues}
          hasEditedForm={hasEditedForm}
          isTemplate={template}
          setHasEditedForm={setHasEditedForm}
          updateTemplate={updateTemplate}
        />
      </Route>

      <MainModal
        content="prompt"
        exitModalContent={{
          saveFunc: () => handleSubmitValues(formFields.current),
          navigateSideEffects: () => setHasEditedForm(false),
        }}
        prompt
        promptCondition={hasEditedForm}
      />
    </Switch>
  );
};

export default ExpertFunctions;
