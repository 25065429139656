import React, {
  useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { calculations as calculationService, realtime } from 'services';
import axios from 'axios';
import { useCalculationContext } from './calculation.hooks';
import {
  setCalculationRealTimeResultsTopic,
  setUpdateCalculationRealTimeResultsContent,
} from '../state/mercure/mercure.actions';
import { useInputsContext } from './input.hooks';

const RealTimeResultsContext = React.createContext({
  loading: true,
  failed: false,
  results: {
    other: {},
    subtotals: [],
    overall: 0,
    top: {},
    total: {},
  },
});

export const useRealTimeResultsContext = () => useContext(RealTimeResultsContext);

const useRealtimeResultsFetcher = (activeResultJournal, calculationId) => {
  const [results, setResults] = useState(null);
  const [loading, setLoading] = useState(false);
  const [failed, setFailed] = useState(false);
  useEffect(() => {
    if (!calculationId || !activeResultJournal
      || activeResultJournal.state !== 'calculated' || activeResultJournal.expired) {
      setLoading(false);
      return;
    }
    setLoading(true);
    const cancelToken = axios.CancelToken.source();
    realtime.progress(calculationId, { cancelToken: cancelToken.token })
      .then(r => {
        setResults(r);
        setFailed(false);
        return r;
      })
      .catch((e) => {
        console.error(e);
        setFailed(true);
        setResults(null);
      })
      .finally(() => setLoading(false));

    // eslint-disable-next-line consistent-return
    return () => {
      // cancelToken.cancel();
    };
  }, [activeResultJournal, calculationId]);

  return {
    results,
    loading,
    failed,
  };
};

const RealTimeResultsListener = ({ setLoading, setResults, setFailed }) => {
  const { result: calculation } = useCalculationContext();
  const dispatch = useDispatch();
  const [lastJournal, setLastJournal] = useState();

  // Mercure update listener: just update lastJournal - effects will do the rest
  const onMercureUpdate = useCallback(data => {
    setLastJournal(data);
  }, []);

  // Set mercure update listener
  useEffect(() => {
    dispatch(setUpdateCalculationRealTimeResultsContent(onMercureUpdate));
    return () => {
      dispatch(setUpdateCalculationRealTimeResultsContent(null));
    };
  }, [dispatch, onMercureUpdate]);

  // Whenever calculation changes, subscribe to that topic
  useEffect(() => {
    if (!calculation) {
      setLastJournal(null);
      return;
    }
    if (calculation.activeResultJournal) {
      setLastJournal(calculation.activeResultJournal);
    } else {
      setLastJournal({ expired: true });
    }
  }, [calculation]);

  console.log(lastJournal?.id, lastJournal?.state, lastJournal?.expired);

  const triggerRecalculation = useCallback(() => calculation
      && calculationService.triggerCalculation(calculation),
  [calculation]);

  // Whenever lastJournal changes, either fetch realtime results or get the new topic
  useEffect(() => {
    if (!lastJournal) {
      return;
    }
    if (lastJournal.expired || lastJournal.state === 'obsolete') {
      setResults(null);
      triggerRecalculation().then(journal => {
        setLastJournal(journal);
      }).catch(console.error);
    } else {
      // dispatch(setCalculationRealTimeResultsTopic(null));
      // setTimeout(() => dispatch(setCalculationRealTimeResultsTopic(lastJournal)));
      dispatch(setCalculationRealTimeResultsTopic(lastJournal));
    }
  }, [dispatch, lastJournal, setResults, triggerRecalculation]);

  const { loading, results, failed } = useRealtimeResultsFetcher(lastJournal, calculation?.id);

  // Update context
  useEffect(() => {
    setLoading(loading);
    setResults(results);
    setFailed(failed);
  }, [failed, loading, results, setFailed, setLoading, setResults]);

  return null;
};

export const WithRealTimeResultsContext = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [results, setResults] = useState(null);
  const [failed, setFailed] = useState(false);
  const value = useMemo(() => ({ loading, results, failed }), [failed, loading, results]);

  return (
    <RealTimeResultsContext.Provider value={value}>
      {children}
      <RealTimeResultsListener
        setFailed={setFailed}
        setLoading={setLoading}
        setResults={setResults}
      />
    </RealTimeResultsContext.Provider>
  );
};

export const RealTimeInputsUpdater = () => {
  const { loading, results } = useRealTimeResultsContext();
  const { overall } = results || {};
  const { refresh: refreshInputs, loading: inputsLoading } = useInputsContext();

  const [lastKnownCost, setLastKnownCost] = useState(null);

  useEffect(() => {
    if (!overall || loading || inputsLoading) return;
    if (overall === lastKnownCost) return;

    setLastKnownCost(overall);
    refreshInputs();
  }, [inputsLoading, lastKnownCost, loading, overall, refreshInputs]);

  return null;
};
