import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import Button from 'components/Button/Button';
import ListItem from 'components/ListItem/ListItem';
import Name from 'components/TopBar/Name';
import LastChangedStatus from 'components/TopBar/LastChangedStatus';
import TopBar from 'components/TopBar/TopBar';
import TopbarActions from 'components/TopbarActions/TopbarActions';
import Spinner from 'components/Spinner';
import * as S from 'routes/Calculations/Overview.styled';
import InfiniteScroll from 'react-infinite-scroll-component';
import { inputs as inputsServices } from 'services';
import edit from 'assets/edit.svg';
import deleteIcon from 'assets/delete.svg';
import { confirmationModalSetup } from 'components/Modal/ModalContent/Delete';
import MainModal from 'components/Modal';
import groupIcon from '../../../../../assets/group.svg';

const Pdis = ({
  name: codeName,
  method: methodFilter,
  wasteScenario: wasteScenarioFilter,
  setTotalNumberOfRecords,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [pdis, setPdis] = useState(null);
  const [page, setPage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [sorting, setSorting] = useState(null);
  const [method, setMethod] = useState(null);
  const [wasteScenario, setWasteScenario] = useState(null);
  const [sortingDate, setSortingDate] = useState(null);
  const [sortingCode, setSortingCode] = useState('asc');
  const [confirmationModal, setConfirmationModal] = useState(null);

  const getData = () => {
    const params = {};
    if (sorting) params['order[name]'] = sorting;
    if (method) params['order[method.code]'] = method;
    if (wasteScenario) params['order[wasteScenarioSet.code]'] = wasteScenario;
    if (sortingDate) params['order[lastModifiedAt]'] = sortingDate;
    if (sortingCode) params['order[code]'] = sortingCode;
    if (methodFilter) params.method = methodFilter?.id;
    if (wasteScenarioFilter) params.wasteScenarioSet = wasteScenarioFilter?.id;
    if (codeName) params.search = codeName;
    setLoading(true);
    inputsServices
      .getPdis(params)
      .then((values) => {
        setPdis(values.data);
        setPage(values.nextPage);
        setTotalNumberOfRecords(values.totalItems);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sorting, sortingDate, sortingCode, method, wasteScenario,
    methodFilter, wasteScenarioFilter]);

  const fetchData = () => {
    if (page) {
      inputsServices
        .getNextPage(page)
        .then((values) => {
          setPage(values.nextPage);
          setPdis(pdis.concat(values.data));
          setTotalNumberOfRecords(values.totalItems);
        });
    }
  };

  const onEditItem = (data) => history
    .push(`/calculations/predefined-input-sets/${data.id}`, { routeName: data.name });

  const onDeleteItem = id => confirmationModalSetup(
    setConfirmationModal,
    (closeModal) => inputsServices
      .deletePdis(id)
      .then(() => {
        setPdis(_.filter(pdis, item => item.id !== id));
        return closeModal();
      }),
  );

  const onCopyItem = id => {
    setLoading(true);
    inputsServices.copyPdis(id)
      .then(() => getData())
      .finally(() => {
        setLoading(false);
      });
  };

  const ListItemContent = ({ data }) => (
    <>
      <S.Option
        key="edit"
        isClickable
        onClick={() => onEditItem(data)}>
        <div>
          <img alt="icon" src={edit} />
        </div>

        <S.StyledLabel isRegular>
          {t('edit')}
        </S.StyledLabel>
      </S.Option>

      <S.Option key="copy" isClickable onClick={() => onCopyItem(data?.['@id'])}>
        <div>
          <img alt="icon" src={groupIcon} />
        </div>

        <S.StyledLabel isRegular>
          {t('copy')}
        </S.StyledLabel>
      </S.Option>

      <S.Option key="delete" isClickable onClick={() => onDeleteItem(data?.id)}>
        <div>
          <img alt="icon" src={deleteIcon} />
        </div>

        <S.StyledLabel color="#BF2E00" isRegular>
          {t('delete')}
        </S.StyledLabel>
      </S.Option>
    </>
  );

  const pdisList = () => (
    <S.OverviewRows id="overviewRowsId">
      <InfiniteScroll
        dataLength={pdis.length}
        hasMore={page}
        loader={<Spinner />}
        next={fetchData}
        scrollableTarget="overviewRowsId"
        scrollThreshold={0.4}
        style={{ minHeight: '50vh' }}>
        {_.map(pdis, (data) => (
          <ListItem
            key={data?.id}
            calculationImport
            codeName={data?.code}
            date={data?.lastModifiedAt}
            dropdownContent={(<ListItemContent data={data} />)}
            imports
            method={data?.method?.name}
            nameCode={data?.name}
            to={{
              pathname: `/calculations/predefined-input-sets/${data.id}`,
              state: {
                routeName: data?.name,
              },
            }}
            wasteScenario={data?.wasteScenarioSet?.name}
          />
        ))}
      </InfiniteScroll>
    </S.OverviewRows>
  );

  return (
    <>
      <TopbarActions>
        <Button
          as={Link}
          styling="select"
          to="/calculations/predefined-input-sets/new">
          {t('add new set')}
        </Button>
      </TopbarActions>

      <TopBar>
        <div style={{ display: 'flex' }}>
          <div style={{ marginRight: '5rem' }}>
            <Name
              label="Code.label"
              onOrderBy={order => {
                setSortingCode(order);
              }}
              order={sortingCode}
            />
          </div>
          <Name
            inverted
            onOrderBy={() => {
              if (method) setMethod(null);
              if (wasteScenario) setWasteScenario(null);
              if (sortingDate) setSortingDate(null);
              if (!sorting) setSorting('asc');
              else if (sorting === 'asc') setSorting('desc');
              else setSorting(null);
            }}
            order={sorting}
          />
        </div>
        <div style={{ display: 'flex', width: '46rem' }}>
          <div style={{ marginRight: '5rem' }}>
            <Name
              inverted
              label="Method"
              onOrderBy={() => {
                if (sorting) setSorting(null);
                if (wasteScenario) setWasteScenario(null);
                if (sortingDate) setSortingDate(null);
                if (!method) setMethod('asc');
                else if (method === 'asc') setMethod('desc');
                else setMethod(null);
              }}
              order={method}
            />
          </div>
          <Name
            inverted
            label="waste scenario set"
            onOrderBy={() => {
              if (sorting) setSorting(null);
              if (method) setMethod(null);
              if (sortingDate) setSortingDate(null);
              if (!wasteScenario) setWasteScenario('asc');
              else if (wasteScenario === 'asc') setWasteScenario('desc');
              else setWasteScenario(null);
            }}
            order={wasteScenario}
          />
          <div style={{ marginLeft: '4.5rem' }}>
            <LastChangedStatus
              inverted
              label={t('last changed')}
              onOrderBy={() => {
                if (sorting) setSorting(null);
                if (wasteScenario) setWasteScenario(null);
                if (method) setMethod(null);
                if (!sortingDate) setSortingDate('asc');
                else if (sortingDate === 'asc') setSortingDate('desc');
                else setSortingDate(null);
              }}
              order={sortingDate}
            />
          </div>
        </div>
      </TopBar>

      {!pdis || loading
        ? (
          <div
            style={{
              height: 'calc(100vh - 4rem)',
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            <Spinner />
          </div>
        )
        : pdisList()}
      <MainModal
        confirmationModalInfo={confirmationModal}
        content="delete"
        isModalOpen={confirmationModal}
        setIsModalOpen={setConfirmationModal}
      />
    </>
  );
};

export default Pdis;
