import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { Collapse } from '@material-ui/core';

export const AsideLinkWrapper = styled(Collapse)`
  padding-left: 2rem;
  display: flex;
  flex-direction: column;
`;

export const AsideLink = styled(NavLink)`
  background-color: white;
  color: inherit;
  margin-bottom: 1.25rem;
  display: block;
  .arrow { display: ${({ activateArrow }) => (activateArrow ? 'inline-block' : 'none')} }

  &.active {
    font-weight: 500;
    .arrow { display: inline-block; }
  }
`;

export const Arrow = styled.img`
  margin-right: 8px;
`;
