import _ from 'lodash';
import React, { useState, useContext } from 'react';
import { useHistory, Redirect, useParams } from 'react-router-dom';
import { alertToast } from 'config/toast';
import { useSelector } from 'react-redux';

import { Field, reduxForm, Form } from 'redux-form';
import { useAsync } from 'react-async-hook';
import { useTranslation } from 'react-i18next';

import { useQuery } from 'utils';
import { calculations as calculationsService } from 'services';
import { Calculation, useCanEdit } from 'hooks/calculation.hooks';

import { Placeholder } from 'components/Splash/Loading';
import Button from 'components/Button/Button';
import { FormInput } from 'components/Input/Input';
import { FormSelect, FormAsyncSelect } from 'components/Select/Select';
import MainModal from 'components/Modal';
import * as G from 'routes/GlobalStyle.styled';
import * as S from './AddInput.styled';

import * as v from './validation';
import handleFailSubmit from './handleFailSubmit';
import { scalingOptions } from '../Advanced/Editor.helpers';
import { useInputsContext } from '../../../../hooks/input.hooks';

const FORM_NAME = 'add-input-semifinished';

const payload = (formData, calculationId) => {
  const body = {
    amount: _.toNumber(formData.amount),
    semiFinishedProduct: formData.semiFinishedProduct['@id'],
    scalingType: formData.scalingType?.value ?? 'none',
    calculation: calculationId,
  };

  return body;
};

const mapInitialValues = (inputData, input) => ({
  ...inputData,
  scalingType: { label: inputData?.scalingType, value: inputData?.scalingType },
  semiFinishedProduct: input,
});

const Wrapper = ({ withSelect }) => {
  const { isHidden } = useSelector(state => state.sidebar);
  const { t } = useTranslation();
  const inputId = useQuery().get('inputId');
  const canEdit = useCanEdit();
  const inputs = useInputsContext();
  const [semiFinishedProductInput, setSemiFinishedProductInput] = React.useState(null);
  const { result: { scalingType, '@id': calculationId } = {}, execute } = useContext(Calculation);
  const input = useAsync(() => calculationsService.usage(inputId)
    ?.then(async res => {
      setSemiFinishedProductInput(res.semiFinishedProduct);
      return res;
    }), [inputId]);
  const calculations = useAsync(
    () => calculationsService
      .getAllSfpsForDropdown({ itemsPerPage: 30, 'order[productName]': 'asc' }),
    [],
  );

  const saveForm = (formData) => calculationsService
    .saveUsage(payload(formData, calculationId), inputId)
    .then(() => {
      execute();
      inputs.refresh();
    })
    .catch(handleFailSubmit);

  const saveAndContinue = (formData) => calculationsService
    .saveUsage(payload(formData, calculationId), inputId)
    .then(() => {
      execute();
      inputs.refresh();
    })
    .catch(handleFailSubmit);

  const loaderList = [inputId ? input : false, calculations];

  if (!canEdit) return <Redirect to="list" />;

  return (
    <G.Background isEditable>
      <G.HeaderTitle>
        {t('Inputs')}
      </G.HeaderTitle>

      {!inputId && (
        <G.FormContainer isHidden={isHidden} style={{ minHeight: 'auto' }}>
          <S.Header>
            <S.TitleHeader>{t('Choose input type')}</S.TitleHeader>

            <S.InputWrapper>
              {withSelect}
            </S.InputWrapper>
          </S.Header>
        </G.FormContainer>
      )}

      <Placeholder loaders={loaderList}>
        {() => (
          <SemifinishedForm
            calculations={calculations}
            initialValues={input.result
              && mapInitialValues(input.result, semiFinishedProductInput)}
            saveAndContinue={saveAndContinue}
            saveForm={saveForm}
            scalable={scalingType !== 'none'}
            semiFinishedProduct={semiFinishedProductInput}
          />
        )}
      </Placeholder>
    </G.Background>
  );
};

let SemifinishedForm = (props) => {
  const {
    scalable,
    handleSubmit,
    submitting,
    saveForm,
    saveAndContinue,
    reset,
    calculations,
    pristine,
    semiFinishedProduct,
  } = props;
  const { isHidden } = useSelector(state => state.sidebar);
  const { t } = useTranslation();
  const { id } = useParams();
  const h = useHistory();
  const inputId = useQuery().get('inputId');
  const [saveSpinner, setSaveSpinner] = useState(false);
  const [saveContinueSpinner, setSaveContinueSpinner] = useState(false);
  const [hasEditedForm, setHasEditedForm] = useState(false);
  const [page, setPage] = useState(null);
  const [search, setSearch] = useState(null);
  const [list, setList] = useState(calculations?.result?.data);

  const save = (args) => {
    setSaveSpinner(true);
    setHasEditedForm(true);
    return saveForm(args)
      .then(() => h.push(`/calculation/${id}/inputs`));
  };

  const saveAndReset = (args) => {
    setSaveContinueSpinner(true);
    return saveAndContinue(args)
      .then(() => {
        alertToast('Created', 'success');
        reset();
      })
      .finally(() => setSaveContinueSpinner(false));
  };

  const loadPageOptions = async (searchValue) => {
    if (searchValue && (search !== searchValue)) {
      setSearch(searchValue);
      const searchResult = await calculationsService
        .getAllSfpsForDropdown({
          productName: searchValue, itemsPerPage: 30, 'order[productName]': 'asc',
        });
      setList(searchResult.data);
      setPage(searchResult.nextPage);
      return {
        options: searchResult.data || [],
        hasMore: searchResult.nextPage,
      };
    }
    if (page) {
      const values = await calculationsService.getNextPageByFolder(page);
      setList(values.data);
      setPage(values.nextPage);
      return {
        options: values.data || [],
        hasMore: values.nextPage,
      };
    }
    if (!page) setPage(calculations?.result?.nextPage);
    return {
      options: [],
      hasMore: page || calculations?.result?.nextPage,
    };
  };

  return (
    <>
      <G.FormContainer isHidden={isHidden} style={{ margin: '0' }}>
        <Form onSubmit={handleSubmit(save)}>
          <G.GroupFieldsDivider />
          <G.GroupFields>
            <G.GroupFieldsHeader>{t('semi finshed product details')}</G.GroupFieldsHeader>
            <Field
              component={FormInput}
              name="amount"
              namespace="inputs"
              props={{ type: 'decimal', min: 1 }}
              validate={[v.required, v.checkPositive]}
            />

            {scalable && (
              <Field
                component={FormSelect}
                name="scalingType"
                props={{
                  getOptionLabel: ({ label }) => t(`${label}`),
                  options: scalingOptions,
                  isSearchable: false,
                }}
                validate={[v.required, v.scalingType]}
              />
            )}

            <Field
              component={FormAsyncSelect}
              isLoading={calculations.loading}
              name="semiFinishedProduct"
              namespace="inputs"
              props={{
                options: _.sortBy(list, s => s?.productName?.toLowerCase()),
                defaultValue: semiFinishedProduct,
                loadOptions: loadPageOptions,
                debounceTimeout: 200,
                getOptionLabel: l => l.productName || 'Non named',
                getOptionValue: l => l['@id'],
              }}
              validate={[v.required]}
            />
          </G.GroupFields>
          <G.GroupFields>
            <G.ButtonsWrapper>
              <Button
                disabled={submitting}
                loading={saveSpinner}
                style={{ marginRight: '1rem', minWidth: '9.8rem' }}
                styling="select"
                type="submit">
                {t('save')}
              </Button>
              {!inputId && (
                <Button
                  disabled={submitting}
                  loading={saveContinueSpinner}
                  onClick={handleSubmit(saveAndReset)}
                  style={{ marginRight: '1rem' }}
                  styling="underline">
                  {t('Add other')}
                </Button>
              )}
              <Button
                onClick={() => h.push(`/calculation/${id}/inputs/list`)}
                styling="underline">
                {t('cancel')}
              </Button>
            </G.ButtonsWrapper>
          </G.GroupFields>
        </Form>
      </G.FormContainer>
      <MainModal
        content="prompt"
        exitModalContent={{
          navigateSideEffects: () => null,
        }}
        prompt
        promptCondition={!pristine && !hasEditedForm}
      />
    </>
  );
};

SemifinishedForm = reduxForm({
  form: FORM_NAME,
  enableReinitialize: true,
})(SemifinishedForm);

export default Wrapper;
