import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setBreadcrumb } from 'state/sidebar/sidebar.actions';
import { setCalculationTopic, setUpdateCalculationMercure } from 'state/mercure/mercure.actions';
import { useParams, Link, Redirect } from 'react-router-dom';
import * as S from 'routes/GlobalStyle.styled';
import { useTranslation } from 'react-i18next';
import Button from 'components/Button/Button';
import AsideLayout from 'components/AsideLayout/AsideLayout';
import arrowIcon from 'assets/ArrowSelection.svg';
import fail from 'assets/imports/fail.svg';
import success from 'assets/imports/success.svg';
import ValidationFailed from 'routes/Calculations/Import/ValidationFailed';
import { imports as importsService } from 'services';
import { Placeholder } from 'components/Splash/Loading';
import moment from 'moment';
import { reduxForm, Form } from 'redux-form';
import { downloadFile } from 'utils';
import Spinner from 'components/Splash/Spinner';
import * as Style from './Import.style';
import { canImportCalculations } from '../../../utils/featureAccess';

const Import = ({
  handleSubmit,
  submitting,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [cancelLoading, setCancelLoading] = useState(false);
  const [importValue, setImport] = useState(null);
  const [logs, setLogs] = useState(null);
  const importRef = useRef(null);

  const updateMercure = (data) => {
    const inProgress = ((importRef.current?.state === 'import_completed')
      || (importRef.current?.state === 'validation_completed')
      || (importRef.current?.state === 'validation_failed')
      || (importRef.current?.state === 'import_failed'))
      ? {
        ...importRef.current,
        progress: data?.progress,
        state: data?.state,
      } : data;
    const res = {
      ...inProgress,
      originalFilename: importRef.current?.originalFilename,
      contentUrl: importRef.current?.contentUrl,
    };
    importRef.current = res;
    setImport(res);
  };

  useEffect(() => {
    if (importValue?.originalFilename) dispatch(setBreadcrumb(importValue?.originalFilename));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [importValue]);

  useEffect(() => {
    setLoading(true);
    importsService.getCalculationImport(id).then(values => {
      importsService.getImportFile(values?.upload['@id'])
        .then(res => {
          const obj = {
            ...values,
            originalFilename: res.originalFilename,
            contentUrl: res.contentUrl,
          };
          dispatch(setCalculationTopic(obj));
          dispatch(setUpdateCalculationMercure(updateMercure));
          if (values?.state === 'new') {
            importsService.getCalculationImportValidate(id);
          }
          importRef.current = obj;
          setImport(obj);
        }).finally(() => setLoading(false));
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    const logsTemp = importRef.current?.logEntries;
    // eslint-disable-next-line no-unused-expressions
    logsTemp?.reverse();
    dispatch(setCalculationTopic(importRef.current));
    dispatch(setUpdateCalculationMercure(updateMercure));
    setImport(importRef.current);
    setLogs(logsTemp);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [importValue]);

  useEffect(() => () => {
    // CLean up mercure leak
    dispatch(setCalculationTopic(null));
    dispatch(setUpdateCalculationMercure(null));
  },
  [dispatch]);

  if (!canImportCalculations(user)) return <Redirect to="/" />;

  const runValidate = () => importsService.getCalculationImportValidate(id);

  const runImport = () => importsService.getCalculationImportedFile(id);

  const runCancel = () => {
    setCancelLoading(true);
    importsService.getCalculationImportCanceled(id).finally(() => setCancelLoading(false));
  };

  const ValidationInProgress = () => (
    <S.Background isEditable style={{ paddingBottom: 0, height: '100%' }}>
      <S.Bar>{`${t('validation in progress')}...`}</S.Bar>
      <S.FormContainer style={{ marginTop: '1.6875rem' }}>
        <div
          style={{
            display: 'flex-inline',
            justifyContent: 'space-between',
            margin: '1.8rem 0 6.25rem',
            flexDirection: 'column',
          }}>
          <Style.Label>
            {t('file to import.label')}
          </Style.Label>
          <Style.Value>
            {importValue?.originalFilename}
          </Style.Value>
          <Button
            disabled
            style={{ minWidth: '9.87rem' }}
            styling="select"
            type="submit">
            {`${t('validation in progress')}...`}
          </Button>
        </div>
      </S.FormContainer>
    </S.Background>
  );

  const ImportInvalidated = () => (
    <S.Background isEditable style={{ paddingBottom: 0, height: '100%' }}>
      <S.Bar>{t('import_failed')}</S.Bar>
      <S.FormContainer style={{ marginTop: '1.6875rem' }}>
        <div
          style={{
            display: 'flex-inline',
            justifyContent: 'space-between',
            margin: '1.8rem 0 6.25rem',
            flexDirection: 'column',
          }}>
          <Style.Label>
            {t('file to import.label')}
          </Style.Label>
          <Style.Value>
            {importValue?.originalFilename}
          </Style.Value>
          <div style={{ display: 'flex' }}>
            <Form onSubmit={handleSubmit(runValidate)}>
              <Button
                loading={submitting}
                style={{ minWidth: '9.87rem', marginRight: '30px' }}
                styling="select"
                type="submit">
                {t('start validation')}
              </Button>
            </Form>
            <Button
              loading={cancelLoading}
              onClick={runCancel}
              style={{ minWidth: '9.87rem' }}
              styling="underline">
              {t('cancel import')}
            </Button>
          </div>
        </div>
      </S.FormContainer>
    </S.Background>
  );

  const ValidationComplete = () => (
    <S.Background isEditable style={{ paddingBottom: 0, height: '100%' }}>
      <S.Bar>{t('confirm import')}</S.Bar>
      <S.FormContainer style={{ marginTop: '1.6875rem' }}>
        <div
          style={{
            display: 'flex-inline',
            justifyContent: 'space-between',
            margin: '1.8rem 0 6.25rem',
            flexDirection: 'column',
          }}>
          <div style={{
            display: 'flex',
          }}>
            <div style={{ marginRight: '12rem' }}>
              <Style.Label>
                {t('calculations')}
              </Style.Label>
              <Style.Value>
                {importValue?.validatedNewProductCalculations}
              </Style.Value>
              <Style.Label>
                {t('Semi Finished Products')}
              </Style.Label>
              <Style.Value>
                {importValue?.validatedNewSemiFinishedProducts}
              </Style.Value>
              <Style.Label>
                {t('import folder')}
              </Style.Label>
              <Style.Value>
                {importValue?.folder?.name}
              </Style.Value>
            </div>
          </div>
          <div style={{ display: 'flex' }}>
            <Form onSubmit={handleSubmit(runImport)}>
              <Button
                loading={submitting}
                style={{ minWidth: '9.87rem', marginRight: '30px' }}
                styling="select"
                type="submit">
                {t('run import')}
              </Button>
            </Form>
            <Button
              loading={cancelLoading}
              onClick={runCancel}
              style={{ minWidth: '9.87rem' }}
              styling="underline">
              {t('cancel import')}
            </Button>
          </div>
        </div>
      </S.FormContainer>
    </S.Background>
  );

  const ImportInProgress = () => (
    <S.Background isEditable style={{ paddingBottom: 0, height: '100%' }}>
      <S.Bar>{`${t('import in progress')}...`}</S.Bar>
      <S.FormContainer style={{ marginTop: '1.6875rem' }}>
        <div
          style={{
            display: 'flex-inline',
            justifyContent: 'space-between',
            margin: '1.8rem 0 6.25rem',
            flexDirection: 'column',
          }}>
          <div style={{
            display: 'flex',
          }}>
            <div style={{ marginRight: '12rem' }}>
              <Style.Label>
                {t('calculations')}
              </Style.Label>
              <Style.Value>
                {importValue?.validatedNewProductCalculations}
              </Style.Value>
              <Style.Label>
                {t('Semi Finished Products')}
              </Style.Label>
              <Style.Value>
                {importValue?.validatedNewSemiFinishedProducts}
              </Style.Value>
              <Style.Label>
                {t('import folder')}
              </Style.Label>
              <Style.Value>
                {importValue?.folder?.name}
              </Style.Value>
            </div>
          </div>
          <Button
            disabled
            style={{ minWidth: '9.87rem', marginRight: '30px' }}
            styling="select"
            type="submit">
            {`${t('import in progress')}...`}
          </Button>
        </div>
      </S.FormContainer>
    </S.Background>
  );

  const ValidationInProgressSidebar = () => (
    <>
      <p>{`${t('validation in progress')}...`}</p>
      <p style={{
        fontSize: '12px',
        lineHeight: '21px',
      }}>
        {t('upload is validating')}
      </p>
      <div style={{
        border: '1px solid #EBEBEB',
        boxSizing: 'border-box',
        height: '42px',
        display: 'flex',
        alignItems: 'center',
        marginTop: '30px',
      }}>
        <div
          style={{
            height: '42px', width: `${importValue?.progress}%`, backgroundColor: '#FECF07',
          }}
        />
        <div style={{
          position: 'absolute',
          left: '48%',
        }}>
          {`${importValue?.progress}%`}
        </div>
      </div>
    </>
  );

  const ImportInProgressSidebar = () => (
    <>
      <p>{`${t('import in progress')}...`}</p>
      <p style={{
        fontSize: '12px',
        lineHeight: '21px',
      }}>
        {t('upload is being imported')}
      </p>
      <div style={{
        border: '1px solid #EBEBEB',
        boxSizing: 'border-box',
        height: '42px',
        display: 'flex',
        alignItems: 'center',
        marginTop: '30px',
      }}>
        <div
          style={{
            height: '42px', width: `${importValue?.progress}%`, backgroundColor: '#FECF07',
          }}
        />
        <div style={{
          position: 'absolute',
          left: '48%',
        }}>
          {`${importValue?.progress}%`}
        </div>
      </div>
    </>
  );

  const ImportCanceled = () => (
    <S.Background isEditable style={{ paddingBottom: 0, backgroundColor: 'white' }}>
      <S.Bar>{t('import_canceled')}</S.Bar>
      <S.FormContainer style={{ marginTop: '1.6875rem' }}>
        <div style={{ display: 'flex' }}>
          <div style={{ marginRight: '12rem' }}>
            <Style.LabelRegular>{t('filename')}</Style.LabelRegular>
            <Style.ValueRegular>{importValue?.originalFilename}</Style.ValueRegular>
          </div>
          <div>
            <Style.LabelRegular>{t('import_canceled')}</Style.LabelRegular>
            {importValue?.updatedAt
              && (
                <Style.ValueRegular>
                  {moment(importValue?.updatedAt).format('DD-MM-YYYY HH:mm')}
                </Style.ValueRegular>
              )}
          </div>
        </div>
      </S.FormContainer>
    </S.Background>
  );

  const ImportCompleted = () => (
    <S.Background isEditable style={{ paddingBottom: 0, backgroundColor: 'white' }}>
      <S.Bar>{t('results')}</S.Bar>
      <S.FormContainer style={{ marginTop: '1.6875rem' }}>
        <div style={{ display: 'flex' }}>
          <div style={{ marginRight: '12rem' }}>
            <Style.LabelRegular>{t('imported file')}</Style.LabelRegular>
            <Style.ValueRegular>{importValue?.originalFilename}</Style.ValueRegular>
            <Style.LabelRegular>{t('calculations')}</Style.LabelRegular>
            <Style.ValueRegular>{importValue?.createdProductCalculations}</Style.ValueRegular>
            <Style.LabelRegular>{t('Semi Finished Products')}</Style.LabelRegular>
            <Style.ValueRegular>{importValue?.createdSemiFinishedProducts}</Style.ValueRegular>
            <Style.LabelRegular>{t('import folder')}</Style.LabelRegular>
            <Style.ValueRegular>{importValue?.folder?.name}</Style.ValueRegular>
          </div>
        </div>
      </S.FormContainer>
    </S.Background>
  );

  return (
    <AsideLayout
      LeftPane={(
        <div>
          <S.LinksWrapper
            style={importValue?.state?.includes('started') ? {} : {
              border: 'unset',
              paddingBottom: 'unset',
            }}>
            <p style={{
              fontWeight: '500',
              fontSize: '1.33rem',
              marginTop: '-10px',
              marginBottom: '8px',
            }}>
              {t('import calculation.label')}
            </p>
            <Placeholder loaders={[loading]}>
              {(importValue?.state === 'validation_started' || importValue?.state === 'new')
                && <ValidationInProgressSidebar />}
              {importValue?.state === 'import_started' && <ImportInProgressSidebar />}
            </Placeholder>
          </S.LinksWrapper>
          {logs?.map((l, i) => (
            <S.LinksWrapper
              key={i}
              isFirst={l?.state?.includes('started') ? false : (i === 0)}>
              {(l?.state === 'validation_started' || l?.state === 'import_started' || (i !== 0))
                && (
                  <p>{t(l?.state)}</p>
                )}
              {(l?.state === 'validation_failed' || l?.state === 'import_failed') && (i === 0)
                && (
                  <div style={{ display: 'flex' }}>
                    <S.Icon src={fail} style={{ transform: 'unset', marginRight: '3px' }} />
                    <p>{t(l?.state)}</p>
                  </div>
                )}
              {l?.state === 'validation_completed' && (i === 0)
                && (
                  <div style={{ display: 'flex' }}>
                    <S.Icon src={success} style={{ transform: 'unset', marginRight: '3px' }} />
                    <p>{t(l?.state)}</p>
                  </div>
                )}
              {l?.state === 'import_completed' && (i === 0)
                && (
                  <div style={{ display: 'flex' }}>
                    <S.Icon src={success} style={{ transform: 'unset', marginRight: '6px' }} />
                    <p>{t(l?.state)}</p>
                  </div>
                )}
              {l?.state === 'import_canceled' && (i === 0)
                && (
                  <div style={{ display: 'flex' }}>
                    <S.Icon
                      src={fail}
                      style={{ transform: 'unset', marginRight: '6px', marginBottom: '6px' }}
                    />
                    <p>{t(l?.state)}</p>
                  </div>
                )}
              {l?.username && <Style.Author>{`${t('by')} ${l?.username}`}</Style.Author>}
              <Style.Date>{moment(l?.createdAt).format('DD-MM-YYYY HH:mm')}</Style.Date>
              {l?.state === 'validation_failed' && (i === 0)
                && (
                  <>
                    <p style={{ marginTop: '16px' }}>{t('file contains errors')}</p>
                    <Button
                      as={Link}
                      style={{ padding: '0', marginTop: '16px' }}
                      styling="underline"
                      to="/calculations/import/new">
                      {t('upload new file')}
                    </Button>
                  </>
                )}
              {l?.state === 'validation_completed' && (i === 0)
                && (
                  <p style={{ marginTop: '16px' }}>{t('validated with success')}</p>
                )}
              {l?.state === 'import_failed' && (i === 0)
                && (
                  <p style={{ marginTop: '16px' }}>{t('import has been invalidated')}</p>
                )}
              {l?.state === 'import_completed' && (i === 0)
                && (
                  <p style={{ marginTop: '16px' }}>{t('import has been imported with success')}</p>
                )}
              {l?.state === 'validation_started' && importValue?.contentUrl
                && (
                  <S.Download
                    onClick={() => {
                      setDownloadLoading(true);
                      downloadFile(
                        `${process.env.REACT_APP_API_URL}${importValue?.contentUrl}`,
                        importValue?.originalFilename,
                        setDownloadLoading,
                      );
                    }}>
                    <div style={{
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}>
                      {importValue?.originalFilename}
                    </div>
                    {downloadLoading ? <S.Icon as={Spinner} /> : <S.Icon src={arrowIcon} />}
                  </S.Download>
                )}
            </S.LinksWrapper>
          ))}
        </div>
    )}>
      <Placeholder loaders={[loading]}>
        {(importValue?.state === 'validation_started' || importValue?.state === 'new')
          && <ValidationInProgress />}
        {importValue?.state === 'validation_failed'
          && <ValidationFailed errors={importValue?.validationErrors} />}
        {importValue?.state === 'validation_completed' && <ValidationComplete />}
        {importValue?.state === 'import_started' && <ImportInProgress />}
        {importValue?.state === 'import_failed' && <ImportInvalidated />}
        {importValue?.state === 'import_canceled' && <ImportCanceled />}
        {importValue?.state === 'import_completed' && <ImportCompleted />}
      </Placeholder>
    </AsideLayout>
  );
};

export default reduxForm({
  form: 'import',
  enableReinitialize: true,
})(Import);
