import React, { useState, memo } from 'react';
import ReactModal from 'react-modal';
import _ from 'lodash';

import closeIcon from 'assets/close2.svg';

import Profiles from 'routes/Profiles/Overview.modal';

import Button from 'components/Button/Button';
import * as S from 'components/Modal/styled';
import { withLabels } from 'components/Field';

ReactModal.setAppElement('#root');

const EnvironmentProfiles = ({ placeholder, input: { value, ...input } }) => {
  const [isOpen, toggleOpen] = useState(false);

  const shouldClose = e => {
    input.onBlur(e);
    toggleOpen(false);
  };

  const shouldOpen = e => {
    input.onFocus(e);
    toggleOpen(true);
  };

  const selectProfile = p => {
    const profile = _.clone(p);
    input.onChange(profile);
    shouldClose();
  };

  return (
    <>
      <Button onClick={shouldOpen} styling="white">
        {value
          ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                textAlign: 'left',
              }}>
              <p>{`ID: ${value?.code}`}</p>
              <p>{`Name: ${value?.name}`}</p>
              <p>{`Process used: ${value?.processUsed}`}</p>
              {value?.unit && <p>{`Unit: ${value?.unit}`}</p>}
            </div>
          )
          : placeholder}
      </Button>

      <ReactModal
        isOpen={isOpen}
        onRequestClose={shouldClose}
        shouldCloseOnEsc
        shouldCloseOnOverlayClick
        shouldFocusAfterRender
        shouldReturnFocusAfterClose
        style={{
          overlay: S.reactModalStyles.overlay,
          content: {
            ...S.reactModalStyles.content,
            overflow: 'hidden',
          },
        }}>
        <S.Header>
          <p style={{
            marginRight: '1.4rem',
            fontSize: '18px',
            fontWeight: '500',
          }}>
            {placeholder}
          </p>

          <S.Close
            onClick={shouldClose}
            type="button">
            <img alt="close" src={closeIcon} />
          </S.Close>
        </S.Header>

        <Profiles onSelect={selectProfile} />
      </ReactModal>
    </>
  );
};

export default memo(withLabels(EnvironmentProfiles));
