import React from 'react';
import { PredefinedInputSetDetails } from '../Details/PredefinedInputSetDetails';
import { SemiFinishedRow } from '../Rows/SemiFinishedRow';
import { useResolveUnit } from '../../../../../../components/FunctionalUnit/FunctionalUnit.hook';

const FromSfpUsage = ({ sfpUsage, forceExpanded }) => {
  const unit = useResolveUnit({
    calculationType: 'product', standardUnit: sfpUsage.standardUnit,
  });
  return (
    <SemiFinishedRow
      key={sfpUsage.id}
      blueBackground
      forceExpanded={forceExpanded}
      forSubInputs
      funcUnit={unit || ''}
      product={sfpUsage}>
      <div style={{ paddingBottom: '1.1px' }}>
        {sfpUsage.predefinedInputSetUsages.map(pdisUsage => (
          <PredefinedInputSetDetails
            key={pdisUsage.id}
            forceExpanded={forceExpanded}
            pdis={{
              ...pdisUsage.predefinedInputSet,
              amount: pdisUsage.amount,
            }}
          />
        ))}
      </div>
    </SemiFinishedRow>
  );
};

export const PredefinedInputSetsList = ({
  pdisUsages, sfpUsages, forceExpanded, updateContext,
}) => (
  <>
    {sfpUsages?.filter(sfpUsage => sfpUsage.predefinedInputSetUsages?.length > 0)
      .map(sfpUsage => (
        <FromSfpUsage
          key={sfpUsage.id}
          forceExpanded={forceExpanded}
          sfpUsage={sfpUsage}
        />
      ))}
    {pdisUsages.map(usage => (
      <PredefinedInputSetDetails
        editable
        forceExpanded={forceExpanded}
        pdis={{
          ...usage.predefinedInputSet,
          amount: usage?.amount,
          id: usage?.id,
        }}
        updateContext={updateContext}
      />
    ))}
  </>
);
