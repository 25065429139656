import React, {
  useCallback, useContext, useEffect, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { setBreadcrumb } from 'state/sidebar/sidebar.actions';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { calculations as calculationService } from 'services';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Calculation, useCanEdit } from 'hooks/calculation.hooks';
import { useInputsContext } from 'hooks/input.hooks';

import { Placeholder } from 'components/Splash/Loading';
import Button from 'components/Button/Button';
import Switch from 'components/SwitchToggle/SwitchToggle';

import * as S from 'routes/GlobalStyle.styled';
import Spinner from 'components/Spinner';
import CalculationInputItem from './components/CalculationInputItem';
import { SemiFinishedProductDetailsList }
  from './components/SemiFinished/SemiFinishedProductDetailsList';
import { PredefinedInputSetsList } from './components/PredefinedInputSets/PredefinedInputSetsList';
import { SemiFinishedProductInputs } from './components/SemiFinished/SemiFinishedProductInputs';
import { PredefinedInputSetInputs }
  from './components/PredefinedInputSets/PredefinedInputSetInputs';
import { useInputsByStage } from './inputs.helpers';

const Inputs = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { result: calculation, execute: refreshCalculation } = useContext(Calculation);
  const inputsContext = useInputsContext();

  const [allInputs, setAllInputs] = React.useState(null);

  const { pdisUsages } = inputsContext;
  const sfpUsagesWithPdis = inputsContext.sfpUsages?.filter(
    usage => usage.predefinedInputSetUsages?.length
  );

  const canEdit = useCanEdit();

  const [withDetails, setDetails] = useState(!canEdit);
  useEffect(() => { setDetails(!canEdit); }, [canEdit]);
  const isSemi = calculation?.calculationType === 'semi_finished_product';

  const loaders = [inputsContext];
  const [inputsList, setInputs] = useState(null);
  const [page, setPage] = useState(null);

  const [loading, setLoading] = useState(false);
  const loadingRef = React.useRef(false);

  const inputsByStage = useInputsByStage(inputsContext.stages,
    inputsContext.sfpUsages,
    inputsContext.pdisUsages,
    inputsContext.isPdis ? inputsList : inputsContext?.inputs,
    isSemi);

  useEffect(() => {
    // For pdis, reset content when props change
    if (inputsContext.isPdis && inputsContext.inputs) {
      setAllInputs(inputsContext.inputs);
      setInputs(inputsContext.inputs);
      setPage(inputsContext?.inputsNextPage);
    }
    // eslint-disable-next-line
  }, [inputsContext.isPdis, inputsContext?.inputs, inputsContext.calculationId]);

  useEffect(() => {
    // For calculation, when inputsContext changes
    if (inputsContext?.inputs) {
      setAllInputs(inputsContext?.inputs);
      setInputs(inputsContext?.inputs);
      setPage(inputsContext?.inputsNextPage);
    }
  }, [inputsContext, inputsContext.stages,
    inputsContext.sfpUsages]);

  const toggleDetails = () => setDetails(!withDetails);

  useEffect(() => {
    if (calculation?.productName) dispatch(setBreadcrumb(calculation?.productName));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calculation]);

  useEffect(() => {
    // TODO Figure out when to call this....
    // if (calculation) semifinished.execute();
    // if (calculation) calculationInputsContext.execute();
    // if (calculation) calculationInputsContext.pdis.execute();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchData = () => {
    if (page && !loadingRef.current) {
      loadingRef.current = true;
      setLoading(true);
      calculationService
        .getNextPageByFolder(page)
        .then((values) => {
          const newList = inputsList.concat(values.data);
          setPage(values.nextPage);
          setInputs(newList);
        })
        .finally(() => {
          loadingRef.current = false;
          setLoading(false);
        });
    }
  };

  const updateContextForSfp = useCallback(
    () => inputsContext.refresh(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [inputsContext.refresh],
  );

  const updateContextForPDIS = useCallback(
    () => {
      inputsContext.refresh();
      return refreshCalculation?.();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [inputsContext.refresh],
  );

  const updateContextForInput = useCallback(() => {
    inputsContext.refresh();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputsContext.refresh]);

  return (
    <>
      <S.HeaderTitle
        style={{
          margin: '0',
          width: '100%',
          transition: '1s',
        }}>
        {t('Inputs')}
        <div style={{
          display: 'flex',
          alignItems: 'center',
        }}>
          {canEdit && (
          <Switch
            checked={withDetails}
            label={t('View mode')}
            onChange={toggleDetails}
          />
          )}
          {canEdit && (
            <Button
              as={Link}
              style={{ width: 'fit-content', marginLeft: '17px' }}
              styling="select"
              to="add-input">
              {t('add new')}
            </Button>
          )}
        </div>
      </S.HeaderTitle>
      <Placeholder loaders={[!allInputs]}>
        <S.OverviewRows id="overviewRowsId">
          <S.Background>
            <div style={{ marginTop: '1rem' }}>
              {!isSemi && inputsContext.sfpUsages
              && inputsContext.sfpUsages?.length ? (
                <>
                  <S.SectionWrapper>
                    <Placeholder loaders={loaders}>
                      <S.SectionTitile>
                        {t('semi_finished_product', { count: 0 })}
                      </S.SectionTitile>
                      <SemiFinishedProductDetailsList
                        forceExpanded={withDetails}
                        sfpUsages={inputsContext.sfpUsages}
                        updateContext={updateContextForSfp}
                      />
                    </Placeholder>

                    {!withDetails && (
                    <Button
                      as={Link}
                      style={{
                        width: 'fit-content',
                        background: '#FDFEFF',
                        border: '1px solid #DCDCDC',
                        boxSizing: 'border-box',
                        boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.1)',
                        borderRadius: '2px',
                      }}
                      styling="select"
                      to="add-input?stage=0">
                      {t('add new')}
                    </Button>
                    )}
                  </S.SectionWrapper>
                  <S.SectionDivider />
                </>
                ) : null}

              {pdisUsages?.length || sfpUsagesWithPdis?.length ? (
                <>
                  <S.SectionWrapper>
                    <Placeholder loaders={loaders}>
                      <>
                        <S.SectionTitile>
                          {t('predefined-input-sets')}
                        </S.SectionTitile>
                        <PredefinedInputSetsList
                          forceExpanded={withDetails}
                          pdisUsages={pdisUsages}
                          sfpUsages={inputsContext.sfpUsages}
                          updateContext={updateContextForPDIS}
                        />
                      </>
                    </Placeholder>

                    {!withDetails && (
                    <Button
                      as={Link}
                      style={{
                        width: 'fit-content',
                        background: '#FDFEFF',
                        border: '1px solid #DCDCDC',
                        boxSizing: 'border-box',
                        boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.1)',
                        borderRadius: '2px',
                      }}
                      styling="select"
                      to="add-input?stage=pdis">
                      {t('add new')}
                    </Button>
                    )}
                  </S.SectionWrapper>
                  <S.SectionDivider />
                </>
              ) : null}

              {(!inputsByStage) || (inputsContext?.loading)
                ? (
                  <div
                    style={{
                      height: 'calc(100vh - 4rem)',
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}>
                    <Spinner />
                  </div>
                )
                : (
                  <InfiniteScroll
                    dataLength={inputsByStage.reduce((acc, curr) => acc + curr.inputCount, 0)}
                    hasMore={page}
                    loader={<Spinner />}
                    next={fetchData}
                    scrollableTarget="overviewRowsId"
                    scrollThreshold={0.4}
                    style={{ overflow: 'unset' }}>
                    {inputsByStage?.map(({
                      stage, semiFinishedUsages,
                      predefinedInputSetUsages, inputs: stageInputs, inputCount,
                    }) => (
                      <React.Fragment key={stage.id}>
                        <S.SectionWrapper>
                          <S.SectionTitile>
                            <h4>{t(`${stage.code}.label`)}</h4>
                            <legend>
                              {t('item', {
                                count: inputCount,
                              })}
                            </legend>
                          </S.SectionTitile>

                          <S.Description>
                            {!t(`${stage.code}.hint`).endsWith('.hint') && (
                            <blockquote>{t(`${stage.code}.hint`)}</blockquote>
                            )}
                          </S.Description>

                          <Placeholder loaders={[...loaders, loading]}>
                            {semiFinishedUsages
                              ?.map(({ sfpUsage, ...rest }) => (
                                <SemiFinishedProductInputs
                                  key={sfpUsage.id}
                                  forceExpanded={withDetails}
                                  sfpUsage={sfpUsage}
                                  updateContext={null}
                                  {...rest}
                                />
                              ))}
                            {predefinedInputSetUsages?.map(pdisUsage => (
                              <PredefinedInputSetInputs
                                key={pdisUsage.id}
                                amount={pdisUsage.amount}
                                blueBackground
                                forceExpanded={withDetails}
                                inputs={pdisUsage.inputs}
                                {...pdisUsage.predefinedInputSet}
                              />
                            ))}

                            {stageInputs?.map(input => (
                              <CalculationInputItem
                                key={input?.id}
                                expandDetails={withDetails}
                                input={input}
                                updateContext={updateContextForInput}
                              />
                            ))}
                          </Placeholder>

                          {!withDetails && (
                          <Button
                            as={Link}
                            style={{
                              width: 'fit-content',
                              background: '#FDFEFF',
                              border: '1px solid #DCDCDC',
                              boxSizing: 'border-box',
                              boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.1)',
                              borderRadius: '2px',
                            }}
                            styling="select"
                            to={`add-input?stage=${stage.id}`}>
                            {t('add new')}
                          </Button>
                          )}
                        </S.SectionWrapper>
                        <S.SectionDivider />
                      </React.Fragment>
                    ))}
                  </InfiniteScroll>
                )}
            </div>
          </S.Background>
        </S.OverviewRows>
      </Placeholder>
    </>
  );
};

export default Inputs;
