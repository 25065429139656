import styled, { css } from 'styled-components/macro';

export const InputContainer = styled.div`
  font-size: 0.85rem;
  position: relative;
  width: 100%;
  button {
    cursor: pointer;
    position: absolute;
    top: .95rem;
    right: 1rem;
    border: none;
    background: none;
  }
`;

export const Input = styled.input`
  font-size: 14px;
  padding: 0.62rem 1rem;
  border: 1px solid rgba(0, 0, 0, 0.13);
  width: 100%;
  height: 2.8rem;
  color: rgba(32, 37, 56, 0.5);
  background: white;

  :focus { outline: none; }
  :disabled { border-color: transparent; }

  ${({ error, type }) => error && type !== 'submit' && css`border-bottom: 3px solid #FF4D03`};
  ${({ type }) => type === 'password' && css`padding-right: 2.1rem`};
  ${({ error }) => error && css`color: #EC0A0A`};
`;
