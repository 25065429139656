import _ from 'lodash';
// import { errorToast } from 'utils';
import { alertToast as toast, errorToast } from 'config/toast';

import network from './network';

const hydraMember = ({ 'hydra:member': data }) => data;

export const get = (conf = {}) => {
  const config = _.clone(conf);

  if (!_.has(config, 'params.itemsPerPage')) _.set(config, 'params.itemsPerPage', 9999);

  return network.get('/folders', config).catch((err) => errorToast(err));
};

export const create = (data) => {
  const payload = _.clone(data);
  if (payload.parent) payload.parent = `/2/folders/${payload.parent}`;

  return network.post('/folders', payload).catch((err) => errorToast(err));
};

export const getById = (id) => network.get(`/folders/${id}`).catch((err) => errorToast(err));

export const update = (id, payload) => network
  .put(`/folders/${id}`, payload)
  .then(hydraMember)
  .catch((err) => errorToast(err));

export const remove = (id) => network.delete(`/folders/${id}`).catch((err) => errorToast(err));

export const moveInto = (calculationId, folderId) => network
  .put(`/calculations/${calculationId}`, { folder: folderId })
  .then(() => toast('Moved!', 'success'))
  .catch((err) => errorToast(err));
