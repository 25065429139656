import _ from 'lodash';
import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { setBreadcrumb } from 'state/sidebar/sidebar.actions';
import { Redirect, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { calculations } from 'services';
import { Calculation, useCanEdit } from 'hooks/calculation.hooks';
import { useMethods } from 'hooks/methods.hooks';
import { useWasteRegion } from 'hooks/wasteRegion.hooks';

import { Placeholder } from 'components/Splash/Loading';

import * as S from 'routes/GlobalStyle.styled';
import handleFailSubmit from './handleFailSubmit';
import {
  predefinedConstructionWasteOpt,
  scalingOptions,
  wasteScenarioInputMethodsOpt,
} from './Editor.helpers';
import AdvancedForm from './AdvancedForm';
import { useInputsContext } from '../../../../hooks/input.hooks';

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { result: calculation, execute } = useContext(Calculation);
  const canEdit = useCanEdit();
  const methods = useMethods(calculation);
  const wasteRegions = useWasteRegion(calculation);
  const inputsContext = useInputsContext();

  React.useEffect(() => {
    if (calculation?.productName) dispatch(setBreadcrumb(calculation?.productName));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calculation]);

  const save = (formData) => {
    const semifinished = calculation.calculationType === 'semi_finished_product';
    const payload = {};

    payload.scalingType = formData.scalingType.value;
    if (formData.scalingType.value === 'linear') {
      payload.amountScalingDimensions = formData.amountScalingDimensions.value;
      if (formData.dimension1) payload.dimension1 = Number(formData.dimension1);
      if (formData.dimension2) payload.dimension2 = Number(formData.dimension2);
      payload.descriptionDimension1 = formData.descriptionDimension1;
      if (formData.descriptionDimension2) {
        payload.descriptionDimension2 = formData.descriptionDimension2;
      }
      if (formData.unitDimension1) payload.unitDimension1 = formData.unitDimension1;
      if (formData.unitDimension2) payload.unitDimension2 = formData.unitDimension2;
      if (formData.scalableDimension1) {
        payload.scalableDimension1 = Number(formData.scalableDimension1);
      }
      if (formData.scalableDimension2) {
        payload.scalableDimension2 = Number(formData.scalableDimension2);
      }
    }

    // WasteRegion
    payload.wasteScenarioInputMethod = formData.wasteScenarioInputMethod.toString();
    if (payload.wasteScenarioInputMethod === '2') {
      payload.wasteScenarioProductionWaste = formData.wasteScenarioProductionWasteForm
        && formData.wasteScenarioProductionWasteForm['@id'];
      payload.wasteScenario = formData.wasteScenarioForm && formData.wasteScenarioForm['@id'];
    }
    payload.productionWasteInputMethod = formData.productionWasteInputMethod.value;

    if (formData.productionWastePercentageForm) {
      payload.productionWastePercentage = _.toNumber(formData.productionWastePercentageForm);
    }

    if (!semifinished && !calculation.template) {
      // Only set those fields if not SFP and calculation doesn't have a template (EPD-1168)
      payload.predefinedConstructionWaste = formData.predefinedConstructionWaste.value;
      if (payload.predefinedConstructionWaste === 0) {
        payload.predefinedConstructionWaste = null;
        payload.constructionWaste = parseFloat(formData.constructionWaste);
      } else {
        payload.constructionWaste = payload.predefinedConstructionWaste;
      }
    }

    return calculations
      .save(calculation.id, payload)
      .then(() => {
        execute();
        inputsContext.refresh();
        history.goBack();
      })
      .catch(handleFailSubmit);
  };

  if (!canEdit) return <Redirect to={`/calculation/${calculation.id}/`} />;

  return (
    <S.Background isEditable>
      <S.HeaderTitle>{t('Advanced settings')}</S.HeaderTitle>

      <Placeholder loaders={[wasteRegions]}>
        <AdvancedForm
          initialValues={{
            productType: methods.productType,
            scalingType: _.find(scalingOptions, ({ value }) => calculation.scalingType === value),

            // ScalingExtras
            amountScalingDimensions: {
              value: calculation.amountScalingDimensions || 1,
            },
            descriptionDimension1: calculation.descriptionDimension1,
            unitDimension1: calculation.unitDimension1,
            dimension1: calculation.dimension1,
            scalableDimension1: calculation.scalableDimension1,
            descriptionDimension2: calculation.descriptionDimension2,
            unitDimension2: calculation.unitDimension2,
            dimension2: calculation.dimension2,
            scalableDimension2: calculation.scalableDimension2,

            // Waste
            wasteScenarioProductionWasteForm: wasteRegions.productionWaste,
            wasteScenarioInputMethod: wasteRegions.scenario,
            wasteScenarioForm: wasteRegions.wasteScenario,
            productionWasteInputMethod: _.find(
              wasteScenarioInputMethodsOpt,
              ({ value }) => value === calculation.productionWasteInputMethod,
            ),
            productionWastePercentageForm: wasteRegions.productionWastePercentage,
            predefinedConstructionWaste: wasteRegions.predefinedConstructionWaste
              || predefinedConstructionWasteOpt.find(opt => opt.value === 0),
            constructionWaste: wasteRegions.constructionWaste,
          }}
          isSemi={calculation.calculationType === 'semi_finished_product'}
          methods={methods}
          saveForm={save}
          template={calculation.template}
          wasteRegions={wasteRegions}
        />
      </Placeholder>
    </S.Background>

  );
};
