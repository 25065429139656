import React from 'react';
import {
  useRouteMatch,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';

import View from './ViewAdvanced';
import Editor from './AdvancedSettings';

export default () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route component={() => <Redirect to="advanced/view" />} exact path={path} />
      <Route component={View} exact path={`${path}/view`} />
      <Route component={Editor} exact path={`${path}/edit`} />
    </Switch>
  );
};
