import styled from 'styled-components';
import { InputWidth, DARK_BLUE } from 'config/style';

export const ContentWrapper = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.13);
  box-sizing: border-box;
  margin: 0;
  max-width: 40rem;
  padding: 1rem;
  font-size: .875rem;
`;

export const SimpleText = styled.p`
  color: rgba(0, 0, 0, 0.3);
  font-size: .875rem;
`;

export const ContentWrapperNOBorder = styled.div`
  margin: 0;
  width: ${InputWidth};
  color: ${DARK_BLUE};
  font-size: .875rem;
`;
