import { statusStyle } from 'config/style';

export const mapReviewStatus = ({
  reportable, submittable, state, reviewState,
}) => {
  if (!reportable && !submittable && !reviewState && !state) return 'in-progress';
  if (reportable && !submittable && !reviewState && !state) return 'in-review';
  if (reportable && submittable && !reviewState && !state) return 'valid';
  return reviewState || state;
};

export const mapCalculationStatus = calculation => {
  const status = mapReviewStatus(calculation);

  switch (status) {
    case 'valid': return statusStyle.Valid;
    case 'verified': return statusStyle.Verified;
    case 'approved': return statusStyle.Completed;
    case 'resubmitted':
    case 'submitted':
    case '10': return statusStyle.Submitted;
    case 'accepted': return statusStyle.Review;
    case 'denied':
    case 'rejected': return statusStyle.Rejected;
    case 'declined': return statusStyle.FinalRejected;
    case 'in-review': return statusStyle.Review;
    case 'in-progress': return statusStyle.Progress;
    case 'draft': return statusStyle.Progress;
    case 'incomplete': return statusStyle.Incomplete;
    default: return statusStyle.Progress;
  }
};
