import _ from 'lodash';
import React from 'react';

const Pricing = ({ cost, nilDigit, ...rest }) => (
  <span {...rest}>
    {(cost && `${_.ceil(cost, 3)}`) || (nilDigit || '0')}
  </span>
);

export default Pricing;
