import React, { memo } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { mapCalculationStatus } from 'utils/calculation-statuses';

import * as S from './CalculationCard.styled';

const CalculationCard = ({ data: calculation }) => {
  const status = mapCalculationStatus(calculation);
  const { lastModifiedAt, createdAt, calculationType } = calculation;
  const { t } = useTranslation();

  const iconBackgroundColor = status?.iconColor ? { color: 'white' } : null;

  return (
    <S.CardWrapper
      background={calculationType === 'product' ? '#5885A4' : '#0B0F2F'}
      to={`/calculation/${calculation.id}`}>
      <S.CardHeader>
        <p>{t(calculation.calculationType)}</p>
        <p>{calculation.productName}</p>
      </S.CardHeader>

      <S.CardFooter align="between center" row>
        <S.CardEdit>
          {/* <p>
            Last edited by
            {calculation.owner.name}
          </p> */}
          <p>{moment(lastModifiedAt || createdAt).fromNow()}</p>
          <p>{calculation.identifier}</p>
        </S.CardEdit>

        <S.CardStatus
          buttonBackgroundColor={status.buttonBackgroundColor}
          color={status.color}>
          <status.icon {...iconBackgroundColor} />
          <span>{t(status.text)}</span>
        </S.CardStatus>
      </S.CardFooter>
    </S.CardWrapper>
  );
};

export default memo(CalculationCard);
