import _ from 'lodash';
import React from 'react';
import { Link } from 'react-router-dom';
import { useAsync } from 'react-async-hook';
import { useTranslation } from 'react-i18next';

import { useQuery } from 'utils';
import { calculations as calculationsService } from 'services';

import Button from 'components/Button/Button';
import { ReadOnlyField } from 'components/Field';
import { Placeholder } from 'components/Splash/Loading';
import FunctionalUnit from 'components/FunctionalUnit/FunctionalUnit';
import { ViewModeContainer, TitleBar } from 'routes/GlobalStyle.styled';
import { scalingOptions } from '../Advanced/Editor.helpers';

const ViewInputSemifinished = () => {
  const { t } = useTranslation();
  const inputId = useQuery().get('inputId');
  const input = useAsync(calculationsService.usage, [inputId]);
  const spfRef = input.result?.semiFinishedProduct;
  const semiFinishedProduct = useAsync(calculationsService.getByRef, [spfRef]);

  const scalingTypeName = () => _.find(
    scalingOptions,
    ({ value }) => value === input.result.scalingType,
  ).label;

  return (
    <>
      <TitleBar>
        {t('Input')}

        <Button as={Link} styling="select" to={`edit-input-semifinished?inputId=${inputId}`}>
          {`${t('edit')} ${t('input')}`}
        </Button>
      </TitleBar>

      <Placeholder loaders={[input, semiFinishedProduct]}>
        {() => (
          <ViewModeContainer>
            <ReadOnlyField name="amount" namespace="inputs">
              {input.result.amount}
              {semiFinishedProduct?.result
                && <FunctionalUnit calculation={semiFinishedProduct?.result} />}
            </ReadOnlyField>

            <ReadOnlyField name="scalingType">
              {scalingTypeName()}
            </ReadOnlyField>

            <ReadOnlyField name="semiFinishedProduct" namespace="inputs">
              {semiFinishedProduct.result?.productName}
            </ReadOnlyField>
          </ViewModeContainer>
        )}
      </Placeholder>
    </>
  );
};

export default ViewInputSemifinished;
