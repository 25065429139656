// SVGs
import finalRejected from 'assets/calculationsIcons/FinalRejected';
import progress from 'assets/calculationsIcons/Progress';
import rejected from 'assets/calculationsIcons/Rejected';
import review from 'assets/calculationsIcons/Review';
import submitted from 'assets/calculationsIcons/Submitted';
import valid from 'assets/calculationsIcons/Valid';
import verified from 'assets/calculationsIcons/Verified';

// COLORS
export const YELLOW = '#FECF07';
export const SKY = '#19b5ff';
export const DARK_BLUE = '#202538';
export const DARK_BLUE_400 = '#333950';
export const LIGHT_BLUE = '#F4F8FA';
export const BLUE = '#5885A4';
export const MOSTARD = '#DCB40B';
export const RED = '#BD5230';
export const GREY = '#F4F8FA';
export const GREEN = '#70876E';
export const black = (opacity) => `rgba(0,0,0,${opacity})`;
export const backdrop = black(0.4);

export const DashboardButton = {
  background: '#FDFEFF',
  color: '#202538',
  border: '1px solid #E8EDEF',
  fontWeight: 500,
  boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.1)',
};

export const statusStyle = {
  Draft: {
    id: 'draft',
    color: '#dee3e2',
    icon: finalRejected,
    text: 'Draft',
  },
  Progress: {
    id: 'in-progress',
    buttonBackgroundColor: '#70876E',
    backgroundColor: '#5885A4',
    color: 'white',
    icon: progress,
    iconColor: 'white',
    text: 'In progress',
  },
  Incomplete: {
    id: 'incomplete',
    buttonBackgroundColor: '#BD5230',
    icon: progress,
    iconColor: 'white',
    text: 'Incomplete',
    color: '#dee3e2',
  },
  Valid: {
    id: 'valid',
    color: '#51864C',
    icon: valid,
    text: 'Valid',
  },
  Submitted: {
    id: 'submitted',
    color: '#5885A4',
    icon: submitted,
    text: 'Submitted',
  },
  Review: {
    id: 'in-review',
    color: '#336282',
    icon: review,
    text: 'In review',
  },
  Verified: {
    id: 'verified',
    color: '#51864C',
    icon: verified,
    text: 'Verified',
  },
  Rejected: {
    id: 'rejected',
    color: '#BD5230',
    icon: rejected,
    text: 'Rejected',
  },
  FinalRejected: {
    id: 'declined',
    color: '#BF2E00',
    icon: finalRejected,
    text: 'Final Rejected',
  },
  Completed: {
    id: 'completed',
    color: '#51864C',
    icon: verified,
    text: 'Completed',
  },
};

// Footer Height
export const footerHeight = '6rem';

// Padding Overview rows
export const paddingRows = '1rem 2.25rem';

// Sizes
export const AsideWidth = '25rem';
export const InputHeight = '2.81rem';
export const InputWidth = '22.35rem';
export const FormWidth = 'auto';

// MAX WIDTH
export const MAX_WIDTH = '1280px';

// Navs heigth
export const NAV_HEIGHT = '4rem';

export const styledButton = {
  background: 'white',
  color: DARK_BLUE,
  border: '1px solid #E2E2D2',
  height: '2.81rem',
  minWidth: '11.5rem',
  boxShadow: '0 0 4px rgba(0, 0, 0, 0.1)',
  textAlign: 'center',
};

export const styledButtonForHeaderInViews = {
  background: 'white',
  color: DARK_BLUE,
  height: '2.81rem',
  minWidth: '9.0625rem',
  textAlign: 'center',
  border: '1px solid #FFFFFF',
  boxSizing: 'border-box',
  boxShadow: '0 0 4px rgba(0, 0, 0, 0.1)',
  borderRadius: '2px',
  marginLeft: '2.3rem',
};
