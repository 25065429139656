import React, { useEffect, useState } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import AsideLayout from 'components/AsideLayout/AsideLayout';
import EditProfile from 'routes/MyProfile/EditProfile';
import * as S from 'routes/GlobalStyle.styled';
import Arrow from 'assets/ArrowSelection.svg';
import NewUser from 'routes/Users/NewUser';
import Updates from 'routes/MyProfile/Updates';
import UpdateDetail from 'routes/MyProfile/UpdateDetail';
import Announcements from 'routes/MyProfile/Announcements';
import AnnouncementDetail from 'routes/MyProfile/AnnouncementDetail';
import AccountDetails from './AccountDetails';
import NewCompanyPermissions from '../Users/NewCompanyPermissions';

const MyProfile = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [[page, sub], setCurrentPage] = useState([]);

  useEffect(() => {
    const pathArray = pathname.split('/');
    setCurrentPage([pathArray[1], pathArray[2]]);
  }, [pathname]);

  return (
    <AsideLayout
      LeftPane={(
        <div>
          <S.AsideTitle>
            {t(page)}
          </S.AsideTitle>

          <S.LinksWrapper>
            {page === 'user-profile'
              ? (
                <>
                  <S.AsideLink to="/user-profile">
                    {(!sub || sub === 'edit') && <S.ArrowSelection alt="arrowSelect" src={Arrow} />}
                    {t('account')}
                  </S.AsideLink>
                  <S.AsideLink to="/user-profile/memberships">
                    {sub === 'memberships' && <S.ArrowSelection alt="arrowSelect" src={Arrow} />}
                    {t('memberships')}
                  </S.AsideLink>
                </>
              ) : (
                <>
                  <S.AsideLink to="/updates">
                    {page === 'updates' && <S.ArrowSelection alt="arrowSelect" src={Arrow} />}
                    {t('Updates')}
                  </S.AsideLink>
                  <S.AsideLink to="/announcements">
                    {page === 'announcements'
                      && <S.ArrowSelection alt="arrowSelect" src={Arrow} />}
                    {t('Announcements')}
                  </S.AsideLink>
                </>
              )}
          </S.LinksWrapper>
        </div>
    )}>
      <Switch>
        <Route component={AccountDetails} exact path="/user-profile" />
        <Route component={NewUser} exact path="/user-profile/edit" />
        <Route
          component={NewCompanyPermissions}
          exact
          path={[
            '/user-profile/memberships/new',
            '/user-profile/memberships/:companyId/edit',
          ]}
        />
        <Route component={EditProfile} exact path="/user-profile/memberships/:companyId?" />
        <Route component={Updates} exact path="/updates" />
        <Route component={UpdateDetail} exact path="/updates/:id" />
        <Route component={Announcements} exact path="/announcements" />
        <Route component={AnnouncementDetail} exact path="/announcements/:id" />
      </Switch>
    </AsideLayout>
  );
};

export default MyProfile;
