import _ from 'lodash';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useQuery } from 'utils';
import { Calculation, useCanEdit } from 'hooks/calculation.hooks';
import { useInput } from 'hooks/input.hooks';

import Button from 'components/Button/Button';
import { ReadOnlyField } from 'components/Field';
import { Placeholder } from 'components/Splash/Loading';

import { ViewModeContainer, TitleBar } from 'routes/GlobalStyle.styled';
import { dataQualityOptions } from './inputs.helpers';

const ViewInput = () => {
  const { t } = useTranslation();
  const inputId = useQuery().get('inputId');

  const calculation = useContext(Calculation);
  const canEdit = useCanEdit();
  const {
    id: calculationId, scalingType,
  } = calculation.result || {};
  const input = useInput(calculationId, inputId);
  const { id: stage = 0 } = input.result?.inputStage || {};
  const stageId = _.parseInt(stage);
  const scalable = scalingType === 2;

  return (
    <>
      <TitleBar>
        {t('Input')}

        {canEdit && (
        <Button as={Link} styling="select" to={`edit-input?inputId=${inputId}`}>
          {`${t('Edit')}`}
        </Button>
        )}
      </TitleBar>

      <Placeholder loaders={[input]}>
        {() => (
          <ViewModeContainer>
            <ReadOnlyField name="description" namespace="inputs">
              {input.result.description}
            </ReadOnlyField>

            <ReadOnlyField name="supplier" namespace="inputs">
              {input.result.supplier.name}
            </ReadOnlyField>

            <ReadOnlyField name="environmentalProfile" namespace="inputs">
              {`[${input.result.environmentalProfile.code}]
            ${input.result.environmentalProfile.name}`}
            </ReadOnlyField>

            <ReadOnlyField name="netAmount" namespace="inputs">
              {`${input.result.netAmount} kg`}
            </ReadOnlyField>

            {scalable && (
            <ReadOnlyField name="linearScalable" namespace="inputs">
              {input.result.linearScalable
                ? t('yes')
                : t('no')}
            </ReadOnlyField>
            )}

            {[3, 4].includes(stageId) ? null : input?.environmentalProfile?.wasteScenarioNeeded && (
              <>
                {calculation.result?.productionWasteInputMethod !== 'based_on_production_site' && (
                  <ReadOnlyField name="productionWaste" namespace="inputs">
                    {`${input.result.productionWaste} %`}
                  </ReadOnlyField>
                )}

                <ReadOnlyField name="wasteScenarioProductionWaste" namespace="inputs">
                  {input.result.wasteScenarioProductionWaste.name}
                </ReadOnlyField>
              </>
            )}

            <ReadOnlyField name="wasteScenario" namespace="inputs">
              {input.result.wasteScenario.name}
            </ReadOnlyField>

            {[4, 3, 5, 6, 7, 8, 11].includes(stageId) ? null : (
              <ReadOnlyField name="lifeTime" namespace="inputs">
                {`${input.result.lifeTime} yr`}
              </ReadOnlyField>
            )}

            {input.result.dataQuality && (
            <ReadOnlyField name="dataQuality" namespace="inputs">
              {_.find(dataQualityOptions, item => item.value === input.result.dataQuality)
                .label}
            </ReadOnlyField>
            )}

            {input.result.dataSource && (
            <ReadOnlyField name="dataSource" namespace="inputs">
              {input.result.dataSource}
            </ReadOnlyField>
            )}

            {input.result.comments && (
            <ReadOnlyField name="comments" namespace="inputs">
              {input.result.comments}
            </ReadOnlyField>
            )}
          </ViewModeContainer>
        )}
      </Placeholder>
    </>
  );
};

export default ViewInput;
