import React from 'react';

const Progress = ({ color, ...props }) => (
  <svg fill="none" height="12" viewBox="0 0 15 12" width="15" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      // eslint-disable-next-line max-len
      d="M15 6H13.1167C13.1167 9.30177 10.6 12 7.48333 12C6.36667 12 5.31667 11.645 4.41667 11.0237L5.55 9.40828C6.11667 9.78106 6.78333 9.99408 7.48333 9.99408C9.55 9.99408 11.2333 8.20118 11.2333 6H9.38333L12.2 2.00592L15 6ZM3.75 6C3.75 3.79882 5.43333 2.00592 7.5 2.00592C8.2 2.00592 8.85 2.23669 9.43333 2.59172L10.5667 0.976331C9.66667 0.35503 8.61667 0 7.5 0C4.4 0 1.86667 2.68047 1.86667 6H0L2.81667 9.99408L5.63333 6H3.75Z"
      fill={color || '#DCB40B'}
    />
  </svg>
);

export default Progress;
