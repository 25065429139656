import _ from 'lodash';
import React, { useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';

import { calculations, folders } from 'services';
import { useCalculationType } from 'hooks/calculation.hooks';
import { useLocations } from 'hooks/location.hooks';
import { useWasteRegion } from 'hooks/wasteRegion.hooks';
import {
  useStandardUnits,
  useCustomUnitForLCA,
  useStandardUnitForLCA,
} from 'hooks/functionalUnit.hooks';
import { Placeholder } from 'components/Splash/Loading';
import AsideLayout from 'components/AsideLayout/AsideLayout';
import { useAsync } from 'react-async-hook';
import * as S from 'routes/GlobalStyle.styled';
import { useCompanies } from 'hooks/companies.hooks';
import handleFailSubmit from './handleFailSubmit';
import BasicsForm from './BasicsForm';

// eslint-disable-next-line import/no-anonymous-default-export
export default (props) => {
  const { folderId } = useParams();
  const h = useHistory();
  const { t } = useTranslation();

  const calculationType = useCalculationType();
  const locationHook = useLocations();
  const wasteRegions = useWasteRegion();
  const standardUnits = useStandardUnits();
  const customUnitForLCA = useCustomUnitForLCA();
  const standardUnitForLCA = useStandardUnitForLCA();
  const { result } = useAsync(() => folderId
    && (folderId !== 'root') && folders.getById(folderId), []);
  const { result: templates, loading } = useAsync(calculations.getTemplates, []);
  const companies = useCompanies();

  const operators = useCompanies({ operator: true });
  const loaderList = [
    loading,
    locationHook,
    wasteRegions,
    standardUnits,
    standardUnitForLCA,
    companies.loading,
    operators.loading,
  ];

  const save = (formData, isCustom, template) => {
    let payload = {};
    if (template) {
      const semifinished = formData.calculationType.value === 'semi_finished_product';
      payload = {
        template: template?.['@id'],
        calculationType: formData.calculationType.value,
        productName: formData.productName,
        code: formData.Code,
        location: template?.location?.['@id'] || template?.location || formData.location['@id'],
        method: template?.method?.['@id'] || template?.method,
        productLifeTime: parseInt(template.productLifeTime, 10) || parseInt(formData.productLifeTime, 10),
        wasteRegion: template?.wasteScenarioSet?.['@id'] || template?.wasteScenarioSet,
        wasteScenarioSet: template?.wasteScenarioSet?.['@id'] || template?.wasteScenarioSet,
      };

      if (!semifinished) {
        payload.productType = template.productType;
        if (template?.productType === '/2/product_types/3') {
          payload.distanceToConstructionSite = _.parseInt(template.distanceToConstructionSite);
          payload.transportProfileToConstructionSite = template.transportProfileToConstructionSite;
        }
        /* if is standard else custom. */
        if (!template?.customFunctionalUnit) {
          payload.functionalUnit = template?.functionalUnit?.['@id'] || template?.functionalUnit;
        } else {
          payload.functionalUnitTitle = template.functionalUnitTitle;
          payload.functionalUnitDescription = template.functionalUnitDescription;
          payload.standardUnit = template?.standardUnit?.['@id'] || template?.standardUnit;
        }
        payload.customFunctionalUnit = template?.customFunctionalUnit;
      } else {
        payload.standardUnit = template?.standardUnit;
      }

      // TODO Handle publisher and operator, in case of calculation with template
    } else {
      const semifinished = formData.calculationType.value === 'semi_finished_product';
      payload = {
        calculationType: formData.calculationType.value,
        productName: formData.productName,
        code: formData.Code,
        location: formData.location['@id'],
        method: formData.method['@id'],
        productLifeTime: parseInt(formData.productLifeTime, 10),
        wasteRegion: formData?.wasteRegion?.['@id'],
        wasteScenarioSet: formData?.wasteRegion?.['@id'],
      };

      if (!formData.method.publisher && formData.publisher['@id']) {
        payload.publisher = formData.publisher['@id'];
      }
      if (!formData.method.operator && formData.operator['@id']) {
        payload.operator = formData.operator['@id'];
      }

      // Functional Units
      if (!semifinished) {
        payload.productType = formData.productType && formData.productType['@id'];
        if (formData?.productType?.id === 3) {
          payload.distanceToConstructionSite = _.parseInt(formData.distanceToConstructionSite);
          payload.transportProfileToConstructionSite = formData
            .transportProfileToConstructionSite['@id'];
        }
        /* if is standard else custom. */
        if (!isCustom) {
          payload.functionalUnit = formData.functionalUnit['@id'];
        } else {
          payload.functionalUnitTitle = formData.functionalUnitTitle;
          payload.functionalUnitDescription = formData.functionalUnitDescription;
          payload.standardUnit = formData.standardUnit['@id'];
        }
        payload.customFunctionalUnit = isCustom;
      } else {
        payload.standardUnit = formData.standardUnit['@id'];
      }
    }

    if (folderId && (folderId !== 'root')) {
      payload.folder = result && result['@id'];
    }

    return calculations
      .create(payload)
      .then((r) => {
        h.push(`/calculation/${r.id}/basics/view`, { routeName: r?.productName });
      })
      .catch(handleFailSubmit);
  };

  const [hidePanel, setHidePanel] = useState(true);

  return (
    <AsideLayout
      hidePanel={hidePanel}
      LeftPane={(
        <p style={{ padding: '2.5rem' }}>
          <Trans i18nKey="hint new calculation">
            To set up your calculation environment we need some information. This information can be
            changed after saving.
          </Trans>
        </p>
      )}
      setHidePanel={setHidePanel}>
      <S.Background isEditable>
        <S.HeaderTitle>{t('new calculation')}</S.HeaderTitle>
        <Placeholder loaders={loaderList}>
          <div style={{ paddingLeft: hidePanel ? '0' : '0', transition: '1s' }}>
            <BasicsForm
              {...props}
              calculationType={calculationType}
              companies={companies.data}
              customUnitForLCA={customUnitForLCA}
              folderId={folderId}
              initialValues={{
                calculationType: calculationType.item,
                location: locationHook.selected,
              }}
              location={locationHook}
              operators={operators.data}
              saveForm={save}
              standardUnitForLCA={standardUnitForLCA}
              standardUnits={standardUnits}
              templates={templates?.data}
              wasteRegions={wasteRegions}
            />
          </div>
        </Placeholder>
      </S.Background>
    </AsideLayout>
  );
};
