/* eslint-disable no-unused-expressions */
import _ from 'lodash';
import React, { memo, useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import {
  setMembership, addMembership, setMembershipsSize as setSize,
} from 'state/user/user.actions';
import { users as usersService, companies } from 'services';
import AddButton from 'components/Button/AddButton/AddButton';
import DropDown from 'components/DropDown/DropDown';
import NavSearch from 'components/NavSearch/NavSearch';

// DropDown content
import AddContent from 'components/DropDownContent/Add/Add';
import HelpDesk from 'components/DropDownContent/HelpDesk/HelpDesk';
// import NotificationsContent from 'components/DropDownContent/Notifications/Notifications';
import UserContent from 'components/DropDownContent/User/User';

import HelpDeskIcon from 'assets/helpdesk.svg';
// import NotificationsIcon from 'assets/notification.svg';
import PlusSign from 'assets/plus_sign.svg';
import Select from 'components/Select/Select';
import { useAsyncCallback } from 'react-async-hook';
import { bold } from 'utils';
import * as S from './Nav.styled';

const allMemberships = { id: 0, label: 'All Memberships', value: 'all' };

const Nav = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const { userToken } = useSelector(({ auth }) => auth);
  const {
    name, id, ...other
  } = useSelector(({ user }) => user);

  const [membershipsSize, setMembershipsSize] = useState(0);
  const [result, setResult] = useState(null);

  const { result: currentCompany, execute: executeCompany } = useAsyncCallback(() => companies
    .getCompany(other?.selectedMembership?.company['@id']));

  useEffect(() => {
    if (other?.selectedMembership?.company) executeCompany();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [other?.selectedMembership]);

  const { execute } = useAsyncCallback(() => usersService.getUserMemberships(id)
    .then(value => {
      console.log('Async callback to get memberships called', value);
      const roles = value.users.map(r => r.roles.map(v => {
        const view = v?.view === 'Super Admin'
          ? v?.view?.replace('Super Admin', 'Ultra Admin') : v?.view;
        return {
          ...r,
          role: v,
          roleName: v?.name,
          view: v?.view,
          label: bold(r?.company?.name, view),
          value: `${r?.company?.name} | ${view}`,
        };
      })).flat();
      let arr = roles?.map(v => {
        const view = v?.role?.view === 'Super Admin'
          ? v?.role?.view?.replace('Super Admin', 'Ultra Admin') : v?.role?.view;
        return {
          ...v,
          roleName: v?.name,
          label: bold(v?.company?.name, view),
          value: `${v?.company?.name} | ${view}`,
        };
      });

      const currentMembership = roles.find(r => r.id === other?.selectedMembership?.id);
      // if (!other?.selectedMembership) dispatch(setMembership(arr[0]));
      const view = currentMembership?.view === 'Super Admin'
        ? currentMembership?.view?.replace('Super Admin', 'Ultra Admin')
        : currentMembership?.view;
      setCompany({
        ...currentMembership,
        label: bold(currentMembership?.company?.name, view),
        value: `${currentMembership?.company?.name} | ${view}`,
      });
      if (other?.isRecentMembership) {
        dispatch(addMembership(false));
      }
      setMembershipsSize(arr?.length);
      dispatch(setSize(arr?.length));
      arr = arr.filter(a => a?.value !== other?.selectedMembership?.value);
      arr.reverse();
      if (currentMembership) {
        arr.unshift({
          ...currentMembership,
          label: bold(currentMembership.company?.name, view),
          value: `${currentMembership.company?.name} | ${view}`,
        });
      }
      arr = arr?.slice(0, 4);
      arr.push(allMemberships);
      setResult(arr);
    }));
  const [company, setCompany] = useState(null);

  useEffect(() => {
    if (!pathname?.includes('/password-reset')
    && !pathname?.includes('/login')
    && !pathname?.includes('/forgot')
    ) execute();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, other?.isRecentMembership]);

  useEffect(() => {
    if (pathname === '/' && userToken) execute();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  useEffect(() => {
    if (other?.selectedMembership && result
      && !result?.some(r => r?.value === other?.selectedMembership?.value)) {
      const tempResult = result;
      const view = other?.selectedMembership?.view === 'Super Admin'
        ? other?.selectedMembership?.view?.replace('Super Admin', 'Ultra Admin')
        : other?.selectedMembership?.view;
      tempResult?.shift();
      tempResult?.unshift({
        ...other?.selectedMembership,
        label: bold(other?.selectedMembership?.company?.name, view),
        value: `${other?.selectedMembership?.company?.name} | ${view}`,
      });
      setResult(tempResult);
    }
    if (`${company?.company?.name} | ${company?.view}`
      !== `${other?.selectedMembership?.company?.name} | ${other?.selectedMembership?.view}`) {
      const view = other?.selectedMembership?.view === 'Super Admin'
        ? other?.selectedMembership?.view?.replace('Super Admin', 'Ultra Admin')
        : other?.selectedMembership?.view;
      if (other?.selectedMembership) {
        setCompany({
          ...other?.selectedMembership,
          label: bold(other?.selectedMembership?.company?.name, view),
          value: `${other?.selectedMembership?.company?.name} | ${view}`,
        });
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company, other]);

  const reviewer = other?.selectedMembership?.roleName === 'ROLE_REVIEWER';

  const companyAdmin = other?.selectedMembership?.view === 'Company Admin'
    || other?.selectedMembership?.view === 'Corporate Admin';

  const superAdmin = other?.selectedMembership?.view === 'Super Admin'
    || other?.selectedMembership?.view === 'Nibe Employee';

  const defaultUserOrAdminForCompany = companyAdmin
    ? { path: '/manage', label: 'Manage Company' }
    : null;

  let links = [
    !currentCompany?.corporation && !reviewer && { path: '/calculations', label: 'calculation' },
    (!reviewer && !currentCompany?.corporation)
      && { path: '/locations-&-suppliers', label: 'Locations and Suppliers' },
    reviewer && { path: '/reviews', label: 'reviews' },
    { path: '/environmental-profiles', label: 'Environmental Profiles' },
    { path: '/waste-scenarios', label: 'waste scenarios' },
    superAdmin ? { path: '/manage', label: 'Manage' } : defaultUserOrAdminForCompany,
  ];

  const highRoles = superAdmin;

  if (other?.selectedMembership?.view === 'Corporate Admin') {
    links = [
      { path: '/environmental-profiles', label: 'Environmental Profiles' },
      { path: '/waste-scenarios', label: 'Waste Scenarios' },
      { path: '/manage/companies', label: 'companies' },
      { path: '/manage/users', label: 'users' },
    ];
  } else if (highRoles) ;
  else if (!other?.selectedMembership) {
    links = [];
  }

  links = _.compact(links);

  if (!userToken) return null;

  const handleChange = (value) => {
    dispatch(setMembership((value?.value === 'all') ? null : {
      ...value,
      roleName: value?.role?.name,
    }));
    const view = value?.view === 'Super Admin'
      ? value?.view?.replace('Super Admin', 'Ultra Admin')
      : value?.view;
    setCompany({
      ...value,
      label: bold(value?.company?.name, view),
      value: `${value?.company?.name} | ${view}`,
    });
    history.push('/');
  };

  if (!result || ((membershipsSize <= 4) && !other?.selectedMembership)) return null;

  return (
    <S.Container>
      <S.ContainerSection>
        {(highRoles || other?.selectedMembership?.roleName === 'ROLE_EDITOR'
          || other?.selectedMembership?.view === 'Company Admin')
          && (
            <S.ContainerSection>
              <DropDown content={<AddContent />} type="addBtn">
                <AddButton style={{ backgroundColor: '#FFCE01', border: 'unset' }}>
                  <img alt="icon" src={PlusSign} />
                  <span>{t('Add')}</span>
                </AddButton>
              </DropDown>

              <NavSearch />
            </S.ContainerSection>
          )}

        <S.ContainerSection>
          <S.StyledLinks
            style={
              (highRoles || (other?.selectedMembership?.view === 'Corporate Admin'))
                ? {} : { borderRight: '0px solid #e4e4e4' }
            }>
            {_.map(links, ({ path, label }) => (
              <S.NavButton key={label} exact={path === '/'} to={path}>
                <S.StyledLink>{t(label, { count: 0 })}</S.StyledLink>
              </S.NavButton>
            ))}
          </S.StyledLinks>
        </S.ContainerSection>

        <div style={{ paddingRight: '2rem' }}>
          <DropDown alignLeft content={<UserContent />}>
            <p style={{ color: 'white' }}>{name}</p>
          </DropDown>
        </div>

        {/* <DropDown content={<NotificationsContent />} style={{ marginLeft: '23px' }}>
          <S.StyledImage src={NotificationsIcon} />
        </DropDown> */}
      </S.ContainerSection>
      <S.ContainerSection>
        <div style={{ zIndex: 99, minWidth: '23rem' }}>
          <Select
            name="roles"
            onChange={handleChange}
            options={result}
            value={company}
          />
        </div>

        <DropDown content={<HelpDesk />} style={{ marginLeft: '23px' }}>
          <S.StyledImage src={HelpDeskIcon} />
        </DropDown>
      </S.ContainerSection>
    </S.Container>
  );
};

export default memo(Nav);
