import _ from 'lodash';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import * as actions from 'state/calculations/calculations.actions';
import { useQuery } from 'utils';

import AsideLayout from 'components/AsideLayout/AsideLayout';
import CalculationItem from 'components/CalculationItem/CalculationItem';
import CalculationsFilter from 'components/CalculationsFilter/CalculationsFilter';
import FolderItem from 'components/FolderItem/FolderItem';
import LastChangedStatus from 'components/TopBar/LastChangedStatusCalculations';
import Name from 'components/TopBar/NameCalculations';
import Type from 'components/TopBar/Name';
import TopBar from 'components/TopBar/TopBar';
import { NoContent, Loading } from 'components/Splash/Loading';

import * as Style from 'routes/GlobalStyle.styled';
import * as S from './Overview.styled';

const selector = (state) => _.pick(state, ['calculations', 'company', 'search']);

const SearchResults = () => {
  const { t } = useTranslation();
  const { folderId } = useParams();
  const dispatch = useDispatch();
  const { query: queryString } = useSelector(state => state.search);
  const query = useQuery().get('q') || queryString;
  const { calculations: c, company } = useSelector(selector);
  const [sortingDate, setSortingDate] = React.useState(null);
  const [sortingStatus, setSortingStatus] = React.useState(null);
  const isOverview = folderId === 'root';
  const {
    orderBy,
    list: calculations,
    loading,
    folders,
    filters,
    selection: selectedCalculations,
  } = c;
  const { selectedCompany: { id: selectedCompanyId } = {}, id: ownerId } = company || {};
  const companyId = selectedCompanyId || ownerId;

  useEffect(() => {
    if (query) dispatch(actions.byName(query));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  // Populate list
  useEffect(
    () => {
      const params = { ...orderBy, ...filters };

      if ((filters?.productName !== query) && (query !== '')) {
        _.set(params, 'productName', filters?.productName);
      } else _.set(params, 'productName', query);
      if (!isOverview) _.set(params, 'folder', folderId);
      if (isOverview) params['exists[folder]'] = false;
      if (companyId) _.set(params, 'owner', companyId);

      actions.getList({ params })(dispatch);
    },
    [isOverview, folderId, orderBy, filters, companyId, query, dispatch],
  );

  // didUnmount? Cleanup state (optimize memory)
  useEffect(() => () => dispatch(actions.reset), [dispatch]);

  return (
    <AsideLayout
      LeftPane={(
        <div>
          <Style.AsideTitle>
            Search results
          </Style.AsideTitle>

          <CalculationsFilter hasFilterIcon={false} query={query} />
        </div>
      )}>
      <S.Wrapper>
        <S.Main>
          <TopBar>
            <Name hasCheckbox />
            <div style={{ display: 'flex' }}>
              <div style={{ marginRight: '9rem' }}>
                <Type label="Type" noSorting />
              </div>
              <LastChangedStatus
                hasStatus
                inverted
                onOrderByDate={() => {
                  if (sortingStatus) setSortingStatus(null);
                  if (!sortingDate) setSortingDate('asc');
                  else if (sortingDate === 'asc') setSortingDate('desc');
                  else setSortingDate(null);
                }}
                onOrderByStatus={() => {
                  if (sortingDate) setSortingDate(null);
                  if (!sortingStatus) setSortingStatus('asc');
                  else if (sortingStatus === 'asc') setSortingStatus('desc');
                  else setSortingStatus(null);
                }}
                order={sortingDate}
                orderStatus={sortingStatus}
              />
            </div>
          </TopBar>

          {loading ? (
            <S.Main as="h1">{Loading}</S.Main>
          ) : (
            <S.OverviewRows>
              {_.chain(folders)
                .map((f) => f && <FolderItem key={f.id} folder={f} />)
                .value()}

              {!calculations.length ? (
                NoContent
              ) : (
                _.map(calculations, (data) => (
                  <CalculationItem
                    key={data.id}
                    calculation={data}
                    selected={selectedCalculations.includes(data.id)}
                    selection={[data.id]}
                    type={data?.calculationType === 'product'
                      ? t('product') : t('semiFinishedProduct.label')}
                  />
                ))
              )}
            </S.OverviewRows>
          )}
        </S.Main>
      </S.Wrapper>
    </AsideLayout>
  );
};

export default SearchResults;
