import styled from 'styled-components';
import { paddingRows } from 'config/style';
import { Link } from 'react-router-dom';

export const Wrapper = styled.div`
  border-bottom: 1px solid #e4e4e4;
  padding: ${paddingRows};
  min-height: 4.69rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: white;
  width: 100%;
  cursor: pointer;
`;

export const Section = styled.div`
  display: flex;
`;

export const SecondSection = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Icon = styled.img`
  margin-right: 1.8rem;
  margin-left: .3rem;
`;
export const Dots = styled.img`
  cursor: pointer;
  padding: .5rem;
`;

// export const LastChanged = styled.div`
//   font-weight: normal;
//   font-size: 12px;
//   line-height: 18px;
//   color: #A8A8A8;
//   width: 18rem;
// `;

export const Title = styled.h1`
  font-size: 1rem;
  font-weight: 600;
  font-style: normal;
  line-height: 24px;
  max-width: 45rem;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const StyledLink = styled(Link)`
  width: 100%;
  cursor: hover;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
