import Joi from '@hapi/joi';
import i18next from 'i18next';

const isRequiredMessage = inputName => i18next
  .t('required', { fieldName: i18next.t(`${inputName}.label`) });

export const required = (value, allValues, props, name) => (
  !value ? isRequiredMessage(name) : undefined
);

export const requiredNoName = (value) => (
  !value ? 'Field is required!' : undefined
);

export const requiredAfterInitialSave = (value, allValues, props, name) => {
  if (!props.isEdit) {
    return undefined;
  }

  if (props.initialValues[name] && !value) {
    return i18next.t('required after initial save', { fieldName: i18next.t(`${name}.label`) });
  }

  return undefined;
};

export const isOptionalPositiveNumber = val => Joi
  .number()
  .min(0)
  .optional()
  .validate(val === '' || val === null ? undefined : val)?.error?.message;

export const isNumber = val => Joi
  .number()
  .required()
  .validate(val)?.error?.message;

export const objWithID = val => Joi
  .object({
    id: Joi.number().required(),
  })
  .unknown()
  .validate(val)?.error?.message;

export const productName = val => Joi
  .string()
  .label('Product Name')
  .validate(val)?.error?.message;

export const calculationType = val => Joi
  .object({
    value: Joi.string().valid('semi_finished_product', 'product').required(),
  })
  .unknown()
  .validate(val)?.error?.message;

export const location = val => Joi
  .object({
    id: Joi.number().required(),
  })
  .unknown()
  .validate(val)?.error?.message;

export const method = val => Joi
  .object({
    id: Joi.number().required(),
  }).unknown()
  .validate(val)?.error?.message;

export const productLifeTime = val => Joi
  .number()
  .positive()
  .required()
  .validate(val)?.error?.message;

export const wasteRegion = val => Joi
  .object({
    '@id': Joi.string().required(),
    id: Joi.number().required(),
    code: Joi.string().required(),
    name: Joi.string().required(),
  })
  .unknown()
  .validate(val)?.error?.message;

export const scalingType = val => Joi
  .object({
    value: Joi.number().required(),
    label: Joi.string(),
  })
  .validate(val)?.error?.message;

export const functionalUnitTitle = val => Joi
  .string()
  .validate(val)?.error?.message;

export const functionalUnitDescription = val => Joi
  .string()
  .validate(val)?.error?.message;

export const standardUnit = val => Joi
  .object({
    '@id': Joi.string().required(),
    id: Joi.number().required(),
    quantityName: Joi.string(),
    unit: Joi.string(),
    abbreviation: Joi.string().max(5).min(1),
  })
  .unknown()
  .validate(val)?.error?.message;

export const functionalUnit = val => Joi
  .object({
    '@id': Joi.string().required(),
    id: Joi.number().required(),
    unit: Joi.string().required(),
    description: Joi.string().required(),
    elementName: Joi.string(),
  })
  .unknown()
  .validate(val)?.error?.message;

export const transportProfileToConstructionSite = val => Joi.object({
  id: Joi.number().required(),
  name: Joi.string(),
}).unknown().validate(val)?.error?.message;

export const distanceToConstructionSite = val => Joi
  .number()
  .validate(val)?.error?.message;

// const submitSchema = Joi.object({
//   productName: Joi.string().required().min(6),
// }).unknown();

// export const validate = values => {
//   const { error = {} } = submitSchema.validate(values);

//   return error;
// };
