import styled, { css } from 'styled-components';

export const ButtonsContainer = styled.section`
  display: flex;
  flex-direction: column;
`;

export const StatusContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  width: 7.8rem;
  border-radius: 1.25rem;
  padding: .3rem;
  font-size: .875rem;
  ${({ color }) => color && css`background: ${color}`};

  p {
    margin-left: 10px;
  }
`;

export const ProductName = styled.h1`
  font-size: 1.4rem;
  line-height: 1.7rem;
  margin-bottom: 1rem;
  font-weight: 500;
`;

export const FunctionalUnit = styled.span`
  display: flex;
  font-size: .875rem;
  margin-bottom: 1rem;
`;

export const LinksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-size: .875rem;
  padding: 1rem 2.5rem;
  border-bottom: 1px solid #e4e4e4;
  min-height: 4rem;
`;

export const ProductWrapper = styled.div`
  padding: 1rem 2.5rem;
  border-bottom: 1px solid #e4e4e4;
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Purchase = styled.button`
  background: transparent;
  appearance: none;
  cursor: pointer;
  border: 0;
`;

export const Title = styled.h2`
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 27px;
  margin-bottom: 4.1rem;
  border-bottom: 1px solid #E4E4E4;
  padding: 1rem 2.3rem;
`;

export const TitleContainer = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 27px;
  margin-bottom: 4.1rem;
  border-bottom: 1px solid #E4E4E4;
  padding: 1rem 2.3rem;
  display: flex;
  justify-content: space-between;
`;

export const MajorHeaderTitle = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 34px;
  color: #202538;
`;
