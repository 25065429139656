import React, { memo } from 'react';
import styled from 'styled-components';

const Article = styled.article`
  ul {
    margin-inline-start: 20px;
  }
  ol {
    margin-inline-start: 20px;
  }

  p:first-child {
    margin-top: 0;
  }
  p:last-child {
    margin-bottom: 0;
  }
`;

Article.defaultProps = {
  className: 'with-p-margins',
};

const RenderHTML = ({ children, html, ...props }) => (
  <Article dangerouslySetInnerHTML={{ __html: html || children }} {...props} />
);

export default memo(RenderHTML);
